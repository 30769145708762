import Breadcrumb from 'components/UtilityComps/Breadcrumb/Breadcrumb';
import CopyPillTooltip from 'components/UtilityComps/CopyPillTooltip/CopyPillTooltip';
import { idxClass } from 'helpers/idxClass';
import { defaultUserImg, showModal } from 'helpers/uiFunctions';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import styles from './ClassDetails.module.scss';

const ClassDetailsTopBar = ({ synod, modalOverlay, view, handleAutoTrigger, toggleView, setFilterValue, filterValue, students, classData:{ classId, className, teachers, pendingStudents, iconKey, classCode } }) => {

  const searchRef = useRef()
  const inputRef = useRef()

  return (
    <div className={styles.topBar}>

      <div className={styles.row}>

        <div className={styles.leftItems}>
          <Breadcrumb
            link1={{text:'Home',link: !synod ? '/teacher/classes' : '/dashboard'}}
            current={className} />

        </div>

        {
          !synod &&
            <div className={styles.rightItems}>

              {
                view === 'activities' && 
                  <div ref={searchRef} className={`${styles.search}`}>
                    <div className={styles.inner}>
                      <div onClick={() => { searchRef.current.classList.toggle(styles.open); inputRef.current.focus() }} className={styles.lupa} />
                      <input 
                        onChange={e => setFilterValue(e.target.value)} 
                        value={filterValue}
                        ref={inputRef} autoFocus type="text" name="search input" />
                    </div>
                  </div>
              }

              {
                teachers?.length &&
                  <div className={styles.teacherPic}>
                    <img src={teachers[0].teacherImage ? teachers[0].teacherImage : defaultUserImg(teachers[0])} alt={teachers[0].teacherName} />
                  </div>

              }

            </div>
        }
      </div>
      <div className={`${styles.row} ${styles.leftAlign}`}>
        {
          !synod &&
            <div className={`${styles.codeDiv} ${styles[idxClass(iconKey)]}`}>
              <div className={styles.codeInner}>ID {classCode}</div>
            </div>
        }
        <h5 className={`${styles.classTitle} ${synod && styles.synod}`}>{className}</h5>
        {
          ((students?.length && typeof(students) !== 'string') && !synod )&&
          <div className={styles.classMates}>
            <div style={{width:`${students.length * 35}px`, minWidth: '48px', maxWidth: `135px`}} className={styles.avatars}>
              {
                students?.map((student, idx) =>
                  idx <= 3 &&
                    <Link style={{left:`-${15*idx}px`, zIndex:`${students.length - idx}`}} to={`/teacher/viewStudent/${classId}/${student.studentId}/1`} key={idx}>
                      <img src={student.boomerUrl} alt="avatar" key={idx} />
                      <div className={styles.tooltip}>{student.studentName}</div>
                    </Link>
                )
              }
            </div>
              {
                students.length > 4 ?
                  <div onClick={()=>showModal(modalOverlay, {type:"classmates", students, className, classId })} className={styles.moreStudents}>
                    <span className={styles.circle}>+{students.length - 4}</span>
                    <span className={styles.oval}>view all</span>
                  </div>
                :
                  <div onClick={()=>showModal(modalOverlay, {type:"classmates", students, className, classId })} className={`${styles.moreStudents} ${styles.viewAll}`}>
                    <span className={styles.oval}>view all</span>
                  </div>
              }
          </div>
        }
      </div>
      
        <div className={`${styles.row} ${styles.center} ${styles.marginTop}`}>
          <div className={`${styles.radios}`}>
            <div onClick={() => toggleView('activities')} className={`${styles.radioDiv} ${view === "activities" ? styles.noClick : styles.grey}`}>
              <p>Activities</p>
            </div>
            <div onClick={() => toggleView('students')} className={`${styles.radioDiv} ${view === "students" ? styles.noClick : styles.grey}`}>
              {pendingStudents ? <div className={styles.dot} /> : null}
              <p>{!synod ? "Students" : "Participants"}</p>
            </div>
            <div onClick={() => toggleView('teachers')} className={`${styles.radioDiv} ${view === "teachers" ? styles.noClick : styles.grey}`}>
              <p>{!synod ? "Teachers" : "Group Leaders"}</p>
            </div>
            <div onClick={() => toggleView('reports')} className={`${styles.radioDiv} ${view === "reports" ? styles.noClick : styles.grey}`}>
              <p>{!synod ? "Reports" : "Group Reports"}</p>
            </div>
            
            <div className={`${styles.line} ${styles[view]}`} />
          </div>

          {
            view == "activities" &&
              <div className={`${styles.rowButtons}`}>
                <div onClick={()=>handleAutoTrigger('students')} className={`${styles.btn}`}> <div className={`${styles.icon} ${styles.student}`}/> <p>Add students</p></div>
                <div onClick={()=>handleAutoTrigger('teachers')}  className={`${styles.btn}`}> <div className={`${styles.icon} ${styles.teacher}`}/> <p>Add teachers</p></div>
              </div>
          }

        </div>

    </div>
  )

}

export default ClassDetailsTopBar;