
import axios from 'axios';
import { updateDashData } from 'Redux/actions';
import {store} from '../storeConfig';
import { logout } from 'components/StructuralComps/logoutScript';


export const getFromApi = async (query, action, object, func) => {

  
  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/${query}`
  
  
  try {
    
    console.log(url)
    console.log({headers: { 'Authorization': token || '' } })
    const { data } = await axios.get( url, {headers: { 'Authorization': token || '' } } )
    
    console.log(data)
    return data;
    
  } catch (error) {
    console.log(error)
  }
}

export const sendToApi = (query, newData, action, details) => {
  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/${query}`

  axios.post(url, newData, {headers: { 'Authorization': token || '' }})

  .then(response => {
    console.log(response)

    if(action==='uploadProfilePic'){
        details.setProfilePic(response.data.imageUrl);
        details.setLoading({...details.loading, profilePic:false})
        store.dispatch( updateDashData('imageUrl', response.data.imageUrl) )

    } if (action === 'logout'){
      logout(response.data.url)
    }
    })
    .catch((error) => {
      console.log(error)
    })
    
}
