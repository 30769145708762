import React, { useState, useRef } from 'react';
import { sendToApiReturn } from 'API/teacherCalls';
import { openInNewTab } from 'helpers/uiFunctions';

import styles from './NewGroupModal.module.scss';
import { LoadingOverlay } from 'components';

import { Redirect, useHistory } from 'react-router-dom';


const NewGroupModal = ({ newModalRef, overlayRef, inputRef, toggleNewModal, 
                         responsive, fjUser }) => {

  const [textInput, setTextInput] = useState('')
  const [loading, toggleLoading] = useState(false)
  const [showMore, toggleShowMore] = useState(false)
  const history = useHistory()

  const handleCreateGroup = async () => {
    toggleLoading(true)
    const data = await sendToApiReturn(`class/create?className=${textInput}`);
    localStorage.setItem('dashRecall', true)
    history.push('/teacher');
  }

  return (
    <>
      <div ref={newModalRef} className={`${styles.container} ${responsive && styles.responsive}`}>
        <div className={styles.topRow}>
          <h5>Name your group and start creating activities</h5>
          <div onClick={() => toggleShowMore(!showMore)} className={`${styles.chevron} ${showMore && styles.open}`} />
        </div>
        <div className={`${styles.moreInfo} ${showMore && styles.show}`}>
          <p>Groups can have as many students as you need and can be as small as 1 student.</p>
          <p>Activities are created within the groups.</p>
          <p>You will be able to invite students to your group/classroom later.</p>
          <p>You can create as many groups/classrooms as you need.</p>
          {
            fjUser &&
            <>
              <h5>See our onboarding guide</h5>
              <div onClick={()=>openInNewTab("https://www.youtube.com/watch?v=Px0kITqPIq4&t=1s")} className={`${styles.linkDiv}`}>
                <div className={`${styles.play}`} />
              </div>
            </>
          }
        </div>
        {loading && <LoadingOverlay contained blueSpinner />}
        <div onClick={() => toggleNewModal('remove', responsive)} className={styles.close} />
        <input onKeyUp={e => setTextInput(e.target.value)} ref={inputRef} placeholder={"e.g 5th graders afternoon"} type="text" />

        <div onClick={handleCreateGroup} className={`${styles.createBtn} ${textInput === "" && styles.disabled}`}>
          <p>Create group</p>
        </div>
      </div>
      {responsive && <div ref={overlayRef} className={styles.overlay}></div>}
    </>
  )

}

export default NewGroupModal;