import React from 'react';

import styles from './Tooltip.module.scss';

const Tooltip = ({ hoverClass, tooltipText, maxWidth, padding, whiteSpace, left, sml }) => {

  // hoverClass CSS selector should be contained in the parent component's css module, nested within the 
  // parent element, and should control the hover opacity and position behavior of this container, e.g:

            // [parentElement]:hover{
            //   [hoverClass]{
            //      opacity: 1 !important;
            //      top: 35px !important;
            //    }
            // }

    const contStyles = {
      width:maxWidth, 
      padding:padding,
      left: left ? left : null
    }

  return (
    <section className={`${styles.container} ${sml && styles.sml} ${hoverClass}`} style={contStyles}>
      <p style={{ whiteSpace:whiteSpace }}>{tooltipText}</p>
    </section>
  )

}

export default Tooltip;