import axios from 'axios';

export const getFromApi = async (query) => {
  
  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/school/${query}`
  
  try {
    
    const { data } = await axios.get( url, {headers: { 'Authorization': token || '' } } )

    return data;
    
  } catch (error) {
    console.log(error)
  }
}

