import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import loader from 'assets/spinner-white-sm.svg';
import { store } from 'storeConfig';
import styles from './ChangeEmail.module.scss';
import { sendToApi } from 'API/teacherCalls';
import { defaultUserImg } from 'helpers/uiFunctions';
import { setQueryItem } from 'Redux/actions';

const ChangeEmail = ({closeBtn, item:{setEmail, variant}}) => {

  const mainRef = useRef();
  const newRef = useRef();  
  const btnRef = useRef(); 
  const [error, setError] = useState({visible:false, text:'error message', success:false})
  const [ submit, setSubmit ] = useState({loading:false, response:''})

  const regex = /.+@.+\..+/i;


  const shake = () =>{
    mainRef.current.classList.add(styles.shake)
    setTimeout(()=>{mainRef.current.classList.remove(styles.shake)},1000)
  }


  useEffect(()=>{

    if(submit.response==='error'){
      shake()
      setError({visible:true, text:'An error occured'})
      newRef.current.focus()
      newRef.current.value=''
      
    } else if (submit.response.status===200){
      setError({visible:false, success:true})
      btnRef.current.classList.add(styles.revert)
      setTimeout(()=>{btnRef.current.classList.remove(styles.revert)},1000)
      newRef.current.focus()
      newRef.current.value=''
    }
  },[submit])

  const handleSubmit = () => {
    const newMail = newRef.current.value;

    if(newRef.current.value==='' || !regex.test(newRef.current.value)){
      setError({visible:true, text:'Please enter a valid email address'})
      shake()

    } else {

      setEmail(newMail)
      const payload = {
        email: newMail
      }
      sendToApi(`account/change_email`, payload, 'changeEmail', {setSubmit:function(){console.log('hello')}, submit:submit})

      if(variant!=='modal'){
        closeBtn.current.click()

      } else {
        store.dispatch( setQueryItem({type:'activateAccount', email:newMail}))
        
      }
    }
  }

  return (
    <div className={styles.container}>
        <>
          <div ref={mainRef} className={styles.main}>

            <div className={styles.row}>
              <h3>New email address</h3>
              <input autoFocus ref={newRef} type="email" name="newEmail" id="newEmail"/>
            </div>

            <div className={(error.visible ? styles.error : `${styles.error} ${styles.hidden}`)}> {error.text} </div>
            <div className={(error.success ? styles.success : `${styles.success} ${styles.hidden}`)}> {submit.response.data ? submit.response.data.message : null} </div>

          </div>
          {
            submit.response.status=== 200 ? 
              <Link to='/teacher/classes'> <div ref={btnRef} className={styles.button}>Go to dashboard</div> </Link> 
            :
              <div ref={btnRef} onClick={handleSubmit} className={styles.button}> {!submit.loading ? 'Update email address' : <img src={loader} alt="loader"/> }</div>
          }
        </>
    </div>
  )

}

export default ChangeEmail;