
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
 
import rootReducer from './Redux/reducers';
 
const persistConfig = {
  key: 'root',
  storage,
  blacklist:[
    'chatData'
  ]
}

const middleware = [thunk]

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(...middleware),    
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
);

export  let persistor = persistStore(store)