import React, { useState, useEffect, useRef } from 'react';

import { getFromApi } from 'API/teacherCalls';
import InlineHtml from 'components/UtilityComps/InlineHtml/InlineHtml';
import { showModal } from 'helpers/uiFunctions';

import book from '../../../assets/book.svg'

import styles from './VerseOfTheDay.module.scss';
import WelcomeDiv from './WelcomeDiv';

const VerseOfTheDay = ({modalOverlay}) => {

  // const [quotes, setQuotes] = useState([]);
  const [ quoteTitle, setQuoteTitle ] = useState("")
  const [ quoteText, setQuoteText ] = useState("")
  const [ forDate, setForDate ] = useState("")
  const [ offset, setOffset ] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const mountedRef = useRef(true)

  const fetchQuotes = async () => {
    // only start fetching if the component is mounted
    if (!mountedRef.current) return null;
    // fetch the daily quotes
    const resp = await getFromApi(`daily_quotes?offset=${offset}`);    
    // if we have at least one quote, set the title and description
    if (resp[0]) {
      // setQuotes(resp);
      setQuoteTitle(resp[0].title);
      setQuoteText(resp[0].description);
      setForDate(resp[0].forDate);
    }
  };

  const fetchPrev = () => {
    // calculate the new offset
    const newOffset = offset <= -5 ? -5 : offset - 1
    // only change the UI and re-fetch if the offset is between -5 and 0
    if (newOffset !== offset) {
      // make sure the offset does not go lower than 5 days ago
      setOffset(newOffset)
      // let the user know we're fetching the quote they've requested
      setQuoteTitle("")
      setQuoteText("")
      setForDate("loading...")
    }
  }

  const fetchNext = () => {
    const newOffset = offset >= 0 ? 0 : offset + 1
    // only change the UI and re-fetch if the offset is between -5 and 0
    if (newOffset !== offset) {
      // make sure the offset does not go past today into the future
      setOffset(newOffset)
      // let the user know we're fetching the quote they've requested
      setQuoteTitle("")
      setQuoteText("")
      setForDate("loading...")
    }
  }

  // only fetch when the offset changes
  useEffect(() => {
    fetchQuotes();
  }, [offset]);

  return (
    <div className={styles.container}>
      <div className={`${styles.contents} ${isExpanded && styles.expanded}`}>
        <div className={styles.topRow}>
          <img src={book} alt="book" />
          <h3>Verse of the day</h3>
          <div onClick={()=>fetchPrev()} className={styles.previous} />
          <p className={styles.forDate}>{forDate}</p>
          <div onClick={()=>fetchNext()} className={styles.next} />
          <div onClick={()=>setIsExpanded(!isExpanded)} className={`${styles.expand} ${isExpanded && styles.open}`} />
        </div>
        <div className={`${styles.quoteBody} ${isExpanded && styles.visible}`}>
          {
            quoteText.length && quoteTitle.length ?
            <div>
              <InlineHtml rawHtml={quoteText + 
                "<em> Catholic Public Domain Bible</em>"} />                 
              <p className={styles.sectionTitle}>{quoteTitle}</p>
            </div>
            :
            <p>Fetching quote of the day...</p>
          }
        </div>
        <button
          onClick={() => {
            showModal(modalOverlay, {
              type: "journalFromText",
              quoteTitle: quoteTitle,
              quoteText: quoteText
            });
          }}
        >Add this to a journal</button>
      </div>
    </div>
  );
};

export default VerseOfTheDay;

