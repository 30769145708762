import React from 'react';
import GoogleShareToClassRoom from 'google-classroom-share';
import { redirectGoogleClassroomUrl } from 'helpers/redirectFunctions';

import styles from './AdditionalButtons.module.scss';
import { useSelector } from 'react-redux';
import { showModal } from 'helpers/uiFunctions';
import shareImg from '../../../../assets/icon-shareResource--grey.svg'

const AdditionalButtons = ({ activity, modalOverlay, classId, activeSection }) => {

  const subscription = useSelector(state => state.dashData.subscription)
  const schoolName = useSelector(state => state.dashData.schoolName)
  const plusPermission = subscription === 'complete'
  const isAdmin = (localStorage.getItem('a') == 'true')

  return (


    <div className={`${styles.additionalButtons}`}>
      <div className={styles.icon} id="googleClassIcon">
        <GoogleShareToClassRoom
          body=""
          itemType="assignment"
          url={redirectGoogleClassroomUrl(activity.activityId)}
          size={50}
          title={activity.activityName}
          theme="light"
          onShare={(type) =>
            console.log(`GoogleShareToClassRoom:${type}`)
          }
          onShareComplete={() =>
            console.log("GoogleShareToClassRoom:onShareComplete")
          }
          onShareStart={() =>
            console.log("GoogleShareToClassRoom:onShareStart")
          }
        />
        <div className={"hoverBox"}></div>
        <div id="googleToolTip" className={styles.tooltip}>
          Share to Google Classroom
        </div>
      </div>

      <div
        onClick={() => {
          showModal(modalOverlay, {
            type: "duplicateClass",
            classId: classId,
            activityId: activity.activityId,
          });
        }}
        className={
          plusPermission
            ? styles.icon
            : `${styles.icon} ${styles.disabled}`
        }
      >
        <div className={styles.tooltip}>Duplicate assignment</div>
      </div>

      <div
        onClick={() => {
          showModal(modalOverlay, {
            type: "pdfGen",
            students: activeSection.entries,
            activity: activity,
          });
        }}
        className={
          plusPermission
            ? styles.icon
            : `${styles.icon} ${styles.disabled}`
        }
      >
        <div className={styles.tooltip}>Generate PDF</div>
      </div>

      {
        (isAdmin || schoolName) &&
        <div
          onClick={() => {
            activity.shareable &&
              showModal(modalOverlay, {
                type: "addResource",
                activity: activity,
              });
          }}
          className={`${styles.icon} ${!activity.shareable && styles.blocked}`}
        >
          <img src={shareImg} alt="share" />
          <div className={`${styles.tooltip} ${!activity.shareable && styles.wide}`}>{
            activity.shareable ?
              "Share to Teacher Resources" :
              "Built-in StoryStarts cannot be shared to resources"
          }</div>
        </div>
      }
    </div>

  )

}

export default AdditionalButtons;