import React, { useState, useEffect, useRef } from 'react';
import { sendToApi, sendToApiReturn } from 'API/teacherCalls';

import styles from './ProfileEmailSettings.module.scss';
import { store } from 'storeConfig';
import spinner from 'assets/spinner-white-sm.svg';

const ProfileEmailSettings = ({ permissions }) => {

    const newRef = useRef();  
    const btnRef = useRef(); 
    const [error, setError] = useState({visible:false, text:'error message', success:false})
    const [ submit, setSubmit ] = useState({loading:false, response:''})
    const oldEmail = store.getState().dashData.email;
    const [ btnStage, setBtnStage ] = useState('unedited')

    const regex = /.+@.+\..+/i;


    const shake = () =>{
      newRef.current.classList.add(styles.shake)
      setTimeout(()=>{newRef.current.classList.remove(styles.shake)},1000)
    }

    useEffect(()=>{
      if(submit.response){
        setBtnStage('init')
      }
    },[submit])

  useEffect(()=>{

    if(submit.response==='error'){
      shake()
      setError({visible:true, text:'An error occured'})
      newRef.current.focus()
      newRef.current.value=''
      
    } else if (submit.response.status===200){
      setError({visible:false, success:true})
      btnRef.current.classList.add(styles.revert)
      setTimeout(()=>{btnRef.current.classList.remove(styles.revert)},1000)
      newRef.current.blur()
      // newRef.current.value=''
    }
  },[submit])

  const handleSubmit = async () => {
    const newMail = newRef.current.value;

    if(newRef.current.value==='' || !regex.test(newRef.current.value)){
      setError({visible:true, text:'Please enter a valid email address'})
      shake()

    } else {
      setError({...error, visible:false})
      setSubmit({loading:true, response:''})
      btnRef.current.classList.add(styles.revert)

      setBtnStage('saving')
      // setTimeout(()=>{
      //   setBtnStage('conf')
      // },2500)
      setTimeout(()=>{
        btnRef.current.classList.remove(styles.revert)
      },3500)
      const payload = { email: newMail }

      const res = await sendToApiReturn(`account/change_email`, payload, 'changeEmail', {setSubmit:setSubmit, submit:submit})
      setSubmit({...submit, response:res.message})
      }
  }


  return (
    <div className={styles.innerContainer}>

      <div className={styles.newEmail}>
        <h6>Update email</h6>
        <p>In order to replace the email associated with you Escribli account, please enter a new one below.</p>
        <input 
          ref={newRef} 
          type="email" 
          name="email" 
          id="emailInput" 
          placeholder="Msrodriguez@gmail.com" 
          onChange={()=>{setBtnStage('init')}}
          defaultValue={oldEmail}/>

        <div className={(error.visible ? styles.error : `${styles.error} ${styles.hidden}`)}> {error.text} </div>
     
        {/* <div className={(error.success ? styles.success : `${styles.success} ${styles.hidden}`)}> Success! </div> */}

      </div>
      {/* {
        permissions && permissions[0] !== 'parent' && 
        
        <>
          <h6>Escribli Educator Newsletter</h6>
          <p className={styles.light}>We will periodically send you an email with the latest Escribli news, information and resources</p>
          <p className={styles.strong}>I want to receive:</p>

          <div className={styles.checkBoxes}>
            <label>
              <input type="checkbox" name="preferenceCheck" id="newsletter"/>
              <div className={styles.prefCheckText}>
                <h6>Escribli Educator Newsletter</h6>
                <p>Helping you get the best out of Escribli!</p>
              </div>
            </label>
          </div>
        </>
        
      } */}
     

      <div ref={btnRef} className={btnStage === 'unedited' ? `${styles.btn} ${styles.unedited}` : styles.btn} onClick={handleSubmit}>

        {
          btnStage==='saving' ? 
          <img src={spinner} alt="spinner" /> :
            
          btnStage==='conf' ?
          'Settings updated!' : 

          'Update settings'
        }

        </div>
        {
          submit.response &&
          <p className={styles.resMessage}>{submit.response}</p>
        }
    </div>

  )

}

export default ProfileEmailSettings;



  
// <h6>Manage email preferences</h6>
// <p>Use the boxes below to select the communications we send you. We will never share your details with any third party and will only send information which is relevant to Escribli</p>
//   <div className={styles.checkBoxes}>
//     <label>
//       <input type="checkbox" name="preferenceCheck" id="passwordResets"/>
//       <div className={styles.prefCheckText}>
//         <h6>Student Password Resets</h6>
//         <p>Password reset requests from students whose accounts were created by you</p>
//       </div>
//     </label>
//     <label>
//       <input type="checkbox" name="preferenceCheck" id="schoolAdmin"/>
//       <div className={styles.prefCheckText}>
//         <h6>School Administration</h6>
//         <p>Notifications of students or teacjers requesting to join your school</p>
//       </div>
//     </label>
//     <label>
//       <input type="checkbox" name="preferenceCheck" id="tips"/>
//       <div className={styles.prefCheckText}>
//         <h6>Tips and Updates</h6>
//         <p>Tips and/or updates for improving your Escribli experience</p>
//       </div>
//     </label>
//     <label>
//       <input type="checkbox" name="preferenceCheck" id="accountNotifications"/>
//       <div className={styles.prefCheckText}>
//         <h6>Account Notifications</h6>
//         <p>Updates to the status of any pending requests you have to join a school</p>
//       </div>
//     </label>
//     <label>
//       <input type="checkbox" name="preferenceCheck" id="camps/tutoring"/>
//       <div className={styles.prefCheckText}>
//         <h6>Camps/Tutoring Details</h6>
//         <p>Promotional information and product details for out-of-school teaching model</p>
//       </div>
//     </label>
//     <label>
//       <input type="checkbox" name="preferenceCheck" id="projects"/>
//       <div className={styles.prefCheckText}>
//         <h6>Project Notifications</h6>
//         <p>Information and reminders about any projects you are responsible for</p>
//       </div>
//     </label>
//   </div>
