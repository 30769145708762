import React, { useState, useEffect }  from 'react';
import { useParams } from 'react-router';
import { getFromApi } from 'API/teacherCalls';

import styles from './PrintList.module.scss';
import { showModal } from 'helpers/uiFunctions';
import { useSelector } from 'react-redux';

const PrintList = ({ modalOverlay }) => {
  const { activityId } = useParams()
  const [ students, setStudents ] = useState(null)
  const isFaithJournal = useSelector(state => state.activityData.activityType)==="faith"
  const isBee = useSelector(state => state.activityData.isBee)
  const getStudents = async () => { setStudents(await getFromApi(`activity/${activityId}/students`)) }

  useEffect(()=>{ getStudents() },[])

  return !students ? null :  (
    <div className={styles.container}>
      {

        students.map((student, idx) => 
          <div key={idx} className={styles.studentRow}>
            <p>{student.studentName}</p>
            <div className={styles.buttons}>
              <div onClick={()=>showModal(modalOverlay, { 
                    type:'pageCount', 
                    payload:{student_id:student.studentId}, 
                    activityId:activityId, 
                    printCover:true,
                    isBee:isBee,
                    isFaithJournal:isFaithJournal
                    })
                } className={styles.coverBtn}>download cover</div>

              <div onClick={()=>showModal(modalOverlay, { 
                    type:'pdfRender', 
                    payload:{student_id:student.studentId}, 
                    activityId:activityId, 
                    printContent:true
                    })
                } className={styles.contentBtn}>download content</div>
            </div>
          </div>
        )

      }
    </div>
  )

}

export default PrintList;