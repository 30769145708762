import { useSelector } from 'react-redux';
import React from 'react';
import googleG from 'assets/icon_google_g.svg'
import cap from 'assets/icon_cap_orange.svg'
import addStudent from 'assets/icon_add_student.svg'
import { showModal } from 'helpers/uiFunctions';
import csv from 'assets/icon_csv_green.svg'

import styles from './StudentsEmptyList.module.scss';
import Masonry from 'react-masonry-css';

const StudentsEmptyList = ({ modalOverlay, classData, setStudents, students, classId, windowed, toggleInnerModal }) => {


  const simplifiedActivity = useSelector(state => state.simplifiedActivity);
  const synod = simplifiedActivity == "Synod";

  const triggerModal = (object) => {
    if (windowed) toggleInnerModal('remove')
    showModal(modalOverlay, object)
  }

  const breakpoints =
    !windowed ? { default: 4, 1950: 4, 1300: 2, 850: 1 }
      : { default: 2, 850: 1 }

  return (

    <div className={`${styles.noStudents} ${windowed && styles.windowed}`}>

      {windowed && <div onClick={() => toggleInnerModal()} className={styles.close} />}

      <h2>{!windowed ?
        "Creating student accounts on Escribli is easily done in several different ways:"
        :
        "Create student account"
      }</h2>

      <div className={`${styles.boxes} ${synod && styles.synod}`}>

        {
          !synod ?
            <Masonry
              breakpointCols={breakpoints}
              className="masonry-grid"
              columnClassName={"masonry-grid_column"}>

              <div className={styles.box} onClick={() => triggerModal({ type: 'newStudent', variant: 'google' })}>
                <img src={googleG} alt="icon" />
                <h2>Use Google Sign in <span>{classData.classCode}</span></h2>
                <p>Students can use their school-issued Gmail accounts along with your BW class id to register and login with the “Sign in with Google” button on our Student login page. </p>
              </div>

              <div className={styles.box}
                onClick={() => triggerModal({
                  type: 'newOnboarding',
                  classId,
                  updateStudents: (input) => setStudents(students === 'initial' ? [...input] : [...students, ...input])
                })}>
                <img src={cap} alt="icon" />
                <h2>Create individual accounts</h2>
                <p>Create individual students accounts quickly, easily and as needed</p>
              </div>

              <div className={styles.box}
                onClick={() => triggerModal({
                  type: 'newStudent',
                  variant: 'existing',
                  classId: classId,
                  updateStudents: (input) => setStudents(students === 'initial' ? [...input] : [...students, ...input])
                })}>
                <img src={addStudent} alt="icon" />
                <h2>Add existing student/s</h2>
                <p>Add students to class from existing accounts associated with teacher, school, or district.</p>
              </div>

              <div className={`${styles.box} ${styles.csv}`}
                onClick={() => triggerModal({
                  type: 'newStudent',
                  variant: 'csv',
                  classId: classId,
                  classObj: classData,
                  updateStudents: (input) => {
                    console.log(input)
                    setStudents(input);
                  }
                })}>
                <img src={csv} alt="icon" />
                <h2>CSV or Excel Import</h2>
                <p>Create your student’s accounts in bulk using either Excel or CSV files.</p>
                <p className={styles.unavailable}>Only available on desktop</p>
              </div>

            </Masonry>
            :
            <>
              <div className={styles.box}
                onClick={() => triggerModal({
                  type: 'newOnboarding',
                  classId,
                  updateStudents: (input) => setStudents(students === 'initial' ? [...input] : [...students, ...input])
                })}>
                <img src={cap} alt="icon" />
                <h2>Create individual accounts</h2>
                <p>Create individual participant accounts quickly, easily and as needed</p>
              </div>
              <div className={`${styles.box} ${styles.csv}`}
                onClick={() => triggerModal({
                  type: 'newStudent',
                  variant: 'csv',
                  classId: classId,
                  classObj: classData,
                  updateStudents: (input) => {
                    console.log(input)
                    setStudents(input);
                  }
                })}>
                <img src={csv} alt="icon" />
                <h2>CSV or Excel Import</h2>
                <p>Create your participant's accounts in bulk using either Excel or CSV files.</p>
                <p className={styles.unavailable}>Only available on desktop</p>
              </div>
            </>

        }



      </div>
    </div>
  )

}

export default StudentsEmptyList;