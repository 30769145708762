
import { createConsumer } from '@rails/actioncable';
import { incomingMessage, incrementUnread } from 'Redux/actions';
import { store } from 'storeConfig';
// import { store } from 'storeConfig';
// import { incomingMessage, incrementUnread } from 'Actions/chatDataActions';

export const defineConsumer = (token) => {

  const backUrl = localStorage.getItem('backUrl')
  const wsURL = backUrl.replace(/http/i, "ws"); // replace 'http' + 'https' with 'ws' + 'wss'

  const URL = `${wsURL}/cable?from=teachers&token=${token}`

  if(token!==undefined){return createConsumer(URL)}

}


export const initiateSocket = async (chatterToken, chatter_id, username) => {

  console.log('SOCKET')

  const consumer = await defineConsumer(chatterToken)
  if(consumer && consumer.subscriptions){
    consumer.subscriptions.create({
      channel: "TeacherChannel",
      chatter_id,
      username
    }, {
      connected: () => console.log('connected'),
      disconnected: () => console.log('disconnected'),
      received: data => {return handleMessage(data.message)}
    })
    console.log('subscribe')
  }
}

const handleMessage = (message) => {

  console.log(message)

  if(localStorage.getItem('chatModal')){
    console.log(message)
    store.dispatch(incomingMessage(message))
    return console.log('modal open')
    // return store.dispatch(incomingMessage(data.message))
    
  } else {
    store.dispatch(incrementUnread())
    return console.log('modal closed')
    // return store.dispatch( incrementUnread() )
  }

}



