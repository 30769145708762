import { env, newMsgs, simplifiedActivity,  navOpen } from './GeneralReducers';

import { queryItemReducer  } from './AdminReducers';

import {  dashData,
          chatData,
          fetchedClass, 
          activityData,
          activeClass,
          currentActivity,
          radVal,
          selectedSection,
           } from './TeacherReducers';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
  simplifiedActivity: simplifiedActivity,
  env: env,
  navOpen, navOpen,
  dashData:dashData,
  fetchedClass:fetchedClass,
  activityData: activityData,
  queryItem: queryItemReducer,
  activeClass : activeClass,
  currentActivity: currentActivity,
  radVal: radVal,
  selectedSection: selectedSection,
  newMsgs:newMsgs,
  chatData
})

export default rootReducer;