
export const normalize = input => input?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const filterObjectByObject = (object, criteria) => {
  // console.log(object)
  // console.log(criteria)
  let output = true
  Object.entries(criteria).forEach(([key,value]) =>{
    if( ['','all'].indexOf(value) === -1 && !normalize(object[key]).includes(normalize(value)) ) output=false
  });
  return output
};

export const filterArrayByObject = (array, criteria) => {
  return Object.entries(criteria).reduce((output, [key, value]) => {

    return output.filter((i) =>
      typeof(value) === 'string'
        ? i[key]?.toLowerCase().includes(value.toLowerCase())
        : i[key] == value
    );
  }, array);
};




export const testTeacher = (item, info, filtGrade) => {

  let result = true;
    if ( !(item.teacherName.toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(info.toLowerCase()) ) 
      {result = false} 
      
    if(filtGrade !== 'all' && !item.grades.includes(parseInt(filtGrade)))
      {result = false} 

      return result
}

export const testStudent = (item, info, filtGrade, filtTeacher) => {

  let result = true;
    if ( !(item.studentName.toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(info.toLowerCase()) ) 
      {result = false} 
      
    if(filtGrade !== 'all' && item.grade !== parseInt(filtGrade))
      {result = false} 

    if(filtTeacher !== 'all' && item.teacherName !== filtTeacher)
      {result = false}

      return result
}

//TEST OBJECT KEYS TO SEE IF ANY MATCH A GIVEN STRING
export const testString = (item, info) => {
  if (Object.keys(item).some(i =>
    (item[i].toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .includes(info.toLowerCase()))
    ){return true} else {return false}
}

//TEST AGAINS STRING AND RADIO INPUT
export const testFilters = (item, info, levelRadio, genreRadio) => {
  let result = true

  if(!item.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(info.toLowerCase())
  // &&
  // !item.author.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") .includes(info.toLowerCase())
  )
  { result = false }

  if (levelRadio !== 'all' && item.level !== levelRadio){
    result = false
  }
  if (genreRadio !== 'all' && item.genre !== genreRadio){
    result = false
  }
    return result
}


export const studentStatusFilters = (item, info, radioValue, key) => {
  let result = true
  
  if (
    item && !(item.studentName.toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(info.toLowerCase())
  ) {result = false}

  if (radioValue !== 'all' && item[key] !== radioValue){
    result = false
  }
    return result
}

export const studentStatusFiltersNumeric = (item, info, radioValue, key) => {
  let result = true
  
  if (
    !(item.studentName.toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(info.toLowerCase())
  ) {result = false}

  if (radioValue !== 'all' && item[key] !== parseInt(radioValue)){
    result = false
  }
    return result
}

export const testName = (item, info) => {

  if ( !(item.studentName.toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(info.toLowerCase()) ) 
    {return false} 

  else {return true}

}
export const testClassName = (item, info) => {

  if ( !(item.className.toLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(info.toLowerCase()) ) 
    {return false} 

  else {return true}

}
export const testRadioKey = (item, key, radioVal) => {

  if (radioVal !== 'all' && item[key] !== radioVal.toLowerCase()){
    return false
  }
  else return true
}

export const arraysEqual = (arr1, arr2) => {
  if(arr1.length !== arr2.length)
      return false;
  for(var i = arr1.length; i--;) {
      if(arr1[i] !== arr2[i])
          return false;
  }

  return true;
}


//ResourceItem filter

export const resourceItemFilter = (item, filterValues) => {
  let result = true;
  const {activityType, subject, sections, grade, textInput, schoolId, free} = filterValues;

  if(schoolId !== '' && schoolId != item.schoolId){
    result = false
  }

  if(activityType !== 'all' && activityType.toLowerCase() !== item.activityType.toLowerCase()){
    result = false
  }

  

  if(subject !== "all" && !subject.includes(item.activitySubject.toLowerCase())){
    result = false
  }

  if(sections==='1' && item.sections.length > 2){result = false}
  if(sections==='2'){ if(item.sections.length < 3 || item.sections.length > 6){result = false} } 
  if(sections==='3' && item.sections.length < 7 ){result = false}

  if (free && item.free) {
    result = false;
  }


  if(grade && grade !== "all" && item.shareGrade.length){
    grade.forEach(gr => {
      if(!item.shareGrade.includes(gr)){
        result = false
      }
    })
  }

  if(textInput !== '' && !item.activityName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(textInput.toLowerCase())
    && !item.author.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(textInput.toLowerCase())){
    result=false
  }
  
  return result
}


export const resourceItemFilterNew = (item, filterValues) => {
  
  let result = true;
  const {activityType, textInput, subject } = filterValues;

  console.log(item.activitySubject)
  console.log(filterValues.subject)

  if(subject !== "all" && !subject.toLowerCase().includes(item.activitySubject.toLowerCase())){
    result=false
  }

  if(activityType !== 'all' && activityType.toLowerCase() !== item.activityType.toLowerCase()){
    result=false
  }

  if(textInput !== '' && !item.activityName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(textInput.toLowerCase())
    && !item.author.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(textInput.toLowerCase())){
    result=false
  }


  
  return result
}

export const tutorialFilter = (item, filterValues) => {
  console.log(filterValues)
  console.log(item)

  let result = true;
  const { text, radio} = filterValues;

  if(text !== '' && !item.title.toLowerCase().includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
    result=false
  }
  if(radio!=='all'&&item.tag!==radio){
    result=false
  }

  return result

}