import React, { useRef, useState } from 'react'

import styles from './WelcomeToTheBee.module.scss'
import beeBannerPic from 'assets/bee-modal-img.png'
import { sendToApi } from 'API/teacherCalls'
import { useSelector } from 'react-redux'
import boomer from 'assets/bee-modal-img-boomer.png'
import logo from 'assets/bee-logo-2021.svg'
import { addAnimation, redBorder } from '../../../../helpers/uiFunctions';

const WelcomeToTheBee = () => {

  const [ submitted, setSubmitted ] = useState(false)
  const [ textInput, setTextInput ] = useState({
    name:'',
    school:'',
    grade:'',
    townCity:'',
    twitter:'',
    schoolTwitter:'',
    facebook:'',
    schoolFacebook:'',
    message:''
  })
  const {name, school, townCity, grade } = textInput
console.log(textInput)
  const handleChange = (e) => {
    setTextInput({...textInput, [e.target.name]:e.taget.value})

  }

  const inputsRef= useRef()
  const activityId= useSelector(state=>state.activityData.activityId)
  const inputs = Array.from(document.querySelectorAll("[data-handle='required']"));

  
  const handleSubmit = () => {

    if(!name||!school||!grade||!townCity){
      redBorder(inputs, 'add');
      return addAnimation(inputsRef, 'shakeSlow', 600)
    }

    sendToApi(`bee_shout_out/${activityId}`, textInput)
    setSubmitted(true)
  }

  return (
    <div className={`${styles.container} ${submitted && styles.conf}`}>
      {
        !submitted ? 
          <>
            <div className={`${styles.header}`}> <img src={boomer} alt="boomer"/> <h1>Welcome to</h1> <img src={logo} alt=""/> </div>

            <p className={styles.subhead}>We'd love to give your class a shout out on social media welcoming you and your students to The Writing Bee</p>

            <div ref={inputsRef}  className={styles.inner}>
              <p>We just need the following details: </p>
              <div className={styles.inputs}>
                
                <input
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value}); e.target.classList.remove('errorBorder')}}
                  data-handle='required' 
                  autoFocus 
                  type="text" 
                  name="name" 
                  id="name" 
                  placeholder="Name"/>

                <input 
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value}); e.target.classList.remove('errorBorder')}}
                  data-handle='required'
                  type="text" 
                  name="grade" 
                  id="grade" 
                  placeholder="Grade"/>

                <input 
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value}); e.target.classList.remove('errorBorder')}}
                  data-handle='required'
                  type="text" 
                  name="school" 
                  id="school" 
                  placeholder="School"/>

                <input 
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value}); e.target.classList.remove('errorBorder')}}
                  data-handle='required'
                  type="text" 
                  name="townCity" 
                  id="towcity" 
                  placeholder="Town/City"/>

                <input 
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value})}}
                  type="text" 
                  name="twitter" 
                  id="twitter" 
                  placeholder="Your Twitter Account (Optional)"/>

                <input 
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value})}}
                  type="text" 
                  name="schoolTwitter" 
                  id="schoolTwitter" 
                  placeholder="School Twitter URL (Optional)" />

                <input 
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value})}}
                  type="text" 
                  name="facebook" 
                  id="facebook" 
                  placeholder="Your Facebook Account (Optional)"/>

                <input 
                  onChange={(e)=>{setTextInput({...textInput, [e.target.name]:e.target.value})}}
                  type="text" 
                  name="schoolFacebook" 
                  id="schoolFacebook" 
                  placeholder="School Facebook URL (Optional)"/>
              </div>


              <label>Would you like to personalize your Shout Out with a personal message?:</label>
              <textarea 
              placeholder="(Optional)" 
              onChange={(e)=>setTextInput({...textInput, message:e.target.value})} />

            </div>

              <div onClick={handleSubmit} className={`${styles.submitBtn} ${textInput==='' && styles.disabledBtn}`}>Submit Class Details</div>

          </>
        :
        
        <>
          <img src={beeBannerPic} alt="Boomers"/>
          <h1>Thanks for taking part!</h1>

        </>

      }
    </div>
  )

}

export default WelcomeToTheBee;