import React, { useState, useEffect,  useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { store } from 'storeConfig';

import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import { idxClass } from 'helpers/idxClass';
import styles from './ClassActivityBlock.module.scss';
import { sendToApiReturn } from 'API/teacherCalls';
import Tooltip from 'components/UtilityComps/Tooltip/Tooltip';
import { showModal } from 'helpers/uiFunctions';
import { updateActivityKey } from 'Redux/actions';
import ActivitySectionBlock from './ActivitySectionBlock';
import ClassActivityDropdown from './ClassActivityDropdown';

const ClassActivityBlock = ({ synod, idx, bordered, count, modalOverlay, handleArchive, setClassData, classData, archived, activity, activity:{ activityId, activityClassId, activityName, sections, iconKey}, handleDeleteActivity }) => {

  const history = useHistory()
  const [ isOpen, toggleOpen ] = useState(false)
  const [ isEditing, toggleEditing ] = useState(false)
  const [ display, toggleDisplay ] = useState(false)
  const [newCount, setNewCount ] = useState(0)
  const contRef = useRef()
  const inputRef = useRef()
  const dropRef = useRef()

  const toggleDropdown = input => dropRef.current.classList[input](styles.open)

  const isComplete = sections.every(i => i.completed)

  useEffect(()=>{
    const offset = Math.floor(Math.random() * count) * 90
    setTimeout(()=>{ toggleDisplay(true) },offset)
    let loopCount = 0
    sections.forEach(i =>{
      loopCount = loopCount+i.unseenEntries
    },[])
    setNewCount(loopCount)
  })


  const handleNavigate = (sectionIdx) => {

    if(window.innerWidth<661) return showModal(modalOverlay, {type:'responsiveSectionList', activity, sections: activity.sections.reverse()})

    if(!synod){
      history.push(`/teacher/classes/${activityClassId}/${activityId}/settings/${sectionIdx}`)
    } else {
      history.push(`/write/${activityClassId}/${activityId}/settings/${sectionIdx}`)

    }

  }

  const handleNewName = () => {

    if(inputRef.current?.value !== "" && inputRef.current?.value!== activityName){

      sendToApiReturn(`activity/${activityId}/update`, {...activity, activityName:inputRef.current.value})
      store.dispatch(updateActivityKey('activityName', inputRef.current.value))
      
      setClassData({
        ...classData, 
        activities:[
          ...classData.activities.slice(0, idx),
          {...activity, activityName:inputRef.current.value} ,
          ...classData.activities.slice(idx+1)
        ]
      })
    }

    toggleEditing(false)

  }

  const handleNameinput = async (e) => {
    console.log(e.key)

    if (e.key === 'Escape') {
      toggleEditing(false);
      console.log(isEditing)
    }
    
    if (e.key === 'Enter') {
      console.log('isEditing')
      handleNewName()
    }

  }
  useOutsideClick(contRef,()=>{if(isEditing)handleNewName()})


  return (
    <div
      ref={contRef}
      style={{zIndex:`${500-idx}`}}
      className={`
        ${styles.container} 
        ${bordered && styles.bordered} 
        ${isOpen && styles.open} 
        ${display && styles.display} 
        ${!isComplete && styles.complete}
      `} >

      {(newCount>0 && !archived) && <div className={`${styles.dot} ${isOpen && styles.open}`}></div>}

      <div className={styles.inner}>
        
      <div className={styles.main}>
        <div className={styles.content}>

        <div className={styles.iconDiv}>
          <div className={`${styles.icon} ${styles[idxClass(iconKey)]} ${archived&&styles.grey}`} />
        </div>
        <div className={styles.text}>
          <div className={styles.topRow}>
            <div className={styles.nameDiv}>
                {
                  !isEditing ?
                    <>
                      <h5>{activity.activityName}</h5>
                      <div onClick={()=>toggleEditing(true)} className={styles.editHover}> 
                        <Tooltip 
                          tooltipText={"Change activity name"} 
                          hoverClass={styles.tooltipHover} 
                          maxWidth={"140px"}
                          padding={"5px 20px"}
                          whiteSpace={"noWrap"} /> 
                      </div>
                    </>
                    :
                    <input ref={inputRef} onKeyUp={e=>handleNameinput(e)} type="text" defaultValue={activityName} autoFocus className={styles.nameInput} />

                }
            </div>
            <div onClick={()=>toggleDropdown('toggle')} className={styles.dots} />
          </div>
          <div className={styles.bottomRow}>
            <div className={styles.left}>
              <p>{sections.length} sections</p>
            </div>
            <div onClick={()=>toggleOpen(!isOpen)} className={`${styles.chevron} ${isOpen && styles.open}`} />
          </div>
        </div>

          <div className={styles.clickHandle} onClick={()=>handleNavigate(0)} />
        </div>

      </div>

      <div className={styles.sectionsDiv}>
        { sections.length === 0 ? 

            <div className={styles.placeholder}>
              <h5 className={styles.noSections}>No sections to display</h5>
            </div>

          :

            sections.map((section,idx) => 
              <ActivitySectionBlock
                archived={archived}
                isStoryStart={activity.activityType==='story'&&section.sectionIndex===0}
                handleNavigate={()=>handleNavigate(idx)}
                section={section} 
                idx={idx}
                key={idx} />
            )

        }
      </div>
      </div>

      <ClassActivityDropdown
        idx={idx}
        toggleDropdown={toggleDropdown}
        activity={activity}
        dropRef={dropRef}
        handleArchive={handleArchive}
        handleDeleteActivity={handleDeleteActivity}
        modalOverlay={modalOverlay}
        archived={archived} />

    </div>
  )

}

export default ClassActivityBlock;