import { dateFormatYear } from 'helpers/dateFunctions';
import React, { useEffect, useRef, useState } from 'react';

import styles from './SpendCreditsModal.module.scss';

const SpendCreditsSub = ({ handleSubmit, plusExpireDate, setCreditData, creditData, setScheduleRenewal }) => {

  const contRef = useRef();
  const sumRef = useRef();
  const [ showError, setShowError ] = useState(false)
  const [ showWarning, setShowWarning ] = useState(creditData.scheduleCreditPayment)

  const handleCheckbox = (e) => {
    setShowWarning(e.target.checked)
    setCreditData({...creditData, scheduleCreditPayment:e.target.checked})
    setScheduleRenewal(e.target.checked)
  }


  useEffect(()=>{
    setTimeout(()=>{
      contRef.current.classList.add(styles.spendCreditsInnerShow)
    },20)
  },[])

  return (
    <div ref={contRef} className={styles.spendCreditsSub}>
      <p>
        Use your credits to pay for next month's subscription plan. You can set automatic renewal in case you have enough credits for the following months.
      </p>
      
      <div ref={sumRef} className={styles.subSummary}>
        <p className={styles.summaryHeader}>Summary</p>
        <div className={styles.subSummaryRow}>
          <p>Escribli Complete</p>
          <p>15 Credits</p>
        </div>
        <div className={styles.subSummaryRow}>
          <p>Term</p>
          <p>1 month</p>
        </div>
        <div className={styles.subSummaryRow}>
          <p>New auto-renewal date</p>
          <p>{dateFormatYear(plusExpireDate)}</p>

{
  showWarning &&
    <p className={styles.warning}>Auto-renew using credits if enough credits are available on the renewal date</p>
}
        </div>
      </div>

      <label>
        <input type="checkbox" name="setAuto" id="setAuto" defaultChecked={creditData.scheduleCreditPayment} onChange={(e)=>{handleCheckbox(e)}} />
        <span>Set automatic renawal using credits</span>
      </label>

      <div className={styles.buttonDiv}>
        {showError && <p className={styles.errorMsg}>Insufficient credits</p>}
        <div onClick={()=>handleSubmit(sumRef, setShowError)} className={styles.redeemBtn}>Redeem</div>
      </div>
    </div>
  )

}

export default SpendCreditsSub;