import React from 'react';

import styles from './LoadingOverlay.module.scss';
import spinner from 'assets/Spinner.svg'
import spinner_teal from 'assets/spinner_teal.svg'

const LoadingOverlay = ({ loadingText, contained, blueBg, blueSpinner, blueText, spinnerWidth, transparant }) => {

  return (
    <div className={`${styles.container} ${transparant && styles.transparant} ${contained && styles.contained} ${blueBg && styles.blueBg}`}>
      <img style={spinnerWidth && {width:spinnerWidth}} className={blueSpinner && styles.sml} src={!blueSpinner ? spinner : spinner_teal } alt="spinner"/>
      <h5 className={blueText && styles.blue}>{loadingText}</h5>
    </div>
  )

}

export default LoadingOverlay;