import { getFromApi } from 'API/teacherCalls';
import { LoadingOverlay } from 'components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchClass } from 'Redux/actions';
import { store } from 'storeConfig';
import ClassActivities from './ClassActivities/ClassActivities';
import ClassStudents from './ClassStudents/ClassStudents';

import styles from './ClassDetails.module.scss';
import ClassDetailsTopBar from './ClassDetailsTopBar';
import ClassTeachers from './ClassTeachers/ClassTeachers';
import ResponsiveSectionList from './ResponsiveSectionList';
import ClassReports from './ClassReports/ClassReports';

const ClassDetails = ({ synod, modalOverlay, fjUser, toggleNewActivityVis }) => {

  const { classId } = useParams()
  const [ view, toggleView ] = useState(window.location.search.substring(1))
  const [ classData, setClassData ] = useState(null)
  const [ students, setStudents ] = useState(null)
  const [ filterValue, setFilterValue ] = useState('')
  const [listItem, setListItem] = useState(null)
  const [autoTrigger, toggleAutoTrigger ] = useState(false)

  const handleAutoTrigger = input => {
    toggleView(input)
    toggleAutoTrigger(true)
    setTimeout(()=>{toggleAutoTrigger(false)},1500)
  }

  const fetchClassData = async () => {
    const data = await getFromApi(`class/${classId}`)
    setStudents(await getFromApi(`class/${classId}/students`))
    setClassData(data)
    store.dispatch(fetchClass(data))
  }

  useEffect(()=>{
    fetchClassData()
  },[])
  
  useEffect(()=>{
    setFilterValue('')
    window.history.replaceState(null, null, `?${view}`);
  },[view])
  

  return (
    <div className={`${styles.container} ${synod && styles.synod}`}>

      {
        !classData ? 

          <LoadingOverlay
            blueBg
            blueSpinner
            loadingText={'Fetching class data...'} />

        :

          <>

            <ClassDetailsTopBar
              synod={synod}
              modalOverlay={modalOverlay}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              classData={classData}
              classID={classId}
              view={view}
              toggleView={toggleView}
              students={students}classId
              handleAutoTrigger={handleAutoTrigger} />

            <div className={`${styles.mainSwitch} ${styles[view]}`}>
              {
                  view === 'activities' ?

                    <ClassActivities
                      synod={synod}
                      setListItem={setListItem}
                      fjUser={fjUser}
                      modalOverlay={modalOverlay} 
                      classData={classData} 
                      filterValue={filterValue}
                      setFilterValue={setFilterValue}
                      setClassData={setClassData}
                      toggleNewActivityVis={toggleNewActivityVis} />
                      
                  : view === 'students' ?
                      
                      <ClassStudents
                        synod={synod}
                        students={students}
                        setStudents={setStudents}
                        classData={classData} 
                        setClassData={setClassData} 
                        modalOverlay={modalOverlay}
                        autoTrigger={autoTrigger} />
                      
                      
                  : view === 'teachers' ?
                      
                      <ClassTeachers
                        synod={synod}
                        autoTrigger={autoTrigger}
                        modalOverlay={modalOverlay} />

                    : view === 'reports' ?
                      
                      <ClassReports
                          synod={synod}
                          students={students}
                          setStudents={setStudents}
                          classData={classData} 
                          setClassData={setClassData} 
                          modalOverlay={modalOverlay}
                          autoTrigger={autoTrigger}
                         />

                : null
              }
            </div>

            {listItem && <ResponsiveSectionList activity={listItem} setListItem={setListItem} />}
          </>
      }



    </div>
  )

}

export default ClassDetails;

// : view === 'reports' ?
                      
// <ClassReports
//     synod={synod}
//     students={students}
//     setStudents={setStudents}
//     classData={classData} 
//     setClassData={setClassData} 
//     modalOverlay={modalOverlay}
//     autoTrigger={autoTrigger}
// />