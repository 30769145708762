import React, { useState } from 'react';

import styles from './ClassStudentReport.module.scss';
import coins from 'assets/coins.svg';
import user from 'assets/user_blue.svg';
import { showModal } from 'helpers/uiFunctions';
import { Link } from 'react-router-dom';
import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import { LoadingOverlay } from 'components';

const ClassStudentReport = ({ synod, zIndex, idx, modalOverlay, classId, updateStudents, classData, setClassData, student: { approved, studentName, studentEmail, boomerBucks, studentId, parentEmail, numberOfActivities, totalWords, grade, boomerUrl }}) => {

  const [ dropVis, toggleDropVis ] = useState(false)
  const [ localApproved, setLocalApproved ] = useState(approved)
  const [isLoading, setIsLoading] = useState(false)
  const [ rejected, setRejected ] = useState(false)

  const suffix = grade===1?'st':grade===2?'nd':'th'

  const newFlag = array => array.some(i => !i.approved)

  // console.log("student)


  const handleApprove = async () => {
    setLocalApproved(true)
    const data = await sendToApiReturn(`class/${classId}/approve_student/${studentId}`)
    console.log(data)
    setClassData({...classData, pendingStudents:newFlag(data)})
    console.log(newFlag(data))


  }
  const handleReject = async () => {
    setRejected(true)
    const data = await sendToApiReturn(`class/${classId}/reject_student/${studentId}`)
    console.log(data)
    setClassData({...classData, pendingStudents:newFlag(data)})
    console.log(newFlag(data))
  }
  // (`api/teachers/class/:class_id/student_activity_report/:student_id 

  const handleReport = async () => {
    setIsLoading(true)
    const data = await getFromApi(`class/${classId}/student_activity_report/${studentId}`);
    setIsLoading(false)
    showModal(modalOverlay, {
      type:'studentReport',
      studentName,
      image: boomerUrl,
      data: data
    })
  }

  if(isLoading){
    return (
      <LoadingOverlay
            contained
            loadingText={'loading student data...'} />
    )
  }

  return (
    <div className={`${styles.container} ${synod && styles.synod} ${rejected && styles.hidden}`} style={{ zIndex }}>

      <Link to={`/teacher/viewStudent/${classId}/${studentId}/1`} className={`${styles.column} ${styles.one}`}>
       {!synod &&  <img src={boomerUrl} alt="avatar" />}
        <div className={styles.text}>
          <p>{studentName}</p>
          <p className={styles.email}>{studentEmail}</p>
        </div>
        <div className={styles.profileBtn}>
          <p>open profile</p>
          <img src={user} alt="user" />
        </div>
      </Link>

      {
        localApproved ? 

          <>
            <div className={`${styles.column} ${styles.two} ${styles.activity}`} onClick={handleReport}>
              <p>Check activity report </p>
            </div>

            <div className={styles.column}>
              <p style={{ marginRight: "4px", width: "50px" }}>{boomerBucks}</p>
              <img src={coins} alt={"coins"} />
            </div>

            <div className={styles.column}>
            <p>
              {numberOfActivities}
            </p>
            </div>

            <div className={styles.column}>
              <p>
                {totalWords}
              </p>
            </div>

          </>

        :

          <div className={styles.approveBtns}>
            <p>A new student joined the class</p>
            <div className={styles.btns}>
              <div onClick={handleApprove} className={styles.approveBtn}>Approve</div>
              <div onClick={handleReject} className={styles.rejectBtn}>Reject</div>
            </div>
          </div>

      }


    </div>
  )

}

export default ClassStudentReport;