import React,  { useState } from 'react';
import { useEffect } from 'react';

import styles from './ArrayDropdown.module.scss';

const ArrayDropdown = ({ array, clickHandler, copyable, addOption, itemTitle }) => {

  const [ dropdown, setDropdown ] = useState(false)
  const [ conf, setConf ] = useState({0:false,1:false,2:false,3:false,4:false,5:false,6:false})

  const handleCopy = (e, index) => {

    setConf({...conf, [index]:true });   
    setTimeout(()=>{ setConf({...conf,0:false });},1000)
    navigator.clipboard.writeText(array[index]) 
  }

  return (
    <>
    { !array || array.length===0 || array[0] === undefined || array[0] === '' && addOption? 

          <div onClick={clickHandler} className={styles.noItem} >
            <h5>Add {itemTitle}</h5>
          </div>

    :  array.length>1 || addOption ?

          <div className={styles.multipleItem} 
            onClick={()=>{setDropdown(true)}}
            onMouseLeave={()=>{setDropdown(false)}} >

              <h5>{array[0]}</h5>  

            { dropdown &&
              <div className={styles.emailsDropdown}>
                  {array.map((item, index) => {
                    return (
                      <div className={copyable ? `${styles.singleItem} ${styles.copyable}` : styles.singleItem} 
                          onClick={(e)=>{copyable && handleCopy(e, index)}}>

                      {!conf[index] ?
                        <h5>{item}</h5>   
                      :
                        <p>copied </p>
                      }
                    </div>
                    )
                  })}
                {addOption && <div onClick={clickHandler} className={styles.noItem}> <h5>Add {itemTitle}</h5> </div>}
              </div>
            }

          </div>

      :

          <div className={copyable ? `${styles.singleItem} ${styles.copyable}` : styles.singleItem} 
              onClick={(e)=>{copyable && handleCopy(e,0)}}>

          {!conf[0] ?
            <h5>{array[0]}</h5>   
          :
            <p>copied </p>
          }
        </div>
      
      }
    </>
  )

}

export default ArrayDropdown;