import React,{useState, useEffect, useRef} from 'react';

import styles from './JournalFromTextModal.module.scss';
import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import spinner from 'assets/Spinner.svg'


const JournalFromTextModal = ({ closeBtn, item:{quoteTitle, quoteText}}) => {
  
  const loaderRef = useRef();
  const [ availableClasses, setAvailableClasses ] = useState([]);
  const [ selectedClassId, setSelectedClassId ] = useState();
  const [ availableProjects, setAvailableProjects ] = useState([]);
  const [ selectedProjectId, setSelectedProjectId ] = useState();

  const frontUrl = localStorage.getItem('frontUrl')

  const getClasses = async () => { 
    const data = await getFromApi('classes');
    setAvailableClasses(data)
  }

  const createActivity =  async () => {
    if(selectedClassId && selectedProjectId){
      // send the classId and projectId
      const payload = {classId:selectedClassId, 
                       projectId: selectedProjectId,
                       title: quoteTitle,
                       text: quoteText}
      // only call the backend if we have both required params
      const { activityId, sections } = await sendToApiReturn(`activity/from_text`, payload);
      // we will redirect to the last section in the activity
      const sectionNumber = sections.length ? (sections.length - 1) : 0
      return window.location.href = `${frontUrl}/teacher/classes/${selectedClassId}/${activityId}/settings/${sectionNumber}`
    }
  }
  
  // fetch the classes the first time the modal is loaded
  useEffect(()=>{getClasses()},[])
  
  // fetch the projects from the selected class
  useEffect(()=>{ 
    const getClassActivities = async () => { 
      if(selectedClassId) {
        // fetch all the activities from the selected class
        const resp = await getFromApi(`class/${selectedClassId}/projects`);
        // add the extra option to the array
        resp.push({projectId: 0, projectTitle: "Start a new journal", newJournal: true});
        setAvailableProjects(resp);
      }
    };
    getClassActivities();
  },[selectedClassId])

  // create the activity after a project was selected
  useEffect(()=>{
    createActivity();
  },[selectedProjectId])

  const handleCheckbox = (e) => {
    if(!selectedClassId) {
      // this is the first step when we select a class
      setSelectedClassId(e.target.value)
    } else {
      // set the selected project
      setSelectedProjectId(e.target.value)
    }
  }

  return (
    <div className={styles.container}>
      <h2>{selectedClassId ? "Add verse to journal" : "Add to journal in a class"}</h2>
      <p>{selectedClassId ? "Please select a journal to use with this quote" : "Please select the class to which you wish to assign this new activity"}</p>
      <div className={styles.classBlocks} onChange={(e) => { handleCheckbox(e) }}>
        {selectedClassId && selectedProjectId ?
          <div>
            <h4>creating activity...</h4>
            <img src={spinner} alt="spinner"/>
          </div>
          : 
          selectedClassId && !availableProjects.length ? 
            <div>
              <h4>loading activities...</h4>
              <img src={spinner} alt="spinner"/>
            </div>
            :
            availableProjects.length > 0 ? 
              <React.Fragment>
                {
                  availableProjects.map((item, index) => {
                    return (                
                      <label key={index}>
                        <input type="radio" value={item.projectId} />
                        <div className={`${styles.classBlock} ${item.newJournal ? styles.newJournal : ""}`}>
                          <p>
                            {item.projectTitle}
                          </p>
                        </div>
                      </label>
                    )
                  })
                }
              </React.Fragment>
              : null
        }
        {!availableClasses.length ? 
        
          <div>
          	<h4>loading classes...</h4>
          	<img src={spinner} alt="spinner"/>
          </div>
        :
        <React.Fragment>
        {
          !availableProjects.length &&
          availableClasses.map((item, index) => {
            return (
              <label key={index}>
                <input type="radio" value={item.classId} />
                <div className={styles.classBlock}>
                  <p>
                    {item.className}
                  </p>
                </div>
              </label>
            )
          })
        }
        </React.Fragment>
        }
      </div>

      <div className={styles.buttons}>
        {false && <div onClick={createActivity} className={styles.okBtn}>OK</div>}
        <div onClick={() => { closeBtn.current.click() }} className={styles.cancelBtn}>Cancel</div>
      </div>
    </div>
   );
}

export default JournalFromTextModal;