import React, { useEffect, useState } from 'react'
import styles from "./Settings.module.scss"


import { BiSolidUpArrow, BiSolidDownArrow,  } from  'react-icons/bi'


const SubscriptionSettings = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedValue, setSelectedValue] = useState('monthly');
    const [students, setStudents] = useState(1.00);
    const [total, setTotal] = useState(0);
    const [numberValue, setNumberValue] = useState(0);
    const [workbook, setWorkbook] = useState(0);

    useEffect(() => {
        const totals = students * numberValue;
        setTotal(totals);
      }, [students, numberValue]);

      
  const result = parseFloat(workbook * 6.99);
  const rounded = Math.ceil(result * 100) / 100;

  return (
    <div className={styles.container}>
        <div>
            <form>
                <div className={styles.form}>
                    <div className={styles.input}>
                        <label>Number of students</label>
                        <div className={`${styles.input_word} ${styles.number}`}>
                        <input type="number" name="" value={numberValue}  min={0}
                            onChange={(e) => setNumberValue(e.target.value)}
                            />
                            <div className={styles.arithmetic}>
                            <BiSolidUpArrow size={12}  onClick={() => numberValue < 200 && setNumberValue(Number(numberValue) + 1)} />
                            <BiSolidDownArrow  size={12} onClick={() => numberValue > 0 && setNumberValue(Number(numberValue) - 1)} />
                            </div>
                        </div>

                    </div>
                    <div className={styles.input}>
                        <label>price per student</label>
                        <div className={`${styles.column} ${styles.lookinput}  `}><p>${students}</p></div>
                    </div>
                    <div className={styles.input}>
                        <label>Total price</label>
                        <div className={`${styles.column} ${styles.lookinput} `}><p className={`${styles.price} `}>{`$${total}.00`}</p></div>
                    </div>
                    <div className={styles.input}>
                        <label>Expiration date</label>
                        <div className={`${styles.column} ${styles.lookinput} `}><p className={`${styles.price} `}>May 21, 2021</p></div>
                    </div>
                </div>

                <div className={styles.form}>
                    <div className={styles.input}>
                        <label>Number of Workbooks</label>

                        <div className={`${styles.input_word} ${styles.number}`}>
                            <input type="number" name="" value={workbook} min={0}
                                onChange={(e) => setWorkbook(e.target.value)}
                            />
                            <div className={styles.arithmetic}>
                                <BiSolidUpArrow size={12} onClick={() => setWorkbook(Number(workbook) + 1)}/>
                                <BiSolidDownArrow  size={12} onClick={() => workbook > 0 && setWorkbook(Number(workbook) - 1)} />
                            </div>
                        </div>

                    </div>
                    <div className={styles.input}>
                        <label>price per Workbook</label>
                        <div className={`${styles.column} ${styles.lookinput}  `}><p>$6.99</p></div>
                    </div>
                    <div className={styles.input}>
                        <label>Total price</label>
                        <div className={`${styles.column} ${styles.lookinput} `}><p className={`${styles.price} `}>{`$${rounded}.00`}</p></div>
                    </div>
                </div>

                <div className={styles.notes}>
                    <label>Add notes to this transaction (Just For Internal Use)</label>
                    <input type="text" placeholder='Write Notes Here' />
                </div>

                <div>
                <label className={styles.check}>
                    <input
                        type="checkbox"
                        className={styles.custom_checkbox}
                    />
                    Teacher / Institution requested to pay by check and be invoiced 
                </label>
                </div>

                <div className={styles.btns}>
                    <div className={styles.saveBtn}>
                        Save Changes
                    </div>

                    <div className={styles.userRequest}>
                        Send user request to accept terms
                    </div>
                </div>

            </form>
        </div>
    </div>
  )
}

export default SubscriptionSettings