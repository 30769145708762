import React from 'react';

import styles from './StatusButtonsExplainedModal.module.scss';

const StatusButtonsExplainedModal = ({}) => {
  
  return (
    <div className={styles.container}>
      <h3>Different section stages within an activity</h3>
      <div className={styles.line}>
        <div className={`${styles.actionBtn} ${styles.noColorBtn}`}>Draft</div>
        <p>The activity is not shared with students.</p>
      </div>
      <div className={styles.line}>
        <div className={`${styles.actionBtn} ${styles.blueDarkBtn}`}>Set live</div>
        <p>Makes the activity accessible to students and they can write.</p>
      </div>
      <div className={styles.line}>
        <div className={`${styles.actionBtn} ${styles.blueLightBtn}`}>Schedule</div>
        <p>Makes the activity accessible to students on a specified date.</p>
      </div>
      <div className={styles.line}>
        <div className={`${styles.actionBtn} ${styles.noColorNoBorderBtn}`}>Unshare</div>
        <p>Takes the activity back to Draft.</p>
      </div>
      <div className={styles.line}>
        <div className={`${styles.actionBtn} ${styles.blueLightBtn}`}>End writing</div>
        <p>Students are not allowed to write or submit.</p>
      </div>
      <div className={styles.line}>
        <div className={`${styles.actionBtn} ${styles.blueLightBtn}`}>Start voting</div>
        <div className={`${styles.actionBtn} ${styles.blueLightBtn}`}>End voting</div>
        <p>Controls the voting process (only if Peer Review is activated).</p>
      </div>
      <div className={styles.line}>
        <div className={`${styles.actionBtn} ${styles.blueLightBtn}`}>Close Section</div>
        <p>Marks the section as completed. Students will not be able to continue writing or submit.</p>
      </div>
    </div>
   );
}

export default StatusButtonsExplainedModal;