import React, { useEffect } from 'react';
import { sendToApi } from 'API/generalCalls';

const Logout = () => {
  useEffect(()=>{ 
    sendToApi('auth/logout', '', 'logout')
   }, [])

  return (
    <></>
  )
}

export default Logout;