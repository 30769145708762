import axios from 'axios';

export const getFromApi = async (query, action) => {

  
  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/parents/${query}`
  
  try {
    
    const { data } = await axios.get( url, {headers: { 'Authorization': token || '' } } )

    return data;
    
  } catch (error) {
    console.log(error)
  }
}


export const sendToApi = (query, newData, action, additionalActions) =>{

  
  
  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/parents/${query}`
  
  axios.post(url, newData, {headers: { 'Authorization': token || '' }})

  .then(response => {

    if(action==='addStudent'){
      additionalActions.setApiRes({...additionalActions.apiRes, dashboard:response.data, student:{stage:'complete'}})

    } if (action==='familyPlusSubSuccess'){
      additionalActions(response.data)

    } if(action === 'updateCardInfo'){

      console.log('UPDATE CARD INFO')
      additionalActions(response.data)
    }

  })

  .catch((error) => {
    console.log(error)

  })
    
}



export const sendToApiReturn = async (query, newData) =>{

  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/parents/${query}`
  

  const { data } = await axios.post(url, newData, {headers: { 'Authorization': token || '' }})

  return data
}


export const sendToApiReturnParents = async (query, newData) =>{

  const token = localStorage.getItem('t');
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/parents/${query}`
  
  const { data } = await axios.post(url, newData, {headers: { 'Authorization': token || '' }})

  return data
}

