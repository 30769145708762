
import React,{useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux'

import { getFromApi, sendToApi, sendToApiReturn } from 'API/teacherCalls'
import styles from './CreateNewStudent.module.scss';
import { createStudent } from 'API/TeacherCallFunctions/classFunctions';

const CreateNewStudent = ({closeBtn, item:{ onboarding, classObj, updateStudents }}) => {
  
  const classId = useSelector(state => state.fetchedClass.classId)
  const existingStudents = useSelector(state => state.fetchedClass.students)
  const fjUser = useSelector(state => state.dashData.simplifiedActivity)
  const env = useSelector(state => state.env)
  const [ params, setParams ]= useState({firstName:'', secondName:'', grade:'', parents:['','']})
  const [ selectedClasses, setSelectedClasses ] = useState([classId])
  const [ message, setMessage ] = useState(false);
  const fNameRef = useRef();
  const sNameRef = useRef();
  const gradeRef = useRef();
  const classesRef = useRef();
  const [ availableClasses, setAvailableClasses ] = useState([]);

  useEffect(()=>{
    getClasses();

    if(onboarding){
      setSelectedClasses([classObj.classId])
    } else {
      setSelectedClasses([classId])
    }

  },[])

  const getClasses = async () => { 
    const data = await getFromApi('dashboard');
    setAvailableClasses(data.classes)
  }

  const handleSubmit = async () => {
    
    const payload = {student:[params], classId:selectedClasses}
    const data = await sendToApiReturn('students/create', payload)
    createStudent(data, classId, existingStudents)

      if(!onboarding){
        
        setTimeout(()=>{ 
          updateStudents(params)
          closeBtn.current.click()
        },400)

      } else {

        setTimeout(()=>{       
             
          const frontUrl = localStorage.getItem('frontUrl')
          const URL = `${frontUrl}/teacher/classes/view/${classObj.classId}/?students`
          window.location = URL

          closeBtn.current.click()
          
        },400)
      }
  }
  

  const handleCheckbox = (e) => {
    
    
    let classesCopy = [...selectedClasses]

    if(e.target.checked){
      classesCopy.push(e.target.value)
      setSelectedClasses(classesCopy)
    } else {
      const index = classesCopy.indexOf(e.target.value)
      classesCopy.splice(index, 1)
      setSelectedClasses(classesCopy)
    }
  }

  const checkFields = () => {
    if(params.firstName === '' || params.secondName === '' || params.grade === ''|| !selectedClasses.length) {
      setMessage(true)
      if(params.firstName === ''){fNameRef.current.style.textDecoration='underline red'}
      if(params.secondName === ''){sNameRef.current.style.textDecoration='underline red'}
      if(params.grade === ''){gradeRef.current.style.textDecoration='underline red'}
      if(selectedClasses.length){classesRef.current.style.textDecoration='underline red'}
      return
    } else {
      handleSubmit()
    }
  }
  

  const addMail = (e, index) => {
    let copy = [...params.parents]

    copy[index] = e.target.value;

    setParams({...params, parents:copy})
  }

  return (
    <div className={styles.container}>
      <h1>Create New Student</h1>

      <div className={styles.name}>
        <div className={styles.first}>
          <h2 ref={fNameRef} >First Name</h2>
          <input type="text" onChange={(e)=>{setParams({...params, firstName:e.target.value})}}/>
          {params.firstName !== '' ? <div className={styles.tickDiv}></div> : null}
        </div>
        <div className={styles.second}>
          <h2 ref={sNameRef} >Last Name</h2>
          <input type="text" onChange={(e)=>{setParams({...params, secondName:e.target.value})}}/>
          {params.secondName !== '' ? <div className={styles.tickDiv}></div> : null}
        </div>
      </div>

      <div className={styles.grade}>
        <h2 ref={gradeRef} >Grade</h2>
        <div className={styles.radios} onChange={(e)=>{setParams({...params, grade:e.target.value})}}>
          <label>
            <input type="radio" name="grade" value={2}/>
            <div className={styles.pill}>2</div>
          </label>
          <label>
            <input type="radio" name="grade" value={3}/>
            <div className={styles.pill}>3</div>
          </label>
          <label>
            <input type="radio" name="grade" value={4}/>
            <div className={styles.pill}>4</div>
          </label>
          <label>
            <input type="radio" name="grade" value={5}/>
            <div className={styles.pill}>5</div>
          </label>
          <label>
            <input type="radio" name="grade" value={6}/>
            <div className={styles.pill}>6</div>
          </label>
          <label>
            <input type="radio" name="grade" value={7}/>
            <div className={styles.pill}>7</div>
          </label>
          <label>
            <input type="radio" name="grade" value={8}/>
            <div className={styles.pill}>8</div>
          </label>
          <label>
            <input type="radio" name="grade" value={9}/>
            <div className={styles.pill}>9</div>
          </label>
          <label>
            <input type="radio" name="grade" value={10}/>
            <div className={styles.pill}>10</div>
          </label>
          <label>
            <input type="radio" name="grade" value={11}/>
            <div className={styles.pill}>11</div>
          </label>
          <label>
            <input type="radio" name="grade" value={12}/>
            <div className={styles.pill}>12</div>
          </label>
          {params.grade !== '' ? <div className={styles.tickDiv}></div> : null}
        </div>
      </div>

      <div className={styles.invite}>
        <h2>Invite Parents</h2>
        <p>Invite parent/guardians to manage this student's account and to view their Portfolio.</p>
        <div className={styles.emails} id={'emails'}>
        {
          params.parents.map((item, index) => {
            return(
            <div className={styles.first}>
              <input type="text" placeholder={!fjUser?`parent${index+1}@bw.com`:`parent${index+1}@email.com`} onBlur={(e)=>{addMail(e, index)}}/>
              {params.parents[index] ? <div className={styles.tickDiv}></div> : null}
            </div>
            )
          })
        }
      </div>
      <div className={styles.mailIcon} 
        onClick={()=>{
          setParams({...params, parents:[...params.parents, '']})
          setTimeout(()=>{document.getElementById('emails').scrollTop=document.getElementById('emails').offsetHeight},200)
          
          }}>
      </div>
      </div>

      <div className={styles.classes}>
        <h2 ref={classesRef}>Student's classes</h2>
        {/* <h2 ref={classesRef}>Student's classes({availableClasses.length})</h2> */}
        <div className={styles.classBlocks}  onChange={(e)=>{handleCheckbox(e)}}>

          {
            onboarding ? 

                <label >
                  <input type="checkbox" defaultChecked value={classObj.classId}/>
                  <div className={styles.classBlock}>
                  <p>
                    {classObj.className}
                  </p>
                  </div>
                </label>

            :

              availableClasses.length ?

                availableClasses.map((item,index)=>{
                return(
                  <label key={index}>
                    <input type="checkbox" defaultChecked={item.classId === classId?true:false} value={item.classId}/>
                    <div className={styles.classBlock}>
                    <p>
                      {item.className}
                    </p>
                    </div>
                  </label>
                  )
                }) 
                
              : null
              
          }
          
          
        </div>
      </div>

      <div className={styles.buttons}>
        <div className={styles.btn} onClick={()=>{checkFields()}}>Create</div>
        <div className={styles.btn} onClick={()=>{closeBtn.current.click()}}>Cancel</div>
        {message ? <p>please complete all required fields</p> : null}
      </div>
    </div>
  )
}

export default CreateNewStudent;