import React, {useState, useEffect} from 'react';

import { getFromApi } from 'API/teacherCalls';
import { titleCase } from 'helpers/uiFunctions';
import styles from './RubricModal.module.scss';

const RubricModal = ({item:{rubricName, gradeNumber}}) => {

  const grades = [2,3,4,5,6,7,8,9,10,11,12]
  const rubricNumber = (
    rubricName==='basic' ? 1 :
    rubricName==='argument/opinion' ? 2 :
    rubricName==='information & explanatory' ? 3 :
    rubricName==='narrative' ? 4 : 1
  )

  const [ rubricData, setRubricData ] = useState();
  const [ gradeVal, setGradeVal ] = useState(gradeNumber);
  const [ visibility, setVisibility ] = useState(false);
  
  const callAPI = async (gradeVal, rubricVal) => { setRubricData(await getFromApi(`rubric/${rubricVal}?grade=${gradeVal}`)) }

  useEffect(() => {
    callAPI(gradeVal, rubricNumber); 

    // eslint-disable-next-line
  }, [gradeVal]);

  const handleGrade = (e)=>{ setGradeVal(e.target.value); setVisibility(false) }

  return (
    <div className={styles.container}>
      {rubricData && rubricData[0][0].skill ?
      <React.Fragment>
      <h2>Rubric - {titleCase(rubricName)}</h2>
      <section className={styles.top}>

        <div onClick={()=>{setVisibility(!visibility)}} className={styles.dropDown}>
          <div className={styles.text}> Grade {gradeVal} </div>
          <div className={styles.dropChevron}></div>
          
          <div 
          onMouseLeave={()=>{setVisibility(false)}} 
          className={(visibility ? styles.dropDownOptions : `${styles.dropDownOptions} ${styles.hidden}`)}
          onChange={(e)=>{handleGrade(e)}}>
            {grades.map((grade,index)=>{
                return(
                  <label>
                    <input
                      value={index+2} 
                      type="radio" 
                      name="grade" 
                      defaultChecked={(gradeVal===index+2?true:false)}
                    />
                    <div className={styles.gradeRow}>
                      Grade {grade}
                    </div>
                  </label>
                )
              })
            }
          </div>
        </div>

        <div className={styles.scores}>
          <div className={styles.score}>SCORE 4</div>
          <div className={styles.score}>SCORE 3</div>
          <div className={styles.score}>SCORE 2</div>
          <div className={styles.score}>SCORE 1</div>
        </div>
      </section>

      <section className={styles.main}>
            {
              rubricData.map((item, index)=>{
                return(
                  <div className={styles.tableRow}>
                    <div className={styles.column}>
                    {item[0].skill}
                    </div>
                    <div className={styles.column}>
                      {
                        item[0].criterias[3][0].description.split('<br>').map(item=>{
                            return(
                              <p>{item}</p>
                            )
                        })
                      }
                    </div>
                    <div className={styles.column}>
                      {
                        item[0].criterias[2][0].description.split('<br>').map(item=>{
                            return(
                              <p>{item}</p>
                            )
                        })
                      }
                    </div>
                    <div className={styles.column}>
                      {
                        item[0].criterias[1][0].description.split('<br>').map(item=>{
                            return(
                              <p>{item}</p>
                            )
                        })
                      }
                    </div>
                    <div className={styles.column}>
                      {
                        item[0].criterias[0][0].description.split('<br>').map(item=>{
                            return(
                              <p>{item}</p>
                            )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
      </section>

      </React.Fragment>
      :null}
    </div>
  )

}

export default RubricModal;