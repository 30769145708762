import React, { useState } from 'react';
import { useSelector } from 'react-redux'

import styles from './NewStudentGoogleModal.module.scss';
import img1 from 'assets/login_1.png'
import img2 from 'assets/login_2.png'
import CopyPillTooltip from 'components/UtilityComps/CopyPillTooltip/CopyPillTooltip';

const NewStudentGoogleModal = ({ closeBtn, item:{classObj} }) => {

  const fetchedCode = useSelector(state => state.fetchedClass.classCode);
  const fjUser = useSelector(state => state.dashData.simplifiedActivity)
  const code = !classObj ? fetchedCode : classObj.classCode; //entry via students tab (fetched) or onboarding screen (classObj)

  const redirectUri = 
    !fjUser ? 'https://escribli.com/auth/student' : 'https://faith-journals.com/auth/student'

  const platform = !fjUser?"Escribli":"Faith Journals"

  return (
    <div className={styles.container}>
      <h1>How to use Google sign in</h1>

      <div className={styles.half}>
        <div className={styles.left}>
          <h3>Step 1 - Student login</h3>
          <p>Students need to go to the student login page on <span className={styles.blue}>{platform}</span></p>
          <a href={redirectUri} target="_blank">{redirectUri}</a>
          <p>Or from the main menu, choose <span className={styles.yellow}>'Login'</span>, then <span className={styles.yellow}>'Students'</span></p>
          <p>Students click the <span className={styles.blue}>'Sign in with Google'</span> button and give permission for {platform} to connect to their Google account</p>
        </div>
        <div className={styles.right}>
          <img src={img1} alt="login image" />
        </div>
      </div>
      <div className={styles.half}>
        <div className={styles.left}>
          <h3>Step 2 - Enter class ID</h3>
          <p>Next, your students will need to join the right class by entering the unique class code. You can find this below and on your main dashboard.</p>

          <div className={styles.codePillDiv}>
            <h3>Your group code:</h3>
            <CopyPillTooltip
              blue
              copyData={code} 
              displayData={`ID ${code}`} 
              tooltipText={"click to copy group code"} />
          </div>
        </div>
        <div className={styles.right}>
          <img src={img2} alt="login image2" /></div>
      </div>
      
    </div>
  )  

}

export default NewStudentGoogleModal;