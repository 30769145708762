import { toTitleCase } from 'helpers/toTitleCase';
import React from 'react';

import styles from './AdminFilters.module.scss';
import FilterBlock from './FilterBlock';

const AdminFilters = ({textInput, setTextInput, activeTab, orderBy, activeFilter, setActiveFilter, setMapItems}) => {

  return (
    <div className={styles.filtersBar}>
    <div className={styles.left}>
      <h2>{toTitleCase(activeTab)}</h2>
      <div className={styles.inputDiv}>
        <div className={styles.lupa} />
        {textInput != "" && <div onClick={()=>setTextInput("")} className={styles.clear} />} 
        <input
          onChange={e => setTextInput(e.target.value)} 
          value={textInput}
          className='noFocus' 
          placeholder={`Search ${activeTab}`}
          type="text" />
      </div>
    </div>
    <div className={`${styles.filters}`}>
      <div className={`${styles.filtersIcon}`} />
      <h5>Filters:</h5>
      <FilterBlock 
        textInput={textInput}
        orderBy={orderBy}
        report={activeTab}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        setMapItems={setMapItems}
        filterType={"interval"} />
    </div>
  </div>
  )

}

export default AdminFilters;



    // setMapItems(filterArrayByObject( array, {userName: input}))