import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './EndWritingModal.module.scss';
import { instancesInArray } from 'helpers/uiFunctions';
import { setQueryItem, updateSectionKey, updateActivitySection } from 'Redux/actions';
import { sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import book from 'assets/cover_story.png';

const EndWritingModal = ({ item, hideModal }) => {


  const students = item.students;

  const handleEnd = async () => {

    let res

    if (item.stage === 'writing') {

      res = await sendToApiReturn(`activity/${item.activityId}/section/${item.index}/start_voting`)
      
    } else if (item.stage === 'voting') {
      
        res = await sendToApiReturn(`activity/${item.activityId}/section/${item.index}/end_voting`)
        item.func(res)
        
        if( item.activity.sections.length-1===item.activeSection.sectionIndex && 
            item.activity.activityType!=='assignment'&&
            item.activity.allowExtraChapters ){
          ///show final section modal if ending final section of story or journal
          store.dispatch(setQueryItem({...item, type:'finalSection'}))
      }


    } else if (item.stage === 'in approval' || item.stage === 'in approval-group') {
      
      res = await sendToApiReturn(`activity/${item.activityId}/section/${item.index}/mark_complete`)
    } else {
      return
    }

    store.dispatch(updateActivitySection(item.index, res))
    item.setActiveSection(res)
    hideModal()

  }

  return (
    <div className={item.stage === 'writing' ? `${styles.container} ${styles.bookTip}` : styles.container}>
      { item.stage === 'writing' ?
        <>
          <h1>Voting is ready to start!</h1>
          <div className={styles.warning}>
            <p>Only those entries that have been approved will be included in the voting</p>
          </div>
          <div className={styles.approved}>
            <p>students approved</p>
            <div className={styles.pill}>
              {instancesInArray(students, 'entryStatus', 'approved')} / {students.length}
            </div>
          </div>
          <div className={styles.buttons}>
            <div onClick={hideModal} className={styles.cancel}>Cancel</div>
            <div onClick={handleEnd} className={styles.startVoting}>Start Voting</div>
          </div>
        </>
        : item.stage === 'cantVote' ?
          <>
            <h1>Oops! You can't start voting</h1>
            <div className={styles.warning}>
              <p>You need at least 3 approved entries to start voting</p>
            </div>
            <div className={styles.approved}>
              <p>Students approved</p>
              {students ?
                <div className={styles.pill}>
                 {instancesInArray(students, 'entryStatus', 'approved')} / {students.length}
                </div>
                : null}
            </div>
            <div className={styles.buttons}>
              <div onClick={hideModal} className={styles.startVoting}>Close</div>
            </div>
          </>
        : item.stage === 'voting' ?
          <>
            <h1>End voting now?</h1>
            <div className={styles.warning}>
              <p>Not all students have cast their vote, only those which have will be included in the count</p>
            </div>
            <div className={styles.approved}>
              <p>Votes cast</p>
              {students ?
                <div className={styles.pill}>
                  {item.voted} / {students.length}
                </div>
                : null}
            </div>
            <div className={styles.buttons}>
              <div onClick={hideModal} className={styles.cancel}>Cancel</div>
              <div onClick={handleEnd} className={styles.startVoting}>End Voting</div>
            </div>
          </>
          : item.stage === 'in approval' ?
            <>
              <h1 style={{marginTop:'30px'}}>Are you sure? </h1>
              <div className={`${styles.warning} ${styles.blank}`}>
                <p>The section will be closed but you can re-open it at any time</p>
              </div>
              <div className={styles.buttons}>
                <div onClick={hideModal} className={styles.cancel}>Cancel</div>
                <div onClick={handleEnd} className={`${styles.startVoting} ${styles.red}`}>Close Section</div>
              </div>
            </>
            : item.stage === 'in approval-group' ?
              <>
                <h1 style={{marginTop:'30px'}}>Are you sure? </h1>
                <div lassName={`${styles.warning} ${styles.blank}`}>
                  <p>The section will be closed permanently</p>
                </div>
                <div className={styles.buttons}>
                  <div onClick={hideModal} className={styles.cancel}>Cancel</div>
                  <div onClick={handleEnd} className={`${styles.startVoting} ${styles.red}`}>Close Section</div>
                </div>
              </>
              : null
      }
    </div>

  )
}

export default EndWritingModal;