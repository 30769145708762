
import React from 'react'
import logoFaith from 'assets/nav/logo_nav-faith.svg';

export const returnPlatformStyle = (platformCode) => {

  if(platformCode==='fj') return 'faithJournal'
  
}

export const renderPlatformLogo = (platformCode) => {

  if(platformCode==='fj') return <img className={'faithJournalLogo'} src={logoFaith} alt="Faith Journal Logo" /> 

}