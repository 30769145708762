import React, { useState } from 'react';
import cog from 'assets/cog_black.svg';
import arrow from 'assets/arrow_teal.svg';

import styles from './SetupChapterModal.module.scss';
import SetupButtons from './SetupButtons';
import PeerReviewModal from '../../ActivityDetailsSidebar/SettingsModals/PeerReviewModal/PeerReviewModal';
import GrammarReportModal from '../../ActivityDetailsSidebar/SettingsModals/GrammarReportModal/GrammarReportModal';
import WordBankModal from '../../ActivityDetailsSidebar/SettingsModals/WordBankModal/WordBankModal';
import GradingModal from '../../ActivityDetailsSidebar/SettingsModals/GradingModal/GradingModal';
import UploadFilesModal from '../../ActivityDetailsSidebar/SettingsModals/UploadFilesModal/UploadFilesModal';
import DeadlineModal from '../../ActivityDetailsSidebar/SettingsModals/DeadlineModal/DeadlineModal';
import BreakoutRoomsModal from '../../ActivityDetailsSidebar/SettingsModals/BreakoutRoomsModal/BreakoutRoomsModal';

const SetupChapterModal = ({ item:{ viewProp, activeSection, activity, modalOverlay, isStoryStart, fjUser } }) => {

  const [ view, setView ] = useState(viewProp ? viewProp : 'setup');
  const [ localActiveSection, setLocalActive ] = useState(activeSection)

  const commonProps = {
    activity,
    modalOverlay,
    mobile:true,
    activeSection:localActiveSection,
    setActiveSection:setLocalActive,
    setModal:setView
  }

  return (
    <div className={styles.container}>
      {
        !viewProp &&
          <div className={styles.topBar}>
            {
              view === 'setup' ?
                <>
                  <img src={cog} alt="cog" />
                  <h5>Set up chapter</h5>
                </>
              :
                <div onClick={()=>setView('setup')} className={styles.backDiv}>
                  <img src={arrow} alt="arrow" />
                  <h5>Back</h5>
                </div>
            }
          </div>
      }

      <div className={styles.sectionBar}>
        <div className={styles.title}>
          <div className={styles.circle}>{activeSection.sectionIndex+1}</div>
          <h5>{activeSection.sectionName}</h5>
        </div>
      </div>

      <div className={styles.mainSwitch}>
        {
          view === 'setup' ? 
            <SetupButtons isStoryStart={isStoryStart} setView={setView} fjUser={fjUser}/>

          : view === 'wordbank' ?
            <WordBankModal {...commonProps} />

          : view === 'upload' ?
            <UploadFilesModal {...commonProps} />

          : view === 'peer' ?
            <PeerReviewModal {...commonProps} />

          : view === 'deadline' ?
            <DeadlineModal {...commonProps} />

          : view === 'grammar' ?
            <GrammarReportModal {...commonProps} />

          : view === 'rooms' ?
            <BreakoutRoomsModal {...commonProps} />
      
          : view === 'grading' ?
            <GradingModal {...commonProps} />

          :null

        }
      </div>
    </div>
  )

}

export default SetupChapterModal;