import { format } from 'date-fns';
import React, { useState } from 'react';
import { Calendar } from 'react-modern-calendar-datepicker';

import styles from './DeadlineModalResponsive.module.scss';

const DeadlineModalResponsive = ({ makeDateObject, sectionStart, sectionDue, localDate, setLocalDate, handleSubmit, startBlocked }) => {

  const [ isEnd, toggleIsEnd ] = useState(startBlocked)

  const [ dateRange, setRange ] = useState({
    from: makeDateObject(new Date(sectionStart)),
    to: makeDateObject(new Date(sectionDue))
  });


  const handleDateChange = (val, end) => {
    console.log(val)
    const { day, month, year } = val.from
    const dateStr = `${year}-${month>9?month:`0${month}`}-${day>9?day:`0${day}`}`
    const newDate = new Date(dateStr)
    setLocalDate({...localDate, [!end ? 'sectionStart' : 'sectionDue']:newDate})
    setRange({...dateRange, [!end ? 'from' : 'to']:val.from})
  }
  
  return (
    <div className={styles.container}>
      <Calendar
        value={dateRange}
        // calendarClassName={startBlocked && 'Calendar__blocked'}
        onChange={val => handleDateChange(val, isEnd)}
        minimumDate={new Date()} />

      <div className={styles.dates}>
        <label>
          <input type="radio" name="dateType" checked={!isEnd}/>
          <div onClick={()=>{ !startBlocked && toggleIsEnd(false)}} className={`${styles.dateDiv} ${startBlocked && styles.blocked}`}>
            <div className={styles.textDiv}>
              <p>Start date</p>
             { startBlocked && <div className={styles.padlock} />}
             { (isEnd && !startBlocked) && <p className={styles.sml}> click to select</p>}
            </div>
            <h5>{format(new Date(localDate.sectionStart), 'eee, MMM d')}</h5>
            <div className={styles.selectedBlock} />
          </div>
        </label>
        <label>
          <input type="radio" name="dateType" checked={isEnd}/>
          <div onClick={()=>toggleIsEnd(true)} className={styles.dateDiv}>
            <div className={styles.textDiv}>
              <p>End date</p>
             { !isEnd && <p className={styles.sml}> click to select</p>}
            </div>
            <h5>{format(new Date(localDate.sectionDue), 'eee, MMM d')}</h5>
            <div className={styles.selectedBlock} />
          </div>
        </label>

      </div>

      <div onClick={handleSubmit} className={styles.btn}>
        Save Dates
      </div>

    </div>
  )

}

export default DeadlineModalResponsive;