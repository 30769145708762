import { sendToApiReturn } from 'API/teacherCalls';
import { students } from 'assets/initialStudents-activity';
import { studentStatusFilters } from 'helpers/filterFunctions';
import { letters } from 'helpers/letters';
import { instancesInArray, showModal } from 'helpers/uiFunctions';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import Leaderboard from './Leaderboard/Leaderboard';

import styles from './OverviewTable.module.scss';
import OverviewTableRow from './OverviewTableRow';

const OverviewTable = ({ modalOverlay, fjUser, mobile, setActiveSection, showLeaderboard, activeSection, activeSection: {
  entries, sectionIndex  }}) => {

    const [ displayStudents, setDisplayStudents ] = useState([])
    const [ roomGroups, setRoomGroups ] = useState([])
    // const [ showLeaderboard, toggleLeaderboard ] = useState()
    const [isApproveAll, setApproveAll] = useState(false)
    const [filterValues, setFilterValues] = useState({text:'', radio:'all', key:'status'})
    const activityData = useSelector(state => state.activityData)
    const searchRef = useRef()
    const inputRef = useRef()


  const sortRooms = (students, rooms) => {

    //identify students whose IDs are not contained in the activeSection.rooms arrays
    //and assign them to the displayStudents array
    const mergedRooms = [].concat.apply([], rooms)
    setDisplayStudents(students.filter(s=>!mergedRooms.includes(s.studentId)))

    //identify grouped students and assign them as arrays of objects to the roomGroups 
    //array to be rendered together on list
    const groupedStudents = rooms.reduce((output, room) =>{
      return [...output, room.map(id => students.find(student=>student.studentId===id))]
    },[])
    
    setRoomGroups(groupedStudents)

  }

  const handleApproveAll = async (e) => {
    setApproveAll(true)
    const data = await sendToApiReturn(`activity/${activityData.activityId}/section/${sectionIndex}/approve_entries`)
    data.forEach((item, index) => {
      setTimeout(()=>{
        const itemId = item.studentId;
        const switcheroo = document.getElementById(`switch${itemId}`)
        if(item.approved && switcheroo){ switcheroo.click() }
      },10 * index)
    })
    setActiveSection({...activeSection, entries: data})
    setTimeout(()=>{ setApproveAll(false) }, 2500)
  }

  const anySubmitted = (input) => {
    const check = (item) => item.entryStatus==='submitted' ? true : false;
    return input.some(check) 
  }

  const filterItems = 
      [
      {value:"all", text:"All entries", filterKey:"all", classKey:"all"},
      {value:"approved", text:"Approved", filterKey:"approved", classKey:"approved"},
      {value:"pending revision", text:"Pending revision", filterKey:"pending revision", classKey:"pending"},
      {value:"submitted", text:"Submitted", filterKey:"submitted", classKey:"submitted"},
      {value:"started", text:"Started", filterKey:"started", classKey:"started"},
      {value:"not started", text:"Not started", filterKey:"not started", classKey:"not"},
      ]

  useEffect(()=>{
    if(activeSection) {
      // toggleLeaderboard(activeSection.voting&&activeSection.sectionStatus==='completed'&&activeSection.leaderboard?.length);
      // console.log('LEADERBOARD')
      // console.log(activeSection.voting&&activeSection.sectionStatus==='completed'&&activeSection.leaderboard?.length)
      // // setDisplayStudents(activeSection.entries)
      sortRooms(activeSection.entries, activeSection.rooms)
    }
  },[activeSection])

  const openBreakoutRoom = (room, idx) => {
    showModal(modalOverlay,{
      type:'breakoutRoom', 
      students:activeSection.entries, 
      activity:activityData,
      idOne:room[0].studentId, 
      idTwo:room[1].studentId, 
      idThree:room[2]?.studentId, 
      roomName:letters[idx], 
      section:activeSection,
      setActiveSection:setActiveSection
    })
  }


  return (
    <div className={styles.container}>

      {
        !showLeaderboard ?

          <div className={styles.filtersBar} >

            <div ref={searchRef}
              onClick={() => { searchRef.current.classList.toggle(styles.open); inputRef.current.focus() }}
              className={`${styles.search}`}>
              <div className={styles.inner}>
                <div className={styles.lupa} />
                <input onChange={e => setFilterValues({ ...filterValues, text: e.target.value })} ref={inputRef} autoFocus type="text" name="search input" />
              </div>
            </div>

            <div onChange={e => setFilterValues({ ...filterValues, radio: e.target.value })} className={styles.filterRadios}>
              {
                filterItems.map((item,idx) => 
                <label key={idx}>
                  <input defaultChecked={idx===0} type="radio" name="filter" value={item.value} />
                  <div className={`${styles.radio} ${styles[item.classKey]}`}>
                    <p className={styles.count}>{idx===0 ? entries.length : instancesInArray(entries, 'entryStatus', item.filterKey)}</p>
                    <p>{item.text}</p>
                  </div>
                </label>
                )
              }
            </div>

            <div onClick={handleApproveAll} className={`${styles.approveAll} ${anySubmitted(entries) && styles.enabled}`}>
              <p>Approve All</p>
            </div>

          </div>

        :

          <div className={styles.leaderboardDiv}>
            {
              activeSection?.leaderboard &&
                <Leaderboard 
                  winners={activeSection.leaderboard}
                  students={activeSection.entries} 
                />
            }
          </div>
      }
     

      <div className={styles.tableHeader}>
        <div className={styles.column}>Student Name</div>
        <div className={`${styles.column} ${fjUser && styles.wide}`}>Words</div>
        <div className={`${styles.column} ${fjUser && styles.wide}`}>Status</div>
        <div className={`${styles.column} ${fjUser && styles.wide}`}>Approved</div>
        {
          !mobile && 
            <>
              <div className={`${styles.column} ${fjUser && styles.wide}`}>Voted</div>
              {/* {!fjUser && */}
               <div className={styles.column}>Grade</div>
              {/* // } */}
            </>
        }
      </div>

      <div className={`${styles.tableRows} ${showLeaderboard && styles.withLeaderboard}`}>
        {

            //   with search text or radio input, display search results without breakout room formatting
            filterValues.text || filterValues.radio!=='all' ? 
            
              activeSection.entries
                .sort((a,b) => b.submitted - a.submitted)
                .sort((a,b) => b.approved - a.approved)
                .map((entry,idx)=> 
                  studentStatusFilters(entry,filterValues.text, filterValues.radio, 'entryStatus') && entry &&
                    <OverviewTableRow
                      mobile={mobile}
                      modalOverlay={modalOverlay}
                      key={idx}
                      studentIndex={idx}
                      rowData={entry || { studentBoomerUrl:"", studentName:"", studentId:"", approved:"", voted:"", wordCount:"", finalGrade:"", entryStatus:"", submitted:""}}
                      fjUser={fjUser}
                      activeSection={activeSection}
                      setActiveSection={setActiveSection}
                      isApproveAll={isApproveAll} />
              )

          :

            <>
              
              {
                //  without search input, display grouped students in their breakout rooms, and then un-grouped students below

                //  grouped students:
                roomGroups.map((group, idx) =>
                  <div key={idx} className={styles.roomGroup}>
                    <div onClick={()=>openBreakoutRoom(group, idx)} className={`${styles.roomTab} ${styles[`group_${letters[idx]}`]}`}> <p>Group {letters[idx]}</p> </div>
                    <div className={styles.rows}>
                      {
                        group.map((student,idx) =>
                          <OverviewTableRow
                            modalOverlay={modalOverlay}
                            students={displayStudents}
                            mobile={mobile}
                            key={idx}
                            rowData={student || { studentBoomerUrl:"", studentName:"", studentId:"", approved:"", voted:"", wordCount:"", finalGrade:"", entryStatus:"", submitted:""}}
                            fjUser={fjUser}
                            activeSection={activeSection}
                            setActiveSection={setActiveSection}
                            isApproveAll={isApproveAll} />
                          )
                      }
                    </div>
                  </div>
                  
                )
              }
              {
                //   ungrouped students:
                displayStudents
                  .sort((a,b) => b.submitted - a.submitted)
                  .sort((a,b) => b.approved - a.approved)
                  .map((entry,idx)=> 
                  studentStatusFilters(entry,filterValues.text, filterValues.radio, 'entryStatus') &&
                    <OverviewTableRow
                      modalOverlay={modalOverlay}
                      students={displayStudents}
                      mobile={mobile}
                      key={idx}
                      rowData={entry || { studentBoomerUrl:"", studentName:"", studentId:"", approved:"", voted:"", wordCount:"", finalGrade:"", entryStatus:"", submitted:""}}
                      fjUser={fjUser}
                      activeSection={activeSection}
                      setActiveSection={setActiveSection}
                      isApproveAll={isApproveAll} />
                )
              }

            </>
            
        }
      </div>


    </div>
  )

}

export default OverviewTable;










  // //identify grouped students and assign them as arrays of objects to the roomGroups 
  //   //array to be rendered together on list
  //   const groupedStudents = []
  //   rooms.forEach(arr =>{
  //     let group = []
  //     for(let idx=0; idx<arr.length;idx++){
  //       group.push(students.find(student=>student.studentId===arr[idx]))
  //     }
  //     groupedStudents.push(group)
  //   })
    
  //   setRoomGroups(groupedStudents)



  // //identify grouped students and assign them as arrays of objects to the roomGroups 
  //   //array to be rendered together on list
  //   const groupedStudents = rooms.reduce((output, room) =>{
  //     return [...output, room.map(id => students.find(student=>student.studentId===id))]
  //   },[])
    
  //   setRoomGroups(groupedStudents)