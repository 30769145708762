import React, { useState } from 'react';

import styles from './AddExistingTeacherModal.module.scss';

const AddExistingTeacherModal = () => {

  const [ emails, setEmails ] = useState(['']);
  const [ contStage, setContStage ] = useState('init')

  return (
    <div className={contStage === 'init' ? styles.container : `${styles.container} ${styles.sent}`}>
      <div className={styles.title}>
        <h1>Add existing teacher</h1>
        <h6>Invite existing teachers to your school</h6>
      </div>

      <div className={styles.emails}> 
        <label>Email:</label>
        {
          emails.map((item, idx) => {
            return(
              <input 
              type="email" 
              name={`email${idx}`} 
              placeholder="email@escribli.com"
              onChange={(e)=>{let newArr=[...emails]; newArr[idx]=e.target.value; setEmails(newArr); console.log(newArr); console.log(e.target.value)}}
              />
            )
          })
        }
        <p onClick={()=>{setEmails([...emails, ''])}}> <span>+</span> Add another invite</p>
      </div>

      <div className={styles.btns}>
        <div className={styles.btn}>Cancel</div>
        <div className={styles.btn}>Send Invites</div>
      </div>

    </div>
  )

}

export default AddExistingTeacherModal;