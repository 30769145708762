import axios from 'axios';
import {store} from '../../storeConfig';



export const getChat = async () => {
  
  const backUrl = localStorage.getItem('backUrl')
  const chatUrl = `${backUrl}/api/chat/teacher`
  const token = localStorage.getItem('t')
  

  try {
    const { data } = await axios.get( chatUrl, {headers: { 'Authorization': token || '' } } )

    return data.students;

  } catch (error) {
    console.log(error)
  }
}


export const sendChat = (data, setter) => {
  
  const token = localStorage.getItem('t')
  const backUrl = localStorage.getItem('backUrl')
  const chatUrl = `${backUrl}/api/chat/teacher`
  const env = store.getState().env;

  axios.post(`${chatUrl}/save`, data,
    { headers: { 'Authorization': token || '' }
    }
  )

  .then((response) => {
      console.log(response)
    }
  )
  .catch((error) => console.log(error));
}


export const markAsRead = (id) => {

  const token = localStorage.getItem('t')
  const backUrl = localStorage.getItem('backUrl')
  const chatUrl = `${backUrl}/api/chat/teacher`

  console.log(`${chatUrl}/mark_as_seen/${id}`)
  console.log(` headers: { 'Authorization': ${token} || '' }`)

  axios.post(`${chatUrl}/mark_as_seen/${id}`, {},
    { headers: { 'Authorization': token || '' } }
  )
  .catch((error) => console.log(error));
}



