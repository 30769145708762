import format from 'date-fns/format';
import React from 'react';
import { useState } from 'react';

import styles from './MainTopBar.module.scss';
import iconWhite from 'assets/timeline_white.svg'
import iconBlue from 'assets/timeline_blue.svg'
import { useRef } from 'react';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';

const MainTopBar = ({ entryData: { studentBoomerUrl, studentName, approved, submitted, revisions, voted, events }}) => {

  const stage =  (voted && !approved) ? 5 : (voted & approved) ? 4 : approved ? 3 : revisions ? 2 : submitted ? 1 : 0
  const lineClass = stage > 4 ? 'five' : stage > 3 ? 'four' : stage > 2 ? 'three' :  stage > 1 ? 'two' : null
  const timelineRef = useRef()

  const [ showTimeline, toggleTimeline ] = useState(false)
  
  useOutsideClick(timelineRef, () => toggleTimeline(false))

  return (
    <div className={styles.container}>

      <div className={styles.studentBlock}>
        <img src={studentBoomerUrl} alt="student avatar" />
        <p>{studentName}</p>
      </div>

      <div className={`${styles.progressDots}`}>
        <div className={styles.line}> <div className={`${styles.inner} ${styles[lineClass]}`} /> </div>
        <div className={styles.step}>
          <div className={`${styles.dot} ${(stage>0&&stage<5) && styles.complete}`}> <p>1</p> </div>
          <h5>Submitted</h5>
        </div>
        <div className={styles.step}>
          <div className={`${styles.dot} ${(stage>1&&stage<5) && styles.complete}`}> <p>2</p> </div>
          <h5>Revisions</h5>
        </div>
        <div className={styles.step}>
          <div className={`${styles.dot} ${(stage>2&&stage<5) && styles.complete}`}> <p>3</p> </div>
          <h5>Approved</h5>
        </div>
        <div className={styles.step}>
          <div className={`${styles.dot} ${stage>3 && styles.complete}`}> <p>4</p> </div>
          <h5>Voted</h5>
        </div>
      </div>

      <div ref={timelineRef} onClick={()=>toggleTimeline(!showTimeline)} className={`${styles.btnDiv} ${showTimeline && styles.open}`}>
        <div className={styles.timelineBtn}>
          <img src={showTimeline ? iconWhite : iconBlue } alt="icon" />
          <p>{!showTimeline ? "View" : "Close"} timeline</p>
        </div>
        <div className={`${styles.timelineDiv}`}>
          {
            events.length ? 

                events.map((item,idx) => 
                  <div key={idx} className={styles.eventRow}>
                    <p>{item.description}</p>
                    <div className={styles.dateDiv}>
                      <p>{format(new Date(item.date), 'LLL dd')}</p>
                    </div>
                  </div> 
                )
            :

                <div className={styles.eventRow}>
                  <p className={styles.placeholder}>No events to display</p>
                </div> 


          }
        </div>

      </div>

    </div>
  )

}

export default MainTopBar;