import React, {useState, useRef} from 'react';
import styles from './PdfGenerationModal.module.scss';
import loader from 'assets/loader-sm2.svg';
import cloud from 'assets/icon-cloud--purple.svg'
import { store } from 'storeConfig';
import { setQueryItem } from 'Redux/actions';
import { useSelector } from 'react-redux';


const PdfGenerationModal = ({closeBtn, item:{students, mobile, activity:{activityName, activityType, activityId, sections}}}) => {

  const [ selectedStudents, setSelectedStudents ] = useState([])
  const [ selectedContent, setSelectedContent ] = useState([])
  const [ instructions, setInstructions ] = useState(false)
  const [ feedback, setFeedback ] = useState(false)
  const [ details, setDetails ] = useState(false)
  const [storyStart, setStoryStart] = useState(false)

  const fjUser = useSelector(state => state.dashData.simplifiedActivity)

  const [response, setResponse ] = useState('initial')
  const loaderRef=useRef()
  const linkRef = useRef();
  const contentRef = useRef()
  const elementsRef = useRef()
  const allUnitsRef = useRef()


  const handleOk = () => {
    

    let newArray=[]

    if(mobile){
      sections.forEach(item=> newArray.push(parseInt(item.sectionId)))
    }
    
    if(selectedStudents.length && (selectedContent.length||newArray.length)){
      
      contentRef.current.classList.remove(styles.show)
      elementsRef.current.classList.remove(styles.show)
      loaderRef.current.classList.add(styles.showLoader)

      const payload = {
        studentId:selectedStudents,
        units:mobile ? newArray : selectedContent,
        storyStart:mobile ? true : storyStart,
        instructions:mobile ? true : instructions,
        feedback:mobile ? true : feedback,
        class_details:mobile ? true : details
      }

      store.dispatch(setQueryItem({type:'pdfRender', payload:payload, activityId:activityId, activityName:activityName}))
    
    }
  }
  
  
  const handleDownload = () => {
  closeBtn.current.click();
  response.func()
}

    
  const handleStudent = (e) => {
    
    let studentsCopy = [...selectedStudents]

    console.log(e.target.value)
    if(e.target.checked){
      studentsCopy.push(parseInt(e.target.value))
      setSelectedStudents(studentsCopy)
    } else {
      const index = studentsCopy.indexOf(parseInt(e.target.value))
      studentsCopy.splice(index, 1)
      setSelectedStudents(studentsCopy)
    }

    {!mobile && contentRef.current.classList.add(styles.show)}
  }

  const handleContent = (e) => {

  
    if(e.target.value==='all'){

      let newArray
      const contentDivs = document.querySelectorAll(['[data-content="content"]'])

      if(!e.target.checked){
        contentDivs.forEach(item => { if(item.checked) {item.checked=false}})
        setSelectedContent([''])
        setStoryStart(false)
        
      }else{
        newArray=[]
        sections.forEach(item=> newArray.push(parseInt(item.sectionId)))
        contentDivs.forEach(item => { if(!item.checked) {item.checked=true}})
        setStoryStart(true)
        setSelectedContent(newArray)
        
      }
      

    }else if(e.target.value==='storyStart'){
      setStoryStart(!storyStart)
      return
    }else if(e.target.value!=='all') {

      let contentCopy = [...selectedContent]
      console.log(e.target.value)
      if(e.target.checked){
        contentCopy.push(parseInt(e.target.value))
        setSelectedContent(contentCopy)
      } else {
        const index = contentCopy.indexOf(parseInt(e.target.value))
        contentCopy.splice(index, 1)
        setSelectedContent(contentCopy)
      }
      if(allUnitsRef.current.checked){allUnitsRef.current.checked=false}

    }


    elementsRef.current.classList.add(styles.show)
  }

  const handleElement= (e) => {
    if(e.target.value==='details'){
      setDetails(!details)
    }else if(e.target.value==='feedback'){
      setFeedback(!feedback)
    }else if (e.target.value==='instructions'){
      setInstructions(!instructions)
    }
  }
  
  return (
    <div className={styles.container}>

    <h2>Export your students' writing to a printable PDF</h2>
    <p>Choose which student(s) work you want to print:</p>
      <div className={styles.studentBlocks} onChange={(e) => { handleStudent(e) }}>
        {!students.length ? 
        
        activityType.type==='story' ? <h3>No student entries found for this chapter</h3> : <h3>No student entries found for this section</h3>
        
        :
        <React.Fragment>
        {
          students.map((item, index) => {
            return (
                  <label key={index}>
                    <input type="checkbox" value={item.studentId} />
                    <div className={styles.studentBlock}>
                      <p>
                        {item.studentName}
                      </p>
                    </div>
                  </label>
            )
          })
        }
        </React.Fragment>
        }
      </div>
      <div ref={contentRef} className={styles.contentSelection}>

      <p>Choose what content you want to print from this project:</p>
      <div className={styles.contentBlocks}  onChange={(e) => { handleContent(e) }}> 
      {!sections.length ? 
        
          activityType.type==='story' ? <h3>No content found for this story</h3> : <h3>No content found for this activity</h3>
          
          :
          <React.Fragment>
          <label>
          <input ref={allUnitsRef} type="checkbox" value="all" />
          <div className={styles.studentBlock}>
            <p>
              All { activityType === "story" ? "chapters" :  "sections"}
            </p>
          </div>
        </label>

        {
          activityType === "story" &&
              <label>
              <input type="checkbox" value="storyStart"  data-content="content"/>
              <div className={styles.studentBlock}>
                <p>
                  Story start
                </p>
              </div>
            </label>
        }

        {
          sections.map((item, index) => {
            return (
                  <label key={index}>
                    <input type="checkbox" value={item.sectionId}  data-content="content"/>
                    <div className={styles.studentBlock} >
                      <p>
                        {item.sectionName}
                      </p>
                    </div>
                  </label>
            )
          })
        }
        </React.Fragment>
        }
      </div>
      </div>

      <div ref={elementsRef} className={styles.elementsSelection}>

      <p>Choose which elements you want to include in the PDF:</p>
        <div className={styles.elementBlocks} onChange={(e)=>{handleElement(e)}}> 
          <label>
            <input type="checkbox" value="instructions" />
            <div className={styles.studentBlock} >
              <p>
                Instructions
              </p>
            </div>
          </label>
          <label>
            <input type="checkbox" value="feedback" />
            <div className={styles.studentBlock}>
              <p>
                Teacher Feedback
              </p>
            </div>
          </label>
          {/* <label>
            <input type="checkbox" value="details" />
            <div className={styles.studentBlock}>
              <p>
                class & teacher details
              </p>
            </div>
          </label> */}
        </div>
      </div>

      <div className={styles.buttons}>
        <div onClick={handleOk} className={styles.okBtn}>OK</div>
        <div onClick={() => { closeBtn.current.click() }} className={styles.cancelBtn}>Cancel</div>
      </div>

        <div ref={loaderRef} className={styles.loaderDiv}>
          {response==='initial' ?
          <div className={styles.loaderInner}>
            <img src={loader} alt="loader"/>
            <h4>Generating PDF...</h4>
          </div>
        :
        <div className={styles.readyMsg}>
          <img src={cloud} alt="ready"/>
          <p>Your {activityType} is ready to be downloaded</p>
          <div className={styles.btn} onClick={handleDownload} ref={linkRef}>download</div>
        </div>
          }
        </div>
    </div>
  )

}

export default PdfGenerationModal;