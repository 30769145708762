import React, { useState } from 'react';
import defaultPic1 from 'assets/nav/defaultPic1.svg'
import styles from './ResourceItem.module.scss';
import { showModal} from 'helpers/uiFunctions';
import deleteIcon from 'assets/icon-delete.svg';
import { useSelector } from 'react-redux';
import { returnPlatformStyle } from 'helpers/altPlatformFunctions';

import{BsQuestionCircleFill} from 'react-icons/bs';

import lock from './lock.svg'

const ResourceItem = ({ item, modalOverlay, resourceItems, setResourceItems, fjUser }) => {
  const [isHover, setIsHover] = useState(false);

  const dashData = useSelector(state => state.dashData)

  return (
    <div className={`${styles.container}`} >
      
        {/* { 
        
          dashData.teacherId !== item.teacherId ? */}

            <div className={styles.overlay}>
              <div  onClick={()=>{
                showModal(modalOverlay,
                  {type:'resourceItem', 
                  activity:item}
                )
              }} 
              className={styles.prevBtn}>Preview</div>
            </div>

          {/* : null
        
        } */}

        {
          item.free ? (
            <div className={`${styles.tab} ${styles[item.activityType]} ${fjUser&&styles[returnPlatformStyle('fj')]}`}></div>
          ) : (
            <div className={`${styles.tab} ${styles.sophia}`}></div>
          )
        }

      <h3 className={styles.resourceName}>{item.activityName}</h3>

      <div className={styles.author}>
        <img src={item.imageUrl !== "" ? item.imageUrl : defaultPic1} alt="author"/>
        <p className={styles.resourceP}>By {dashData.teacherId !== item.teacherId ? item.author : "you"}</p>
      </div>

      <div className={styles.pills}>
       

        <div>
          {fjUser && item.free === true ?
            <div className={`${styles.widePill} ${styles[item.activityType]} ${fjUser&&styles[returnPlatformStyle('fj')]}`}>
            {!fjUser?item.activityType:'Journal'}
            </div>
           :
           
            fjUser && (
              <div  className={`${styles.sophia} ${styles.widePill}`}>Sophia Institute</div>
            )
           
           } 
        </div>

        {
          !fjUser &&
            <div className={`${styles.widePill} ${styles.darkBlue}`}>
              {item.activitySubject === 'History-Social Studies' ? 'History' : item.activitySubject}
            </div>
        }

      </div>

      <p className={styles.resourceP}>{item.activityType === 'story' ? 
        'number of chapters: ' 
          : 'number of sections: '} 
        {item.sections.length}
      </p>

      {
        item.free === false && (
          <div className={styles.absolute}>
            <span>
              <img src={lock} alt="lock" className={styles.lock} />
            </span>
            <span className={styles.question}>
              <BsQuestionCircleFill color='#D2D2D2'  size={17} />
                <div className={styles.tooltip}>
                  Sophia Workbooks are available for purchase for $6.99 per student (one time purchase) 
                  You can click to preview it. <br />
                  You will be able to select a classroom where you would like to use it and will only pay for the students in that class.
                </div> 

            </span>
          </div>
        )
      }

      {
        !fjUser &&
          <div className={styles.grades}>
            <p className={styles.resourceP}>Grades:</p>
            <div className={styles.gradePills}>
            {item.shareGrade.length === 11 || !item.shareGrade.length ?         
              <div className={styles.widePill}>
                All grades
              </div>
              :  
              item.shareGrade.map(grade=>{
                return(
                  <div className={styles.gradeDot}>
                    {grade}
                  </div>
                )
              })
            }
            </div>
          </div>
      }
  
    {dashData.teacherId === item.teacherId ?

      <div onClick={()=>{showModal(modalOverlay,{
        type:'remove', 
        variant:'resourceItem',
        resourceItems:resourceItems,
        setResourceItems:setResourceItems,
        activityId:item.activityId
        })}} className={styles.delete}>
        <img src={deleteIcon} alt="delete"/>
      </div>

     :null
    }

    </div>
  )

}

export default ResourceItem;