import React from 'react';

import styles from './TeacherCredit.module.scss';

const CreditSummaryBlock = ({ creditData: { referrals, referalCredits, completedReferrals, completedCredits, bookPurchases, bookPurchaseCredits, entriesApproved, entryCredits }}) => {

  return (
    <div className={styles.creditSummaryBlock}>
      <h1 className={styles.black}>Summary</h1>

      <div className={styles.row}>
        <div className={styles.column}></div>
        <div className={styles.column}>#</div>
        <div className={`${styles.column}`}><p className={styles.creditsHeader}> Credits </p></div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>Referrals (free trial)</div>
        <div className={styles.column}>{referrals}</div>
        <div className={styles.column}>
          <div className={styles.creditsCount}>{referalCredits}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>Completed Referrals</div>
        <div className={styles.column}>{completedReferrals}</div>
        <div className={styles.column}>
          <div className={styles.creditsCount}>{completedCredits}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>Student Book Purchase</div>
        <div className={styles.column}>{bookPurchases}</div>
        <div className={styles.column}>
          <div className={styles.creditsCount}>{bookPurchaseCredits}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>Student Entries Approved</div>
        <div className={styles.column}>{entriesApproved}</div>
        <div className={styles.column}>
          <div className={styles.creditsCount}>{entryCredits}</div>
        </div>
      </div>
    </div>
  )

}

export default CreditSummaryBlock;