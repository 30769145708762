import styles from './CalendarComp.module.scss'

  export const eventClick = (info, env)  => {

    const { activityId, classId } = info.event.extendedProps

    const frontUrl = localStorage.getItem('frontUrl')
    const URL = `${frontUrl}/teacher/classes/${classId}/${activityId}`
    window.location = URL
    
  }


  export const initCalendarTeacher = (classes, styles, setInitialEvents, setEvents, setClassLetters) => {
    const initEv = [];
    const keyLetters = [];

    classes.forEach(item =>{

      const firstLetter = item.className.charAt(0);
      const keyLetter = 
        !keyLetters.includes(firstLetter) ? firstLetter 
        : !keyLetters.includes(`${firstLetter}${firstLetter}`) ? `${firstLetter}${firstLetter}`
          : !keyLetters.includes(`${firstLetter}${firstLetter}${firstLetter}`) ? `${firstLetter}${firstLetter}${firstLetter}`
            : `${firstLetter}${firstLetter}${firstLetter}${firstLetter}`;
      keyLetters.push(keyLetter);

      if(item.activities && item.activities!=='initial'){
        item.activities.forEach(activity => {
  
          const sectionIndex = parseInt(activity.sectionNumber.charAt(0));
          const sectionTitle = activity.sections && activity.sections.length ? 
          activity.sections[sectionIndex-1]?.sectionName : 'Section';

          const classKey = keyLetter.length> 1 ? `<p class=${styles.toThePower}>${keyLetter.charAt(0)}<sup>${keyLetter.length}</sup></p>` : keyLetter
  
          const payload = {
            title:activity.activityName,
            description:`${sectionTitle} (S-${sectionIndex})`,
            id:activity.activityId,
            start:activity.sectionStart,
            end:activity.sectionDue,
            classId: item.classId,
            keyLetter: classKey,
            activityId: activity.activityId,
            className: `${styles.event} 
                        ${styles[activity.activityType]}  
                        ${styles[activity.sectionStatus.replaceAll(' ', '_')]} 
                        `,
          }
          
          initEv.push(payload)})
      }
    })
    setInitialEvents(initEv)
    setEvents(initEv)
    setClassLetters(keyLetters)
  }

  export const addDetailsElements = ({ event, el }) => {

    var newDiv = document.createElement('div')
    newDiv.innerHTML = event._def.extendedProps.keyLetter;
    // newDiv.innerHTML = `<p class=${styles.toThePower}>x<sup>2</sup></p>`;
    newDiv.classList.add(styles.keyDot)
    el.appendChild(newDiv);

    if(el.children[0].classList[0] ==='fc-daygrid-event-dot'){return}

    const textDiv = el.children[0].children[0].children[0];
    var newSpan = document.createElement('span')
    newSpan.innerHTML = event._def.extendedProps.description;
    textDiv.appendChild(newSpan);
  };
