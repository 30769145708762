import React, { useEffect, useState } from 'react';
import { BiSolidUpArrow, BiSolidDownArrow,  } from  'react-icons/bi'
import { format } from 'date-fns';
import { useParams, useHistory } from 'react-router';

import styles from './Subscription.module.scss';
import AccountSettingsBlock from './AccountSettingsBlock';
import PaymentDetailsBlock from './PaymentDetailsBlock';
import { getFromApi } from 'API/teacherCalls';
import { LoadingOverlay } from 'components';

import { showModal } from 'helpers/uiFunctions';

import spirit from "../Teacher/Resources/truth1.svg"

import { sendToApiReturn } from 'API/teacherCalls';
import SwitchButton from 'components/Teacher/Pricing/Switch';
import { useUserType } from 'context/AltPlatformProvider';

const Subscription = ({ modalOverlay }) => {

  const [ apiRes, setApiRes ] = useState(null);
  const { isAdmin } = useParams()
  
  const [selectedValue, setSelectedValue] = useState('monthly');
  const [students, setStudents] = useState(1.00);
  const [total, setTotal] = useState(0);
  const [numberValue, setNumberValue] = useState(0);
  const [workbook, setWorkbook] = useState(0);
  const [empty, setEmpty] = useState(false)
  const history = useHistory();
  const { fjUser } = useUserType() 



  const handleChange = () => {
    const newSelectedValue = selectedValue === 'monthly' ? 'annual' : 'monthly';
    setSelectedValue(newSelectedValue);
  };

  useEffect(() => {
    if (selectedValue === 'monthly') {
      setStudents(1);
    } else {
      setStudents(10);
    }
  }, [selectedValue]);

  useEffect(() => {
    const totals = students * numberValue;
    setTotal(totals);
  }, [students, numberValue]);


  const callApi = async () => setApiRes(await getFromApi('plus_details'));

  const handleDirect = () => history.push('/teacher/account/subscriptiondetails')

  useEffect(()=>{
    callApi();
    console.log(isAdmin ? 'ADMIN USER' : 'NON-ADMIN USER');
  },[]);

  const handlePurchase = async () => {
    try {
      const details = {
        numberOfStudents: numberValue,
        sophiaWorkbooks: workbook,
        yearly: selectedValue === 'monthly' ? false : true
      };
  
      const res = await sendToApiReturn('plus_purchase_credits', details);
      handleDirect()
      if(res.ok){
        console.log('ok')
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  
  const switchToMonthly = async () => {
    setApiRes(await sendToApiReturn(`plus_monthly`, {}))
  }
  
  const switchToYearly = async () => {
    setApiRes(await sendToApiReturn(`plus_yearly`, {}))
  }

  const reactivateSubscription = async () => {
    setApiRes(await sendToApiReturn(`plus_reactivate`, {}))
  }



  const result = parseFloat(workbook * 6.99);
  const rounded = Math.ceil(result * 100) / 100;




  return !apiRes ? <LoadingOverlay blueSpinner blueText loadingText={"Fetching subscription data..."} /> : (
    <div className={`${styles.container} ${fjUser && styles.fjBg} ${!fjUser && styles.escribg}`}>
      <div className={styles.titleBar}>
        <div className={styles.heading}>
          <h1>Subscription</h1>
          <div className={styles.price_options} onClick={()=>showModal(modalOverlay, {type:'pricingOptions', setSubData:setApiRes})}>
            See pricing options
          </div>
        </div>

        {
          apiRes.currentSub.active && (
            <div className={styles.note}>
              <br />
              <p>
                
                <strong>{apiRes.currentSub.yearly? 'Annual' : 'Monthly'} </strong> 
                subscription for {apiRes.currentSub.numberOfSeats} students valid until 
                <strong> {format(new Date(apiRes.currentSub.renewalDate), 'MMMM do, yyyy')}.</strong>
              </p>
              <br />
              {
                fjUser &&
                <>
                <p>
                  {apiRes.currentSub.availableSophiaCredits} Sophia Institute Workbooks  available for use from the library
                  ({apiRes.currentSub.purchasedSophiaCredits - apiRes.currentSub.availableSophiaCredits} workbooks used of {apiRes.currentSub.purchasedSophiaCredits} purchased).
                </p>
                <br />
                 
            </>
              }
           
              
          </div>
          )
        }
          
      </div>

      <div className={styles.instructions_link}>
            {
                fjUser ?
                <a  href="https://es-courses-static.s3.amazonaws.com/subscription-terms.pdf"  target="_blank" rel="noopener" className={styles.faithlink}>
                  <em>
                    See <strong>Faith Journal Subscription Terms</strong>
                  </em>
                </a>
              
                :
                <a  href="https://es-courses-static.s3.amazonaws.com/escribli-subscription-terms-2023-2024"  target="_blank" rel="noopener" className={styles.faithlink}>
                  <em>
                    See <strong>Escribli Subscription Terms</strong>
                  </em>
                </a>
              
              }
      </div>
      <div className={`${styles.current} ${styles.block}`}>
        {apiRes.currentSub.fixedPrice && <h4>Next Payment</h4>}

        <div className={`${styles.table} ${styles.current}`}>
          <div className={styles.tableHeader}>
            <div className={`${styles.column} ${styles.leftPadding}`}><h5></h5></div>
            <div className={styles.column}><h5>Subscription Type</h5></div>
            <div className={`${styles.column} ${styles.hideRes}`}><h5>Students*</h5></div>
            <div className={styles.column}><h5> Price Per Student</h5></div>
            <div className={styles.column}><h5>Total</h5></div>
          </div>


          <div className={styles.tableRow}>
            <div className={styles.column}>
              <p className={styles.responsiveDate}>Additional Student Subscriptions</p>
            </div>
            <div className={`${styles.column} ${styles.hideRes}`}>
              <p className={`${styles.price} ${styles.light}`}>
                  <SwitchButton selectedValue={selectedValue} handleChange={handleChange} />
              </p></div> 
            <div className={`${styles.column} ${styles.input}`}>
                <input type="number" name="" value={numberValue}  min={0}
                  onChange={(e) => setNumberValue(e.target.value)}
                />
                <div className={styles.arithmeticxx}>
                  <BiSolidUpArrow size={10}  onClick={() => numberValue < 200 && setNumberValue(Number(numberValue) + 1)} />
                  <BiSolidDownArrow  size={10} onClick={() => numberValue > 0 && setNumberValue(Number(numberValue) - 1)} />
                </div>
            </div>
            <div className={`${styles.column} ${styles.input} ${styles.hideRes}`}><p>${students}.00</p></div>
            <div className={`${styles.column} ${styles.input} `}><p className={`${styles.price} `}>{`$${total}.00`}</p></div>
          </div>
        </div>
        <br />
        <br />

        { fjUser &&
        <> 
          <div className={`${styles.table} ${styles.current}`}>
            <div className={`${styles.tableHeader} ${styles.sophia}`}>
              <div className={`${styles.column} ${styles.leftPadding}`}><h5>SOPHIA INSTITUTE FOR TEACHERS </h5></div>
              <div className={`${styles.column}`}></div>
              <div className={`${styles.column} ${styles.hideRes}`}><h5>WORKBOOKS**</h5></div>
              <div className={styles.column}><h5> Price Per Workbook</h5></div>
              <div className={styles.column}><h5>Total</h5></div>
            </div>


            <div className={styles.tableRow}>
              <div className={styles.column}>
                <p className={styles.sophiahead}>
                  Sophia Institute Workbooks
                </p>
                <img src={spirit} alt="sophia" className={styles.sophiaimg} />  

              </div>
              <div className={`${styles.column}`}></div>
              <div className={`${styles.column} ${styles.input}`}>
                <div className={`${styles.input_word} ${styles.number}`}>
                  <input type="number" name="" value={workbook} min={0}
                    onChange={(e) => setWorkbook(e.target.value)}
                  />
                  <div className={styles.arithmetic}>
                    <BiSolidUpArrow size={10} onClick={() => setWorkbook(Number(workbook) + 1)}/>
                    <BiSolidDownArrow  size={10} onClick={() => workbook > 0 && setWorkbook(Number(workbook) - 1)} />
                  </div>
                </div>
              </div>
              <div className={`${styles.column} ${styles.hideRes} ${styles.input} `}><p>$6.99</p></div>
              <div className={`${styles.column} ${styles.input}`}><p className={`${styles.price} `}>{`$${rounded}`}</p></div>
            </div>
          </div>
        </>
}
        {
          !apiRes.currentSub.cancelledAt ?
            // <p className={styles.italic}>You will be charged on {format(new Date(apiRes.currentSub.renewalDate), 'MMMM  d, yyyy')}</p>
              apiRes.currentSub.yearly ? 
                <div>
                  <p className={styles.italic}>You pay $135 every 12 months.</p>
                  <p className={styles.cancelBtn} onClick={switchToMonthly}>Switch to $15 monthly payment</p>
                </div>
              : 
                apiRes.currentSub.fixedPrice ? 
                <div>
                  <p className={styles.italic}>You pay based on your usage at the end of each month.</p>
                  <p className={styles.cancelBtn} onClick={switchToYearly}>Switch to $135 for 12 months</p>
                </div>
                : 
                <div></div>
          :
            <div>
              <p className={styles.ending}>Your subscription will end on {format(new Date(apiRes.currentSub.cancelledAt), 'MMMM  d, yyyy')}.</p>
              <p className={styles.cancelBtn} onClick={reactivateSubscription}>Reactivate Subscription</p>
            </div>

        }
        
        <div className={styles.bottomTable}>
          <div  className={styles.subheading}>
              <p>
                  *The student number will be calculated at the end of the month based on the number of students in active classrooms
                  For annual subscriptions, if more students are added throughout the year, new charges will appear on the credit card for the full period.
                  <br />
              </p>
              { 
                fjUser &&  
                 <p>
                   **Sophia Workbooks need to be purchased for entire classrooms/groups. If more workbooks than the initially purchased are used, additional charges will appear in the credit card.
                    <br />
                    You will be able to select the specific workbooks you desire to use from the library of Resources.
                 </p>
                 }
            </div>
           { 
           fjUser ?
           <div className={styles.left}>
                 {
                    apiRes.paymentMethod=== "****" || numberValue ===  0 &&  workbook === 0 ?
                   <div className={styles.disabledBtn}>
                    Confirm Payment
                  </div>
                  :
                  <div className={`${styles.acceptBtn} `} onClick={handlePurchase}>
                    Confirm Payment
                  </div>
                  }
                  <br />
            </div>
            :
            <div className={styles.left}>
            {
               apiRes.paymentMethod=== "****" || numberValue ===  0  ?
              <div className={styles.disabledBtn}>
               Confirm Payment
             </div>
             :
             <div className={`${styles.acceptBtn} `} onClick={handlePurchase}>
               Confirm Payment
             </div>
             }
             <br />
          </div>
            }


          </div>
          { apiRes.paymentMethod=== "****" && (
                    <p className={styles.caution}>Please add a credit card to be able to purchase a subscription</p>
            )}
      </div>

      {
        fjUser && (
          <div style={{ textAlign: "left", margin: "10px 0px"}}>
              <div >
              <p style={{ color: "#858585"}}>
                <em>
                ** Contact us at info@faith-journals.com if your institution would prefer to be <strong>invoiced</strong>.
                </em>
              </p>
              </div>
              <p style={{ color: "#858585", marginTop: "10px", marginBottom: "20px"}}>
                <em>
                *** Contact us if your institution needs multiple teachers so we can include them in the same institution account.
                </em>
              </p>
          </div>
        )
      }
      <br />


      <PaymentDetailsBlock empty={empty} lastFourDigits={apiRes.paymentMethod} updateCard={input=>setApiRes({...apiRes, paymentMethod:input})} />

      { isAdmin && <AccountSettingsBlock email={apiRes.invoice.email} updateEmail={input=>setApiRes({...apiRes, invoice:input})}/> }
      
      <div className={`${styles.history} ${styles.block}`}>
        <h4>Payment History</h4>
        <div className={`${styles.table} ${styles.history}`}>
          <div className={styles.tableHeader}>
            <div className={`${styles.column} ${styles.leftPadding}`}><h5>Date</h5></div>
            {
              fjUser && <div className={`${styles.column} ${styles.hideRes}`}><h5>Workbooks</h5></div>

            }
            <div className={`${styles.column} ${styles.hideRes}`}><h5>Students</h5></div>
            <div className={styles.column}><h5>Amount</h5></div>
            <div className={styles.column}><h5>Status</h5></div>
          </div>
          { 
            !apiRes.paymentHistory.length ? 
              <h5 className={styles.placeholder}>No payment history to display</h5>
            :


            apiRes.paymentHistory.map((item, idx) =>
              <div key={idx} className={styles.tableRow}>
              {/* <div className={styles.column}><p>{format(new Date(item.date), 'MMMM  d, yyyy')}</p></div> */}
              <div className={styles.column}><p>{format(new Date(item.paidAt), 'MMMM do, yyyy')}</p></div>
              {
                  fjUser && (
                    <div className={`${styles.column} ${styles.hideRes}`}><p>{item.purchasedSophiaCredits}</p></div>
                )
              }
              <div className={`${styles.column} ${styles.hideRes}`}><p>{item.activeStudents}</p></div>
              <div className={styles.column}><p>{`$${item.amount}`}</p></div>
              <div className={styles.column}>
                <p className={`${styles.statusPill} ${styles[item.status]}`}>{item.status}</p>
              </div>
            </div>
            )
          }
        </div>

      </div>
      
        {apiRes.currentSub.allowCancel  &&
          <p className={styles.cancelBtn} onClick={()=>showModal(modalOverlay, {type:'cancelSubscription', setSubData:setApiRes})}>Cancel my subscription</p>
        }

    </div>
  )

}

export default Subscription;