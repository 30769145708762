import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Manage.module.scss'
import { initialStudents }from 'assets/initialStudents';
import ManageStudents from './Students/ManageStudents';
import ManageTeachers from './Teachers/ManageTeachers';

const ManageSchool = ({modalOverlay, fjUser}) => { 

  const [ view, setView ] = useState( window.location.search && window.location.search === '?students'?'students':'teachers');
  
  let teacherArray = [...new Set(initialStudents.map(obj => obj.teacherName))];
  let gradeArray = [...new Set(initialStudents.map(obj => obj.grade))];
  
  return (
    <div className={styles.container}>

      <div className={styles.top}>
        
        <div className={styles.breadcrumb}>
          <Link to={"/teacher/admin/dashboard"}>Admin Dashboard</Link>
          <span className={styles.slash}>/</span>
          <h6>Teachers & Students</h6>
        </div>

        <div className={styles.viewSelector} 
        onChange={(e)=>{
          setView(e.target.value)
          window.history.replaceState(null, null, `?${e.target.value}`);
          }}>
          <label>
            <input type="radio" name="viewSelect" id="teacherView" value='teachers' defaultChecked={view==='teachers'?true:false}/>
            <div className={styles.viewRadio}>Teachers</div>
          </label>
          <label>
            <input type="radio" name="viewSelect" id="studentView" value='students' defaultChecked={view==='students'?true:false}/>
            <div className={styles.viewRadio}>Students</div>
          </label>
        </div>
      </div>


      {view !== 'teachers' ?
       
        <ManageStudents
          fjUser={fjUser}
          modalOverlay={modalOverlay}
          students={initialStudents}
          teacherArray={teacherArray}
          setView={setView} /> 
          
      : 

        <ManageTeachers
          fjUser={fjUser}
          modalOverlay={modalOverlay}
          gradeArray={gradeArray} />  

      }
  
    </div>
  );
}

export default ManageSchool;






