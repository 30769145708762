import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { getFromApi } from 'API/teacherCalls';
import { getCsv } from 'API/TeacherCallFunctions/csvGen';

import StudentsEmptyList from './StudentsEmptyList.jsx'
import styles from './ClassStudents.module.scss';
import { LoadingOverlay } from 'components';
import ClassStudentRow from './ClassStudentRow.jsx';
import { filterArrayByObject, normalize } from 'helpers/filterFunctions.js';
import { useLayoutEffect } from 'react';

import { showModal } from '../../../../helpers/uiFunctions';

const ClassStudents = ({ autoTrigger, modalOverlay, classData, setClassData, students, setStudents, synod }) => {

  const [ showAddModal, toggleAddModal ] = useState(false)
  const [filterVals, setFilterVals] = useState({ text: '', radio: 'all' });
  const { classId } = useParams();
  const searchRef = useRef()
  const inputRef = useRef()
  const modalRef = useRef()

  const toggleInnerModal = action =>{
    toggleAddModal(!showAddModal)
    if (classData.onFreePlan && classData.allStudentsCount >= 5) {
      showModal(modalOverlay, {type:'upgradePlan'})
    } else {
      if(!action){ modalRef.current.classList.toggle(styles.display)}
      else {modalRef.current.classList[action](styles.display)}
    }
  }

  useLayoutEffect(()=>{
    if(autoTrigger) toggleInnerModal()
  },[])

  return (
    <div className={styles.container}>
      {
        !students ?

          <LoadingOverlay
            contained
            loadingText={'loading student data...'} />

        : students==='initial' ?
        
          <StudentsEmptyList 
            modalOverlay={modalOverlay}
            classId={classId}
            students={students}
            setStudents={setStudents}
            classData={classData} />
          
        :

        <>

          <div className={styles.main}>

            <div className={styles.topBar}>
              <div className={styles.filters}>

                <div ref={searchRef} className={`${styles.search}`}>
                  <div className={styles.inner}>
                    <div className={styles.lupa} />
                    <input
                      // onFocus={e => console.log(e)}  
                      onFocus={() => { searchRef.current.classList.add(styles.open)}}  
                      onBlur={() => { searchRef.current.classList.remove(styles.open)}}  
                      onChange={e => setFilterVals({ ...filterVals, text: e.target.value })} 
                      ref={inputRef} autoComplete={"off"} type="text" name="search input" />
                  </div>
                </div>

              </div>
              <div onClick={()=>toggleInnerModal()} className={styles.addStudentBtn}>
                <p>Add Students</p>
              </div>
            </div>

            <div className={`${styles.tableCont} ${synod && styles.synod}`}>
              <div className={`${styles.tableHeader} ${synod && styles.synod}`}>
                <div className={styles.column}><p>Student Name</p></div>
                <div className={styles.column}><p>Parent</p></div>
                <div className={styles.column}><p>Escribli Coins</p></div>
                <div className={styles.column}></div>
              </div>
              <div className={styles.rowsContainer}>
                {
                  students.map((student,idx)=>
                    (student?.studentName && normalize(student.studentName).includes(normalize(filterVals.text))) && 
                      <ClassStudentRow 
                        synod={synod}
                        setClassData={setClassData}
                        classData={classData}
                        key={idx} 
                        idx={idx}
                        classId={classId}
                        modalOverlay={modalOverlay}
                        zIndex={students.length - idx}
                        updateStudents={input=>setStudents(input)}
                        student={student} />)
                }
                {
                 ( students?.length  && filterArrayByObject(students, {studentName:filterVals.text}).length < 1) &&
                    <div className={styles.placeholder}>
                      <h5>No students matching search criteria</h5>
                    </div>
                }
              </div>
            </div>

          </div>
          
          <div className={styles.right}>
            <h2>Class login codes</h2>
            <p>Download CSV with class login codes</p>
            <div onClick={() => { getCsv(`teachers/class/${classId}/logins_csv`, classData.className) }}  className={styles.downloadCSVbtn}><p>Download</p></div>
          </div>

          {
            !showAddModal &&
              <div onClick={()=>toggleInnerModal()} className={styles.addBtnResponsive}>
                <h5>Add</h5>
              </div>
          }

        </>

        }

        <div ref={modalRef} className={styles.newStudentModal}>
          <div className={styles.inner}>

          <div className={styles.close} onClick={()=>toggleInnerModal('remove')} />

            <StudentsEmptyList
              windowed
              toggleInnerModal={toggleInnerModal}
              modalOverlay={modalOverlay}
              classId={classId}
              students={students}
              setStudents={setStudents}
              classData={classData}/>
              
          </div>
        </div>
    </div>
  )

}

export default ClassStudents;