import { getFromApi } from 'API/teacherCalls';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import refreshIcon from '../../../../../../assets/refresh.svg'
import styles from './SidebarBreakoutRooms.module.scss';
import spinner from 'assets/spinner-sml--fast.svg';
import ReactQuill from 'react-quill';

const SidebarBreakoutRooms = ({ writingRoom, sectionIndex, activityId, studentId}) => {

  const [ entryData, setEntryData ] = useState(null)
  const [ displayIdx, setDisplayIdx ] = useState(0)
  const dropdownRef = useRef()
  const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T']

  const fetchEntryData = async (roomIds) => {
    const studentOne = await getFromApi(`activity/${activityId}/section/${sectionIndex}/entry/${roomIds[0]}`)
    setEntryData([studentOne])
    if(roomIds[1]) setEntryData([studentOne, await getFromApi(`activity/${activityId}/section/${sectionIndex}/entry/${roomIds[1]}`)])
  }
  
  const toggleDrop = () => { if(writingRoom.roomIds?.length>1 && dropdownRef.current) dropdownRef.current.classList.toggle(styles.open)}

  const handleRefresh = () => {
    setEntryData(null)
    fetchEntryData(writingRoom.roomIds)
  }

  useEffect(()=>{
    setDisplayIdx(0)
    if(writingRoom.roomIds){
      setTimeout(()=>{
        fetchEntryData(writingRoom.roomIds)
        
      },1000)
    }
  },[writingRoom])
  
  
  useEffect(()=>{ toggleDrop() },[displayIdx])
  
  return (
    <div className={styles.container}>
      { 

        writingRoom === 'initial' ?


          <div className={styles.placeholderDiv}>
            <h2>No Breakout Room assigned for this student</h2>
            <p>You can assign them a Breakout Room on the features toolbar, which is located on the right side of the Instructions window.</p>
          </div>

        :

          !entryData || !entryData[displayIdx] ? 
              <div className={styles.spinnerDiv}>
                <img src={spinner} alt="spinner" />
                <h6>retreiving entry data...</h6>
              </div>

        :

        <>
          <div className={styles.topBar}>

            <div className={styles.left}>
              <img src={entryData[displayIdx].studentBoomerUrl} alt="student avatar" />

              <div ref={dropdownRef} onClick={toggleDrop} className={`${styles.dropdown} ${ writingRoom.roomIds[1] && styles.chevron}`}>
                <p>{entryData[displayIdx].studentName}</p>
                <div onMouseLeave={toggleDrop} className={styles.dropdownItems}>
                  {
                    entryData.map((i, idx) => 
                      <div onClick={()=>{setDisplayIdx(idx)}} className={`${styles.dropdownItem} ${idx === displayIdx && styles.selected}`}><p>{i.studentName}</p></div>
                    )
                  }
                </div>
              </div>
            </div>

            <div onClick={handleRefresh} className={styles.refreshBtn}>
              <img src={refreshIcon} alt="refresh" />
              <p>Refresh</p>
            </div>
          </div>

          <ReactQuill
            readOnly
            className={`${styles.entryText}`}
            value={entryData[displayIdx].entryHtml }
            modules={{toolbar:false}} />

          {/* <div className={`${styles.entryText}`} style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: entryData[displayIdx].entryText}}/> */}

          <div className={styles.roomTab}>
            <div className={styles.icon} />
            <p>Group {letters[writingRoom.writingRoom]}</p>
          </div>

        </>
        
      }
    </div>
  )

}

export default SidebarBreakoutRooms;