import { getFromApi } from 'API/teacherCalls';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import Masonry from 'react-masonry-css';
import { useSelector } from 'react-redux';
import { updateDashData } from 'Redux/actions';
import { store } from 'storeConfig';
import DashGroupBlock from '../DashGroupBlock/DashGroupBlock';

import styles from './DashboardClasses.module.scss';

const DashboardClasses = ({ view, modalOverlay }) => {

  const classes= useSelector(state => state.dashData.classes)

  const archivedClasses = useSelector(state => state.dashData.archivedClasses)

  const callApi = async () => store.dispatch(updateDashData('archivedClasses', await getFromApi('dashboard?archived=true')))

  useEffect(()=>{
    if(view==='archive')callApi()
  },[view])

  return (
    <div className={styles.container}>
      {
        view === 'active' ? 
          classes.length > 0 ? 
            <Masonry
              breakpointCols={{ default: 2, 1000: 1 }} 
              className={'masonry-grid'}
              columnClassName={"masonry-grid_column"}>
                {
                  classes.map((item,idx) =>  
                    <DashGroupBlock 
                      modalOverlay={modalOverlay}
                      key={idx}
                      idx={idx}
                      item={item} />
                  )
                } 
            </Masonry>
            :
            <div className={styles.noClassPlaceholder}>
              <div  className={styles.face} />
              <p>You don't have any groups.</p>
            </div>
        :
        ///<---- archived classes view switch
          archivedClasses === null ? 
            <h5>loading</h5>
            : !archivedClasses.length ?
              <div className={styles.noClassPlaceholder}>
                <h5 className={styles.grey}>No archived groups</h5>
              </div>
              :
              <Masonry
                breakpointCols={{ default: 2, 1000: 1 }} 
                className="masonry-grid"
                columnClassName={"masonry-grid_column"}>
                  {
                    archivedClasses.map((item,idx) =>  
                      <DashGroupBlock 
                        modalOverlay={modalOverlay}
                        archive
                        key={idx}
                        idx={idx}
                        item={item} />
                    )
                  }
              </Masonry>
        ////<------
      }
    </div>
  )

}

export default DashboardClasses;