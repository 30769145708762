import { addAnimation } from 'helpers/uiFunctions';
import React, { useRef, useState } from 'react';

import styles from './OnboardingModal.module.scss';

const ResponsiveInfoInput = ({ students, setStudents, type }) => {

  const initialState = {firstName:"",lastName:"",email:"",parentEmail:""};
  const [ studentDetails, setStudentDetails ] = useState(initialState);
  const emailRef = useRef();
  
  const handleInput = (key, value) => {
    setStudentDetails({...studentDetails, [key]:value});
    students[students.length-1][key] = value
    setStudents([...students]);
  }

  const handleRemove = idx => {
    setStudentDetails(initialState)
    setStudents(
        students.length == 1 ? [{}]
      :
        [
        ...students.slice(0, idx),
        ...students.slice(idx+1)
        ]
    )
  }

  const handleSubmit = () => {

    if(type!=="inPerson" && !studentDetails.email){
      console.log('click'); 
      return addAnimation(emailRef, 'shakeSlow', 400)
    }

    console.log(studentDetails)
    setStudents(students[0].firstName ? [...students, {initialState}] : [studentDetails]);
    setStudentDetails(initialState);
    
  }

  return (
    <div className={styles.responsiveInfoInput}>
      {
        students[0].firstName &&
          <>

          <div className={styles.studentList}>
            <h2>Student List</h2>
            {
              students.map((student,idx) =>
                <div key={idx} className={styles.studentRow}>
                  <div className={styles.text}>
                    <h5>{student.firstName} {student.lastName}</h5>
                    {student.email && <p>{student.email}</p>}
                  </div>
                  <div onClick={()=>handleRemove(idx)} className={styles.removeBtn} />
                </div>
              )
            }
          </div>
          </>
      }
      <div className={styles.infoBar}>
        <h2>Student Info</h2>
        <div className={styles.details}>
          <p>Users will receive an access link to the provided emails</p>
        </div>
      </div>

      <div className={styles.inputs}>
        <label>Name
          <input onChange={e=>handleInput("firstName", e.target.value)} value={studentDetails.firstName}  placeholder={"Student name"} type="text" name="name"/>
        </label>
        <label>Last Name
          <input  onChange={e=>handleInput("lastName", e.target.value)} value={studentDetails.lastName}  placeholder={"Student last name"} type="text" name="lastName"/>
        </label>
        <label>Email {type !== "inPerson" && <span>*</span>}
          <input ref={emailRef} onChange={e=>handleInput("email", e.target.value)} value={studentDetails.email}  placeholder={"student@email.com"} type="text" name="email"/>
        </label>
        <label>Parent Email
          <input  onChange={e=>handleInput("parentEmail", e.target.value)} value={studentDetails.parentEmail}  placeholder={"parent@email.com"} type="text" name="parentEmail"/>
        </label>
      </div>

      <div onClick={handleSubmit} className={styles.addBtn}>
        Add student
      </div>

      {type!=="inPerson" && <p>At least one email should be provided to send the link</p>}

      <h3>Select group or groups to add students</h3>
    </div>
  )

}

export default ResponsiveInfoInput;