import React, { useState } from 'react'
import styles from './SubscriptionModal.module.scss'
import { BiSolidDownArrow } from 'react-icons/bi'
import SubscriptionSettings from './SubscriptionSettings'
import Institution from './Institution'
import PaymentHistory from './PaymentHistory'
const AdminSubscriptionModal = () => {
  const [showSubscription, setShowSubscription] = useState(false)
  const [showHistory, setShowHistory] = useState(false)


  return (
    <div className={styles.container}>
      <h4 className={styles.header}>
        <strong> Subscription Details</strong> - anne@gmail.com
      </h4>
      <div className={styles.header_info}>
          <p>
              <strong>Monthly</strong> subscription for <strong>25</strong> students valid until <strong>August 28, 2021</strong>
          </p>
          <p>
              <strong>13</strong> Sophia Institute Workbooks  available for use from the library
              (<strong>7</strong> workbooks used of <strong>20</strong> purchased)
          </p>
          <div className={styles.editBtn} onClick={() => setShowSubscription(!showSubscription)}>
              Edit Subscription
          </div>
      </div>
      {
        showSubscription &&  <SubscriptionSettings/>
      }
     

      <div>
         <div className={styles.showMethodBtn}  onClick={() => setShowHistory(!showHistory)}>
          See payment method <BiSolidDownArrow size={10}/>
         </div>
         {
          showHistory && <PaymentHistory />
         }
      </div>

      <div className={styles.institution}>
          <h4>Institution settings </h4>
          <Institution />
          <div>

          </div>
          <div className={styles.editBtn}>
              Save changes
          </div>
      </div>

    </div>
  )
}

export default AdminSubscriptionModal