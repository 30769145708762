import React, {useState} from 'react';

import styles from './Resources.module.scss';
import { ResourceItem } from 'components';
import { useEffect } from 'react';
import { resourceItemFilter } from 'helpers/filterFunctions';
import { getFromApi } from '../../../API/teacherCalls';
import { useParams } from 'react-router-dom';
import { showModal } from 'helpers/uiFunctions';
import { returnPlatformStyle } from 'helpers/altPlatformFunctions';

import truth from './truth.svg'
import truth1 from './truth1.svg'

const Resources = ({ modalOverlay, fjUser }) => {
  const { activityIdParam } = useParams();
  const [filterValues, setFilterValues] = useState({
    activityType: 'all',
    subject: 'all',
    schoolId: '',
    sections: '',
    grade: 'all',
    special: 'all',
    textInput: '',
    free: false,
  });
  const [responseReceived, setResponseReceived] = useState(false);
  const [resourceItems, setResourceItems] = useState([]);
  const [mySchoolId, setMySchoolId] = useState();
  const [isFreeOnly, setIsFreeOnly] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { mySchool, resources } = await getFromApi('resources');
        setResourceItems(resources);
        setMySchoolId(mySchool);
        setResponseReceived(true);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };

    fetchData();

    if (activityIdParam) {
      setTimeout(() => {
        showModal(modalOverlay, {
          type: 'resourceItem',
          activityId: activityIdParam,
        });
      }, 300);
    }
  }, [activityIdParam, modalOverlay]);

  const limitCheckbox = (item, variant) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [variant]: item.value === filterValues[variant] ? 'all' : item.value,
    }));
  };

  const handleMulti = (e, variant) => {
    setFilterValues((prevValues) => {
      const newArr = [...(prevValues[variant] === 'all' ? [] : prevValues[variant])];

      if (e.target.checked) {
        newArr.push(e.target.value);
      } else {
        const index = newArr.indexOf(e.target.value);
        newArr.splice(index, 1);
      }

      return {
        ...prevValues,
        [variant]: newArr.length ? newArr : 'all',
      };
    });
  };

  return (
    <div className={styles.container}>
      {/* ... (left sidebar content) */}
      <div className={styles.left}>
        {
          mySchoolId && mySchoolId > 0 && 
          <div>
            <h2>School</h2>
            <div className={styles.schoolRadios}>
              <label>
                <input type="checkbox" onClick={(e)=>{limitCheckbox(e.target, 'schoolId')}} name="schoolId" value={mySchoolId}/>
                <div className={styles.pillRadio}>My school</div>
              </label>
              <label>
                <input type="checkbox" onClick={(e)=>{limitCheckbox(e.target, 'schoolId')}} name="schoolId" value=''/>
                <div className={styles.pillRadio}>All schools</div>
              </label>
            </div> 
          </div>
        }

        {!fjUser &&
        <>
          <h2>Type</h2>
          <div className={styles.typeRadios} >
            <label>
              <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'activityType') }} name="activityType" value='assignment' />
              <div className={styles.pillRadio}>Assignment</div>
            </label>
            <label>
              <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'activityType') }} name="activityType" value='story' />
              <div className={styles.pillRadio}>Story</div>
            </label>
            <label>
              <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'activityType') }} name="activityType" value='journal' />
              <div className={styles.pillRadio}>Journal</div>
            </label>
          </div>
        </>
        }

        {!fjUser && 
        <>
          <h2>Subject</h2>
          <div className={styles.subjectChecks} onChange={(e) => { handleMulti(e, 'subject') }}>
            <label>
              <input type="checkbox" name="activitySubject" value='ela' />
              <div className={styles.pillRadio}>ELA</div>
            </label>
            <label>
              <input type="checkbox" name="activitySubject" value='science' />
              <div className={styles.pillRadio}>Science</div>
            </label>
            <label>
              <input type="checkbox" name="activitySubject" value='mathematics' />
              <div className={styles.pillRadio}>Math</div>
            </label>
            <label>
              <input type="checkbox" name="activitySubject" value='poetry' />
              <div className={styles.pillRadio}>Poetry</div>
            </label>
            <label>
              <input type="checkbox" name="activitySubject" value='history-social studies' />
              <div className={styles.pillRadio}>History</div>
            </label>
            <label>
              <input type="checkbox" name="activitySubject" value='other' />
              <div className={styles.pillRadio}>Other</div>
            </label>
          </div>
        </>
        }

        <h2>Number of chapters/sections</h2>
        <div className={styles.sectionsRadios}>
          <label>
            <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'sections') }} name="sections" value='1' />
            <div className={styles.pillRadio}>{'< 3'}</div>
          </label>
          <label>
            <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'sections') }} name="sections" value='2' />
            <div className={styles.pillRadio}>3 to 6</div>
          </label>
          <label>
            <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'sections') }} name="sections" value='3' />
            <div className={styles.pillRadio}>More than 6</div>
          </label>
        </div>

        {/* <h2>Special filters</h2>
        <div className={styles.specialFilters}>
          <label>
            <input type="checkbox" onClick={(e)=>{limitCheckbox(e.target, 'special')}} name="special" value='famous'/>
            <div className={styles.pillRadio}>Only famous authors</div>
          </label>
        </div> */}

        <h2>Grades</h2>
        <div className={styles.gradeChecks} onChange={(e) => { handleMulti(e, 'grade') }}>
          <label>
            <input type="checkbox" name="grades" value={2} />
            <div className={styles.circleCheck}>2</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={3} />
            <div className={styles.circleCheck}>3</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={4} />
            <div className={styles.circleCheck}>4</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={5} />
            <div className={styles.circleCheck}>5</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={6} />
            <div className={styles.circleCheck}>6</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={7} />
            <div className={styles.circleCheck}>7</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={8} />
            <div className={styles.circleCheck}>8</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={9} />
            <div className={styles.circleCheck}>9</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={10} />
            <div className={styles.circleCheck}>10</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={11} />
            <div className={styles.circleCheck}>11</div>
          </label>
          <label>
            <input type="checkbox" name="grades" value={12} />
            <div className={styles.circleCheck}>12</div>
          </label>
        </div>


       {
        fjUser && (
          <label className={styles.sophiaBtn}>
            <input type="checkbox" onClick={(e) => { 
              limitCheckbox(e.target, 'free')
              setIsFreeOnly(!isFreeOnly)
            }} 
              onChange={e => setFilterValues({...filterValues, free: e.target.checked})}
              name="free"
            />
          <div>
            {
              isFreeOnly ? 
              <img src={truth} alt="truth" /> :
              <img src={truth1} alt="truth" />
            }
            <span>workbooks</span>
          </div>
        </label>
        )
       }
      </div>
      <div className={`${styles.main} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
        <div className={styles.header}>
          <div className={`${styles.headerPic} ${fjUser && styles[returnPlatformStyle('fj')]}`}></div>
          <div className={styles.headerText}>
            <h1>Resources</h1>
            <p className={styles.subhead}>
              {!fjUser
                ? "Create new activities using Escribli and other teachers' created resources!"
                : "Create your new activity using saved resources!"}
            </p>
          </div>
        </div>

        <div className={styles.search}>
          <input
            onChange={(e) => setFilterValues({ ...filterValues, textInput: e.target.value })}
            type="search"
            name="filterText"
            placeholder="Search resources or author"
          />
          <span className={styles.submit}>Search</span>
        </div>

        <div className={styles.itemsDiv}>
          {responseReceived ? (
            resourceItems.length ? (
              resourceItems[0].title !== 'initial' ? (
                resourceItems
                  .sort((a, b) => b.activityId - a.activityId)
                  .map((item) =>
                    resourceItemFilter(item, filterValues) ? (
                      <ResourceItem
                        fjUser={fjUser}
                        item={item}
                        modalOverlay={modalOverlay}
                        resourceItems={resourceItems}
                        setResourceItems={setResourceItems}
                        key={item.activityId}
                      />
                    ) : null
                  )
              ) : (
                <h5>No resources match those criteria</h5>
              )
            ) : (
              <h5>No resources available</h5>
            )
          ) : (
            <h5>Searching for resources...</h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default Resources;

// import React, {useState} from 'react';

// import styles from './Resources.module.scss';
// import { ResourceItem } from 'components';
// import { useEffect } from 'react';
// import { resourceItemFilter } from 'helpers/filterFunctions';
// import { getFromApi } from '../../../API/teacherCalls';
// import { useParams } from 'react-router-dom';
// import { showModal } from 'helpers/uiFunctions';
// import { returnPlatformStyle } from 'helpers/altPlatformFunctions';

// import truth from './truth.svg'
// import truth1 from './truth1.svg'

// const Resources = ({modalOverlay, fjUser}) => {

//   const {activityIdParam} = useParams()
//   const [ filterValues, setFilterValues ] = useState()
//   const [ responseReceived, setResponseReceived ] = useState(false)
//   const [resourceItems, setResourceItems] = useState([])
//   const [mySchoolId, setMySchoolId] = useState()
//   const [isFreeOnly, setIsFreeOnly] = useState(false);



//   const callApi = async () => {
//     const {mySchool, resources} = await getFromApi(`resources`)
//     console.log(resources)
//     setResourceItems(resources);    
//     setMySchoolId(mySchool)
//     setResponseReceived(true)
//   }

//   useEffect(()=>{
//     if (activityIdParam){
//       setTimeout(()=>{showModal( modalOverlay, {
//         type:'resourceItem', 
//         activityId:activityIdParam
//       }) },300)
//     }

//     callApi()
//     setFilterValues({ activityType:'all', subject:'all', schoolId:'', sections:'', grade:'all', special:'all', textInput:'', free: false
//   })
//     // eslint-disable-next-line
//   },[activityIdParam])

//   const limitCheckbox = (item, variant) => {
//     setFilterValues({...filterValues, [variant]:item.value});
//     let checks = Array.from(document.querySelectorAll(`input[name='${variant}']`));
//     setTimeout(()=>{
//       if(filterValues[variant] === item.value){
//         item.checked=false; 
//         setFilterValues({...filterValues, [variant]:'all'})}
//     },50)
//     if (checks.length > 1){ checks.forEach(check => {check.checked=false; item.checked=true})}
//   }
    
//   const handleMulti = (e, variant) => {
//     let newArr = filterValues[variant] === 'all' ? [] : [...filterValues[variant]]
//     if(e.target.checked){
//       newArr.push(e.target.value)
//     } else {
//       const index = newArr.indexOf(e.target.value)
//       newArr.splice(index, 1)
//     }
//     newArr.length ? 
//       setFilterValues({...filterValues, [variant]:newArr})
//         : setFilterValues({...filterValues, [variant]:'all'})
//   }
    
//   return(
//     <div className={styles.container}>
//       <div className={styles.left}>
//         {
//           mySchoolId && mySchoolId > 0 && 
//           <div>
//             <h2>School</h2>
//             <div className={styles.schoolRadios}>
//               <label>
//                 <input type="checkbox" onClick={(e)=>{limitCheckbox(e.target, 'schoolId')}} name="schoolId" value={mySchoolId}/>
//                 <div className={styles.pillRadio}>My school</div>
//               </label>
//               <label>
//                 <input type="checkbox" onClick={(e)=>{limitCheckbox(e.target, 'schoolId')}} name="schoolId" value=''/>
//                 <div className={styles.pillRadio}>All schools</div>
//               </label>
//             </div> 
//           </div>
//         }

//         {!fjUser &&
//         <>
//           <h2>Type</h2>
//           <div className={styles.typeRadios} >
//             <label>
//               <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'activityType') }} name="activityType" value='assignment' />
//               <div className={styles.pillRadio}>Assignment</div>
//             </label>
//             <label>
//               <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'activityType') }} name="activityType" value='story' />
//               <div className={styles.pillRadio}>Story</div>
//             </label>
//             <label>
//               <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'activityType') }} name="activityType" value='journal' />
//               <div className={styles.pillRadio}>Journal</div>
//             </label>
//           </div>
//         </>
//         }

//         {!fjUser && 
//         <>
//           <h2>Subject</h2>
//           <div className={styles.subjectChecks} onChange={(e) => { handleMulti(e, 'subject') }}>
//             <label>
//               <input type="checkbox" name="activitySubject" value='ela' />
//               <div className={styles.pillRadio}>ELA</div>
//             </label>
//             <label>
//               <input type="checkbox" name="activitySubject" value='science' />
//               <div className={styles.pillRadio}>Science</div>
//             </label>
//             <label>
//               <input type="checkbox" name="activitySubject" value='mathematics' />
//               <div className={styles.pillRadio}>Math</div>
//             </label>
//             <label>
//               <input type="checkbox" name="activitySubject" value='poetry' />
//               <div className={styles.pillRadio}>Poetry</div>
//             </label>
//             <label>
//               <input type="checkbox" name="activitySubject" value='history-social studies' />
//               <div className={styles.pillRadio}>History</div>
//             </label>
//             <label>
//               <input type="checkbox" name="activitySubject" value='other' />
//               <div className={styles.pillRadio}>Other</div>
//             </label>
//           </div>
//         </>
//         }

//         <h2>Number of chapters/sections</h2>
//         <div className={styles.sectionsRadios}>
//           <label>
//             <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'sections') }} name="sections" value='1' />
//             <div className={styles.pillRadio}>{'< 3'}</div>
//           </label>
//           <label>
//             <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'sections') }} name="sections" value='2' />
//             <div className={styles.pillRadio}>3 to 6</div>
//           </label>
//           <label>
//             <input type="checkbox" onClick={(e) => { limitCheckbox(e.target, 'sections') }} name="sections" value='3' />
//             <div className={styles.pillRadio}>More than 6</div>
//           </label>
//         </div>

//         {/* <h2>Special filters</h2>
//         <div className={styles.specialFilters}>
//           <label>
//             <input type="checkbox" onClick={(e)=>{limitCheckbox(e.target, 'special')}} name="special" value='famous'/>
//             <div className={styles.pillRadio}>Only famous authors</div>
//           </label>
//         </div> */}

//         <h2>Grades</h2>
//         <div className={styles.gradeChecks} onChange={(e) => { handleMulti(e, 'grade') }}>
//           <label>
//             <input type="checkbox" name="grades" value={2} />
//             <div className={styles.circleCheck}>2</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={3} />
//             <div className={styles.circleCheck}>3</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={4} />
//             <div className={styles.circleCheck}>4</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={5} />
//             <div className={styles.circleCheck}>5</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={6} />
//             <div className={styles.circleCheck}>6</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={7} />
//             <div className={styles.circleCheck}>7</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={8} />
//             <div className={styles.circleCheck}>8</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={9} />
//             <div className={styles.circleCheck}>9</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={10} />
//             <div className={styles.circleCheck}>10</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={11} />
//             <div className={styles.circleCheck}>11</div>
//           </label>
//           <label>
//             <input type="checkbox" name="grades" value={12} />
//             <div className={styles.circleCheck}>12</div>
//           </label>
//         </div>


//        {
//         fjUser && (
//           <label className={styles.sophiaBtn}>
//             <input type="checkbox" onClick={(e) => { 
//               limitCheckbox(e.target, 'free')
//               setIsFreeOnly(!isFreeOnly)
//             }} 
//               onChange={e => setFilterValues({...filterValues, free: e.target.checked})}
//               name="free"
//             />
//           <div>
//             {
//               isFreeOnly ? 
//               <img src={truth} alt="truth" /> :
//               <img src={truth1} alt="truth" />
//             }
//             <span>workbooks</span>
//           </div>
//         </label>
//         )
//        }
//       </div>

//       <div className={`${styles.main} ${fjUser&&styles[returnPlatformStyle('fj')]}`}>
//         <div className={styles.header}>
//           <div className={`${styles.headerPic} ${fjUser&&styles[returnPlatformStyle('fj')]}`}></div>
//           <div className={styles.headerText}>
//             <h1>Resources</h1>
//             <p className={styles.subhead}>
//               {
//               !fjUser ?
//                 "Create new activities using Escribli and other teachers' created resources!"
//                 :
//                 "Create your new activity using saved resources!"
//               }
//             </p>
//           </div>
//         </div>
//         <div className={styles.search}>
//           <input  onChange={(e)=> setFilterValues({...filterValues, textInput:e.target.value})} 
//                   type="search" 
//                   name="filterText" 
//                   placeholder='Search resources or author'
//               />

//           <span className={styles.submit}>Search</span>
//         </div> 

//         <div className={styles.itemsDiv}>
//           {
//             !responseReceived  ?
//               <h5>Searching for resources...</h5>
//               :
//               !resourceItems.length ? 
//                 <h5>No resources available</h5>
//                 :
//                 resourceItems.length && resourceItems[0].title !== "initial"  ?
//                   resourceItems.sort((a, b) => b.activityId - a.activityId ).map(item =>{
//                     if(resourceItemFilter(item, filterValues)){
//                     return(
//                       <ResourceItem 
//                         fjUser={fjUser}
//                         item={item} 
//                         modalOverlay={modalOverlay}
//                         resourceItems={resourceItems}
//                         setResourceItems={setResourceItems}
//                         key={item.activityId}
                        
//                         />
//                     )
//                   }else{return null}
//                   })
//                   :
//                   <h5>No resources match those criteria</h5>
//           }
//         </div> 
//       </div>  

//     </div>
//   )
// }

// export default Resources;
