import React from 'react';

import styles from './ConnectYourChildModal.module.scss';
import access1 from 'assets/access1.svg'
import access2 from 'assets/access2.svg'
import access3 from 'assets/access3.svg'

const FindChild = ({ contRef, setContState}) => {


  const handleDone = () => {
    setContState('init')
    contRef.current.classList.remove(styles.find);
    contRef.current.classList.add(styles.init)
  }

  return (
    <>

      <h1>Find your child's code</h1>

      <p>If your child knows their Escribli login, you can access Escribli's student App and get the access code following our step-by-step guide: </p>

      <div className={styles.main}>
        <div className={styles.column}>
          <h2>1 - Accessing student information</h2>
          <div className={styles.columnInner}>
            <img src={access1} alt="access"/>
            <p>Click on the Profile button and then go to the 'My Details' section</p>
          </div>
        </div>
        <div className={styles.column}>
          <h2>2 - Getting the access code</h2>
          <div className={styles.columnInner}>
            <img src={access2} alt="access2"/>
            <div>
              <p>The access code will be available on the last input and the code looks like this: <strong>10XF567</strong></p>
              <img src={access3} alt="access3"/>
            </div>
          </div>

        </div>
      </div>
      <div onClick={handleDone} className={styles.doneBtn}>Done</div>
    </>
  )

}

export default FindChild;