import React, { useRef } from 'react';
import { useState } from 'react';

import styles from './CopyPillTooltip.module.scss';

const CopyPillTooltip = ({ copyData, displayData, tooltipText, small, blue, transparant, noBorder, noTooltip }) => {

  const [ confirm, toggleConfirm ] = useState(false)
  const contRef = useRef()

  const handleCopy = () => {
    
    toggleConfirm(true);  
    if( contRef.current)contRef.current.classList.add(styles.reset) 

    setTimeout(()=>{
      toggleConfirm(false);
      if( contRef.current)contRef.current.classList.remove(styles.reset) 
    },1500)

    navigator.clipboard.writeText(copyData) 
  }

  return (
    <div ref={contRef} onClick={handleCopy} 
      className={`
        ${styles.container} 
        ${blue && styles.blue} 
        ${transparant && styles.transparant} 
        ${noBorder && styles.noBorder} 
        ${small && styles.small}
        ${confirm && styles.confirm}
      `}>

        {
          !noTooltip &&
            <div className={styles.tooltip}>
              <p>{tooltipText}</p>
            </div>
        }
      {
        !confirm ?
          <>
            <h5>{displayData}</h5>
            <div className={styles.copyIcon} />
          </>
        :
          <h5>COPIED</h5>
      }
    </div>
  )

}

export default CopyPillTooltip;