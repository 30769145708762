import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setQueryItem, updateDashData } from '../../../Redux/actions';

import {
  StudentDetailsModal,
  NewTeacherModal,
  MiniYouModal,
  EndWritingModal,
  LoginCodeModal,
  CreateNewStudent,
  AddExistingModal,
  UploadCSVModal,
  NewStudentGoogleModal,
  DuplicateClassModal,
  JournalFromTextModal,
  RemoveModal,
  UpgradePlusModal,
  PdfGenerationModal,
  PreviewModal,
  PreviewBigCarouselModal,
  BreakoutRoom,
  ChatModal,
  PaymentModal,
  RubricModal,
  JournalTemplateModal,
  WritingTipsModal,
  SendParentInviteModal,
  AddResourceModal,
  ResourceItemModal,
  CancelSubscriptionModal,
  ShareTheNewsModal,
  TieBreakerModal,
  ManageSubscriptionModal,
  AddTeacherModal,
  AddParentModal,
  NewClassOnboarding,
  AddExistingTeacherModal,
  UpgradePlanModal,
  ConnectYourChildModal,
  ActivateAccountModal,
  ChangeEmail,
  BookPurchaseModal,
  TutorialsModal,
  TeacherNotifications,
  FinalSectionBookModal,
  PdfCreationModal,
  HauntSubModal,
  VideoPlayerModal,
  AddStudentsWarningModal,
  SpendCreditsModal
} from '../..';

import styles from './ModalBox.module.scss';
import { store } from 'storeConfig';

import ReflectionQuestionsModal from 'components/Teacher/ActivityDetails/ReflectionQuestionsModal/ReflectionQuestionsModal';
import VerseOfTheDayModal from 'components/Teacher/ActivityDetails/VerseOfTheDayModal/VerseOfTheDayModal';
import StatusButtonsExplainedModal from './StatusButtonsExplainedModal/StatusButtonsExplainedModal';
import BookPreviewModal from 'components/Teacher/BookPreviewModal/BookPreviewModal';
import WelcomeToTheBee from './WelcomeToTheBee/WelcomeToTheBee';
import PDFRenderModal from '../PDFRenderModal/PDFRenderModal';
import PageCountModal from '../PDFRenderModal/PageCountModal/PageCountModal';
import OnboardingModal from 'components/Teacher/OnboardingModal/OnboardingModal';
import EntryModal from 'components/Teacher/ActivityDetails/EntryModal/EntryModal';
import CalendarComp from 'components/Teacher/CalendarComp/CalendarComp';
import BreakoutRoomsSetupModal from 'components/Teacher/BreakoutRoomsSetupModal/BreakoutRoomsSetupModal';
import ResponsiveSectionList from 'components/Teacher/ClassDetails/ResponsiveSectionList';
import SetupChapterModal from 'components/Teacher/ActivityDetails/ActivityDetailsResponsive/SetupChapterModal/SetupChapterModal';
import NewActivityModal from 'components/Teacher/NewActivityModal/NewActivityModal';
import ClassmatesModal from 'components/Teacher/ClassDetails/ClassmatesModal';
import LoginLinkModal from 'components/Teacher/ClassDetails/ClassStudents/LoginLinkModal';
import ResponsiveNotificationsModal from './TeacherNotifications/ResponsiveNotificationsModal';
import ChapterList from 'components/Teacher/ActivityDetails/ChapterList/ChapterList';
import ActivityList from 'components/Teacher/ActivityDetails/ActivityListModal/ActivityList';
import SubscriptionModal from './Subscription/SubscriptionModal';
import StudentReportModal from './StudentReportModal/StudentReportModal';
import AdminSubscriptionModal from './AdminSubscription/Admin/AdminSubscriptionModal';
const ModalBox = ({ modalOverlay }) => {

  const item = useSelector(state => state.queryItem);
  const navOpen = useSelector(state => state.navOpen);
  const closeBtn = useRef();
  const dispatch = useDispatch();


  const hideModal = () => {
    modalOverlay.current.classList.remove('show')
    dispatch(setQueryItem({item:'null'}))
    dispatch(updateDashData('activeId', 0))
    dispatch(updateDashData('unreadChatCount', 0))
  }


  const handleClose = () => {

    if(item.type === 'changeEmail'&&item.variant==='modal'){
      store.dispatch( setQueryItem({type:'activateAccount', email:item.email}) )

    } else {
      hideModal()

    }
  }

  
  useEffect(() => {
    dispatch(setQueryItem({ item: 'null' }))
    window.addEventListener('keydown', (e) => { if (e.key === 'Escape') { handleClose() } });
      // eslint-disable-next-line
    }, [])
  
  return (

    <div id="modalBox" className={(
      item.type === 'student' ? styles.containerStudent :
      item.type === 'newTeacherLogin' ? styles.containerNewTeacher :
      item.type === 'mini-you' ? styles.containerMiniYou :
      item.type === 'newActivity' ? styles.containerNewActivity :
      (item.type === 'endWriting' || item.type === 'notAllVoted') ? styles.containerStandard :
      item.type === 'markAsComplete' ? styles.containerMarkComplete :
      item.type === 'loginCode' ? styles.containerStandard :
      item.type === 'remove' ? styles.containerNoPadd :
      (item.type === 'newStudent' && item.variant === 'createNew') ? styles.containerCreateNewStudent :
      (item.type === 'newStudent' && item.variant === 'existing') ? styles.containerNoPadd :
      (item.type === 'newStudent' && item.variant === 'csv') ? styles.containerNoPadd :
      (item.type === 'newStudent' && item.variant === 'google') ? `${styles.containerNoPadd} ${styles.mobileClose}` :
      item.type === 'duplicateClass' ? styles.containerRemove :
      item.type === 'journalFromText' ? styles.containerNoPadd :
      item.type === 'reflectionQuestions' ? styles.containerNoPadd :
      item.type === 'verseOfTheDay' ? styles.containerNoPadd :
      item.type === 'statusButtonsExplained' ? styles.containerNoPadd :
      item.type === 'pdfGen' ? styles.pdfGen :
      item.type === 'upgradePlus' ? styles.containerUpgradePlus :
      item.type === 'preview' ? styles.containerPreview :
      item.type === 'previewBigCarousel' ? styles.containerPreviewCarousel:
      item.type === 'breakoutRooms' || item.type === 'breakoutRoom' ? styles.containerBreakoutRooms :
      item.type === 'chatModal' ? styles.containerChat :
      item.type === 'paymentModal' ? styles.containerPayment :
      item.type === 'viewRubric' ? styles.containerRubric :
      item.type === 'journalTemplate' ? styles.containerJournalTemplate :
      item.type === 'writingTips' ? styles.containerWritingTips :
      item.type === 'sendParentInvite' ? styles.containerSendParentInvite :
      item.type === 'addResource' ? styles.containerNoPadd :
      item.type === 'newActivityMobile' ? styles.containerNewActivityModal :
      item.type === 'responsiveSectionList' ? styles.containerResSectionList :
      item.type === 'resourceItem'|| item.type === 'resourceItem_step2' ? `${styles.containerResourceItem} ${styles.step1}` :
      item.type === 'cancelSubscription' ? styles.containerCancelSubscription :
      item.type === 'shareTheNews' ? styles.containerShareTheNews :
      item.type === 'tieBreaker' ? styles.containerTieBreaker :
      item.type === 'manageSubscription' ? styles.containerManageSubscription :
      item.type === 'addTeacher' ? styles.containerAddTeacher :
      item.type === 'addExistingTeacher' ? styles.containerAddExistingTeacher :
      item.type === 'addParent' ? styles.containerAddParent :
      item.type === 'newClassOnboarding' ? styles.containerNewClassOnboarding :
      item.type === 'upgradePlan' ? styles.containerUpgradePlan :
      item.type === 'connectYourChild' ? styles.containerConnectYourChild :
      item.type === 'changeEmail' ? styles.containerChangeEmail :
      item.type === 'activateAccount' ? styles.containerActivateAccount :
      item.type === 'bookPurchase' ? styles.containerBookPurchase :
      item.type === 'pricingOptions' ? styles.containerPricingOptions :
      item.type === 'tutorials' ? styles.containerTutorials :
      item.type === 'notifications' ? `${styles.containerNotifications} ${navOpen && styles.navOpen}` :
      item.type === 'responsiveNotifications' ? `${styles.containerNoPadd}` :
      item.type === 'finalSection' ? styles.containerStandard :
      item.type === 'writingClubActivity' ? styles.containerWritingClubActivity :
      item.type === 'pdfCreation' ? styles.containerStandard :
      item.type === 'hauntSub' ? styles.containerStandard :
      item.type === 'addStudentsToClass' ? styles.containerStandard :
      item.type === 'simplifiedActivity' ? styles.containerStandard :
      item.type === 'videoPlayer' ? styles.containerVideoPlayer :
      item.type === 'showBookPreview' ? styles.containerBookPreview :
      item.type === 'spendCredits' ? styles.containerCredits :
      item.type === 'welcomeToTheBee' ? styles.containerStandard :
      item.type === 'bookParty' ? styles.containerStandard :
      item.type === 'pageCount' ? styles.containerStandard :
      item.type === 'newOnboarding' ? styles.containerNoPadd :
      item.type === 'calendarModal' ? `${styles.containerStandard} ${styles.noClose} ${styles.noBG}` :
      item.type === 'entryModal' ? `${styles.containerNoPadd} ${styles.offsetClose}` :
      item.type === 'classmates' ? `${styles.containerNoPadd}` :
      item.type === 'pdfRender' ? styles.containerPDFRender :
      item.type === 'SetupChapterModal' ? `${styles.containerNoPadd} ${styles.setupChapter}` :
      item.type === 'chapterList' ? `${styles.containerNoPadd} ${styles.chapterList}` :
      item.type === 'activityList' ? `${styles.containerResSectionList}` :
      item.type === 'adminSubscription' ? `${styles.containerAdminSubscription}` :
      item.type === 'studentReport' ? `${styles.containerStudentReport}` :
      item.type === 'loginLink' ? `${styles.containerNoPadd} ${styles.noClose}` :
      styles.containerPlaceholder
    )}>

      <div onClick={handleClose} className={styles.close} ref={closeBtn}></div>
      {
        item.type === 'newTeacherLogin' ? <NewTeacherModal item={item} />:
        item.type === 'mini-you' ? <MiniYouModal item={item} hideModal={hideModal} modalOverlay={modalOverlay} />:
        item.type === 'student' ? <StudentDetailsModal item={item} />:
        item.type === 'studentReport' ? <StudentReportModal item={item} />:
        item.type === 'endWriting' ? <EndWritingModal hideModal={hideModal} item={item} />:
        item.type === 'notAllVoted' ? <EndWritingModal hideModal={hideModal} item={item} />:
        item.type === 'markAsComplete' ?<EndWritingModal hideModal={hideModal} item={item} />:
        item.type === 'loginCode' ? <LoginCodeModal item={item} closeBtn={closeBtn} /> :
        item.type === 'remove' ? <RemoveModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'newStudent' && item.variant === 'createNew') ? <CreateNewStudent item={item} closeBtn={closeBtn} /> :
        (item.type === 'newStudent' && item.variant === 'csv') ? <UploadCSVModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'newStudent' && item.variant === 'existing') ? <AddExistingModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'newStudent' && item.variant === 'google') ? <NewStudentGoogleModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'duplicateClass') ? <DuplicateClassModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'journalFromText') ? <JournalFromTextModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'reflectionQuestions') ? <ReflectionQuestionsModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'verseOfTheDay') ? <VerseOfTheDayModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'statusButtonsExplained') ? <StatusButtonsExplainedModal item={item} closeBtn={closeBtn} /> :
        item.type === 'pdfGen' ? <PdfGenerationModal item={item} closeBtn={closeBtn} /> :
        item.type === 'upgradePlus' ? <UpgradePlusModal item={item} closeBtn={closeBtn} /> :
        item.type === 'preview' ? <PreviewModal item={item} closeBtn={closeBtn} hideModal={hideModal} modalOverlay={modalOverlay}/> :
        item.type === 'previewBigCarousel' ? <PreviewBigCarouselModal item={item} closeBtn={closeBtn} hideModal={hideModal}/> :
        item.type === 'breakoutRooms' ? <BreakoutRoomsSetupModal item={item} closeBtn={closeBtn} /> :
        // item.type === 'breakoutRooms' ? <BreakoutRoomsModal item={item} closeBtn={closeBtn} /> :
        item.type === 'breakoutRoom' ? <BreakoutRoom item={item} closeBtn={closeBtn} /> :
        item.type === 'chatModal' ? <ChatModal item={item} closeBtn={closeBtn} /> :
        item.type === 'paymentModal' ? <PaymentModal item={item} closeBtn={closeBtn} /> :
        item.type === 'viewRubric' ? <RubricModal item={item} closeBtn={closeBtn} /> :
        (item.type === 'journalTemplate' || item.type === 'resourceItem_step2') ? <JournalTemplateModal item={item} closeBtn={closeBtn} /> :
        item.type === 'writingTips' ? <WritingTipsModal item={item} closeBtn={closeBtn} /> :
        item.type === 'sendParentInvite' ? <SendParentInviteModal item={item} closeBtn={closeBtn} /> :
        item.type === 'addResource' ? <AddResourceModal item={item} closeBtn={closeBtn} /> :
        item.type === 'resourceItem' ? <ResourceItemModal item={item} closeBtn={closeBtn} /> :
        item.type === 'cancelSubscription' ? <CancelSubscriptionModal item={item} closeBtn={closeBtn} /> :
        item.type === 'shareTheNews' ? <ShareTheNewsModal item={item} closeBtn={closeBtn} /> :
        item.type === 'tieBreaker' ? <TieBreakerModal item={item} closeBtn={closeBtn} /> :
        item.type === 'manageSubscription' ? <ManageSubscriptionModal item={item} closeBtn={closeBtn} /> :
        item.type === 'addTeacher' ? <AddTeacherModal item={item} closeBtn={closeBtn} /> :
        item.type === 'addExistingTeacher' ? <AddExistingTeacherModal item={item} closeBtn={closeBtn} /> :
        item.type === 'addParent' ? <AddParentModal item={item} closeBtn={closeBtn} /> :
        item.type === 'newClassOnboarding' ? <NewClassOnboarding item={item} closeBtn={closeBtn} /> :
        item.type === 'upgradePlan' ? <UpgradePlanModal item={item} closeBtn={closeBtn} /> :
        item.type === 'connectYourChild' ? <ConnectYourChildModal item={item} closeBtn={closeBtn} /> :
        item.type === 'changeEmail' ? <ChangeEmail item={item} closeBtn={closeBtn} /> :
        item.type === 'activateAccount' ? <ActivateAccountModal item={item} closeBtn={closeBtn} /> :
        item.type === 'bookPurchase' ? <BookPurchaseModal item={item} closeBtn={closeBtn} /> :
        item.type === 'tutorials' ? <TutorialsModal item={item} closeBtn={closeBtn} /> :
        item.type === 'notifications' ? <TeacherNotifications item={item} closeBtn={closeBtn} /> :
        item.type === 'responsiveNotifications' ? <ResponsiveNotificationsModal item={item} closeBtn={closeBtn} /> :
        item.type === 'finalSection' ? <FinalSectionBookModal item={item} closeBtn={closeBtn} /> :
        item.type === 'pdfCreation' ? <PdfCreationModal item={item} closeBtn={closeBtn} /> :
        item.type === 'hauntSub' ? <HauntSubModal item={item} closeBtn={closeBtn} /> :
        item.type === 'videoPlayer' ? <VideoPlayerModal item={item} closeBtn={closeBtn} /> :
        item.type === 'addStudentsToClass' ? <AddStudentsWarningModal item={item} closeBtn={closeBtn} /> :
        item.type === 'showBookPreview' ? <BookPreviewModal item={item} closeBtn={closeBtn} /> :
        item.type === 'spendCredits' ? <SpendCreditsModal item={item} closeBtn={closeBtn} /> :
        item.type === 'welcomeToTheBee' ? <WelcomeToTheBee item={item} closeBtn={closeBtn} /> :
        // item.type === 'bookParty' ? <BookPublishingPartyModal item={item} closeBtn={closeBtn} /> :
        item.type === 'pageCount' ? <PageCountModal item={item} closeBtn={closeBtn} /> :
        item.type === 'pdfRender' ? <PDFRenderModal item={item} closeBtn={closeBtn} /> :
        item.type === 'newOnboarding' ? <OnboardingModal item={item} closeBtn={closeBtn} /> :
        item.type === 'entryModal' ? <EntryModal item={item} closeBtn={closeBtn} /> :
        item.type === 'calendarModal' ? <CalendarComp item={item} handleClose={handleClose} /> :
        item.type === 'responsiveSectionList' ? <ResponsiveSectionList item={item} handleClose={handleClose} /> :
        item.type === 'classmates' ? <ClassmatesModal item={item} handleClose={handleClose} /> :
        item.type === 'SetupChapterModal' ? <SetupChapterModal item={item} handleClose={handleClose} /> :
        item.type === 'newActivityMobile' ? <NewActivityModal mobile item={item} handleClose={handleClose} /> :
        item.type === 'loginLink' ? <LoginLinkModal mobile item={item} handleClose={handleClose} /> :
        item.type === 'pricingOptions' ? <SubscriptionModal item={item} handleClose={handleClose} /> :
        item.type === 'chapterList' ? <ChapterList modal activeId={item.activeId} activity={item.activity} handleClose={handleClose} /> :
        item.type === 'activityList' ? <ActivityList modalOverlay={modalOverlay} classData={item.classData} handleClose={handleClose} /> :
        item.type === 'adminSubscription' ? <AdminSubscriptionModal modalOverlay={modalOverlay}  handleClose={handleClose} /> :

        <h4>Whoops! Content not found</h4>
      }


    </div>

  )
}

export default ModalBox;