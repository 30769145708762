import React, { useState } from 'react'

import group from './group.svg'
import book from './book.svg'

import greenbook from './greenbook.svg'
import greenpeople from './grrenpeople.svg'

import styles from './Welcome.module.scss';

import { showModal } from 'helpers/uiFunctions';
import { useEffect } from 'react';
import { useUserType } from 'context/AltPlatformProvider';
import { useSelector } from 'react-redux'


const WelcomeDiv = ({modalOverlay}) => {
    const [ close, setClose] = useState(false);
    const { fjUser} = useUserType()

    const dashData = useSelector(state => state.dashData)

    useEffect(() => {
        const isWelcomeDivClosed = localStorage.getItem('welcomeDivClosed');
        if (isWelcomeDivClosed) {
          setClose(true);
        }
      }, []);
    
      const handleClose = () => {
        localStorage.setItem('welcomeDivClosed', 'true'); 
        setClose(true);
      };
      console.log(dashData, "datash")
    

    if(!dashData?.repeatModal){
        return null;
    }
  return (
    <div className={styles.center}>
        {
            fjUser ?
            <div className={styles.container}>
                <div>
                    <h4>Welcome to <strong>Faith Journals</strong>, now collaborating with <span>Sophia Institute for Teachers!</span> </h4>
                </div>

                <div className={styles.times} onClick={handleClose}>
                </div>
                <div className={styles.box}>
                    <div  className={styles.box}>
                        <div className={styles.image_div}>
                            <img src={group} alt="group" className={styles.group} />
                        </div>
                        <div className={styles.write}>
                            <p>Create classrooms or groups and add students to it. We have created the first one for you!</p>
                        </div>
                    </div>

                    <div  className={styles.box}>
                    <div className={styles.image_div}>
                            <img src={book} alt="group" className={styles.book} />
                        </div>
                        <div className={styles.write}>
                            <p>
                            Add journal activities to your class. As many as you would like to!
                            </p>
                        </div>

                    </div>

                </div>


                <div>
                <p>
                    *You can use Faith Journals free of charge up to 5 students.
                    To use Faith Journals with more than 5 students, a subscription is required.
                </p>
                </div>

                <div className={styles.btns}>
                    <div className={styles.second}
                        onClick={()=>showModal(modalOverlay, {type:'pricingOptions'})}
                    >See subscription options</div>

                </div>
            </div>
            :
            <div className={styles.container}>
                <div>
                    <h4>
                        Thank you for using <span>escribli</span> in our classroom.
                    </h4>
                    <h4>
                        We love supporting teachers and that is why we make our tools as accessible and affordable as possible to all.
                        In an effort to accommodate our pricing to teacher's and school's budget, escribli's new pricing will consist on the following:
                    </h4>
                    <h4 style={{ fontWeight: "bold"}}>
                        <span> $1/ student per month</span>
                    </h4>
                </div>

                <div className={styles.times} onClick={handleClose}>
                </div>
                <div className={styles.box}>
                    <div  className={styles.box}>
                        <div className={styles.image_div}>
                            <img src={greenpeople} alt="group" className={styles.group} />
                        </div>
                        <div className={styles.write}>
                            <p lassName={styles.groupx} >Create classrooms or groups and add students to it. We have created the first one for you!</p>
                        </div>
                    </div>

                    <div  className={styles.box}>
                    <div className={styles.image_div}>
                            <img src={greenbook} alt="group" className={styles.book} />
                        </div>
                        <div className={styles.write}>
                            <p>
                            Add activities to your class. As many as you would like to!
                            </p>
                        </div>

                    </div>

                </div>


                <div>
                <p>
                    *You can use escribli free of charge up to 5 students.
                </p>
                </div>

                <div className={styles.btns}>
                    <div className={styles.second}
                        onClick={()=>showModal(modalOverlay, {type:'pricingOptions'})}
                    >See subscription options</div>

                </div>
            </div>
        }

    </div>

    )
}

export default WelcomeDiv