import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import BreakoutRoomsModal from './BreakoutRoomsModal/BreakoutRoomsModal';
import DeadlineModal from './DeadlineModal/DeadlineModal';
import GradingModal from './GradingModal/GradingModal';
import GrammarReportModal from './GrammarReportModal/GrammarReportModal';
import PeerReviewModal from './PeerReviewModal/PeerReviewModal';
import PrivateModeModal from './PrivateModeModal/PrivateModeModal';

import styles from './SettingsModalsContainer.module.scss';
import UploadFilesModal from './UploadFilesModal/UploadFilesModal';
import WordBankModal from './WordBankModal/WordBankModal';

const SettingsModalsContainer = ({selected, setModal, activeSection, setActiveSection, modalOverlay, activity }) => {

  const contRef = useRef()

  useEffect(()=>{
    if(selected){
      contRef.current.classList.remove(styles.display)
      setTimeout(()=>{
        contRef.current.classList.add(styles.display)
      },50)
    }
  },[selected])

  return (
    <div ref={contRef} className={`${styles.container} ${selected === "deadline" && styles.calendar}`}>
      <div onClick={()=>setModal(null)} className={styles.closeBtn} />
      {
        selected === 'deadline' ?
          <DeadlineModal
            activity={activity}
            setModal={setModal}
            activeSection={activeSection}
            setActiveSection={setActiveSection}/>
          : selected === 'wordbank' ?
            <WordBankModal
              activeSection={activeSection}
              setActiveSection={setActiveSection} />
            : selected === 'upload' ?
              <UploadFilesModal
                activeSection={activeSection}
                setActiveSection={setActiveSection} />
              : selected === 'grammar' ?
                <GrammarReportModal
                  setModal={setModal}
                  activeSection={activeSection}
                  setActiveSection={setActiveSection} />
                : selected === 'rooms' ?
                  <BreakoutRoomsModal
                    modalOverlay={modalOverlay}
                    setModal={setModal}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection} />
                  : selected === 'grading' ?
                    <GradingModal
                      activity={activity}
                      modalOverlay={modalOverlay}
                      setModal={setModal}
                      activeSection={activeSection}
                      setActiveSection={setActiveSection}
                      contRef={contRef} />
                    : selected === 'peer' ?
                      <PeerReviewModal
                        setModal={setModal}
                        activeSection={activeSection}
                        setActiveSection={setActiveSection} /> 
                      : selected === 'private' ?
                      <PrivateModeModal
                        modalOverlay={modalOverlay}
                        setModal={setModal}
                        activity={activity} />
                        :
                        <h5>{selected}</h5> 
      }
    </div>
  )
}

export default SettingsModalsContainer;