import React, { useState, useEffect }  from 'react';

import styles from './Pricing.module.scss';
import { Link, useHistory } from 'react-router-dom';

import { getFromApi } from 'API/teacherCalls';

import { useUserType } from 'context/AltPlatformProvider';
import PricingEscrib from './PricingEscrib';
import MainPricing from './MainPricing';



const Pricing = ({ modalOverlay }) => {

  const [ planData, setPlanData ] = useState({initial:true})
  const [selectedValue, setSelectedValue] = useState('monthly');
  const { fjUser } = useUserType() 




  const { currentSub, paymentMethod } = planData
  
  const history = useHistory()

  const getData = async () =>  setPlanData(await getFromApi('plus_details'))

  useEffect(()=>{ getData() },[])
  const handleButton = () => history.push('/teacher/account/subscriptiondetails')

  if(currentSub?.active){
    handleButton()
  }


  return (

      <div className={styles.containerx}>
        <h1>Subscription</h1>
      {
        fjUser ?
          <MainPricing />
           :
        <div className={styles.container_dark}>
          <PricingEscrib show={true} />
        </div>
        }



    
      </div>

  )

}

export default Pricing;









