import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import { returnPlatformStyle } from 'helpers/altPlatformFunctions';

import styles from './CalendarComp.module.scss';
import { eventClick, initCalendarTeacher, addDetailsElements } from './calendarFuncs_teacher';

const CalendarComp = ({ handleClose, fjUser }) => {

const dashData = useSelector(state => state.dashData)
const classes = dashData.classes
const env = useSelector(state => state.env)
const [ events, setEvents ] = useState()
const [ initialEvents, setInitialEvents ] = useState([])
const [ classLetters, setClassLetters ] = useState(['A','B']); 

useEffect(()=>{ 
  initCalendarTeacher(classes, styles, setInitialEvents, setEvents, setClassLetters);
  // eslint-disable-next-line
},[dashData])

  const handleClassFilter = (input) => {
    if(input === "all"){
      setEvents(initialEvents)
    } else {
      setEvents(initialEvents.filter(item => item.classId === parseInt(input)))
    }
  }

  const handleArrow = (input) => {
    const arrowBtn = input === 'next' ?
      document.getElementsByClassName('fc-next-button')[0] : document.getElementsByClassName('fc-prev-button')[0];
    arrowBtn.click()
  }

  return (
    <div className={styles.container}>
      <div className={styles.close} onClick={()=>{handleClose(false)}}></div>

      <div className={`${styles.leftBar} ${fjUser&&styles[returnPlatformStyle('fj')]}`}>
        <h2>Filter by class</h2>

        <div className={styles.radios}>

        <div className={styles.radiosInner} onChange={(e)=>{handleClassFilter(e.target.value)}}>
          <label>
            <input defaultChecked type="radio" name="classSelect" value="all"/>
            <div className={styles.classRadio}>
              <div className={styles.circleCont}>
                <div className={styles.circle}></div>
              </div>
              <h3>All classes</h3>
            </div>
          </label>
            {classes && classes.length ? 
              classes.map((item, index) =>{
                return(
                  <label>
                    <input type="radio" name="classSelect" value={item.classId}/>
                    <div className={styles.classRadio}>
                      <div className={styles.circleCont}>
                        <div className={styles.circle}></div>
                      </div>
                      <h3>{item.className}</h3>
                      <div className={styles.keyLetter}>
                        {classLetters && classLetters[index] ? 
                          classLetters[index].length> 1 ? 
                          <p className= {styles.toThePower}>{classLetters[index].charAt(0)}<sup>{classLetters[index].length}</sup></p> 
                            : <p> {classLetters[index]} </p>
                        :null}
                      </div>
                    </div>
                  </label>
                )
              })
            :null}
        </div>
        </div>

      </div>
      <div className={styles.calendarDiv}>

        <div className={styles.arrows}>
          <div onClick={()=>{handleArrow('prev')}} className={styles.left}></div>
          <div onClick={()=>{handleArrow('next')}} className={styles.right}></div>
        </div>

        {
          !fjUser&&
            <div className={styles.legend}>
              <div className={styles.legendItem}>
                <div className={`${styles.dot} ${styles.yellow}}`}></div>
                <h5>Assignment</h5>
              </div>
              <div className={styles.legendItem}>
                <div className={`${styles.dot} ${styles.blue}}`}></div>
                <h5>Journal</h5>
              </div>
              <div className={styles.legendItem}>
                <div className={`${styles.dot} ${styles.green}}`}></div>
                <h5>Story</h5>
              </div>
            </div>
        }

          <FullCalendar
            events= {events}
            plugins={[ dayGridPlugin ]}
            initialView="dayGridMonth"
            height='680px'
            eventClick={(info)=>eventClick(info, env)}
            fixedWeekCount={false}
            dayHeaderFormat={{weekday: 'long'}}
            displayEventTime={false}
            dayMaxEventRows={3}
            eventDidMount =  {addDetailsElements}
          />
      </div>
      
    </div>
  )
}

export default CalendarComp;