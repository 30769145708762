import { setGrammarFeedback } from 'components/StructuralComps/Modal/CorrectionModal/autoFeedbackFuncs';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import styles from './GrammarReportBlock.module.scss';

const GrammarReportBlock = ({ reportArray, fullEntry }) => {

  const [ autoFeedbackParam, setAutoFeedbackParam ] = useState('passive')
  const [ entryHTML, setEntryHTML ] = useState('')
  const [ loading, toggleLoading ] = useState(false)


  useEffect(()=>{
    if(reportArray?.length){
      console.log(autoFeedbackParam)
      setEntryHTML(setGrammarFeedback(fullEntry, reportArray, autoFeedbackParam))
    }
    // eslint-disable-next-line
  },[autoFeedbackParam])


  return (
    <div className={styles.container}>
      
          <p className={styles.header}>Click on the report you want to see</p>
          <div className={styles.pills}>
            <label>
              <input checked={autoFeedbackParam === 'passive'} type="radio" name="feedbackParam" />
              <div className={`grammarRow ${styles.row}`} onClick={() => setAutoFeedbackParam('passive')}>
                <div className={styles.number}>{reportArray.filter(i => i.type === 'passive').length}</div>
                <p>Passive Voice</p>
              </div>
            </label>
            
            <label>
              <input checked={autoFeedbackParam === 'initial'} type="radio" name="feedbackParam" />
              <div className={`grammarRow ${styles.row}`} onClick={() => setAutoFeedbackParam('initial')}>
                <div className={styles.number}>{reportArray.filter(i => i.type === 'initial').length}</div>
                <p>Initial Pronouns</p>
              </div>
            </label>
            
            <label>
              <input checked={autoFeedbackParam === 'grammar'} type="radio" name="feedbackParam" />
              <div className={`grammarRow ${styles.row}`} onClick={() => setAutoFeedbackParam('grammar')}>
                <div className={styles.number}>{reportArray.filter(i => i.type === 'grammar').length}</div>
                <p>Grammar</p>
              </div>
            </label>
            
            <label>
              <input checked={autoFeedbackParam === 'overuse'} type="radio" name="feedbackParam" />
              <div className={`grammarRow ${styles.row}`} onClick={() => setAutoFeedbackParam('overused')}>
                <div className={styles.number}>{reportArray.filter(i => i.type === 'overused').length}</div>
                <p>Overuse</p>
              </div>
            </label>
            
            <label>
              <input checked={autoFeedbackParam === 'consistency'} type="radio" name="feedbackParam" />
              <div className={`grammarRow ${styles.row}`} onClick={() => setAutoFeedbackParam('consistency')}>
                <div className={styles.number}>{reportArray.filter(i => i.type === 'consistency').length}</div>
                <p>Consistency</p>
              </div>
            </label>
          </div>


         <div className={`${styles.entryText}`} style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: entryHTML}}></div>
    </div>
  )

}

export default GrammarReportBlock;