import React from 'react';
import { Link } from 'react-router-dom';

import styles from './AddStudentsWarningModal.module.scss';

const AddStudentsWarningModal = ({closeBtn, item:{classId}}) => {

  return (
    <div className={styles.container}>
      <h1>This class contains no students</h1>
      <div className={styles.warning}>
        <p>Please add some students to this class before setting the activity live</p>
      </div>
      <div className={styles.buttons}>
        <div onClick={()=>{closeBtn.current.click()}} className={styles.cancel}>Cancel</div>
        <Link to={`/teacher/classes/view/${classId}/?students`}><div className={styles.addStudents}>Add students</div></Link>
      </div>
    </div>
  )

}

export default AddStudentsWarningModal;