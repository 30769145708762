import { sendToApiReturn } from 'API/teacherCalls';
import { LoadingOverlay } from 'components';
import React, { useEffect, useRef, useState } from 'react';

import styles from './LoginLinkModal.module.scss';

const LoginLinkModal = ({ handleClose, item: { studentId, studentEmail }}) => {

  const [ apiRes, setApiRes ] = useState(null);
  const [ success, setSuccess ] = useState(null);
  const warnRef = useRef()

  const callApi = async () => setApiRes(await sendToApiReturn(`email_login_link/${studentId}`))

  useEffect(()=>{
    callApi()
  },[])

  useEffect(()=>{
    if(apiRes){
      
      console.log(apiRes.message.message)
      setSuccess(apiRes?.message.message!=="error")
      if(apiRes?.message.message=="error"){
        warnRef.current.classList.add('shakeSlow')
      }
    } 

  },[apiRes])


  return (
    <div ref={warnRef} className={styles.container}>
      {
        !apiRes ?

          <LoadingOverlay contained />
        
        : 

        <>
          {!success ?
            <>
              <h1>Email not sent</h1>
              <div className={styles.warning}>
                <p>Only students with a valid email for their username can be mailed a login code</p>
              </div>
            </>
          :
            <>
              <h1>Email sent</h1>
              <p>Login link sent to <span>{studentEmail}</span></p>
            </>
          }
          <div onClick={handleClose} className={styles.okBtn}>
            Done
          </div>
        </>
      }
    </div>
  )

}

export default LoginLinkModal;