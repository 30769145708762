import React, { useState } from 'react';

import styles from './CopyUrlBtn.module.scss';

const CopyUrlBtn = ({ activityId, sectionIdx }) => {

  const [ btnText, setBtnText ] = useState("Share section");

  const frontUrl = localStorage.getItem('studentsFrontUrl');

  const handleCopyUrl = () => {

    const copyData = `${frontUrl}/write?activityId=${activityId}&s=${sectionIdx}`
    navigator.clipboard.writeText(copyData)

    setBtnText("COPIED!");
    setTimeout(()=>{
      setBtnText("Share section");
    },1500)
  }

  return !frontUrl ? null : (
    <div onClick={handleCopyUrl} className={styles.copyLinkBtn}>
      <div className={styles.icon} />
      <p>{btnText}</p>
      <div className={styles.tooltip}>
        Copy the URL of this section to pass to your students
      </div>
    </div>
  )

}

export default CopyUrlBtn;