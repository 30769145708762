import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import { LoadingOverlay } from 'components';
import { toTitleCase } from 'helpers/toTitleCase';
import { defaultUserImg, showModal } from 'helpers/uiFunctions';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styles from './ClassTeachers.module.scss';

const ClassTeachers = ({ modalOverlay, synod, autoTrigger }) => {

  const [ invites, setinvites ] = useState(['']);
  const [ teachers, setTeachers ] = useState(null);
  const [ error, setError ] = useState(null);
  const { classId } = useParams()
  const [ leadId, setLeadId ] = useState(null)
  const { teacherId } = useSelector(state=>state.dashData)

  const callApi = async () => setTeachers(await getFromApi(`class/${classId}/teachers`))
  
  useEffect(()=>{ callApi() },[])

  useEffect(()=>{
    if(teachers){
      setLeadId(teachers.find(i=>i.teacherStatus==="lead").teacherId)
    }
  },[teachers])

  const setInviteText = (idx, text) => {
    const newArray = [...invites]
    newArray[idx] = text
    setinvites(newArray)
  }

  const handleReset = () => {
    setError(null)
    setinvites(['']); 
    const input = document.getElementById('input1')
    if(input) input.focus()
  }

  const handleSubmit = async () => {

    if(validateMails(invites)) return setError('One or more invalid email')
    const data = await sendToApiReturn(`class/${classId}/invite`, { emails: invites} )
    setTeachers(data)
    handleReset()
  }

  const validateMails = (array) => {
    const regex = /.+@.+\..+/i;
    return array.some(mail => !regex.test(mail))
  }

  const handleDelete = (idx, email) => {
    setTeachers([
      ...teachers.slice(0,idx),
      ...teachers.slice(idx+1)
    ])
    sendToApiReturn(`class/${classId}/remove_teacher?email=${email}`)
  }


  return (
    <div className={styles.container}>
      {
        !teachers ? 
          <LoadingOverlay />

        :

          <>
            <div className={styles.teacherList}>
              <div className={styles.listHeader}>
                <p className={styles.column}>{!synod ? "Teacher Name" : "Leaders"}</p>
                <p className={styles.column}></p>
                <p className={styles.column}>Status</p>
              </div>

              {
                teachers.map((teacher, idx) => 
                  <div key={idx} className={styles.teacherRow}>
                    <div className={styles.column}>
                    { !synod && <img src={defaultUserImg()} alt="" />}
                      <p>{teacher.teacherName}</p>
                    </div>
                    <div className={styles.column}>
                      <p>{teacher.teacherEmail}</p>
                    </div>
                    <div className={styles.column}>
                      <div className={`${styles.statusPill} ${styles[teacher.teacherStatus]}`}>
                        {teacher.teacherStatus==='lead'?'Lead Teacher': toTitleCase(teacher.teacherStatus) }
                      </div>
                    </div>
                    {
                      (leadId===teacherId && teacher.teacherId!==teacherId) &&
                          <div onClick={()=>showModal(modalOverlay, {type:'remove', variant:'deleteTeacher', func: ()=>handleDelete(idx,teacher.teacherEmail)})} className={styles.delete} />
                    }
                  </div>
                )
              }

            </div>
            <div className={styles.inviteDiv}>

              <div className={styles.topBar}>
                <h4>Invite another teacher to collaborate on this class</h4>
              </div>

              <div className={styles.main}>
                {/* <p>You can only invite teachers from your school</p> */}
                {
                  invites.map((item, idx) =>
                    <input autoFocus value={invites[idx]} autoFocus id={`input${idx + 1}`} key={idx} onChange={e => {setInviteText(idx, e.target.value); setError(null)}} type="text" />
                  )
                }
                <p className={`${styles.addInvite} ${invites[invites.length - 1] == '' && styles.inactive}`} onClick={() => setinvites([...invites, ''])} >Add another invitation <span>+</span></p>

              </div>

              <div className={styles.bottom}>
                <div onClick={handleReset} className={styles.resetBtn}>
                  reset
                </div>
                <div onClick={handleSubmit} className={styles.submitBtn}>
                  Send invites
                </div>

               {error &&  <p className={styles.error}>{error}</p>}
              </div>
            </div>

          </>
      }
      
    </div>
  )

}

export default ClassTeachers;







  // const setInviteText = useCallback(
  //   debounce((idx, text, array) =>{
  //     const newArray = [...array]
  //     newArray[idx] = text
  //     setinvites(newArray)
  //   }, 250), [])