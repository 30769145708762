

export const stripTags = input => input.replace(/<[^>]*>/g, ' ').trim()



//strip punctuation also
// export const stripTags = input => input.replace(/<[^>]*>/g, ' ').replace(/[^\w\s]|_/g, "").trim()

// //strip <br> tags which for some reason are missed 
// text.replace(/<br>/g, " ")