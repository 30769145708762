import React, { useState, useEffect, useRef } from 'react';

import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import InlineHtml from 'components/UtilityComps/InlineHtml/InlineHtml';
import styles from './VerseOfTheDayModal.module.scss';

import { monthAndDayToday } from 'helpers/uiFunctions';

import book from '../../../../assets/book.svg'

const VerseOfTheDayModal = ({handleNewSection, closeModal, activityId, activityClassId}) => {

  // const [quotes, setQuotes] = useState([]);
  const [ quoteTitle, setQuoteTitle ] = useState("")
  const [ quoteText, setQuoteText ] = useState("")
  const [ forDate, setForDate ] = useState("")
  const [ offset, setOffset ] = useState(0)
  const addToJournalRef = useRef()

  const frontUrl = localStorage.getItem('frontUrl')

  const fetchQuotes = async () => {
    // fetch the daily quotes
    const resp = await getFromApi(`daily_quotes?offset=${offset}`);    
    // if we have at least one quote, set the title and description
    if (resp[0]) {
      // setQuotes(resp);
      setQuoteTitle(resp[0].title);
      setQuoteText(resp[0].description);
      setForDate(resp[0].forDate);
    }
  };

  const fetchPrev = () => {
    // calculate the new offset
    const newOffset = offset <= -5 ? -5 : offset - 1
    // only change the UI and re-fetch if the offset is between -5 and 0
    if (newOffset !== offset) {
      // make sure the offset does not go lower than 5 days ago
      setOffset(newOffset)
      // let the user know we're fetching the quote they've requested
      setQuoteTitle("")
      setQuoteText("")
      setForDate("loading...")
    }
  }

  const fetchNext = () => {
    const newOffset = offset >= 0 ? 0 : offset + 1
    // only change the UI and re-fetch if the offset is between -5 and 0
    if (newOffset !== offset) {
      // make sure the offset does not go past today into the future
      setOffset(newOffset)
      // let the user know we're fetching the quote they've requested
      setQuoteTitle("")
      setQuoteText("")
      setForDate("loading...")
    }
  }

  const addToJournal = async () => {
    if(activityId && activityClassId){
      // let the user know 
      addToJournalRef.current.innerText = "Adding..."
      // create the new section using the date today as a title and adding
      // the quote title as the first line in the instructions
      const sectionTitle = monthAndDayToday()
      const sectionInstructions = `<strong>${quoteTitle}</strong><br/>${quoteText}`
      handleNewSection(sectionTitle, sectionInstructions)
      // first hide, then close the modal so that we can reopen it
      setTimeout(() => closeModal(), 500)
    }
  }

  // only when the offset changes
  useEffect(() => {
    fetchQuotes();
  }, [offset]);

  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <div className={styles.topRow}>
          <img src={book} alt="book" />
          <h3>Verse of the day</h3>
          <div onClick={()=>fetchPrev()} className={styles.previous} />
          <p className={styles.forDate}>{forDate}</p>
          <div onClick={()=>fetchNext()} className={styles.next} />
        </div>
        <div className={styles.quoteBody}>
          {
            quoteText.length && quoteTitle.length ?
            <div>
              <InlineHtml rawHtml={quoteText + 
                "<em> Catholic Public Domain Bible</em>"} />                 
              <p className={styles.sectionTitle}>{quoteTitle}</p>
            </div>
            :
            <p>Fetching quote of the day...</p>
          }
        </div>
        <button ref={addToJournalRef} onClick={addToJournal}>Add to journal</button>
      </div>
    </div>
  );
};

export default VerseOfTheDayModal;

