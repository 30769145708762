import React, { useEffect, useRef, useState } from 'react';

import styles from './Subscription.module.scss';
import pencil from 'assets/pencil_blue.svg';
import tick from 'assets/teacher/icon-circleTick.svg'
import { sendToApiReturn } from 'API/teacherCalls';
import { addAnimation } from 'helpers/uiFunctions';
import { LoadingOverlay } from 'components';

const AdminAccountSettingsBlock = ({ email, updateEmail }) => {
  const initialError = {vis:false, text:""}
  const emailRef = useRef()
  const inputsRef = useRef()
  const [ editing, toggleEditing ] = useState(false);
  const [ textInput, setTextInput ] = useState("")
  const [ showConf, toggleConf ] = useState(false)
  const [ showSpinner, toggleSpinner ] = useState(false)
  const [ error, toggleError ] = useState(initialError)

  useEffect(()=>{
    if(editing){
      emailRef.current.focus();
      toggleConf(false)
    }
  },[editing])

  const handleSubmit = async () => {
    const regex = /.+@.+\..+/i;
    
    
    if(textInput==="" || !regex.test(textInput)){
      addAnimation(inputsRef, 'shake', 500)
      return toggleError({vis:true, text:'Please enter a valid email address'})
    }
    
    toggleSpinner(true)
    const res = await sendToApiReturn('plus_update_email', {email:textInput});
    toggleSpinner(false)
    if(res.invoice){
      updateEmail(res.invoice)
      toggleEditing(false);
      toggleError(initialError);
      toggleConf(true);
    }
  }

  return (
    <div className={`${styles.accountSettings} ${styles.block}`}>
      <h4>Account Settings</h4>
      <p className={styles.italic}> The email to which we send the monthly invoice</p>
      <div className={styles.contentSwitch}>
        {showSpinner && <LoadingOverlay blueSpinner contained /> }
        <div className={`${styles.nameRow} ${editing && styles.hidden}`}>
          <p className={styles.heavy}>{email}</p>
          {showConf && 
          <>
            <img style={{margin:"0 10px", height:"18px"}} src={tick} />
            <p className={styles.confirmation}>updated</p>
          </>
          }
        </div>
        <div ref={inputsRef} className={`${styles.inputsDiv} ${ !editing && styles.hidden}`}>
          <label> Email </label>
            <input ref={emailRef} onChange={e=>{setTextInput(e.target.value); toggleError(initialError)}} value={textInput} type="mail" name="accountEmail"/>
        </div>
      </div>
      <div className={styles.buttons}>
        {
          !editing ?
            <div onClick={()=>toggleEditing(true)} className={styles.actionBtn}>
              <img src={pencil} alt="pencil" />
              <p>Edit</p>
            </div>
          :
            <div onClick={()=>{toggleEditing(false); setTextInput("")}} className={styles.actionBtn}>
              <p>Cancel</p>
            </div>
        }
            <div onClick={handleSubmit} className={`${styles.actionBtn} ${styles.update} ${!editing && styles.hidden}`}>
              <p>Update</p>
            </div>
            {error.vis && <p className={styles.error}>{error.text}</p> }

      </div>
    </div>
  )

}

export default AdminAccountSettingsBlock;