import React, { useState } from 'react';
import EmailDropdown from './EmailDropdown';

import styles from './ClassStudentRow.module.scss';
import coins from 'assets/coins.svg';
import user from 'assets/user_blue.svg';
import deleteIcon from 'assets/icon-delete_red.svg';
import keyIcon from 'assets/icon_key.svg';
import userGrey from 'assets/user_grey.svg'
import { showModal } from 'helpers/uiFunctions';
import { Link } from 'react-router-dom';
import { sendToApiReturn } from 'API/teacherCalls';

const ClassStudentRow = ({ synod, zIndex, idx, modalOverlay, classId, updateStudents, classData, setClassData, student: { approved, studentName, studentEmail, boomerBucks, studentId, parentEmail, grade, boomerUrl }}) => {

  const [ dropVis, toggleDropVis ] = useState(false)
  const [ localApproved, setLocalApproved ] = useState(approved)
  const [ rejected, setRejected ] = useState(false)

  const suffix = grade===1?'st':grade===2?'nd':'th'

  const newFlag = array => array.some(i => !i.approved)


  const handleApprove = async () => {
    setLocalApproved(true)
    const data = await sendToApiReturn(`class/${classId}/approve_student/${studentId}`)
    console.log(data)
    setClassData({...classData, pendingStudents:newFlag(data)})
    console.log(newFlag(data))


  }
  const handleReject = async () => {
    setRejected(true)
    const data = await sendToApiReturn(`class/${classId}/reject_student/${studentId}`)
    console.log(data)
    setClassData({...classData, pendingStudents:newFlag(data)})
    console.log(newFlag(data))
  }

  return (
    <div className={`${styles.container} ${synod && styles.synod} ${rejected && styles.hidden}`} style={{ zIndex }}>

      <Link to={`/teacher/viewStudent/${classId}/${studentId}/1`} className={`${styles.column} ${styles.one}`}>
       {!synod &&  <img src={boomerUrl} alt="avatar" />}
        <div className={styles.text}>
          <p>{studentName}</p>
          <p className={styles.email}>{studentEmail}</p>
        </div>
        <div className={styles.profileBtn}>
          <p>open profile</p>
          <img src={user} alt="user" />
        </div>
      </Link>

      {
        localApproved ? 

          <>
            <div className={`${styles.column} ${styles.two}`}>
              <EmailDropdown studentId={studentId} modalOverlay={modalOverlay} array={parentEmail} />
            </div>

            <div className={styles.column}>
              <img src={coins} alt={"coins"} />
              <p>{boomerBucks}</p>
            </div>

            <div className={`${styles.column} ${styles.last}`}>
              {/* <p>{grade}{suffix}</p> */}
              <div onMouseLeave={() => toggleDropVis(false)} onClick={() => toggleDropVis(true)} className={styles.dots}>
                <div className={`${styles.dropdown} ${dropVis && styles.show}`}>

                  {!synod &&
                    <Link to={`/teacher/viewStudent/${classId}/${studentId}/1`} className={styles.dropRow}>
                      <img src={userGrey} alt="user" />
                      <p>View profile</p>
                    </Link>
                  }

                  <div onClick={()=>showModal(modalOverlay, {type:'loginLink', studentId, studentEmail })} className={styles.dropRow}>
                    <img className={styles.key} src={keyIcon} alt="user" />
                    <p>Email login link</p>
                  </div>
{/* 
                  <div onClick={()=>showModal(modalOverlay, {type:'loginCode', studentName, studentEmail, studentId})} className={styles.dropRow}>
                    <img className={styles.key} src={keyIcon} alt="user" />
                    <p>Generate new login code</p>
                  </div> */}

                  <div onClick={()=>showModal(modalOverlay, { type:'remove', variant:'student', classObj:{classId}, func:updateStudents, studentArray:[studentId], index:idx })} className={`${styles.dropRow} ${styles.red}`}>
                    <img className={styles.delete} src={deleteIcon} alt="user" />
                    <p>Remove</p>
                  </div>

                </div>
              </div>
            </div>

          </>

        :

          <div className={styles.approveBtns}>
            <p>A new student joined the class</p>
            <div className={styles.btns}>
              <div onClick={handleApprove} className={styles.approveBtn}>Approve</div>
              <div onClick={handleReject} className={styles.rejectBtn}>Reject</div>
            </div>
          </div>

      }


    </div>
  )

}

export default ClassStudentRow;