import { dateFormatYear } from 'helpers/dateFunctions';
import React, { useState, useRef, useEffect } from 'react';
import spinner from 'assets/spinner-sml--fast.svg';
import styles from './FamilyPlusSettings.module.scss';
import { getFromApi, sendToApi } from 'API/parentCalls';
import { sendToApiReturn } from 'API/teacherCalls';

const FamilyPlusSettings = () => {

  // const subData = {
  //   subscription:'Family +',
  //   price:15.99,
  //   renewalDate:new Date(2020, 11, 25),
  //   cardEnding:4242,
  //   trialDaysLeft:0,
  // }
  const [ subData, setSubData ] = useState({renewalDate:null,price:'-',cardEnding:''})
  const [ error, setError ] = useState({vis:false, conf:false, text:''});
  const [ showSpinner, setShowSpinner ] = useState(false);
  const [ updateRes, setUpdateRes ] = useState(null);
  const cardRef = useRef();
  const formRef = useRef();
  const nameRef = useRef();
  const numberRef = useRef();
  const expRef = useRef();
  const cvcRef = useRef();
  let regex = /[0-9]|\./;

  const callApi = async () => {
    setSubData(await getFromApi('haunt_details'))
  }

  useEffect(()=>{callApi()},[])

  useEffect(()=>{
    if(updateRes){
      setShowSpinner(false);

      if(updateRes.message==='error'){
        setError({vis:true, conf:false, text:'There was a problem, please check your card details'})

      }else if (updateRes.message==='success'){
        setError({vis:false, conf:true, text:''})
        setSubData({...subData, cardEnding:updateRes.cardEnding})
        nameRef.current.value=''
        numberRef.current.value=''
        expRef.current.value=''
        cvcRef.current.value=''
        cardRef.current.value=''
      }
    }

  },[updateRes])
    
  
  const handleCreditCard = async () =>{
    setError({...error, vis:false, conf:false})
    if(nameRef.current.value===""||numberRef.current.value===""||expRef.current.value===""||cvcRef.current.value===""){
      formRef.current.classList.add('shake')
      setTimeout(()=>{formRef.current.classList.remove('shake')},500)
      setError({vis:true, text:'Please complete all fields'})

    } else {
      setShowSpinner(true)
      const payload={
        name:nameRef.current.value,
        card_number:numberRef.current.value.split(' ').join(''),
        expiration_date:expRef.current.value,
        cvc:cvcRef.current.value
      }
      const res = await sendToApiReturn('haunt_update_card', payload)
      setUpdateRes(res)
    }
  }

  const formatInput = (e, variant)  => {

    if(variant==='card' && e.key!=='Backspace' && e.key!=='Tab'){

      const x = e.target;
      if(x.value.length === 4){x.value=x.value+' '}
      if(x.value.length === 9){x.value=x.value+' '}
      if(x.value.length === 14){x.value=x.value+' '}

    } 

    if(variant==='month' && e.key!=='Backspace' && e.key!=='Tab' ){
      const x = e.target;
      if(x.value.length === 2){x.value=x.value+'/'}
    }
    

    if( !regex.test(e.key) && e.key!=='Backspace' && e.key!=='Tab' ) {
      e.returnValue = false;
      if(e.preventDefault) e.preventDefault();
    }
  }


  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <h4>Your Subscription</h4>

        <div className={styles.row}>
          <p>Monthly price: </p>
          <p>{subData.price}</p>
        </div>

        <div className={styles.row}>
          <p>Automatic renewal date: </p>
          <p>{!subData.renewalDate ? '-' : dateFormatYear(subData.renewalDate)}</p>
        </div>

        {/* <div className={styles.row}>

          {
            <p>Escribli {subData.subscription} plan</p>
          }

          <p>{subData.subscription !== 'trial' ? `$${subData.price}` : '$0.00'}</p>
        </div>
        <div className={styles.row}>

          <p>{subData.subscription !== 'trial' ? 'Automatic renewal date' : 'Trial days remaining'}</p>

          <p>{subData.subscription !== 'trial' ? dateFormatYear(subData.renewalDate) : subData.trialDaysLeft }</p>


        </div> */}
        <div className={styles.row}>
          <p>Current credit card info</p>
          {
          subData.cardEnding === '****' ?
            <p className={styles.cardNumber}>No card on file</p>
          :
          subData.cardEnding === '' ?
            <p className={styles.cardNumber}>-</p>
          :
            <p className={styles.cardNumber}>Ending in {subData.cardEnding}</p> 
          } 
        </div>
      </div>

        <div ref = { cardRef } className={`${styles.block} ${styles.cardDetails}`}>
          <h4>Update credit card information
          </h4>

          <div ref={formRef} className={styles.form}>
            <label htmlFor="name">Name on card</label>
            <input placeholder="John Doe" type="text" name="name" id="name" ref={nameRef} onChange={()=>{setError({...error, vis:false})}}/>
            <label htmlFor="cardNumber">Card number</label>
            <input 
            ref={numberRef}
            onKeyDown={(e)=>{ formatInput(e, 'card'); setError({...error, vis:false}) }}
            placeholder="0000 0000 0000 0000" 
            type="text" 
            name="number" 
            id="cardNumber" 
            maxLength="19"
            />

          <div className={styles.bottom}>
            <div>
              <label htmlFor="exp">Expiration</label>
              <input onKeyDown={(e)=>{formatInput(e, 'month'); setError({...error, vis:false})}} placeholder="MM/YY" type="text" name="exp" id="exp" maxLength={5} ref={expRef}/>
            </div>
            <div>
              <label htmlFor="cvc">CVC</label>
              <input onKeyDown={(e)=>{formatInput(e, 'card'); setError({...error, vis:false})}} maxLength="3" placeholder="000" type="text" name="cvc" id="cvc" ref={cvcRef}/>
            </div>
          </div>

          </div>
          <div className={styles.buttons}>
            {
              showSpinner && <img src={spinner} alt="spinner" />
            }
            {
              error.vis&&<p className={styles.error}>{error.text}</p>
            }
            {
              error.conf&&<p className={styles.conf}>Information updated succesfully!</p>
            }
            <div className={styles.cancel}>{!showSpinner ? '':''}</div>
            {/* <div className={styles.cancel}>{!showSpinner ? 'Cancel subscription':''}</div> */}
            <div onClick={handleCreditCard} className={styles.btn}>
              Update credit card
            </div>
          </div>
        </div>
    </div>
  )

}

export default FamilyPlusSettings;