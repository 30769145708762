
export const simplifiedActivity = (state='initial', action) => {

  switch(action.type){

    case 'UPDATE_SIMPLIFIED':
      return action.payload
    default:
      return state

  }
}

export const navOpen = (state=false, action) => {

  switch(action.type){

    case 'TOGGLE_NAV_OPEN':
      return action.payload
    default:
      return state

  }
}

export const newMsgs = (state=0, action ) => {

  switch(action.type){

    case 'NEW_MESSAGE':
      console.log('NEW_MESSAGE')
      state++
      return state;

    case 'MODAL_OPENED':
      return 0;

    default:
      return state
      
  }
}

export const env = (state='dev', action) => {
  switch(action.type){

    case 'UPDATE_ENV':
      // console.log('UPDATE_ENV');

      return action.payload
    default:
      return state;
  }
}