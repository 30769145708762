import Auth from 'components/Auth/Auth';
import Logout from 'components/Auth/Logout';
import ActivityDetails from 'components/Teacher/ActivityDetails/ActivityDetails';
import ClassDetails from 'components/Teacher/ClassDetails/ClassDetails';
import NewActivityModal from 'components/Teacher/NewActivityModal/NewActivityModal';
import TeacherDashboard from 'components/Teacher/TeacherDashboard/TeacherDashboard';
import React, { useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import NavSynod from './NavSynod/NavSynod';

import styles from './Synod.module.scss';

const Synod = ({ modalOverlay }) => {

  const newActivityCont =  useRef();

  return (
    <div className={styles.container}>

      
      <Switch>
        <Route exact path='/dashboard' component={() => (
          <NavSynod dash />)} />

        <Route exact path={['/write/:classId/:activityId/:tab?/:sectionIndex?', '/view/:classId']} component={() => (
          <NavSynod />)} />

      </Switch>

      <Switch>

        <Redirect from="/" to="/dashboard" exact />
        <Redirect from="/teacher" to="/dashboard" exact />
        <Redirect from="/teacher/classes" to="/dashboard" exact />

        <Route path='/dashboard' component={() => (
          <TeacherDashboard synod />)} />


        <Route path='/view/:classId' exact component={() => (
          <ClassDetails
            synod
            toggleNewActivityVis={() => newActivityCont.current?.classList.toggle(styles.show)}
            modalOverlay={modalOverlay}/>
        )} />

        <Route exact path='/write/:classId/:activityId/:tab?/:sectionIndex?' component={() => (
          <ActivityDetails synod modalOverlay={modalOverlay} />)} />


        <Route path={['/auth--:jwt']} component={() => (
          <Auth />)} />

        <Route path={'/auth/logout'} component={() => (
          <Logout />)} />

      </Switch>

      <NewActivityModal
        fjUser
        synod
        bannerPadding={false}
        closeModal={() => { newActivityCont.current.classList.remove(styles.show)}}
        newActivityCont={newActivityCont} />


    </div>
  )

}

export default Synod;