import React, { useRef, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Nav,
  Teacher,
  Auth,
  ProfileSettings,
  ReferAndEarn,
  ModalOverlay,
  TeacherCredit,
  Resources,
} from "./components";
import { setQueryItem } from "./Redux/actions";
import Helmet from "react-helmet";
import "./shared.scss";
import "./styles/overrideStyles.scss";
import "./styles/customDatePicker.scss";
import "./styles/altPlatformStyles.scss";
import Logout from "components/Auth/Logout";
import escribliIcon from "./assets/favicons/favicon_escribli.png";
import fjIcon from "./assets/favicons/favicon_faith.ico";
import Synod from "components/Synod/Synod";
import { useUserType } from "context/AltPlatformProvider";
import Manage from "components/Admin/Manage/Manage";
import Subscriptions from "components/Admin/Subscription/Subscriptions";
import RandomAccess from "components/Teacher/RandomAccess/RandomAccess";
function App() {
  const history = useHistory();
  const env = useSelector((state) => state.env);
  const modalOverlayRef = useRef();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.dashData.permissions);
  const location = useLocation();

  //variables for Faith Journal or Synod users
  //these flags apply restyling the entire app
  const { fjUser, synodUser } = useUserType();

  //unmount modal component when user navigates without closing the modal.
  //This prevents the data being cached when it shouldn't be
  useEffect(() => {
    dispatch(setQueryItem({ item: "null" }));
    localStorage.removeItem("chatModal");
  }, [location]);

  useEffect(() => {
    const appHeight = () => {
      document.documentElement.style.setProperty(
        "--viewportHeight",
        `${window.innerHeight}px`
      );
      document.documentElement.style.setProperty(
        "--viewportMinus",
        `${window.innerHeight - 60}px`
      );
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {localStorage.getItem("t") && localStorage.getItem("backUrl") ? (
        <div className="App">
          <Helmet>
            <title> {fjUser ? "Faith Journals" : "Escribli"} </title>
            <meta name={fjUser ? "Faith Journals" : "Escribli"} />
            <link rel="icon" href={fjUser ? fjIcon : escribliIcon} />
          </Helmet>

          {synodUser ? (
            <Synod modalOverlay={modalOverlayRef} />
          ) : (
            <>
              <Nav permissions={permissions} modalOverlay={modalOverlayRef} />

              <Switch>
                <Redirect from="/" to="/teacher" exact />

                <Route
                  path="/teacher"
                  component={() => (
                    <Teacher
                      permissions={permissions}
                      modalOverlay={modalOverlayRef}
                    />
                  )}
                />

                <Route
                  path="/account/:tab"
                  component={() => (
                    <ProfileSettings modalOverlay={modalOverlayRef} />
                  )}
                />

                <Route
                  path="/admin/users"
                  component={() => <Manage modalOverlay={modalOverlayRef} />}
                />

                <Route
                  path="/admin/subscriptions"
                  component={() => <Subscriptions />}
                />

                <Route
                  path="/refer"
                  exact
                  component={() => (
                    <ReferAndEarn modalOverlay={modalOverlayRef} />
                  )}
                />

                <Route
                  path="/credit"
                  exact
                  component={() => (
                    <TeacherCredit modalOverlay={modalOverlayRef} />
                  )}
                />
              </Switch>
            </>
          )}

          <ModalOverlay modalOverlayRef={modalOverlayRef} />
        </div>
      ) : (
        <Switch>
          <Route exact path={"/auth/logout"} component={() => <Logout />} />
          <Route
            path={"/teacher/preview/:activityIdParam"}
            component={() => <RandomAccess modalOverlay={modalOverlayRef} />}
          />
          <Route path={"/"} component={() => <Auth />} />
        </Switch>
      )}

      <Route path={"/auth:subRoute?"} component={() => <Auth />} />
    </>
  );
}

export default App;
