import React from 'react';

import styles from './EntryModalImgPreview.module.scss';

const EntryModalImgPreview = ({ previewImgs, previewImgs: { imgs, idx }, setPreviewImgs }) => {


  const handleArrow = left => {
    setPreviewImgs({...previewImgs, idx: left ? idx-1 : idx+1})
  }

  return (
    <div className={styles.container}>
      <div className={styles.imgWrapper}>
        <div onClick={()=>setPreviewImgs(null)} className={styles.close} />
        <img src={imgs[idx].fileUrl} alt="" />

        <div onClick={()=>handleArrow(true)} className={`${styles.arrow} ${idx===0 && styles.hidden}`}> 
          <div className={styles.icon}/> 
        </div>
        <div onClick={()=>handleArrow(false)} className={`${styles.arrow} ${idx===imgs.length-1 && styles.hidden} ${styles.right}`}> 
          <div className={styles.icon}/>
        </div>

      </div>
    </div>
  )

}

export default EntryModalImgPreview;