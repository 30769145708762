import axios from "axios";
import { redirectStatic } from "helpers/redirectFunctions";

export const getFromApi = async (query, action) => {
  const token = localStorage.getItem("t");
  const backUrl = localStorage.getItem("backUrl");
  const url = `${backUrl}/api/teachers/${query}`;

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: token || "" },
    });
    console.log("data", data);

    if (action === "stripePayment" && data.pi_id) {
      localStorage.setItem("pi_id", data.pi_id);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("t");
      redirectStatic("auth/logout");
    }
    console.log(error);
  }
};

export const getFromApiRandom = async (query, action) => {
  // default to production location
  let baseUrl = `https://backapi.faith-journals.com/api/teachers`;

  // set correct location for the API for dev and staging
  if (window.location.host === "localhost:3000") {
    baseUrl = `http://localhost:3007/api/teachers`;
  } else if (window.location.host === "app.sand.faith-journals.com") {
    baseUrl = `https://sand.backapi.faith-journals.com/api/teachers`;
  }

  const url = `${baseUrl}/${query}`;

  try {
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
    }
    console.log(error);
  }
};

export const sendToApi = (query, newData) => {
  const token = localStorage.getItem("t");
  const backUrl = localStorage.getItem("backUrl");
  const url = `${backUrl}/api/teachers/${query}`;
  console.log("Backend URL: ", url);

  axios
    .post(url, newData, { headers: { Authorization: token || "" } })

    .then((response) => {
      console.log(response);
    })

    .catch((error) => {
      console.log(error);
    });
};

export const sendToApiReturn = async (query, newData) => {
  const token = localStorage.getItem("t");
  const backUrl = localStorage.getItem("backUrl");
  const url = `${backUrl}/api/teachers/${query}`;
  console.log(url);

  const { data } = await axios.post(url, newData, {
    headers: { Authorization: token || "" },
  });
  console.log(newData);
  console.log(data);

  return data;
};

export const sendToApiReturnFull = async (query, newData) => {
  const token = localStorage.getItem("t");
  const backUrl = localStorage.getItem("backUrl");
  const url = `${backUrl}/api/teachers/${query}`;

  const res = await axios.post(url, newData, {
    headers: { Authorization: token || "" },
  });
  console.log(res);

  return res;
};
