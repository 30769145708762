import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'
import styles from './PaymentModal.module.scss';
import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import loader from 'assets/Spinner.svg'
import spinnerBlue from 'assets/spinner-sml--fast.svg';
import { useHistory } from 'react-router-dom';
import { store } from 'storeConfig';
import { updateDashData } from 'Redux/actions';



const PaymentModal = ({item}) => {



  const [ apiResponse, setApiResponse ] = useState('initial')
  const errorRef = useRef()
  const loadOverRef = useRef()
  const confRef = useRef()
  const btnRef = useRef()
  const couponCodeRef = useRef()
  const history = useHistory()
  const [ couponVis, setCouponVis ] = useState(false)
  const [ confVis, setConfVis ] = useState(false)
  const [ errorVis, setErrorVis ] = useState(false)
  const [ codeInput, setCodeInput ] = useState('');
  const [ btnState, setBtnState ] = useState('initial');


  const term = (item.payment==='yearly'?1:0);
  const number=(item.case==='basic'?1:item.case==='complete'?2:0);
  const cnt = (item.payment!=='yearly'?1:item.months);
  const pi_id = localStorage.getItem("pi_id")



  const callAPI = async (discountCode) => { 


    if (!pi_id) { // if we do not have a pi_id in localStorage

      
      console.log('NO PI_ID DETECTED')

      if(item.discount){ // if user arrived here through discount code URL (email)
        const URL = `plus_purchase?discountCode=${item.discount}` + `${ pi_id ? `&pi_id=${pi_id}` : '' }` 
        setApiResponse(await getFromApi(URL, 'stripePayment'))
        
      } else if(discountCode){ // if the user has input a code on the UI
        setApiResponse(await getFromApi(`plus_purchase?discountCode=${discountCode}`, 'stripePayment'))
        
      } else {
        setApiResponse(await getFromApi(`plus_purchase`, 'stripePayment'))
      }
      
    } else {
      
      console.log('PI_ID DETECTED')

      if(item.discount){// if user arrived here through discount code URL (email)
        const URL = `plus_purchase?discountCode=${item.discount}` + `${ pi_id ? `&pi_id=${pi_id}` : '' }` 
        setApiResponse(await getFromApi(URL, 'stripePayment'))

      } else if(discountCode){ // if the user has input a code on the UI
        setApiResponse(await getFromApi(`plus_purchase?pi_id=${localStorage.getItem("pi_id")}&discountCode=${discountCode}`, 'stripePayment'))

      }else {
        setApiResponse(await getFromApi(`plus_purchase?pi_id=${localStorage.getItem("pi_id")}`, 'stripePayment'))
      }

    }
  }


  useEffect(() => { callAPI() }, []);

  const {email, name} = useSelector(state=>state.dashData)
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
        '.__PrivateStripeElement': {
          border:'1px solid chartreuse !important'
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadOverRef.current.classList.add(styles.show)

    if (!stripe || !elements) { return; }

    const result = await stripe.confirmCardPayment(apiResponse.cs, {

      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email:email
        },
      }

      
    });  
    console.log(elements.getElement(CardElement))
    
    if (result.error) {
      loadOverRef.current.classList.remove(styles.show)
      errorRef.current.innerText=result.error.message;
      console.log(result.error.message);
    } else {
      
      if (result.paymentIntent.status === 'succeeded') {
        loadOverRef.current.classList.remove(styles.show)
        confRef.current.classList.add(styles.show)

        if(item.discount){ // success of payment when user arrived here through discount URL

          const trimmedDiscount = item.discount.substring(2);
          const type = item.discount.charAt(0)
          const res = await sendToApiReturn(`purchase_success/${apiResponse.pi_id}?type=${type}&${trimmedDiscount}`)
          store.dispatch(updateDashData('subscription', res.subscription))

          setTimeout(()=>{ history.push('/teacher/account/manage')},800)

          

        } else { // regular success
          
          const res = await sendToApiReturn(`purchase_success/${apiResponse.pi_id}?type=${number}&cnt=${cnt}`)
          store.dispatch(updateDashData('subscription', res.subscription))
          setTimeout(()=>{ history.push('/teacher/account/manage')},800)
        }
      }
    }
    
  };


  const handleCode = () => {

    if(codeInput!==''){
      callAPI(codeInput);
      setBtnState('loading')    
      btnRef.current.classList.add(styles.revert)
      // setTimeout(()=>{btnRef.current.classList.remove(styles.revert)},1000)
    }else{
      couponCodeRef.current.classList.add('shakeSm')
      setTimeout(()=>{couponCodeRef.current.classList.remove('shakeSm')},600)
    }
  }


  useEffect(()=>{
    if(apiResponse && apiResponse.discount){
      if(apiResponse.discount==='success'){
        setCouponVis(false)
        setConfVis(true)
        setBtnState('initial')
        
      } else {
        setErrorVis(true)
        setBtnState('initial')
      }
    }
  },[apiResponse])




  return !apiResponse || apiResponse==='initial' ? <img src={spinnerBlue} style={{height:"70px", padding:"120px 0"}} alt="spinner" /> : (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.subRow}>
          <h6>Subscription to {item.case==='basic' ? 'Escribli+':'Escribli+  Complete'} :  ${apiResponse ? apiResponse.price : ''}</h6>
          {/* <div className={styles.couponDiv}>
          { apiResponse && apiResponse.price ?

            couponVis ?
              <>
                <input 
                  onChange={(e)=>{
                    setCodeInput(e.target.value); 
                    setErrorVis(false)
                    btnRef.current.classList.remove(styles.revert)}
                  } 
                  placeholder="Enter code..."
                  type="text" 
                  name="couponCode" 
                  autoFocus 
                  ref={couponCodeRef}
                />
                {errorVis && <p className={styles.error}>invalid code</p>}
                <div ref={btnRef} onClick={handleCode} className={styles.btn}>
                  {
                    btnState === 'initial' ?
                      "Apply"
                    :

                      <img src={spinner} alt="spinner" />

                  }
                </div>
              </>

          : confVis ? 

              <p className={styles.conf}>Discount applied!</p>
              
          :
              
             !item.discount && <p onClick={()=>{setCouponVis(true)}}>I have a discount code</p>
          

          :null}
        </div> */}
      </div>
      <h6>{name}({email})</h6>

      </div>

      <div className={styles.details}>
        <h4>Billing Details</h4>
        <p>Please enter your card details below</p>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <div ref={errorRef} className={styles.errorMsg}></div>
        <form onSubmit={handleSubmit}>
        <button style={{margin:'12px 0 0 0'}} disabled={!stripe}>Confirm order</button>
      </form>

      </div>

      <div className={styles.bottom}>
        <h6>Subscription can be cancelled at any time</h6>
      </div>

      <div ref={loadOverRef} className={styles.loadingOverlay}>
        <img src={loader} alt="loader"/>
        <p>processing...</p>
      </div>
      <div ref={confRef} className={styles.confMsg}>
        <h4>Payment successful!</h4>
        <p>Thank you for your order.</p>
      </div>
    </div>
  )

}

export default PaymentModal;