import { openInNewTab } from 'helpers/uiFunctions';
import React, { useState } from 'react';
import pencil from 'assets/icon-pencil--black.png';

import styles from './StnEmailPreview.module.scss';
import { useRef } from 'react';
import { redirectStaticStore } from 'helpers/redirectFunctions';

const StnEmailPreview = ({setContState, loginCode, emailText, setEmailText, variant, isBee, bookCode}) => {
  
  const handleCopy = (e) => {
    setVis({...vis, copy:true});
    e.preventDefault();    
    setTimeout(()=>{setVis({...vis, copy:false})},1000)
    navigator.clipboard.writeText(loginCode)
  }
  
  
  const emailTextRef = useRef();
  const [ vis, setVis ] = useState({copy:false, edit:false})

  return (
    <div className={styles.container}>
      <div className={styles.block}></div>
      <h1>Email preview</h1>
      <h5>Hello Families,</h5>

      <div className={vis.edit?`${styles.textDiv} ${styles.edit}`: styles.textDiv}>

        {!vis.edit &&
          <div onClick={()=>{setVis({...vis, edit:true})}} className={styles.editBtn}>
            <img src={pencil} alt="edit"/>
            <div className={styles.tooltip}>Edit copy</div>
          </div>
        }

        {
          !vis.edit ?
            
              emailText.split('\n').map(p => <p>{p}</p> )
            
          :

          <>
            <textarea 
              type="text" 
              name="emailTextInput" 
              ref={emailTextRef} 
              autoFocus
              defaultValue={emailText}
              />

            <div className={styles.editBottom}>
              <p onClick={()=>{setVis({...vis, edit:false})}} >cancel</p>
              <div onClick={()=>{setEmailText(emailTextRef.current.value); setVis({...vis, edit:false})}} className={styles.saveBtn}>Save changes</div>
            </div>


          </>

        }
      </div>

      <p>{!isBee ? "The Boomwriter Bookstore is now accepting orders for our class story using the store’s link and our class story’s unique code:}":"You can order your child’s personalized copy of the book using the store’s link below and our class story’s unique code:"}</p>
      {
        !isBee ?
          <p onClick={() =>redirectStaticStore('') } className={styles.link}>boomwriter.com/store</p>
          :
          <p style={{margin:"30px 0 50px 0"}} onClick={() =>redirectStaticStore(bookCode) } className={styles.link}>boomwriter.com/store/{bookCode}</p>
      }
      {!isBee && 
        <>
          <p>and enter this code:</p>
          <div onClick={(e)=>{handleCopy(e)}} className={styles.codeDiv}>
            {!vis.copy ? loginCode : <p>copied</p>}
            <div className={styles.tooltip}>Copy</div>
          </div>
        </>
      }
      <div className={isBee ? `${styles.bookImg} ${styles.bee}` :  variant === 'story' ? `${styles.bookImg} ${styles.story}` :  `${styles.bookImg} ${styles.journal}`}></div>

      <div className={styles.backBtn} onClick={()=>{setContState('main')}}>Back to list</div>

      {/* <h4 onClick={()=>{setContState('main')}}>{"< back to list"}</h4> */}
    </div>
  )

}

export default StnEmailPreview;