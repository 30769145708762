import React, { useEffect, useRef, useState } from 'react';

import styles from './Subscription.module.scss';
import stripeLogo from 'assets/poweredByStripe.svg';
import cardIcon from 'assets/icon-visa--card.svg';
import { formatDataInput } from 'helpers/formatDataInput';
import { addAnimation } from 'helpers/uiFunctions';
import { sendToApiReturn } from 'API/teacherCalls';
import { LoadingOverlay } from 'components';
import tick from 'assets/teacher/icon-circleTick.svg'
import warning from 'assets/warning_red.svg'

const AdminPaymentDetailsBlock = ({ lastFourDigits, updateCard, empty }) => {
  const initialErrorState = {vis:false, text:''}
  const cardNumberRef = useRef();
  const contentRef = useRef();
  const expRef = useRef();
  const cvcRef = useRef();
  const [ editing, toggleEditing ] = useState(false);
  const [ error, setError ] = useState(initialErrorState);
  const [ showSpinner, toggleSpinner ] = useState(false);
  const [ showConf, toggleConf ] = useState(false)

  const resetErrors = () =>   setError(initialErrorState);
  const resetInputs = () => {
    cardNumberRef.current.value="";
    expRef.current.value="";
    cvcRef.current.value="";
  }

  useEffect(()=>{

    resetErrors()

    if(editing){
      cardNumberRef.current.focus();
      toggleConf(false)

    } else {
      resetInputs()
    }
  },[editing])

  if(empty){
    addAnimation(contentRef, 'shake', 500)
    setError({vis:true, text:'Please complete all fields'}) 
  }
    
  const handleCreditCard = async () =>{

    setError({...error, vis:false, conf:false})

    if(
      cardNumberRef.current.value===""||
      expRef.current.value===""||
      cvcRef.current.value===""
      ){

      addAnimation(contentRef, 'shake', 500)
      setError({vis:true, text:'Please complete all fields'})

    } else {
      
      
      toggleSpinner(true)
      const payload={
        card_number:cardNumberRef.current.value.split(' ').join(''),
        expiration_date:expRef.current.value,
        cvc:cvcRef.current.value
      }
      const res = await sendToApiReturn('plus_update_card', payload )

      if(res.message){
        addAnimation(contentRef, 'shake', 500)
        setError({vis:true, text: "Something went wrong, please check the details you provided"})
      }else {

        updateCard(res.cardEnding);
        toggleEditing(false);
        toggleConf(true)
      }
      toggleSpinner(false)
    }

  }

  return (
    <div className={`${styles.paymentDetails} ${styles.block}`}>
      <h4>Payment Method</h4>

      <img className={styles.stripeLogo} src={stripeLogo} alt="Stripe logo" />
      <div ref={contentRef} className={styles.contentSwitch}>
        {showSpinner && <LoadingOverlay blueSpinner contained /> }
        <div className={`${styles.cardNumberDiv} ${editing && styles.hidden}`}>
          {
            lastFourDigits === "****" ? 
              <div className={styles.warningDiv}>
                <img src={warning} alt="waring" />
                <p className={styles.noCardOnFile}>No card on file, please update your card information.</p>
              </div>

            :
              <>
                <img src={cardIcon} alt="Card icon" />
                <p className={styles.cardNumber}><span>●●●● ●●●● ●●●●</span> {lastFourDigits}</p>
                {showConf && 
                <>
                  <img style={{marginLeft:"10px"}} src={tick} />
                  <p className={styles.confirmation}>updated</p>
                </>
                }
              </>
          }
        </div>

        <div className={`${styles.inputsDiv} ${!editing && styles.hidden}`}>
         
          <label>Card Number</label>
          <input
            ref={cardNumberRef}
            className={styles.fullWidth} 
            onKeyDown={(e)=>{ formatDataInput(e, 'card'); resetErrors() }}
            placeholder="0000 0000 0000 0000" 
            type="text" 
            name="number" 
            id="cardNumber" 
            maxLength="19" />

          <div className={styles.smallInputs}>
              <label>Expiration Date</label>
              <div className={styles.dates}>
                <input maxLength="5" onKeyDown={e=>{formatDataInput(e, 'month');resetErrors()}} ref={expRef} placeholder={"MM/YY"} type="text"/>
                <input maxLength="3" onKeyDown={()=>resetErrors()} ref={cvcRef} placeholder={"CVV"} className={styles.cvv} type="text"/>
                {/* <input ref={yearRef} placeholder={"YYYY"} type="text"/> */}
              </div>
          </div>

        </div>


      </div>


      <div className={styles.buttons}>
        {
          !editing ?
            <div onClick={()=>toggleEditing(true)} className={styles.actionBtn}>
              <p>Update Card</p>
            </div>
          :
            <div onClick={()=>toggleEditing(false)} className={styles.actionBtn}>
              <p>Cancel</p>
            </div>
        }
            <div onClick={handleCreditCard} className={`${styles.actionBtn} ${styles.update} ${!editing && styles.hidden}`}>
              <p>Update Card</p>
            </div>
            {error.vis && <p className={styles.error}>{error.text}</p> }
      </div>
    </div>
  )

}

export default AdminPaymentDetailsBlock;