import React, { Fragment } from 'react';

import styles from './MiniYouModal.module.scss';
import mini from '../../../../assets/modalContent/mini_me.png';
import mini_fj from '../../../../assets/Boomers/boomer_FJ2.png';
import menuSnippet from '../../../../assets/modalContent/menu-snippet.png';
import menuSnippet_FJ from '../../../../assets/modalContent/menu_snippet_fj.png';
import info from '../../../../assets/modalContent/info.png'
import { sendToApiReturn } from 'API/teacherCalls';

const MiniYouModal = ({ item:{fjUser}}) => {

  const handleClick = async () => {
    const res = await sendToApiReturn('mini_me', '', 'mini-me')
    localStorage.setItem('resURL', res.url)
    window.location.href = res.url;
  }

return (
  <Fragment>
    
    <div className={styles.top}>
      <h1 className={styles.title}>Your "mini You" student!</h1>
    </div>

    <div className={styles.main}>
      <div className={styles.inner}>
        <img src={mini} alt="" />
          <p>
          Have your own student account in your classes. It is a “mini you” - your own student profile so that you can see what students see and participate as one more student in class activities.
          </p>
      </div>
    </div>

    <div className={styles.details}>
        <img src={!fjUser ? menuSnippet : menuSnippet_FJ} alt="menu snippet" />
        <div className={styles.switchDiv}>
          <img src={info} alt="info" />
          <p>You can switch between teacher and student account in the profile section.</p>
        </div>
      </div>

    <div className={styles.bottom}>
      <div onClick={handleClick} className={styles.gotIt}>Login as student</div>
    </div>

  </Fragment>

)
}

export default MiniYouModal;