import React, { useEffect, useRef, useState } from 'react';

import styles from './ConnectYourChildModal.module.scss';
import InitState from './InitState';
import CreateChild from './CreateChild';
import FindChild from './FindChild';
import YourChild from './YourChild';

const ConnectYourChildModal = ({closeBtn, item:{setKids, kids, setSelected}}) => {

  const contRef = useRef();
  const [ contState, setContState ] = useState('init');
  const [ childCode, setChildCode ] = useState('');
  const [ error, setError ] = useState(false)
  const [ conf, setConf ] = useState(false);
  const [ apiRes, setApiRes ] = useState({})

  useEffect(()=>{
  
    if(apiRes&&apiRes.student){
      
      if(!apiRes.student.stage){ //switch to 'details' state if creating student, not confirming
        setContState('details');
        contRef.current.classList.remove(styles.init);
        contRef.current.classList.add(styles.details);

      } else {
        setTimeout(()=>{
          closeBtn.current.click()
        },500)
      }

      if(apiRes.dashboard){
        console.log(apiRes.dashboard)
        console.log(apiRes.dashboard[apiRes.dashboard.length-1])
        setKids(apiRes.dashboard);
        setSelected(apiRes.dashboard[apiRes.dashboard.length-1])
        setTimeout(()=>{
          const newUiElem = apiRes.studentName ? //difference between creating student and confirming existing student
          document.getElementById(apiRes.studentId):
          document.getElementById(apiRes.student.studentId);
          const scrollDiv = document.getElementById("kidsInner");

          if(scrollDiv){
            scrollDiv.scrollLeft=scrollDiv.scrollWidth // regular dash
          } else {
            const childSelector = document.getElementById("childSelector"); //family plus dash
            childSelector.scrollLeft=childSelector.scrollWidth;
            setSelected(kids[kids.length-1])
          }
          
          if(newUiElem){newUiElem.click()}
        },200)
      }
      
    } else if(apiRes.message){
      console.log(apiRes.message)
    }
  },[apiRes])

  const handleReset = () => {
    setContState('init')
    contRef.current.classList.remove(styles.details)
    contRef.current.classList.add(styles.init);
  }

  const handleCopy = (input) => {  
    setConf(true);
    setTimeout(()=>{setConf(false)},1500)
    navigator.clipboard.writeText(input) 
  }


  return (
    <div ref={contRef} className={`${styles.container} ${styles.init}`}>

      {
          contState === 'init' ?
            <InitState
              setChildCode = {setChildCode}
              setApiRes={setApiRes}
              apiRes={apiRes} 
              contRef={contRef} 
              setContState={setContState} 
            />

        : contState === 'create' ?

            <CreateChild 
              setApiRes={setApiRes}
              apiRes={apiRes}
            />

        : contState === 'find' ?

            <FindChild 
              setContState={setContState} 
              contRef={contRef}
            />
          
        : contState === 'yourChild' ?

            <YourChild 
              childCode={childCode}
              setApiRes={setApiRes}
              apiRes={apiRes}
              setContState={setContState} 
              contRef={contRef}
            />
          
        : contState === 'details' ?

            apiRes.student ? 
                <>
                  <h1>Student login details</h1>
                  <p>Username: <strong>{apiRes.student.studentEmail}</strong> </p>
                  <div className={styles.loginRow}>
                    <p>Login code: </p>
                    <div className={styles.loginCode}>
                      <div onClick={()=>{handleCopy(apiRes.student.loginCode)}} className={styles.codeDiv}>
                        {apiRes.student.loginCode}
                        <div className={styles.tooltip}>Copy to clipboard</div>
                      </div>
                      {conf &&  <p className={styles.conf}>Copied to clipboard</p> }
                    </div>
                  </div>
                  <div onClick={()=>closeBtn.current.click()} className={styles.dashBtn}>Go to dashboard</div>
                </>

            :
              
                <div className={styles.errorDiv}>
                <h4>Oops! Something went wrong, please <span onClick={handleReset}>try agin</span></h4>
                </div>
          
          //<-----end of 'details' div

        : null
        }

    </div>
  )

}

export default ConnectYourChildModal;