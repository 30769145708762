import { updateClassKey } from 'Redux/actions'
import { store } from 'storeConfig'

//send to API

export const updateFolders = ( data ) => {
  const newFolders = data.folders
  store.dispatch(updateClassKey('folders', newFolders))
}

export const createStudent = (data, classId, existingStudents) => {
  console.log(data)
  const array = data.classIds;
  if(array.includes(classId)){
    let newStudentArray = [...existingStudents];
    newStudentArray.push(data)
    store.dispatch(updateClassKey('students', newStudentArray, classId))
  }
}

export const updateStudentFunc = (response, store, details, fetchedClass, updateClassKey) => {
  details(response.data);
  const sIndex = fetchedClass.students.findIndex(student => student.studentId === response.data.studentId);
  const newArray = [...fetchedClass.students]
  newArray[sIndex] = response.data
  store.dispatch(updateClassKey('students', newArray))

}

export const revisionFeedbackFunc = (response, store, updateSectionKey, details) =>{
  
  const sectionIndex = store.getState().selectedSection;
  const newArr = [...details.entries];
  const sIndex = details.entries.findIndex(item => item.studentId === response.data.studentId);
  newArr[sIndex] = response.data;
  details.setDisplayStudent(response.data);
  store.dispatch(updateSectionKey(sectionIndex, 'entries', newArr))
}