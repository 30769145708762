import { sendToApiReturn } from 'API/teacherCalls';
import React from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { updateActivitySection } from 'Redux/actions';
import { store } from 'storeConfig';
import icon from '../../../../../../assets/sidebarIcons/sidebar_wordbank_blue.svg';

import styles from './WordBankModal.module.scss';

const WordBankModal = ({mobile, activeSection, setActiveSection, activeSection: {wordBank, sectionIndex}}) => {

  const inputRef = useRef()
  const activityId = useSelector(state=>state.activityData.activityId)

  const handleNewWord = async () => {
    if (inputRef.current.value !== '') {
      const newData = {...activeSection, wordBank:[...wordBank, inputRef.current.value]}
      const res = await sendToApiReturn(`activity/${activityId}/update_unit/${sectionIndex}`, newData )
      setActiveSection(res)
      store.dispatch(updateActivitySection(sectionIndex, res))
      inputRef.current.value = '';
    }
  }


  const handleDeleteWord = async idx => {
    wordBank.splice(idx,1)
    const res = await sendToApiReturn(`activity/${activityId}/update_unit/${sectionIndex}`, {...activeSection, wordBank:wordBank})
    store.dispatch(updateActivitySection(sectionIndex, res))
    setActiveSection({...activeSection, wordBank:wordBank})
  }


  const handleKeyPress = e =>  { 
    
    if((e.key === 'Enter' || e.type === 'blur' || e.key === ',')){ 
      //ignore keypresses which are not enter, tab or comma
      return handleNewWord()
    }
  }

  return (
    <div className={`${styles.container} ${mobile && styles.mobile}`}>
      <div className={styles.titleBar}>
        <img src={icon} alt="wordbank" />
        <h2>Word bank</h2>
        <p>you can add many words at once by copying and pasting a list of words separated by a comma and hitting 'enter'</p>
      </div>

      <div className={styles.inputDiv}>
        <input
          onKeyUp={e=>handleKeyPress(e)}
          ref={inputRef}
          autoFocus
          // maxLength={15}
          placeholder={"Add a word"} 
          type="text"/>

        <div onClick={handleNewWord} className={styles.plusBtn} />
      </div>

      <div className={styles.wordsDiv}>
        {
          wordBank?.length ? 

            wordBank.map((word,idx) =>

              <div key={idx} className={styles.wordBankItem}>
                <p>{word}</p>
                <div onClick={()=>handleDeleteWord(idx)} className={styles.deleteWordBtn} />
              </div>
            
            )

          :

            <p className={styles.placeholder}>No words currently in word bank</p>

        }

      </div>

    </div>
  )

}

export default WordBankModal;