import React from 'react';

import styles from './UploadCSVModal.module.scss';
import CsvStepOne from './CsvStepOne';
import CsvStepTwo from './CsvStepTwo';
import CsvStepThree from './CsvStepThree';
import CsvStepFour from './CsvStepFour';
import { useState } from 'react';
import { sendToApiReturn, getFromApi } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { useUserType } from 'context/AltPlatformProvider';

const UploadCSVModal = ({ closeBtn, item:{classObj, classId, onboarding, updateStudents }}) => {

  const [ step, setStep ] = useState(1);
  const [ apiRes, setApiRes ] = useState([])
  const [ apiRes2, setApiRes2 ] = useState([]);
  const env = store.getState().env
  const { synodUser } = useUserType();


  const nextStep = async () => {

    if(step==2){
      setApiRes2(await sendToApiReturn(`class/${classId}/import_csv`))
      updateStudents(await getFromApi(`class/${classId}/students`))
    }

    setStep(step+1)
  }




  return (
    <div className={styles.container}>
    <h1 >Upload CSV</h1>

    { 
      step===1?

        <CsvStepOne synodUser={synodUser} setStep={setStep} classId={classObj.classId} setApiRes={setApiRes} />

      : step === 2 ?

        <CsvStepThree synodUser={synodUser} setStep={setStep} apiRes={apiRes}  setApiRes={setApiRes} /> 

      : step === 3 &&

        <CsvStepFour synodUser={synodUser} setStep={setStep} apiRes2={apiRes2}  setApiRes2={setApiRes2} closeBtn={closeBtn} classObj={classObj} onboarding={onboarding} env={env} updateStudents={updateStudents} /> 

    }

    { 
        step === 2 ?

        <div className={styles.btns}>
          <div onClick={()=>{setStep(step-1)}} className={styles.btn}>back</div>
          <div onClick={()=>{nextStep()}} className={styles.btn}>next</div>
        </div>

    : null }
    </div>
  )
}

export default UploadCSVModal;