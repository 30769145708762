import React, { useState, useEffect, useRef } from 'react';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';

import styles from './ActivityDetailsSidebar.module.scss';
import SettingsModalsContainer from './SettingsModals/SettingsModalsContainer';
import { useSelector } from 'react-redux';

const ActivityDetailsSidebar = ({setView, view, activeSection, setActiveSection, 
                                 isStoryStart, modalOverlay, fjUser }) => {
  
  const [ activeModal, setModal ] = useState(null)
  const contRef = useRef()
  const activity = useSelector(state => state.activityData)

  useOutsideClick(contRef, () => setModal(null) )

  useEffect(()=>setModal(null),[view])

  const showPill = item => item.pill===true || item.pill?.length>0 || item?.pill>0

  const sidebarItems = [
    {
      text:"Deadline",
      itemClass:"deadline",
      pill: null,
    },
    {
      text:"Word Bank",
      itemClass:"wordbank",
      pill: activeSection.wordBank.length,
    },
    {
      text:"Upload Files",
      itemClass:"upload",
      pill: activeSection.files.length,
    },
    {
      text:"Grammar",
      itemClass:"grammar",
      pill: activeSection.shareGrammar,
    },
    {
      text:"Rooms",
      itemClass:"rooms",
      pill: activeSection.rooms,
    },
    {
      text:"Peer Review",
      itemClass:"peer",
      pill: activeSection.voting,
    },
    {
      text:"Grading",
      itemClass:"grading",
      pill: activity.grading,
    },
    {
      text:"Private",
      itemClass:"private",
      pill: activity.privateMode,
    },
  ]

  return (
    <div ref={contRef} className={styles.container}>
      { activeModal && 
        <SettingsModalsContainer
          modalOverlay={modalOverlay}
          setView={setView}
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          setModal={setModal}
          activity={activity}
          selected={activeModal} /> }

      <div className={styles.items}>  
        {
          sidebarItems.map((item,idx) => 

            !(!fjUser && item.itemClass === 'private') &&

              <div onClick={()=>setModal(item.itemClass)} key={idx}
                className={`
                  ${styles.sidebarItem} 
                  ${styles[item.itemClass]} 
                  ${styles.settingsItem} 
                  ${activeModal === item.itemClass && styles.active}
                  ${(isStoryStart && ['grading','private'].indexOf(item.itemClass) === -1) && styles.disabled}
                  ${(fjUser && ['grammar'].indexOf(item.itemClass) !== -1) && styles.hidden}
                `}>
                <div className={styles.icon} />
                
                  <div className={` ${styles.greenPill } ${ showPill(item) && styles.display } `}>
                    {/* //if the item.pill value is a number, display that. If not, display 'ON'
                    // grading is an exception. This will be a number but needs to show 'ON', not the number */}
                    <p>{ (typeof(item.pill) === 'number' && item.itemClass !== 'grading' ) ? item.pill : 'ON' }</p>
                  </div> 
                
                <p>{item.text}</p>
              </div> 
          )
        }
      </div>
    </div>
  )

}

export default ActivityDetailsSidebar;