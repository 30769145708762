import { getFromApi } from 'API/generalCalls';
import React from 'react';
import { useState } from 'react';

import styles from './AdminFilters.module.scss';

const FilterBlock = ({ filterType, setMapItems, activeFilter, setActiveFilter, report, orderBy, textInput }) => {

  const [ dropVis, toggleDropVis ] = useState(false)

  const filterText = 
    filterType == "interval" ? "by date" : "filter"

  const dropdownItems = 
    filterType == "interval" ? 
      [
        {text:"Connected in last 24 hours", value:1},
        {text:"Connected in last 2 weeks", value:14},
        {text:"Connected in past month", value:30},
      ]
    : []

  const handleFilterSelect = async (e, input) => {
    e.stopPropagation()
    toggleDropVis(false)
    setActiveFilter({ 
      type: input == 0 ? "" : filterType ,
      value:input
    })

    const searchQuery = `${textInput!==""?`&search=${textInput}`:""}`

    const url = 
      input == 0 ? 
      `reports/${report}?asc=${orderBy.asc}&filter=${orderBy.key}${searchQuery}` :    // <--- remove filter URL
      `reports/${report}?asc=${orderBy.asc}&filter=${orderBy.key}&${filterType}=${input}${searchQuery}` //<--- add/modify filter URL

    const res = await getFromApi(url)
    setMapItems(res)
  }

  return (
    <div 
      onClick={()=>toggleDropVis(true)} 
      onMouseLeave={()=>toggleDropVis(false)} 
      className={`${styles.filterBlock} ${activeFilter.type == filterType && styles.active}`} >

     {activeFilter.type==filterType && <div onClick={e=>handleFilterSelect(e, 0)} className={`${styles.remove}`}/>}
      <h5>{filterText}</h5>
      <div className={`${styles.icon} ${styles[filterType]}`} />
      <div className={`${styles.dropdown} ${dropVis && styles.show}`}>
        {
          dropdownItems.map((i,idx) => 
            <div onClick={e=>handleFilterSelect(e, i.value)} key={idx} className={`${styles.dropRow} ${activeFilter.value==i.value&&styles.selected}`}>{i.text}</div>
          )
        }
      </div>
    </div>
  )

}

export default FilterBlock;