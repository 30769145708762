import React, { useState } from 'react';

import styles from './ManageSubscriptionModal.module.scss';
import { calculatePlanPrice } from 'helpers/priceFunctions';
import { useHistory } from 'react-router-dom';
import { sendToApiReturn } from 'API/teacherCalls';
import loader from 'assets/Spinner.svg'

const ManageSubscriptionModal = ({modalOverlay, closeBtn, item:{subData, setSubData}}) => {

  const [ planParams, setPlanParams ] = useState({
    paymentPlan:subData.monthsCount===1?'monthly':'multi',
    plusType:subData.subscription==='complete'?2:subData.subscription==='basic'?1:0,
    monthCount:subData.monthsCount,
    localChange:false
  })
  const [ showLoader, toggleLoader ] = useState(false)
  const [ showConf, toggleConf ] = useState(false)

  const payload = {
    cnt:planParams.monthCount,
    term:planParams.paymentPlan==='multi'?1:0
  }

  const { paymentPlan, plusType, monthCount, localChange } = planParams;
  const history = useHistory();
  const setPaymentPlan = () => {

    if(planParams.paymentPlan === 'monthly'){
      setPlanParams({plusType:plusType, paymentPlan:'multi', monthCount:12, localChange:true})
      
    } else{
      setPlanParams({plusType:plusType, paymentPlan:'monthly', monthCount:1, localChange:true})
      
    }
  }

  const handleSubmit = async () => {
    toggleLoader(true)
    const data = await sendToApiReturn(`plus_purchase`, payload)
    console.log(data)
    setSubData(data)
    toggleLoader(false)
    toggleConf(true)

    setTimeout(()=>{ closeBtn.current.click() },2300)
  }

  

  return (
    <div className={`${styles.container} ${showConf && styles.conf}`}>

      <h1>Change your Escribli plan:</h1>

        {
        !showConf ? 
          <>
            <div className={styles.switchContainer}>
              <span>Monthly</span>
              <div className={styles.switch} onClick={setPaymentPlan}>
                <div className={paymentPlan === 'monthly' ? styles.greenBall : `${styles.greenBall} ${styles.right}`}></div>
              </div>
              <span>Multi</span>

              {paymentPlan === 'multi' &&
                <div className={styles.monthInput}>
                  <label for='months'>Number of months</label>
                  <input name='months' type='number'
                    onChange={(e) => setPlanParams({ ...planParams, monthCount: parseInt(e.target.value), localChange: true })}
                    value={monthCount} max={12} min={2} />
                </div>
              }
            </div>

            <div className={`${styles.subscriptionDiv} ${plusType === 1 ? styles.basicPlan : styles.completePlan}`}>
              <h4>Your Subscription</h4>
              <div className={styles.subscriptionRow}>
                <p>{plusType === 1 ? 'Escribli Basic Plan' : 'Escribli Complete plan'}</p>
                <p>${calculatePlanPrice(plusType, monthCount)}/<span className={styles.frequency}>{paymentPlan === 'monthly' ? 'month' : `${monthCount} months`}</span></p>
              </div>
              <p onClick={() => { history.push('/info/pricing') }} className={styles.blue}>See details</p>

              <div className={styles.colorTab}></div>
            </div>

            <div className={styles.buttons}>
              <div onClick={() => closeBtn.current.click()} className={styles.btn}>Cancel</div>
              <div
                onClick={handleSubmit}
                className={localChange ? styles.btn : `${styles.btn} ${styles.disabled}`}>Change Plan</div>
            </div>

          </>
        :
        
        <div className={styles.confDiv}>
          <h3>Plan successfuly updated!</h3>
        </div>
      
      
        }

        

      {showLoader &&
        <div className={styles.loadingOverlay}>
          <img src={loader} alt="loader"/>
          <p>processing...</p>
        </div>
      }
    </div>
  )

}

export default ManageSubscriptionModal;



// <div 
// onClick={()=>{store.dispatch(setQueryItem({
//   type:'paymentModal',
//   payment:paymentPlan==='multi'?'yearly':'monthly', 
//   months:monthCount, 
//   func:(input)=>{setSubData(input)}, 
//   price:calculatePlanPrice(plusType, monthCount), 
//   case:plusType===2?'complete':'basic'
//  }))}}
// className={localChange ? styles.btn : `${styles.btn} ${styles.disabled}`}>Change Plan</div>