import React from 'react';

import styles from './GradingModal.module.scss';
import icon from '../../../../../../assets/sidebarIcons/sidebar_grading_blue.svg';
import eye from '../../../../../../assets/eye_blue.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import { addAnimation, showModal } from 'helpers/uiFunctions';
import { sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { addActivityData } from 'Redux/actions';
import { useRef } from 'react';
import SettingsModalsContainer from '../SettingsModalsContainer';

const GradingModal = ({ mobile, setModal, activity, activeSection, setActiveSection, modalOverlay }) => {

  const { grading, shareGrading, activityGradingType } = activity;
  const radiosRef = useRef()

  const [ localData, setLocalData ] = useState({ grading:grading, shareGrading, value:activityGradingType })
  const [ error, setError ] = useState(false)
  const gradeNumber = ( !activeSection?.entries?.length ? 2 : activeSection.entries[0].grade );

  useEffect(()=>{setError(false)},[localData])

  const handleSlider = (key, newValue) => {
    const newData = key == 'grading' ?
      {
        ...localData,
        grading: newValue,
        value: newValue==1?'Basic':null

      }
    :
      {
        ...localData, 
        [key]:newValue 
      }

     setLocalData(newData) 

     handleSubmit(newData)
  }

  const handleSubmit = (newData) => {

    if(grading && !newData.grading ){ //<------deactivating grading

      return showModal(modalOverlay, { 
        type: 'remove', 
        variant: 'disableGrading',
        activity:activity,
        setActiveSection:setActiveSection,
        index:activeSection.sectionIndex
      })

    } else if(grading && activityGradingType !== newData.value) { //<-----changing rubric
            
      return showModal(modalOverlay, { 
        type: 'remove', 
        variant: 'changeRubric', 
        activityId: activity.activityId, 
        activity:{...activity, shareGrading:newData.shareGrading},
        rubric: newData.value
      })

    } else {

      if(newData.grading && !newData.value){  
        setError(true)
        return addAnimation(radiosRef, 'shake', 500)
      }

      const payload = {
        ...activity,
        grading:newData.grading,
        shareGrading:
        newData.shareGrading,
        activityGradingType:
        newData.value
      }

      sendToApiReturn(`activity/${activity.activityId}/update`,  payload)
      store.dispatch(addActivityData(payload))

    }

    if(mobile)setModal("setup")
  }

  const showRubricModal = () => {
    showModal(modalOverlay, { type: 'viewRubric', rubricName: localData.value ? localData.value.toLowerCase() : 'basic', gradeNumber: gradeNumber }) ;
  }
  
  return (
    <div className={`${styles.container} ${mobile && styles.mobile}`}>

      <div className={styles.titleBar}>
        <img src={icon} alt="upload" />
        <h2>Grading</h2>
      </div>

      <h5>Enable grading for this activity</h5>

      <div onClick={()=>handleSlider('grading', !localData.grading ? 1 : 0)} className={styles.sliderDiv}>
        <p className={!localData.grading && styles.show}>not active</p>
        <div className={`${styles.slider} ${localData.grading && styles.active}`}>
          <div className={`${styles.sliderInner} ${localData.grading && styles.active}`} />
        </div>
        <p className={localData.grading && styles.show}>active</p>
      </div>

      <div className={`${styles.main} ${localData.grading && styles.show}`}>

        <div ref={radiosRef} className={`${styles.rubricRadios}`}>
          <label>
            <input checked={localData.value==='Basic'} type="radio" name="rubric"/>
            <div onClick={()=>{handleSlider("value", "Basic")}} className={styles.rubricDiv}>
              <p>Basic</p>
              <div className={styles.checkbox} />
            </div>
          </label>
          <label>
            <input checked={localData.value==='Argument/Opinion'} type="radio" name="rubric"/>
            <div onClick={()=>{handleSlider("value","Argument/Opinion")}} className={styles.rubricDiv}>
              <p>Argument/Opinion</p>
              <div className={styles.checkbox} />
            </div>
          </label>
          <label>
            <input checked={localData.value==='Narrative'} type="radio" name="rubric" />
            <div onClick={()=>{handleSlider("value", "Narrative")}} className={styles.rubricDiv}>
              <p>Narrative</p>
              <div className={styles.checkbox} />
            </div>
          </label>
          <label>
            <input checked={localData.value==='Information & Explanatory'} type="radio" name="rubric" />
            <div onClick={()=>{handleSlider("value", "Information & Explanatory")}} className={styles.rubricDiv}>
              <p>Information & Explanatory</p>
              <div className={styles.checkbox} />
            </div>
          </label>
        </div>

        <div className={styles.sharingDiv}>
          <h5>Share grading with students</h5>

          <div onClick={()=>handleSlider('shareGrading', !localData.shareGrading)} className={styles.sliderDiv}>
            <p className={!localData.shareGrading && styles.show}>not active</p>
            <div className={`${styles.slider} ${localData.shareGrading && styles.active}`}>
              <div className={`${styles.sliderInner} ${localData.shareGrading && styles.active}`} />
            </div>
            <p className={localData.shareGrading && styles.show}>active</p>
          </div>

          <div onClick={showRubricModal} className={styles.viewRubricBtn}>
            <img src={eye} alt="eye icon" />
            <p>View Rubric</p>
          </div>

        </div>

      </div>

      {/* <div 
        onClick={handleSubmit}
        className={`${styles.submitBtn} ${!isEqual(localData, {grading:grading, shareGrading, value:activityGradingType}) && styles.enabled}`}>
        <p> Save</p>
        {error && <span className={styles.error}>Please select a rubric</span>}
      </div> */}

    </div>
  )

}

export default GradingModal;