import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ link1, link2, current, borderBottom }) => {

  return (
    <div className={`${styles.container} ${borderBottom && styles.borderBottom}`}>
      <Link to={link1.link}>{link1.text}</Link>
      <h5 className={styles.arrow}>{'>'}</h5>

      {
        link2 &&
          <>
            <Link to={link2.link}>{link2.text}</Link>
            <h5 className={styles.arrow}>{'>'}</h5>
          </>
      }
      <h5 className={styles.current}>{current}</h5>

    </div>
  )

}

export default Breadcrumb;