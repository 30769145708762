import { sendToApiReturn } from 'API/teacherCalls';
import { arrayObjectHasValue } from 'helpers/arrayObjectHasValue';
import { instancesInArray } from 'helpers/uiFunctions';
import Tooltip from 'components/UtilityComps/Tooltip/Tooltip';
import React from 'react';
import { Link } from 'react-router-dom'
import { updateActivitySection } from 'Redux/actions';
import { store } from 'storeConfig';

import styles from './SectionStatusBtn.module.scss';

const SectionStatusBtn = ({
  showModal,
  modalOverlay,
  activeSection,
  activeSection: { sectionStatus, sectionIndex, sectionWritingType, sectionStage, voting, entries, sectionStart, sectionPhase, sectionDue },
  activity: { classHasStudents, activityClassId, activityId, activityType, sections },
  activity,
  isStoryStart,
  setActiveSection
}) => {
  // no more than 5 students on the free plan to share sections
  const isBlocked = (activity.onFreePlan && activity.allStudentsCount > 5)

  const returnUpdatedSection = (status, stage, date) => {
    return { ...activeSection, sectionStatus: status, sectionStage: stage, sectionStart: date }
  }

  console.log("Activity: ", activity);

  const anyEntryApproved = arrayObjectHasValue(entries, 'approved', true)

  const setLive = async () => {

    if (!classHasStudents) {
      showModal(modalOverlay, { type: 'addStudentsToClass', classId: activityClassId })

    } else {
      const res = await sendToApiReturn(`activity/${activityId}/update_unit/${sectionIndex}`, returnUpdatedSection('live', 'writing', new Date(sectionStart)))
      store.dispatch(updateActivitySection(sectionIndex, res))
      //activeSection updated in ActivitiyDetails useEffect [activity.sections] in response to activity object update
    }

  }

  const unshare = async () => {
    const res = await sendToApiReturn(`activity/${activityId}/unshare_unit/${sectionIndex}`)
    store.dispatch(updateActivitySection(sectionIndex, res))
    //activeSection updated in ActivitiyDetails useEffect [activity.sections] in response to activity object update
  }

  const schedule = async () => {
    const payload = { ...activeSection, sectionStage: "", sectionStatus: "scheduled", sectionPhase: 21 }
    store.dispatch(updateActivitySection(sectionIndex, payload))
    sendToApiReturn(`activity/${activityId}/schedule_unit/${sectionIndex}`)
    //activeSection updated in ActivitiyDetails useEffect [activity.sections] in response to activity object update

  }

  const reopen = async () => {
    const res = await sendToApiReturn(`activity/${activityId}/update_unit/${sectionIndex}`, returnUpdatedSection('live', 'writing', new Date(new Date().setDate(new Date().getDate() + 7))))
    store.dispatch(updateActivitySection(sectionIndex, res))
    //activeSection updated in ActivitiyDetails useEffect [activity.sections] in response to activity object update

  }

  const handleStatusChange = async () => {

    const commonProps = { activeSection: activeSection, index: sectionIndex, activityId: activityId, students: entries, setActiveSection: setActiveSection, activity: activity }

    if (sectionStatus === 'live') {

      const res = await sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/end_writing`)
      store.dispatch(updateActivitySection(sectionIndex, res))
      setActiveSection(res)

      if (voting) {
        
        if(instancesInArray(entries, 'approved', true)>2)
          showModal(modalOverlay, { type: 'endWriting', stage: 'writing', ...commonProps })
        

      } else {

        if (sections.length - 1 === sectionIndex && activityType !== 'assignment') {
          ///show final section modal if ending final section of story or journal
          showModal(modalOverlay, { type: 'finalSection', activity: activity, activityId: activityId, initClass: 'main', variant: activityType })
          return
        }
      }
    }

    else if (sectionStatus === 'voting') {


      if (entries.length !== instancesInArray(entries, 'voted', true)) {
        showModal(modalOverlay, { type: 'notAllVoted', voted: instancesInArray(entries, 'voted', true), stage: 'voting', func: setActiveSection, ...commonProps })

      } else {
        const res = await sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/end_voting`)
        setActiveSection(res)
        console.log(res)

      }
    }

    else if (sectionStatus === 'in approval') {

      if (sectionWritingType === 'group' && sectionStage !== 'post vote') {

        if(instancesInArray(entries, 'approved', true)<3) return showModal(modalOverlay, { type: 'endWriting', stage: 'cantVote', ...commonProps })

        const res = await sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/start_voting`)
        store.dispatch(updateActivitySection(sectionIndex, res))
        setActiveSection(res)

      } else {

        showModal(modalOverlay, { type: 'markAsComplete', stage: sectionWritingType === 'group' ? 'in approval-group' : 'in approval', ...commonProps })

      }
    }
  }


  return (
    
      isBlocked ?

        <div className={`${styles.container} ${styles.noFlex}`}>
          <div className={`${styles.statusBtn} ${styles.setLive} ${styles.blocked}`}>
            <h5>Set live</h5>
          </div>
          <p className={styles.blockedText}>To share activities <Link to={"/teacher/account/manage"}>upgrade to a paid account</Link></p>
        </div>
        

      : isStoryStart ? null

      :

      <div className={styles.container}>
        {(sectionStatus === 'draft' && sectionPhase === 1) &&
          <div onClick={schedule} className={`${styles.statusBtn} ${(!sectionDue || sectionDue === 'open') && styles.shownAndDisabled}`}>
            <h5>"Schedule</h5>
          </div>
        }
        {
          (sectionStatus === 'draft' || sectionStatus === 'scheduled') &&
          <div onClick={setLive} className={`${styles.statusBtn} ${styles.setLive}`}>
            <h5>Set live</h5>
          </div>
        }

        {
          (sectionStatus === 'live') &&
          <div onClick={unshare} className={`${styles.statusBtn} ${styles.unshare} ${anyEntryApproved && styles.disabled}`}>
            <h5>Unshare</h5>
          </div>
        }

        {
          (sectionStatus === 'completed' && sectionWritingType === 'individual') &&
          <div onClick={reopen} className={`${styles.statusBtn} ${styles.reopen}`}>
            <h5>Reopen</h5>
          </div>
        }
        {
          ['scheduled', 'completed', 'draft'].indexOf(sectionStatus) === -1 &&
          <div onClick={handleStatusChange} className={`${styles.statusBtn}`}>
            {
              sectionStatus === 'live' ?
                  <h5>
                    End Writing
                  </h5>

              : sectionStatus === 'voting' ?
                  <h5>
                    End voting
                  </h5>

              : sectionStatus === 'in approval' && sectionWritingType === 'individual' ?
                  <h5>
                    Close Section
                  </h5>

              : sectionStatus === 'in approval' && sectionStage !== 'post vote' ?
                  <h5>
                    Start voting
                  </h5>

              : (sectionStage === 'post vote' && sectionWritingType === 'group') &&
                  <h5>
                    Close section
                  </h5>
            }
          </div>
        }
      </div>
      
  )

}

export default SectionStatusBtn;
