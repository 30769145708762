import React from 'react'

import styles from './SubscriptionModal.module.scss'
import { useUserType } from 'context/AltPlatformProvider'

const PaymentHistory = () => {
    const {fjUser} = useUserType()
  return (
    <div className={`${styles.tablecontainer}`}>
    <h4>Payment History</h4>
    <div className={`${styles.table} ${styles.history}`}>
      <div className={styles.tableHeader}>
        <div className={`${styles.column} ${styles.leftPadding}`}><h5>Date</h5></div>
        {
          fjUser && <div className={`${styles.column} ${styles.hideRes}`}><h5>Workbooks</h5></div>

        }
        <div className={`${styles.column} ${styles.hideRes}`}><h5>Students</h5></div>
        <div className={styles.column}><h5>Amount</h5></div>
        <div className={styles.column}><h5>Status</h5></div>
      </div>
      { 
        // !apiRes.paymentHistory.length ? 
        //   <h5 className={styles.placeholder}>No payment history to display</h5>
        // :


        // apiRes.paymentHistory.map((item, idx) =>
        //   <div key={idx} className={styles.tableRow}>
        //   {/* <div className={styles.column}><p>{format(new Date(item.date), 'MMMM  d, yyyy')}</p></div> */}
        //   <div className={styles.column}><p>{format(new Date(item.paidAt), 'MMMM do, yyyy')}</p></div>
        //   {
        //       fjUser && (
        //         <div className={`${styles.column} ${styles.hideRes}`}><p>{item.purchasedSophiaCredits}</p></div>
        //     )
        //   }
        //   <div className={`${styles.column} ${styles.hideRes}`}><p>{item.activeStudents}</p></div>
        //   <div className={styles.column}><p>{`$${item.amount}`}</p></div>
        //   <div className={styles.column}>
        //     <p className={`${styles.statusPill} ${styles[item.status]}`}>{item.status}</p>
        //   </div>
        // </div>
        // )
      }
    </div>

  </div>
  )
}

export default PaymentHistory