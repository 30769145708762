import React, { useState } from 'react';
import {AiFillCheckSquare} from "react-icons/ai"
import styles from './Institution.module.scss'
import InstituteTable from './InstituteTable';


function Institution() {
  const [selectedOption, setSelectedOption] = useState('');
  const [checkbox1Checked, setCheckbox1Checked] = useState(true);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckbox1Change = () => {
    setCheckbox1Checked(!checkbox1Checked);
    setCheckbox2Checked(false);
  };
  
  const handleCheckbox2Change = () => {
    setCheckbox2Checked(true);
    setCheckbox1Checked(false);
  };
  
  
  return (
    <div className={styles.container}>
      <div>
        <label  className={styles.check}>
          <input
            type="checkbox"
            checked={checkbox1Checked}
            onChange={handleCheckbox1Change}
            className={styles.custom_checkbox}
          />
          Teacher Account (default)
        </label>
        <div>
          <label className={styles.check}>
            <input
              type="checkbox"
              checked={checkbox2Checked}
              onChange={handleCheckbox2Change}
              className={styles.custom_checkbox}
            />
          Institution account 
          </label>

          <div className={styles.right}>
              <label className={styles.check}>
                <input
                  type="checkbox"
                  checked={checkbox2Checked}
                  onChange={handleCheckbox2Change}
                  className={styles.custom_checkbox}
                />
            Move all students to the institution account (default option)
            </label>
          </div>
        </div>
      </div>

      {
        checkbox2Checked && (
          <div style={{marginTop: "2rem"}}>
            <h4>
              Institution Name
            </h4>
            <div className={styles.instruction_name}>
              Christ the king
            </div>
    
            <InstituteTable />
  
        </div>
        )
      }


    </div>
  );
}

export default Institution;
