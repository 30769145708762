
export const allowTabIndentation = () => {

  var textareas = document.getElementsByTagName('textarea');
  var count = textareas.length;
  for(var i=0;i<count;i++){
    textareas[i].onkeydown = function(e){
      if(e.keyCode===9 || e.which===9){
        e.preventDefault();
        var s = this.selectionStart;
        this.value = this.value.substring(0,this.selectionStart) + "\t" + this.value.substring(this.selectionEnd);
        this.selectionEnd = s+1; 
      }
    }
  }
  
}


export const radValNumber = (input) => {
  let output;
  switch(input){
    case 'one':
      output= 0
      break;
    case 'two':
      output= 1
      break;
    case 'three':
      output = 2
      break;
    default:
      output=0;
  }
  return output
}

export const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const randomWithExclude = (min, max, excludeArray) => {
  const randomNumber = Math.floor(Math.random() * (max - min + 1 - excludeArray.length)) + min;
  return randomNumber + excludeArray.sort((a, b) => a - b).reduce((acc, element) => { return randomNumber >= element - acc ? acc + 1 : acc}, 0);
}

export const checkParams = (obj) => {
  console.log(obj)
  for (var key in obj) {
    if (obj[key] === "")
      return false;
  }
  return true;
}


export const countWords = (entryText) => {
  let r = 0;
  entryText = entryText.replace(/\s/g, ' ');
  entryText = entryText.replace(/&nbsp;/g, ' ');
  entryText = entryText.replaceAll('<br>', ' ');
  let a = entryText.split(' ');
  for (let z = 0; z < a.length; z++) {
      if (a[z].length > 0) r++;
  }
  return r;
}