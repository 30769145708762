import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { RenderDownloadContent } from "./renderDownloadContent";
import { RenderPrintContent } from "./renderPrintContent";
import { RenderCoverContent } from "./renderCoverContent";

import styles from './PDFRenderModal.module.scss';

const DownloadPDFBtn = ({printContent, printCover, pdfData, pageCount, isFaithJournal, coverChoice}) => {

  const [ show, setShow ] = useState(false)

  useEffect(()=>{
    if(pdfData?.projectTitle){
      setTimeout(()=>{setShow(true)},1500)
    }
    console.log(pdfData)
  },[pdfData])

  return !show ? <div className={styles.downloadBtn}>Loading Document...</div> : (

    <PDFDownloadLink

    document={

      printContent ? 
        <RenderPrintContent
          pdfData={pdfData}/>

    :

      printCover  ?
        <RenderCoverContent 
          pdfData={pdfData}
          coverChoice={coverChoice}
          pageCount={pageCount} />

    :

      <RenderDownloadContent
          isFaithJournal={isFaithJournal} 
          pdfData={pdfData}/>
      
    }

    fileName={printCover ? `${pdfData?.projectTitle}_cover.pdf` : `${pdfData?.projectTitle}.pdf`}
    className={styles.downloadBtn}>
    {({ blob, url, loading, error }) =>
      loading ? "Loading Document..." : "Download File" }
  </PDFDownloadLink>
  )

}

export default DownloadPDFBtn;