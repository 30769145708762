import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';

import styles from './Manage.module.scss';
import { showModal } from 'helpers/uiFunctions';

const TableRow = ({ user, mapItems, orderBy, activeTab, modalOverlay }) => {

  const contRef = useRef()

  const offset = (Math.floor(Math.random() * mapItems.length+1)+1) * 20
  useEffect(()=>{
    contRef.current.classList.remove(styles.show)
    setTimeout(()=>{ contRef.current.classList.add(styles.show) },offset % 140)
  },[orderBy, mapItems])

  const loginAsUrlForUser = (user) => {
    let impersonateUrl = user.loginAsUrl
    if(window.location.host === "localhost:3000") {
      impersonateUrl = impersonateUrl.replace("https://sand.faith-journals.com", "http://localhost:3011")
    }
    return impersonateUrl
  }

  return !user ? null : (
    <div ref={contRef} className={`${styles.row} ${styles.show}`} key={user.userName}>
      {
        activeTab !== 'teachers' ?

        <>
        <div className={`${styles.column}  ${styles[activeTab]} ${styles.wide}`}>
          <div>
            <p>{user.userName}</p> 
            <span style={{fontSize: "10px"}}>{user.email}</span>
          </div>
        </div>

        <div className={`${styles.column} ${styles[activeTab]}  ${styles.wide}` } >
          <p>
            {user.teacherEmail}
          </p>
        </div>
        <div className={`${styles.column} ${styles[activeTab]}`}>
          <p>
          {format(new Date(user.lastLogin), 'MMM d, yyy') || '-'}
          </p>

        </div>
        <div className={`${styles.column} ${styles[activeTab]}`}>
          <p>
            {format(new Date(user.signUp), 'MMM d, yyy') || '-'}
          </p>

        </div>
        <div className={`${styles.column} ${styles[activeTab]}`}>
          <p>{Number(user.entriesCount).toLocaleString()}</p>
        </div>
        <div className={`${styles.column} ${styles[activeTab]}`}>
          <p>{Number(user.wordsCount).toLocaleString()}</p>
        </div>
        <div className={`${styles.column} ${styles[activeTab]}`}>
          <p>{user.country || '-'}</p>

        </div>
        <div className={`${styles.column} ${styles[activeTab]} `}>
          <a href={loginAsUrlForUser(user)} className={`${styles.impersonateBtn}`}>Impersonate</a>
        
        </div>
      </>

      :
        <>
          <div className={`${styles.column} ${styles.wide}`}>
            <div>
              <p>{user.userName}</p> 
              <span style={{fontSize: "10px"}}>{user.email}</span>
            </div>
            <a href={loginAsUrlForUser(user)} className={`${styles.impersonateBtn}`}>Impersonate</a>
          </div>

          <div className={`${styles.column} ${styles.wide}` } >
            <p>
              {user.institution}
            </p>
          </div>
          <div className={`${styles.column}`}>
            <p>
            {format(new Date(user.lastLogin), 'MMM d, yyy') || '-'}
            </p>

          </div>
          <div className={`${styles.column}`}>
            <p>
              {format(new Date(user.signUp), 'MMM d, yyy') || '-'}
            </p>

          </div>
          <div className={`${styles.column}`}>
            <p>{Number(user.projects).toLocaleString()}</p>

          </div>
          <div className={`${styles.column}`}>
            <p>{Number(user.students).toLocaleString()}</p>
          </div>
          <div className={`${styles.column}`}>
            <p>{Number(user.workbooks).toLocaleString()}</p>

          </div>
          <div className={`${styles.column}`}>
            <p>{Number(user.words).toLocaleString()}</p>
          </div>
          <div className={`${styles.column}`}>
            <p>{user.city || '-'}</p>

          </div>
          <div className={`${styles.column} ${styles.subscription}`}>
          <p>{user.subscription}</p>
            <div className={styles.subscription_btn} onClick={()=>showModal(modalOverlay, {type:'adminSubscription'})}>
              See subscription
            </div>
          </div>
        </>
}


      
      {/* <div className={`${styles.column} ${styles[activeTab]}`}>
        
      </div> */}
    </div>  
  )

}

export default TableRow;