import React, { useState } from 'react';
import { setQueryItem } from 'Redux/actions';
import { store } from 'storeConfig';

import styles from './pageCountModal.module.scss';

const PageCountModal = ({ item: {payload, activityId, printCover, isFaithJournal, isBee }}) => {

  const [ pageCount, setPageCount ] = useState(35)
  const [ coverChoice, setCoverChoice ] = useState(1)

  const handleSubmit = () => {
    store.dispatch(setQueryItem({
      type:'pdfRender', 
      pageCount:pageCount, 
      coverChoice:coverChoice,
      payload:payload, 
      activityId: activityId, 
      printCover: printCover}))
  }

  return (
    <form className={styles.pageCount}>
      <h4>Please enter number of pages</h4>
      <input min={0} autoFocus value={pageCount} onChange={(e)=>setPageCount(e.target.value)} type="number"/>

      {
        isFaithJournal || isBee &&
          <div className={styles.coverSelect} onChange={e=>setCoverChoice(parseInt(e.target.value))}>
            <label className={styles.row}>
              Cover 1
              <input type="radio" name="coverSelect" value={1}/>
              <div className={`${styles.checkbox} `}>
                <div className={`${parseInt(coverChoice) === 1 ? 'radioChecked':'radioUnchecked'}`} />
              </div>
            </label>
            <label className={styles.row}>
              Cover 2
              <input type="radio" name="coverSelect" value={2}/>
              <div className={`${styles.checkbox} `}>
                <div className={`${parseInt(coverChoice) === 2 ? 'radioChecked':'radioUnchecked'}`} />
              </div>
            </label>

          </div>
      }
      <button type={"submit"} onClick={handleSubmit}className={`${styles.button} ${pageCount<1&&styles.disable}`}>Submit</button>
    </form>
  )

}

export default PageCountModal;