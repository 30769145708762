import { StyleSheet, Font } from "@react-pdf/renderer";
import kurphSrc from "./fonts/kurph-webfont.ttf"
import openSansSrc from "./fonts/OpenSans-Regular.ttf"

Font.register({
  family: 'Kurph',
  src: kurphSrc
})

Font.register({
  family: 'Open',
  src: openSansSrc
})

export const contentStyles = () => {

  return StyleSheet.create({
    page: {
      paddingTop: 60,
      paddingBottom: 60,
      paddingHorizontal: 65,
      position:"relative",
      height:"100%"
    },
    title_page: {
      display:"flex",
      justifyContent:"center",
      flexDirection:"column",
      paddingTop: 60,
      paddingBottom: 50,
      paddingHorizontal: 65,
    },
    title_page_top: {
      display:"flex",
      justifyContent:"flex-start",
      flexDirection:"column",
      paddingTop: 60,
      paddingBottom: 50,
      paddingHorizontal: 65,
    },
    title_page_text: {
      whiteSpace:"nowrap",
      position:"relative",
      bottom:"100px",
      width:"90%",
      marginHorizontal:"auto",
      fontFamily:"Open",
    },
    title_text_wide: {
      fontFamily:"Kurph",
      fontSize: 30,
      textAlign: "center",
      marginBottom:15,
    },
    title_text: {
      fontFamily:"Kurph",
      fontSize: 30,
      textAlign: "center",
      marginBottom:15,
      width:"320",
      marginHorizontal:"auto",
      textAlign:"center"
    },
    secondary_title_text: {
      fontFamily:"Kurph",
      fontSize: 30,
      marginBottom:20,
      padding:0,
      textAlign: "center",
      width:"auto"
    },
    secondary_title_text_sml: {
      fontFamily:"Kurph",
      fontSize: 18,
      marginBottom:10,
      padding:0,
      textAlign: "center",
      width:"auto"
    },
    secondary_title_text_sml_left: {
      fontSize: 15,
      marginBottom:5,
      padding:0,
      textAlign: "left",
      width:"auto",
      fontFamily:"Open",
    },
    subtitle_text: {
      fontSize: 12,
      lineHeight:1.5,
      marginBottom:15,
      marginLeft: 0,
      paddingLeft:0,
      marginTop:15,
      textAlign: "center",
      fontFamily:"Open",
    },
    text_large: {
      fontSize: 14,
      fontWeight:"300",
      marginBottom:0,
      textAlign: "center",
      fontFamily:"Open",
    },
    text_med_margin: {
      fontSize: 12,
      fontWeight:"300",
      marginBottom:0,
      textAlign: "center",
      marginTop:5,
      fontFamily:"Open",
    },
    text_subhead: {
      textAlign: "center",
      fontSize: 12,
      marginBottom:"3px",
      marginTop:15,
      fontFamily:"Open",
    },
    text_parenthesis: {
      textAlign: "center",
      fontSize: 12,
      marginBottom:"3px",
      marginTop:0,
      fontFamily:"Open",
    },
    text_medium: {
      textAlign: "center",
      fontSize: 10,
      marginBottom:"3px",
      fontFamily:"Open",
    },
    text_small: {
      textAlign: "center",
      fontSize: 8,
      fontFamily:"Open",
    },
    dedictaion_text: {
      textAlign: "center",
      fontFamily:"Kurph",
      fontSize: 25,
      marginBottom:150,
      padding:0
    },
    text_body: {
      fontFamily:"Open",
      marginTop:"20px",
      textAlign:"justify",
      fontSize: 11,
      lineHeight:2,
    },
      footer_text: {
      fontSize: 12,
      marginTop:2,
      textAlign:"center",
      fontFamily:"Open",
    },
    chapter_title_text:{
      fontWeight:700,
      fontSize:15,
      marginBottom:"5px",
      fontFamily:"Kurph"
    },
    footer: {
      position:"absolute",
      height:"50px",
      width:450,
      bottom:"20px"
    },
    footer_number: {
      left:223,
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      height:"10px",
      position:"absolute",
      bottom:30,
      fontFamily:"Open",
    },
    pageNumber: {
      position: "absolute",
      width:"100%",
      bottom:0,
      left:0,
      textAlign:"center",
      fontSize: 10,
      fontFamily:"Open",
    },
    blurb_title_text: {
      fontFamily:"Kurph",
      fontSize: 18,
      marginBottom:20,
      padding:0,
      textAlign: "center",
      width:"auto"
    },
    blurb_title_text_bw: {
      fontFamily:"Kurph",
      fontSize: 18,
      marginBottom:2,
      padding:0,
      textAlign: "center",
      width:"auto"
    },
    blurb_subhead: {
      fontSize: 13,
      fontWeight:"300",
      marginBottom:0,
      textAlign: "center",
      marginBottom:10,
      fontFamily:"Open",
    },
    wimpy_kid_blurb: {
      marginVertical: 10,
      fontFamily:"Open",
    },
    blurb_text_bold: {
      fontWeight: 700,
      textAlign:"center",
      fontSize: 14,
      fontFamily:"Open",
    },
    blurb_text: {
      fontSize: 10,
      textAlign:"center",
      fontWeight: "regular",
      fontFamily:"Open",
    },
    blurb_text_margin: {
      fontSize: 10,
      textAlign:"center",
      fontWeight: "regular",
      marginVertical:5,
      fontFamily:"Open",
    },
    address: {
      marginVertical:5
    },
    authors: {
      width:"85%",
      display:"flex",
      flexWrap:"wrap",
      justifyContent:"space-evenly",
      flexDirection:"row",
      marginHorizontal: "auto",
      marginVertical:15
    },
    author: {
      fontSize: 14,
      fontWeight:"300",
      marginBottom:0,
      textAlign: "center",
      width:"48%",
      marginVertical:6,
      fontFamily:"Open",
    }
  
  });
} 