import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom'
import EditProfile from './EditProfile';
import ProfilePasswordSettings from './ProfilePasswordSettings';
import ProfileEmailSettings from './ProfileEmailSettings';
import FamilyPlusSettings from './FamilyPlusSettings';
import styles from './ProfileSettings.module.scss';
import { useSelector } from 'react-redux';
import loader from 'assets/spinner-sml--fast.svg';
import { defaultUserImg } from 'helpers/uiFunctions';
import { sendToApi } from 'API/generalCalls';
import { store } from 'storeConfig';
import { updateDashData } from 'Redux/actions';
import ProfileSettingsResponsive from './ProfileSettingsResponsive';
 

const ProfileSettings = ({ isTeacher }) => {

  const { tab } = useParams()
  const userName = useSelector(state=>state.dashData.name)
  const familyPlus = useSelector(state=>state.dashData.familyPlus)
  const permissions = useSelector(state=>state.dashData.permissions)
  const userProfilePic = useSelector(state=>state.dashData.imageUrl)
  const userObj = useSelector(state=>state.dashData)
  const history = useHistory();
  const [ loading, setLoading ] = useState({profilePic:false})
  const imgInput = useRef();
  const [ profilePic, setProfilePic ] = useState(userProfilePic ? userProfilePic : defaultUserImg({imageUrl: userObj.imageUrl}))

  const handleUploadImg = (e) => {
    if(e.target.files){

      const newPic = e.target.files[0]
      setLoading({...loading, profilePic:true})

      const formData = new FormData();
      formData.append('file', e.target.files[0], e.target.files[0].name);
      formData.append('name', 'image from React app'); 
      sendToApi('account/profile_image', formData, 'uploadProfilePic', {setProfilePic:setProfilePic, setLoading:setLoading, loading:loading})
    }
  }
  
  const handleDeleteProfilePic = () => {
    
    store.dispatch( updateDashData('imageUrl', ''))
    sendToApi('account/profile_image', '')
    
  }

  useEffect(()=>{setProfilePic(userObj.imageUrl)},[userObj])

  return (
    <>
    <div className={styles.container}>

    <div className={styles.top}>

      {
        permissions&&permissions[0] === 'teacher' ?
        <Link to='/teacher/classes'> <div className={styles.back}> {'< Go to dashboard'}</div> </Link> :
        permissions&&permissions[0] === 'admin' ?
        <Link to='/admin'> <div className={styles.back}> {'< Go to dashboard'}</div> </Link> :
        permissions&&permissions[0] === 'parent' &&
        <Link to='/parent'> <div className={styles.back}> {'< Go to dashboard'}</div> </Link>
      }

      {/* //breadcrumb */}
      <h4>{userName !== '-' &&  `${userName} /` }{tab==='profile'?'Edit Profile':tab==='password'?'Password':tab==='email_settings'?'Email Settings':'Profile Settings'} </h4> 
      
    </div>

    <div className={styles.main}>

      <div className={styles.photoBlock}>
        <div className={styles.photo}>
          {!loading.profilePic ? <img src={defaultUserImg({imageUrl: userObj.imageUrl})} alt="teacher pic"/> :  <img src={loader} className={styles.loader} alt="loader" /> }
          {/* {!loading.profilePic ? <img src={profilePic} alt="teacher pic"/> :  <img src={loader} className={styles.loader} alt="loader" /> } */}
        </div>
        <div className={styles.btns}>
          <label>
            <input type="file" name="imageUpload" id="imgUl" ref={imgInput} onChange={handleUploadImg} accept=".png, .jpg, .jpeg"/>
            <div className={styles.btn}> Upload new photo </div>
          </label>
          <div onClick={handleDeleteProfilePic} className={styles.btn}> Delete </div>
        </div>
      </div>


      <ProfileSettingsResponsive
        isTeacher={isTeacher}
        permissions={permissions}
        />

      <div className={styles.settingsBlock}>

      <div className={styles.tabs}>
        <label>
          <input type="radio" name="tab" id="edit" value = 'Edit Profile' defaultChecked={tab==='profile'?true:false}/>
          <div onClick={()=>{ history.push(!isTeacher ? '/account/profile' : '/teacher/account/profile') }}  className={styles.tabRadio}>Edit Profile</div>
        </label>
        <label>
          <input type="radio" name="tab" id="password" value = 'Password' defaultChecked={tab==='password'?true:false}/>
          <div onClick={()=>{ history.push(!isTeacher ? '/account/password' : '/teacher/account/password') }} className={styles.tabRadio}>Password</div>
        </label>
        <label>
          <input type="radio" name="tab" id="email" value = 'Email Settings' defaultChecked={tab==='email_settings'?true:false}/>
          <div  onClick={()=>{ history.push(!isTeacher ? '/account/email_settings' : '/teacher/account/email_settings') }} className={styles.tabRadio}>Email Settings</div>
        </label>

        {familyPlus && 
          <label>
            <input type="radio" name="tab" id="family+" value = 'Family+' defaultChecked={tab==='family+'?true:false}/>
            <div  onClick={()=>{ history.push('/account/family+') }} className={styles.tabRadio}>Family +</div>
          </label>
        }

      </div>


      <div className={styles.settingsInner}>

        {
          tab === 'profile' ?
            <EditProfile />
            
            :
            
            tab === 'password' ?
            <ProfilePasswordSettings />
            
            :
            
            tab === 'email_settings' ?
            <ProfileEmailSettings permissions={permissions} />
            
            :

            tab === 'family+' && familyPlus ?
            <FamilyPlusSettings />

            : null

        }

      </div>
      </div>

      </div>


    </div>

    </>
)

}

export default ProfileSettings;