import { simpleTimeFormat } from 'helpers/dateFunctions';
import React from 'react';
import arrow from 'assets/arrow_teal.svg'
import { useSwipeable } from 'react-swipeable'


import styles from './ChatModal.module.scss';

const ResponsiveConversationModal = ({ showConversation, toggleConversation, handleSubmit, chatData, activeIdx = 0, isNewDay, inputRef, rendernewDayPill, sendMsg }) => {


  const handlers = useSwipeable({
    onSwipedRight: () => toggleConversation(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return  (
    <div {...handlers} className={`${styles.responsiveConversation} ${showConversation && styles.open}`}>

      <div onClick={() => { toggleConversation(false) }} className={styles.topBar}>
        <img className={styles.arrow} src={arrow} alt="" />
        <img src={chatData && chatData[activeIdx].boomerUrl} alt="" />
        <h5>{chatData && chatData[activeIdx].studentName}</h5>

      </div>


      <div className={styles.main}>
        <div className={styles.mainInner}>

          {chatData?.length && chatData[activeIdx].messages?.length ?
            chatData[activeIdx].messages.map((message, index) => {

              return (
                <>
                  <div key={index} className={(message.sentBy === 1 ? `${styles.message} ${styles.self}` : `${styles.message} ${styles.external}`)}>
                    {message.sentBy !== 1 ? <h6 className={styles.from}>{message.studentName.split(' ')[0]}</h6> : null}
                    {message.sentBy !== 1 ? <img src={message.boomerUrl} alt="boomer" /> : null}
                    <h4>{message.content}</h4>
                    <h6>{
                      message.timeStamp?.length === 5 ?
                        message.timeStamp
                        :
                        simpleTimeFormat(new Date(message.timeStamp))
                    }</h6>
                  </div>
                  {index === chatData[activeIdx]?.messages.length - 1 && rendernewDayPill(message)}
                  {isNewDay(chatData[activeIdx]?.messages[index + 1]?.timeStamp, message.timeStamp) && rendernewDayPill(message)}
                </>
              )
            })

            : null}

        </div>

        <div className={styles.inputDiv}>
          <input
            onKeyPress={(e) => { if (e.key === 'Enter' && e.target.value !== '') { handleSubmit() } }}
            ref={inputRef}
            type="text"
            name="msgInput"
            placeholder="Type your message here"
          />
          <img onClick={handleSubmit} src={sendMsg} alt="Send Message" />
        </div>

      </div>

    </div>
  )

}

export default ResponsiveConversationModal;