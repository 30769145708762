import React, { useContext} from 'react'
import { useSelector } from 'react-redux'

const AltPlatformContext = React.createContext()

export function useUserType(){
  return useContext(AltPlatformContext)
}

export default function AltPlatformProvider({children}) {
  const simplifiedActivity = useSelector(state => state.simplifiedActivity)
  const fjUser = (simplifiedActivity === true || window.location.href.includes("faith-journals.com"));
  const synodUser = simplifiedActivity === "Synod";

  return (
    <AltPlatformContext.Provider value={{fjUser, synodUser}}>
      {children}
    </AltPlatformContext.Provider>
  )
}
