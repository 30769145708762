import React,{useEffect} from 'react';
import { store } from 'storeConfig'
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode'

import {initiateUser, loadDashData, updateDashData, updateEnv, updateSimplified } from 'Redux/actions';
import { redirectStatic } from 'helpers/redirectFunctions';

const Auth = () => {
  
  const history = useHistory();
  const JWT = window.location.href.split('--')[1]

  useEffect(()=>{ 
    // localStorage.clear();
    //in case we somehow have ls or store entries left from previous login
    localStorage.removeItem('t')
    localStorage.removeItem('backUrl')
    localStorage.removeItem('frontUrl')
    localStorage.removeItem('env')
    store.dispatch(initiateUser({}))
    store.dispatch(loadDashData({}))
    
    if(JWT){ // JWT received
      
      console.log("JWT: ", jwt_decode(JWT))

      let { 
        t, 
        permissions=["teacher"], 
        env="prod", 
        backUrl="https://schools.escribli.com", 
        frontUrl = "https://schools.escribli.com",
        stripeKey="", 
        imp=false,  
        userType,
        studentsFrontUrl,
        isAdmin,
        simplifiedActivity
      } = jwt_decode(JWT)

      // update the front apps URLs for dev env
      if(window.location.host === "localhost:3000"){
        env = "dev"
        frontUrl = "http://localhost:3000/"
        studentsFrontUrl = "http://localhost:3010/"
      }

      localStorage.setItem('t', t)
      localStorage.setItem('a', isAdmin)
      localStorage.setItem('backUrl', backUrl)
      localStorage.setItem('frontUrl', frontUrl)
      localStorage.setItem('studentsFrontUrl', studentsFrontUrl)
      localStorage.setItem('env', env)
      localStorage.setItem('permissions', permissions)
      if(imp){ localStorage.setItem('impersonate', true) }

      store.dispatch(updateEnv(env))
      store.dispatch(updateSimplified(simplifiedActivity === true))
      store.dispatch(updateDashData('simplifiedActivity', simplifiedActivity === true))
      
      const attempted = localStorage.getItem('attempted_url')

      if(attempted){
        localStorage.removeItem('attempted_url')
        window.location = attempted
      } else {
        history.push( userType != "Synod" ?  '/teacher/classes' : '/dashboard')
      }

    } else { // no JWT received

      // user accessing some nested URL without being logged in?
      // Save the attempted URL and redirect to login, URL then used on the next auth attempt (in authRedirect function)
      localStorage.setItem('attempted_url', window.location)

      setTimeout(()=>{ redirectStatic('auth/teacher') },600)

    }
  
  },[])
  
  return (
    <div className="container" style={{position:'relative', left:'50px'}}>

      <div> {  <h6>Redirecting...</h6> } </div>

    </div>
  )

}

export default Auth;