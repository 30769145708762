import React from 'react';
import styles from './BreakoutRoomBlock.module.scss';
import roomIcon from 'assets/room_white.svg';

const BreakoutRoomBlock = ({groupLetter, studentOne, studentTwo, studentThree, removeIndex, handleRemove, confirmed}) => {


    return studentOne && (
      <div className={(confirmed ? `${styles.container} ${styles.hoverable}` : styles.container)}>
        <div className={(confirmed ? styles.inner : `${styles.inner} ${styles.unconfirmed}`)}>

          <div className={`${styles.groupDiv} ${`group_${groupLetter} noHover`}`}>
            <img src={roomIcon} alt="room" />
            <h1>Room {groupLetter}</h1>
          </div>
          <h3>{studentOne.studentName}</h3>
          {studentTwo &&  <h3>{studentTwo.studentName}</h3>}
          {studentThree && <h3>{studentThree.studentName}</h3>}
    
          <div className={styles.boomers}>
            <div className={`${styles.boomer}`}>
              <img alt="boomer" src={studentOne.studentBoomerUrl}/>
            </div>
            {
              studentTwo &&
                <div className={`${styles.boomer}`}>
                  <img alt="boomer" src={studentTwo.studentBoomerUrl}/>
                </div>
            }
            {
              studentThree &&
                <div className={`${styles.boomer}`}>
                  <img alt="boomer" src={studentThree.studentBoomerUrl}/>
                </div>
            }
          </div>
        </div>
        <div onClick={()=>{handleRemove(studentOne.studentId, confirmed)}} 
        className={(!confirmed ? `${styles.delete} deleteDraft` : styles.delete)} >          
        </div>
        {!confirmed ? <div className={styles.unsaved}><p> unsaved </p></div> : null}
      </div>
    )

}

export default BreakoutRoomBlock;