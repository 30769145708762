import React from 'react';

import styles from './SidebarGrading.module.scss';
import Gradepicker from './GradePicker';

const SidebarGrading = ({ gradingData, sectionIndex, activityId, studentId, updateEntryKey, updateLocalGrade }) => {

  return (
    <div className={styles.container}>
      {
        !gradingData?.length ?

          <div className={styles.placeholderDiv}>
            <h2>Grading is not enabled for this activity</h2>
            <p>You can activate grading on the features toolbar, which is located on the right side of the Instructions window.</p>
          </div>
        :
          gradingData.map((criteria, idx) => 
            <Gradepicker
              updateEntryKey={updateEntryKey}
              sectionIndex={sectionIndex}
              updateLocalGrade={updateLocalGrade}
              studentId={studentId}
              activityId={activityId}
              key={idx}
              gradeData={criteria} />
          )
      }
    </div>
  )

}

export default SidebarGrading;