import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './TeacherDashboard.module.scss';
import { LoadingOverlay } from 'components';
import { defaultUserImg } from 'helpers/uiFunctions';
import DashboardClasses from './DashboardClasses/DashboardClasses';
import { getDashData } from 'API/TeacherCallFunctions/dashBoardFunctions';
import { sendToApiReturn } from 'API/teacherCalls';
import CopyPill from 'components/UtilityComps/CopyPill/CopyPill';
import { Link, useHistory } from 'react-router-dom';

import cover from './logo.svg'

import NewGroupModal from './NewGroupModal/NewGroupModal';
import VerseOfTheDay from './VerseOfTheDay';
import WelcomeDiv from './WelcomeDiv';

const TeacherDashboard = ({ modalOverlay, synod, fjUser }) => {

  const [ view, toggleView ] = useState('active')
  const dashData = useSelector(state => state.dashData)
  const env = useSelector(state => state.env)
  const newModalContRef = useRef()
  const newModalRef = useRef()
  const inputRef = useRef();
  const history = useHistory()

  useEffect(()=>{ 
    if(!dashData?.teacherId||localStorage.getItem('dashRecall')){
      getDashData(env)
    }
  },[])

  const handleMiniMeLogin = async () => {
    const res = await sendToApiReturn('mini_me', '', 'mini-me')
    let miniMeUrl = res.url
    if(window.location.host === "localhost:3000") {
      miniMeUrl = "http://localhost:3011/auth/student/login_as"
    }
    localStorage.setItem('resURL', miniMeUrl)
    window.location.href = miniMeUrl;
  }

  const toggleNewModal = (input, res) => {    
    newModalRef.current.classList[input](styles.showModal)
    if(inputRef.current && input==='add'){
      inputRef.current.value=""
      inputRef.current.focus()
    }
  }

  const handleClick = () => {
    history.push(`/teacher/resources`)
  }
  const handleClickSec = () => {
    history.push(`/teacher/resources/635`)
  }

  return (
    <>
      {
        !dashData || !dashData.classes  ?
          <LoadingOverlay
            blueBg
            blueSpinner
            loadingText={'Retrieving group data...'} />
        :
        <div className={`${styles.container} ${synod && styles.synod} ${view === 'archive' && styles.archive}`}>
          <div className={styles.sideBar}>
            <div className={styles.mainBlock}>
              <div className={styles.blueBar} />
              <img src={defaultUserImg({imageUrl:dashData.imageUrl})} alt="teacher photo" />
              <h5>{dashData.name}</h5>
              <p>{dashData.schoolName !== "" ? `Teacher at ${dashData.schoolName}` : "Teacher"}</p>
              <div className={styles.row}>
                <p>Words written</p>
                <p className={styles.blue}>{dashData.wordsWritten}</p>
              </div>
              <div className={styles.row}>
                <p>Student entries</p>
                <p className={styles.blue}>{dashData.totalStudentEntries}</p>
              </div>
              <div className={styles.row}>
                <p>Total activities</p>
                <p className={styles.blue}>{dashData.totalActivities}</p>
              </div>

              <div className={styles.miniMeDiv}>
                <img src={dashData.miniMeUrl} alt="mini me" />
                <div className={styles.btnDiv}>
                  <div onClick={handleMiniMeLogin} className={styles.miniMeBtn}>
                    Log in as "mini me"
                  </div>
                    
                    <div className={styles.tipDiv}>
                      <div className={styles.tooltip}>
                      "Mini me" is your own student profile. Your "Mini-me" will be included in each of your groups or classes and can participate as one more student. Logging in as "mini-me" will let you see what students see
                      </div>
                    </div>
                </div>
              </div>
              
            </div>

            {

              !fjUser ?
                <div className={styles.additionalBlock}>
                  <div className={styles.icon} />
                  <div className={styles.text}>
                    <h3>Referral code</h3>
                    <p>Share & get a free month for each referral</p>
                    <CopyPill copyText={"Copy"} copyData={dashData.referralUrl} blue smallRadius bold />
                  </div>
                  <div className={styles.tipDiv}>
                    <div className={styles.tooltip}>Get a free month when you share your unique code with other teachers and they get the same price plan as you.</div>
                  </div>
                </div>
              :
                <div className={`${styles.additionalBlock} ${styles.resource} `}>
                  <div className={`${styles.imgDiv}`}>
                    <img src={cover} alt="journal" />
                  </div>
                  <div className={styles.btns}>
                    <div onClick={handleClick} className={styles.first}>Check Workbooks</div>
                    <div onClick={handleClickSec} className={styles.second}>
                      Access Sample activity
                    </div>
                  </div>
                </div>

            }
          </div>

          <div id={'dashMain'} className={styles.main}>
            <div className={styles.topBar}>
              <div className={`${styles.radios} ${view === 'archive' && styles.grey}`}>
                <div onClick={()=>toggleView('active')} className={`${styles.radioDiv} ${view==="active"&&styles.noClick}`}>
                  <p>Active Groups</p>
                  <p className={styles.res}>Active</p>
                </div>
                <div onClick={()=>toggleView('archive')} className={`${styles.radioDiv} ${view!=="active"&&styles.noClick}`}>
                  <p>Archived Groups</p>
                  <p className={styles.res}>Archived</p>
                </div>
                <div onClick={() => toggleNewModal('add')} className={`${styles.radioDiv} ${styles.button} ${view!=="active"&&styles.hidden}`}>
                  <p>+ New Group</p>
                  <p className={styles.res}>+ New</p>
                </div>
                <div className={`${styles.line} ${styles[view]}`} />
              </div>
            </div>

            <NewGroupModal
                inputRef={inputRef}
                toggleNewModal={toggleNewModal}
                newModalRef={newModalRef}
                fjUser={fjUser} />

            <DashboardClasses
              modalOverlay={modalOverlay} 
              classes={dashData.classes}
              view={view}
              fjUser={fjUser} />
              

            <div className={styles.bottomBar}>
              {fjUser &&   <VerseOfTheDay modalOverlay={modalOverlay} />}
              <div className={styles.contactUs}>
                <a href={"mailto:info@escribli.com"}>Contact us</a>
              </div>
            </div> 
          </div>
          <img className={styles.responsiveTeacherImg} src={defaultUserImg({imageUrl:dashData.imageUrl})} alt="teacher photo" />
        <WelcomeDiv modalOverlay={modalOverlay} />

        </div>
      }

    </>
  )
}

export default TeacherDashboard;
