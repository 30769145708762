export const queryItemReducer = (state='student', action) =>{
  
  switch(action.type){

      case 'SET_QUERY_ITEM':
        return action.payload
      default:
        return state
  }
}



