import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ClassmatesModal.module.scss';

const ClassmatesModal = ({ item: { className, classId, students}}) => {

  return (
    <div className={styles.container}>
      <h1>{className}</h1>
      <div className={styles.blocks}>
        {
          students.map((student, idx) => 
          <Link to={`/teacher/viewStudent/${classId}/${student.studentId}/1`} className={styles.block}>
            <img src={student.boomerUrl} alt="avatar" />
            <h5>{student.studentName}</h5>
          </Link>)
        }
      </div>
    </div>
  )

}

export default ClassmatesModal;