import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ModalBox } from 'components';
import '../../../shared.scss';


const ModalOverlay = ({ modalOverlayRef }) => {


  const location = useLocation();


  useEffect(() => {
    if(modalOverlayRef.current){modalOverlayRef.current.classList.remove('show')};
    
    // eslint-disable-next-line
  },[location])



  return (

    <div className="modalOverlay" ref={modalOverlayRef}>
      <div className="overInner">
        <ModalBox modalOverlay={modalOverlayRef} />
      </div>
    </div>

  )

}

export default ModalOverlay;