import React from 'react';
import { useState } from 'react';
import spinner from 'assets/Spinner.svg'

import styles from './SidebarGrammarReport.module.scss';
import { getFromApi } from 'API/teacherCalls';
import GrammarReportBlock from './GrammarReportBlock';

const SidebarGrammarReport = ({ activityId, sectionIndex, studentId, wordCount, autoFeedbackCallsCount, autoFeedback }) => {

  const [ localCallCount, setLocalCount ] = useState(autoFeedbackCallsCount)
  const [ localReportData, setLocalReportData ] = useState(autoFeedback)
  const [ loading, toggleLoading ] = useState(false)
  const reportArray = localReportData.report
  const {fullEntry} = localReportData
  const blockBtn = localCallCount > 1 || wordCount < 10;

  const handleGenerate = async () => {
    toggleLoading(true)
    const entryObject = await getFromApi(`activity/${activityId}/section/${sectionIndex}/auto_feedback/${studentId}`)
    setLocalReportData(entryObject.autoFeedback)
    setLocalCount(entryObject.autoFeedbackCallsCount)
    toggleLoading(false)
  }

  return (
    <div className={styles.container}>

    {loading &&  
      <div className={styles.loadingOverlay}>
        <img src={spinner} alt="" />
      </div>
    }

      <div className={styles.topRow}>

        <div className={styles.countDiv}>
          <p>{2-localCallCount}/2 reports left</p>
        </div>
        
        {
          localCallCount === 1 &&

            <div onClick={handleGenerate} className={`${styles.generateBtn} ${styles.slim}`}>
              <p>Run grammar report</p>
            </div>
          
        }

      </div>
      <div className={styles.mainDiv}>
        {
          !reportArray?.length ?

            <div className={styles.placeholderDiv}>
              { 
                wordCount < 10 ? 
                  <h2>A minimum of 10 words is required for the grammar report</h2> 
                :
                  <p className={styles.grey}>You can generate 2 reports per student</p>
              }

              <div onClick={handleGenerate} className={`${styles.generateBtn} ${blockBtn && styles.disabled}`}>
                <p>Run grammar report</p>
              </div>
            </div>
          
          :

            <GrammarReportBlock
              reportArray={reportArray}
              fullEntry={fullEntry} />

        }

      </div>
    </div>
  )

}

export default SidebarGrammarReport;