import { sendToApiReturn } from 'API/teacherCalls';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import styles from './LinkModal.module.scss';


const LinkModal = ({ addLink }) => {

  const [ display, toggleDisplay ] = useState(false)
  const [ inputText, setInput ] = useState("")
  const inputRef = useRef()

  useEffect(()=>{
    if(display){
      inputRef.current.focus()
    }
  },[display])
  

  const handleLink = async () => {
    
    let url = inputText;
    var pattern = /^((http|https|ftp):\/\/)/;
    if(!pattern.test(url)) {
      url = "http://" + url;
    }
    addLink(url)
    toggleDisplay(false)
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.linkBtn}`} onClick={()=>toggleDisplay(true)} />
      <div className={`${styles.tooltip}`}>Add link to page</div>
      <div onClick={()=>toggleDisplay(false)} className={`${styles.modalContainer} ${display && styles.display}`}>
        <div onClick={e=>e.stopPropagation()} className={`${styles.linkModal}`}>
          <p>Enter link:</p>
          <input
            ref={inputRef}
            type={"text"}
            value={inputText||""}
            onKeyDown={e=>{
              if(e.key=="Escape"){toggleDisplay(false)}
              if(e.key=="Enter"&&inputText !== ""){handleLink()}
            }}
            onChange={e=>setInput(e.target.value)}/>
          <div onClick={handleLink} className={`${styles.confirmBtn} ${inputText == "" && styles.disabled}`}>Save</div>
        </div>
      </div>
    </div>
  )

}

export default LinkModal;