import { openInNewTab } from './uiFunctions'

// @todo update redirects to Escribli URLs

export const redirectUser = (param) => {

  let env

  if(window.location.host ==='localhost:3000'){
    env = 'dev'
  } else if (window.location.host ==='app.sand.faith-journals.com'){
    env = 'stage'
  } else if (window.location.host ==='app.faith-journals.com'){
    env = 'prod'
  }

  if(env === 'dev'){
    window.location=`http://localhost:3000/${param}`
  } else if(env === 'stage' ) {
    window.location = `https://app.sand.faith-journals.com/${param}`
  }else if(env === 'prod') {
    window.location = `https://app.faith-journals.com/${param}`
  }
}


export const redirectStatic = (route) => {
  //cannot use the local storage env item as is used for a un-logged-in redirect in auth.jsx
  let env

  if(window.location.host === 'localhost:3000'){
    env = 'dev'

  } else if (
    window.location.host === 'app.sand.escribli.com'||
    window.location.host === 'app.sand.faith-journals.com'){
    env = 'stage'

  } else if (
    window.location.host === 'app.escribli.com'||
    window.location.host === 'escribli.com' ||
    window.location.host === 'app.faith-journals.com'||
    window.location.host === 'faith-journals.com'){
    env = 'prod'
  }

  if(env === 'dev'){
    window.location=`http://localhost:3011/${route}` 
  } else if(env === 'stage') {
    if(window.location.host === 'app.sand.escribli.com') {
      window.location = `https://app.sand.escribli.com/${route}`
    }

    if(window.location.host === 'app.sand.faith-journals.com') {
      window.location = `https://app.sand.faith-journals.com/${route}`
    }

  }else if(env === 'prod') {
    if( window.location.host === 'app.faith-journals.com'){
      window.location = `https://faith-journals.com/${route}`
    }else {
      window.location = `https://escribli.com/${route}`
    }
  }
}

export const redirectGoogleClassroomUrl = (activityId) => {

  const env  = localStorage.getItem('env')
    
  if(env === 'dev'){
    return `http://localhost:3010/project/${activityId}/current` 

  } else if(env === 'stage') {
    return `https://hey.faith-journals.com/project/${activityId}/current`

  }else if(env === 'prod') {
    // TODO - here we need to deal both with write.escribli and with write.faith-journals
    return `https://write.faith-journals.com/project/${activityId}/current`
  }
}

export const redirectStaticStore = (bookCode) => {

  const env  = localStorage.getItem('env')
    
  if(env === 'dev'){
    openInNewTab(`http://localhost:3011/store?code=${bookCode}`)

  } else if(env === 'stage' ) {
    openInNewTab(`https://sand.escribli.com/store?code=${bookCode}`)

  }else if(env === 'prod') {
     openInNewTab(`https://escribli.com/store?code=${bookCode}`)
  }
}
