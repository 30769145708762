import React, { useRef, useState } from 'react';

import styles from './ConnectYourChildModal.module.scss';
import {checkParams} from 'helpers/inputFunctions';
import { sendToApiReturn } from 'API/parentCalls';
import { useSelector } from 'react-redux';
import { store } from 'storeConfig';
import { loadDashData } from 'Redux/actions';

const CreateChild = ({ setApiRes }) => {

  const [ params, setParams ] = useState({grade:'', lastName:'', firstName:'', email:''});
  const [ error, setError ] = useState(false)
  const [ btnState, setBtnState ] = useState('init')
  const gradeRef = useRef()
  const fNameRef = useRef()
  const sNameRef = useRef()
  const btnRef = useRef()
  const dashData = useSelector(state => state.dashData)

  


  const handleCreate = async () => {
    if(!checkParams({1:params.grade, 2:params.firstName, 3:params.lastName})){
      setError(true)
      btnRef.current.classList.add('shake')
      setTimeout(()=>{btnRef.current.classList.remove('shake')},500)

    } else {
      setBtnState('loading')
      const data = await sendToApiReturn('create_student', params)
      setApiRes(data)

      const { student } = data
      const newChildObj = {
        childId:student.studentId,
        childName:student.studentName,
        boomerUrl:student.boomerUrl,
        boomerBucks: student.boomerBucks,
        currentActivities:[]
      }
      store.dispatch( loadDashData({...dashData, kids:[...dashData.kids, newChildObj]}))
    }
  }

  Array.from(document.getElementsByTagName('input')).forEach(i => {
    i.addEventListener('change', ()=>{setError(false)})
    i.addEventListener('keydown', ()=>{setError(false)})
  })

  return (
    <div className={styles.createChild}>
      <h1>Create a account for your child</h1>
      <div className={styles.name}>
          <div className={styles.first}>
            <h2 ref={fNameRef} >First Name</h2>
            <input type="text" onChange={(e)=>{setParams({...params, firstName:e.target.value})}}/>
            {params.firstName !== '' ? <div className={styles.tickDiv}></div> : null}
          </div>
          <div className={styles.second}>
            <h2 ref={sNameRef} >Second Name</h2>
            <input type="text" onChange={(e)=>{setParams({...params, lastName:e.target.value})}}/>
            {params.lastName !== '' ? <div className={styles.tickDiv}></div> : null}
          </div>
        </div>

        <div className={styles.grade}>
          <h2 ref={gradeRef} >Grade</h2>
          <div className={styles.radios} onChange={(e)=>{setParams({...params, grade:e.target.value})}}>
            <label>
              <input type="radio" name="grade" value={2}/>
              <div className={styles.pill}>2</div>
            </label>
            <label>
              <input type="radio" name="grade" value={3}/>
              <div className={styles.pill}>3</div>
            </label>
            <label>
              <input type="radio" name="grade" value={4}/>
              <div className={styles.pill}>4</div>
            </label>
            <label>
              <input type="radio" name="grade" value={5}/>
              <div className={styles.pill}>5</div>
            </label>
            <label>
              <input type="radio" name="grade" value={6}/>
              <div className={styles.pill}>6</div>
            </label>
            <label>
              <input type="radio" name="grade" value={7}/>
              <div className={styles.pill}>7</div>
            </label>
            <label>
              <input type="radio" name="grade" value={8}/>
              <div className={styles.pill}>8</div>
            </label>
            <label>
              <input type="radio" name="grade" value={9}/>
              <div className={styles.pill}>9</div>
            </label>
            <label>
              <input type="radio" name="grade" value={10}/>
              <div className={styles.pill}>10</div>
            </label>
            <label>
              <input type="radio" name="grade" value={11}/>
              <div className={styles.pill}>11</div>
            </label>
            <label>
              <input type="radio" name="grade" value={12}/>
              <div className={styles.pill}>12</div>
            </label>
            {params.grade !== '' ? <div className={styles.tickDiv}></div> : null}
          </div>
        </div>

        <div className={styles.email}>
          <h2 ref={fNameRef} >Email <span>*optional</span></h2>
          <input type="text" onChange={(e)=>{setParams({...params, email:e.target.value})}}/>
          {params.email !== '' ? <div className={styles.tickDiv}></div> : null}
        </div>

        <p className={styles.createEmail}>The email is used for your child's login. If not specified, we will generate an email-like username</p>

        <div className={styles.buttons}>

        {btnState!=='loading' ? 
          <div ref={btnRef} onClick={handleCreate} className={styles.createBtn}>Create account</div>
        : 
          <div  className={styles.loadingBtnCreate} ></div>
        }

          {error && <p className={styles.error}>Please complete all required fields</p>}
        </div>
    </div>
  )

}

export default CreateChild;