import React, { useState } from 'react';

import styles from './BreakoutRoomsModal.module.scss';
import icon from '../../../../../../assets/sidebarIcons/sidebar_rooms_blue.svg';
import { useSelector } from 'react-redux';
import { showModal } from 'helpers/uiFunctions';
import { sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { updateActivitySection } from 'Redux/actions';

const BreakoutRoomsModal = ({activeSection, setActiveSection, setModal, mobile, modalOverlay, activeSection: { rooms, sectionIndex }}) => {

  const [ localBool, setLocalBool ] = useState(rooms.length)
  const [ pending, setPending ] = useState(false)
  const activity = useSelector(state => state.activityData)

  const handleSlider = () => {
    setLocalBool(!localBool)
    setPending(!rooms.length ? false : localBool)
  }

  const handleSubmit = async () => {
    if(localBool){
      setModal(null)
      showModal( modalOverlay,
        {type:'breakoutRooms', 
        students:activeSection.entries, 
        activeSection:activeSection,
        setActiveSection:setActiveSection,
        activity:activity,
        section:activeSection}
        )
      } else {
      const res = await sendToApiReturn(`activity/${activity.activityId}/rooms/${sectionIndex}`, {rooms:[]})
      setActiveSection(res)
      store.dispatch(updateActivitySection(sectionIndex, res))
      setPending(false)
      setTimeout(()=>setModal(null),200)
    }
  }

  return (
    <div className={`${styles.container} ${mobile && styles.mobile}`}>

      <div className={styles.titleBar}>
        <img src={icon} alt="upload" />
        <h2>Breakout rooms</h2>
      </div>

      <div className={styles.textDiv}>
        <p>Foster students' teamwork skills by creating breakout rooms for collaborative writing.</p>
      </div>

      <div onClick={handleSlider} className={styles.sliderDiv}>
        <p className={!localBool && styles.show}>not active</p>
        <div className={`${styles.slider} ${localBool && styles.active}`}>
          <div className={`${styles.sliderInner} ${localBool && styles.active}`} />
        </div>
        <p className={localBool && styles.show}>active</p>
      </div>

      <div className={styles.howItWorks}>
        <h5>How do breakout rooms work?</h5>
        <p>Foster student’s teamwork skills by creating breakout rooms for collaborative writing.</p>
      </div>

      <div className={`${styles.submitBtn} ${(localBool || pending) && styles.enabled}`}>
        <p onClick={handleSubmit}>{pending ? "Save" : "Setup"}</p>
      </div>

    </div>
  )
  

}

export default BreakoutRoomsModal;