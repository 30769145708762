import { sendToApiReturn } from 'API/teacherCalls';
import { showModal } from 'helpers/uiFunctions';
import React from 'react';
import { useSelector } from 'react-redux';
import { archiveClass, unarchiveClass, updateDashData } from 'Redux/actions';
import { store } from 'storeConfig';

import styles from './DashGroupBlock.module.scss';

const DashGroupDropdown = ({toggleDropdown, synod, toggleEditing, dropRef, idx, classId, className, modalOverlay, archived, canBeDeleted }) => {

  const activeClasses = useSelector(state=>state.dashData.activeClasses)

  const handleDuplicateClass = async () => {
    const data = await sendToApiReturn(`class/create?className=${className + ' copy'}&templateClassId=${classId}`)
    store.dispatch(updateDashData('classes', data))
    window.location = `${localStorage.getItem('frontUrl')}/teacher/classes/view/${data[0].classId}/?activities`
    localStorage.setItem('dashRecall', true)
  }

  const hanldeArchive = () => {

    if(archived){
      store.dispatch(unarchiveClass(idx))
      store.dispatch(updateDashData('activeClasses', activeClasses+1))
      
    } else {
      store.dispatch(archiveClass(idx))
      store.dispatch(updateDashData('activeClasses', activeClasses-1))
    }

    sendToApiReturn(`class/${classId}/archive`)
    toggleDropdown('remove')

  }
  
  const handleDeleteClass = async () => {

    console.log({
          type:'remove', 
          variant:'deleteClass', 
          source:!archived?'dash':'notDash',
          classId:classId,
          index:idx,
          func:toggleDropdown
        })

    showModal(modalOverlay, {
        type:'remove', 
        variant:'deleteClass', 
        source:!archived?'dash':'notDash',
        classId:classId,
        index:idx,
        func:toggleDropdown
      })
  }

  return (

    <div onMouseLeave={() => toggleDropdown('remove')} ref={dropRef} className={`${styles.dropdown} ${synod && styles.synod} ${archived && styles.archive}`}>
      {
        !archived &&
        <>

          <div onClick={() => { toggleEditing(true); toggleDropdown('remove') }} className={`${styles.dropRow} ${styles.edit}`}>
            <div className={styles.icon} />
            <p>Edit group name</p>
          </div>

          <div onClick={handleDuplicateClass} className={`${styles.dropRow} ${synod && styles.hide} ${styles.duplicate}`}>
            <div className={styles.icon} />
            <p>Duplicate group</p>
          </div>

        </>
      }

      <div onClick={hanldeArchive} className={`${styles.dropRow} ${synod && styles.hide} ${styles.archive}`}>
        <div className={styles.icon} />
        <p>{!archived ? 'Archive' : 'Unarchive'} group</p>
      </div>

      <div onClick={handleDeleteClass} className={`${styles.dropRow} ${styles.delete} ${synod && styles.hide} ${!canBeDeleted && styles.disabled}`}>
        <div className={styles.icon} />
        <p>Delete group</p>
      </div>
    </div>

  )

}

export default DashGroupDropdown;