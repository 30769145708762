import React from 'react';

import styles from './UploadFilesModal.module.scss';
import icon from '../../../../../../assets/sidebarIcons/sidebar_upload_blue.svg';
import spinner from '../../../../../../assets/Spinner858585.svg';
import { useRef } from 'react';
import { useState } from 'react';
import { sendToApiReturn } from 'API/teacherCalls';
import { useSelector } from 'react-redux';
import { store } from 'storeConfig';
import { updateActivitySection } from 'Redux/actions';

const UploadFilesModal = ({ mobile, activeSection, setActiveSection }) => {

  const [ loading, setLoading ] = useState(false)
  const activityId = useSelector(state => state.activityData.activityId)
  const uploadInput = useRef()

  const isImageFile = url =>  ['.png', '.jpg', 'jpeg'].indexOf(url.slice(url.length-4)) !== -1

  const fileLimitCheck = () => {
    if(activeSection.files.length>=5||activeSection.files.length>=5){return}
    else{uploadInput.current.click()}
  }

  const handleUploadFile = async (e) => {
    if(e.target.files.length){
      setLoading(true)
      const formData = new FormData();
      formData.append('file', e.target.files[0], e.target.files[0].name);
      formData.append('name', 'image from teacher app'); 
      const data = await sendToApiReturn(`activity/${activityId}/save_file/${activeSection.sectionIndex}`, formData)
      setActiveSection({...activeSection, files:[...data]})
      store.dispatch(updateActivitySection(activeSection.sectionIndex, {...activeSection, files:[...data]}))
      setLoading(false)
    }
  }
   
  const handleDeleteFile = async (url) => {
    setLoading(true)
    let newImgArray = [...activeSection.files];
    const index = newImgArray.findIndex(i => i.fileUrl == url)
    newImgArray.splice(index, 1);
    const data = await sendToApiReturn(`activity/${activityId}/delete_file/${activeSection.sectionIndex}`, {url: url})
    setActiveSection({...activeSection, files:[...data]})
    setLoading(false)
  }


  return (
    <div className={`${styles.container} ${mobile && styles.mobile}`}>

      <div className={styles.titleBar}>
        <img src={icon} alt="upload" />
        <h2>Upload files</h2>
      </div>

      <div className={styles.textDiv}>
        <p>Upload files from your computer.</p>
        <i>Supported file types: png, jpg, jpeg, docx, and pdf</i>
        <p className={styles.small}>Maximum upload file size: 10mb</p>
      </div>

      <div className={styles.filesDiv}>
        {loading && <div className={styles.loadingDiv}> <img src={spinner} alt="spinner" /> </div>}
        {
          activeSection.files?.length ? 
            activeSection.files.map((item,idx) =>
              <div key={idx} className={styles.itemDiv}>
                { isImageFile(item.fileUrl) ? <img src={item.fileUrl} alt="image" /> : <div className={styles.folderIcon} /> }
                <p>{item.fileName}</p>
                <div onClick={()=>handleDeleteFile(item.fileUrl)} className={styles.deleteDiv}></div>
              </div>
            )
          :
          !loading && <p className={styles.placeholder}>No files uploaded</p>
        }
      </div>

      <div className={styles.buttons}>
        <input type="file" name="upload" ref={uploadInput} onChange={handleUploadFile} accept=".png, .jpg, .jpeg, .docx, .pdf"/>
        <div 
          onClick={fileLimitCheck}  
          className={activeSection.files.length<5?styles.submitBtn:`${styles.submitBtn} ${styles.disabled}`} > 
          <p>{activeSection.files.length < 5 ? "Upload file" : "Limit reached"}</p> 
        </div>
      </div>

    </div>
  )

}

export default UploadFilesModal;