import React, { useState } from 'react';

import styles from './EmailDropdown.module.scss'
import plus from 'assets/icon_plus_blue.svg'
import chevron from 'assets/chevron_blue.svg'
import multi from 'assets/icon-mail--multiple_blue.svg'
import user from 'assets/user_grey.svg'
import add from 'assets/user_add.svg'
import copy from 'assets/icon-copy--grey.svg'
import { showModal } from 'helpers/uiFunctions';

const EmailDropdown = ({ array, studentId, modalOverlay }) => {

  const [ dropdownVis, toggleDropdown ] = useState(false)
  const [ conf, setConf ] = useState(null)
  const [ guardians, setGuardians ] = useState(array)

  const isEmpty = !guardians||!guardians.length

  const handleCopy = (email, index) => {
    setConf(index);   
    setTimeout(()=>{ setConf(null);},1500)
    navigator.clipboard.writeText(email) 
  }

  const triggerModal = () => showModal(modalOverlay, {type:'sendParentInvite', studentId, guardians:guardians, setGuardians:setGuardians})

  const clickHandler = () => {
    if(isEmpty) return triggerModal()
    toggleDropdown(true)
  }

  return (
    <section onClick={clickHandler} onMouseLeave={()=>toggleDropdown(false)}  className={`${styles.container} ${isEmpty && styles.empty}`}>
      {
        isEmpty ?
          <>
            <p>Add <span>parent</span> email</p>
            <img src={plus} alt="plus" />
          </>

        :

          <>
            {guardians.length>1 && <img src={multi} alt="multi"/> }
            <p>{guardians[0]}</p>
            <img className={styles.chevron} src={chevron} alt="chevron" />

            <div className={`${styles.dropdown} ${dropdownVis && styles.show}`}>
              {
                guardians.map((item,idx) =>
                  <div onClick={()=>handleCopy(item, idx)} key={idx} className={styles.dropRow}>
                    <img src={user} alt="user" />
                    <p>{ conf===idx? 'copied!' : item }</p>
                    <img className={styles.copy} src={copy} alt="copy" />
                  </div>
                )
              }
              <div onClick={triggerModal} className={`${styles.dropRow} ${styles.addEmail}`}>
                <img src={add} alt="add student"/>
                <p>Add parent email</p>
              </div>
            </div>
          </>

      }
    </section>
  )

}

export default EmailDropdown;