import React, { useState } from 'react';

import styles from './PrivateModeModal.module.scss';
import icon from '../../../../../../assets/sidebarIcons/sidebar_private_blue.svg';
import { useSelector } from 'react-redux';
import { showModal } from 'helpers/uiFunctions';
import { sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { addActivityData, updateActivitySection } from 'Redux/actions';

const PrivateModeModal = ({ setModal, activity }) => {

  const { privateMode, activityId } = activity
  const [ localBool, setLocalBool ] = useState(privateMode)
  const [ pending, setPending ] = useState(false)

  const handleSlider = () => {
    setPending( localBool === privateMode )
    setLocalBool(!localBool)
  }

  const handleSubmit = async () => {
    console.log('submit')
    const res = await sendToApiReturn(`activity/${activityId}/update`, {...activity, privateMode:localBool});
    store.dispatch( addActivityData({...activity, privateMode:localBool}))
    setModal(false)
    setPending(false)
  }

  return (
    <div className={styles.container}>

      <div className={styles.titleBar}>
        <img src={icon} alt="upload" />
        <h2>Private mode</h2>
      </div>

      <div className={styles.textDiv}>
        <p>In private mode, educators won't be able to see any of the student entries for the entire activity (all sections).
        Educators will be able to see who is writing and how many words each one has written.</p>
      </div>

      <div onClick={handleSlider} className={styles.sliderDiv}>
        <p className={!localBool && styles.show}>not active</p>
        <div className={`${styles.slider} ${localBool && styles.active}`}>
          <div className={`${styles.sliderInner} ${localBool && styles.active}`} />
        </div>
        <p className={localBool && styles.show}>active</p>
      </div>

      <div className={`${styles.submitBtn} ${ pending && styles.enabled}`}>
        <p onClick={handleSubmit}>Save</p>
      </div>

    </div>
  )
  

}

export default PrivateModeModal;