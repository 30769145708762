import React,{useState} from 'react';
import styles from './Thumbnail.module.scss'
import { showModal } from '../../../../helpers/uiFunctions';



const Thumbnail = ({img,modalOverlay,images,slide}) => {
    const [hover,setHover] = useState(false)
    console.log(modalOverlay)
    return (
        <>
        <div className={styles.thumbnail} onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            <img src={img} alt='' />
            <div className={`${styles.hoverContainer} ${!hover&&styles.hide}`}>
                <div className={styles.btnWrapper}>
                    <div className={`${styles.button} ${styles.bordered}`} onClick={() => showModal(modalOverlay, { type: 'previewBigCarousel', item:{images:images,slideIndex:slide} })} >preview</div>
                </div>
            </div>
        </div>
        
        </>
    );
}

// 

export default Thumbnail;