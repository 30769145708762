import { LoadingOverlay } from 'components';
import React, { useEffect, useState } from 'react';

import wordbankImg from 'assets/sidebarIcons/sidebar_wordbank_blue.svg';
import styles from './EntryModalResponsive.module.scss';
import EntryTextEditor from './EntryTextEditor/EntryTextEditor';
import { stripTags } from 'helpers/stripTags';
import pencil from 'assets/pencil_blue.svg'
import save from 'assets/save.svg'
import approve from 'assets/thumb.svg'
import { sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { updateActivitySection } from 'Redux/actions';
import privateModeImg from 'assets/sidebarIcons/sidebar_private_blue.svg';
import EntryCommentInput from './EntryCommentInput/EntryCommentInput';
import StudentFileDiv from './EntryTextEditor/StudentFileDiv/StudentFileDiv';

const EntryModalResponsive = ({ activityClassName, privateMode, handleApproveEntry, activityName, sectionName, sectionIndex, entryData, wordBank, activityId }) => {

  const [ localText, setLocalText ] = useState()
  const [ editing, setEditing ] = useState(false)
  const [ usedWords, setUsed ] = useState([])
  const [ previewImg, setPreview ] = useState(null)

  const handleEditSave = async () => {

    if (editing) {
      const data = await sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/edit_entry/${entryData.studentId}`, {value: localText})
      store.dispatch(updateActivitySection(sectionIndex, data))
    }
    
    setEditing(!editing)

  }

  const sortWords = text => {

    if(!text?.length) return

    //strip HTML tags from entry text, split into array of words, strip punctuation, 
    //convert words to lower case
    let entryTextArray = stripTags(text.replace(/<br>/g, " ").replace(/[^\w\s]|_/g, "")).split(' ')
    entryTextArray=entryTextArray.map(i => i.toLowerCase())

    //first and last items take a p from the enclosing <p> tags for some reason! Cut those out using substrings
    entryTextArray[0] = entryTextArray[0].substring(1)
    entryTextArray[entryTextArray.length-1] = entryTextArray[entryTextArray.length-1].substring(0, entryTextArray[entryTextArray.length-1].length-1)

    //identify used words by filtering the entryTextArray prepared above
    setUsed([...new Set(wordBank.filter(n => entryTextArray.includes(n.toLowerCase())))])
    
  }

  useEffect(()=>{if(localText)sortWords(localText)},[localText])

  useEffect(()=>{
    if(entryData)setLocalText(entryData.entryText)
  },[entryData])

  return (
    <div className={styles.container}>
      {
        !entryData ? <LoadingOverlay contained />

        :

          <>

          {/* <div className={styles.main}> */}
            <div className={styles.topBar}>
              <img src={entryData.studentBoomerUrl} alt="avatar" />
              <h5>{entryData.studentName}</h5>
            </div>  
            <div className={styles.detailsBar}>
              <p className={styles.sml}>{activityClassName} / {activityName}</p>
              <h3><span><p>{sectionIndex+1}</p></span> {sectionName} </h3>
            </div>

            <div className={styles.editorDiv}>
              {privateMode &&
                <div className={styles.privateModeOverlay}>
                  <img src={privateModeImg} alt="private mode" />
                  <h1>Private mode is turned <span>ON</span></h1>
                  <p>In private mode, educators won't be able to see any of the student entries for the entire activity (all sections).
                    Educators will be able to see who is writing and how many words each one has written.</p>

                </div>
              }
              <EntryTextEditor
                privateMode={privateMode}
                editing={editing}
                localText ={localText}
                setLocalText={setLocalText} 
                mobile />
            </div>

            {
              wordBank.length > 0 &&
                <div className={styles.wordbank}>
                  <div className={styles.titleBar}>
                    <img src={wordbankImg} alt="wordbank" />
                    <h6>Word bank</h6>
                    <p className={styles.count}>{usedWords.length}/{wordBank.length} words used</p>
                  </div>
                  <div className={styles.itemsDiv}>
                    {
                      wordBank.map((word, idx) =>
                        <div key={idx} className={`${styles.wordBlock} ${usedWords.includes(word.toLowerCase()) && styles.used}`}>
                          <p>{word}</p>
                        </div>
                      )
                    }
                  </div>
                </div>
            }
                       
      {
        (entryData.studentFiles?.length > 0 ) &&

          <div className={styles.filesDiv}>
            {
              entryData.studentFiles?.map((i,idx) => 
                <StudentFileDiv
                  styles={styles}
                  showPreview={()=>setPreview(i.fileUrl)} 
                  key={idx} 
                  item={i} />
                )
            }
          </div>

      }

            <div className={styles.buttons}>
              <div className={styles.left}>
                <EntryCommentInput 
                  entryData={entryData}
                  activityId={activityId}
                  sectionIndex={sectionIndex}  />
              </div>
              <div className={styles.right}>

                <div onClick={handleEditSave} className={styles.editBtn}>
                  <img src={!editing ? pencil : save} alt="icon" />
                </div>

                <div onClick={handleApproveEntry} className={`${styles.approveBtn} ${( !entryData.submitted || entryData.approved ) && styles.inactive}`}>
                  <img src={approve} alt="approve" />
                  <p>Approve</p>
                </div>
              </div>
            </div>

            {
              previewImg &&
                <div className={styles.imagePreviewOverlay}>
                  <div className={styles.imgDiv}>
                    <div onClick={()=> setPreview(null)} className={styles.close} />
                    <img src={previewImg} alt="attached image" />
                  </div>
                </div>
            }

          </>

      }
    </div>
  )

}

export default EntryModalResponsive;