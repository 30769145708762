import React, {useState} from 'react'
import styles from './Pricing.module.scss';

import { useHistory } from 'react-router-dom';

import PricingCard from './PricingCard';

import SwitchButton from './Switch';

const MainPricing = () => {
    const [selectedValue, setSelectedValue] = useState('monthly');
    const history = useHistory()
  
  
    const handleChange = () => {
      const newSelectedValue = selectedValue === 'monthly' ? 'annual' : 'monthly';
      setSelectedValue(newSelectedValue);
    };


  
    const handleButton = () => history.push('/teacher/account/manage')

    const cardData = [
        {
          type:"Trial Account",
          allowance: "First 30 days",
          monthly:"0",
          yearly: null,
          benefits:["Access to all functionality", "Unlimited activities"],
          ctaText:"Start for free",
          subtitle: '',
          ctaAction:"signup_teacher"
        },
        {
          type:"Standard",
          allowance: "Up to 20 students",
          monthly: selectedValue === 'annual' ? '10' : '1',
          yearly: "100",
          benefits:[
            "Access to all functionality","Unlimited activities", "Personalized onboarding support", 
            "Professional development, training and ongoing support"
          ],
          subtitle: "",
          ctaText:"Get started",
          ctaAction:"contact"
        },
        {
          type:"Spirit of Truth Workbooks",
          allowance: "",
          monthly:"6.99",
          yearly: "",
          benefits:[
           'Journals that include Sophia "Spirit of Truth" workbooks'
          ],
          subtitle: '*A Standard subscription is required to use Sophia Workbooks.',
          ctaText:"Get started",
          ctaAction:"spirit"
        }
      ]

  return (
    <div className={`${styles.container}`}>
    <SwitchButton selectedValue={selectedValue} handleChange={handleChange} />

    <div className={`${styles.cards}`}>
      { cardData.map((i,idx) => 
        <PricingCard 
          cardData={i} 
          key={idx} 
          blue={idx===2} 
          pink={idx===1}
          light={idx===0}
          selectedValue={selectedValue}
          />
      )}
    </div>
      <a href="https://es-courses-static.s3.amazonaws.com/subscription-terms.pdf"  target="_blank" rel="noopener">
         <em>See Faith Journal Subscription Terms</em> 
      </a>
    <div onClick={handleButton} className={styles.trialBtn}>
      <p>Activate my subscription</p>
    </div> 
   </div>
  )
}

export default MainPricing