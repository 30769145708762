import React, { useState, useRef } from 'react';

import styles from './CancelSubscriptionModal.module.scss';
import { sendToApiReturn } from 'API/teacherCalls';

const CancelSubscriptionModal = ({closeBtn, item:{ setSubData }}) => {  
  
  const textRef = useRef();
  const contRef = useRef()
  const checkRef = useRef();

  const [ formData, setFormData ] = useState({reason:'', additionalComments:''})
  const [ confStage, setConfStage ] = useState(false);
  const [ errorVis, setErrorVis ] = useState(false)


  const handleSubmit = async () => {

    const selectedReasons = Array.from(contRef.current.querySelectorAll('input:checked'))
    const arr = selectedReasons.map(({dataset})=>dataset.code)

    if(!selectedReasons.length){
      checkRef.current.classList.add('shake');
      setErrorVis(true)
      setTimeout(()=>{checkRef.current.classList.remove('shake')},500)
      return

    } else if (arr.includes("7") && !formData.additionalComments.length){
      textRef.current.classList.add('shake');
      setErrorVis(true)
      setTimeout(()=>{textRef.current.classList.remove('shake')},500)
      return
    }

    setConfStage(true)
    setSubData(await sendToApiReturn(`plus_cancel`, {...formData, reason:arr}))
  }

  return (
    <div ref={contRef} className={`${styles.container} ${confStage && styles.confStage}`}>
      {!confStage ? 
        <>
          <h1>We are sad to see you go</h1>
          <h4>Before you cancel your subscription, could you please let us know the reason why and any additional feedback will be greatly appreciated.</h4>

          <div ref={checkRef} className={styles.checkboxes} onChange={()=>{setErrorVis(false)}}>
            <label htmlFor="missingFeatures">
              <input type="checkbox" className='check' name="reason" data-code={1} id="missingFeatures"/>
              <span>Missing features I need</span>
            </label>
            <label htmlFor="technicalDifficulties">
              <input type="checkbox" className='check' name="reason" data-code={2} id="technicalDifficulties"/> 
              <span>Technical difficulties</span>
            </label>
            <label htmlFor="switching">
              <input type="checkbox" className='check' name="reason" data-code={3} id="switching"/>
              <span>Switching to a competitor</span>
            </label>
            <label htmlFor="expensive">
              <input type="checkbox" className='check' name="reason" data-code={4} id="expensive"/>
              <span>Too expensive</span>
            </label>
            <label htmlFor="hardToUse">
              <input type="checkbox" className='check' name="reason" data-code={5} id="hardToUse"/>
              <span>Too hard to use</span>
            </label>
            <label htmlFor="usability">
              <input type="checkbox" className='check' name="reason" data-code={6} id="usability"/>
              <span>The usability is different than I expected</span>
            </label>
            <label htmlFor="other">
              <input onClick={()=>{textRef.current.focus()}} type="radio" className='check' name="reason" data-code={7} id="other"/>
              <span>Other (explain below)</span>
            </label>
          </div>

          <textarea 
          ref={textRef}
          onChange={(e)=>{setFormData({...formData, additionalComments:e.target.value}); setErrorVis(false)}}
          placeholder="Other reason for cancellation or additional feedback"
          // readOnly={formData.reason==='other' ? false : true}
          name="additionalComments" 
          id="additional"
          ></textarea>

          <div onClick={()=>{closeBtn.current.click()}} className={styles.btn}>I don't want to cancel anymore</div>
          <p onClick={handleSubmit}>cancel subscription</p>
          {errorVis ? <h3 className={styles.warning}>Please tell us why you are canceling your subscription</h3> : null}
        </>
      :
      
        <div className={styles.confDiv}>
          <h3>Cancellation confirmed</h3>
          <p>You can continue to use premium features until your regularly scheduled renewal date</p>
          <div onClick={()=>{closeBtn.current.click()}} className={styles.btn}>Close</div>
        </div>
      
      }
    </div>
  )

}

export default CancelSubscriptionModal;