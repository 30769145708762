import React from 'react';
import { Link } from 'react-router-dom'

import styles from './upgradePlusModal.module.scss';
import logo from 'assets/logoPlus.svg';
import pdf from 'assets/icon-plus--pdf.svg';
import dupe from 'assets/icon-plus--dupe.svg';
import easy from 'assets/icon-plus--sheet.svg';
import { openInNewTab } from 'helpers/uiFunctions';

const UpgradePlusModal = () => {

  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt="logo"/>
      <p>Enjoy everything you love about Escribli plus more!</p>
      <p>Following teacher’s requests, we have added a set of new functionalities that will make your experience in the classroom even better. These new functionalities are part of Escribli+ :</p>

      <div className={styles.header}>
        <img src={pdf} alt="pdf"/>
        <h2>Make PDFs of your writing activities and your students' entries </h2>
      </div>
      <ul>
        <li><span>To print and provide additional feedback to students</span></li>
        <li><span>To share via email or any other digital means</span></li>
        <li><span>To display your students' best writing and send home to families</span></li>
      </ul>
      <div className={styles.PDFbtn} onClick={()=>{openInNewTab('https://bw-defaults.s3.us-east-2.amazonaws.com/schools/6a-water-unit+study-guide.pdf')}}>PDF Sample</div>

      <div className={styles.header}>
        <img src={dupe} alt="dupe"/>
        <h2>Duplicate assignments to other classes </h2>
      </div>
      <p>You're just a click away from replicating and sharing any amazing assignments you’ve created with other classes! </p>

      {/* <div className={styles.header}>
        <img src={discount}/>
        <h2>Bookstore Discounts</h2>
      </div>
      <p>Your students and their families will also enjoy benefits from your Escribli Plus account by automatically receiving a 10% discount on any books or student journals purchased that are created within your class. </p> */}

      <div className={styles.header}>
        <img src={easy} alt="" srcset=""/>
        <h2>Easy-to-use Grading and Standards-based Rubrics* </h2>
      </div>
      <p>Track your students' writing performance in the three major genres. </p>

      <Link to='/info/pricing' >
        <div className={styles.signUpBtn}>
          Sign Up for Escribli+
        </div>
      </Link>

      {/* <h6>*Will remain available until August 2020 to BW teachers currently using grading</h6> */}
    </div>
  )
  
  
  
}

export default UpgradePlusModal;