import { returnPhaseText } from 'helpers/returnPhaseText';
import { showModal } from 'helpers/uiFunctions';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import ChapterDetailsBottomSection from '../../ChapterDetailsBottomSection/ChapterDetailsBottomSection';
import ChapterList from '../../ChapterList/ChapterList';
import DetailsTextEditor from '../../DetailsTextEditor/DetailsTextEditor';
import SectionStatusBtn from '../../SectionStatusBtn/SectionStatusBtn';
import SetupButtons from '../SetupChapterModal/SetupButtons';

import styles from './ChapterSettingsResponsive.module.scss';

const ChapterSettingsResponsive = ({ fjUser, activeSection, setActiveSection, modalOverlay, activity, isStoryStart, detailsHTML, isBlocked, bottomBlockRef, bottomBlockSrolled  }) => {

  const sectionPhase = returnPhaseText(activeSection.sectionPhase);
  const [ showEditor, toggleEditor ] = useState(false)
  const saveBtnRef = useRef()
  
  const setModalView = input => {
    showModal(modalOverlay, {type:"SetupChapterModal", viewProp:input, activeSection, activity, modalOverlay, isStoryStart, fjUser})
  }

  const handleSaveBtn = e => {
    saveBtnRef.current.innerText = "Saved!"
    setTimeout(()=>{
      if(saveBtnRef.current)saveBtnRef.current.innerText = "Save"
    },1500)
  }

  return (
    <>
      <DetailsTextEditor
        /*  non-functional DetailsTextEditor for preview, functional editor is below <ChapterList />  */
        preview
        mobile
        callback={()=>toggleEditor(true)}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        activity={activity}
        isStoryStart={activity?.activityType === "story" && activeSection?.sectionIndex === 0}
        detailsHTML={detailsHTML}
        saveBtnRef={null} />

        <div className={styles.statusDiv}>
          <p className={`
            ${styles.message}
            ${((activeSection.sectionStatus === 'draft' || activeSection.sectionStatus === 'scheduled')&&!isBlocked) && styles.visible}
            `}>By clicking '<span>Set live</span>', this chapter will be shared with your students.</p>
          <div className={styles.bottom}>
            <p>This chapter is {sectionPhase === "Draft" && "in "}<span className={styles[sectionPhase]} >{sectionPhase}</span></p>

            <SectionStatusBtn 
              modalOverlay={modalOverlay}
              showModal={showModal}
              activity={activity}
              isStoryStart={isStoryStart}
              setActiveSection={setActiveSection}
              activeSection={activeSection} />
          </div>
        </div>

        {!fjUser && <SetupButtons fjUser={false} isStoryStart={isStoryStart} setView={setModalView} />}

        <ChapterList students={activeSection.entries} modalOverlay={modalOverlay} activeId={activeSection.sectionId} activity={activity} />

        <ChapterDetailsBottomSection
          modalOverlay={modalOverlay}
          blockRef={bottomBlockRef}
          bottomBlockSrolled={bottomBlockSrolled} />

        {
          showEditor &&
            <div className={styles.editorDiv}>
              <div className={styles.topRow}>
                <div onClick={()=>toggleEditor(false)} className={styles.chevron} />
                <h3 ref={saveBtnRef} onClick={handleSaveBtn}>Save</h3>
              </div>
              <div className={styles.inputDiv}>
                <DetailsTextEditor
                  mobile
                  preview={false}
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  activity={activity}
                  isStoryStart={activity?.activityType === "story" && activeSection?.sectionIndex === 0}
                  detailsHTML={detailsHTML}
                  saveBtnRef={null} />
              </div>

            </div>
        }
    </>
  )

}

export default ChapterSettingsResponsive;