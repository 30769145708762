import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import ClassActivityBlock from '../ClassActivityBlock/ClassActivityBlock';

import styles from './ClassActivities.module.scss';
import arrow from 'assets/arrow-long-curved2.svg'
import arrowRes from 'assets/arrow-long-curved3.svg'
import face from 'assets/files_plus.svg'
import { normalize } from 'helpers/filterFunctions';
import LoadingOverlay from 'components/StructuralComps/LoadingOverlay/LoadingOverlay';
import { showModal } from 'helpers/uiFunctions';

const ClassActivities = ({ synod, fjUser, modalOverlay, setListItem, setClassData, filterValue, setFilterValue, toggleNewActivityVis, classData }) => {

  const [mapItems, setMapItems] = useState([...classData.activities, 'placeholder' ])
  const [archivedActivities, setArchivedActivities] = useState(null)
  const [view, toggleView] = useState('active')

  const getArchived = async () =>{
    const data = await getFromApi(`class/${classData.classId}?archived=true`)
    setArchivedActivities(data === 'initial' ? [] : data)
    setMapItems(data === 'initial' ? [] : data)
  }

  const handleDeleteActivity = ( activityId, activityIndex ) => {
    const { activities } = classData
    const newArray = [
      ...activities.slice(0, activityIndex),
      ...activities.slice(activityIndex+1)
    ]
    setClassData({...classData, activities: newArray})
    setMapItems([...newArray, 'placeholder'])
    sendToApiReturn(`activity/${activityId}/delete`)
  }

  const handleArchive = (activity, toggleDropdown, idx, archived) => {

    toggleDropdown('remove')

    if(!archived){
      setMapItems([...mapItems.slice(0, idx), ...mapItems.slice(idx+1)])
      setClassData({...classData, activities:[...classData.activities.slice(0,idx), ...classData.activities.slice(idx+1)]})
      setArchivedActivities( archivedActivities ? [...archivedActivities, activity] : [activity])
    } 
    else {
      setMapItems([...mapItems.slice(0, idx), ...mapItems.slice(idx+1)])
      setArchivedActivities([...archivedActivities.slice(0,idx), ...archivedActivities.slice(idx+1)])
      setClassData({...classData, activities:[...classData.activities, activity]})
    }
    
    sendToApiReturn(`activity/${activity.activityId}/archive`)

  }

  useEffect(()=>{
    if(view==='active'){
      setMapItems(null)
      //include 'placeholder' in the array to create 'new activity' button when mapping active activities
      setMapItems([...classData.activities.filter(i => (!i.activityName || normalize(i.activityName).includes(normalize(filterValue)))), 'placeholder'])

    } else if (view==='archived'){
      setMapItems(null)
      if(!archivedActivities) getArchived()
      else setMapItems( (archivedActivities.length&&archivedActivities!=='initial') ? [...archivedActivities?.filter(i => (!i.activityName || normalize(i.activityName).includes(normalize(filterValue))))] : [])
    }
  },[ filterValue, classData, view ])

  useEffect(()=>{setFilterValue('')},[view])

  return (
    <div className={`${styles.container} ${styles[view]}`}>

      {/* {
        !synod &&
          <div className={`${styles.radios}`}>
            <div onClick={() => toggleView('active')} className={`${styles.radioDiv} ${view === "active" ? styles.noClick : styles.grey}`}>
              <p>Active</p>
            </div>
            <div onClick={() => toggleView('archived')} className={`${styles.radioDiv} ${view === "archived" ? styles.noClick : styles.grey}`}>
              <p>Archived</p>
            </div>
            <div className={`${styles.line} ${styles[view]}`} />
          </div>
      } */}

      { 

         view === 'active' && !classData.activities.length ? 
            //empty active view
            <div className={styles.noActivities}>
              <img className={styles.face} src={face} alt="face"/>
              {/* <h5 className={styles.main}>There are currently no activities for this group</h5> */}
              <h3 className={styles.main}>Let's create your first activity</h3>
              {/* <img className={styles.arrow} src={arrow} alt="arrow" />
              <img className={styles.arrowRes} src={arrowRes} alt="arrow" /> */}
              <div className={styles.btnDiv}>
                <div onClick={()=>toggleNewActivityVis()} 
                  className={styles.newActivityDiv}>
                  <div className={styles.plus} />
                  <h5>Create Activity</h5>
                </div>
              </div>
            </div>

          : !mapItems ?
              //loading items
              <div className={styles.loaderDiv}>
                <LoadingOverlay contained transparant blueSpinner />
              </div>

          :  view === 'active' && (filterValue !== "" && mapItems.length===1) ?
              //no active serch results (length===1 due to placeholder)
              <h5 className={styles.noActivitiesText}>No activities matching search</h5>
              
          :  view === 'archived' && (filterValue !== "" && !mapItems?.length) ?
              //no archived search results
              <>
                <h5 className={styles.noArchive} >No archived activities matching search</h5>
                <p onClick={() => toggleView('active')} className={`${styles.goBack}`}>{`< back to activities`}</p>
              </>

          : view === 'archived' && !archivedActivities?.length ?
              //empty archive view

              <>
                <h5 className={styles.noArchive} >No archived activities</h5>
                <p onClick={() => toggleView('active')} className={`${styles.goBack}`}>{`< back to activities`}</p>
              </>
              
          :

              <>
                  <div className={`${styles.listTopBar}`}>
                    {
                       view === 'active' ? 

                        <>
                          <div onClick={()=>toggleNewActivityVis()} className={`${styles.newBtn}`}>
                            <div className={`${styles.icon}`} />
                            <p>Create activity</p>
                          </div>
                          <div onClick={() => toggleView('archived')} className={`${styles.archiveBtn}`}>
                            <div className={`${styles.tooltip}`}>View archive</div>
                          </div>
                        
                        </>

                      :

                        <>
                          <div onClick={() => toggleView('active')} className={`${styles.back}`} />
                          <h5>Archived activities</h5>
                        </>

                    }
                  </div>
                  {
                      mapItems?.map((activity, idx) => 
                        activity !== 'placeholder' &&
                          <ClassActivityBlock
                            synod={synod}
                            setListItem={setListItem}
                            archived={view==='archived'}
                            modalOverlay={modalOverlay}
                            key={idx}
                            idx={idx}
                            classData={classData}
                            setClassData={setClassData}
                            count={classData.activities.length}
                            activity={activity}
                            handleArchive={handleArchive}
                            handleDeleteActivity={handleDeleteActivity} />
                      // : 
                      //     <div onClick={()=>toggleNewActivityVis()} className={`${styles.newActivityDiv} ${styles.big}`}>
                      //       <div className={styles.plus} />
                      //       <h5>New Activity</h5>
                      //     </div>
                      )
             
                  }
              </>
          
              
          }

          { view === 'active' && 
            <div onClick={()=> showModal(modalOverlay, 
              {type:'newActivityMobile',
              fjUser,
              })} 
            className={`${styles.newActivityBtnRes} ${!classData.activities.length && styles.big}`}> <h5>New activity</h5> </div> }
    </div>
  )

}

export default ClassActivities;


