
// GENERAL ACTIONS //

export const toggleNavOpen = (bool) => {
  return{
    type:'TOGGLE_NAV_OPEN',
    payload:bool
  }
}

export const updateEnv = (input) => {
  return{
    type:'UPDATE_ENV',
    payload:input
  }
}

export const setView = (input) => {
  return{
    type:'SET_VIEW',
    payload:input
  }
}
export const setQueryItem = (input) => {
  return{
    type:'SET_QUERY_ITEM',
    payload:input
  }
}

export const updateSimplified = (input) => {
  return{
    type:'UPDATE_SIMPLIFIED',
    payload:input
  }
}


////// BACKEND INTEGRATED (teachers) //////

export const incomingMessage = ( message ) => {
  return{
    type: 'INCOMING_MESSAGE',
    payload: message
  }
}
export const incrementUnread = ( ) => {
  return{
    type: 'UNREAD_MESSAGE'
  }
}

export const setUnread = (count) => {
  return{
    type: 'SET_UNREAD',
    payload:count
  }
}

export const fetchClass = (input) => {
  return{
    type:'FETCH_CLASS',
    payload:input
  }
}

export const addActivityData = (input) => {
  return{
    type:'ADD_ACTIVITY_DATA',
    payload:input
  }
}

export const loadDashData = (data) => {
  return{
    type:'LOAD_DASH_DATA',
    payload:data
  }
}

export const updateDashData = (key, payload) => {
  // console.log('updateDashData')
  // console.log(key)
  // console.log(payload)
  return{
    type:'UPDATE_DASH_DATA',
    key:key,
    payload:payload
  }
}

export const updateDashClassName = (classIndex, payload) => {
  return{
    type:'UPDATE_DASH_CLASSNAME',
    classIndex:classIndex,
    payload:payload
  }
}

export const loadClasses = (input) => {
  return{
    type:'TEACHER_CLASSES',
    payload:input
  }
}
export const updateClassKey = (key, value, classId) => {
  // console.log('updateClassKey')
  // console.log(key)
  // console.log(value)
  return{
    type:'UPDATE_CLASS_KEY',
    key:key,
    value:value,
    classId:classId
  }
}

export const createNewClass = (input) => {
  return{
    type:'CREATE_NEW_CLASS',
    className:input,
  }
}

export const duplicateClass = (classIndex) => {
    return{
      type:'DUPLICATE_CLASS',
      classIndex:classIndex
    }
}

export const archiveClass = ( classIndex ) => {
  return{
    type:'ARCHIVE_CLASS',
    classIndex:classIndex
  }
}
export const unarchiveClass = ( classIndex ) => {
  return{
    type:'UNARCHIVE_CLASS',
    classIndex:classIndex
  }
}
export const deleteClass = ( classIndex , location) => {
  return{
    type:'DELETE_CLASS',
    classIndex:classIndex,
    location:location
  }
}
export const archiveActivity = ( activityId, classId ) => {
  return{
    type:'ARCHIVE_ACTIVITY',
    activityId:activityId,
    classId:classId
  }
}

export const unarchiveActivity = (activity) => {
  
  return{
    type:'UNARCHIVE_ACTIVITY',
    activity:activity
  }
}

export const deleteActivity = (source, activityIndex) => {
  return{
    type:'DELETE_ACTIVITY',
    source:source,
    activityIndex: activityIndex
  }
}

export const deleteActivityDash = (classIndex, activityIndex) => {
  
  return{
    type:'DELETE_ACTIVITY_DASH',
    activityIndex: activityIndex,
    classIndex: classIndex
  }
}

export const updateActivityKey = (key, value) => {
  // console.log(key)
  // console.log(value)
  return{
    type:'UPDATE_ACTIVITY_KEY',
    key: key,
    value: value
  }  
}

export const updateFetchedActivity = (activityId, key, value) => {
  return{
    type:'UPDATE_FETCHED_ACTIVITY',
    activityId:activityId,
    key:key,
    value:value
  }
}

export const clearDisplayData = () => {
  return{
    type:'CLEAR_DISPLAY_DATA',
  }
}

export const updateSectionKey = (sectionIndex, key, value) => {
  // console.log(value)
  return{
    type:'UPDATE_SECTION_KEY',
    sectionIndex: sectionIndex,
    key: key,
    value: value
  }  
}

export const updateClassFolder = ( folderKey, value ) => {
  
  return{
    type:'UPDATE_CLASS_FOLDER',
    folderKey:folderKey,
    value:value
  }
}
export const addToFolder = (folderKey, newAct) => {
  
  return{
    type:'ADD_TO_FOLDER',
    folderKey:folderKey,
    newAct:newAct
  }
}
export const removeFromFolder = (folderKey, index) => {
  
  return{
    type:'REMOVE_FROM_FOLDER',
    folderKey:folderKey,
    index:index
  }
}

export const addNewSection = (sectionObj) => {
  return{
    type:'ADD_NEW_SECTION',
    payload: sectionObj
  }
}

export const addWordBank = (sectionIndex, value) => {
  // console.log('addWordBank')
  // console.log(sectionIndex)
  // console.log(value)
  return{
    type:'ADD_WORD',
    sectionIndex: sectionIndex,
    value: value
  }  
}
export const removeWordBank = (sectionIndex, wordIndex) => {
  return{
    type:'REMOVE_WORD',
    sectionIndex: sectionIndex,
    wordIndex: wordIndex
  }  
}

///////////////////////////////

export const initiateUser = (input) => {
  return{
    type: 'INITIATE_USER',
    payload:input
  }
}

// ADMIN ACTIONS //
export const filterStudents = (input) => {
  return{
    type:'FILTER_STUDENTS',
    payload:input
  }
}
export const filterTeachers = (input) => {
  return{
    type:'FILTER_TEACHERS',
    payload:input
  }
}


// SETTINGS ACTIONS //
export const activeClass = (input) => {
  return{
    type:'ACTIVE_CLASS',
    payload:input
  }
}
export const currentActivity = (input) => {
  return{
    type:'CURRENT_ACTIVITY',
    payload:input
  }
}

export const setRadVal = (input) => {
  return{
    type:'SET_RADVAL',
    payload:input
  }
}
export const setSelectedSection = (input) => {
  return{
    type:'SWITCH_SELECTED_SECTION',
    payload:input
  }
}
export const updateKey = (key, input) => {
  return{
    type:'UPDATE_KEY',
    payload:input,
    key: key
  }
}

export const updateActivitySection = (sectionIndex, input) => {
  // console.log(sectionIndex);
  // console.log(input);
  return{
    type:'UPDATE_ACTIVITY_SECTION',
    sectionIndex: sectionIndex,
    payload:input
  }
}

export const updateActivity = (key, input) => {
  return{
    type:'UPDATE_ACTIVITY',
    key: key,
    input: input
  }  
}

export const messageReceived = () => {

  return{ type:'NEW_MESSAGE' }
  
}

export const clearMessages = () => {

  return{ type:'MODAL_OPENED' }

}

export const removeSection = (classIndex, activityIndex, sectionIndex) => {
  return{
    type:'REMOVE_SECTION',
    classIndex:classIndex,
    activityIndex:activityIndex,
    sectionIndex:sectionIndex
  }
}

export const addNewActivity = ( activityObj) => {
  return{
    type:'ADD_NEW_ACTIVITY',
    payload: activityObj
  }
}

export const updateClassStudentKey = (studentIndex, key, value) => {
  return({
    type:'UPDATE_CLASS_STUDENT_KEY',
    studentIndex:studentIndex,
    key:key,
    value:value
  })

}