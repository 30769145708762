import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { getCsv } from 'API/TeacherCallFunctions/csvGen';

import styles from './Manage.module.scss';
import TableRow from './TableRow';
import { debounce } from 'helpers/debounce';
import { getFromApi } from 'API/generalCalls';
import LoadingOverlay from 'components/StructuralComps/LoadingOverlay/LoadingOverlay';
import AdminFilters from './AdminFilters';


const Manage = ({modalOverlay}) => {

  const [ textInput, setTextInput ] = useState("")
  const [ orderBy, toggleOrderBy ] = useState({key:"userName", asc:true})
  const [ activeFilter, setActiveFilter ] = useState({type:"", value:""})
  const [originalData, setOriginalData] = useState(null)
  const [ mapItems, setMapItems ] = useState(null)
  const [ activeTab, setActiveTab ] = useState('teachers')
  const [ teacherData, setTeacherData ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ studentData, setStudentData ] = useState(null)
  const isAdmin = localStorage.getItem('a')


const columns = activeTab == "teachers" ?
  [
    {
      text:'Name',
      key:'userName'
    }, 
    {
      text:'Institution',
      key:'institution'
    }, 
    {
      text:'Last login',
      key:'lastLogin'
    }, 
    {
      text:'Sign up',
      key:'signUp'
    }, 
    {
      text:'Projects',
      key:'projects'
    }, 
    {
      text:'Students',
      key:'students'
    }, 
    {
      text: "Workbooks",
      key: "workbooks"
    },
    {
      text: "words",
      key: "words"
    },
    {
      text:'City/State',
      key:'country'
    }, 
    {
      text:'Subscription',
      key:'subscription'
    }, 
  ]
:
  [

    {
      text:'Name',
      key:'userName'
    }, 
    {
      text:'Teacher',
      key:'teacherEmail'
    }, 
    {
      text:'Last login',
      key:'lastLogin'
    }, 
    {
      text:'Sign up',
      key:'signUp'
    }, 
    {
      text: "Entries",
      key: 'entriesCount'
    },
    {
      text: "Words",
      key: 'wordsCount'
    },
    {
      text: "Country",
      key: 'country'
    },
    {
      text:'',
      key:''
    }
  ]


  const constructApiUrl = (tab, asc, key, text, filter) => {
    const base = `reports/${tab}?asc=${asc}&filter=${key}`;
    const searchQuery = `${text!==""?`&search=${text}`:""}`;
    const filterQuery = `${filter.type!==""?`&${filter.type}=${filter.value}`:""}`;
    const url =  `${base}${filterQuery}${searchQuery}`
    console.log(url)
    return url;
  }

  const updateMapItems = async (url) => {
    // setLoading(true)
    setMapItems(await getFromApi(url))
    setLoading(false)
  }

  // const handleFilterToggle = async (key) => {
  //   const url = constructApiUrl(activeTab, !orderBy.asc, key, textInput, activeFilter)
  //   toggleOrderBy({key, asc: orderBy.key == key ? !orderBy.asc : true })
  //   updateMapItems(url)
  // }
    const handleFilterToggle = (key) => {
      toggleOrderBy({ key, asc: orderBy.key === key ? !orderBy.asc : true });
      const validFilter = activeFilter || { type: '', value: '' };

  // Filter the original data based on the active filter and text input
    if (validFilter && originalData) {
      
      const filteredData = applyFilters(originalData, validFilter);
      setMapItems(filteredData);
    }

  };
  const applyFilters = (data, filter) => {
    const filteredData = data.filter((item) => {
      const filterCondition =
        filter.type === "" || item[filter.type] === filter.value;
      const textCondition =
        textInput === "" || item.userName.toLowerCase().includes(textInput.toLowerCase());
      return filterCondition && textCondition;
    });
    return filteredData;
  };

  const fetchData = async (report) => {
    const res = await getFromApi(`reports/${report}`)
    setMapItems(res)
    if(report == "students"){ setStudentData(res) } 
    else { setTeacherData(res) }
  }

  const debounceInput = useCallback(debounce(async ( input, tab, orderBy, filter ) => {
    const url = constructApiUrl(tab, orderBy.asc, orderBy.key, input, filter)
    updateMapItems(url)
  }, 300), [])

  useEffect(()=>{

      if (textInput === "") {
        setMapItems(originalData); 
      } else {
           debounceInput(textInput, activeTab, orderBy, activeFilter)

      }
    }, [textInput]);
    


  useEffect(()=>{

    toggleOrderBy({key:"lastLogin", asc:false})
    setActiveFilter({type:"",value:""})
    setTextInput("")
    setMapItems(null)

    if(activeTab=="students"){
      if(!studentData){
        fetchData("students")
      } else {
        setMapItems(studentData)
      }
      
    } else {
      setMapItems(teacherData)
      
    }

  },[activeTab])


  useEffect(()=>{fetchData("teachers")},[])

  return isAdmin != "true" ? <Redirect to={"/teacher/classes"}/> : 

    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.tabsBar}>
        </div>
        <div className={styles.inner}>
          <div className={styles.filtersBar}>
            <div className={styles.left}>
              <AdminFilters
                textInput={textInput}
                setTextInput={setTextInput} 
                activeTab={activeTab} 
                orderBy={orderBy}
                activeFilter={activeFilter} 
                setActiveFilter={setActiveFilter} 
                setMapItems={setMapItems} />
            </div>
            <div onChange={e => setActiveTab(e.target.value)} className={styles.tabs}>
              <label>
                <input defaultChecked value={"teachers"} type="radio" name="activeTab" />
                <div className={styles.tabDiv}>
                  Teachers
                  <div className={styles.colorTab} />
                </div>
              </label>
              <label>
                <input value={"students"} type="radio" name="activeTab" />
                <div className={styles.tabDiv}>
                  Students
                  <div className={styles.colorTab} />
                </div>
              </label>
            </div>
          </div>
          <div onClick={() => { getCsv(`reports/teachers_csv`, "all_teachers") }}  className={styles.downloadCSVbtn}><p>Teachers CSV</p></div>

          <div className={styles.tableHeader}>
            {
              columns.map((item, idx) => 
                <div className={` ${styles.column} ${styles[activeTab]} ${[0,1].indexOf(idx) != -1 && styles.wide} `} key={idx} >
                  <div onClick={()=>handleFilterToggle(item.key)} className={`${styles.filterToggle} ${orderBy.key==item.key && styles.selected} ${orderBy.asc && styles.ascending}`}>
                    {item.text}
                  </div>
                </div>
              )
            }
          </div>
          <div className={styles.rows}>
            { 

              !mapItems ?  <div className={styles.loaderDiv}><LoadingOverlay contained/></div> 

            :

              !mapItems.length ?

                <div className={`${styles.row} ${styles.noResults}`}>
                  {textInput !== "" ? "no results matching your criteria" : `No ${activeTab} found`}
                </div>

            :
              
              mapItems.sort((a, b) => 

                orderBy.asc ?
                  //sort map items ascending or descending based on orderBy.asc
                  a[orderBy.key] > b[orderBy.key] ? 1 : -1
                :
                  b[orderBy.key] > a[orderBy.key] ? 1 : -1

              ).map(user => 
                <TableRow activeTab={activeTab} user={user} orderBy={orderBy} mapItems={mapItems} modalOverlay={modalOverlay} />
              )
            }
             <br />
              <br />
              <br />
          </div>
        </div>
      </div>

    </div>

  

}

export default Manage;


// const updateMapItems = async (url) => {
//   setLoading(true)
//   setMapItems(await getFromApi(url))
//   setLoading(false)
// }

// const handleFilterToggle = (key) => {
//   toggleOrderBy({ key, asc: orderBy.key === key ? !orderBy.asc : true });

//   if (activeFilter && originalData) {
//     const filteredData = applyFilters(originalData, activeFilter, textInput);
//     setMapItems(filteredData);
//   }
// };

// const applyFilters = (data, filter, text) => {
//   return data.filter((item) => {
//     const filterCondition = filter.type === "" || item[filter.type] === filter.value;
//     const textCondition = text === "" || item.userName.toLowerCase().includes(text.toLowerCase()); // Adjust the property for text filtering

//     return filterCondition && textCondition;
//   });
// };

// const fetchData = async (report) => {
//   const res = await getFromApi(`reports/${report}`)
//   setMapItems(res)
//   if(report == "students"){ setStudentData(res) } 
//   else { setTeacherData(res) }
// }

// const debounceInput = useCallback(debounce(async ( input, tab, orderBy, filter ) => {
//   const url = constructApiUrl(tab, orderBy.asc, orderBy.key, input, filter)
//   updateMapItems(url)
// }, 300), [])

// useEffect(()=>{

//   if(textInput==""){
//     setMapItems(activeTab == "teachers" ? teacherData : studentData)
//     setOriginalData(activeTab == "teachers" ?  teacherData : studentData)

//   } else {
//     debounceInput(textInput, activeTab, orderBy, activeFilter)

//   }
  
// },[textInput])


// useEffect(()=>{

//   toggleOrderBy({key:"lastLogin", asc:false})
//   setActiveFilter({type:"",value:""})
//   setTextInput("")
//   setMapItems(null)

//   if(activeTab=="students"){
//     if(!studentData){
//       fetchData("students")
//     } else {
//       setMapItems(studentData)
//     }
    
//   } else {
//     setMapItems(teacherData)
    
//   }

// },[activeTab])


// useEffect(()=>{fetchData("teachers")},[])
