

import { getFromApi, sendToApi } from 'API/parentCalls';
import React, { useRef, useState, useEffect } from 'react';

import styles from './ConnectYourChildModal.module.scss';

const InitState = ({ setContState, contRef, apiRes, setApiRes, setChildCode }) => {

  const codeRef = useRef();
  const btnRef = useRef();
  const [ error, setError ] = useState({vis:false, text:''})
  const [ btnState, setBtnState ] = useState('init')

  const handleNext = async () => {

    if(codeRef.current.value===""){
      setError({vis:true, text:'Please enter a code below'})
      codeRef.current.classList.add('shake')
      setTimeout(()=>{codeRef.current.classList.remove('shake')},500)
      
    } else {
      setBtnState('loading')
      setChildCode(codeRef.current.value)
      setApiRes( await getFromApi(`search?code=${codeRef.current.value}`))

    }
  }

  const handleFind = () => {
    setContState('find')
    contRef.current.classList.remove(styles.init)
    contRef.current.classList.add(styles.find);
  }
  
  useEffect(()=>{

    setBtnState('init')
    if(apiRes.message){
      setError({vis:true, text:'Sorry, no student has been found with this code'})

    } else if (apiRes.studentName){
      setContState('yourChild')
      contRef.current.classList.remove(styles.init)
      contRef.current.classList.add(styles.yourChild);

    }

  },[apiRes])

  return (
    <>
      <h1>Connect your child</h1>
      <div className={styles.main}>

        <h3>Enter your child's code</h3>

        <p>Your child's teacher may have provided you with a code that looks like this: <strong>11AB11</strong> </p>

        <p className={styles.error}>{error.vis ? error.text : ''}</p>

        <input onChange={()=>setError({...error, vis:false})} placeholder="11AB11" type="text" name="codeInput" ref={codeRef}/>

        {btnState!=='loading' ? 
          <div className={styles.nextBtn} onClick={handleNext}>Next</div>
        : 
          <div  className={styles.loadingBtn} ></div>
        }

        <div className={styles.links}>
          <p onClick={()=>{setContState('create'); setError({...error, vis:false})}} className={styles.blue}>My child doesn't have an Escribli account</p>
          <p onClick={handleFind} className={styles.blue}>I don't know my child's Escribli code</p>
        </div>
      </div>
    </>
  )
}

export default InitState;