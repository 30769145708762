
  export const formatDataInput = (e, variant)  => {

    const regex = /[0-9]|\./;

    if(variant==='card' && e.key!=='Backspace' && e.key!=='Tab'){

      const x = e.target;
      if(x.value.length === 4){x.value=x.value+' '}
      if(x.value.length === 9){x.value=x.value+' '}
      if(x.value.length === 14){x.value=x.value+' '}

    } 

    if(variant==='month' && e.key!=='Backspace' && e.key!=='Tab' ){
      const x = e.target;
      if(x.value.length === 2){x.value=x.value+'/'}
    }
    

    if( !regex.test(e.key) && e.key!=='Backspace' && e.key!=='Tab' ) {
      e.returnValue = false;
      if(e.preventDefault) e.preventDefault();
    }
  }