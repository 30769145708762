import React from 'react';

import styles from './TeacherCredit.module.scss';
import query from '../../../../assets/credit-query.svg'

const CreditBreakdown = () => {

  return (
    <div className={styles.breakdown}>

      <div className={`${styles.breakdownSection} ${styles.getCredits}`}>
        <h3 className={styles.breakdownSubhead}>GET CREDITS</h3>
        <h1>How do you earn credits?</h1>
        <div className={styles.row}>
          <span>
            <p>Each referral (free trial)</p> 
            <div className={styles.query}>  
              <div className={styles.tooltip}>Refer other teachers to an Escribli 30-day free trial by sharing your unique Refer & Earn link and receive 3 credits.</div>
            </div> 
          </span>
          <p><span className={styles.creditNumber}>3</span> credits</p>
        </div>  
        <div className={styles.row}>
          <span>
            <p>Each completed referral</p>
            <div className={styles.query}>  
              <div className={styles.tooltip}>When your referrals subscribe to a Complete subscription you earn 15 credits per referral.</div>
            </div> 
          </span>
          <p><span className={styles.creditNumber}>15</span> credits</p>
        </div>  
        <div className={styles.row}>
          <span>
            <p>Each student book purchased</p>
            <div className={styles.query}>  
              <div className={styles.tooltip}>Every book purchased by the families of your students will result in 4 credits.</div>
            </div> 
          </span>
          <p><span className={styles.creditNumber}>4</span> credits</p>
        </div>  
        <div className={styles.row}>
          <span>
            <p>Each student entry approved</p>
            <div className={styles.query}>  
              <div className={styles.tooltip}>You'll receive 0.2 credits for each student writing entry you approve.</div>
            </div> 
          </span>
          <p><span className={styles.creditNumber}>0.2</span> credits</p>
        </div>  
      </div>

      <div className={`${styles.breakdownSection} ${styles.spendCredits}`}>
        <h3 className={styles.breakdownSubhead}>SPEND CREDITS</h3>
        <h1>Where can you spend your credits?</h1>
        <div className={styles.row}>
          <p>Monthly subscription</p>
          <p>15 credits/month</p>
        </div>  
        <div className={styles.row}>
          <p>Class book purchase</p>
          <p>13 credits</p>
        </div> 

        <i>* shipping not included</i>
      </div>

    </div>
  )

}

export default CreditBreakdown;