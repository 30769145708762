import React, { useState } from 'react';

import styles from './EntryCommentInput.module.scss';
import comment from 'assets/comment_blue.svg'
import comment_green from 'assets/comment_green.svg'
import chevron from 'assets/chevron_solid_white.svg'
import chevron_green from 'assets/chevron_solid_green.svg'
import send from 'assets/send_white.svg'
import { useRef } from 'react';
import { useEffect } from 'react';
import { sendToApiReturn } from 'API/teacherCalls';
import { arrayObjectHasValue } from 'helpers/arrayObjectHasValue';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import { format } from 'date-fns/esm';

const EntryCommentInput = ({ entryData: { comments, studentId, submitted, approved }, sectionIndex, activityId }) => {

  const canRequestRevision = submitted && !approved
  const canBeFinalFeedback = approved
  const showCheckbox = canBeFinalFeedback || canRequestRevision

  const [ isOpen, setIsOpen ] = useState(false)
  const [ commentType, setType ] = useState(canBeFinalFeedback ? 3 : 1)
  const [ localComments, setLocalComments ] = useState([...comments])
  const [ isBlocked, setBlocked ] = useState(false)
  const [ activeBtn, toggleActiveBtn ] = useState(false)
  const inputRef = useRef()
  const editFinalRef = useRef()
  const contRef = useRef()


  useEffect(()=>{
    if(inputRef.current){
      inputRef.current.setAttribute("style", "height:" + (inputRef.current.scrollHeight) + "px;overflow-y:hidden;");
      inputRef.current.addEventListener("input", OnInput, false);
    
    
      function OnInput() {
        this.style.height = "auto";
        this.style.height = (this.scrollHeight) + "px";
      }

    }
  },[inputRef.current])


  useEffect(()=>{ setBlocked(arrayObjectHasValue(localComments, 'status', 'final')) },[localComments]) 

  useEffect(()=>{
    if(isOpen) setTimeout(()=>inputRef.current.focus(),300) 
  },[isOpen])

  const handleCheckbox = () => {
    
    if([2,3].indexOf(commentType) !== -1) setType(1)
    if(commentType === 1 && canBeFinalFeedback) setType(3)
    if(commentType === 1 && canRequestRevision) setType(2)
  }


  const handleSendComment = (editFinal) => {
    
    toggleActiveBtn(false)
    if(!editFinal && inputRef.current.value === "") return false
    if(editFinal && editFinalRef && editFinalRef.current.value === "") return false

    const payload = { createdOn: new Date(), status: commentType, text:editFinal ? editFinalRef.current.value : inputRef.current.value  }

    if(!editFinal) {
      setLocalComments([...localComments, {...payload, status: commentType === 3 ? "final" : commentType === 2 ? "revision" : "comment"}])
      inputRef.current.value=""
    }

    sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/comment/${studentId}`, payload)
    console.log('send message')
  }

  const handleKeyUp = (e) => {
    toggleActiveBtn(e.target.value!=="")
    if(e.key === 'Enter') handleSendComment()
  }

  useOutsideClick(contRef, () => setIsOpen(false))

  const hasStudentComment = comments.some(i => i.isStudent)

  return (

    <div ref={contRef} className={styles.container}>

      <div onClick={()=> setIsOpen(!isOpen)} 
        className={`${styles.commentBtn} ${isOpen && styles.open} ${canBeFinalFeedback && styles.green}`}>
        {!isOpen && <img src={!canBeFinalFeedback ? comment : comment_green} alt="comment icon" />}
        {(!isOpen && hasStudentComment) && <div className={`${styles.dot}`} />}
        <p>{isOpen  ? "Close" : !canBeFinalFeedback ? "Comment" : "Final Feedback" }</p>
        {isOpen && <img className={styles.chevron} src={!canBeFinalFeedback ? chevron : chevron_green} alt="close icon" />}
      </div>

      <div className={`${styles.dynamicBlock} ${isOpen && styles.open}`}>
        <div className={styles.commentsDiv}>

          {
            localComments.map((item, idx) => 
              <div key={idx} className={`${styles.commentContainer} ${item.isStudent && styles.studentComment}`}>
                  <div className={styles.dateTimeDiv}>
                    <p className={styles.dateTime}>{format(new Date(item.createdOn), 'do MMMM yyyy')}</p>
                    <p className={styles.dateTime}>{format(new Date(item.createdOn), 'hh:mm aaa')}</p>
                  </div>
                {
                  item.status=="final" ?
                  <>
                    <textarea ref={editFinalRef} className={styles.finalInput} defaultValue={item.text} type="text" name="" id="" />
                    <div onClick={()=>handleSendComment(true)} className={`${styles.sendBtn} ${inputRef.current?.value!=="" && styles.active}`}>
                      <p>Send</p>
                      <img src={send} alt="send" />
                    </div>
                  </>
                  :
                    <h5 className={styles[item.status]}>{item.text}</h5>
                }
              </div>
            )
          }

        </div>
        
        <div className={`${styles.inputDiv} ${isBlocked && styles.blocked} ${activeBtn && styles.noMargin}`}>
          <div className={`
            ${styles.inputContainer} 
            ${canBeFinalFeedback && styles.final} 
            ${canRequestRevision && styles.revision}`
            }>
            <textarea autoresize onKeyUp={handleKeyUp} ref={inputRef} type="text" />
            {
              showCheckbox &&
                <div className={styles.checkboxDiv}>
                  <label>
                    <input checked={[2,3].indexOf(commentType) !== -1} type="checkbox" name="check"/>
                    <div onClick={handleCheckbox} className={styles.checkboxContainer}>
                      <div className={styles.checkbox} />
                    </div>
                    <h5>{canRequestRevision ? 'Revision request' : 'Final feedback'}</h5>
                  </label>
                </div>
            }
          </div>
          <div onClick={()=>handleSendComment(false)} className={`${styles.sendBtn} ${activeBtn && styles.active}`}>
            <p>Send</p>
            <img src={send} alt="send" />
          </div>
          
        </div>
      </div>

    </div>
  )

}

export default EntryCommentInput;


// comment: 1
// revision: 2
// final: 3