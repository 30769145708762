import React, {useEffect, useState} from 'react';

import styles from './Resources.module.scss';

import { useParams } from 'react-router-dom';


import { showModal } from 'helpers/uiFunctions';
import { getFromApiRandom } from 'API/teacherCalls';

import { resourceItemFilter } from 'helpers/filterFunctions';
import { returnPlatformStyle } from 'helpers/altPlatformFunctions';


import truth from './truth.svg'
import truth1 from './truth1.svg'
import RandomAccessItem from './RandomAccessItem';
import RandomItem from './RandomAccessItem';

const RandomAccess = ({modalOverlay}) => {


    
  return(
    <div className={styles.container}>
     <RandomItem />
    </div>
  )
}

export default RandomAccess;
