import React,{ useEffect, useRef, useState } from 'react';
import{ useSelector, useDispatch } from 'react-redux';

import styles from './BreakoutRoom.module.scss';
import { statusPill } from 'helpers/uiFunctions';
import { setQueryItem } from 'Redux/actions';
import { getFromApi } from 'API/teacherCalls';
import loader from 'assets/Spinner.svg';

const BreakoutRoom = ({closeBtn, item: { idOne, idTwo, idThree, roomName, students, section, activeSection, setActiveSection}, item}) => {

  const [ messages, setMessages ] = useState([])
  const dispatch= useDispatch();
  const msgRef = useRef();
  const chatRef = useRef();
  const chevRef = useRef();
  const activity = useSelector(state => state.activityData);
  const sectionIndex = useSelector(state => state.selectedSection);
  const [studentOne, setStudentOne] = useState(null)
  const [studentTwo, setStudentTwo] = useState(null)
  const [studentThree, setStudentThree] = useState(null)
  const regex = /<br>/gi;

  const handleChevron = () => {
    if(chatRef.current.classList.contains((styles.hide))){
      chatRef.current.classList.remove(styles.hide)
    }else{
      setTimeout(()=>{chatRef.current.classList.add(styles.hide)},400)
    }
    chatRef.current.classList.toggle(styles.closed)
    chevRef.current.classList.toggle(styles.closed)
    // setTimeout(()=>{chatRef.current.classList.toggle(styles.noBorder)},300)
  }

  useEffect(()=>{
    const getEntries = async () => {
      setStudentOne(await getFromApi(`activity/${activity.activityId}/section/${section.sectionIndex}/entry/${idOne}`)) 
      setStudentTwo(await getFromApi(`activity/${activity.activityId}/section/${section.sectionIndex}/entry/${idTwo}`)) 
      idThree && setStudentThree(await getFromApi(`activity/${activity.activityId}/section/${section.sectionIndex}/entry/${idThree}`)) 
    }

    getEntries()
  },[])


  return (
    <div className={styles.container}>

      <div onClick={()=>closeBtn.current.click()} className={styles.close} />

      { studentOne && studentTwo ?
        <>
          <div className={styles.left}>
            <div className={styles.activityTitle}>
              <h4>Activity name</h4>
              <h2>{activity.activityName}</h2>
            </div>

            <div className={styles.groupBlock}>
              <h2>Group {roomName}</h2>
              <p>{studentOne.studentName}</p>
              <p>{studentTwo.studentName}</p>
              {studentThree ? <p>{studentThree.studentName}</p> : null}

              <div className={styles.boomers}>
                <div className={styles.boomer}>
                  <img src={studentOne.studentBoomerUrl} alt='boomer' />
                </div>
                <div className={styles.boomer}>
                  <img src={studentTwo.studentBoomerUrl} alt='boomer' />
                </div>
                {studentThree ?
                  <div className={styles.boomer}>
                    <img src={studentThree.studentBoomerUrl} alt='boomer' />
                  </div>
                  : null}
              </div>
            </div>
            {/* <h4>{studentTwo.studentName}</h4> */}

            <div className={styles.chat}>
              <div className={styles.titleBar}>
                <div className={styles.title}>
                  <div className={styles.chatIcon}></div>
                  <h2>Chat</h2>
                </div>
                <div className={`${styles.chevron} ${styles.closed}`} ref={chevRef} onClick={handleChevron}></div>
              </div>

              <div className={`${styles.chatBox} ${styles.closed} ${styles.hide}`} ref={chatRef}>

                <div className={styles.placeholder}>
                  <h5>Chat coming soon</h5>
                </div>

                <div className={styles.messages}>
                  {messages.map(msg => {
                    return (
                      <div className={styles.message}>
                        {msg.text}
                      </div>
                    )
                  })}
                </div>

                <div className={styles.messageInput}>
                  <input
                    ref={msgRef}
                    onKeyPress={(e) => { if (e.key === 'Enter' && e.target.value !== '') { setMessages([...messages, { text: e.target.value }]); e.target.value = '' } }}
                    type="text"
                    placeholder="Type your message here"
                  />
                  <div onClick={() => { setMessages([...messages, { text: msgRef.current.value }]); msgRef.current.value = '' }} className={styles.sendBtn}>Send</div>
                </div>
              </div>
            </div>

          </div>

          <div className={styles.main}>
            <div className={styles.top}>

              <div className={styles.topLeft}>
                <div className={styles.roomTitle}>
                  <h1>Breakout Room </h1>
                  <div className={styles.dot}>{roomName}</div>
                </div>
                <div className={styles.status}></div>
              </div>

              <div className={styles.topRight}>
                <h4>Section {parseInt(sectionIndex) + 1}</h4>
                <h1>{activity.sections[parseInt(sectionIndex)].sectionName}</h1>
              </div>
            </div>
            <div className={styles.bottom}>

              <div className={(studentThree ? `${styles.entry} ${styles.slim}` : styles.entry)}>
                <div className={styles.header}>
                  <h3>{studentOne.studentName}</h3>
                  {statusPill(studentOne.entryStatus)}
                </div>
                <textarea readonly className={styles.text} defaultValue={studentOne.entryText.replace(regex, '\n\n')} />
              </div>

              <div className={(studentThree ? `${styles.entry} ${styles.slim}` : styles.entry)}>
                <div className={styles.header}>
                  <h3>{studentTwo.studentName}</h3>
                  {statusPill(studentTwo.entryStatus)}
                </div>
                <textarea readonly className={styles.text} defaultValue={studentTwo.entryText.replace(regex, '\n\n')} />
              </div>

              {studentThree && studentThree.entryText ?
                <div className={`${styles.entry} ${styles.slim}`}>
                  <div className={styles.header}>
                    <h3>{studentThree.studentName}</h3>
                    {statusPill(studentThree.entryStatus)}
                  </div>
                  <textarea readonly className={styles.text} defaultValue={studentThree.entryText} />
                </div>
                : null}
            </div>
          </div>

          <div className={styles.backBtn}
            // onClick={()=>{dispatch(setQueryItem(prevItem))}}>Back to breakout rooms</div>
            onClick={() => {
              dispatch(setQueryItem({
                type: 'breakoutRooms',
                students: students,
                activity: item.activity,
                activeSection: section,
                setActiveSection: setActiveSection
              }))
            }}
          >Setup breakout rooms</div>
        </>

        :

            <div className={styles.loaderDiv}>
              <img src={loader} alt=""/>
            </div>
        
      }
    </div>
  )

}

export default BreakoutRoom;