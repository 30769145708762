import { sendToApiReturn } from 'API/teacherCalls';
import { showModal } from 'helpers/uiFunctions';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import paperclip from 'assets/paperclip.svg'

import styles from './OverviewTableRow.module.scss';

const OverviewTableRow = ({ modalOverlay, rowData, activeSection, rowData: { studentBoomerUrl, studentName, studentId, approved, voted, wordCount, finalGrade, entryStatus, submitted, studentFiles, hasStudentComments}, isApproveAll, setActiveSection, fjUser, mobile }) => {

  const [ localApproved, setApproved ] = useState(approved)
  const activityData = useSelector(state => state.activityData)

  console.log("rowdat", rowData)

  useEffect(()=>{setApproved(approved)},[rowData])

  const handleSlider = async () => {
    //if regular click, toggle localApproved boolean
    //if included in an 'approve all' action, only set to true, to avoid un-approving items
    setApproved(!isApproveAll ? !localApproved : true)

    //only make a call to backend if not part of an 'approve all' action
    if(!isApproveAll){
      const data = await sendToApiReturn(`activity/${activityData.activityId}/section/${activeSection.sectionIndex}/toggle_entry_approve/${studentId}`)
      setActiveSection(data)
    }

  }

  const gradeClass = 
   finalGrade>0&&finalGrade <2 ? 'one' :
   finalGrade>=2&&finalGrade <3 ? 'two' :
   finalGrade>=3&&finalGrade <4 ? 'three' :
   finalGrade===4 ? 'four' : null

  const statusClass = 
    entryStatus === 'approved' ? 'approved' :
    entryStatus === 'pending revision' ? 'pendingRes' :
    entryStatus === 'submitted' ? 'submitted' :
    entryStatus === 'started' ? 'started' :
    entryStatus === 'not started' ? 'notStarted' : null


  return (
    <div className={styles.container}>

      <div onClick={()=> showModal( modalOverlay,{ 
          type:'entryModal', 
          maxWords:activeSection.maxWords, 
          activeSection, 
          studentIndex:activeSection.entries.findIndex(i=>i.studentId===rowData.studentId), 
          activityData, 
          setActiveSection, 
          fjUser 
        } )} 
        className={styles.column}>
        <img src={studentBoomerUrl} alt="avatar" />
        <h5 className={styles.hideMobile}>{studentName}</h5>
        <h5 className={styles.res}>{ studentName ? `${studentName.split(' ')[0]} ${studentName.split(' ')[1].substring(0,1)}` : ""}</h5>
        {hasStudentComments &&  <div className={`${styles.commentIcon}`} />}
        <div className={styles.openDiv}>
          <p>Open</p>
          <div className={styles.openIcon}/>
        </div>
      </div>
      <div className={`${styles.column} ${fjUser && styles.wide}`}>
        <h5>{wordCount}</h5>
        { studentFiles && <img className={styles.clip} src={paperclip} alt="paperclip" /> }
      </div>
      <div className={`${styles.column} ${fjUser && styles.wide}`}>
        <div className={`${styles.statusDot} ${styles[statusClass]}`} ></div>
      </div>
      <div className={`${styles.column} ${fjUser && styles.wide}`}>
        <div 
          onClick = {handleSlider}
          id={`switch${studentId}`}
          className={`${styles.approvedSlider} ${!submitted && styles.disabled} ${localApproved && styles.approved}`}>
          <div className={styles.handle} />
          <p className={styles.yes}>Yes</p>
          <p className={styles.no}>NO</p>
        </div>
      </div>
      {
        !mobile  &&
        
        <>
          <div className={`${styles.column} ${fjUser && styles.wide}`}>
            <div className={`${styles.voted} ${voted && styles.yes}`} />
          </div>
          {/* {
            !fjUser && */}
              <div className={styles.column}>
                {
                    finalGrade === 0 ? <p className={styles.noGrade}>N/A</p>
                  :
                    <div className={`${styles.gradePill} ${styles[gradeClass]}`}><p>{finalGrade}</p></div>
                }
              </div>
          {/* } */}


        </>
      }

    </div>
  )

}

export default OverviewTableRow;