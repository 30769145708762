import React, { useRef, useState } from 'react';
import { WhatsappShareButton, TwitterShareButton, EmailShareButton, FacebookShareButton } from 'react-share'
;
import styles from './TeacherCredit.module.scss';

import icon1 from 'assets/refer-icon1.svg';
import icon2 from 'assets/refer-icon2.svg';
import icon4 from 'assets/refer-icon5.svg';
import icon5 from 'assets/refer-icon4.svg';

import icon_link from 'assets/icon-link.svg';

const CreditLinkBlock = ({ url }) => {

  const [ vis, setVis ] = useState({copyConf:false, tooltip:false})
  const linkRef = useRef()


  const copyToClip = () => { 
    setVis({copyConf:true, tooltip:false})  
    setTimeout(()=>{ setVis({copyConf:false, tooltip:false}) },2000)
    navigator.clipboard.writeText(linkRef.current.value)
  }

  return (

    <div className={styles.linkBlock}>
    <h3>Refer & Earn</h3>
    <p>Share this unique link with other teachers who may want to join and you'll earn credits!</p>
    <div className={styles.linkBox}>

      <input ref={linkRef} type="text" value={url} readOnly/>

      <div className={styles.linkBtn} 
        onClick={copyToClip}
        onMouseEnter={()=>{setVis({...vis, tooltip:true})}}
        onMouseLeave={()=>{setVis({...vis, tooltip:false})}}
        id="linkBtn"
        >
          
        {!vis.copyConf ? 
          <img src={icon_link} alt="copy link"/> 
        : 
          <p>copied</p>
        }
         {vis.tooltip ? <div className={styles.tooltip}> click to copy </div> : null}
      </div>
    </div>
    <div className={styles.pills}>
            <div className={styles.pill}>
            <FacebookShareButton 
              url={url}
              imageURL="https://i0.wp.com/reachinghighernh.org/wp-content/uploads/2018/07/competency-portfolio-learning.png?fit=460%2C300&ssl=1"
              quote={"I love using Escribli with my students. If you buy an Escribli plan with my unique link, you’ll get 10% off your first purchase!"}
            />
              <img src={icon1} alt="Facebook icon" />
            </div>
          <div  className={styles.pill}>
            <TwitterShareButton url={url} title={"Hey!\r\n\r\nI am using Escribli with my classroom and we are loving it so much that I needed to share it with you. Best thing?  If you purchase a plan with my unique link, you get 10% off your first purchase!\r\n\r\n Use my link to check it out: "} />
            <img src={icon2} alt="Twitter icon" />
          </div>
          {/* <div className={styles.pill}>
            <WhatsappShareButton url={url} title={"Hey!\r\n\r\nI am using Escribli with my classroom and we are loving it so much that I needed to share it with you. Best thing?  If you purchase a plan with my unique link, you get 10% off your first purchase!\r\n\r\n Use my link to check it out: "}  />
            <img src={icon4} alt="Whatsapp icon" />
          </div> */}
          <div className={styles.pill}>
            <WhatsappShareButton url={url} title={"Hey!\r\n\r\nI am using Escribli with my classroom and we are loving it so much that I needed to share it with you. Best thing?  If you purchase a plan with my unique link, you get 10% off your first purchase!\r\n\r\n Use my link to check it out: "}  />
            <img src={icon4} alt="Whatsapp icon" />
          </div>
          <div className={styles.pill}>
            {/* @todo update share image */}
            <EmailShareButton 
              media={"https://boomwriter.com/static/media/journal-illustration.6e1fdc28.png"}
            url={`${url}`} 
            subject={"I am loving Escribli, check it out for yourself and get 10% off 🙌"} body={"Hey,\r\n\r\nI am using Escribli with my classrooms, my students and I are loving it. If you purchase a plan with my unique link, you get 10% off your first purchase!\r\n\r\nUse my link to check it out: "} separator={" "}/>
            <img src={icon5} alt="Email icon" />
          </div>
        </div>
  </div>
  )

}

export default CreditLinkBlock;