import React,{useState, useEffect, useRef} from 'react';

import { Redirect, useHistory } from 'react-router-dom'
import styles from './JournalTemplateModal.module.scss';
import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import loader from 'assets/loader-sm2.svg'
import spinner from 'assets/Spinner.svg'
import { store } from 'storeConfig';
import { setQueryItem } from 'Redux/actions';
import { useUserType } from 'context/AltPlatformProvider';


const JournalTemplateModal = ({ closeBtn, item:{tempId, resource, free} }) => {
  
  const loaderRef = useRef();
  const contRef = useRef();
  const [ availableClasses, setAvailableClasses ] = useState([]);
  const [ selectedClasses, setSelectedClasses ] = useState([]);
  const [ vis, setVis ] = useState({error:false});
  const [ response, setResponse ] = useState('initial');
  const [selectedLabel, setSelectedLabel] = useState();
  const [ apiRes, setApiRes ] = useState(null);
  const history = useHistory();

  const { fjUser } = useUserType() 
 

  const callApi = async () => setApiRes(await getFromApi('plus_details'));

  useEffect(()=>{
    callApi();
  },[]);




  let checks = document.querySelectorAll(".check");


  const getClasses = async () => { 
    const data = await getFromApi('classes');
    !data.length ? store.dispatch(setQueryItem({type:'newClassOnboarding', variant:'journalTemplate', activityId:tempId})) : setAvailableClasses(data);
  }
  
  useEffect(()=>{getClasses()},[])

  useEffect(()=>{
    if(response==='Success!'){setTimeout(()=>{closeBtn.current.click()},1500)}

    // eslint-disable-next-line
  },[response])

  const selectiveCheck = () => {
    let checkedChecks = document.querySelectorAll(".check:checked");
    if(checks.length>3){
    if (checkedChecks.length > 1)
      return false;
    }
  }
  for (var i = 0; i < checks.length; i++) checks[i].onclick = selectiveCheck;

  const handleDirect = () => {
    history.push('/teacher/account/manage')
  }

  const handleOk = async () => {

    if(selectedLabel && !free && selectedLabel.studentsCount > apiRes.currentSub.availableSophiaCredits){
      handleDirect()
    } else {
      if(selectedClasses.length){
        loaderRef.current.classList.add(styles.show)
  
        if(availableClasses.length){
          setTimeout(async()=>{
            const data = await sendToApiReturn(`activity/${tempId}/duplicate`, {classId:selectedClasses})
            history.push(`/teacher/classes/${data.class_id}/${data.activity_id}/settings/0`)
            // const frontUrl = localStorage.getItem('frontUrl')
            // window.location=`${frontUrl}/teacher/classes/${data.class_id}/${data.activity_id}/settings/0`
          }
          ,1000)
          // closeBtn.current.click()
        }
  
      } else {
        contRef.current.classList.add(styles.shake);
        setTimeout(()=>{if(contRef.current){contRef.current.classList.remove(styles.shake)}},2500);
        setVis({...vis, error:true})
      }
    }


  }

    
  const handleCheckbox = (e) => {
    setSelectedClasses(e.target.value)
    if(vis.error){setVis({...vis, error:false})}
  }

  return (
    <div className={styles.container}>
      <div ref={loaderRef} className={styles.loaderDiv}>
        {!resource ? 

            response==='initial' ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
              <img src={loader} alt="loader"/>

              {!resource ? 
                <h4>Creating activity from template...</h4>
                : 
                <h4>Creating activity...</h4> 
              }

            </ div>
            : response==='Success!' ? 
              <h3>Activity successfully created!</h3>
            :
              <h4>An error occured</h4>
        :

            response==='initial' ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
              <img src={loader} alt="loader"/>
              <h4>Creating new activity...</h4>
            </div>
            : response==='Success!' ? 
              <h3>Activity successfully created!</h3>
            :
              <h4>An error occured</h4>
              
        }
      </div>

    {!resource ? 
      <h2>Start an activity in your class using this Template</h2> 
    : 
      <h2>Start an activity in your class using this Template</h2>
    }
       <p>Select the class to which you wish to assign new activity</p>

    {
       !free && (
        <p style={{ fontSize: '12px' }}>Each <strong>Sophia Institute workbook</strong> has a cost of 
        <strong>$6.99 per student</strong> in a class. 
          It is a one time charge and you will be able to access the workbook any time after.
          An <strong>active subscription</strong> will be necessary to share the activity in the class.
           </p>
      )
    }
      <div ref={contRef}  className={styles.classBlocks} onChange={(e) => { handleCheckbox(e) }}>
        {availableClasses && !availableClasses.length ? 
        
          <div style={{display:'flex', flexDirection:'column', alignItems:'center', margin:'15px auto' }}>
          	<h4>loading available classes...</h4>
          	<img src={spinner} alt="spinner"/>
          </div>
        :
        <React.Fragment>
        {
          availableClasses.map((item, index) => {
            return (
                  <label key={index}>
                    <input type="radio" name='class' value={item.classId} 
                      onChange={() => setSelectedLabel(item)}
                    className="check"/>
                    <div className={styles.classBlock}>
                      <p>
                        {item.className}
                      </p>
                    </div>
                  </label>
            )
          })
        }
        </React.Fragment>
        }
      </div>
       {
      selectedLabel && !free &&   (
        <p style={{ color: '#077CA0', fontSize: '12px', textAlign: 'left'}}>
         <strong>{selectedLabel.className} has {selectedLabel && selectedLabel?.studentsCount || 0} students.  </strong>
         {
          selectedLabel && selectedLabel?.studentsCount > apiRes.currentSub.availableSophiaCredits && `You need to purchase`
         }
           <strong> 
             {selectedLabel && selectedLabel?.studentsCount > apiRes.currentSub.availableSophiaCredits 
              && ` ${selectedLabel?.studentsCount - apiRes.currentSub.availableSophiaCredits}  additional Sophia Institute Workbooks  as `
             }
            </strong>
          you have {apiRes.currentSub.availableSophiaCredits} Workbooks available in your credit.
        </p>
       )
       
       }
     
 
      <div className={styles.buttons}>
        <div onClick={handleOk} className={styles.okBtn}>OK</div>
        <div onClick={() => { closeBtn.current.click() }} className={styles.cancelBtn}>Cancel</div>
      </div>
      {
        !free  && selectedLabel &&
        selectedLabel?.studentsCount > apiRes.currentSub.availableSophiaCredits &&  (
        <p style={{ color: "#FB3333", fontSize: '12px' }}>*By clicking “ok” you will confirm the purchase of the workbooks and the total price will be charged to the credit card on file.</p>
       )}
      {vis.error ? <h5 className={styles.error}>Please select a class</h5> : null}
    </div>
  )

}

export default JournalTemplateModal;