import React, { useEffect, useState } from "react";

import { sendToApiReturn } from "API/teacherCalls";
import { sendToApiReturnParents } from "API/parentCalls";

import { RenderDownloadContent } from "./renderDownloadContent";
import { RenderPrintContent } from "./renderPrintContent";
import { RenderCoverContent } from "./renderCoverContent";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import styles from './PDFRenderModal.module.scss'
import DownloadPDFBtn from "./DownloadPDFBtn";
import { isSafari } from "helpers/isSafari";
import { useSelector } from "react-redux";

const PDFRenderModal = ({item: { payload, pageCount, coverChoice, activityId, parent, printCover, printContent}}) => {

  const [ pdfData, setPdfData ] = useState(null)
  const isFaithJournal = useSelector(state=>state.dashData.simplifiedActivity)


  useEffect(() => {
    console.log(isSafari)
  }, [isSafari])
  
  
  const retreiveData = async (id) => {
    const { response } = 
    parent ? 
      await sendToApiReturnParents(`activity/${id}/pdf`, payload ) 
      : printCover ? 
        await sendToApiReturn(`activity/${activityId}/print_cover`, payload ) 
        : printContent ? 
          await sendToApiReturn(`activity/${activityId}/print_contents`, payload ) 
            : 
              await sendToApiReturn(`activity/${id}/pdf`, payload ) 
    setPdfData( response )
  }

  useEffect(()=>{ retreiveData(activityId) },[])

  return(
    <div className={styles.container}>

        {
          !pdfData?.projectTitle ? <h6>fetching PDF data...</h6> :

          pdfData?.coverType==='assignment' ? <div style={{width:"100%", height:"100%", display:"flex", justifyCOntent:"center", alignItems:"center"}}> <h3 style={{margin:"50px auto"}}>No compatible activity type detected</h3> </div>

        :
          <>
            <PDFViewer className={`${styles.renderWrapper} ${printContent&&styles.content} ${isSafari&&styles.safari_only }`} >
              {
                  printContent ? 
                    <RenderPrintContent
                      pdfData={pdfData}/>
                :

                  printCover  ?
                    <RenderCoverContent 
                      pdfData={pdfData}
                      coverChoice={coverChoice}
                      pageCount={pageCount} />
                :

                  <RenderDownloadContent 
                      isFaithJournal={isFaithJournal}
                      pdfData={pdfData}/>
                      
                    }
            </PDFViewer>

          
             <DownloadPDFBtn
              isFaithJournal={isFaithJournal}
              pdfData={pdfData}
              coverChoice={coverChoice}
              printContent={printContent}
              printCover={printCover}
              pageCount={pageCount} />
            
          </>

        }

    </div>
  );
}

export default PDFRenderModal