import React, { useEffect, useState } from 'react';

import styles from './NewActivityResources.module.scss';
import ResourceItem from './ResourceItem/ResourceItem';
import lupa from 'assets/lupa_grey.svg'
import { toTitleCase } from 'helpers/toTitleCase';
import { getFromApi } from 'API/teacherCalls';
import { LoadingOverlay } from 'components';
import Masonry from 'react-masonry-css';
import { filterObjectByObject, testFilters } from 'helpers/filterFunctions';
import { useSelector } from 'react-redux';
import { useUserType } from 'context/AltPlatformProvider';

const NewActivityResources = ({filterVals, setFilterVals,  setPreviewItem, toggleResources, params, setParams, resourceItems, setResourceItems, storyStarts, setStoryStarts }) => {

  const resourceFilterItems = [ 'all', 'ELA', 'science', 'poetry', 'history', 'mathematics', 'religion', 'other' ]
  const storyFilterItems = [ 'all', 'Fantasy', 'Humor', 'Mystery', 'Myths & monsters', 'Romance', 'Suspense & Thrillers', 'Realistic fiction', 'Sports', 'Historical Fiction', 'Action & Adventure', 'Science Fiction' ]

  const [ displayItems, setDisplayItems ] = useState(null)
  const [ isStory, setIsStory ] = useState(params.activityType==='story')
  const { fjUser } = useUserType()

  let data
    
  const setResources = async () => {

    setDisplayItems(null)

    if(!resourceItems){
      data = await getFromApi('resources');
      setResourceItems(data)
    } else {
      data = resourceItems
    }

    setDisplayItems(data.resources.filter(item =>
      filterObjectByObject(
        item,
        {
          activityType: params.activityType,
          activityName: filterVals.textInput,
          activitySubject: filterVals.subject,
          author: filterVals.author
        }
      )
    ))
  }

  const setStoryItems = async () => {
    setDisplayItems(null)
    if(!storyStarts){
      data = await getFromApi('story_starts');
      setDisplayItems(data.filter(i=> testFilters(i, filterVals.textInput, 'all', 'all')))
      return setStoryStarts(data)
    } else {
      setDisplayItems(storyStarts.filter(storyStart=> 
        filterObjectByObject(
          storyStart, 
          {
            title:filterVals.textInput,
            genre:filterVals.subject,
            author: filterVals.author
          }
         )
        )
      )
    }
  }

  useEffect(()=>{

    setIsStory(params.activityType==="story")

    if(params.activityType==='story'){
      setStoryItems()

    } else {
      setResources()
    }

  },[params.activityType, filterVals])

  useEffect(()=>setFilterVals({...filterVals, textInput:'', subject:'all', author:'all' }),[params.activityType])


  return (
    <div className={styles.container}>

      <div onClick={()=>{toggleResources(false); setParams({...params, startType:"own"})}} className={styles.close} />
 
      <div className={styles.topBar}>
        <h1>Select {!isStory ? "Resource" : "StoryStart"} From Library</h1>
        <div className={styles.searchDiv}>
          <img src={lupa} alt="serach" />
          <input value={filterVals.textInput} onChange={e=>setFilterVals({...filterVals, textInput:e.target.value})} type="text"/>
        </div>
      </div>
      {
        !fjUser &&
          <div className={styles.filters}>
            {
              !isStory ? 
                resourceFilterItems.map((i,idx) => 
                <div 
                  key={idx} 
                  onClick={()=>setFilterVals({...filterVals, subject:i==='history'?"History-Social Studies":i})} 
                  className={`${styles.filterRadio} ${filterVals.subject === i && styles.checked}`}>
                    {i==='all'?'All subjects' :  toTitleCase(i)}
                  </div> 
                )
                :

                  <>
                  <div 
                    onClick={()=>setFilterVals({...filterVals, author:'all'})} 
                    className={`${styles.filterRadio} ${filterVals.author === 'all' && styles.checked}`}>All Authors</div> 
                  <div 
                    onClick={()=>setFilterVals({...filterVals, author:'escribli'})} 
                    className={`${styles.filterRadio} ${filterVals.author === 'escribli' && styles.checked}`}>Escribli</div> 

                    {                
                      storyFilterItems.map((i,idx) => 
                        <div 
                          key={idx} 
                          onClick={()=>setFilterVals({...filterVals, subject:i})} 
                          className={`${styles.filterRadio__pink} ${filterVals.subject === i && styles.checked}`}>
                            {i==='all'?'All genres':i==='Realistic fiction'?'Realistic' :  toTitleCase(i)}
                          </div> 
                        )
                    }
                  </>
            }
            {
            }
          </div>
      }

      <div className={`${styles.items} ${fjUser && styles.marginTop}`}>
        { 
          !displayItems ?
            <LoadingOverlay contained blueSpinner/>

          : !displayItems.length ?

                (filterVals.textInput!==""||filterVals.author!=='all'||filterVals.subject!=='all') ?
                      <h5 className={`${styles.noItems} ${isStory&&styles.story}`}>No {!isStory ? "resource items" : "StoryStarts"} matching search criteria</h5>
                  :
                      <h5 className={`${styles.noItems} ${isStory&&styles.story}`}>No {!isStory ? "resource items" : "StoryStarts"} found</h5>

          :

            <Masonry
              breakpointCols={{ default: 4, 1500: 3, 1300: 2, 850: 1 }} 
              className="masonry-grid"
              columnClassName={"masonry-grid_column"}>
              {
                !isStory ?
                  displayItems.map((i,idx) => i !== 'initial' &&
                    <ResourceItem
                      setPreviewItem={setPreviewItem}
                      key={idx}
                      item={i} /> )
                      
                : 
                      
                  displayItems.map((i,idx) => i !== 'initial' &&
                    <ResourceItem
                      storyStart
                      setPreviewItem={setPreviewItem}
                      key={idx}
                      item={{activityName:i.title, author:i.author, sections:[{instructions:i.blurb}], activitySubject:i.genre, activityId:i.storyStartId, storyStart:true}} /> )

              }
            </Masonry>

          
          
        }
      </div>

    </div>
  )

}

export default NewActivityResources;






// useEffect(()=> {

    
//   setDisplayItems(null)
//   // setFilterVals({...filterVals, textInput:'', subject:'all' })
//   setIsStory(params.activityType === 'story'); 

//     if( params.activityType !== 'story'){
//       console.log('set1')
//       if (!resourceItems )  fetchResources()
//       else setDisplayItems(resourceItems.filter(i=> resourceItemFilterNew(i, {...filterVals, textInput:'', subject:'all' })))
      
//     }  else {
//       console.log('set2')
      
//       if(!storyStarts) fetchStoryStarts()
//       else setDisplayItems(storyStarts)
      
//     }
    
    
//   } ,[params.activityType])
  
  
//   const fetchResources = async () => {
//   console.log('set3')
//   setDisplayItems(null)
//   setResourceItems(await getFromApi(`resources`));
// }

// const fetchStoryStarts = async () => {
//   console.log('set4')
//   setDisplayItems(null)
//   setStoryStarts(await getFromApi('story_starts'));
// }


// useEffect(()=>{
//   console.log(filterVals)
//   if(filterVals.textInput!==""||filterVals.subject!=='all'){
//     console.log('set5')
//     console.log(filterVals.subject)
//     console.log(filterVals.textInput)
//     if(resourceItems && !isStory) setDisplayItems(resourceItems.filter(i=> resourceItemFilterNew(i, filterVals)))
//     if(storyStarts && isStory) setDisplayItems(storyStarts.filter(i=> testFilters(i, filterVals.textInput, 'all', 'all')))
//   }
// },[filterVals])

// useEffect(()=>{ if( resourceItems && !isStory ) //receiving data from backend (journals and assignments)
//   console.log('set6')
//   setDisplayItems(resourceItems?.filter(i=> resourceItemFilterNew(i, filterVals))) 
// },[resourceItems])

// useEffect(()=>{ if( storyStarts && isStory ) //receiving data from backend (stories)
//   console.log('set7')
// setDisplayItems(storyStarts) },
// [storyStarts])