import React, { useState, useRef } from 'react';

import styles from './NewClassOnboarding.module.scss';
import { sendToApi, sendToApiReturn } from 'API/teacherCalls';
import loader from 'assets/loader-sm2.svg'
import { handleNewClassOnboarding } from 'API/TeacherCallFunctions/activityFunctions';
import { store } from 'storeConfig';
import { setQueryItem } from 'Redux/actions';

const NewClassOnboarding = ({closeBtn, item:{variant, activityId, activityType}}) => {

  const [ inputText, setInputText ] = useState('')
  const loaderRef = useRef();

  const handleNew = async () =>  {
    if(inputText!==''){
      loaderRef.current.classList.remove(styles.hidden)
      const res = await sendToApiReturn(`class/create?className=${inputText}`)
      handleNewClassOnboarding(res, {variant:variant, activityId:activityId, activityType}, store, setQueryItem )
    }
  }
  return (
    <div className={styles.container}>
      <div ref={loaderRef} className={`${styles.loaderDiv} ${styles.hidden}`}>
        <img src={loader} alt="loader" />
      </div>
      <h1>Choose a name for your first class</h1>
      <p>Class names are editable and can be changed at any time.</p>
      <input onChange={(e)=>{setInputText(e.target.value)}} type="text" autoFocus={true} placeholder="New class name..."/>

      <div className={styles.buttons}>
        <div onClick={handleNew} className={styles.btn}>Next</div>
      </div>
    </div>

  )
}

export default NewClassOnboarding;