import React, { useState } from 'react';

import styles from './GrammarReportModal.module.scss';
import icon from '../../../../../../assets/sidebarIcons/sidebar_grammar_blue.svg';
import { sendToApiReturn } from 'API/teacherCalls';
import { useSelector } from 'react-redux';
import { store } from 'storeConfig';
import { updateActivitySection } from 'Redux/actions';

const GrammarReportModal = ({activeSection, setActiveSection, setModal, mobile, activeSection: { shareGrammar, sectionIndex }}) => {

  const [ localBool, setLocalBool ] = useState(shareGrammar)
  const activityId = useSelector(state => state.activityData.activityId)

  const handleSave = async (input) => {
    setLocalBool(input)
    const res = await sendToApiReturn(`activity/${activityId}/update_unit/${sectionIndex}`, {...activeSection, shareGrammar:input})
    setActiveSection(res)
    store.dispatch(updateActivitySection(sectionIndex, res))
    if(mobile){setModal("setup")}
  }

  return (
    <div className={`${styles.container} ${mobile && styles.mobile}`}>

      <div className={styles.titleBar}>
        <img src={icon} alt="upload" />
        <h2>Grammar report</h2>
      </div>

      <div className={styles.textDiv}>
        <p>Make grammar report available to the students after they submit their entry</p>
      </div>

      <div onClick={()=>handleSave(!localBool)} className={styles.sliderDiv}>
        <p className={!localBool && styles.show}>not active</p>
        <div className={`${styles.slider} ${localBool && styles.active}`}>
          <div className={`${styles.sliderInner} ${localBool && styles.active}`} />
        </div>
        <p className={localBool && styles.show}>active</p>
      </div>

      <div className={styles.howItWorks}>
        <h5>How does the grammar report work?</h5>
        <p>The grammar report is an app inside of the student’s entry that will show the any grammar mistakes  on the activity</p>
      </div>
{/* 
      <div className={`${styles.submitBtn} ${ localBool !== shareGrammar && styles.enabled}`}>
        <p onClick={handleSave}>Save</p>
      </div> */}

    </div>
  )
  

}

export default GrammarReportModal;