import React, { useState, useEffect } from 'react';

import styles from './LoginCodeModal.module.scss';
import { sendToApi, sendToApiReturn } from 'API/teacherCalls';

const LoginCodeModal = ({item:{studentName, studentEmail, studentId}, closeBtn}) => {

  const [confirmation, setConfirmation ] = useState(false);
  const [ loginCode, setLoginCode ] = useState('-')

  const handleCopy = (e) => {
    setConfirmation(true);
    e.preventDefault();    
    setTimeout(()=>{setConfirmation(false)},2000)

    const copyData = `Username: ${studentEmail} \nAccess Code: ${loginCode}`
    navigator.clipboard.writeText(copyData)
}

const getCode = async () => {
  const res = await sendToApiReturn(`login_code/${studentId}`)
  setLoginCode(res.login_code)
}

useEffect(()=>{ getCode() },[])

  return (
    <div className={styles.container}>
      <h1>Generate Password</h1>
      <div className={styles.inner}>
        <p> See below the one-time login code for: </p>
        <p><span className={styles.studentName}>{studentName}</span></p>
        <p>Username: <span> {studentEmail}</span> </p>
        <p className={styles.access}>Access code: <span className={styles.code}>{loginCode}</span></p>
        <div className={styles.copy} onClick={(e)=>{handleCopy(e)}}> Copy Details
        </div><span className={styles.conf}>{confirmation ? <p> Details copied to clipboard </p> : null }</span> 
      </div>
      <div className={styles.btn} onClick={()=>{closeBtn.current.click()}}>Done</div>
    </div>

  )
}

export default LoginCodeModal;