import React from 'react';
import { setView, setQueryItem } from '../Redux/actions';
import {store} from '../storeConfig';

import defaultPic0 from 'assets/nav/defaultPic0.png'
import defaultPic1 from 'assets/nav/defaultPic1.svg'
import defaultPic2 from 'assets/nav/defaultPic2.svg'
import defaultPic3 from 'assets/nav/defaultPic3.svg'



export const switchView = (input) =>{ store.dispatch(setView(input)) }

export const showModal = (modal, item ) => {
  if(modal){
    modal.current.classList.add('show')
    store.dispatch(setQueryItem(item))
  } else {
    showModal();
  }
}

export const statusPill = (input) =>{ 
  // console.log(`Pill input ${input}`)
  switch(input){
    case 'Live':
      return (
          <span className="pill live">{input}</span> 
        )
    case 'Writing':
      return (
          <span className="pill live">{input}</span> 
        )
    case 'Completed':
      return (
          <span className="pill completed">{input}</span> 
       )
    case 'Scheduled':
      return (
          <span className="pill scheduled">{input}</span> 
       )
    case 'Voting':
      return (
          <span className="pill voting">{input}</span> 
       )
    case 'Draft':
      return (
          <span className="pill draft">{input}</span> 
       )
    case 'In Approval':
      return (
          <span className="pill inApproval">{input}</span> 
       )
    case 'approved':
      return (
          <span className="pill approved">{input}</span> 
       )
    case 'pending revision':
      return (
          <span className="pill pendingRevision">{input}</span> 
       )
    case 'submitted':
      return (
          <span className="pill submitted">{input}</span> 
       )
    case 'started':
      return (
          <span className="pill started">{input}</span> 
       )
    case 'not started':
      return (
          <span className="pill notStarted">{input}</span> 
       )
    default:
      return (<h6>status</h6>)
  }
}

export const levelPill = (input) =>{ 
  switch(input){
    case 'basic':
      return (
          <span className="pill basic">{input}</span> 
       )
    case 'intermediate':
      return (
          <span className="pill intermediate">{input}</span> 
       )
    case 'advanced':
      return (
          <span className="pill advanced">{input}</span> 
       )
    default:
      return (<span>level</span>)
  }
}

export const statusDot = (status, styles) => {
  switch(status){
    case 'approved':
      return <div className={`${styles.dot} ${styles.approved}`}></div>
      case 'pending revision':
        return <div className={`${styles.dot} ${styles.pendingRes}`}></div>
      case 'submitted':
        return <div className={`${styles.dot} ${styles.submitted}`}></div>
      case 'started':
        return <div className={`${styles.dot} ${styles.started}`}></div>
      case 'not started':
        return <div className={`${styles.dot} ${styles.notStarted}`}></div>
      default:
        return <div className={styles.dot}></div>
  }
}
export const votedPill = (vote, styles) => {

  
  if(vote){
    return <div className={`${styles.gradePill} ${styles.voted}`}></div>

  } else {
    return <div className={`${styles.gradePill}`}></div>
    
  }

}

export const instancesInArray = (array, key, value) => {
  if(array && array.length){
    return array.filter(item => item[key] === value).length

  } else {
    return 0
  }
}


export const titleCase = (input) => {
  if(input){
    var splitStr = input.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }
}

export const allApproved = (students, action, dispatch) => {
  let i = 0;
  for(i ; i<students.length ; i++){
    dispatch(action(i,'approved',true))
    dispatch(action(i,'status','approved'))
  }
}

export const colorTab = (keyInput, styles) => {
  
      const key = parseInt(keyInput)
  
      if(key === 0 || key === 8 || key === 16 || key === 24 || key === 32){
       return  <div className={`${styles.colorTab} ${styles.one}`}></div>
      }
      else if(key === 1 || key === 9|| key === 17 || key === 25 || key === 33){
       return  <div className={`${styles.colorTab} ${styles.two}`}></div>
      }
      else if(key === 2 || key === 10 || key === 18 || key === 26 || key === 34){
       return  <div className={`${styles.colorTab} ${styles.three}`}></div>
      }
      else if(key === 3 || key === 11 || key === 19 || key === 27 || key === 35){
       return  <div className={`${styles.colorTab} ${styles.four}`}></div>
      }
      else if(key === 4 || key === 12 || key === 20 || key === 28 || key === 36){
       return  <div className={`${styles.colorTab} ${styles.five}`}></div>
      }
      else if(key === 5 || key === 13 || key === 21 || key === 29 || key === 37){
       return  <div className={`${styles.colorTab} ${styles.six}`}></div>
      }
      else if(key === 6 || key === 14 || key === 22 || key === 30 || key === 38){
       return  <div className={`${styles.colorTab} ${styles.seven}`}></div>
      }
      else if(key === 7 || key === 15 || key === 23 || key === 31 || key === 39){
       return  <div className={`${styles.colorTab} ${styles.eight}`}></div>
      } 
      else {
        return  <div className={`${styles.colorTab} ${styles.five}`}></div>
      }
    }

export const colorTabActivityType = (activityType, styles)=> {
  switch(activityType){
    case 'story':
      return  <div className={`${styles.colorTab} ${styles.three}`}></div>
    case 'journal':
      return  <div className={`${styles.colorTab} ${styles.two}`}></div>
    case 'assignment':
      return  <div className={`${styles.colorTab} ${styles.one}`}></div>
    case 'faithJournal':
      return  <div className={`${styles.colorTab} ${styles.faithJournal}`}></div>
    default:
      return  <div className={`${styles.colorTab} ${styles.four}`}></div>
  }
}
    export const gradeBox = (grade, styles) => {

      switch(grade){
        case 1:
          return `${styles.number} ${styles.one}`
        case 2:
          return `${styles.number} ${styles.two}`
        case 3:
          return `${styles.number} ${styles.three}`
        case 4:
          return `${styles.number} ${styles.four}`
        case 5:
          return `${styles.number} ${styles.five}`
        default:
          return 'styles.number'
      }
    }

    export const gradeRowOpen = (grade, styles) => {
      switch(grade){
        case 1:
          return `${styles.container} ${styles.one}`
        case 2:
          return `${styles.container} ${styles.two}`
        case 3:
          return `${styles.container} ${styles.three}`
        case 4:
          return `${styles.container} ${styles.four}`
        case 5:
          return `${styles.container} ${styles.five}`
        default:
          return 'styles.container'
      }
    }
// export const sortWinners = (students) => {

//   students.sort((a, b) => a.finalGrade > b.finalGrade ? -1 : 1)
//   .map((item, i) => {
//     if(testName(item, filterValues.text)){
//       return <GradesRow student={item} />
//     } else { 
//       return null
//     }
//   })
// }

export const rubricHeader = (rubric, index) => {
  if(rubric==='Basic'){
    if(index===0){
      return 'Average'
    }else if (index===1){
      return 'Quality of Work'
    }else if (index===2){
      return '-'
    }else if (index===3){
      return '-'
    }else if (index===4){
      return '-'
    }else if (index===5){
      return '-'
    }
  } else if (rubric ==='Argument/Opinion'){
    if(index===0){
      return 'Average'
    }else if (index===1){
      return 'Introduces the topic and creates an orginisational structure'
    }else if (index===2){
      return 'Evidence supports the claim'
    }else if (index===3){
      return 'Progression (links one idea to the next)'
    }else if (index===4){
      return 'Provides a concluding statement or section'
    }else if (index===5){
      return 'Language, vocabulary and Conventions'
    }
  }else if (rubric ==='Information & Explanatory'){
    if(index===0){
      return 'Average'
    }else if (index===1){
      return 'Introduces the topic and creates an orginisational structure'
    }else if (index===2){
      return 'Topic development'
    }else if (index===3){
      return 'Progression (links one idea to the next)'
    }else if (index===4){
      return 'Provides a concluding statement or section'
    }else if (index===5){
      return 'Language, vocabulary and Conventions'
    }
  }else if (rubric ==='Narrative'){
    if(index===0){
      return 'Average'
    }else if (index===1){
      return 'Introduction and conclusion'
    }else if (index===2){
      return 'Organisation and progression'
    }else if (index===3){
      return 'Plot & story development'
    }else if (index===4){
      return 'Descriptive writing techniques'
    }else if (index===5){
      return 'Language, vocabulary and Conventions'
    }
  } else {
    if(index===0){
      return 'Average'
    }else if (index===1){
      return 'Quality of Work'
    }else if (index===2){
      return '-'
    }else if (index===3){
      return '-'
    }else if (index===4){
      return '-'
    }else if (index===5){
      return '-'
    }
  }

}


export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}


export const defaultUserImg = (user) => {

  const defaultPics = [defaultPic3, defaultPic1, defaultPic2, defaultPic3];

  // console.log(user)
  // console.log(user.imageUrl)

  if(!user || user===undefined || user.imageUrl===undefined || !user.imageUrl){ 
    // console.log('case 1')
    return defaultPic3 
  }

  else if (user.imageUrl && user.imageUrl.toString().length < 5 ){
    // console.log('case 2')
    // console.log(user.imageUrl)
    return defaultPics[user.imageUrl];

  } else {
    // console.log('case 3')
    // console.log(user.imageUrl)
    return user.imageUrl
  }
  
}

export const defaultUserImgAdmin = (user) => {

  const defaultPics = [defaultPic3, defaultPic1, defaultPic2];

  console.log(user)
  // console.log(user.imageUrl)

  if(!user.imageUrl){ return defaultPic1 }
  
  return  defaultPics[user.imageUrl]
}

export const defaultUserImgSettings = (user) => {

  const defaultPics = [defaultPic3, defaultPic1, defaultPic2];

  console.log(user)
  console.log(user.imageUrl)

  if(!user.imageUrl){ return defaultPic1 }
  
  return  user.imageUrl;
}


export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      '.__PrivateStripeElement': {
        border:'1px solid chartreuse !important'
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};


export const redBorder = (array, action) => { //give empty input fields a red border
  console.log(array)
  if(array){
    array.forEach(i => {
      if(i.value===''||action==='remove'){
        i.classList[action]('errorBorder');
      }
    })
  }
}

export const addAnimation = (ref, animation, timeout) => {
  ref.current.classList.add(animation);

  setTimeout(()=>{
    ref.current.classList.remove(animation)
  },timeout)
}

// it will the date like this: "July 30", "December 21", ...
export const monthAndDayToday = () => {
  // today's date
  const today = new Date()
  // full month date
  const monthName = today.toLocaleString('default', { month: 'long' })
  // construct the Month and day response
  return `${monthName} ${today.getDate()}`
}