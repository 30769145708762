import React from 'react';
import spinner from 'assets/Spinner.svg';

import styles from './UploadCSVModal.module.scss';
import { useEffect } from 'react';

const CsvStepThree = ({ apiRes, synodUser }) => {

  // useEffect(()=>{setApiRes([])},[])

  return (
    <div className={styles.containerStep}>

    { apiRes && apiRes.data && apiRes.data.length ? 
      <>
      
        <div className={styles.timeline}>

          <div className={styles.step}>
            <h6>Upload</h6>
            <div className={`${styles.circle} ${styles.completed}`}><h5>1</h5></div>
          </div>

          <div className={styles.step}>
            <h6>Review</h6>
            <div className={`${styles.circle} ${styles.currentStage}`}><h5>2</h5></div>
          </div>

          <div className={styles.step}>
            <h6>Creation</h6>
            <div className={styles.circle}><h5>3</h5></div>
          </div>

          <div className={styles.line}>
            <div className={`${styles.blueLine} ${styles.stageThree}`}></div>
          </div>

        </div>


        <h3>Review import</h3>
        <p>If any of the details are incorrect, please go back to your CSV file and update the values, making sure to use this order for the columns: First Name, Last Name, {!synodUser&&"Grade,"} {!synodUser ? "Student":"participant"} Email (optional), {!synodUser && "Parent Email (optional),"} and upload it again.</p>

        <div className={styles.infoDivs}>
          <div className={styles.infoDiv}>
            Your file contains <span>{apiRes.data.length - apiRes.errCount}</span> {!synodUser ? "students" : "participants"}
          </div>
          {
            apiRes.errCount!==0 &&
            <div className={styles.infoDiv}>
              <span>{apiRes.errCount}</span> { apiRes.errCount>1?"errors":"error"} (not imported)
            </div>
          }
        </div>


        <div className={styles.csvTable}>

          <div className={styles.headers}>
            <div className={`${styles.headerDropdown} ${styles.static}`}> First Name </div>
            <div className={`${styles.headerDropdown} ${styles.static}`}> Last Name </div>
            {!synodUser && <div className={`${styles.headerDropdown} ${styles.static}`}> Grade </div>}
            <div className={`${styles.headerDropdown} ${styles.static}`}> Student Email </div>
            {!synodUser &&  <div className={`${styles.headerDropdown} ${styles.static}`}> Parent Email </div>}
          </div>

          <div className={styles.dataRows}>
            {
              apiRes.data.map((item, idx) =>  {
                return(
                  <div className={(!item.error ? styles.dataRow : `${styles.dataRow} ${styles.error}`)}>
                    {
                      !item.first_name.trim().length? 
                        <div className={`${styles.dataColumn} ${styles.error}`}>{item.first_name.trim()}</div>
                      :
                        <div className={`${styles.dataColumn}`}>{item.first_name.trim()}</div>
                    }
                    {
                      !item.last_name.trim().length? 
                        <div className={`${styles.dataColumn} ${styles.error}`}>{item.last_name.trim()}</div>
                      :
                        <div className={`${styles.dataColumn}`}>{item.last_name.trim()}</div>
                    }
                    {!synodUser ?
                        // !item.grade.trim().length? 
                        //   <div className={`${styles.dataColumn} ${styles.error}`}>{item.grade.trim()}</div>
                        // :
                          <div className={`${styles.dataColumn}`}>{item.grade.trim()}</div>
                      :null
                    }
                    <div className={`${styles.dataColumn}`}>{item.student_email.trim()}</div>
                    {!synodUser && <div className={styles.dataColumn}>{item.parent_email.trim()}</div>}
                  </div>
                )
              })
            }
          </div>

        </div>
      </>


    :
    
        <div className={styles.loader}>
          <img src={spinner} alt="spinner"/>
          <h6>Importing data...</h6>
        </div>
    
    }


    </div>
  )

}

export default CsvStepThree;