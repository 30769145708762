import { stripTags } from 'helpers/stripTags';
import React from 'react';

import styles from './ResourceItem.module.scss';

const ResourceItem = ({ setPreviewItem, storyStart, item, item: { activityName, activityType, activityId, activitySubject, author, sections }}) => {

  return (!activityName) ? null : (
    <div className={styles.container}>

      <div className={styles.hoverDiv}>
        <div onClick={()=>setPreviewItem(item)} className={styles.previewBtn}>
          Preview
        </div>
      </div>

      <div className={styles.details}>
        <h4>{activityName}</h4>
        <h6>By {author}</h6>
      </div>

      <div className={`${styles.subjectPill} ${styles[storyStart ? 'green' : activitySubject.slice(0,3)]}`}>
        {activitySubject==="" ? "other" : activitySubject.slice(0,3) === 'His' ? 'History' : activitySubject}
      </div>

        <div className={styles.previewText} style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: stripTags(sections[0].instructions.replaceAll('<p>', '\n').replaceAll('<li>', '\n')) || ""}}>
        </div>      

    </div>
  )

}

export default ResourceItem;

{/* <h5>{activityName?activityName:'no name'}</h5> 
    <h5>{activityName?activityName:'no name'}</h5> 
    <h5>{activitySubject?activitySubject:'no subject'}</h5>  */}