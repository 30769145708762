import React from 'react';

import styles from './PricingCard.module.scss';

import Img2 from './truth.png'

const PricingCard = ({ blue, pink, light, cardData, selectedValue }) => {




  const { type, allowance, monthly,subtitle, yearly,  benefits, ctaText, ctaAction } = cardData

  return (
    <div className={`${styles.container} `}>
      <div  className={`${styles.cardHead} ${pink&&styles.pink} ${blue&&styles.blue} ${light&&styles.light}`}>
        
        <div className={`${styles.flex}`}>
              <h4>{type}</h4>
              <div>
                {type ==='Spirit of Truth Workbooks' &&<img src={Img2} alt="logo" />}
              </div>
        </div>
        <h6>{allowance}</h6>
        {
          monthly &&
          <div className=''>
              <div className={`${styles.priceDiv}`}>

                <h3>{`$${monthly}`}</h3>
                <div className={`${styles.space}`}>
                  {type ==='Small Class' && '/Month'} 
                  {type ==='Standard' && `/Student per  ${selectedValue === 'annual' ? 'Year' : 'Month'}`} 
                  {type ==='Spirit of Truth Workbooks' && `/per Student`} 
                  <br />
                  <span>
                  {type ==='Spirit of Truth Workbooks' && `per Workbook`} 

                  </span>
                  
                  </div>
              </div>
              

          </div>

        }
      </div>

        
        <div className={`${styles.benefitsList}`}>
          {
            benefits.map((item, idx) =>
              <p className={`${styles.listItem} 
              ${pink&&styles.pink} ${blue&&styles.blue} ${light&&styles.light}`} 
              key={idx}>{item}
              </p>
            )
          }
          <h6 className={`${styles.grey}`} style={{ marginTop: '-18px'}}>
              {ctaAction == "spirit" && 
                'Activities that are designed to accompany the Spirit of Truth High School courses 1-6'
              }
          </h6>
          <h6 className={`${styles.grey}`}>
             <em>{subtitle}</em>
          </h6>
        </div>





    </div>
  )

}

export default PricingCard;