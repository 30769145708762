import React, { Fragment } from 'react';
import { useState } from 'react';
import ArrayDropdown from 'components/StructuralComps/ArrayDropdown/ArrayDropdown';
import { defaultUserImgAdmin, showModal } from '../../../../helpers/uiFunctions';
import styles from './StudentRow.module.scss';

const StudentRow = ({modalOverlay, student, student: { studentName, email, grade, teacherName, parentName, parentEmail, studentBoomerUrl, studentClasses } }) => {

  const open = (e) => {} // { showModal(modalOverlay, {...student, type:'student'}) }
  const [ dropVis, setDropVis ] = useState(false)
  const teachArray = ['Teacher one', 'teacher two']
  
  return (


    <Fragment>
      <div className="hideMobile">
        <div className={`${styles.container}`}>
          <div  onClick={open} className={styles.boomer}>
            {/* <img src={defaultUserImgAdmin({imageUrl:Math.floor(Math.random()*4)})} alt="User Img" /> */}
            <img src={studentBoomerUrl ? studentBoomerUrl : defaultUserImgAdmin({imageUrl:Math.floor(Math.random()*3)})} alt="User Img" />
          </div>
          <div onClick={open} className={styles.name}>
            <h5>{studentName}</h5>
          </div>
          {/* <div onClick={open} className={`${styles.email} hideRes`}>
            <h5>{email}</h5>
          </div> */}
          <div onClick={open} className={styles.groupName}>
            <h5>{studentClasses[0]}</h5>
          </div>
          <div className={styles.teacher}>
            <ArrayDropdown 
              array={[teacherName]} 
              clickHandler={()=>{showModal(modalOverlay, { type:'addParent' })}} 
              copyable={false}
              addOption={false}
              itemTitle={'Teacher'}
              />
          </div>

          <div className={`${styles.parentName}`}>
            {parentName === "" ?
                <div className={styles.inviteBtn} onClick={()=>{showModal(modalOverlay, {type:'addParent'})}}>
                  <span className={styles.plus}>+</span>
                  <span className={styles.invite}>Invite parent</span>
                </div>

              :
                
              <ArrayDropdown 
                array={[parentName]} 
                clickHandler={()=>{showModal(modalOverlay, { type:'addParent' })}} 
                copyable={false}
                addOption={true}
                itemTitle={'Parent'}
                />
              }
            </div>

          <div className={`${styles.parentMail} hideRes`}>
            {parentEmail === "" ?
              null
                // <div className={styles.noMail}>
                //   <p >Add email</p>
                // </div>

              :
                
              <ArrayDropdown 
                array={[parentEmail, parentEmail]} 
                clickHandler={()=>{showModal(modalOverlay, { type:'addParent' })}} 
                copyable={true}
                addOption={false}
                itemTitle={'email'}
                />
              } 
          </div>

          <div className={styles.dotsDiv}>
            
            <div onClick={(e)=>{setDropVis(true)}} onMouseLeave={()=>{setDropVis(false)}} className={styles.dots}>
              {dropVis &&
                <div className={styles.dotsDropdown} onMouseLeave={()=>{setDropVis(false)}}>
                  <div onClick={open} className={styles.dropdownItem}>View profile</div>
                  <div className={styles.dropdownItem}>View portfolio</div>
                  <div onClick={()=>{ showModal(modalOverlay, {type:'remove', variant:'studentAdmin'}) }} className={styles.dropdownItem}>Remove student</div>
                </div>
              }
            </div>
          </div>
        </div>
    
      </div>
  
      <div className="showMobile">
        <div onClick={() => showModal(modalOverlay, {...student, type:'student'})} className={`${styles.container}`}>
          <div className={styles.boomer}>
          </div>
          <div className={styles.mobileHalf}>
            <h4>{studentName}</h4>
            <h5>{email}</h5>
            <h5>Grade {grade}</h5>
          </div>
        </div>
        
      </div>
    </Fragment>
    
    
  )
}

export default StudentRow;