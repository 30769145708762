import React, { useEffect, useRef } from 'react';
import { redirectStaticStore} from 'helpers/redirectFunctions';

import styles from './SpendCreditsModal.module.scss';

const SpendCreditsBooks = ({ booksContRef }) => {

  useEffect(()=>{
    setTimeout(()=>{
      if(booksContRef.current){
        booksContRef.current.classList.add(styles.spendCreditsInnerShow)
      }
    },20)
  },[])

  const inputRef = useRef()

  const handleSubmit = () => {
    console.log(inputRef.current.value)
    console.log(inputRef.current.value.length===5)
    console.log(inputRef.current.value.length)
    if(inputRef.current.value.length===5){
      redirectStaticStore(inputRef.current.value)
    }
  }

  return (
    <div ref={booksContRef} className={styles.spendCreditsBooks}>
    <p>
      Credits can be applied on book/journal purchases at checkout on the Escribli store. First, you need to get the book code for the desired book/journal and you can find the code by following the steps below:
    </p>
      
      <div className={`${styles.subSummary} ${styles.books}`}>
        <p className={styles.books}>1 - Login to your BW Dashboard and go to the desired activity page</p>
        <p className={styles.books}>2 - Open to the activity's 'Overview' tab</p>
        <p className={styles.books}>3 - The code will be visible in 'Publishing Corner' located in the top right corner of the page</p>
      </div>

      <div className={styles.bottomDiv}>
        <input ref={inputRef} type="text" name="codeIndput" id="code" placeholder={"Enter book access code  E.G: GHJB"}/>
        {/* {showError && <p className={styles.errorMsg}>Insufficient credits</p>} */}
        <div onClick={handleSubmit} className={styles.proceedBtn} >Proceed to checkout</div>
      </div>
    </div>
  )

}

export default SpendCreditsBooks;