import React, { useRef } from 'react';

import styles from './ReflectionQuestionsModal.module.scss';

import book from '../../../../assets/book.svg'

const ReflectionQuestionsModal = () => {
  // one reference for each question to be copied
  const q1Ref = useRef()
  const q2Ref = useRef()
  const q3Ref = useRef()
  const q4Ref = useRef()
  const q5Ref = useRef()
  const q6Ref = useRef()
  const q7Ref = useRef()
  const q8Ref = useRef()

  const handleCopy = (qRef) => {
    if(qRef.current){
      // extract the question
      const question = qRef.current.textContent
      // copy it to the clipboard
      navigator.clipboard.writeText(question)
      // let the user know we've copied the question 
      qRef.current.textContent = "COPIED!"
      setTimeout(() => {
        qRef.current.textContent = question
      }, 1500)
    } 
  }

  return (
    <div className={styles.container}>
      <div className={styles.line}>
          <img src={book} alt="" />
         <h3>Reflection questions</h3>
      </div>
      <div className={styles.line}>
        <p ref={q1Ref}>What does this Bible verse mean to you?</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q1Ref)} />
      </div>
      <div className={styles.line}>
        <p ref={q2Ref}>What is the most important part in this verse?</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q2Ref)} />
      </div>
      <div className={styles.line}>
        <p ref={q3Ref}>Explain with your own words what each word in the word bank mean to you.</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q3Ref)} />
      </div>
      <div className={styles.line}>
        <p ref={q4Ref}>After reading the verses, write a few sentences of thankfulness to God.</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q4Ref)} />
      </div>
      <div className={styles.line}>
        <p ref={q5Ref}>Write to Jesus in response to these verses (100 words).</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q5Ref)} />
      </div>
      <div className={styles.line}>
        <p ref={q6Ref}>What is the Holy Spirit telling you in these verses?</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q6Ref)} />
      </div>
      <div className={styles.line}>
        <p ref={q7Ref}>What does this Bible verse mean to you?</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q7Ref)} />
      </div>
      <div className={styles.line}>
        <p ref={q8Ref}>Write a prayer thanking God for ...</p>
        <div className={styles.copyQuestion} onClick={() => handleCopy(q8Ref)} />
      </div>
    </div>
  );
};

export default ReflectionQuestionsModal;

