import React from 'react';
import logo from 'assets/escribli_logo_white.svg'
import logout from 'assets//logout_door_white.svg'
import chevron from 'assets//chevron_white.svg'
import styles from './NavSynod.module.scss';
import { Link } from 'react-router-dom';
import { sendToApi } from 'API/generalCalls';

const NavSynod = ({ dash }) => {

  return (
    <div className={styles.container}>
      {/* {dash ?  */}
        <img src={logo} alt="Escribli Logo" /> 
      {/* : 
        <Link to={"/dashboard"} className={styles.back}>
          <img src={chevron} alt="back" />
          <h5>Go back</h5>
        </Link>
      } */}
      <h4>St. Francis Church - Synod 2021</h4>
      <div onClick={() => sendToApi('auth/logout', '', 'logout')} className={styles.logoutBtn}>
        <img src={logout} alt="logout" />
        <p>Logout</p>
      </div>
    </div>
  )

}

export default NavSynod;