import { sendToApiReturn } from 'API/teacherCalls';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import React, { useRef } from 'react';
import { useState } from 'react';

import styles from './SidebarGrading.module.scss';

const Gradepicker = ({ gradeData, sectionIndex, activityId, studentId, updateEntryKey, updateLocalGrade }) => {

  const { criterias, skill, student_score } = gradeData[0] 

  const [ localScore, setLocalScore ] = useState(student_score)
  const contRef = useRef()
  const [ isOpen, toggleOpen ] = useState(false)

  const colorClass = (input, defaultClass) =>
    input === 4 ? 'green' :
    input === 3 ? 'greenLight' :
    input === 2 ? 'orange' : 
    input === 1 ? 'red' : defaultClass

  const negativeColorClass = input =>
    input === 4 ? 'greenNeg' :
    input === 3 ? 'greenLightNeg' :
    input === 2 ? 'orangeNeg' : 
    input === 1 ? 'redNeg' : 'greyNeg'

   useOutsideClick(contRef, ()=>{toggleOpen(false)})

   const handleRadio = async (value, criteria_id) => {
     setLocalScore(value)
     const data = await sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/grade_student/${studentId}`, {criteria:criteria_id})
     updateEntryKey('finalGrade', data.finalGrade)
     updateLocalGrade(data.finalGrade, sectionIndex-1)
   }

  return (
    <div ref={contRef} className={styles.gradePicker}>
      <div onClick={(e)=>{toggleOpen(!isOpen)}} className={`${styles.titleBar} ${isOpen && styles[colorClass(localScore, 'grey')]} ${isOpen && styles.openDiv}`}>
        <div className={`${styles.scoreBlock} ${!isOpen && styles[colorClass(localScore, null)]}`}>
          <h4 className={isOpen && styles[negativeColorClass(localScore, 'grey')]}>{localScore ? localScore : "-"}</h4> 
        </div>
        <h5>{skill}</h5>
        <div className={styles.chevron} />
      </div>
      <div className={`${styles.content} ${isOpen && styles.openContent}`}>
        {
          criterias.map((item,idx) => 
            <label>
              <input checked={localScore == idx+1} type="radio" name="picker"/>
              <div onClick={()=>handleRadio(idx+1, item[0].criteria_id)}  className={styles.criteriaRow}>
                <div className={styles.checkboxContainer}>
                  <div className={styles.checkbox} />
                </div>
                <div className={`${styles.numberDiv} ${styles[colorClass(idx+1, 'grey')]}`}>
                  <h4>{idx+1}</h4>
                </div>
                <div className={styles.text}>
                  {
                    item[0].description.split('<br>').map((i,arrIdx) => <p key={arrIdx}>{i}</p>)
                  }
                </div>
                
              </div>
            </label>
          )
        }
      </div>
    </div>
  )

}

export default Gradepicker;