import React from 'react'
import styles from './Subscriptions.module.scss'

const Subscriptions = () => {
  return (
    <div className={styles.container}>
        <h4 className={styles.header}>
           <strong> Subscription Details</strong> - anne@gmail.com
        </h4>
        <div>
            <p>
                <strong>Monthly</strong> subscription for <strong>25</strong> students valid until <strong>August 28, 2021</strong>
            </p>
            <p>
                <strong>13</strong> Sophia Institute Workbooks  available for use from the library
                 (<strong>7</strong> workbooks used of <strong>20</strong> purchased)
            </p>
            <div>
                Edit Subscription
            </div>
        </div>
        <div>
            <select name="payment" id="">
                <option value="0">Select payment method</option>
                <option value="1">Monthly</option>
                <option value="2">Annual</option>
            </select>
        </div>

        <div>
            <h4>Institution settings </h4>
        </div>

    </div>
  )
}

export default Subscriptions