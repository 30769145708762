import React from 'react'
import styles from './EscribCard.module.scss';


const EscribCard = ({blue, cardData, selectedValue }) => {
  const { type, allowance, monthly, yearly, benefits, ctaText, ctaAction } = cardData

  return (
    <div className={`${styles.container} ${blue&&styles.blue}`}>
      <h4>{type}</h4>
      <h6>{allowance}</h6>
      {
        monthly &&
          <div className={`${styles.priceDiv}`}>
            <h3>{`$${monthly}`}</h3>
            <span>
            {type ==='Small class' && '/Month'} 
            {type ==='Standard' && `/ ${selectedValue === 'annual' ? 'Year' : 'Month'}`}               
              {type === 'Standard' && '  per Student'}
              </span>
            {yearly &&
              <>
                <h5>{`$${yearly}`}</h5>
                <span>/Yearly</span>
              </>
            }
          </div>
      }
        
        <div className={`${styles.benefitsList}`}>
          <p className={`${styles.listItem}`}>Access to all functionality</p>
          <p className={`${styles.listItem}`}>Unlimited activities</p>
          {
            benefits.map((item, idx) =>
              <p className={`${styles.listItem}`}>{item}</p>
            )
          }
        </div>



    </div>  )
}

export default EscribCard