import { getFromApi } from 'API/generalCalls';
import { CreditLinkBlock, CreditSummaryBlock, CreditBreakdown } from 'components';
import { showModal } from 'helpers/uiFunctions';
import React, { useEffect, useState } from 'react';

import styles from './TeacherCredit.module.scss';

const TeacherCredit = ({ modalOverlay }) => {


  const [ creditData, setCreditData ] = useState({
    totalCredits:'-',
    referrals:'-',
    referalCredits:'-',
    completedReferrals:'-',
    completedCredits:'-',
    bookPurchases:'-',
    bookPurchaseCredits:'-',
    entriesApproved:'-',
    entryCredits:'-',
    plusExpireDate:'-',
    url:''
  })

  const getUserCredits = async ()=> { setCreditData( await getFromApi('account/points_info'))}

  useEffect(()=>{ getUserCredits() },[]) 


  return (
    <div className={styles.container}>

        <div className={styles.left}>

          <div className={styles.creditBanner}>
            <h1>Escribli Teacher Credit</h1>
            <h4>Credits can be applied to monthly teacher subscriptions and for purchasing class Escribli books</h4>
          </div>

          <div className={styles.leftLower}>

            <div className={styles.totalCredits}>
              <h5 className={styles.totalHeader}>TOTAL CREDITS</h5>
              <h1 className={styles.totalCreditsNumber}>{creditData.totalCredits}</h1>
              <div onClick={()=>showModal(
                modalOverlay, 
                { 
                  type:'spendCredits', 
                  credits:creditData.totalCredits, 
                  plusExpireDate:creditData.plusExpireDate, 
                  scheduleCreditPayment: creditData.scheduleCreditPayment,
                  setCreditData:setCreditData,
                  creditData:creditData
                }
                )} className={styles.useCreditBtn}>Use your credit</div>
            </div>

            <CreditSummaryBlock creditData={creditData} />

            <CreditLinkBlock url={creditData.url} />

          </div>


        </div>

        <CreditBreakdown />

    </div>
  )

}

export default TeacherCredit;
