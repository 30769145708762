import { sendToApi, sendToApiReturn } from 'API/teacherCalls';
import React, { useRef } from 'react';
import { store } from 'storeConfig';

import styles from './UploadCSVModal.module.scss';

const CsvStepOne = ({ setStep, classId, setApiRes, synodUser }) => {


  const csvInput = useRef()
  
  const handleUploadfile = async (e) => {
    if(e.target.files){
      const formData = new FormData();
      formData.append('file', e.target.files[0], e.target.files[0].name);
      formData.append('name', 'CSV file from React app'); 

      const data = await sendToApiReturn(`class/${(classId !== undefined ?  classId : store.getState().fetchedClass.classId )}/upload_csv`, formData)

      var errCount = 0
      data.forEach(item => {
        if(
          !item.first_name.trim().length
          ||!item.last_name.trim().length
          // ||!item.grade.trim().length
          ){
            errCount++;
          }
      })

      setApiRes({data: data, errCount:errCount})

      setStep(2)
    }
  }

  return (
    <div className={styles.containerStep}>
      
      {/* <h3>Step-by-step</h3> */}
      <p>1 - Create your CSV</p>
      <p>2 - The required columns are: </p>

      <div className={`${styles.table} ${synodUser && styles.synod}`}>
        <div className={styles.header}>
          <div className={styles.column}>FIRST NAME</div>
          <div className={styles.column}>LAST NAME</div>
          {!synodUser && <div className={styles.column}>GRADE</div>}
          <div className={styles.column}>{!synodUser ? "STUDENT" : "PARTICIPANT"} EMAIL</div>
          {!synodUser && <div className={styles.column}>PARENT EMAIL</div>}
        </div>
        <div className={styles.row}>
        <div className={styles.column}>First name 1</div>
          <div className={styles.column}>Last name 1</div>
         {!synodUser &&  <div className={styles.column}>5</div>}
          <div className={styles.column}>name1@gmail.com</div>
          {!synodUser && <div className={styles.column}>parent1@gmail.com</div>}
        </div>
        <div className={styles.row}>
        <div className={styles.column}>First name 2</div>
          <div className={styles.column}>Last name 2</div>
         {!synodUser &&  <div className={styles.column}>5</div>}
          <div className={styles.column}>name2@gmail.com</div>
          {!synodUser && <div className={styles.column}>parent2@gmail.com</div>}
        </div>
        <div className={styles.row}>
        <div className={styles.column}>First name 3</div>
          <div className={styles.column}>Last name 3</div>
         {!synodUser &&  <div className={styles.column}>5</div>}
          <div className={styles.column}>name3@gmail.com</div>
          {!synodUser && <div className={styles.column}>parent3@gmail.com</div>}
        </div>
        <p className={styles.optional}>Required</p>
      </div>

      <p>3 - You do not need to add the column names as you will be able to match the data with the required fields</p>
      <p>4 - Upload the CSV File</p>





      <div className={styles.upload}>
        <div className={styles.img}></div>
        <h3>Upload {!synodUser?"students":"participants"} here</h3>
        <div className={styles.btn} onClick={()=>{csvInput.current.click()}}>Choose file</div>
        <input type="file" name="uploadImg" ref={csvInput} onChange={handleUploadfile} accept=".csv" style={{display:'none'}}/>
      </div>
    </div>
  )

}

export default CsvStepOne;