import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { store } from "./storeConfig";
import App from "./App";
import "./shared.scss";
import AltPlatformProvider from "context/AltPlatformProvider";
import RandomAccess from "components/Teacher/RandomAccess/RandomAccess";

let env;

if (window.location.host === "localhost:3000") {
  env = "dev";
} else if (window.location.host === "app.sand.escribli.com") {
  env = "stage";
} else if (window.location.host === "app.escribli.com") {
  env = "prod";
}

const stripeKey =
  env === "prod"
    ? "pk_live_51JZGxAH8mRTYLOz5s91L7tW1B9NnidWHl3TnxFUcyByAVqlBu22e7IrMZvqxndtPUf4sRsTvaGA2MAwJJV29m9fr00NWljFwGC"
    : "pk_test_51JZGxAH8mRTYLOz5uAiJwW9c24BFhL3RrZmfTzGvDMvQHcby1F9xNPr352ywyFBcTFIxO2S55ihHM1cCM1WpzbBn00ZyCCQrdb";
const stripePromise = loadStripe(stripeKey);

ReactDOM.render(
  <Provider store={store}>
    <div className="appWrapper">
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <AltPlatformProvider>
            <App />
          </AltPlatformProvider>
        </Elements>
      </BrowserRouter>
    </div>
  </Provider>,
  document.getElementById("root")
);
