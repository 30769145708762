import { toTitleCase } from 'helpers/toTitleCase';
import React, { useRef, useState, useEffect } from 'react';

import styles from './ActivitySectionsBar.module.scss';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import { sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { updateSectionKey } from 'Redux/actions';

const ActivitySectionDiv = ({ isStoryStart, editing, setEditing, activityId, activeSection, setActiveSection, section, section:{sectionName, sectionPhase, sectionIndex, sectionId, unseenEntries }, idx, selectedIdx, setSelectedIdx}) => {

  const [ yState, setClientY ] = useState(0)
  const [ localName, setLocalName ] = useState(sectionName)
  const contRef=useRef()
  const nameInputRef=useRef()
  const currentSectionRef = useRef()

  useEffect(()=>{ 
    if(currentSectionRef && currentSectionRef.current && 
       sectionIndex === selectedIdx){
      // scroll to the current one
      currentSectionRef.current.scrollIntoView();
    }
  },[])

  const phaseClass = 
    [2,3,4,5].indexOf(sectionPhase) !== -1 ? 'live'
    : sectionPhase=== 1 ? 'draft'
    : sectionPhase=== 21 ? 'scheduled'
    : [7,8,10].indexOf(sectionPhase) !== -1 ? 'completed' : 'null'

    const isDraft = [1].indexOf(sectionPhase) !== -1;
    const isComplete = [7,8,10].indexOf(sectionPhase) !== -1;
    const isScheduled = sectionPhase=== 21;

  

  const handleNewName = () => {
    if(nameInputRef.current?.value !== "" && nameInputRef.current?.value !== sectionName){
      
      const newData = {...activeSection, sectionName:nameInputRef.current?.value} //create updated section object

      store.dispatch(updateSectionKey(idx, 'sectionName', nameInputRef.current?.value))
      sendToApiReturn(`activity/${activityId}/update_unit/${idx}`, newData)
      setLocalName(nameInputRef.current?.value)

      //if the section being renamed is the currently selected section, the following operation is necessary to ensure 
      //that the new name is available to sibling/parent components
      if(sectionId === activeSection.sectionId)setActiveSection({...activeSection, sectionName:nameInputRef.current?.value})

    }
    setEditing(false)
  }

  const handleNameInput = e => { 
    if(e.key==='escape'){
      setEditing(false)
    }
    else if(e.key === 'Enter'){
      handleNewName()
    } 
  }

  const handleEditTitle = () => {
    // set editing
    setEditing(idx)
    // make sure this is the selected section
    setSelectedIdx(idx)
  }

  const handleClick = e => {
    //yState represents the yOffset of this particular sectionDiv
    //mouseUp event compares the current yOffset with the event's clientY property
    //in order to ascertain if it was a drag or just a click
    //we only select a new section in the case of a click, a drag is controlled in the parent - <ActivitySectionsBar />
    if(yState === e.clientY){
      setSelectedIdx(idx)
    } 
  }
  useOutsideClick(contRef, ()=>{if(editing===idx)handleNewName()})
  
  return (
    <label ref={currentSectionRef}>
        <input checked={selectedIdx === idx} type="radio" name="activeSection" />
        <div ref={contRef} className={` ${styles.sectionDiv} ${selectedIdx === idx && styles.checked}`}>
            <div 
              className={`
              ${selectedIdx === idx && styles.checked}
              ${styles.wrapper} 
              ${isStoryStart && styles.storyStart} 
              ${styles[phaseClass]}
              `}>
            {(unseenEntries>0&&sectionPhase===2)&&<div className={styles.dot} />}
          <div className={styles.numberCircle}>
            <p>{idx + 1}</p>
          </div>

          {
            editing === idx ?
              <input defaultValue={localName} onKeyDown={handleNameInput} type="text" autoFocus ref={nameInputRef} className={styles.nameInput}/>
            :
              <h5>{!isStoryStart ? localName : 'Story start'}</h5>
          }

          { 
            isStoryStart ? 
              <div className={styles.rocket} />
            
            :
              <div onClick={()=>handleEditTitle()} className={`${styles.pencil} ${editing===idx && styles.inactive}`} />

          }

          { (isComplete && !isStoryStart ) && <div className={styles.tick} /> }

          { isScheduled && <div className={styles.calendar} /> }


          <div onMouseDown={(e) => setClientY(e.clientY)} onMouseUp={(e)=>handleClick(e)} className={styles.clickHandle} />
          </div>
        </div>

      </label>
  )

}

export default ActivitySectionDiv;




// <Tooltip
// hoverClass={styles.tooltipHover}
// maxWidth={'fit-content'}
// left={'-50px'}
// whiteSpace={'nowrap'}
// padding={'3px 15px'}
// tooltipText={'change section name'} />