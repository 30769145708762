import React from 'react';

import styles from './AppBanner.module.scss';

const AppBanner = ({ bannerText, buttonFunction, bannerStyle, buttonText, buttonStyle }) => {

  return (
    <div className={`${styles.container} ${styles[bannerStyle]}`}>

      <h5>{bannerText}</h5>

      <div onClick={buttonFunction} className={`${styles.button} ${styles[buttonStyle]}`}>
        <h5>{buttonText}</h5>
      </div>

    </div>
  )

}

export default AppBanner;