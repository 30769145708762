import React,{useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS } from 'helpers/uiFunctions';

import styles from './HauntSubModal.module.scss';
import { getFromApi, sendToApi } from 'API/parentCalls';
import loader from 'assets/Spinner.svg'
import spinner from 'assets/spinner-sml--fast.svg';
import tick from 'assets/teacher/icon-circleTick.svg'

const HauntSubModal = ({closeBtn, item:{kids, setApiRes}}) => {

  const [ apiResponse, setApiResponse ] = useState('initial')
  const [ noSelect, setNoSelect ] = useState(false)
  const [ complete, setComplete ] = useState(false)
  const {email, name} = useSelector(state=>state.dashData)
  const stripe = useStripe();
  const elements = useElements();
  const loadOverRef = useRef();
  const confRef = useRef();
  const errorRef = useRef();


  const callApi = async () => { 
    if (!localStorage.getItem("haunt_pi_id")) {
    setApiResponse(await(getFromApi('haunt_purchase')))

    } else {
      setApiResponse(await(getFromApi(`haunt_purchase?pi_id=${localStorage.getItem("haunt_pi_id")}`)))

    }
  }

  useEffect(()=>{callApi()},[])

  useEffect(()=>{
    if(apiResponse&&apiResponse.pi_id){
      localStorage.setItem('haunt_pi_id', apiResponse.pi_id);
    }
  },[apiResponse])
  

  const handleSubmit = async () => {
    loadOverRef.current.classList.add(styles.show)

    console.log(elements.getElement(CardElement))

    if (!stripe || !elements) { return; }

    const result = await stripe.confirmCardPayment(apiResponse.cs, {

      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email:email
        },
      }
    }); 
    

  
    if (result.error) {
      loadOverRef.current.classList.remove(styles.show)
      errorRef.current.innerText=result.error.message;
      console.log(result.error.message);
    } else {
      
      if (result.paymentIntent.status === 'succeeded') {
        setComplete(true)
        loadOverRef.current.classList.remove(styles.show)
        confRef.current.classList.add(styles.show)
        sendToApi(`haunt_purchase_success/${localStorage.getItem("haunt_pi_id")}`, '', 'familyPlusSubSuccess', setApiRes)
      }
    }
    
  };

  const handleCheckbox = async () => {
    const newArray = Array.from(document.querySelectorAll("input[type='checkbox']:checked"));
    if(!newArray.length){setNoSelect(true)}else(setNoSelect(false))
    setApiResponse({...apiResponse, price:'recalculating'})
    setApiResponse(await(getFromApi(`haunt_purchase?pi_id=${localStorage.getItem("haunt_pi_id")}&students_ids=${newArray.map(i => i.id)}`)))
  }

  return (
    apiResponse && apiResponse.price ? 

    <div className={!complete ? styles.container : `${styles.container} ${styles.complete}`}>
      <h1>Payment Details</h1>
      <div className={styles.confirmTopBar}>
        <div className={styles.colorTab}></div>
        <h4>Your Subscription</h4>

        {
          noSelect ? 

            <p className={styles.noSelect} >Please select at least one child from the list below to sign up to Escribli Family+</p>

          :

          <>
              <div className={styles.planRow}>
                <p>Family+ Plan 
                  <span className={styles.planCount}>

                    {
                      apiResponse.price!=='recalculating' ?
                        ` x${apiResponse.students_ids.length}`
                      :null

                    }

                  </span>
                </p>

                {
                  apiResponse.price!=='recalculating' ?
                    <p>${apiResponse.price}</p>
                  :
                    <img className={styles.priceSpinner} src={spinner} alt="spinner"/>

                }
              </div>
              <div className={styles.planRow}>
                <p>Term*</p>
                <p>1 month</p>
              </div>
              <div className={styles.planRow}>
                <i className={styles.subtext}>* automatic renewal each month</i>
              </div>
          </>

        }
      </div>

      <p className={styles.childrenTitle}>Children to sign up:</p>
      <div className={styles.children}>
        <div onChange={handleCheckbox} className={styles.childrenInner}>
          {
            kids&&kids.length ? 
              kids.map((item, idx)=>{
                return(
                  <label key={idx}>
                    <img src={item.boomerUrl} alt="boomer"/>
                    <h6>{item.childName}</h6>
                    <input type="checkbox" name="children" id={item.childId} defaultChecked/>
                  </label>
                )
              })
            :null
          }
        </div>
      </div>

      <div className={styles.details}>
        <p>Please enter your card details below</p> 
        <CardElement options={CARD_ELEMENT_OPTIONS} className={styles.cardElement} />
        <div ref={errorRef} className={styles.errorMsg}></div>
      </div>
      <div onClick={handleSubmit} className={styles.confirmBtn}>Submit</div>


     <div ref={loadOverRef} className={styles.loadingOverlay}>
       <img src={loader} alt="loader"/>
       <p>processing...</p>
     </div>
     <div ref={confRef} className={styles.confMsg}>
       <img src={tick} alt="tick"/>
       <h2>You have succesfully subscribed!</h2>
       <p>Thank you for subscribing to Escribli Family+</p>

       <div onClick={()=>closeBtn.current.click()} className={styles.closeBtn}>Close</div>
     </div>
      
    </div>

    :

      <div className={styles.container}>
        <div className={styles.initLoadDiv}>
            <img src={spinner} alt="spinner"/>
        </div>
      </div>


  )

}

export default HauntSubModal;

