import React from 'react';
import img1 from 'assets/tutorial1.png'
import img2 from 'assets/tutorial2.png'
import img3 from 'assets/tutorial3.png'
import img4 from 'assets/tutorial4.jpg'
import clock from 'assets/clock.svg'
import play from 'assets/play_red.svg'
import styles from './Tutorials.module.scss';
import { openInNewTab, showModal } from 'helpers/uiFunctions';

const videos = [
  {
    title:"Easily create Faith-Journals activities with videos and images",
    duration:3,
    image:img1,
    link:"https://youtu.be/IYDnjvOj2T4"
  },
  {
    title:"Adding students in Faith Journals",
    duration:4,
    image:img2,
    link:"https://youtu.be/0CEOGuOTt1E"
  },
  {
    title:"Faith Journals Student View",
    duration:2,
    image:img3,
    link:"https://youtu.be/PMfrSrfQKv8"
  },
  {
    title:"Faith Journals Overview",
    duration:6,
    image:img4,
    link:"https://youtu.be/eSP7s3AivFg"
  },
]

const Tutorials = ({modalOverlay}) => {

  const triggerModal = url => showModal(modalOverlay, {type: 'videoPlayer', url, width:"100%"})
  

  return (
    <div className={styles.container}>
      <div className={`${styles.inner}`}>
        <h1>Faith Journals tutorials videos</h1>
        <div className={`${styles.links}`}>
          {
            videos.map((i, idx) => 
              <div key={idx} onClick={()=>triggerModal(i.link)} className={`${styles.linkBlock}`}>
                <div className={`${styles.imgWrapper}`}>
                  <img className={`${styles.preview}`} src={i.image} alt="video preview" />
                  <img className={`${styles.play}`} src={play} alt="play" />
                </div>
                <div className={`${styles.text}`}>
                  <h2>{i.title}</h2>
                  <div className={`${styles.bottom}`}>
                    <div className={`${styles.watchBtn}`}>
                      Watch tutorial
                    </div>
                    <div className={`${styles.duration}`}>
                      <img src={clock} alt="clock" />
                      <p>{i.duration} min</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )

}

export default Tutorials;