import React, { useEffect , useState, useRef } from 'react';
import styles from './ProfileSettings.module.scss';
import loader from 'assets/loader-sm2.svg';
import spinner from 'assets/spinner-white-sm.svg';
import { getFromApi, sendToApi } from 'API/generalCalls';
import { CountryDropdown, RegionDropdown  } from 'react-country-region-selector';

const EditProfile = () => {

  const btnRef = useRef()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const displayNameRef = useRef()
  const phoneNumberRef = useRef()
  const address1Ref = useRef()
  const address2Ref = useRef()
  const cityRef = useRef()
  const countryRef = useRef()
  const zipRef = useRef()
  const [ apiRes, setApiRes ] = useState({})
  const [ btnStage, setBtnStage ] = useState('unedited')


  useEffect(()=>{
    const callAPI = async () => { setApiRes(await getFromApi('account/info')) };
    callAPI()
  },[])


  const handleSubmit = () => {
    sendToApi('account/info/update', apiRes)
    btnRef.current.classList.add(styles.revert)
    setBtnStage('saving')
    setTimeout(()=>{
      setBtnStage('conf')
    },2500)
    setTimeout(()=>{
      // setBtnStage('init')
      btnRef.current.classList.remove(styles.revert)
    },3500)
  }
  
  const inputs = Array.from(document.querySelectorAll('input'));
  [...inputs].forEach(input=>input.addEventListener('keyup', function(e){
    setBtnStage('init')
  }))



  return (
    <div className={styles.innerContainer}>
      {
        !apiRes ? 

          <div className={styles.loaderDiv}>
            <img src={loader} alt="loader" />
          </div>

        :

          <>
        
          <h5>PERSONAL INFORMATION</h5>
          <label>First name</label>
          <input ref={firstNameRef} type="text" name="firstName" id="firstName" defaultValue={apiRes.first_name} onChange={(e)=>{setApiRes({...apiRes, first_name:e.target.value})}}/>
          <label>Last name</label>
          <input ref={lastNameRef} type="text" name="lastName" id="lastName" defaultValue={apiRes.last_name} onChange={(e)=>{setApiRes({...apiRes, last_name:e.target.value})}}/>
          <label>Display name</label>
          <input ref={displayNameRef} type="text" name="displayName" id="displayName" defaultValue={apiRes.display_name} onChange={(e)=>{setApiRes({...apiRes, display_name:e.target.value})}}/>

          <h5>CONTACT INFORMATION</h5>
          <label>Phone number</label>
          <input ref={phoneNumberRef} type="tel" name="phoneNumber" id="phoneNumber" defaultValue={apiRes.phone_number} onChange={(e)=>{setApiRes({...apiRes, phone_number:e.target.value})}}/>

          <h5>MAILING ADDRESS</h5>
          <label>Address line 1</label>
          <input ref={address1Ref} type="text" name="add1" id="add1" defaultValue={apiRes.address_1} onChange={(e)=>{setApiRes({...apiRes, address_1:e.target.value})}}/>
          <label>Address line 2</label>
          <input ref={address2Ref} type="text" name="add2" id="add2" defaultValue={apiRes.address_2}  onChange={(e)=>{setApiRes({...apiRes, address_2:e.target.value})}}/>
          <label>City/Town</label>
          <input ref={cityRef} type="text" name="city" id="city" defaultValue={apiRes.city}  onChange={(e)=>{setApiRes({...apiRes, city:e.target.value})}}/>
          <label>Country</label>
          <CountryDropdown
          value={apiRes.country_name}
          onChange={(val) => {setApiRes({...apiRes, country_name:val}); setBtnStage('init')}} />

            {
              apiRes.country_name === 'United States' &&
              <>
                <label>State</label>
                <RegionDropdown
                defaultOptionLabel={"Select state"}
                country={apiRes.country_name}
                value={apiRes.state_name}
                onChange={(val) => {setApiRes({...apiRes, state_name:val}); setBtnStage('init')}} />
              </>
            }

          <label>Zip code</label>
          <input ref={zipRef} type="text" name="zip" id="zip"  defaultValue={apiRes.zip}  onChange={(e)=>{setApiRes({...apiRes, zip:e.target.value})}}/>

          <div ref={btnRef} className={btnStage === 'unedited' ? `${styles.btn} ${styles.unedited}` : styles.btn} onClick={handleSubmit}>

            {
              btnStage==='saving' ? 
               <img src={spinner} alt="spinner" /> :
                
              btnStage==='conf' ?
              'Profile updated!' : 
          
              'Update profile'
            }

          </div>

          </>
      }
    </div>
  )

}

export default EditProfile;



          {/* <input ref={countryRef} type="text" name="Country" id="Country" defaultValue={apiRes.country}/> */}