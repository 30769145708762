import React from 'react';
import { useState } from 'react';

import styles from './OnboardingModal.module.scss';
import inPerson_img from 'assets/icon_onboarding_person.svg'
import remote_img from 'assets/icon_onboarding_remote.svg'
import plus_icon from 'assets/plus_blue.svg'
import chevron from 'assets/chevron_black.svg'
import deleteIcon from 'assets/icon-bin_grey.svg'
import spinner from 'assets/loader-dots.svg'
import { useEffect } from 'react';
import { useRef } from 'react';
import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import { addAnimation } from '../../../helpers/uiFunctions'
import CopyPill from 'components/UtilityComps/CopyPill/CopyPill';
import { getCsv } from 'API/TeacherCallFunctions/csvGen';
import ResponsiveInfoInput from './ResponsiveInfoInput';
import { idxClass } from 'helpers/idxClass';
import { useUserType } from 'context/AltPlatformProvider';

const OnboardingModal = ({ closeBtn, item: { updateStudents, classId } }) => {

  const [ stage, setStage ] = useState(1)
  const [ type, setType ] = useState('')
  const [ students, setStudents ] = useState([{}])
  const [ availableClasses, setAvailableClasses ] = useState(null)
  const [ selectedClasses, setSelectedClasses ] = useState([classId])
  const [ error, setError ] = useState(false)
  const [ apiRes, setApiRes ] = useState(null)
  
  const dropRef = useRef()
  const contRef = useRef()

  const { synodUser } = useUserType()

    
  const getClasses = async () => { 
    const data = await getFromApi('classes');
    setAvailableClasses(data)
  }

  useEffect(()=>{
    getClasses()
    return () => setStudents([{}])
  },[])


  useEffect(()=>{
    if(type)setStage(2)
  },[type])

  useEffect(()=>{
    if(type)setStage('confirmed')
  },[apiRes])

  const handleDrop = () => {
    dropRef.current.classList.toggle(styles.open)
  }

  const handleType = () => {
    setType(type === "inPerson" ? "remote" : "inPerson")
  }

  const addOneEmptyStudent = (e) => {
    // when the user starts typing the first name, add an extra line to the table
    if (students[students.length-1]['firstName'] !== undefined){
      setStudents([...students, {} ])
    }
  }

  const handleInput = (key, value, index) => {
    setError(false)
    let newState = [...students]
    newState[index][key]=value
    setStudents(newState)
  }

  const handleDelete = (idx) => {
    if(students.length===1) return setStudents([{}])
    setStudents([...students.slice(0, idx), ...students.slice(idx+1)])
  }
      
  const handleCheckbox = (e) => {
    
    setError(false)
    let classesCopy = [...selectedClasses]

    if(e.target.checked){
      classesCopy.push(e.target.value)
      setSelectedClasses(classesCopy)
    } else {
      const index = classesCopy.indexOf(e.target.value)
      classesCopy.splice(index, 1)
      setSelectedClasses(classesCopy)
    }
  }

  const handleSubmit = async () => {

    const payload = {
      type,
      students,
      classes: selectedClasses
    }

    const checkKey = (key) => {
      return !key.firstName || !key.lastName || (type==="remote"&&!key.email&&!key.parentEmail)
    }

    // only check for the elements in the array exept the last empty one
    const allStudentsNotLastEmpty = students.slice(0, -1)
    if (allStudentsNotLastEmpty.some(checkKey)){ 
      addAnimation(contRef, 'shake', 500)
      if(type==="remote")
        return setError("Please ensure to provide a name, last name and at least one email for each student")
      if(type==="inPerson")
        return setError("Please ensure to provide a name and last name for each student")
    }
    
    if (!selectedClasses.length){ 
      addAnimation(contRef, 'shake', 500)
      return setError("Please select at least one class")
    }

    setStage(3)
    const newStudents = await sendToApiReturn(`students/invite`, payload)
    setApiRes(newStudents)
    setTimeout(()=>{ updateStudents(newStudents) },1000)

  }

  const handleCsv = () => {
    const classObj = availableClasses.find(i => i.classId===parseInt(selectedClasses[0]))
    getCsv(`teachers/class/${classObj.classId}/logins_csv`, classObj.className)
  }

  return !students ? null : (
    <div ref={contRef} className={styles.container}>
      <h1 className={stage !== 1 && styles.hideRes}>
        {stage !== "confirmed" ? "Create new student accounts" : "Students Created"}
      </h1>
      {
        stage === 1 ?  // INITIAL STAGE

          <div className={styles.typeRadios}>

            <div onClick={()=>setType('inPerson')} className={`${styles.optionDiv}`}>
              <img src={inPerson_img} alt="in person" />
              <h2>Onboarding without email</h2>
            </div>

            <div onClick={()=>setType('remote')} className={`${styles.optionDiv}`}>
              <img src={remote_img} alt="remote" />
              <h2>Onboarding with email</h2>
            </div>

          </div>

      : stage === 2 ?  // INPUT STAGE

          <div className={styles.main}>

            <div onClick={handleDrop} onMouseLeave={()=>dropRef.current.classList.remove(styles.open)} className={styles.selectedType}>
              <img className={styles.icon} src={type==="inPerson"?inPerson_img:remote_img} alt="img" />
              <h5>{type==="inPerson"?"In-person":"Remote"} Onboarding</h5>
              <img className={styles.chevron} src={chevron} alt="chevron" />
              <div onClick={handleType} ref={dropRef} className={styles.typeDrop}>
                <img className={styles.icon} src={type!=="inPerson"?inPerson_img:remote_img} alt="img" />
                <h5>{type!=="inPerson"?"In-person":"Remote"} Onboarding</h5>
                <img className={styles.chevron} src={chevron} alt="chevron" />
              </div>
            </div>

            <div className={styles.line} />

            <ResponsiveInfoInput
              students={students}
              setStudents={setStudents}
              type={type} />

            <div className={styles.hideRes}>
              <div className={styles.infoBar}>
                <h2>{!synodUser ? "Student" : "Particpant"} Info</h2>
                <div className={styles.details}>
                  {
                    type==="inPerson" ?

                      <p>A list of login codes will be generated after creating the users</p>
                    :
                      <>
                        <p>Users will receive an access link to the provided emails</p>
                        <p className={styles.hideRes}>At least one email should be written to send the link</p>
                      </>
                  }
                </div>
              </div>

                <div className={styles.inputTable}>
                  <div className={styles.tableHeader}>
                    <div className={styles.column}>First Name</div>
                    <div className={styles.column}>Last Name</div>
                    <div className={styles.column}>Email{type==="inPerson"&& <span>(optional)</span>}</div>
                    {!synodUser && <div className={styles.column}>Parent Email{type==="inPerson"&& <span>(optional)</span>}</div>}
                  </div>
                  <div className={styles.tableBody}>
                    {
                      students.map((student,idx)=>
                        <div key={idx} className={styles.tableRow}>
                          <div className={styles.column}>
                            <input value={students[idx].firstName ? students[idx].firstName : ""} 
                                   onKeyUp={(e)=>addOneEmptyStudent(e)} 
                                   onChange={e=>handleInput("firstName", e.target.value, idx)} 
                                   placeholder={idx === 0 ? "Student 1 name" : ""}
                                   autoFocus={idx===0} type="text"/>
                          </div>
                          
                          <div className={styles.column}>
                            <input value={students[idx].lastName ? students[idx].lastName : ""} 
                                   onChange={e=>handleInput("lastName", e.target.value, idx)} 
                                   type="text"/>
                          </div>

                          <div className={styles.column}>
                            <input value={students[idx].email ? students[idx].email : ""} 
                                   onChange={e=>handleInput("email", e.target.value, idx)} 
                                   type="text"/>
                          </div>
                          
                          <div className={styles.column}>
                            <input value={students[idx].parentEmail ? students[idx].parentEmail : ""} onChange={e=>handleInput("parentEmail", e.target.value, idx)} type="text"/>
                            {idx > 0 && idx !== (students.length - 1) && <img onClick={()=>handleDelete(idx)} src={deleteIcon} alt="delete" className={styles.delete} />}
                          </div>
                  
                        </div>
                      )
                    }
                  </div>
                </div>
            </div>

            <h3>Select the group/s where you want to add the new students</h3>
            <div className={styles.classCheckboxes}>
              {
                !availableClasses ? 
                  <h5>loadingClasses</h5>
                  :
                  availableClasses.map((item, idx) => 
                    <label key={idx}>
                      <input onClick={e=>handleCheckbox(e)} checked={selectedClasses.includes(String(item.classId))} type="checkbox" name="classes" value={item.classId}/>
                      <div className={styles.classDiv}>
                        <div className={`${styles.oval} ${styles[idxClass(item.iconKey)]}`} />
                        {item.className}
                      </div>
                    </label>
                  )
              }
            </div>
              
              {error ? 
                <h6 className={styles.error}>{error}</h6>
              :
                
              <div className={styles.errorPlaceholder} />
              
              }
              <div className={styles.buttons}>
                <div onClick={()=>closeBtn.current.click()} className={styles.cancelBtn}>Cancel</div>
                <div onClick={handleSubmit} className={styles.submitBtn}>Create Students</div>
              </div>

          </div>

        : // <---------------- CONFIRMATION STAGE

           <div className={styles.confDiv}>
             {
               !apiRes ? 
                <>
                  <img src={spinner} alt="loader" />
                  <h6>Creating accounts</h6>
                </>

              : 
                <>
                  <h4>Students have been emailed a login link</h4>
                  <div className={styles.confTable}>
                    <div className={styles.tableHeader}>
                      <div className={styles.column}>Name</div>
                      <div className={styles.column}>Last Name</div>
                      <div className={styles.column}>Username</div>
                      <div className={`${styles.column} ${styles.hideRes}`}>Login Code</div>
                      <div className={`${styles.column} ${styles.hideRes}`}>Parent Email</div>
                    </div>
                    <div className={styles.tableBody}>
                      {
                        apiRes.map((item,idx)=>
                          <div key={idx} className={styles.tableRow}>
                            <div className={styles.column}>{item.studentName?.split(' ')[0]}</div>
                            <div className={styles.column}>{item.studentName?.split(' ')[1]}</div>
                            <div className={styles.column}>{item.studentEmail}</div>
                            <div className={`${styles.column} ${styles.hideRes}`}><CopyPill copyData={item.loginCode} /> </div>
                            <div className={`${styles.column} ${styles.hideRes}`}>
                              {
                                item.parentEmail.length ?
                                  <p className={styles.emailSent}>Login code access sent <span /> </p>
                              : 
                                  <p>N/A</p>
                              } 
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div onClick={handleCsv} className={styles.downloadBtn}>Download student list</div>
                </>

             }
           </div>
      }

    </div>
  )

}

export default OnboardingModal;