import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './RemoveModal.module.scss';
import { updateClassKey, updateActivityKey, addActivityData, updateDashData } from 'Redux/actions';
import { sendToApi, sendToApiReturn } from 'API/teacherCalls';
import { deleteIndexFromArray } from 'helpers/deleteIndexFromArray';
import spinner from 'assets/spinner_teal.svg';

const RemoveModal = ({item:{ variant, source, index, func, activity, classId, newArray, classObj, activityId, rubric, resourceItems, setResourceItems, classes, studentId, students, guardians, setGuardians, studentArray, payload, teachers, }, closeBtn}) => {

  const dispatch = useDispatch();
  const dashData = useSelector(state => state.dashData)
  const revisedStyle = ['deleteTeacher'].indexOf(variant) !== -1;
  const [ loading, toggleLoading ] = useState(false)

  const handleOk = async () => {

    toggleLoading(true)
    
    if (variant==='deleteClass'){ //<----------------------------------------------------------------CASE

      sendToApiReturn(`class/${classId}/delete`);

      if(source === 'dash') {
        //delete active class
        dispatch(updateDashData('classes', deleteIndexFromArray(dashData.classes, index)))
        // localStorage.setItem('dashRecall', true)
        func('remove')

      } else {
        //delete archived class
        console.log('here')
        dispatch(updateDashData('archivedClasses', deleteIndexFromArray(dashData.archivedClasses, index)))
        
      }

  } else if (variant==='student'){ //<--------------------------------------------------------------CASE

    
    const newArray = await sendToApiReturn(`class/${classObj.classId}/delete_students`, {studentIds:studentArray}) 
    func(newArray)
    localStorage.setItem('dashRecall', true)


  } else if (variant==='multipleStudents'){//<------------------------------------------------------CASE
    
    let updatedArray = [...students];
    const idArray=[];
    newArray.forEach(item => {
      const index = updatedArray.map(i => i.studentId).indexOf(item.studentId);
      updatedArray.splice(index, 1)
      idArray.push(item.studentId)
    })

    console.log(updatedArray)
    dispatch(updateClassKey('students', updatedArray))
    const check = document.querySelectorAll("[data-key='box']");
    check.forEach(item =>{item.checked=false})

    sendToApi(`class/${classObj.classId}/delete_students`, {studentIds:idArray}) 
    setTimeout(()=>{ window.location.reload()},400)

    
  } else if (variant ==='deleteActivity'){//<------------------------------------------------------CASE
    func()
    localStorage.setItem('dashRecall', true)

  }else if (variant==='disableGrading'){//<--------------------------------------------------------CASE
    
    sendToApiReturn(`activity/${activity.activityId}/set_rubric/${0}`)
    dispatch(addActivityData({...activity, activityGradingType:'', grading:0, shareGrading:false}))
    
  }else if (variant==='changeRubric'){//<----------------------------------------------------------CASE
    
      const rubricNumber = (
        rubric==='Basic' ? 1 :
        rubric==='Argument/Opinion' ? 2 :
        rubric==='Information & Explanatory' ? 3 :
        rubric==='Narrative' ? 4 : 1
      )
    
    sendToApiReturn(`activity/${activityId}/set_rubric/${rubricNumber}`)
    dispatch(addActivityData({...activity, activityGradingType:rubric}))

  } else if (variant === 'resourceItem'){//<-------------------------------------------------------CASE
    const ind = resourceItems.findIndex(i => i.activityId === activityId)
    setResourceItems([...resourceItems.slice(0, ind), ...resourceItems.slice(ind+1)])
    sendToApi(`activity/${activityId}/unshare`)

  }else if (variant === 'guardianMail'){//<--------------------------------------------------------CASE
    sendToApi(`remove_invite/${studentId}?email=${guardians[index]}`)
    setGuardians([...guardians.slice(0, index), ...guardians.slice(index+1)])
    
  } else if (variant==='removeTeacher'){ //<--------------------------------------------------------------CASE
    sendToApi(`class/${classId}/remove_teacher?email=${payload}`)
    func([...teachers.slice(0, index), ...teachers.slice(index+1)])

  }else if (variant==='deleteSection'){ //<--------------------------------------------------------------CASE
    
    const data = await sendToApiReturn(`activity/${activityId}/delete_unit/${index}`)
    dispatch(updateActivityKey('sections', data))
    func()

  }else if (variant==='deleteTeacher'){ //<--------------------------------------------------------------CASE
    
    func()
  }
  
    closeBtn.current.click();
  }

  return (
    <div className={`${styles.container} ${revisedStyle && styles.revised}`}>

      {variant==="notShareable"? 

        <h1>Oops...</h1>
      :
        <h1>Are You Sure?</h1>

      }

      <div className={styles.warning}>
        {variant === 'student' || variant === 'multipleStudents' ?
          <p>The student(s) will be removed from your class, but not permanently deleted.</p>

        :variant === 'deleteTeacher' ?
        <p>This teacher will be removed from your class.</p>
        
        :variant === 'deleteClass' ?
        <p>This class will be permanently deleted.</p>
        
        :variant === 'deleteActivity' ?
        <p>This activity will be permanently deleted.</p>

        :variant === 'disableGrading' ?
        <p>All grading data for this activity will be permanently deleted.</p>

        :variant === 'changeRubric' ?
        <p>All grading data for the current rubric will be permanently deleted.</p>

        :variant === 'resourceItem' ?
        <p>The activity will be removed from the Resources page but will still be available to you.</p>

        :variant === "notShareable" ?
        <p>You cannot share an Escribli original storyStart.</p>

        :variant === "guardianMail" ?
        <p>The parent/guardian will be removed from this account.</p>
        
        :variant === "removeTeacher" ?
        <p>The teacher account(s) will be removed from your school but not permanently deleted.</p>

        : variant === 'studentAdmin' ?
          <p>The student will be removed from your school.</p>

        : variant === 'deleteSection' ?
          <p>This section will be permanently deleted.</p>
        
        : null
        }
      </div>

      <div className={styles.buttons}>
        {
          variant==="notShareable" ? 
          <div onClick={handleOk} className={styles.okBtn}>Accept</div>

          :

          <>
          <div onClick={handleOk} className={`${styles.okBtn} ${loading && styles.loading}`}>{!loading?"Accept": <img src={spinner} />}</div>
          <div onClick={()=>{ closeBtn.current.click()}}  className={styles.cancelBtn}>Cancel</div>
          </>

        }
      </div>
    </div>
  )
}

export default RemoveModal;