import React,{useState, useEffect, useRef} from 'react';

import styles from './DuplicateClassModal.module.scss';
import { getFromApi, sendToApi, sendToApiReturnFull } from 'API/teacherCalls';
import loader from 'assets/loader-sm2.svg'
import spinner from 'assets/Spinner.svg'


const DuplicateClassModal = ({ closeBtn, item:{classId, activityId}}) => {
  
  const loaderRef = useRef();
  const [ availableClasses, setAvailableClasses ] = useState([])
  const [ selectedClasses, setSelectedClasses ] = useState([])
  const [ response, setResponse ] = useState('initial');
  
  const getClasses = async () => { 
    const data = await getFromApi('classes');
    setAvailableClasses(data)
  }
  
  useEffect(()=>{getClasses()},[])

  useEffect(()=>{ 
    if(response===200){setTimeout(()=>{closeBtn.current.click()},3000)} 

    // eslint-disable-next-line
},[response])
  
  const handleOk =  async () => {

    if(selectedClasses.length){
  
      loaderRef.current.classList.add(styles.show)
  
      const payload = {classId:selectedClasses}
  
      if(availableClasses.length){

        const {status} = await sendToApiReturnFull(`activity/${activityId}/duplicate`, payload)
        setResponse(status)
      }
    }
  }

    
  const handleCheckbox = (e) => {
    
    let classesCopy = [...selectedClasses]

    if(e.target.checked){
      classesCopy.push(e.target.value)
      setSelectedClasses(classesCopy)
    } else {
      const index = classesCopy.indexOf(e.target.value)
      classesCopy.splice(index, 1)
      setSelectedClasses(classesCopy)
    }
  }

  return (
    <div className={styles.container}>
      <div ref={loaderRef} className={styles.loaderDiv}>
        {
          response==='initial' ? 
          <div>
            <img src={loader} alt="loader" />
            <h4>Duplicating...</h4>
          </div>
          : response=== 200 ? 
            <h3>Activity successfully duplicated!</h3>
          :
            <h4>An error occured</h4>
        }
      </div>

    <h2>Duplicate Assignment</h2>
    <p>Duplicate this assignment and its accompanying settings (which can be modified later from class-specific dashboard) for the following class(es)?</p>
      <div className={styles.classBlocks} onChange={(e) => { handleCheckbox(e) }}>
        {!availableClasses.length ? 
        
          <div>
          	<h4>loading classes...</h4>
          	<img src={spinner} alt="spinner"/>
          </div>
        :
        <React.Fragment>
        {
          availableClasses.map((item, index) => {
            return (
              <>
                {item.classId === parseInt(classId) ?
                  null
                  :
                  <label key={index}>
                    <input type="checkbox" value={item.classId} />
                    <div className={styles.classBlock}>
                      <p>
                        {item.className}
                      </p>
                    </div>
                  </label>
                }

              </>
            )
          })
        }
        </React.Fragment>
        }
      </div>

      <div className={styles.buttons}>
        <div onClick={handleOk} className={styles.okBtn}>OK</div>
        <div onClick={() => { closeBtn.current.click() }} className={styles.cancelBtn}>Cancel</div>
      </div>
    </div>
  )

}

export default DuplicateClassModal;