import React, { useState } from 'react'
import { BiSolidUpArrow, BiSolidDownArrow } from 'react-icons/bi';

import styles from './ReportTable.module.scss'
import { useUserType } from 'context/AltPlatformProvider';

import bookreport from "./bookreport.svg"
import dash from './remove_circle_outline_black_24dp 8.svg'
import check from './check_circle_outline_black_24dp.svg'

const ReportTable = ({data}) => {
  const [collapsedSections, setCollapsedSections] = useState([]);
  const {fjUser} = useUserType()

  const toggleSection = (activityIndex) => {
    if (collapsedSections.includes(activityIndex)) {
      setCollapsedSections(collapsedSections.filter(index => index !== activityIndex));
    } else {
      setCollapsedSections([...collapsedSections, activityIndex]);
    }
  };



  const getNumberBorder = (number) => {
    if (number >= 1.0 && number <= 2.0) {
      return `${styles.red}`;
    } else if (number >= 2.1 && number <= 3.5) {
      return `${styles.orange}`;
    } else if (number >= 3.6 && number <= 5) {
      return `${styles.green}`;
    } else {
      return "";
    }
  };

  const getNumberText = (number) => {
    if (number) {
      return number.toString();
    } else {
      return "N/A";
    }
  };



  return (
    <div className={styles.container}>
      {
        data.map((report, index) => {
          return (
            <div key={index}>
              <div className={styles.header}>
                <img src={bookreport} alt="bookreport" />
                <h4 onClick={() => toggleSection(index)}>
                  {report.activityName.substring(0, 28)}...
                  <div>
                    {collapsedSections.includes(index) ? <BiSolidUpArrow /> : <BiSolidDownArrow />}
                  </div>
                </h4>
              </div>
              <div>
                {
                  report.sections.map((item, i) => {
                    const statusClass = 
                    item.entryStatus === 'approved' ? 'approved' :
                    item.entryStatus === 'pending revision' ? 'pendingRes' :
                    item.entryStatus === 'submitted' ? 'submitted' :
                    item.entryStatus === 'started' ? 'started' :
                    item.entryStatus === 'not started' ? 'notStarted' : null;

                    return (
                      !collapsedSections.includes(index) && (
                        <div key={i} className={styles.tableHeader}>
                            <div className={`${styles.column} ${fjUser && styles.wide}`}>{item.sectionName}</div>
                            <div className={`${styles.column} ${fjUser && styles.wide}`}>{item.wordsCount || 0}</div>
                            <div className={`${styles.column} ${fjUser && styles.wide}`}>
                                     <div className={`${styles.statusDot} ${styles[statusClass]}`} ></div>
                            </div>
                            <div className={`${styles.column} ${fjUser && styles.wide}`}>
                            <div 
                                // onClick = {handleSlider}
                                // id={`switch${studentId}`}
                                className={`${styles.approvedSlider} ${item.approved && styles.approved}`}>
                                <div className={styles.handle} />
                                    <p className={styles.yes}>Yes</p>
                                    <p className={styles.no}>NO</p>
                                </div>
                            </div>
                            <div className={`${styles.column} ${fjUser && styles.wide}`}>
                                <div className={`${styles.voted} ${item.voted && styles.yes}`} />
                            </div>
                            <div className={`${styles.column} ${fjUser && styles.wide}`}>
                              <span className={` ${getNumberBorder(item.grade)}`}>
                               {getNumberText(item.grade)}
                              </span>
                            </div>
                        </div>
                      )
                    )
                   
                    })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ReportTable