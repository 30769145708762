import ClassActivityBlock from 'components/Teacher/ClassDetails/ClassActivityBlock/ClassActivityBlock';
import React from 'react';

import styles from './ActivityList.module.scss';

const ActivityList = ({ classData, modalOverlay }) => {

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.activities}>
          {
                        
            classData?.activities.map((item, idx) => 
              <ClassActivityBlock
                bordered
                synod={false}
                setListItem={()=>console.log("null")}
                archived={false}
                modalOverlay={modalOverlay}
                key={idx}
                idx={idx}
                classData={classData}
                setClassData={()=>console.log("null")}
                count={0}
                activity={item}
                handleArchive={()=>console.log("null")}
                handleDeleteActivity={()=>console.log("null")} />
            )
          }
        </div>
      </div>
    </div>
  )

}

export default ActivityList;