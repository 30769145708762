import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import styles from './ItemPreviewModal.module.scss';

const ItemPreviewModal = ({ item, item:{author, activityName, sections, activityId, storyStart }, selectItem, setPreviewItem }) => {

  const [ selectedIndex, setSelectedIndex ] = useState(0)
  const [ selectedSection, setSelectedSection ] = useState(sections[selectedIndex])

  useEffect(()=>{setSelectedSection(sections[selectedIndex])},[selectedIndex])

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={`${styles.topBar} ${storyStart && styles.story}`}>

          
          <div className={`${styles.titleRow} ${storyStart && styles.story}`}>

            <div className={styles.rocketDiv} />
            <p className={styles.start}>storystart</p>

            <div onClick={()=>setPreviewItem(null)} className={styles.close} />

            <h3>{activityName}</h3>
            <p>By {author}</p>
          </div>
          <div className={styles.sections}>
            {
              sections.map((i,idx) => 
                <div onClick={()=>setSelectedIndex(idx)}
                  className={`${styles.sectionDiv} ${selectedIndex === idx && styles.checked}`}><p>{idx+1}</p></div>
              )
            }
          </div>
          
        </div>

        <div className={styles.instructionsDiv}>
          <ReactQuill
            readOnly
            modules={{toolbar:false}}
            value={selectedSection.instructions||""} />

        </div>

        <div onClick={()=>selectItem(activityId, item)} className={styles.selectBtn}>
          select this {storyStart ? 'StoryStart' : 'item'}
        </div>
      </div>
    </div>
  )

}

export default ItemPreviewModal;