import React,{useEffect, useRef, useState} from 'react';

import styles from './AddResourceModal.module.scss';
import { useSelector } from 'react-redux';
import sent from 'assets/sent_plane.svg';
import { sendToApi } from '../../../../API/teacherCalls'
import ReactQuill from 'react-quill';
import { addAnimation } from 'helpers/uiFunctions';
import { Link } from 'react-router-dom';

const AddResourceModal = ({item:{activity:{activityType, activitySubject, activityId, activityName, sections, additionalComments, includeAttachments}}, closeBtn}) => {

  const confDivRef = useRef();
  const gradeRef = useRef();
  const genreRef = useRef();
  const [ vis, setVis ] = useState({error:false, conf:false})
  const dashData = useSelector(state => state.dashData)
  const reduxActivity = useSelector(state => state.activityData)
  const fjUser = useSelector(state => state.dashData.simplifiedActivity)
  const [ shareData, setShareData ] = useState(JSON.parse(localStorage.getItem(`${activityId}-resourceItem`)) || {
    activityName: activityName, 
    sections: sections, 
    activityType:activityType, 
    activitySubject:activitySubject, 
    shareGrade:[2,3,4,5,6,7,8,9,10,11,12], 
    additionalComments:additionalComments || "", 
    includeAttachments:includeAttachments || [],
    author: dashData.name,
    imageUrl: dashData.imageUrl
  });

  useEffect(()=>{
    if(shareData)setShareData({...shareData, shareGrade:[], activitySubject:shareData.activityType==='story'?null:activitySubject})
  },[shareData.activityType])

  const handleCommentsChange = val => setShareData({...shareData, additionalComments:val})

  const handleCheckbox = input => setShareData({...shareData, activityType:input})

  const handleGradeRadio = grade => {
    setVis({...vis, error:false})
    const selected = shareData.shareGrade
    console.log(grade)
    console.log(selected)
    if(grade === 0){
      setShareData({...shareData, shareGrade: selected.length==11? [] : [2,3,4,5,6,7,8,9,10,11,12]})
    }
    else{
      if(selected.includes(grade+1)){
        const idx = (selected.findIndex(i=>i===grade+1))
        setShareData({...shareData, shareGrade: [...selected.slice(0,idx), ...selected.slice(idx+1)] })
        
      } else {
        setShareData({...shareData, shareGrade: [ ...selected, grade+1 ] })
        
      }
    }
  }

  const handleInclude = () => {
    const newData = shareData.includeAttachments.includes('uploadedImgs') ? [] : ['uploadedImgs']
    setShareData({...shareData, includeAttachments: newData})
  }

  const handleSubmit = () => {
      if(shareData.activityType==='story' && shareData.activitySubject===null){
        addAnimation(genreRef, 'shake', 400)
        return setVis({...vis, error:'Please select a genre'})
      }
      if(shareData.activityType==='story' && !shareData.shareGrade.length){
        addAnimation(gradeRef, 'shake', 400)
        return setVis({...vis, error:'Please select at least one grade'})
      }
      setVis({...vis, conf:true})
      setTimeout(()=>{confDivRef.current.classList.add(styles.show)},200);
      let resArray = !localStorage.getItem('sharedResources') ? [] : JSON.parse(localStorage.getItem('sharedResources'));
      resArray.push(shareData)
      localStorage.setItem('sharedResources', JSON.stringify(resArray))
      localStorage.setItem(`${activityId}-resourceItem`, JSON.stringify(shareData))
      sendToApi(`activity/${reduxActivity.activityId}/share`,shareData);
  }

  const subjectItems = [
    {text:'ELA', value:"ELA"},
    {text:'Science', value:'Science'},
    {text:'Maths', value:'Mathematics'},
    {text:'Poetry', value:'Poetry'},
    {text:'History', value:'History-Social Studies'},
    {text:'Other', value:'Other'},
  ]

 
  const genreItems = [
    {text:'Action & Adventure', value:'Action & Adventure'},
    {text:'Fantasy',value:'Fantasy'},
    {text:'Humor', value:'Humor'},
    {text:'Mystery',value:'Mystery'},
    {text:'Myths and Monsters',value:'Myths and Monsters'},
    {text:'Romance',value:'Romance'},
    {text:'Science Fiction',value:'Science Fiction'},
    {text:'Suspense & Thrillers',value:'Suspense & Thrillers'},
    {text:'Realistic', value:'Realistic Stories'},
    {text:'Historical Fiction', value:'Historical Fiction'},
    {text:'Sports', value:'Sports Stories'}
  ]





  return (
    <div className={(!vis.conf ? `${styles.container} ${fjUser&&styles.simplified}` : `${styles.container} ${styles.confirm}`)}>

      {!vis.conf ? 
      <>
        <h1>Share activity in Teacher Resources</h1>
        <p className={styles.subtitle}>This activity will be shared on the resources section located in the navbar in order to be used and help other teachers</p>

        <section className={styles.inputRow}>
          <div className={styles.authorDiv}>
            <h3>Author</h3>
            <input readOnly type="text" name="author" defaultValue={dashData ? dashData.name : 'name'}/>
          </div>

          {
            !fjUser &&

              <div className={styles.typeDiv}>
                <h3>Activity type</h3>
                <div className={styles.radiosDiv}>
                  <label onClick={()=>handleCheckbox('assignment')} >
                    <input checked={shareData.activityType==='assignment'} type="checkbox" name="activityType" />
                    <div className={styles.checkboxContainer}>
                      <div className={styles.checkbox} />
                    </div>
                    <p className={styles.labelText}>Assignment</p>
                  </label>
                  <label onClick={()=>handleCheckbox('story')} >
                    <input checked={shareData.activityType==='story'} type="checkbox" name="activityType" />
                    <div className={styles.checkboxContainer}>
                      <div className={styles.checkbox} />
                    </div>
                    <p className={styles.labelText}>Story</p>
                  </label>
                  <label onClick={()=>handleCheckbox('journal')} >
                    <input checked={shareData.activityType==='journal'} type="checkbox" name="activityType" />
                    <div className={styles.checkboxContainer}>
                      <div className={styles.checkbox} />
                    </div>
                    <p className={styles.labelText}>Journal</p>
                  </label>
                </div>
              </div>
              
          }
        </section>

        { 
          !fjUser &&

            shareData.activityType !== 'story' ?

              <section className={styles.subjectDiv}>
                <h3>Subject</h3>
                <div className={styles.subjectRadios} onChange={(e)=>{setShareData({...shareData, activitySubject:e.target.value})}}>
                  {
                    subjectItems.map((i,idx) => 
                      <label key={idx}>
                        <input
                          type="radio"
                          name="activitySubject"
                          value={i.value}
                          defaultChecked={activitySubject === i.value ? true : false} />
                        <div className={styles.checkboxContainer}>
                          <div className={styles.checkbox} />
                        </div>
                        <p className={styles.labelText}>{i.text}</p>
                      </label>
                    )
                  }
                </div>
              </section>

            :
              <>
                {
                /* <h3>Genre</h3>
                <div ref={genreRef} onChange={e=>{setShareData({...shareData, activitySubject:e.target.value}); setVis({...vis, error:false})}} className={styles.genreRadios}>
                  {
                    genreItems.map((item,idx) => 
                      <label>
                        <input value={item.value} type="radio" name="genre"/>
                        <div key={idx} className={styles.genrePill}>{item.text}</div>
                      </label>
                    )
                  }
                </div> */
                }

                <h3>Grades</h3>
                <div ref={gradeRef} className={styles.gradeRadios}>
                  {
                    [...Array(12)].map((num, idx) => 
                      <label key={idx}>
                        <input onClick={()=> handleGradeRadio(idx)} checked={ (idx===0&&shareData.shareGrade.length===11)|| shareData.shareGrade.includes(idx+1)} type="checkbox" name="grades"/>
                        <div className={styles.gradeRadio}>
                          {idx===0?'All grades' : idx+1}
                        </div>
                      </label>
                    
                    )
                  }
                </div>

              </>
        }

        <section className={styles.additionalDiv}>
          <h3>Additional comments (optional)</h3>
          <div className={`${styles.editorDiv} shareResourceModal`}>
            <ReactQuill
              onChange={val => handleCommentsChange(val)}
              className={`${styles.textEditor}`}
              modules={{toolbar:false}}
              value={shareData.additionalComments || ""} />
          </div>
        </section>

        <div className={styles.bottomRow}>
            <div onClick={handleInclude} className={styles.includeAttachments}>
              <input checked={true} type="checkbox" name="include" id="" />
              <div className={styles.checkboxContainer}>
                <div className={styles.checkbox} />
              </div>
              <label>Include Attachments </label>
            </div>

          <div onClick = {handleSubmit} className={styles.bottomBtn}> Share in School Resources 
          {vis.error ? <h4 className={styles.error}>{vis.error}</h4> : null}
        </div>

        </div>


      </>
      :
        <div ref={confDivRef} className={styles.confDiv}>
          <img src={sent} alt="sent!" />
          <h1>Your activity has been shared!</h1>
          {/* <p className={styles.sml}>Now you take a look at it on the navbar and invite other teachers to use it</p> */}
          <div className={styles.btns}>
            <Link to={'/teacher/resources'} className={styles.goBtn}>Go to resources</Link>
            <div onClick={()=>{closeBtn.current.click()}} className={styles.closeBtn}>Got it</div>
          </div>
        </div>
      }
    </div>
  )

}

export default AddResourceModal;