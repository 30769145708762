import { sendToApi } from 'API/parentCalls';
import React from 'react';

import styles from './ConnectYourChildModal.module.scss';

const YourChild = ({ apiRes, setApiRes, contRef, setContState, childCode }) => {

  const gradeText = (grade) => {

    if(grade === 1) return '1st grade'
    if(grade === 2) return '2nd grade'
    if(grade === 3) return '3rd grade'

    return `${grade}th grade`
  }


  const handleReset = () => {
    setApiRes({})
    setContState('init')
    contRef.current.classList.remove(styles.yourChild);
    contRef.current.classList.add(styles.init)
  }
  
  const handleConfirm = () => {
    sendToApi('add_student', {code:childCode}, 'addStudent', {setApiRes:setApiRes, apiRes:apiRes});
  }

  return (
    <>
      <h1>Is this your child?</h1>
      <div className={styles.main}>
        <div className={styles.boomer}>
          <img src={apiRes.boomerUrl} alt="boomer" />
        </div>
        <h2>{apiRes.studentName} - {gradeText(apiRes.grade)}</h2>
      </div>
      <div className={styles.btns}>
        <div onClick={handleConfirm} className={styles.yesBtn}>This is my child</div>
        <div onClick={handleReset} className={styles.noBtn}>This isn't my child</div>
      </div>
    </>
  )

}

export default YourChild;