import React, { useState, useRef, useEffect } from 'react';
import { getFromApi } from 'API/adminCalls';
import styles from './ManageStudents.module.scss';
import { testStudent } from '../../../../helpers/filterFunctions';
import spinner from 'assets/Spinner.svg'
import { statusDot } from 'helpers/uiFunctions';
import StudentRow from './StudentRow';

const ManageStudents = ({ modalOverlay }) => {
  const [ filterVals, setFilterVals ] = useState({grade:'all', teacher:'all', text:''})
  const [ dropVis, setDropVis ] = useState(false)
  const inputRef = useRef();
  const [ students, setStudents ] = useState('init');
  const [teacherArray, setTeacherArray ] = useState([]);



  useEffect(()=>{
    const callApi = async () => { setStudents( await getFromApi('school_students'))};
    callApi();
  },[])

  useEffect(()=>{
    if(students.length&&students!=='init'){
      setTeacherArray([...new Set(students.map(obj => obj.teacherName))]);
    }
  },[students])




  return (
    <div className={styles.container}>

      <div className={styles.inner}>
        <div className={styles.top}>

          <div className={styles.teacherDrop} onClick={()=>setDropVis(!dropVis)} onMouseLeave={()=>setDropVis(false)}>
            <p>{filterVals.teacher === 'all' ? 'All Teachers' : filterVals.teacher}</p>
            {dropVis && 
              <div className={styles.teacherDropMenu} onMouseLeave={()=>setDropVis(false)}>
                <label  onClick={(e)=>{setFilterVals({...filterVals, teacher:'all'}); setDropVis(false)}}>
                  <input type="radio" name="teacher" id="teacher" value="all" defaultChecked={filterVals.teacher==='all'?true:false}/>
                  <div className={styles.teacherRow}><p>All teachers</p></div>
                </label>
                {
                  teacherArray.map((teacher, idx) => {
                    if(teacher.length){
                      return(
                        <label  onClick={(e)=>{setFilterVals({...filterVals, teacher:teacher}); setDropVis(false)}}>
                          <input type="radio" name="teacher" id="teacher" value="teacher" defaultChecked={filterVals.teacher===teacher?true:false}/>
                          <div className={styles.teacherRow}><p>{teacher}</p></div>
                        </label>
                        // <h4>{teacher}</h4>
                      )

                    } else {
                      return null
                      
                    }
                  })
                }
              </div>
            }
          </div>

          <div className={styles.radios} onChange={(e)=>{setFilterVals({...filterVals,grade:e.target.value})}}>
            <label>
              <input value={"all"} defaultChecked type="radio" name="grade"/>
              <div className={`${styles.pill} ${styles.long}`}>All Grades</div>
            </label>

            {[...Array(12)].map((x, i) =>{
              return(
                <label>
                <input value={i+1} type="radio" name="grade"/>
                <div className={styles.pill}>{i+1}</div>
              </label>
              )}
            )}

          </div>
          <input 
          ref={inputRef}
              type="text" 
              onFocus={()=>{inputRef.current.classList.add(styles.open)}}
              onBlur={()=>{inputRef.current.classList.remove(styles.open)}}
              onChange={(e)=>{setFilterVals({...filterVals,text:e.target.value})}}
              name="dashSearch"/>
        </div>
        <div className={styles.tableHeader}>
            
            <div className={`${styles.boomer} hideMobile`}>
            </div>
            <div className={`${styles.name} hideMobile`}>
              <h5>Student Name</h5>
            </div>
            {/* <div className={`${styles.email} hideRes`}>
              <h5>Email</h5>
            </div> */}
            <div className={`${styles.groupName} hideMobile`}>
              <h5>Class Name</h5>
            </div>
            <div className={`${styles.teacher} hideMobile`}>
              <h5>Teacher Name</h5>
            </div>
            <div className={`${styles.parentName} hideMobile`}>
              <h5>Parent</h5>
            </div>
            <div className={`${styles.parentMail} hideRes`}>
              <h5>Parent Email</h5>
            </div>

        </div>
        <div className={styles.tableRows}>
          {
              students === 'init' ? 

              <div className={styles.loaderDiv}>
                <img className={styles.loader} src={spinner} alt="spinner"/>
              </div> 
            :

              !students.length ?

                <h5>No students found</h5>
            
            : 
            
              students.length ?
                students.map((student, index) => {
                  if (testStudent(student, filterVals.text, filterVals.grade, filterVals.teacher)) 
                      { return ( <div key={index}> <StudentRow student={student} modalOverlay={modalOverlay} /> </div> )} 
                  else { return null} 
                }) 

            : null

          }

        </div>
      </div>
    </div>
  )
}

export default ManageStudents;

