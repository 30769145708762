import React, { Fragment, useState } from 'react';

import styles from './StudentDetailsModal.module.scss';

const StudentDetailsModal = ({ item }) => {

  const [ conf, setConf ] = useState({0:false,1:false,2:false,3:false,4:false,5:false,6:false})

  const student = {
    parentEmail:[
      {name:'Peter Chang', email:'peterchang@escribli.com'},
      {name:'Maria Chang', email:'mariachang@escribli.com'}
    ]
  }
  const code = "GKIYU";
  const handleCopy = (index, input) => {
    setConf({...conf, [index]:true });   
    setTimeout(()=>{ setConf({...conf,[index]:false });},1000)
    navigator.clipboard.writeText(input) 
  }

  return (

    <Fragment>
      <div className={`${styles.row} ${styles.one}`}>

        <div className={`${styles.boomerBlock} ${styles.block}`}>
          <div className={styles.boomer}></div>
          <div className={styles.details}>
            <h1>{item.studentName}</h1>
            <h4>{item.email}</h4>
            <div className={styles.detailsButtons}>
              <h4>Grade {item.grade}</h4>
            </div>
            <div className={styles.bucks}>
              <div className={styles.box}>${item.boomerBucks}</div>
              <h4>Boomer Bucks</h4>
            </div>
          </div>

          <div className={`${styles.topRight}`}>
            <div className={styles.buttons}>
                <div className={styles.manageButton}>Manage Student</div>
                <div className={styles.portfolioButton}>Portfolio</div>
                <div className={styles.deleteButton}>
                  <div className={styles.tooltip}>Remove student</div>
                </div>
            </div>

            <div className={styles.loginCode}>
              <h4>Copy login code</h4>
              <div onClick={()=>{handleCopy(0, item.loginCode)}} className={styles.codeDiv}>
                { !conf[0] ? item.loginCode  : <p>COPIED</p> }
                <div className={styles.tooltip}>Copy to clipboard</div>
              </div>
            </div>
          </div>

        </div>


      </div>
      <div className={`${styles.row} hideSml`}>
        <div className={`${styles.block} ${styles.third}`}>
          <div className={styles.listRow}>
            <h3>Classes</h3>
          </div>

          {
            !item.studentClasses.length?
              <div className={styles.listRow}>
                <h4>no classes found</h4>
              </div>

            :

            item.studentClasses.map(item =>{
              return(
                <div className={styles.listRow}>
                  <h4>{item}</h4>
                </div>
              )
            })
          }
          
          {item.studentClasses.length ? <div className={styles.viewBtn}>View All</div > : null}

        </div>

        <div className={`${styles.block} ${styles.third}`}>

          <div className={styles.listRow}>
            <h3>Recent Activities</h3>
          </div>
          {
            !item.recentActivities.length ?
              <div className={styles.listRow}>
                <h4>no recent activities</h4>
              </div>

            :

            item.recentActivities.map(item =>{
              return(
                <div className={styles.listRow}>
                  <h4>{item}</h4>
                </div>
              )
            })
          }
        </div>


        <div className={`${styles.block} ${styles.third}`}>
          <div className={styles.listRow}>
            <h3>Parent/Guardian</h3>
          </div>
            {
              item.parents.map((item, idx)=>{
                return(
                  <div onClick={()=>{handleCopy(idx+1, item.email)}} key={idx} className={`${styles.listRow} ${styles.copy}`}>

                    {!conf[idx+1] ?
                      <h4>
                        {item.email}
                        <div className={styles.tooltip}>{item.name}</div>
                      </h4>
                    :

                      <p className={styles.copied}>COPIED</p>
                    }
                </div>
                )
              })
            }

            <div className={styles.addParentBtn}>Add parent/guardian</div>
        </div>
      </div>
    </Fragment>

  )
}

export default StudentDetailsModal;