import React, { useState } from 'react';
import Carousel from 'react-elastic-carousel';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import defaultPic1 from 'assets/nav/defaultPic1.svg'
import styles from './ResourceItem.module.scss';
import { setQueryItem } from 'Redux/actions';
import { useEffect } from 'react';
import { useRef } from 'react';
import spinner from 'assets/Spinner.svg';
import faceImg from 'assets/face_shocked.svg'
import ReactQuill from 'react-quill';

import lock from './lock.svg'

import { useUserType } from 'context/AltPlatformProvider';
import { getFromApiRandom } from 'API/teacherCalls';

const RandomItem = ({closeBtn, item, modalOverlay}) => {
  const { activityIdParam } = useParams();

  const [ radVal, setRadVal] = useState(0);
  const history = useHistory();
  
  const [ carousel, setCarousel ] = useState({vis:false, index:0});
  const [ activity, setActivity] = useState({});
  const [ expand, setExpand] = useState(0)
  const contRef = useRef();
  const { fjUser, synodUser } = useUserType();


  

  const fetchTeachers = async () => {
    try {
      const response = await getFromApiRandom(`resource/${activityIdParam}`);

      if (response.activityId) {
        setActivity(response);
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, [activityIdParam]);


  const free = true

  // if(free) {
  //   return <Redirect from="/" to="/teacher" exact />
  // }
  if(localStorage.getItem("t") && localStorage.getItem("backUrl")){
     return <Redirect to={`/teacher/resources/${activityIdParam}`} />
  }



  return (
    <div ref={contRef} className={styles.container}>
      {
        activity?.activityName ?

        <>
          <div className={styles.topBar}>
                
                <div className={`${styles.colorTab} ${styles[activity.activityType]}`}></div>

                <div className={styles.text}>
                  <h3>{activity.activityName}</h3>
                  <div className={styles.author}>
                  <img src={activity.imageUrl !== "" ? activity.imageUrl : defaultPic1} alt="authorPic"/>
                  <h6>Author: {activity.author}</h6>
                  </div>
                </div>

                <div className={styles.details}>

                  <div className={styles.grades}>
                    <h6>Grades:</h6>
                    <div className={styles.gradeDots}>
                      <div className={styles.dotsInner}>

                      {activity.shareGrade.length === 11 ?
                      
                        <div className={styles.widePill}>
                          All grades
                        </div>
                      :  
                        activity.shareGrade.map(grade=>{
                          return(
                            <div className={styles.gradeDot}>
                              {grade}
                            </div>
                          )
                        })
                      }

                      </div>
                    </div>
                  </div>
                  
                  <div className={styles.typeSubject}>
                    {
                      free &&  activity.free === false && (
                         <div  className={`${styles.sophia}`}>Sophia Institute</div>
                      )
                    }
                    <div className={`${styles.widePill} ${styles[activity.activityType]}`}>
                      {activity.activityType.charAt(0).toUpperCase() + activity.activityType.slice(1)}
                    </div>
                    <div className={`${styles.widePill} ${styles.darkBlue}`}>
                      {activity.activitySubject === 'History-Social Studies' ? 'History' : activity.activitySubject}
                    </div>
                   
                  </div>
                </div>
              </div>

              <div className={styles.bottom}>
                <div className={styles.sections}>
                  <h3>Sections</h3>
                  <div className={styles.sectionDivs} onChange={(e)=>{setRadVal(e.target.value); console.log(activity.sections[radVal].instructions)}}>
                    {
                      activity.sections.map((section, index) => {
                        return(
                          <label key={index}>
                            <input defaultChecked={index===0?true:false} type="radio" name="section" 
                              onChange={(e) => setExpand(index)}
                              value={index} 
                              disabled={activity.free === true ? false : true}  
                            />
                            <div className={styles.sectionDiv}>
                              <div className={`${styles.colorTab} ${styles[activity.activityType]}`}></div>
                                <div className={styles.item}>

                                  {section.sectionName} 
                                   {
                                     index === expand? '' :
                                      <div>
                                        {activity.free === false && 
                                            <img src={lock} alt="lock" className={styles.lock} />
                                        }  
                                      </div>
                                   }
                                </div>

                            </div>
                          </label>
                        )
                      })
                    }

                  </div>

                  <h3>Additional comments</h3>
                  <textarea readOnly name="additionalComments" placeholder={activity.additionalComments} />
                </div>

                <div>
                  <div className={styles.instructions}>
                    <div className={`${styles.colorTab} ${styles[activity.activityType]}`}></div>
                    <h3>Instructions: </h3>

                    <ReactQuill
                      readOnly
                      modules={{ "toolbar": false }}
                      theme="snow"
                      value={activity.sections[radVal].instructions ? activity.sections[radVal].instructions : ''}/>

                    <div className={styles.imagesDiv}>
                    <h3>Images: </h3>
                    <div className={styles.pics}>
                      {activity.sections[radVal].images.length ? 
                        activity.sections[radVal].images.map((item,index)=>{
                          return (
                            <div  onClick={()=>{setCarousel({vis:true, index:index})}}  className={styles.pic}>
                              <div className={styles.overlay}>
                                <div  className={styles.preview}>Preview</div>
                              </div>
                              <img key={index} src={item} alt={`pic${index}`}/>
                            </div>
                          )
                        })
                      :
                        <h5>(no images included)</h5>
                      }
                    </div>
                    </div>
                  </div>
                  <div className={styles.note}>
                    {
                      activity.free === false && (
                        <p>
                          As you select the resource, you will be asked to choose the classroom where you would like to use it and will only pay for the number of students in that class.
                          Only after you select a classroom the journals will be charged to your account.
                        </p>
                      )
                    }
                     <div onClick={()=> history.push('/') } className={styles.btn}>
                       Login
                      </div>
                  </div>
                 
                </div>

              </div>


        </>

        : activity?.message ?

        <div className={styles.errorDiv}>

          <img className={styles.faceImg} src={faceImg} alt="boomer"/>

          <h3>Oops, no such resource was found!</h3>

          <div onClick={()=>{closeBtn.current.click()}} className={styles.goBack}>Go back</div>
        </div>
        :
        <div className={styles.loadingDiv}>
          <img src={spinner} alt="spinner"/>
        </div>
      }
      
    </div>
  )

}

export default RandomItem;

// height:360px;
// width: 600px;