import React, { useState } from 'react'
import SwitchButton from './Switch'
import { useHistory } from 'react-router';


import EscribCard from './EscribCard'
import styles from './PricingEscrib.module.scss';


const PricingEscrib = ({show}) => {
    const [selectedValue, setSelectedValue] = useState('monthly');

    const history = useHistory()

  
  
    const handleButton = () => history.push('/teacher/account/manage')
  
  
    const handleChange = () => {
      const newSelectedValue = selectedValue === 'monthly' ? 'annual' : 'monthly';
      setSelectedValue(newSelectedValue);
    };
    
  const cardDataes = [
    {
      type:"Small class",
      allowance: "Up to 5 students",
      monthly:"0",
      yearly: null,
      benefits:["unlimited time"],
      ctaText:"Start for free",
      ctaAction:"signup_teacher"
    },
    {
      type:"Standard",
      allowance: "More than 5 students",
      monthly: selectedValue === 'annual' ? '10' : '1',
      yearly: "",
      benefits:[
        "Unlimited students",
        "Personalized onboarding support",
        "Professional development, training and ongoing support",
      ],
      ctaText:"Get started",
      ctaAction:"contact"
    }
  ]

  return (
    <>
      <SwitchButton selectedValue={selectedValue} handleChange={handleChange} />

    <div className={`${styles.cards}`}>
      { cardDataes.map((i,idx) => 
        <EscribCard 
          cardData={i} key={idx} blue={idx===1}
          selectedValue={selectedValue}
          />
      )}
    </div>
    <a style={{ color: "white"}}  href="https://es-courses-static.s3.amazonaws.com/escribli-subscription-terms-2023-2024"  target="_blank" rel="noopener" className={styles.faithlink}>
        <em>
        See <strong>Escribli Subscription Terms</strong>
        </em>
      </a>
    {show &&
        <div onClick={handleButton} className={styles.trialBtn}>
        <p>Activate my subscription</p>
      </div> 
    }
  
   </>  
   )
}

export default PricingEscrib