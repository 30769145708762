import React, { useState, useRef } from 'react';

import styles from './SendParentInviteModal.module.scss';
import { sendToApi } from 'API/teacherCalls';

const SendParentInviteModal = ({closeBtn, item:{studentId, guardians, setGuardians}}) => {

  const [ error, setError ] = useState({invalid:false, empty:false});
  const [ success, setSuccess ] = useState(false);
  const inputRef = useRef();
  const regex = /.+@.+\..+/i;

  const handleSubmit = () => {
    if(!inputRef.current.value.length){
      setError({...error, empty:true})

    } else if (!regex.test(inputRef.current.value)){
      setError({...error, invalid:true})
      
    } else {
      setError({empty:false, invalid:false})
      setSuccess(true)
      setTimeout(()=>{closeBtn.current.click()},2000)
      sendToApi(`invite_parent/${studentId}`, {email:inputRef.current.value})
      setGuardians([inputRef.current.value])
    }
  }

  return (
    <>
      <div className={styles.container}>

        <h2>Enter parent/guardian email below</h2>
        <input autoFocus onChange={()=>{setError({invalid:false, empty:false})}} ref={inputRef} type="email" className={styles.emailInput}/>
        <div onClick={handleSubmit} className={styles.send}>Save Email</div>

      </div>

        {error.invalid ? <p className={styles.error}>Invalid email entered</p> : null}
        {error.empty ? <p className={styles.error}>Please enter an email address</p> : null}
        {success ? <p className={styles.success}>Email updated!</p> : null}
    </>
  )

}

export default SendParentInviteModal;