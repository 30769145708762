import React, { useState, useRef, useEffect } from 'react';
import { isSameDay, isToday, isYesterday, format } from 'date-fns'

import { testName } from 'helpers/filterFunctions';
import { instancesInArray } from 'helpers/uiFunctions';
import { simpleTimeFormat } from 'helpers/dateFunctions';
import { getChat, sendChat, markAsRead } from 'API/TeacherCallFunctions/chatFunctions';
import styles from './ChatModal.module.scss';
import sendMsg from 'assets/icon-sendMsg.svg';
import loader from 'assets/loader-sm2.svg'
import { setUnread, updateDashData } from 'Redux/actions';
import {store} from 'storeConfig';
import { useSelector } from 'react-redux';
import ResponsiveConversationModal from './ResponsiveConversationModal';


const ChatModal = ({ standalone }) => {

  const textRef = useRef();
  const inputRef = useRef();
  const [ textInput, setTextInput ] = useState('');
  const [ chatData, setChatData ] = useState(null)
  const [ showConversation, toggleConversation ] = useState(false)
  const [ activeIdx, setActiveIdx ] = useState(0)
  const incomingMessage = useSelector(state => state.chatData.incomingMessage)

  const getChatData = async () => { setChatData(await getChat()) }

  useEffect(()=>{
    getChatData()
    store.dispatch(setUnread(0))
    store.dispatch( updateDashData('unreadChatCount', 0) )
    localStorage.setItem('chatModal', true)
    return () => localStorage.removeItem('chatModal')
  },[])

  useEffect(()=>{
    if(chatData&&incomingMessage)handleLiveMessage(incomingMessage)
  },[incomingMessage])


  const handleSubmit = () => {

    sendChat({studentId:chatData[activeIdx].studentId, content:inputRef.current.value})

    const dataCopy=[...chatData]
    dataCopy[activeIdx].messages.unshift({ content:inputRef.current.value, sentBy:1, timeStamp:new Date()})
    setChatData(dataCopy)
    inputRef.current.value=""

  }

  const handleLiveMessage = (message) => {
    const { body, reply_to, student_name, student_id } = message
    const chatIdx = chatData?.map(i=>i.studentId).indexOf(reply_to)
    const dataCopy=[...chatData]
    const newMsg = {
      studentId: student_id,
      studentName: student_name,
      sentBy:2,
      content: body,
      seen:chatData[activeIdx].studentId===reply_to,
      timeStamp: new Date(),
      boomerUrl: chatData.find(i=>i.studentId===reply_to).boomerUrl
    }
    
    dataCopy[chatIdx].messages.unshift(newMsg)

    setChatData(dataCopy)
  }


  //mark messages as read upon switching chat
  useEffect(()=>{  
    if(chatData?.length) {
      markAsRead(chatData[activeIdx].studentId)
      const dataCopy = [...chatData]
      dataCopy[activeIdx].messages.forEach(i=>i.sentBy===2?i.seen=true:null)
      setChatData(dataCopy)
    }
  },[activeIdx, chatData?.length])

  const isNewDay = (d1, d2) => {
    if(d1&&d2) return !isSameDay(new Date(d1), new Date(d2))
  }

  const rendernewDayPill = (msg) => {
    return <div className={styles.datePill}>
              {
                  isToday(new Date(msg.timeStamp)) ? "Today"
                :
                  isYesterday(new Date(msg.timeStamp)) ? "Yesterday"
                :
                  format(new Date(msg.timeStamp), 'dd/MM/yyyy')
              }
          </div>
  }

  const handleClick = () => {
    if(window.innerWidth<=660){
      toggleConversation(true)
    }
  }
  


  return (
    <div className={`${styles.container} ${standalone && styles.standalone}`}>
      {
        !chatData &&
          <div className={styles.loaderOverlay}>
            <img src={loader} alt="loader"/>
            <h4>loading conversations</h4>
          </div>
      }

      <div className={styles.students}>

        { standalone && 
          <ResponsiveConversationModal
            handleSubmit={handleSubmit} 
            showConversation={showConversation} 
            toggleConversation={toggleConversation}
            chatData={chatData} 
            rendernewDayPill={rendernewDayPill}
            sendMsg={sendMsg}
            activeIdx={activeIdx}
            isNewDay={isNewDay} 
            inputRef={inputRef}  />
        }

        <div className={styles.responsiveSearch}>
          <h4>Chat</h4>

          <div className={styles.search}>
            <input ref={textRef} type="text" name="search" placeholder="Student name" onChange={(e)=>{setTextInput(e.target.value)}}/>
          </div>
          
        </div>

        <div className={styles.search}>
          <input ref={textRef} type="text" name="search" placeholder="Student name" onChange={(e)=>{setTextInput(e.target.value)}}/>
        </div>
        <div className={styles.studentList} onChange={(e)=>setActiveIdx(parseInt(e.target.value))}>
          {chatData?.length ?

            chatData.map((student,index)=>{

              if(testName(student, textInput) ) 
                {
                  return(
                    <label onClick={handleClick}  className={index}  key={index}>
                      
                      <input 
                        value={index} 
                        className="studRad" 
                        type="radio" 
                        name={`student}`} 
                        defaultChecked={index===0}/>
                    
                      <div className={styles.studentDiv}>
                        <div className={styles.colorTab}></div>
                        <img src={student.boomerUrl} alt="student boomer"/>
                        <h3>{student.studentName}</h3>

                        {instancesInArray(student.messages.filter(item => item.sentBy === 2), 'seen', false) > 0 ?
                        <div className={styles.unreadCount}>
                          {instancesInArray(student.messages.filter(item => item.sentBy === 2), 'seen', false) }
                        </div>
                        :null}
                      </div>
                    </label>
                  )
                }

                else{return null}

            })
          :

            <p>no students found</p>

          }
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.mainInner}>

        {chatData?.length && chatData[activeIdx].messages?.length ?
            chatData[activeIdx].messages.map((message, index)=>{
              
              return(
                <React.Fragment>
                  {/* {renderDatePill(message, index)} */}

                  <div key={index} className={(message.sentBy===1 ? `${styles.message} ${styles.self}` : `${styles.message} ${styles.external}`)}>
                    {message.sentBy!==1 ? <h6 className={styles.from}>{message.studentName.split(' ')[0]}</h6> : null}
                    {message.sentBy!==1 ? <img src={message.boomerUrl} alt="boomer" /> : null}
                    <h4>{message.content}</h4>
                    <h6>{
                      message.timeStamp?.length===5 ?
                        message.timeStamp
                      :
                        simpleTimeFormat(new Date(message.timeStamp))
                    }</h6>
                  </div>
                  {index === chatData[activeIdx]?.messages.length-1 && rendernewDayPill(message)}
                  {isNewDay(chatData[activeIdx]?.messages[index+1]?.timeStamp, message.timeStamp)&&rendernewDayPill(message)}
                </React.Fragment>
                )
              })

          : null }

        </div>

        <div className={styles.inputDiv}>
          <input 
            onKeyPress={(e)=>{if(e.key === 'Enter' && e.target.value !== ''){handleSubmit()}}} 
            ref={window.innerWidth<=660 ? null : inputRef} 
            type="text" 
            name="msgInput" 
            placeholder="Type your message here"
          />
          <img onClick={handleSubmit} src={sendMsg} alt="Send Message" />
        </div>
        
      </div> 
    </div>
 )

}

export default ChatModal;

