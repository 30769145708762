import React, { useRef, useState } from 'react';
import chevron from 'assets/icon-chevron-white.png';
import styles from './ActivateAccountPopper.module.scss';
import { showModal } from 'helpers/uiFunctions';
import sent from 'assets/icon-sent--blue.svg';
import { sendToApi } from 'API/generalCalls';

const ActivateAccountPopper = ({ email, modalOverlay }) => {

  const popupRef = useRef();
  const popperRef = useRef();
  const resendRef = useRef();
  const [ localMail, setEmail ] = useState(email)
  const [ contState, setContState ] = useState('init')

  const handleResend = () =>{
    if(localMail){
      setContState('sent');
      sendToApi('account/resend_confirm')
      setTimeout(()=>{popperRef.current.click()},1200)
      setTimeout(()=>{setContState('init')},1500)
    } else {
      resendRef.current.classList.add('shake')
      setTimeout(()=>{
        resendRef.current.classList.remove('shake')
      },500)
    }
  } 

  return (
    <div onMouseLeave={()=>{popupRef.current.classList.add(styles.closed); popperRef.current.classList.add(styles.closed)}} className={styles.container}>
      <div ref={popupRef} className={`${styles.popup} ${styles.closed}`}>

        <div className={styles.inner}>
          {
            contState === 'init' ? 
              <>
                <h3>Get full access to the app</h3>

                <p>  <span className={styles.checkMail}>Check your mail and activate</span> your account to get full access to the app.</p>

                <div className={styles.email}>
                <p className={styles.email}> Current email: <strong>{localMail?localMail:'No email on file'}</strong> </p>
                  <span onClick={()=>{ showModal(modalOverlay, {type:'changeEmail', setEmail:setEmail}) }} className={styles.changeBtn} className={styles.changeEmail}>change email</span> 
                </div>

                <i>* If your email is incorrect, you can change it before activating the account</i>

                <div className={styles.buttons}>
                  <div ref={resendRef} onClick={handleResend} className={styles.resendBtn}>Resend email</div>
                </div>
              </>

            :
              <div className={styles.sentModal}>
                <img src={sent} alt="sent!" />
                <h1>Email sent!</h1>
              </div>
          }

        </div>

      </div>

      <div 
        ref={popperRef}
        onClick={()=>{popupRef.current.classList.toggle(styles.closed); popperRef.current.classList.toggle(styles.closed)}} 
        className={`${styles.popper} ${styles.closed}`} >

        <img src={chevron} alt="chevron"/>
        <h4>Activate Account</h4>
      </div>
    </div>
  )

}

export default ActivateAccountPopper;