import { titleCase } from 'helpers/uiFunctions';
import React, { useEffect, useState } from 'react';

import digitalStory from 'assets/Parent/purchaseOption-digitalStory.svg'
import digitalJournal from 'assets/Parent/purchaseOption-digitalJournal.svg'
import paperbackStory from 'assets/Parent/purchaseOption-paperbackStory.svg'
import paperbackJournal from 'assets/Parent/purchaseOption-paperbackJournal.svg'
import bundleJournal from 'assets/Parent/purchaseOption-bundleJournal.svg'
import bundleStory from 'assets/Parent/purchaseOption-bundleStory.svg'
import bestOption from 'assets/Parent/bestOption.svg'
import { multiPrice } from 'helpers/priceFunctions'
import spinner from 'assets/spinner-sml--fast.svg';

import styles from './BookPurchaseModal.module.scss';
import { getFromApi } from 'API/parentCalls';
import { store } from 'storeConfig';
import { setQueryItem } from 'Redux/actions';

const PurchaseStepOne = ({ activity: { activityTitle, activityType, activityId }, contRef, setStep, payload, setPayload, childId }) => {

  const [ localCopies, setLocalCopies ] = useState({paperback:1, bundle:1})
  const [ apiRes, setApiRes ] = useState('init')
  const [ disableDigital, setDisableDigital ] = useState(false)
  const callApi = async () =>{ setApiRes( await getFromApi(`purchase_options/${childId}?project_id=${activityId}`)) }

  useEffect(()=>{
    callApi();
  },[])

  useEffect(()=>{
   
    if(apiRes&&apiRes.address_1){
      setPayload({...payload, address:{
        address_1:apiRes.address_1,
        address_2:apiRes.address_2,
        city:apiRes.city,
        country_name:apiRes.country_name,
        state_name:apiRes.state_name,
        zip:apiRes.zip
      }});
      if(!apiRes.allowPdfPurchase){ 
        setDisableDigital(true);
      }
    }
    
  },[apiRes])

  useEffect(()=>{
    if(disableDigital){ setPayload({...payload, type:'paperback'}) }
  },[disableDigital])

  const handleNext = () => {

    if(payload.type==='bundle'){
      setPayload({...payload, copies:localCopies.bundle})
    } else if (payload.type==='paperback'){
      setPayload({...payload, copies:localCopies.paperback})
    }

    setStep('delivery')
    contRef.current.classList.remove(styles.init)
    contRef.current.classList.add(styles.delivery)
  }


  useEffect(()=>{

    if(payload.type==='digital'){
      setPayload({...payload, price: 3.99})

    } else {
      setPayload({...payload, price: multiPrice(
        payload.type==='paperback'?localCopies.paperback:localCopies.bundle, payload.type==='bundle'
      )})
    }
  },[localCopies])

  const handleDownload = () => {
    store.dispatch(setQueryItem({type:"pdfRender", payload:{student_id:childId}, activityId:activityId,  activityName:activityTitle, parent:true}))
  }

  return (
    
    <>
    {
      apiRes!=='init' ? 
        <>
          <div className={styles.titleBar}>
            <div className={`${styles.colorTab} ${styles[activityType]}`}></div>
            <h2>{activityTitle}</h2>
            <div className={`${styles.typePill} ${styles[activityType]}`}>{titleCase(activityType)}</div>
          </div>

          <div className={styles.options}>
            <h1>Purchase options</h1>
            <div onChange={(e)=>{
              setLocalCopies({paperback:1, bundle:1});
              setPayload({...payload, type:e.target.value})
              }} className={styles.radios}>
              <label className={ disableDigital ? `${styles.unavailable}` : null}>
                <input type="radio" name="format" value='digital' checked={payload.type==='digital'?true:false}/>
                  <div className={ disableDigital ? `${styles.option} ${styles.unavailable}` : styles.option}>
                  <div className={styles.imageDiv}>
                    <img className={styles[activityType]} src={activityType==='story'?digitalStory:digitalJournal} alt="digital"/>
                  </div>
                  <div className={styles.price}>
                    <h3>Digital</h3>
                    <h3>$3.99</h3>
                  </div>
                </div>
              </label>
              <label>
                <input type="radio" name="format" value='paperback' checked={payload.type==='paperback'?true:false}/>
                  <div className={styles.option}>
                    <div className={styles.imageDiv}>
                      <img className={styles[activityType]} src={activityType==='story'?paperbackStory:paperbackJournal} alt="paperback"/>
                    </div>
                  <div className={styles.price}>
                    <h3>Paperback</h3>
                    <h3>${multiPrice(localCopies.paperback, false)}</h3>
                  </div>
                  <div className={styles.copies}>
                    <p>Copies</p>
                    <div className={styles.copyCount}>{localCopies.paperback}</div>
                    <div onClick={()=>{if(localCopies.paperback>1){setLocalCopies({...localCopies, paperback:localCopies.paperback-1})}}} className={`${styles.button} ${styles.minus}`} >-</div>
                    <div onClick={()=>setLocalCopies({...localCopies, paperback:localCopies.paperback+1})} className={`${styles.button} ${styles.plus}`} >+</div>
                  </div>
                </div>
              </label>
              <label  className={ disableDigital ? `${styles.unavailable}` : null}>
                <img className={styles.bestOption} src={bestOption} alt="best option"/>
                <input type="radio" name="format" value='bundle' checked={payload.type==='bundle'?true:false}/>
                  <div  className={ disableDigital ? `${styles.option} ${styles.unavailable}` : styles.option}>
                    <div className={styles.imageDiv}>
                      <img className={styles[activityType]} src={activityType==='story'?bundleStory:bundleJournal} alt="bundle"/>
                    </div>
                  <div className={styles.price}>
                    <h3>Bundle: Paperback + Digital</h3>
                    <h3>${multiPrice(localCopies.bundle, true)}</h3>
                  </div>
                  <div className={styles.copies}>
                    <p>Copies</p>
                    <div className={styles.copyCount}>{localCopies.bundle}</div>
                    <div onClick={()=>{if(localCopies.bundle>1){setLocalCopies({...localCopies, bundle:localCopies.bundle-1})}}} className={`${styles.button} ${styles.minus}`} >-</div>
                    <div onClick={()=>setLocalCopies({...localCopies, bundle:localCopies.bundle+1})} className={`${styles.button} ${styles.plus}`} >+</div>
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div className={styles.textRow}>
            <h4 className={styles.smallPrint}> Shipping included in price, cover colors will vary</h4>

            {disableDigital && <h4 className={styles.download}><i class="fas fa-cloud-download-alt"></i>Download your PDF <span onClick={handleDownload} >here</span></h4>}
          </div>
          <div onClick={handleNext} className={styles.nextBtn}>Next</div>

        </>

      :

        <div className={styles.loaderDiv}>
          <img src={spinner} alt="loader"/>
        </div>

    }

    </>
  )

}

export default PurchaseStepOne;