import React from 'react';
import spinner from 'assets/Spinner.svg';

import styles from './UploadCSVModal.module.scss';
import { getCsv } from 'API/TeacherCallFunctions/csvGen';
import { useSelector } from 'react-redux';
import CopyPillTooltip from 'components/UtilityComps/CopyPillTooltip/CopyPillTooltip';

const CsvStepFour = ({ closeBtn, apiRes2, classObj, onboarding, synodUser }) => {

  const { classCode } = useSelector(state=>state.fetchedClass)

  const handleClose = () => {

    if(onboarding){

      const frontUrl = localStorage.getItem('frontUrl')
      const URL = `${frontUrl}/teacher/classes/view/${classObj.classId}/?students`
      window.location = URL


    } else {
      closeBtn.current.click()
    }
  }


  const copyToClip = (e, code) => { 
    const copy = e.target.parentElement.parentElement.children[4].children[0]
    const copied = e.target.parentElement.parentElement.children[4].children[1]

    copy.classList.add(styles.hide)
    copied.classList.remove(styles.hide)

    setTimeout(()=>{
      copy.classList.remove(styles.hide)
      copied.classList.add(styles.hide)
    },1500)

    navigator.clipboard.writeText(code)
  }

  return (
    <>
      {
        !apiRes2 ?
          <div className={styles.containerStep}>
            <div className={styles.loader}>
              <img src={spinner} alt="spinner" />
              <h6>Creating accounts...</h6>
            </div>
          </div>


          : !apiRes2.length ?

            <div className={styles.noAccountsCreated}>
              <h2>No {!synodUser ? "students":"participants"} added</h2>
              <p>The accounts you have tried to create appear to already exist!</p>
              <p>In order to add them to this class, please request that they join using the code below.</p>
              <div className={styles.pillDiv}>
                <CopyPillTooltip 
                  small
                  copyData={classCode} 
                  displayData={`${classCode}`} 
                  tooltipText={"click to copy class code"} />
              </div>

              <div onClick={handleClose} className={styles.btnDone}>Done</div>
            </div>

            :




            <div className={styles.containerStep}>

              <div className={styles.timeline}>

                <div className={styles.step}>
                  <h6>Upload</h6>
                  <div className={`${styles.circle} ${styles.completed}`}></div>
                </div>

                <div className={styles.step}>
                  <h6>Review</h6>
                  <div className={`${styles.circle} ${styles.completed}`}></div>
                </div>

                <div className={styles.step}>
                  <h6>Creation</h6>
                  <div className={`${styles.circle} ${styles.currentStage}`}><h5>3</h5></div>
                </div>

                <div className={styles.line}>
                  <div className={`${styles.blueLine} ${styles.stageFour}`}></div>
                </div>

              </div>


              <div className={`${styles.infoDiv} ${styles.green}`}>
                <span>{apiRes2.length}</span> {!synodUser ? "students" : "participants"} successfuly added!
              </div>

              <p>{!synodUser ? "Students" : "Participants"} will need to use the login code when they first login. After that, thay will be prompted to create their own password</p>


              <div className={styles.csvTableFinal}>

                <div className={styles.header}>
                  <div className={`${styles.headerColumn}`}> First Name </div>
                  <div className={`${styles.headerColumn}`}> Last Name </div>
                  <div className={`${styles.headerColumn}`}> Username </div>
                  <div className={`${styles.headerColumn}`}> Login Code 
                    <div className={styles.icon}>
                      <div className={styles.tooltip}>Login code is a temporary password the user uses on the first log in</div>
                    </div> 
                  </div>
                  <div className={`${styles.headerColumn}`}> Login Link 
                    <div className={styles.icon}>
                      <div className={styles.tooltip}>Login link is a direct URL (link) to access the student's account. </div>
                    </div> 
                  </div>
                </div>

                <div className={styles.dataRows}>
                  {
                    apiRes2.map((item, idx) => {
                      return (
                        <div className={styles.dataRow}>
                          <div className={styles.dataColumn}>{item.studentName.split(' ')[0]}</div>
                          <div className={styles.dataColumn}>{item.studentName.split(' ')[1]}</div>
                          <div className={styles.dataColumn}>{item.studentEmail}</div>
                          <div className={styles.dataColumn}>
                            <CopyPillTooltip
                              blue
                              noBorder
                              noTooltip
                              copyData={item.loginCode}
                              displayData={item.loginCode}
                              tooltipText={"click to copy login code"} />
                          </div>
                          <div className={styles.dataColumn}>
                            <CopyPillTooltip
                              blue
                              noBorder
                              noTooltip
                              copyData={item.uniqLoginUrl}
                              displayData={'Copy Link'}
                              tooltipText={"click to copy login link"} />
                          </div>
                        </div>
                      )
                    })
                  }
                </div>

                <div className={styles.btnsFinal}>
                  <div onClick={handleClose} className={styles.btnFinal}>Done</div>
                  <div onClick={() => { getCsv(`teachers/class/${classObj.classId}/logins_csv`, classObj.className) }} className={styles.btnFinal}>Download Login Details</div>
                </div>

              </div>
            </div>
      }
    </>
  )

}

export default CsvStepFour;