import { idxClass } from 'helpers/idxClass';
import React, { useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import AdditionalButtons from '../AdditionalButtons/AdditionalButtons';

import ReflectionQuestionsModal from '../ReflectionQuestionsModal/ReflectionQuestionsModal';
import VerseOfTheDayModal from '../VerseOfTheDayModal/VerseOfTheDayModal';

import styles from './ActivitySectionsBar.module.scss';
import plus from 'assets/icon_plus_blue.svg';
import loader from 'assets/spinner_teal.svg';
import { store } from 'storeConfig';
import { updateActivityKey } from 'Redux/actions';
import { sendToApiReturn } from 'API/teacherCalls';
import Tooltip from 'components/UtilityComps/Tooltip/Tooltip';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import ActivitySectionDiv from './ActivitySectionDiv';
import { useEffect } from 'react';

const getItems = sections => 
  sections?.map((item,idx) => {return {...item, id:item.sectionName}})

const ActivitySectionsBar = ({fjUser, activity, classId, activeSection, setActiveSection, 
                              modalOverlay, selectedIdx, setSelectedIdx, setLocalActivityName, 
                              activity:{ activityName, sections, iconKey, activityId, 
                                         activityType, activityClassId }}) => {

  const [ loading, setLoading ] = useState(false)
  const [ activeModal, setActiveModal ] = useState(null)
  const [ editing, setEditing ] = useState(-1)
  const [ editingName, toggleEditing ] = useState(false)
  const [ items, setItems ] = useState(null)
  const inputRef = useRef()
  const nameDivRef = useRef()
  const modalContRef = useRef()
  const contRef = useRef()

  useOutsideClick(contRef, () => setActiveModal(null) )

  useEffect(()=>{
    // make sure the modal is not already visible
    if (modalContRef && modalContRef.current) {
      // activeModal is null or undefined, make sure the container div is not visible
      modalContRef.current.classList.remove(styles.display)
    }
    if(activeModal){
      // show the current modal with a small delay
      setTimeout(()=>{modalContRef.current.classList.add(styles.display)},50)
    }
  },[activeModal])

  useEffect(()=>{ 
    // set the list of sections
    setItems(getItems(sections)) 
    },[sections])

  const handleNewSection = async (title = '', instructions = '') => {
    let params = {}
    if(title.length > 0 && instructions.length > 0) {
      params = {sectionName: title, instructions: instructions}
    }
    const newSection = await sendToApiReturn(`activity/${activityId}/new_unit`, params)
    store.dispatch(updateActivityKey('sections', [...sections, newSection]))
    setSelectedIdx(activity.sections.length-1)
  }

  const handleNewName = () => {
    if(inputRef.current?.value !== "" && inputRef.current?.value!== activityName){
      sendToApiReturn(`activity/${activityId}/update`, {...activity, activityName:inputRef.current.value})
      store.dispatch(updateActivityKey('activityName', inputRef.current.value))
      setLocalActivityName(inputRef.current.value)
    }
    toggleEditing(false)
  }

  const handleNameinput = async (e) => {
    if (e.key === 'Escape') {
      return toggleEditing(false);
    }
    
    if (e.key === 'Enter') {
      handleNewName()
    }
  }

  useOutsideClick(inputRef, () => { if(editingName) handleNewName() })

  const onDragEnd = result => {

    const { source, destination } = result;

    if (!result.destination||(activityType=="story"&&result.source.index==0)) {
      return;
    }

    if(selectedIdx == source.index){
      setSelectedIdx(destination.index)
    } else if (selectedIdx == destination.index) {
      if(destination.index<source.index){
        setSelectedIdx(selectedIdx > 0 ? selectedIdx+1 : selectedIdx)
      } else {
        setSelectedIdx(selectedIdx > 0 ? selectedIdx-1 : selectedIdx)
      }

    }else if(selectedIdx >= destination.index && selectedIdx < destination.index){
      setSelectedIdx(selectedIdx < sections.length-1 ? selectedIdx+1 : selectedIdx)
    }else if(selectedIdx >= source.index ){
      if(selectedIdx < destination.index){
        setSelectedIdx(selectedIdx < sections.length-1 ? selectedIdx-1 : selectedIdx)
      }
    } 
    sendToApiReturn(`activity/${activityId}/reorder_unit/${source.index}`, {newIndex:destination.index})

    const newItems = [...items];
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);
    setItems(newItems)
  }

  return (
    <div ref={contRef} className={styles.container}>
        
        <div ref={modalContRef} className={styles.modalContainer}>
          <div onClick={()=>setActiveModal(null)} className={styles.closeBtn} />
          { activeModal === 'reflectionQuestions' &&  <ReflectionQuestionsModal /> }
          { activeModal === 'verseOfTheDay' &&        <VerseOfTheDayModal activityId={activityId} 
                                                                     activityClassId={activityClassId}
                                                                     handleNewSection={handleNewSection}
                                                                     closeModal={setActiveModal} /> }
        </div>
      
      {
        (!activity || !activity.sections || !items) ? 
        <div className={styles.spinner}>
          <img src={loader} alt=""spinner />
        </div> 
        :
        <>
          {
            <div className={styles.activityDetails}>
              <div className={`${styles.iconDiv}`}>
                <div className={`${styles.icon}  ${styles[idxClass(iconKey)]}`}></div>
              </div>
              <div ref={nameDivRef} className={styles.text}>
                <div className={`${styles.codeDiv}`}>
                  <h6>{activity.printCode}</h6>
                  <div className={`${styles.query}`}>
                    <div className={`${styles.tooltip}`}>
                      <div className={`${styles.book}`} />
                      <p>Use this code if you or parents would like to order a paperback book copy of this activity. Each student will have his/her unique copy and will include, the instructions and each student entry</p>
                    </div>
                  </div>
                </div>
                {/* {
                  !fjUser &&
                    <div className={`${styles.typePill} ${styles[activity.activityType]}`}>
                      <p>{activity.activityType === 'faith' ? 'journal' : activity.activityType}</p>
                    </div>
                } */}

                <div className={styles.nameDiv}>
                  {
                    !editingName ?
                      <>
                        <div onClick={() => toggleEditing(true)} className={styles.editHover}>
                          <h5>{activityName}</h5>
                          <Tooltip
                            tooltipText={"Change activity name"}
                            hoverClass={styles.tooltipHover}
                            maxWidth={"140px"}
                            padding={"5px 20px"}
                            whiteSpace={"noWrap"} />
                        </div>
                      </>
                      :
                      <input ref={inputRef} onKeyUp={e => handleNameinput(e)} type="text" defaultValue={activityName} autoFocus className={styles.nameInput} />
                  }
                </div>

              </div>
            </div>
          }
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  className={styles.sections}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {items.map((item, idx) => (
                    
                    activityType=="story"&&idx==0 ? //non-dragabble storyStart div
                      <ActivitySectionDiv
                        isStoryStart={activity.activityType === 'story' && idx === 0}
                        key={item.id}
                        idx={idx}
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        section={item}
                        selectedIdx={selectedIdx}
                        setSelectedIdx={setSelectedIdx}
                        activityId={activityId}
                        editing={editing}
                        setEditing={setEditing} />

                  :
                    
                    <Draggable draggableId={item.id} index={idx} key={item.id}>

                      {provided =>
                        <div
                          style={{marginBottom:"5px", border:"1px solid red"}}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                            <ActivitySectionDiv
                              isStoryStart={activity.activityType === 'story' && idx === 0}
                              key={item.id}
                              idx={idx}
                              activeSection={activeSection}
                              setActiveSection={setActiveSection}
                              setA
                              section={item}
                              selectedIdx={selectedIdx}
                              setSelectedIdx={setSelectedIdx}
                              activityId={activityId}
                              editing={editing}
                              setEditing={setEditing} />
                        </div>
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div id={"createNewSection"} onClick={handleNewSection} className={`${styles.newSectionBtn} ${loading && styles.inactive}`}>
            <img src={plus} alt="add new section" />
            <p>New section</p>
          </div>
        </>
      }
      
      {
        fjUser && 
        <div className={styles.activitySuggestions}>
          <h4>Suggestions for the activity:</h4>
          <div className={styles.shortcutArea} onClick={() => setActiveModal('reflectionQuestions')}>
            <p>Reflection questions</p>
            <div className={styles.openQuestions} />
          </div>
          <div className={styles.shortcutArea} onClick={() => setActiveModal('verseOfTheDay')}>
            <p>Verse of the day</p>
            <div className={styles.verseOfTheDay} />
          </div>
        </div>
      }

      {
        <AdditionalButtons
          activity={activity}
          modalOverlay={modalOverlay}
          classId={classId}
          activeSection={activeSection} />
      }
    </div>
  )

}

export default ActivitySectionsBar;




