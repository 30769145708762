
export const dateFormatYear = (input) => {
  
  const date = new Date(input);
  const day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();

  switch (month){
    case 0:
      month = 'Jan'
      break;
    case 1:
      month = 'Feb'
      break;
    case 2:
      month = 'Mar'
      break;
    case 3:
      month = 'Apr'
      break;
    case 4:
      month = 'May'
      break;
    case 5:
      month = 'Jun'
      break;
    case 6:
      month = 'Jul'
      break;
    case 7:
      month = 'Aug'
      break;
    case 8:
      month = 'Sept'
      break;
    case 9:
      month = 'Oct'
      break;
    case 10:
      month = 'Nov'
      break;
    case 11:
      month = 'Dec'
      break;
    default:
      month = 'Jan'
  }
  
  return (`${month} ${day}, ${year}`)
}

export const dateFormat = (input) => {
  
  const date = new Date(input);
  const day = date.getDate();
  let month = date.getMonth();

  switch (month){
    case 0:
      month = 'Jan'
      break;
    case 1:
      month = 'Feb'
      break;
    case 2:
      month = 'Mar'
      break;
    case 3:
      month = 'Apr'
      break;
    case 4:
      month = 'May'
      break;
    case 5:
      month = 'Jun'
      break;
    case 6:
      month = 'Jul'
      break;
    case 7:
      month = 'Aug'
      break;
    case 8:
      month = 'Sept'
      break;
    case 9:
      month = 'Oct'
      break;
    case 10:
      month = 'Nov'
      break;
    case 11:
      month = 'Dec'
      break;
    default:
      month = 'Jan'
  }
  // return day
  return (`${month} ${day}`)
}


export const dateNumeric = (input) => {
  const date = new Date(input);
  const year = date.getFullYear();
  const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${year}-${month}-${day}`
}
export const dateNumericVar = (input) => {
  const date = new Date(input);
  const year = date.getFullYear();
  const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${month}/${day}/${year}`
}
export const dateNumericMonthDay = (input) => {
  const date = new Date(input);
  const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${month}/${day}`
}

export const hasEnded = (input) => {
  const today = new Date();
  const endDate = new Date(input)
  
  if( 
    endDate.getFullYear() === today.getFullYear() &&
    endDate.getMonth() === today.getMonth() &&
    endDate.getDate() === today.getDate() 
  ) 

  { return false }

  else 

  { return +today >= +endDate; }
}

export const hasStarted = (input) => {
  const today = new Date();
  const startDate = new Date(input)
  
  if( startDate.getFullYear() === today.getFullYear() &&
      startDate.getMonth() === today.getMonth() &&
      startDate.getDate() === today.getDate() ) 

      { return true }

  else 

    { return +today >= +startDate; }
  
}

export const addDays = (dateObj, numDays)  => {
  dateObj.setDate(dateObj.getDate() + numDays);
  return dateObj;
}

export const dayDiff = (dateOne, dateTwo, variant) => {
  const date1 = new Date(dateOne);
  const date2 = new Date(dateTwo);
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  if(date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()) { 
    return 0 // zero for same day
  } 



  // return variant === 'start'||variant === 'ended' ? diffDays-1 : diffDays; //start date and past-date calculation requires minus one
  return variant === 'start' ? diffDays-1 : diffDays; //start date calculation requires minus one  

}

export const simpleTimeFormat = (date) => {
  const h = date.getHours();
  const m = date.getMinutes();
  const hours = h>=10 ? h : `0${h}`;
  const minutes = m>=10 ? m : `0${m}`;

  return  `${hours}:${minutes}`
}









  // return variant === 'start'||variant === 'ended' ? diffDays-1 : diffDays; //start date and past-date calculation requires minus one