import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';

import styles from './AddParentModal.module.scss';

const AddParentModal = () => {

  const formRef = useRef();
  const [ errorVis, setErrorVis ] = useState(false)

  const [ formData, setFormData ] = useState({first_name:'', last_name:'', email:''})
  const { first_name, last_name, email } = formData

  const handleSubmit = () => {
    if( !first_name || !last_name || !email ){
      formRef.current.classList.add('shake');
      setErrorVis(true)
      setTimeout(()=>{formRef.current && formRef.current.classList.remove('shake');},1500)
    }
    console.log(formData)
  }

  return (
    <div className={styles.container}>
      <h1>Add Parent/Guardian</h1>

      <form ref={formRef}>
        <label>First name:</label>
        <input onChange={(e)=>{setFormData({...formData, first_name:e.target.value}); setErrorVis(false)}} type="text" name="first_name" id="firstName" placeholder="John"/>
        <label>Last name:</label>
        <input onChange={(e)=>{setFormData({...formData, last_name:e.target.value}); setErrorVis(false)}} type="text" name="Last_name" id="lastName" placeholder="Smith"/>
        <label>Email:</label>
        <input onChange={(e)=>{setFormData({...formData, email:e.target.value}); setErrorVis(false)}} type="email" name="email" id="email" placeholder="email@escribli.com"/>
      </form>
      
      {errorVis && <p className={styles.error}>Please comlpete all fields</p>}

      <div onClick={handleSubmit} className={styles.btn}> Add parent/guardian</div>
    </div>
  )

}

export default AddParentModal;