import React from "react";
import { Page, Text, View, Document, Image, Font } from "@react-pdf/renderer";

import cover_green from 'assets/book-cover-green.jpg'
import cover_pink from 'assets/book-cover-pink.jpg'
import cover_light_pink from 'assets/book-cover-light-pink.jpg'
import cover_purple from 'assets/book-cover-purple.jpg'
import cover_journal_escribli from 'assets/book-cover-journal-escribli.jpg'
import cover_journal from 'assets/book-cover-journal.jpg'
import cover_faith from 'assets/book-cover-faith.jpg'
import cover_faith2 from 'assets/book-cover-faith2.jpg'
import cover_bee from 'assets/book-cover-bee.jpg'
import cover_bee2 from 'assets/book-cover-bee2.jpg'

import kurphSrc from "./fonts/kurph-webfont.ttf"
import beachSrc from "./fonts/beachday.ttf"
import rifficSrc from "./fonts/riffic.ttf"
import aldiSrc from "./fonts/aldi.ttf"
import josefinSrc from "./fonts/josefinsans_semibold.ttf"
import nexaSrc from "./fonts/Nexa_black.ttf"

import { coverStyles } from "./coverStyles";


export function RenderCoverContent({ pdfData, pageCount, coverChoice }) {

  const { 
    projectTitle, 
    studentName, 
    coverType, 
    coverColor, 
    printCode, 
    backCoverCode, 
    avatarFile 
  } = pdfData

  const titleTextClass = 
    projectTitle.length < 10 ? "story_title_text_short" :
    projectTitle.length > 10 && projectTitle.length < 20 ? "story_title_text" : "story_title_text_long"

  const bleed = 0.125
  const spine_width = (pageCount/444) + 0.06
  const page_width = 6
  const cover_height = (9 + (bleed * 2))*72 
  const cover_width = ((page_width * 2) + (spine_width + (bleed * 2)))*72  
  // multipy by 72 to convert inches to pt for use with react-pdf

  const styles = coverStyles(cover_height, cover_width)

  const storyCover = 
    coverColor === 0 ? cover_green:
    coverColor === 1 ? cover_light_pink :
    coverColor === 2 ? cover_pink :
    coverColor === 3 ? cover_purple : null

  const faithCover = 
    coverChoice === 1 ? cover_faith :
    coverChoice === 2 ? cover_faith2 : cover_light_pink

  const beeCover = 
    coverChoice === 1 ? cover_bee :
    coverChoice === 2 ? cover_bee2 : cover_bee


  Font.register({
    family: 'riffic',
    src: rifficSrc
  })

  Font.register({
    family: 'Josefin',
    src: josefinSrc
  })

  Font.register({
    family: 'Kurph',
    src: kurphSrc
  })

  Font.register({
    family: 'Aldi',
    src: aldiSrc
  })

  Font.register({
    family: 'TitleFont',
    src: beachSrc
  })

  Font.register({
    family: 'Nexa',
    src: nexaSrc
  })

  Font.register({ 
    family: 'Oswald',
    fonts:[
      {
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
        fontWeight: 900
      }
    ]
  });


  
  if(coverType==='story')return(

    <Document>
      <Page size={{width: cover_width, height: cover_height}}>

        <Image style={styles.absoluteFullpage} src={storyCover}></Image>

        <View style={styles.pageBack}>
          <View style={styles.story_codes}>
            <Text style={styles.story_code_text}>Pin code: {printCode}</Text>
            <Text style={styles.story_code_text}>{backCoverCode}</Text>
          </View>
        </View>
        <View style={styles.pageFront}>
          <Text style={styles[titleTextClass]}>{projectTitle}</Text>
          <Image style={styles.story_boomer} src={`data:image/png;base64,${avatarFile}`}></Image>
          <View style={styles.story_author}>
            <Text style={styles.story_author_text}>Alternate ending by:</Text>
            <Text style={styles.story_author_text}>{studentName}</Text>
          </View>
          
        </View>
      </Page>
    </Document>
    
  )
  
  if(coverType==='journal')return(

    <Document>
      <Page size={{width: cover_width, height: cover_height}}>

        <Image style={styles.absoluteFullpage} src={cover_journal_escribli}></Image>

        <View style={styles.pageBack}>
          <View style={styles.journal_codes}>
            <Text style={styles.journal_code_text}>Pin code: {printCode}</Text>
            <Text style={styles.journal_code_text}>{backCoverCode}</Text>
          </View>
        </View>
        <View style={styles.pageFrontPadding}>
          <Image style={styles.journal_boomer} src={`data:image/png;base64,${avatarFile}`}></Image>
          <View style={styles.journal_details}>
            {/* <Text style={styles.journal_heading_text}>Escribli Journal</Text> */}
            <Text style={styles.journal_title_text}>{projectTitle}</Text>
            <Text style={styles.by_text}>By</Text>
            <Text style={styles.journal_heading_text}>{studentName}</Text>
          </View>
          
        </View>
      </Page>
    </Document>

  )

  
  if(coverType==='bee')return(

    <Document>
      <Page size={{width: cover_width, height: cover_height}}>

        <Image style={styles.absoluteFullpage} src={beeCover}></Image>

        <View style={styles.pageBack}>
          <View style={styles.bee_codes}>
            <Text style={coverChoice===1?styles.bee_code_text:styles.bee_code_text2}>Pin code: {printCode}</Text>
            <Text style={coverChoice===1?styles.bee_code_text:styles.bee_code_text2}>{backCoverCode}</Text>
          </View>
        </View>
        <View style={styles.pageFront}>
          <Text style={coverChoice===1?styles.bee_author_text:styles.bee_author_text2}>{studentName}</Text>
        </View>
      </Page>
    </Document>

  )

  
  if(coverType==='faith')return(

    <Document>
      <Page size={{width: cover_width, height: cover_height}}>

        <Image style={styles.absoluteFullpage} src={faithCover}></Image>


        <View style={styles.pageBack}>
          <Image style={styles.faith_boomer} src={`data:image/png;base64,${avatarFile}`}></Image>
          {coverChoice === 2 &&
              <Text style={styles.sponsor_text}>Thank you to our generous sponsor:</Text>
          }
        </View>

        <View style={styles.pageFrontPaddingTwo}>
          <Text style={coverChoice === 1 ? styles.faith_title_text : styles.faith_title_text_black }>{projectTitle}</Text>
          <Text style={coverChoice === 1 ? styles.faith_heading_text : styles.faith_heading_text_black}>My {coverChoice === 1 ? "Faith" : "Bible"} Journal</Text>
          <Text style={coverChoice === 1 ? styles.faith_by_text : styles.faith_by_text_black}>By</Text>
          <Text style={coverChoice === 1 ? styles.faith_author_text : styles.faith_author_text_black}>{studentName}</Text>
        </View>
      </Page>
    </Document>

  )


  return <Document><Text>no activity</Text></Document>

}
