import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { returnPlatformStyle } from 'helpers/altPlatformFunctions';
import { showModal } from 'helpers/uiFunctions';
import { sendToApi } from 'API/generalCalls';
import { clearMessages, updateDashData } from 'Redux/actions';
import { initiateSocket } from 'cable';

import styles from './Nav.module.scss';
// import styles from './NavCollapsable.module.scss';

import resources from 'assets/newNavAssets/resources.svg'
import admin from 'assets/newNavAssets/admin.svg'
import calendar from 'assets/newNavAssets/calendar.svg'
import profile from 'assets/newNavAssets/profile.svg'
import tutorials from 'assets/query_white.svg'
import notifications from 'assets/newNavAssets/notifications.svg'
import chat from 'assets/newNavAssets/chat.svg'
import home from 'assets/newNavAssets/home.svg'
import NavLogo from './NavLogo';
import { store } from 'storeConfig';
import { useUserType } from 'context/AltPlatformProvider';

const Nav = ({ modalOverlay, permissions }) => {
  
  const location = useLocation()
  const dashData = useSelector(state => state.dashData);
  const navOpen = useSelector(state => state.navOpen);
  const [popupVis, setPopupVis] = useState(false);
  const unreadCount = useSelector(state => state.chatData.unreadCount)
  const hasNotifications = useSelector(state => state.dashData.hasNotifications)
  const { fjUser } = useUserType();
  const isAdmin = localStorage.getItem('a')
  
  //connect/disconnect WebSocket
  useEffect(()=>{
    initiateSocket(dashData.chatterToken, dashData.chatterId, dashData.name)
  },[dashData.chatterToken])

  const openChat = () => {
  store.dispatch( clearMessages() )
  store.dispatch(updateDashData('chatOpened', true));
  showModal(modalOverlay, {type:'chatModal', 
    user:{
      name:dashData.name, 
      chatter_id:dashData.chatterId,
      chatter_token:dashData.chatterToken
      }
    });
  }
  
  return (
    <>

      {

        location.pathname.startsWith('/auth') ? null : //hide on auth routes

          <div className={`hideMobile ${styles.container} ${navOpen && styles.navOpen} ${fjUser && styles[returnPlatformStyle('fj')]}`}>

              <div className={styles.top}>

                <NavLogo
                  fjUser={fjUser}
                  navOpen={navOpen} />

                <NavLink
                  to="/teacher/classes" exact
                  activeClassName={`${styles.activeItem} ${fjUser && styles[returnPlatformStyle('fj')]}`}
                  className={styles.navItem}>
                  <img src={home} alt="home" />
                  <div className={styles.tooltip}>Home</div> 
                  <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />
                </NavLink>

                {
                  permissions?.includes('admin') &&
                  <NavLink 
                    to="/teacher/admin/dashboard" 
                    activeClassName={`${styles.activeItem} ${fjUser && styles[returnPlatformStyle('fj')]}`}
                    className={styles.navItem}>
                    <img src={admin} alt="admin" />
                    <div className={styles.tooltip}>School</div> 
                    <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />
                  </NavLink>
                }

                <NavLink
                  to="/teacher/resources"
                  activeClassName={`${styles.activeItem} ${fjUser && styles[returnPlatformStyle('fj')]}`}
                  className={styles.navItem}>
                  <img src={resources} alt="resources" />
                  <div className={styles.tooltip}>Resources</div> 
                  <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />
                </NavLink>

                <div
                  onClick={() => showModal(modalOverlay, { type: "notifications" })}
                  className={`${styles.navItem} ${hasNotifications > 0 && styles.solid}`}>
                  {hasNotifications && <div className={styles.new}></div>}
                  <img src={notifications} alt="notifications" />
                  <div className={styles.tooltip}>Notifications</div> 
                  <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />
                </div>

                <div
                  onClick={() => showModal(modalOverlay, { type: "calendarModal" })}
                  className={`${styles.navItem}`}>
                  <img src={calendar} alt="calendar" />
                  <div className={styles.tooltip}>Calendar</div> 
                  <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />
                </div>

                <div
                  onClick={openChat}
                  className={`${styles.navItem} ${unreadCount > 0 && styles.solid}`}>
                  {unreadCount > 0 ? <div className={styles.unreadIndicator}>{unreadCount}</div> : null}
                  <img src={chat} alt="chat" />
                  <div className={styles.tooltip}>Chat</div> 
                  <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />
                </div>

              </div>

              <div className={styles.bottom}>

                {
                  fjUser &&
                  <NavLink 
                      to={"/teacher/tutorials"} 
                      activeClassName={`${styles.activeItem} ${fjUser && styles[returnPlatformStyle('fj')]}`}
                      className={`${styles.navItem} ${styles.tutorials}`}>
                    <div className={styles.tooltip}>Tutorials</div>
                    <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />
                    <img src={tutorials} alt="tutorials" />
                  </NavLink>
                }

                <div
                  onMouseLeave={() => { setPopupVis(false) }}
                  onClick={() => setPopupVis(!popupVis)}
                  activeClassName={`${styles.activeItem} ${fjUser && styles[returnPlatformStyle('fj')]}`}
                  className={`${styles.navItem} ${styles.profile}`}>
                  <img src={profile} alt="profile" />
                  {!popupVis && <div className={styles.tooltip}>Profile</div> }
                  {/* <div className={styles.tooltip}>Profile</div>  */}
                  <div className={`${styles.colorTab} ${fjUser && styles[returnPlatformStyle('fj')]}`} />

                  <div
                    className={(popupVis ? `${styles.popupMenu} ${fjUser && styles[returnPlatformStyle('fj')]}` : `${styles.popupMenu} ${fjUser && styles[returnPlatformStyle('fj')]} ${styles.hidden}`)}>

                    <div className={styles.block} />

                    {
                      // <Link to='/teacher/account/subscription'>
                      <Link to='/teacher/account/subscription'>
                        <div onClick={() => { setPopupVis(false) }} className={`${styles.row} ${styles.popupRow} ${styles.sub}`}>Subscription</div>
                      </Link>
                    }

                    {
                      isAdmin == "true" &&
                      <Link to='/admin/users'>
                        <div onClick={() => { setPopupVis(false) }} className={`${styles.row} ${styles.admin} ${styles.popupRow}`}>Reports</div>
                      </Link>
                    }


                    <Link to='/account/profile'>
                      <div onClick={() => { setPopupVis(false) }} className={`${styles.row} ${styles.popupRow} ${styles.settings}`}>Settings</div>
                    </Link>

                    <div onClick={() => sendToApi('auth/logout', '', 'logout')} className={`${styles.row} ${styles.popupRow} ${styles.logout}`}>
                      Logout
                    </div>

                  </div>

                </div>

              </div>
              
          </div>
      }


    </>
  )
};

export default Nav;