import { getFromApi } from 'API/teacherCalls';
import { loadDashData, setUnread, updateDashData } from 'Redux/actions';
import { store } from 'storeConfig';


export const getDashData = async (env) => {

  localStorage.removeItem('dashRecall') //avoid recalling API by removing this key

  store.dispatch(updateDashData('teacherId', 'xx'))
  const data = await getFromApi('dashboard');
  store.dispatch(loadDashData(data))
  store.dispatch(setUnread(data.unreadChatCount))

  if (!localStorage.getItem('imgUrlKey')) {
    const num = Math.floor(Math.random() * 3);
    localStorage.setItem('imgUrlKey', num)
  }
}

export const redirectClassActivities = (classId) => { // retirect for approving requests

  const frontUrl = localStorage.getItem('frontUrl')
  window.location = `${frontUrl}/teacher/classes/view/${classId}/?activities`
}

export const createNewActivityFunc = (data, details ) => {

  localStorage.setItem('dashRecall', true)
  
  const frontUrl = localStorage.getItem('frontUrl')
  const classId = details.onboarding ? details.classObj.classId : details.fetchedClass.classId;

  const dashCopy = { ...details.dashData }
  let dashClassArray = dashCopy.classes;
  const dashClassIndex = dashClassArray.findIndex(item => item.classId === classId);

  
  if(!details.onboarding){
    if (dashCopy.classes[dashClassIndex] && dashCopy.classes[dashClassIndex].activities === 'initial') {
      dashCopy.classes[0].activities = []
      dashCopy.classes[0].activities.unshift(data)
    }

  } else {
    const newClass = {...details.classObj};
    newClass.activities=[data]
    dashCopy.classes=[newClass]
  }

  store.dispatch(loadDashData(dashCopy));

  const newId = String(data.activityId);
  window.location = `${frontUrl}/teacher/classes/${classId}/${newId}/true`
}
