import React from 'react';
import { Link } from 'react-router-dom';
import { returnPhaseText } from 'helpers/returnPhaseText';

import styles from './ChapterList.module.scss';
import { sendToApiReturn } from 'API/teacherCalls';
import { useState } from 'react';
import { showModal } from 'helpers/uiFunctions';

const ChapterList = ({ modalOverlay, modal, activeId, students, activity, activity: { activityId, activityClassId, activityName, sections, activityType }}) => {

  // const rowObjs = modal ? sections : sections?.filter(i => i.sectionId != activeId );
  const rowObjs =  sections;

  const [ loading, toggleLoading ] = useState(false)

  const handleNewSection = async () => {
    toggleLoading(true)
    const newSection = await sendToApiReturn(`activity/${activityId}/new_unit`)
    window.location = `/teacher/classes/${activityClassId}/${activityId}/settings/${newSection.sectionIndex}`
    
  }

  const toggleModal = () => {
    showModal(modalOverlay, {type:"chapterList", activeId, activity, modal:true})
  }


  return (
    <div className={`${styles.container} ${modal && styles.modal}`}>
      <div className={styles.titleBar}>
        <h4>More chapters</h4>
        <div className={styles.pdfBtn}
            onClick={() => { showModal(modalOverlay, { type: "pdfGen", students, activity, mobile: true}); }} />
      </div>
      <div className={styles.main}>
        <div className={styles.topRow}>
          <h3>{activityName}</h3>
          <div className={styles.dots}/>
        </div>
        <div className={styles.chapterRows}>
          {
            rowObjs?.map((item, idx) => 
              (idx<4 || modal ) &&
                <Link to={`/teacher/classes/${activityClassId}/${activityId}/settings/${item.sectionIndex}`} 
                  className={`${styles.row} ${(activityType=='story'&&item.sectionIndex===0 ) && styles.storyStart} ${styles[returnPhaseText(item.sectionPhase)]} ${item.sectionId === activeId && styles.selected}`}>
                  <div className={styles.left}>
                    <p className={styles.numberDot}>{item.sectionIndex+1}</p>
                    <p className={styles.sectionName}>{item.sectionName}</p>
                  </div>
                  <div className={styles.right}>
                   {(item.totalEntries > 0 ) &&  <p>{item.totalEntries} response{item.totalEntries>1?"s":""}</p>}
                   {item.unseenEntries > 0 && <p className={styles.newEntries}>{item.unseenEntries} NEW</p>}
                  </div>
                </Link>
            )
          }
        </div>
        <div onClick={handleNewSection} className={`${loading && styles.loading} ${styles.newChapterBtn}`}>{!loading&&`Add new${modal?" chapter":""}`}</div>

       {!modal && <div onClick={toggleModal} className={styles.showAllBtn}>Show all chapters</div>}
      </div>
    </div>
  )

}

export default ChapterList;