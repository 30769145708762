import React from 'react';

import styles from './ClassActivityBlock.module.scss';

const ActivitySectionBlock = ({ handleNavigate, idx, isStoryStart, archived, section: { sectionPhase, sectionName, sectionIndex, unseenEntries, votesCount, entriesSubmitted } }) => {

  const phaseClass = 
    isStoryStart ? 'storyStart' :
    sectionPhase===1 ? 'draft' :
    sectionPhase===21 ? 'scheduled' :
    sectionPhase===4 ? 'voting' :
    ([2,3,5].indexOf(sectionPhase) !== -1) ? 'live' :
    ([7,8,10].indexOf(sectionPhase) !== -1) ? 'completed' : null


  return (
    <div onClick={()=>handleNavigate(sectionIndex)} key={idx} className={`${styles.sectionDiv} ${styles[phaseClass]} ${archived && styles.archived}`}>
      <div className={styles.left}>
        <div className={styles.numberCircle}>
          <p>{sectionIndex+1}</p>
        </div>
        <h5>{!isStoryStart ? sectionName : 'StoryStart'}</h5>
      </div>
      <div className={styles.submissions}>
        
        <p>
          {
            phaseClass === 'live' ?
                `${ (!entriesSubmitted || entriesSubmitted===0) ? 0 : entriesSubmitted} submission${entriesSubmitted !== 1 ? "s" : ""}`

            : phaseClass === 'voting' &&
                `${votesCount} vote${votesCount !== 1 ? "s" : ""}`

          }
        </p>
        {
          (unseenEntries > 0 && phaseClass !== 'voting') &&

            <p className={styles.unseen}>{unseenEntries} NEW</p>
        }
      </div>
    </div>
  )

}

export default ActivitySectionBlock;

//PHASES
// if (phase===3 || phase===5) return 'Live'
// if (phase===2) return 'started writing'
// if (phase===4) return 'voting'
// if (phase===1) return 'draft'
// if (phase===21) return 'scheduled'
// if (phase===7||phase===10||phase===8) return 'Completed'