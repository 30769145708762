import { returnPlatformStyle } from 'helpers/altPlatformFunctions';
import { showModal } from 'helpers/uiFunctions';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { clearMessages, updateDashData } from 'Redux/actions';
import { store } from 'storeConfig';

import styles from './NavMobile.module.scss';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import ResponsiveNotificationsModal from 'components/StructuralComps/Modal/TeacherNotifications/ResponsiveNotificationsModal';
import { sendToApiReturn } from 'API/teacherCalls';

const NavMobile = ({ fjUser, modalOverlay }) => {


  const dashData = useSelector(state => state.dashData)
  const popupRef = useRef();
  const location = useLocation();
  const [ isProfile, setIsProfile ] = useState(false)
  const [ menuOpen, toggleMenu ] = useState(false)
  const [ notificationsOpen, toggleNotifications ] = useState(false)
  const hasNotifications = useSelector(state => state.dashData.hasNotifications)

  useEffect(()=>{
    console.log(location.pathname.split('/'))

  },[location])

  const openChat = () => {
    store.dispatch( clearMessages() )
    store.dispatch(updateDashData('chatOpened', true));
    showModal(modalOverlay, {type:'chatModal', 
      user:{
        name:dashData.name, 
        chatter_id:dashData.chatterId,
        chatter_token:dashData.chatterToken
        }
      });
    }

    const handleMiniMeLogin = async () => {
      const res = await sendToApiReturn('mini_me', '', 'mini-me')
      let miniMeUrl = res.url
      if(window.location.host === "localhost:3000") {
        miniMeUrl = "http://localhost:3011/auth/student/login_as"
      }
      localStorage.setItem('resURL', miniMeUrl)
      window.location.href = miniMeUrl;
    }


    const handleCloseMenu = () => {
      if(menuOpen){
        toggleMenu(false)
      }
    }

    useOutsideClick(popupRef, handleCloseMenu)

  return (
    <div className={`${styles.container} ${fjUser&&styles[returnPlatformStyle('fj')]}`}>
      <NavLink
          to="/teacher/classes"  
          className={`${styles.navItem} ${styles.one}`}
          activeClassName={`${styles.activeItem}`}
          >
        <div className={`${styles.icon} ${styles.home}`} />
        <h5>Home</h5>
      </NavLink>
      <NavLink 
        to="/teacher/chat"  
        className={`${styles.navItem} ${styles.two}`}
        activeClassName={`${styles.activeItem}`}>
        <div className={`${styles.icon} ${styles.chat}`} />
        <h5>Chat</h5>
      </NavLink>
      {/* <div onClick={()=>showModal(modalOverlay, {type:"responsiveNotifications"})} className={`${styles.navItem} ${styles.three}`}> */}
      <div onClick={()=>toggleNotifications(!notificationsOpen)} className={`${styles.navItem} ${styles.three} ${notificationsOpen && styles.activeItem}`}>
        {hasNotifications && <div className={styles.new}></div>}
        <div className={`${styles.icon} ${styles.notifications}`} />
        <h5>Notifications</h5>
      </div>

      <div onClick={()=>{ toggleMenu(!menuOpen); setIsProfile(!isProfile)}} className={`${styles.navItem} ${styles.four} ${isProfile && styles.activeItem}`}>
        <div className={`${styles.icon} ${styles.profile}`} />
        <h5>Profile</h5>
      </div>

      <div ref={popupRef} className={`${styles.popupMenu} ${menuOpen && styles.open} `}>
        <div onClick={handleMiniMeLogin} className={`${styles.popupRow} ${styles.miniMe}`}>
          <div className={styles.icon} />
          <h5>Mini Me "Student"</h5>
        </div>
        <NavLink to={"/teacher/account/subscription"} className={`${styles.popupRow} ${styles.subscription}`}>
          <div className={styles.icon} />
          <h5>Subscription</h5>
        </NavLink>
        <NavLink to={"/teacher/account/profile"} className={`${styles.popupRow} ${styles.settings}`}>
          <div className={styles.icon} />
          <h5>Settings</h5>
        </NavLink>
        <NavLink to={"/auth/logout"} className={`${styles.popupRow} ${styles.logout}`}>
          <div className={styles.icon} />
          <h5>Logout</h5>
        </NavLink>

        <div className={styles.block} />
      </div>

      <ResponsiveNotificationsModal open={notificationsOpen} handleClose={()=>toggleNotifications(!notificationsOpen)}/>

    </div>
  )

}

export default NavMobile;