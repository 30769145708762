import React from "react";
import { useSelector } from 'react-redux'
import boomersParty from 'assets/boomerGroup.png';
import logo from 'assets/logo_escribli_blue.png'
import logoFooter from 'assets/logo_escribli_circle2.png'
import logoJournal from 'assets/logo-journal--text.png'
import logoJournalFooter from 'assets/logo-journal.png'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import { stripTags } from "helpers/stripTags";

Font.register({ family: 'Oswald',src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf', fontStyle: 'normal', fontWeight: 'normal'});

const styles = StyleSheet.create({
  page: {
    paddingTop: 45,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "#ffffff",
  },
  title_page: {
    backgroundColor: "#ffffff",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    padding: "50px 50px 30px 50px",
  },
  title_page_text: {
    whiteSpace:"nowrap",
    position:"relative",
    bottom:"200px",
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    marginTop:100,
  },
  title_text: {
    whiteSpace:"wrap",
    width:400,
    textAlign:"center",
    fontSize:45,
    fontWeight:900,
    fontFamily:"Oswald"
  },
  boomers_img: {
    width:"150px",
    height:"auto",
    margin:"20px 0 5px 0",
    objectFit:"contain"
  },
  sudent_name_text:{
    width:350,
    textAlign:"center",
    fontSize: 10,
    fontFamily:"Oswald"
  },
  text_medium: {
    fontSize: 13,
    marginBottom:"3px"
  },
  text_small: {
    fontSize: 12,
  },
  text_body: {
    marginTop:"20px",
    fontSize: 16,
    lineHeight:2,
  },
    footer_text: {
    fontSize: 14,
    marginTop: 20,
    position: 'absolute',
    right:"0px",
    bottom:"20px",
    color: 'grey',
  },
  logo: {
    width:"140px",
    height:"auto",
    margin:"15px 0",
    objectFit:"contain",
  },
  logo_faith: {
    width:"140px",
    height:"auto",
    margin:"15px 0",
    objectFit:"contain",
  },
  chapter_title_text:{
    fontWeight:700,
    fontSize:25,
    marginBottom:"5px"
  },
  chapter_details_subtext:{
    display: "flex",
    flexDirection: "row",
    display:"flex",
    width:500,
    margin:"5px 0"
  },
  chapter_instuctions: {
    backgroundColor:"#f2f2f2",
    margin: "5px 0",
    padding:"5px"
  },
  chapter_instuctions_item: {
    margin: "5px 0",
  },
  chapter_details_subtext_item:{
    display: "flex",
    flexDirection: "row",
    display:"inline",
    fontSize:14,
    color:"#cacaca",
    marginRight:20
  },
  footer: {
    width: 600,
    display:"flex",
    flexDirection: "row",
    justifyContent:"center",
    height:"50px",
    position:"absolute",
    bottom:"20px",
  },
  logo_footer: {
    width:"35px",
    height:"35px",
    margin:"5px 0",
    objectFit:"cover",
    transform:"translateX(50%)"
  },

});

export function RenderDownloadContent({ pdfData, isFaithJournal }) {

  const { students, teacherName, storyStart, projectTitle } = pdfData


  return (

    <Document>

        <Page style={styles.title_page} size={"A4"}> 
          {/* //<----------------------------TITLE PAGE  */}
          <View style={styles.title_page_text}>

            {
              !isFaithJournal ?  
              <>
                {/* <Image source={boomersParty} style={styles.boomers_img} /> */}
                <Image source={logo} style={styles.logo} /> 
              </>
            :
                <Image source={logoJournal} style={styles.logo_faith} />
            }

            <Text style={styles.title_text}>{projectTitle}</Text>
           
          </View>
        </Page>

        {
         storyStart?.split(' ').length && 
            <Page style={styles.page} wrap={true} size={"A4"}>
              <View>
                <Text style={styles.chapter_title_text}>Story Start</Text>
                <Text style={styles.text_body}>{storyStart}</Text>
              </View>
              <View style={styles.footer} fixed>  
                <Image source={!isFaithJournal?logoFooter:logoJournalFooter} style={styles.logo_footer} />
              </View>
            </Page>
        }

        {        
          students.map(student => 

            student.entries?.map(entry => 
              <Page style={styles.page} wrap={true} size={"A4"}> 
                <View style={styles.chapter_details_text}>
                  <Text style={styles.chapter_title_text}>{entry.unitTitle}</Text>
                  <Text style={styles.chapter_author_text}>By {student.studentName}</Text>
                  <View style={styles.chapter_details_subtext}>
                    {teacherName && <Text style={styles.chapter_details_subtext_item}>Teacher: {teacherName}</Text>}
                    <Text style={styles.chapter_details_subtext_item}>Date submitted: {entry.submittedAt}</Text>
                  </View>
                </View>
                <View style={styles.chapter_instuctions}>  

                  {entry.instructions?.split(' ').length && 
                    <View style={styles.chapter_instuctions_item}>
                      <Text style={styles.text_medium}>Instructions:</Text>
                      <Text style={styles.text_small}>{stripTags(entry.instructions.replaceAll('<p>', '\n').replaceAll('<li>', '\n'))}</Text>
                    </View> }

                  <View style={styles.chapter_instuctions_item}>
                    <Text style={styles.text_medium}>Vocabulary: </Text>
                    <Text style={styles.text_small}>{entry.vocabulary}</Text>
                  </View>
                </View>

                { !entry.entryText?.length  ?  null 
                
                  : entry.entryText?.map(text=> <Text style={styles.text_body}>{text}</Text> ) 
                }

                {entry.feedback?.split(' ').length && 
                  <View style={styles.chapter_instuctions}>
                    <View style={styles.chapter_instuctions_item}>
                      <Text style={styles.text_medium}>Teacher Feedback: </Text>
                      <Text style={styles.text_small}>{entry.feedback}</Text>
                    </View>
                  </View>}

                <View style={styles.footer} fixed>  
                  <Image source={!isFaithJournal?logoFooter:logoJournalFooter} style={styles.logo_footer} />
                </View>
              </Page>
            )
          ) 
        }
    </Document>
  )
}

