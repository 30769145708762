import React from 'react';

import styles from './TutorialsModal.module.scss';
import play from 'assets/icon-play.svg'

const TutorialDiv = ({ item: { title, tag, url, prevImg }, playerRef, setPlayer }) => {

  const pillClass = (tag) => {
    if(tag==='basic')return `${styles.tagPill} ${styles.basic}`
    if(tag==='tips and tricks')return `${styles.tagPill} ${styles.tips}`
    if(tag==='writing bee')return `${styles.tagPill} ${styles.writing}`
    if(tag==='onboarding')return `${styles.tagPill} ${styles.onboarding}`
  }

  return (
    <div onClick={()=>{
      setPlayer({url:url})
      playerRef.current.classList.add(styles.show)
      console.log("click")
      }} 
      className={styles.tutorialDiv}>
      <h3>{title}</h3>
      <img src={play} alt="play"/>
      <div className={styles.tags}>
        <p>Tags:</p>
        <div className={pillClass(tag)}>{tag}</div>
      </div>
      <div className={styles.bg}>
        <div className={styles.blue}></div>
        <img src={prevImg} alt="preview"/>
      </div>
    </div>
  )

}

export default TutorialDiv;