export const chunkedArray = (arr, chunkSize, listLength) =>{
  
  const oddLength = arr.length % chunkSize !== 0 //list not divisible by chunkSize
  const chunkedArr = [];

  console.log(arr)
  console.log(chunkSize)
  console.log(listLength)

  arr.forEach((val, idx) => {

    // Get last element
    const last = chunkedArr[chunkedArr.length - 1];

    // if list not divisible, the final array will need to be 1 item longer
    if(idx===listLength-1&&oddLength)  {
      last.push(val);

    }else if( !last || last.length === chunkSize) {
      chunkedArr.push([val]);

    } else {
      last.push(val);

    }
  });

  return chunkedArr;
}


    // const getChunkArray = (arr, len) => {
    //   const chunkArray = [];
    //   let i = 0;
    //   while (i < arr.length) {
    //     const isRemainder = (arr.length % 2 !== 0 && i === arr.length-1)
    //     chunkArray.push(arr.slice(i, i + (!isRemainder ? len : len+1)));
    //     i = i + len;
    //   }
    //   return chunkArray;
    // };


