import { tutorialFilter } from 'helpers/filterFunctions';
import React, { useRef, useState, useEffect } from 'react';
import TutorialDiv from './TutorialDiv';
import ReactPlayer from 'react-player/youtube'
import styles from './TutorialsModal.module.scss';
import tutorials from './tutorialLinks'
import closeImg from 'assets/icon-close--circle.svg'
const TutorialsModal = ({ closeBtn }) => {

  const [ filterValues, setFilterValues ] = useState({radio:'all', text:''})
  const[ player, setPlayer ] = useState({url:''})
  const playerRef = useRef();

  // useEffect(()=>{
  //   if(player.url!==''){
  //     console.log(player);
  //     setTimeout(()=>{
  //       playerRef.current&&playerRef.current.classList.add(styles.show)
  //     },200)
      
  //   } else {
  //     setTimeout(()=>{
  //       playerRef.current&&playerRef.current.classList.remove(styles.show)
  //     },200)
      
  //   }
  // },[player])

  return (
    <div className={styles.container}>

     {/* {player.vis&& */}
        <div ref={playerRef} className={styles.playerDiv}>

          <div
            onClick={()=>{
              setPlayer({url:''})
              playerRef.current&&playerRef.current.classList.remove(styles.show)
              }} 
            className={styles.closePlayer}>
            <img src={closeImg} alt="close"/>
          </div>

         <ReactPlayer 
          controls={true}
          width={'990px'}
          height={'557px'}
          url={player.url} />

          <p onClick={()=>{
            setPlayer({url:''})
            playerRef.current&&playerRef.current.classList.remove(styles.show)
            }}>back</p>
        </div>
      {/* } */}

      <h1>Tutorials</h1>
      <p>Let our collection of video tutorials help you get the most out of Escribli</p>
      
      <div className={styles.filters}>
        <div className={styles.search}>
          <input onChange={(e)=>setFilterValues({...filterValues, text:e.target.value})} type="text"/>
          <div className={styles.submit}>Search</div>
        </div>

        <div className={styles.radios} onChange={(e)=>setFilterValues({...filterValues, radio:e.target.value})}>
          <label>
            <input type="radio" name="tutorialsRadios" value='all' defaultChecked/>
            <div className={`${styles.tutorialRadio} ${styles.all}`} >Display all</div>
          </label>
          <label>
            <input type="radio" name="tutorialsRadios" value='basic'/>
            <div className={`${styles.tutorialRadio} ${styles.basic}`} >Basic</div>
          </label>
          <label>
            <input type="radio" name="tutorialsRadios" value='tips and tricks'/>
            <div className={`${styles.tutorialRadio} ${styles.tips}`} >Tips and Tricks</div>
          </label>
          <label>
            <input type="radio" name="tutorialsRadios" value='writing bee'/>
            <div className={`${styles.tutorialRadio} ${styles.bee}`} >Writing Bee</div>
          </label>
          <label>
            <input type="radio" name="tutorialsRadios" value='onboarding'/>
            <div className={`${styles.tutorialRadio} ${styles.onboarding}`} >Onboarding</div>
          </label>
        </div>
      </div>

      <div className={styles.tutorialDivs}>
        {
          tutorials && tutorials.length ?
            tutorials.map((item, idx) => {
              if(tutorialFilter(item, filterValues)){
                return <TutorialDiv key={idx} item={item} setPlayer={setPlayer} playerRef={playerRef} player={player}/>
                
              } else {
                return null
              }
            })
        :null}
      </div>

      <div onClick={()=>closeBtn.current.click()} className={styles.closeBtn}>Close</div>

    </div>
  )

}

export default TutorialsModal;



// if(filter==='all'){
//   return <TutorialDiv item={item} />
  
// } else if (item.tag===filter){
//   return <TutorialDiv item={item} />
  
// } else {
//   return null
// }