import React, { useEffect, useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import styles from './PreviewBigCarouselModal.module..scss'
import useImageSize from '@use-hooks/image-size';

const PreviewBigCarouselModal = ({ item: { item: { images, slideIndex } }, closeBtn }) => {
    // window.addEventListener('keydown', (e) => { if (e.keyCode === 27) { setShow(false) } }); //line to press esc and go off modal
    
    console.log(images)
    const carousel = useRef(Carousel)
    const imageInfo = []



    useEffect(()=>{

        const handleKeyboardNav = (e) =>{
            e.stopImmediatePropagation()
            if (e.key === 'ArrowLeft'&&document.querySelector('.rec-arrow-left')) { 
                document.querySelector('.rec-arrow-left').click() }
            if (e.key === 'ArrowRight'&&document.querySelector('.rec-arrow-right')) { 
                document.querySelector('.rec-arrow-right').click() }
            if (e.key === 'Escape') { closeBtn.current.click() } 
        }
        
        document.addEventListener('keydown', (e) => handleKeyboardNav(e)); 

        return () => document.removeEventListener('keydown', handleKeyboardNav)
        
    // eslint-disable-next-line
    },[])
    
    images.forEach((elm,idx)=>{
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let [width, height] = useImageSize(elm)
        imageInfo.push({ width, height, index: idx, ratio: width / height })
        
    })

    imageInfo.forEach((elm) => {
        if (elm.height > 600) {
            elm.height = 600
            elm.width = 600 * elm.ratio
        }
        if (elm.width > 1000) {
            elm.width = 1000
            elm.height = 1000 / elm.ratio
        }
        
    })

    useEffect(() => { //When show changes carousel slide go to Show(it is the idx number)
        carousel.current.goTo(Number(slideIndex))
    }, [slideIndex])

    return (


        <div style={{ position: 'relative' }}>
            {/* <div className={styles.closeModal} onClick={()=>setShow(false)}><p>X</p></div> */}
            <Carousel itemsToShow={1} disableArrowsOnEnd={false} pagination={false} className={styles.carousel} initialFirstItem={slideIndex} ref={carousel}>
                {images.map((elm, idx) => {
                    return (
                        <div>
                            <img src={elm} key={idx} alt='' width={imageInfo[idx].width} height={imageInfo[idx].height} />
                        </div>
                    )
                })}
            </Carousel>
        </div>



    );
}
export default PreviewBigCarouselModal;