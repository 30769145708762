import React, { useEffect, useRef, useState } from 'react';
import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import { useSwipeable } from 'react-swipeable'
import Notification_Teacher from '../../../StructuralComps/Modal/TeacherNotifications/Notification_Teacher';

import styles from './ResponsiveNotificationsModal.module.scss';
import { LoadingOverlay } from 'components';
import { useHistory } from 'react-router';
import { store } from 'storeConfig';
import { updateDashData } from 'Redux/actions';

const ResponsiveNotificationsModal = ({ open, handleClose }) => {

  const innerRef = useRef();
  const [ showWrapper, toggleWrapper ] = useState(false);
  const [ apiRes, setApiRes ] = useState(null)
  const history = useHistory();

  const callApi = async () => { 
    console.log('call')
    setApiRes( await getFromApi(`notifications`)) 
  }

  const handleNavigate = (classId, activityId, sectionIdx) => {
    history.push(`/teacher/classes/${classId}/${activityId}/responses/${sectionIdx-1}`)
  }

  const handleMarkAsRead = () => {
    const uiItems = document.querySelectorAll(".itemWrapper");
    let i = 0;
    
    while(i<uiItems.length){
      (function (i) {
        setTimeout(function () {
          uiItems[i].classList.add(styles.hide)
        },i*120)
      }(i))
      i++
    }

    setTimeout(()=>{
      setApiRes([])
      sendToApiReturn('submissions_notifs_seen')
      store.dispatch(updateDashData('hasNotifications', false));
    },uiItems.length*165)
  }


  useEffect(()=>{
    if(open){
      toggleWrapper(true)
      setApiRes(null)
      callApi()
      
    } else {
      setTimeout(()=>{ toggleWrapper(false)},300)

    }
  },[open])

  const handlers = useSwipeable({
    onSwipedDown: () => handleClose(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div {...handlers} className={`${styles.container} ${showWrapper && styles.open}`}>
      <div ref={innerRef} className={`${styles.notificationsInner} ${open && styles.open}`}>
        <div className={styles.topBar}>
          <h5>Notifications</h5>
          <div onClick={handleClose} className={styles.close} />
        </div>
        <div className={styles.mainDiv}>
          {
            !apiRes ?
              <LoadingOverlay contained />

            :
              apiRes.length == 0 ?
                <h6>No new notifications</h6>
            :
              apiRes.map((item, idx) => 
                item.type === "newSubmissions" ?
                  <div onClick={()=>handleNavigate(item.classId, item.projectId, item.sectionNumber)} className={`${styles.itemWrapper} itemWrapper`}>
                    <div className={styles.avatarDiv}>
                      <img src={item.studentsAvatars[0]} alt="" />
                    </div>
                    <div className={styles.text}>
                      <h6>{item.studentsNames[0]}<span>{item.studentsNames.length > 1 && ` and ${item.studentsNames.length-1} more` }</span></h6>
                      <p>{item.studentsNames.length > 1 ? "have" : "has"} <span>submitted</span> a chapter to <span>{item.projectTitle}</span></p>
                    </div>
                  </div>

                :  item.type==='teacherRequest' &&

                  <Notification_Teacher
                    teacherName={item.name}
                    classTitle={item.classTitle}
                    inviteId={item.inviteId}
                    classId={item.classId}
                    setApiRes={setApiRes}
                    key={idx}/> 
              )
          }
      
        </div>
        <div onClick={()=>handleMarkAsRead([...apiRes])} className={`${styles.markAsReadBtn} ${apiRes?.length && styles.active}`}>
          Mark all as read
        </div>
      </div>
    </div>
  )

}

export default ResponsiveNotificationsModal;