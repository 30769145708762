import React from 'react';
import { useState } from 'react';

import styles from './CopyPill.module.scss';

const CopyPill = ({ copyData, copyText, blue, smallRadius, bold }) => {

  const [ confirm, toggleConfirm ] = useState(false)

  const handleCopy = () => {
    toggleConfirm(true);   
    setTimeout(()=>{toggleConfirm(false)},1500)
    navigator.clipboard.writeText(copyData) 
  }

  return (
    <div onClick={handleCopy} className={`${styles.container} ${blue && styles.blue} ${smallRadius && styles.smallRadius} ${bold && styles.bold}`}>
      {
        !confirm ?
          <>
            <h5>{copyText ? copyText : copyData}</h5>
            <div className={styles.copyIcon} />
          </>
        :
          <h5>Copied!</h5>
      }
    </div>
  )

}

export default CopyPill;