import React, {useRef, useState, useEffect} from 'react';
import { WhatsappShareButton, TwitterShareButton, EmailShareButton, FacebookShareButton } from 'react-share'

import styles from './ReferAndEarn.module.scss';
import gift_icon from 'assets/icon-gift.svg';
import icon1 from 'assets/refer-icon1.svg';
import icon2 from 'assets/refer-icon2.svg';
import icon4 from 'assets/refer-icon5.svg';
import icon5 from 'assets/refer-icon4.svg';
import icon_link from 'assets/icon-link.svg';
import { getFromApi } from 'API/teacherCalls';

const ReferAndEarn = () => {


  const linkRef = useRef();
  // const twittRef = useRef();
  const [apiData, setApiData] = useState({count:0, url: 'loading link...'})
  const[vis, setVis ] = useState({copyConf:false, tooltip:false})

  const callApi = async () => {
    const response = await(getFromApi('account/refer'));

    if(response){setApiData(response)}
  }

  useEffect(()=>{callApi()},[])

  const copyToClip = () => { 
    setVis({copyConf:true, tooltip:false})  
    setTimeout(()=>{ setVis({copyConf:false, tooltip:false}) },2000)
    navigator.clipboard.writeText(linkRef.current.value)
  }

  return (
    <div className={styles.container}>

      <div className={styles.banner}>
        <img src={gift_icon} alt="gift"/>
        <div className={styles.text}>
          <h1>Escribli Referral Program</h1>
          <p>Share Escribli with other Teachers! For each teacher you refer who purchases an Escribli subscription, you will get <span>one month for FREE</span>, and the referred teacher receives <span>10% off their first month's purchase!</span></p>
        </div>
      </div>

      <div className={styles.main}>

        <div className={styles.pills}>
        {apiData.url ? 
          // <a href={`https://www.facebook.com/sharer/sharer.php?u=${apiData.url}`} target="_blank"> 
            <div className={styles.pill}>
            <FacebookShareButton 
              url={apiData.url}
              imageURL="https://i0.wp.com/reachinghighernh.org/wp-content/uploads/2018/07/competency-portfolio-learning.png?fit=460%2C300&ssl=1"
              quote={"I love using Escribli with my students. If you buy an Escribli plan with my unique link, you’ll get 10% off your first purchase!"}
            />
              <img src={icon1} alt="Facebook icon" />
              <h5>share</h5>
            </div>
          // </a>
        : 
          <div className={styles.pill}>
            <img src={icon1} alt="Facebook icon" />
            <h5>share</h5>
          </div>
        }
          <div  className={styles.pill}>
            <TwitterShareButton url={apiData.url} title={"Hey!\r\n\r\nI am using Escribli with my classroom and we are loving it so much that I needed to share it with you. Best thing?  If you purchase a plan with my unique link, you get 10% off your first purchase!\r\n\r\n Use my link to check it out: "} />
            <img src={icon2} alt="Twitter icon" />
            <h5>tweet</h5>
          </div>
          {/* <div className={styles.pill}>
            <img src={icon3} alt="Messener icon" />
            <h5>send</h5>
          </div> */}
          <div className={styles.pill}>
            <WhatsappShareButton url={apiData.url} title={"Hey!\r\n\r\nI am using Escribli with my classroom and we are loving it so much that I needed to share it with you. Best thing?  If you purchase a plan with my unique link, you get 10% off your first purchase!\r\n\r\n Use my link to check it out: "}  />
            <img src={icon4} alt="Whatsapp icon" />
            <h5>send</h5>
          </div>
          <div className={styles.pill}>
            {/* @todo update share image */}
            <EmailShareButton 
              media={"https://boomwriter.com/static/media/journal-illustration.6e1fdc28.png"}
            url={`${apiData.url}`} 
            subject={"I am loving Escribli, check it out for yourself and get 10% off 🙌"} body={"Hey,\r\n\r\nI am using Escribli with my classrooms, my students and I are loving it. If you purchase a plan with my unique link, you get 10% off your first purchase!\r\n\r\nUse my link to check it out: "} separator={" "}/>
            <img src={icon5} alt="Email icon" />
            <h5>email</h5>
          </div>
        </div>

        <div className={styles.link}>
          <h3>Share your unique link</h3>
          <div className={styles.linkBox}>
            <input ref={linkRef} type="text" value={apiData.url} readOnly/>
            <div className={styles.linkBtn} 
              onClick={copyToClip}
              onMouseEnter={()=>{setVis({...vis, tooltip:true})}}
              onMouseLeave={()=>{setVis({...vis, tooltip:false})}}
              id="linkBtn"
              >
                
              {!vis.copyConf ? 
                <img src={icon_link} alt="copy link"/> 
              : 
                <p>copied</p>
              }
               {vis.tooltip ? <div className={styles.tooltip}> click to copy </div> : null}
            </div>
          </div>
        </div>

        <div className={styles.bottom}>
              <div className={styles.text}>
              <p>Here you can see the rewards you've won when your teacher friends purchase an Escribli account</p>
              </div>
              <div className={styles.box}>
                <h4>Referrals</h4>
                <h1>{apiData.count}</h1>
              </div>
        </div>

      </div>
    </div>
  )

}

export default ReferAndEarn;