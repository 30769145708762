import { idxClass } from 'helpers/idxClass';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ActivitySectionBlock from './ClassActivityBlock/ActivitySectionBlock';

import styles from './ResponsiveSectionList.module.scss';

const ResponsiveSectionList = ({ item : {activity, activity: { iconKey, sections, activityClassId, activityId }, archived } }) => {

  const history = useHistory()


  const handleNavigate = (section) => {

    history.push(`/teacher/classes/${activityClassId}/${activityId}/settings/${section}`)

  }


  return (
    <div className={styles.container}>

      <div className={styles.inner}>

        <div className={styles.activityBar}>

          <div className={styles.iconDiv}>
            <div className={`${styles.icon} ${styles[idxClass(iconKey)]} ${archived && styles.grey}`} />
          </div>
          <div className={styles.text}>
            <div className={styles.topRow}>
              <div className={styles.nameDiv}>
                <h5>{activity.activityName}</h5>
              </div>
            </div>
            <div className={styles.bottomRow}>
              <div className={styles.left}>
                <p>{sections.length} sections</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sectionsDiv}>
          { sections.length === 0 ? 

              <div className={styles.placeholder}>
                <h5 className={styles.noSections}>No sections to display</h5>
              </div>

            :

            sections.sort((a,b)=> a.sectionIndex - b.sectionIndex).map((section,idx) => 
                <ActivitySectionBlock
                  archived={archived}
                  isStoryStart={activity.activityType==='story'&&section.sectionIndex===0}
                  handleNavigate={handleNavigate}
                  section={section} 
                  idx={idx}
                  key={idx} />
              )

          }
        </div>
      </div>

    </div>
  )

}

export default ResponsiveSectionList;