import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import LoadingOverlay from 'components/StructuralComps/LoadingOverlay/LoadingOverlay';
import React, { useEffect, useState } from 'react';

import styles from './EntryModal.module.scss';
import EntryModalImgPreview from './EntryModalImgPreview/EntryModalImgPreview';
import EntryModalMain from './EntryModalMain/EntryModalMain';
import EntryModalResponsive from './EntryModalResponsive';
import EntryModalSidebar from './EntryModalSidebar/EntryModalSidebar';

const EntryModal = ({ item: { maxWords, studentIndex, activityData:{activityId, activityClassName, activityName, privateMode }, setActiveSection, activeSection, activeSection:{ entries, sectionIndex, sectionName, wordBank }, fjUser }}) => {

  const [ selectedIdx, setSelectedIdx ] = useState(studentIndex)
  const [ entryData, setEntryData ] = useState(null)
  const [ previewImgs, setPreviewImgs ] = useState(null);


  const returnStudentId = idx => entries[idx].studentId

  const fetchEntryData = async (studentId) => 
    setEntryData( await getFromApi(`activity/${activityId}/section/${sectionIndex}/entry/${studentId}`)) 

  useEffect(()=>{
   fetchEntryData(returnStudentId(selectedIdx))
  },[selectedIdx])

  const handleArrow = isLeft => {
    setPreviewImgs(null)
    setEntryData(null)
    const newIdxPlus = selectedIdx === entries.length-1 ? 0 : selectedIdx+1
    const newIdxMinus = selectedIdx === 0 ? entries.length-1 : selectedIdx-1
    setSelectedIdx( isLeft ? newIdxMinus : newIdxPlus )
  }

  const updateLocalGrade = (input) => {
    let newEntries = [...activeSection.entries]
    newEntries[studentIndex].finalGrade = input
    setActiveSection({...activeSection, entries:newEntries});
  }

  const handleApproveEntry = async () => {
    const data = await sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/toggle_entry_approve/${returnStudentId(selectedIdx)}`)
    setActiveSection(data)
    setEntryData({...entryData, approved:true})
  }

  const updateEntryKey = (key,value) => setEntryData({...entryData, [key]:value})

  return (
    <>

    <EntryModalResponsive
      privateMode={privateMode}
      entryData={entryData}
      activityName={activityName}
      activityClassName={activityClassName}
      activityId={activityId}
      sectionIndex={sectionIndex}
      sectionName={sectionName}
      maxWords={maxWords}
      wordBank={wordBank}
      handleApproveEntry={handleApproveEntry} />

    <div className={styles.container}>
      {
        !entryData ? 
        
          <LoadingOverlay loadingText={"Retreiving entry data..."} contained />

        :

          <>

            {
              previewImgs &&
                <EntryModalImgPreview
                  setPreviewImgs={setPreviewImgs}
                  previewImgs={previewImgs} />
            }

            <EntryModalMain
              privateMode={privateMode}
              setPreviewImgs={setPreviewImgs}
              handleApproveEntry={handleApproveEntry}
              activityId={activityId}
              entryData={entryData}
              activityName={activityName}
              activityClassName={activityClassName}
              sectionIndex={sectionIndex}
              sectionName={sectionName} 
              maxWords={maxWords}
              wordBank={wordBank} />

            <EntryModalSidebar
              privateMode={privateMode}
              updateEntryKey={updateEntryKey}
              entryData={entryData}
              activeSection={activeSection}
              activityId={activityId}
              sectionIndex={sectionIndex}
              fjUser={fjUser}
              updateLocalGrade={updateLocalGrade}/>

          </>

}
      {
        !previewImgs &&
        <>

          <div onClick={()=>handleArrow(true)} className={styles.arrow}> 
            <div className={styles.icon}/> 
          </div>
          <div onClick={()=>handleArrow(false)} className={`${styles.arrow} ${styles.right}`}> 
            <div className={styles.icon}/>
          </div>

        </>
      }

    </div>

    </>
  )

}

export default EntryModal;