import React from 'react';

// import styles from '../EntryTextEditor.module.scss';
import fileIcon from 'assets/file_teal.svg'
import eyeIcon from 'assets/eye_blue.svg'
import { openInNewTab } from 'helpers/uiFunctions';

const isImageFile = file =>  ['.png', '.jpg', 'jpeg'].indexOf(file.fileUrl.slice(file.fileUrl.length-4)) !== -1

const StudentFileDiv = ({ styles, item, showPreview }) => {


  return (
            
    <div className={`${styles.fileDiv} ${!isImageFile(item) && styles.pdfFile}`}>
      {
        isImageFile(item) ? 
          <img src={item.fileUrl} alt="image" className={styles.imageFile} />
        :
          <>
            <img src={fileIcon} alt="image" className={styles.pdfFile} />
            <p>file.pdf</p>
          </>
      }
      <div className={styles.hoverDiv}>
        {/* <div onClick={()=> openInNewTab(item.fileUrl)} className={styles.viewBtn}> */}
        <div onClick={showPreview} className={styles.viewBtn}>
          <img src={eyeIcon} alt="eye" />
          <p>View</p>
        </div>
      </div>
    </div>
  )

}

export default StudentFileDiv;