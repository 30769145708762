import React from 'react';

import styles from './SetupButtons.module.scss';

const SetupButtons = ({ fjUser, isStoryStart, setView }) => {



  const sidebarItems = [
    {
      text:"Word Bank",
      itemClass:"wordbank"
    },
    {
      text:"Upload Files",
      itemClass:"upload"
    },
    {
      text:"Deadline",
      itemClass:"deadline"
    },
    {
      text:"Peer Review",
      itemClass:"peer"
    },
    // {
    //   text:"Grammar",
    //   itemClass:"grammar"
    // },
    // {
    //   text:"Rooms",
    //   itemClass:"rooms"
    // },
    {
      text:"Grading",
      itemClass:"grading"
    },
    {
      text:"Private",
      itemClass:"private"
    },
  ]

  return (
    <div className={styles.setupButtons}>
      {
        sidebarItems.map((item, idx) =>

          <div onClick={() => setView(item.itemClass)} key={idx}
            className={`
                  ${styles.item} 
                  ${styles[item.itemClass]} 
                  ${styles.settingsItem} 
                  ${(isStoryStart && ['grading', 'private'].indexOf(item.itemClass) === -1) && styles.disabled}
                  ${(fjUser && ['grading', 'grammar'].indexOf(item.itemClass) !== -1) && styles.hidden}
                  ${(!fjUser && item.itemClass == 'private') && styles.hidden}
                `}>
            <div className={styles.icon} />
            <p>{item.text}</p>
          </div>
        )
      }

    </div>
  )

}

export default SetupButtons;