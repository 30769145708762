import { sendToApi } from 'API/teacherCalls';
import React from 'react';
import { useState } from 'react';
import { updateSectionKey } from 'Redux/actions';
import{ store } from 'storeConfig'
import styles from './TieBreakerModal.module.scss';

// const students = [
//   {
//     studentId:19,
//     studentName:"Maya Aya",
//     studentBoomerUrl:"https://s3.us-east-2.amazonaws.com/bw-defaults/author.png",
//     entryText:"Wild Island is practically cut in two by a very wide and muddy river,' continued the cat. 'This river begins near one end of the island and flows into the ocean at the other. Now the animals there are very lazy, and they used to hate having to go all the way around the beginning of this river to get to the other side of the island. It made visiting inconvenient and mail deliveries slow, particularly during the Christmas rush.<br><br> Crocodiles could have carried passengers and mail across the river, but crocodiles are very moody, and not the least bit dependable, and are always looking for something to eat. They don't care if the animals have to walk around the river, so that's just what the animals did for many years'. 'But what does all this have to do with airplanes?' asked my father, who thought the cat was taking an awfully long time to explain.<br><br> 'Be patient, Elmer,' said the cat, and she went on with the story. 'One day about four months before I arrived on Wild Island a baby dragon fell from a low-flying cloud onto the bank of the river. He was too young to fly very well, and besides, he had bruised one wing quite badly, so he couldn't get back to his cloud. The animals found him soon afterwards and everybody said, 'Why, this is just exactly what we've needed all these years!' They tied a big rope around his neck and waited for the wing to get well. This was going to end all their crossing-the-river troubles."
//   },
//   {
//     studentId:19,
//     studentName:"Daniel Chang",
//     studentBoomerUrl:"https://s3.us-east-2.amazonaws.com/bw-defaults/author.png",
//     entryText:"One day, Molly the milkmaid had filled her pails with milk. Her job was to milk the cows, and then bring the milk to the market to sell. Molly loved to think about what to spend her money on. <br><br>As she filled the pails with milk and went to market, she again thought of all the things she wanted to buy. As she walked along the road, she thought of buying a cake and a basket full of fresh strawberries.<br><br> A little further down the road, she spotted a chicken. She thought, “With the money I get from today, I’m going to buy a chicken of my own. That chicken will lay eggs, then I will be able to sell milk and eggs and get more money!”<br><br> She continued, “With more money, I will be able to buy a fancy dress and make all the other milkmaids jealous.” Out of excitement, Molly started skipping, forgetting about the milk in her pails. Soon, the milk started spilling over the edges, covering Molly.<br><br> Drenched, Molly said to herself, “Oh no! I will never have enough money to buy a chicken now.” She went home with her empty pails.<br><br> “Oh, my goodness! What happened to you?” Molly’s mother asked.<br><br>“I was too busy dreaming about all the things I wanted to buy that I forgot about the pails,” she answered<br><br>. “Oh, Molly, my dear. How many times do I need to say, ‘Don’t count your chickens until they hatch?’”"
//   },
//   // {
//   //   studentId:19,
//   //   studentName:"Peter Holmes",
//   //   studentBoomerUrl:"https://s3.us-east-2.amazonaws.com/bw-defaults/author.png",
//   //   entryText:"A man sits at his desk, alone in his cluttered office with no exit. Stacks of manila envelopes and filing cabinets with every other drawer ajar, surround him. Overflowing waste bins sat in the corner filled with coffee cups and fast food wrappers. Bags under his eyes hung as if they were filled with lead, his shirt covered in beige stains, and dust rest on his shoulders. The office was very dimly lit with the only sources of light coming from a small desk lamp and his very old computer screen.<br><br>Ring…..Ring…..<br><br>The man flinched, causing dust to fly up from his shoulders. The phones cry filled the room as it reverberated off the walls making it impossible to not hear.<br><br>“Hello?” the man grumbled.<br><br>“Yeah, Steve. Its Peter, I’ve got an issue up here. I’ve never seen anything like it before.”<br><br>“Alright, Peter, slow down. What’s wrong?”<br><br>“Well, I was doing my regular shift in Quadrant 4, and I was about to cash in this guys soul, but he just…. he isn’t dead.”<br><br> “Ok, so the incident didn’t kill him. Good for him. He gets to live another day, you really didn’t need to call me for this, bye.” The man motioned the phone towards its base, fighting a bit with the cord.<br><br> “Steve! Steve! Don’t hang up!”<br><br> “What, Peter? Just fill out the P-40 form and mark it as a failed incident. It happens all the …”"
//   // },
//   // {
//   //   studentId:19,
//   //   studentName:"Peter Holmes",
//   //   studentBoomerUrl:"https://s3.us-east-2.amazonaws.com/bw-defaults/author.png"
//   // },
// ]

const TieBreakerModal = ({closeBtn, item:{activeSection, activityId, set, idx}}) => {

  const students = activeSection.tiebreakStudents;
  const [ readIndex, setReadIndex ] = useState(0);
  const [ readView, setReadView ] = useState(false);
  const regex = /<br>/gi;

  const handleWinner = (input) => {
    const index = input ? input : readIndex
    sendToApi(`activity/${activityId}/section/${activeSection.sectionIndex}/end_voting?studentId=${students[index].studentId}`, '', 'endVoting', {set:set, idx:idx})
    // store.dispatch(updateSectionKey(activeSection.sectionIndex, 'sectionStatus', 'in approval'))
    store.dispatch(updateSectionKey(activeSection.sectionIndex, 'sectionStage', 'post vote'))
    setTimeout(()=>{document.getElementById('clickHandle').click()},100);
    closeBtn.current.click();
  }

  return (
    <div className={styles.container}>
      {students ? 
        <>
        <div className={styles.header}>
          <h1>There is a tie!</h1>
          <h4>Read the tied entries and decide who will be the winner</h4>
        </div>

      <div className={styles.main}>
        {
          !readView ?

          <div className={(students.length === 3 ? styles.stepOneRadios : `${styles.stepOneRadios} ${styles.two}`)}>

            {
              students.map((student, idx) => {
                return(
                  <div key={idx} className={styles.stepOneRadio}>
                    <img src={student.studentBoomerUrl} alt="boomer"/>
                    <h3>{student.studentName}</h3>
                    <div className={styles.btns}>
                      <div onClick={()=>{setReadView(true)}} className={styles.btn}>Read entry</div>
                      <div onClick={()=>{handleWinner(idx)}} className={styles.btn}>Winner</div>
                    </div>
                  </div>
                )
              })
            }

          </div>

          :

          <div className={styles.readViewDiv}>
            <div className={styles.entryDiv}>
              <div className={styles.stepTwoRadios} onChange={(e)=>{setReadIndex(e.target.dataset.key)}}>
                {
                  students.map((student, idx) => {
                    return(
                      <label key={idx}>
                        <input data-key={idx} type="radio" name="entry" defaultChecked={readIndex === idx ? true : false}/>
                        <div className={(students.length === 3 ? styles.stepTwoRadio : students.length === 4 ? `${styles.stepTwoRadio} ${styles.narrow}` : `${styles.stepTwoRadio} ${styles.two}`)}>
                          <img src={student.studentBoomerUrl} alt="boomer"/>
                          {students.length !== 4 ? <h3>{student.studentName}'s entry</h3> : <h3>{student.studentName.split(' ')[0]}'s entry</h3>}
                        </div>
                      </label>
                    )
                  })
                }
              </div>
              {/* <div className={styles.entryText}> */}
              <textarea 
                className={styles.entryText}
                id="editText" 
                value = {students[readIndex].entryText.replaceAll(regex, '\b\r')} />
              {/* </div> */}
              <div onClick={()=>{handleWinner(false)}} className={styles.selectBtn}>Select winner</div>
            </div>
          </div>
        }

      </div>
    </>
  :null}
  </div>
  )

}

export default TieBreakerModal;