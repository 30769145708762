import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import OverviewTable from '../OverviewTable/OverviewTable';

import styles from './ActivityDetailsResponsive.module.scss';
import ChapterSettingsResponsive from './ChapterSettingsResponsive/ChapterSettingsResponsive';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';

const ActivityDetailsResponsive = ({ isBlocked, modalOverlay, fjUser, activity, activity:{ activityClassId, activityName, activityClassName }, activeSection, activeSection:{sectionIndex, sectionName}, setActiveSection, detailsHTML, setDetailsHTML }) => {

  const { tab, classId } = useParams();
  const [ view, toggleView ] = useState("settings")
  const [ bottomBlockSrolled, toggleBottomBlock ] = useState(null)
  const [ isStoryStart, setStoryStart ] = useState(activity?.activityType === "story" && activeSection?.sectionIndex === 0)
  const bottomBlockRef = useRef()
  const contRef = useRef()

  useEffect(()=>{
    if(tab){
      toggleView(tab);
      console.log(tab)
    }
  },[])



  const onScroll = async () => {
    //offest scroll amount for bottom block, the class feed will be called upon scrolling into view
    let topPosition = bottomBlockRef.current?.getBoundingClientRect().top;

    //bottom block will come into view at innerHeight - 60px due tot he 60px occupied by nav
    //call backend 40 pixels after becoming visible (innerHeight - 100)
    if(window.innerHeight - 100 >= topPosition && !bottomBlockSrolled) toggleBottomBlock("await");

  }


  useLayoutEffect(() => {
    if(contRef.current)contRef.current.addEventListener('scroll', onScroll)
    return () => contRef.current.removeEventListener('scroll', onScroll)
  }, [])


  return (
    <div className={`${styles.container} ${styles.simplified}`}>

     <div className={styles.topBar}>

       <div className={styles.detailsBar}>
         <Link to={`/teacher/classes/view/${activityClassId}/?activities`} className={styles.chevronBack} />
         <div className={styles.text}>
            <p>{activityClassName} / {activityName}</p>
            <p className={styles.chapterName}>{sectionIndex+1}. {sectionName}</p>
         </div>
         <div className={styles.chevronDown} />
       </div>

        
        <div className={`${styles.tabs}`}>
          <div onClick={() => toggleView('settings')} className={`${styles.radioDiv} ${view === "settings" && styles.noClick}`}>
            <p>Chapter Settings</p>
          </div>
          <div onClick={() => toggleView('responses')} className={`${styles.radioDiv} ${view !== "settings" && styles.noClick} ${isStoryStart && styles.inactive}`}>
            <p>Responses</p>
          </div>
          {/* <div className={`${styles.line} ${styles[view]}`} /> */}
        </div>

     </div>

      <div ref={contRef} className={`${styles.mainSwitch} ${styles[view]}`}>
        {

          view === 'responses' && !(activity?.activityType === "story" && activeSection?.sectionIndex === 0) ?

            <OverviewTable
              mobile
              modalOverlay={modalOverlay}
              setActiveSection={setActiveSection}
              activeSection={activeSection} />

          :
          
            <ChapterSettingsResponsive
              mobile
              modalOverlay={modalOverlay}
              fjUser={fjUser}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              activity={activity}
              isBlocked={isBlocked}
              isStoryStart={activity?.activityType === "story" && activeSection?.sectionIndex === 0}
              detailsHTML={detailsHTML}
              setDetailsHTML={setDetailsHTML}
              bottomBlockRef={bottomBlockRef}
              bottomBlockSrolled={bottomBlockSrolled}
              saveBtnRef={null} />

        }
      </div>

    </div>
  )

}

export default ActivityDetailsResponsive;



// <div className={styles.topBar}>

// <Link to={`/teacher/classes/view/${activityClassId}/?activities`}>
//   <div className={styles.iconDiv}>
//     <div className={`${styles.icon} ${styles[idxClass(iconKey)]}`} />
//   </div>
//   <div className={styles.text}>
//     <div className={styles.topRow}>
//       <div className={styles.nameDiv}>
//         <h5>{activity.activityName}</h5>
//       </div>
//     </div>
//     <div className={styles.bottomRow}>
//       <div className={styles.left}>
//         <p>{sections?.length} sections</p>
//       </div>
//     </div>
//   </div>
// </Link>
// <div onClick={()=> showModal(modalOverlay, {type:'responsiveSectionList', activity, sections: activity.sections.reverse()})} className={styles.chevron} />
// </div>

// <div className={styles.sectionBar}>
// <div className={styles.title}>
//   <div className={styles.circle}>{activeSection.sectionIndex+1}</div>
//   <h5>{activeSection.sectionName}</h5>
// </div>
// <SectionStatusBtn
//   modalOverlay={modalOverlay}
//   showModal={showModal}
//   activity={activity}
//   isStoryStart={isStoryStart}
//   setActiveSection={setActiveSection}
//   activeSection={activeSection} />
// </div>

// <div className={styles.tabsBar}>

// <div className={`${styles.radios}`}>
//   <div onClick={() => toggleView('settings')} className={`${styles.radioDiv} ${view === "settings" && styles.noClick}`}>
//     <p>Settings</p>
//   </div>
//   <div onClick={() => toggleView('responses')} className={`${styles.radioDiv} ${view !== "settings" && styles.noClick} ${isStoryStart && styles.inactive}`}>
//     <p>Responses</p>
//   </div>
//   <div className={`${styles.line} ${styles[view]}`} />
// </div>

// <div onClick={()=> showModal(modalOverlay, {type:'SetupChapterModal', activeSection, setActiveSection, isStoryStart, activity, modalOverlay, fjUser})} className={styles.cogBtn} />

// </div>