import React, { useEffect, useState } from "react";
import {contentStyles} from './contentStyles';
import { Page, Text, View, Document, Font } from "@react-pdf/renderer";


const styles = contentStyles()

export function RenderPrintContent({ pdfData }) {

  const { students, authorsNames, winners, storyStartAuthor, teacherName, storyStart, projectTitle, activityType, printCode } = pdfData
  const isJournal = activityType === 'journal' || activityType === 'faith';
  const isFaithJournal = activityType === 'faith';
  const isBee = activityType === 'bee';
  const isWinner = isJournal ? false : students[0]?.studentId === winners[winners.length-1]?.studentId 

  const {entries} = students[students.length-1] //this student's entries for this activity
  const finalChapter = entries[entries.length-1] //this students final chapter (for alt ending)

  useEffect(()=>{
    console.log(entries)
    console.log(finalChapter)
  },[entries, finalChapter])

  return (

      <Document>

        {
          !isJournal && //<---- BOOMWRITER STORY PAGE 
            <>
            <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>

              {/* //<----------------------------BOOMWRITER PAGE  */}
              <View style={styles.title_page_text}>
                <Text style={styles.title_text_wide}>An Escribli Book</Text>
                <Text style={styles.subtitle_text}>Escribli books are collaboratively written by children.</Text>
                <Text style={styles.subtitle_text}>An Escribli book begins with a first chapter called a “storystart.” The students then write their version of what happens next (Chapter 2) and let their imaginations run wild!</Text>
                <Text style={styles.subtitle_text}>After students submit their chapters, they all get to anonymously vote on which of the entries is best.</Text>
                <Text style={styles.subtitle_text}>The winner becomes the official Chapter 2, and the process continues until the book is complete!</Text>
              </View>

                <View style={styles.footer} fixed>  
                  <Text style={styles.footer_text}>Learn more at</Text>
                  <Text style={styles.footer_text}>escribli.com</Text>
                </View>
            </Page>


            <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
              <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                <View style={styles.pageNumber}>
                  <Text>{`${pageNumber}`}</Text>
                </View>
              )}/>
            </Page>
            
            </>
        }

          <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>

            {/* //<----------------------------TITLE PAGE  */}
            {isJournal && !isFaithJournal && <Text style={styles.secondary_title_text}>Escribli Journal</Text> }
              <Text style={styles.title_text}>{projectTitle}</Text>

              {!isJournal&&
                <>
                  <Text style={styles.text_subhead}>Story Start By:</Text>
                  <Text style={styles.text_large}>{storyStartAuthor}</Text>
                </>
              }

              <Text style={styles.text_subhead}>{isJournal? "This journal has been thoughtfully written by:" : "Completed By:"}</Text>
              <Text style={styles.text_large}>{students[0].studentName}</Text>
              {isJournal && <Text style={styles.text_med_margin}>{students[0].entries[students[0].entries.length-1].submittedAt.split(',')[1]}</Text>}
              {
                !isBee &&
                  <>
                    <Text style={styles.text_subhead}>Special thanks to:</Text>
                    <Text style={styles.text_large}>{teacherName}</Text>
                    <Text style={styles.text_parenthesis}>{`(the supervising teacher for this ${isJournal?"journal":"book"})`}</Text>
                  </>
              }

            {
              isFaithJournal &&  
                <View style={styles.footer} fixed>  
                  <Text style={styles.footer_text}>Book purchase code: {printCode}</Text>
                  <Text style={styles.footer_text}>faith-journals.com</Text>
                </View>
            }
          </Page>

          

          <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
            <View style={styles.footer_number} fixed render={({ pageNumber }) => (
              <View style={styles.pageNumber}>
                <Text>{`${pageNumber}`}</Text>
              </View>
            )}/>
          </Page>


          <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>

            <Text style={styles.dedictaion_text}>To:</Text>
            <Text style={styles.dedictaion_text}>From:</Text>
            <View style={styles.footer_number} fixed render={({ pageNumber }) => (
              <View style={styles.pageNumber}>
                <Text>{`${pageNumber}`}</Text>
              </View>
            )}/>
          </Page>


         {!isFaithJournal &&  
          
            <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
              <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                <View style={styles.pageNumber}>
                  <Text>{`${pageNumber}`}</Text>
                </View>
              )}/>
            </Page>
          }


          {!isJournal && //<---- STORY START & AUTHORS
          
            <>

              {

                authorsNames.length ?
                
                  <>
                    <Page wrap={true} style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
                      <Text style={styles.secondary_title_text}>Authors</Text>
                      <View style={styles.address}>
                        <Text style={styles.text_large}>These authors helped create this book by writing their</Text>
                        <Text style={styles.text_large}>own entries and voting to decide the winners:</Text>
                      </View>

                      <View style={styles.authors}>
                        { authorsNames?.map(author=><Text style={styles.author}>{author}</Text>) }
                      </View>
                      <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                        <View style={styles.pageNumber}>
                          <Text>{`${pageNumber}`}</Text>
                        </View>
                      )}/>
                    </Page>

                    <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
                    <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                      <View style={styles.pageNumber}>
                        <Text>{`${pageNumber}`}</Text>
                      </View>
                    )}/>
                    </Page>
                  </>

                : null

              }


              <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
                <Text style={styles.secondary_title_text}>Story Start</Text>
                <Text style={styles.secondary_title_text}>By:</Text>
                <Text style={styles.secondary_title_text}>{storyStartAuthor}</Text>
                <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                  <View style={styles.pageNumber}>
                    <Text>{`${pageNumber}`}</Text>
                  </View>
                )}/>
              </Page>

            {storyStart?.split(' ').length && 
              <Page style={styles.page} wrap={true} size={{width: 6.25*72, height: 9.25*72}}>
                <View>
                    <Text style={styles.text_body}>{storyStart}</Text>
                </View>
                <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                  <View style={styles.pageNumber}>
                    <Text>{`${pageNumber}`}</Text>
                  </View>
                )}/>
              </Page>}
            </>

          }

          {isFaithJournal ?   //<---- chapter formatting for FAITH JOURNALS     
            students.map(student => 

              student.entries?.map((entry, idx) => 
              <>
                <Page wrap={true} style={styles.title_page_top} size={{width: 6.25*72, height: 9.25*72}}>
                  <Text style={styles.secondary_title_text_sml}>{entry.unitTitle}</Text>
                  <Text style={styles.text_body}>{entry.instructions}</Text>
                <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                  <View style={styles.pageNumber}>
                    <Text>{`${pageNumber}`}</Text>
                  </View>
                )}/>
                </Page>
                
                <Page style={styles.page} wrap={true} size={{width: 6.25*72, height: 9.25*72}}>
                  <Text style={styles.secondary_title_text_sml_left}>Reflection</Text>
                  { !entry.entryText?.length  ?  null 
                  
                    : entry.entryText?.map(text=> <Text style={styles.text_body}>{text}</Text> ) 
                  }
                <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                  <View style={styles.pageNumber}>
                    <Text>{`${pageNumber}`}</Text>
                  </View>
                )}/>
                </Page>

                </>
              )
            ) 

            :
              isJournal ?   //<---- chapter formatting for JOURNALS     
                students.map(student => 

                  student.entries?.map((entry, idx) => 
                  <>
                    <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
                      <Text style={styles.secondary_title_text}>{entry.unitTitle}</Text>
                      <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                        <View style={styles.pageNumber}>
                          <Text>{`${pageNumber}`}</Text>
                        </View>
                      )}/>
                    </Page>
                    
                    <Page style={styles.page} wrap={true} size={{width: 6.25*72, height: 9.25*72}}>
                      { !entry.entryText?.length  ?  null 
                      
                        : entry.entryText?.map(text=> <Text style={styles.text_body}>{text}</Text> ) 
                      }
                    <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                      <View style={styles.pageNumber}>
                        <Text>{`${pageNumber}`}</Text>
                      </View>
                    )}/>
                    </Page>

                    </>
                  )
                ) 


            :  //<---- chapter formatting for STORIES AND BEE    
                  
            <>
              {
                winners.map(winner => 
                  <>
                    <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
                      <Text style={styles.secondary_title_text}>{winner.unitTitle}</Text>
                      <Text style={styles.secondary_title_text}>Written By:</Text>
                      <Text style={styles.secondary_title_text}>{winner.studentName}</Text>
                      <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                        <View style={styles.pageNumber}>
                          <Text>{`${pageNumber}`}</Text>
                        </View>
                      )}/>
                    </Page>
                    
                    <Page style={styles.page} wrap={true} size={{width: 6.25*72, height: 9.25*72}}>
                      { !winner.entryText?.length  ?  null 
                      
                        : winner.entryText?.map(text=> <Text style={styles.text_body}>{text}</Text> ) 
                      }
                    <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                      <View style={styles.pageNumber}>
                        <Text>{`${pageNumber}`}</Text>
                      </View>
                    )}/>
                    </Page>
                  </>
                
                )
              }
              <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
                  <Text style={styles.secondary_title_text}>The End</Text>
                  <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                    <View style={styles.pageNumber}>
                      <Text>{`${pageNumber}`}</Text>
                    </View>
                  )}/>
                </Page>
              {!isJournal && !isWinner &&
                <> 
                  <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
                      <Text style={styles.secondary_title_text}>Alternate Ending</Text>
                      <Text style={styles.secondary_title_text}>Written By:</Text>
                      <Text style={styles.secondary_title_text}>{students[students.length-1].studentName}</Text>
                      <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                        <View style={styles.pageNumber}>
                          <Text>{`${pageNumber}`}</Text>
                        </View>
                      )}/>
                    </Page>
                    
                    <Page style={styles.page} wrap={true} size={{width: 6.25*72, height: 9.25*72}}>
                      { !finalChapter?.entryText?.length  ?  null 
                      
                        : finalChapter?.entryText?.map(text=> <Text style={styles.text_body}>{text}</Text> ) 
                      }
                    <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                      <View style={styles.pageNumber}>
                        <Text>{`${pageNumber}`}</Text>
                      </View>
                    )}/>
                    </Page>
                  </>
              }
              </>
          }

          
          <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
            <View style={styles.footer_number} fixed render={({ pageNumber }) => (
              <View style={styles.pageNumber}>
                <Text>{`${pageNumber}`}</Text>
              </View>
            )}/>
          </Page>

          <Page style={styles.page} size={{width: 6.25*72, height: 9.25*72}}>

            <View style={{position:"relative", marginTop:"100px"}}>
              <Text style={styles.secondary_title_text}>Autographs & Messages</Text> 
              <Text style={styles.blurb_subhead}>Use this space to collect signatures and well-wishes</Text> 
              <Text style={styles.blurb_subhead}>from your classmates and teachers:</Text> 
            </View>
            <View style={styles.footer_number} fixed render={({ pageNumber }) => (
              <View style={styles.pageNumber}>
                <Text>{`${pageNumber}`}</Text>
              </View>
            )}/>

          </Page>

          <Page style={styles.page}  size={{width: 6.25*72, height: 9.25*72}}> 
              <Text style={styles.secondary_title_text}>Autographs & Messages</Text> 
              <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                <View style={styles.pageNumber}>
                  <Text>{`${pageNumber}`}</Text>
                </View>
              )}/>
          </Page>

          <Page style={styles.page}  size={{width: 6.25*72, height: 9.25*72}}> 
              <Text style={styles.secondary_title_text}>Autographs & Messages</Text> 
              <View style={styles.footer_number} fixed render={({ pageNumber }) => (
                <View style={styles.pageNumber}>
                  <Text>{`${pageNumber}`}</Text>
                </View>
              )}/>
          </Page>

          
          <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
            <View style={styles.footer_number} fixed render={({ pageNumber }) => (
              <View style={styles.pageNumber}>
                <Text>{`${pageNumber}`}</Text>
              </View>
            )}/>
          </Page>

          <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>  
          {/* //<<-----LEGAL BLURB PAGE */}

          <Text style={styles.blurb_title_text}> {!isFaithJournal ? "An Escribli":"A Faith Journal"} Publication</Text>

          {
            activityType==="bee" &&

            <View style={styles.wimpy_kid_blurb}>
              <Text style={styles.blurb_text}>© 2021, Wimpy Kid, Inc. DIARY OF A WIMPY KID®, WIMPY KID™, and the Greg Heffley</Text>
              <Text style={styles.blurb_text}>design™ are trademarks of Wimpy Kid, Inc.</Text>
              <Text style={styles.blurb_text}>ALL RIGHTS RESERVED</Text>
              <Text style={styles.blurb_text_margin}>Jeff Kinney Author Photo © Filip Wolak</Text>
              <Text style={styles.blurb_text_margin}>Jerry Craft Author Photo Hollis King</Text>

            </View>
          }
          {/* @todo update company data */}
          <View style={styles.legal_blurb}>
            <Text style={styles.blurb_text_margin}>Publishers</Text>
            <Text style={styles.blurb_text}>Escribli Inc.</Text>
            <Text style={styles.blurb_text}>Copyright ©{" "}2022 Escribli</Text>

            <View style={styles.address}>
              <Text style={styles.blurb_text}>Published by Escribli</Text>
              {/* <Text style={styles.blurb_text}>1 Broadway, Floor 14</Text> */}
              <Text style={styles.blurb_text}>Cambridge, MA</Text>
              <Text style={styles.blurb_text}>02142 USA</Text>
            </View>

            <Text style={styles.blurb_text}>No part of this publication may be reproduced, stored in a</Text>
            <Text style={styles.blurb_text}>retrieval system, or transmitted in any form or by any means,</Text>
            <Text style={styles.blurb_text}>electronic, mechanical, photocopying, recording, scanning, or</Text>
            <Text style={styles.blurb_text}>otherwise, except as permitted under section 107 or 108 of the</Text>
            <Text style={styles.blurb_text}>1976 US Copyright Act, without either the prior written permission</Text>
            <Text style={styles.blurb_text}>of the publisher. Requests to the Publisher for permission should</Text>
            <Text style={styles.blurb_text}>be addressed to:</Text>

            <View style={styles.address}>
              <Text style={styles.blurb_text}>Escribli Inc.</Text>
              <Text style={styles.blurb_text}>Attn. Publisher</Text>
              {/* <Text style={styles.blurb_text}>1 Broadway, Floor 14</Text> */}
              <Text style={styles.blurb_text}>Cambridge, MA </Text>
              <Text style={styles.blurb_text}>02142, USA</Text>
            </View>
          </View>

          {
            isFaithJournal &&
              <View style={styles.footer} fixed>  
                <Text style={styles.footer_text}>Learn more at</Text>
                <Text style={styles.footer_text}>faith-journals.com</Text>
              </View>
          }


          </Page>

          
          <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
            <View style={styles.footer_number} fixed render={({ pageNumber }) => (
              <View style={styles.pageNumber}>
                <Text>{`${pageNumber}`}</Text>
              </View>
            )}/>
          </Page>

          {
            !isFaithJournal &&
              <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>  
                <Text style={styles.blurb_title_text_bw}>Escribli</Text>
                <Text style={styles.blurb_subhead}>Where kids love to write!</Text>

                <View style={styles.address}>
                  <Text style={styles.blurb_text}>Learn more about {!isFaithJournal ? "Escribli's":"Faith Journals'"} unique writing</Text>
                  <Text style={styles.blurb_text}>activities at school, home, and around the world by</Text>
                  <Text style={styles.blurb_text}>visiting:</Text>
                </View>

                  <Text style={styles.blurb_text_bold}>{!isFaithJournal ? "escribli.com" : "faith-journals.com"}</Text>
              </Page>
          }


         {!isFaithJournal &&  
         
         <Page style={styles.title_page} size={{width: 6.25*72, height: 9.25*72}}>
            <View style={styles.footer_number} fixed render={({ pageNumber }) => (
              <View style={styles.pageNumber}>
                <Text>{`${pageNumber}`}</Text>
              </View>
            )}/>
          </Page>
       }

      </Document>
  )
}

