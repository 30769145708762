import { Link } from 'react-router-dom';
import React from 'react';
import { store } from 'storeConfig';
import { toggleNavOpen } from 'Redux/actions';
import logo from 'assets/logo_escribli_circle2.svg'
import logo_faith from 'assets/logo_faith_hands.png'
import burger from 'assets/newNavAssets/burger_white.svg'
import burger_close from 'assets/newNavAssets/burger_white_close.svg'

import styles from './Nav.module.scss';

const NavLogo = ({ navOpen, fjUser }) => {

  return (

    <Link
      to={"/teacher/classes"}
      // onClick={() => store.dispatch(toggleNavOpen(!navOpen))}
      className={styles.navItem} >
      <img className={`${styles.logo} ${!fjUser && styles.bw}`} src={fjUser ? logo_faith : logo} alt="logo" />
    </Link>

  )

}

export default NavLogo;