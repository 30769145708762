import React from 'react'
import { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'



function RootRedirect() {

  const history = useHistory()

  useEffect(()=>{history.push('/teacher')},[])

  return (
    null
  )
}

export default RootRedirect
