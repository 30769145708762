import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import loader from 'assets/spinner-white-sm.svg';

import styles from './ProfilePasswordSettings.module.scss';
import { sendToApiReturn } from 'API/teacherCalls';
import { openInNewTab } from 'helpers/uiFunctions';

const ProfilePasswordSettings = () => {

  const mainRef = useRef();
  const oldRef = useRef(); 
  const newRef = useRef(); 
  const confRef = useRef(); 
  const btnRef = useRef(); 
  const [error, setError] = useState({visible:false, text:'error message', success:false})
  const [ submit, setSubmit ] = useState({loading:false, response:''})


  const shake = () =>{
    mainRef.current.classList.add(styles.shake)
    setTimeout(()=>{mainRef.current.classList.remove(styles.shake)},1000)
  }


  useEffect(()=>{

    if(submit.response==='error'){
      shake()
      setError({visible:true, text:'Incorrect password'})
      oldRef.current.value=''
      oldRef.current.focus()
      newRef.current.value=''
      confRef.current.value=''
      
    } else if (submit.response==='success'){
      setError({visible:false, success:true})
      btnRef.current.classList.add(styles.revert)
      setTimeout(()=>{btnRef.current.classList.remove(styles.revert)},1000)
      oldRef.current.value=''
      oldRef.current.focus()
      newRef.current.value=''
      confRef.current.value=''
    }
  },[submit])


  const handleSubmit = async () => {
    
    const oldPass = oldRef.current.value;
    const newPass = newRef.current.value;
    const confPass = confRef.current.value;


    if(oldRef.current.value===''||newRef.current.value===''||confRef.current.value===''){
      setError({visible:true, text:'Please complete all fields'})
      shake()

    } else if (newRef.current.value.length<6){
      setError({visible:true, text:'Password must be at least 6 characters'})
      shake()

    } else if (newRef.current.value !== confRef.current.value){
      setError({visible:true, text:'Passwords do not match'})
      shake()

    } else {
      setError({...error, visible:false})
      setSubmit({loading:true, response:''})
      btnRef.current.classList.add(styles.revert)
      setTimeout(()=>{btnRef.current.classList.remove(styles.revert)},1500)
      const payload = {
        existing_password:oldPass,
        new_password: newPass,
        confirm_password:confPass
      }

       const res = await sendToApiReturn(`account/change_password`, payload)
       setSubmit({...submit, response:res.message.message})

      }
  }

  return (
    // <div className={styles.innerContainer}>
      <>
          <div ref={mainRef} className={styles.main}>

            <h6>Password Settings</h6>

            <div className={styles.row}>
              <h3>Old password</h3>
              <input ref={oldRef} type="password" name="oldPassword" id="oldPassword"/>
              {/* @todo update forgotten password link  */}
              <p onClick={()=>{openInNewTab('https://account.boomwriter.com/educator/forgot')}}>Forgotten password?</p>
            </div>

            <div className={styles.row}>
              <h3>New password</h3>
              <input ref={newRef} type="password" minLength={6} name="newPassword" id="newPassword"/>
              <p>Minimum 6 characters</p>
            </div>

            <div className={styles.row}>
              <h3>Confirm new password</h3>
              <input ref={confRef} type="password" minLength={6} name="confNew" id="confNew"/>
            </div>

            

          </div>
          {
            submit.response==='success' ? 
              <Link to='/teacher/classes'> <div ref={btnRef} className={styles.btnPass}>Go to dashboard</div> </Link> 
            :
              <div ref={btnRef} onClick={handleSubmit} className={styles.btnPass}> {!submit.loading ? 'Confirm' : <img src={loader} alt="loader"/> }</div>
          }
          <div className={(error.visible ? styles.error : `${styles.error} ${styles.hidden}`)}> {error.text} </div>
          <div className={(error.success ? styles.success : `${styles.success} ${styles.hidden}`)}> Password successfuly changed! </div>
          </>
    // </div>
  )

}

export default ProfilePasswordSettings;