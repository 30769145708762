import React from 'react';
import EditProfile from './EditProfile';
import ProfilePasswordSettings from './ProfilePasswordSettings';
import ProfileEmailSettings from './ProfileEmailSettings';

import styles from './ProfileSettings.module.scss';

const ProfileSettingsResponsive = ({ permissions }) => {

  return (
    <div className={styles.resContainer}>
      <EditProfile />
      <ProfilePasswordSettings />
      <ProfileEmailSettings permissions={permissions} />
      
    </div>
  )

}

export default ProfileSettingsResponsive;