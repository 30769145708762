import React, { useEffect, useRef, useState } from 'react';
import { CountryDropdown, RegionDropdown  } from 'react-country-region-selector';
import styles from './BookPurchaseModal.module.scss';
import {checkParams} from 'helpers/inputFunctions';
import { getFromApi } from 'API/parentCalls';

const PurchaseStepTwo = ({payload, setPayload, contRef, setStep, apiRes, setApiRes, childId}) => {

  const formRef = useRef();
  const [ error, setError ] = useState(false);


  Array.from(document.getElementsByTagName('input')).forEach(i => {
    i.addEventListener('change', ()=>{setError(false)})
    i.addEventListener('keydown', ()=>{setError(false)})
  })


  const handleBack = () => {
    setStep('init')
    contRef.current.classList.add(styles.init)
    contRef.current.classList.remove(styles.delivery)
    setError(false)
  }

  const handleNext = async () => {

    if(!checkParams({
      1:payload.address.address_1, 
      2:payload.address.address_2, 
      3:payload.address.city, 
      4:payload.address.country_name, 
      5:payload.address.zip
    })||(payload.address.country_name==='United States' && !payload.address.state_name)){
      setError(true)
      formRef.current.classList.add('shake')
      setTimeout(()=>{formRef.current.classList.remove('shake')},500)

    } else {


      setStep('confirm')
      contRef.current.classList.remove(styles.delivery)
      contRef.current.classList.add(styles.confirm)
      // setApiRes( await getFromApi(`book_purchase/${childId}?type=${payload.type}&cnt=${payload.copies}&price=${payload.price}`, 'bookPurchase'))
      //call backend, add response to apiRes object

    }
    
  }

  // useEffect(()=>{

  //   if(apiRes.name){
  //     //move to next step upon receiving apiRes
  //     contRef.current.classList.remove(styles.delivery)
  //     contRef.current.classList.add(styles.confirm)
  //     setStep('confirm')

  //   }

  // },[apiRes])

  return (
    <>
      <h1>Delivery address:</h1>
      <div ref={formRef} className={styles.form}>
    
        <input 
          placeholder="Street address 1" 
          value={payload.address.address_1}
          type="text" 
          name="add1" 
          id="add1" 
          onChange={(e)=>{setPayload({...payload, address:{...payload.address, address_1:e.target.value}})}}
        />
    
        <input 
          placeholder="Street address 2"
          value={payload.address.address_2} 
          type="text" 
          name="add2" 
          id="add2" 
          onChange={(e)=>{setPayload({...payload, address:{...payload.address, address_2:e.target.value}})}}
        />
    
        <input 
          placeholder="City" 
          value={payload.address.city}
          type="text" 
          name="city" 
          id="city" 
          onChange={(e)=>{setPayload({...payload, address:{...payload.address, city:e.target.value}})}}
        />
    
       <input 
          placeholder="Zip code" 
          value={payload.address.zip}
          type="text" 
          name="zip" 
          id="zip"   
          onChange={(e)=>{setPayload({...payload, address:{...payload.address, zip:e.target.value}})}}
        />
    
        <CountryDropdown
          value={payload.address.country_name}
          onChange={(val) => {
            setPayload({...payload, address:{...payload.address, country_name:val}});
            setError(false)
          }} 
        />

          {
            payload.address.country_name === 'United States' &&
            <>
              <RegionDropdown
                defaultOptionLabel={"Select state"}
                value={payload.address.state_name}
                country={payload.address.country_name}
                onChange={(val) => {
                  setPayload({...payload, address:{...payload.address, state_name:val}});
                  setError(false)
                }}
              />
            </>
          }

      </div>

      <div className={styles.buttons}>
        <h4 onClick={handleBack}>{"< back to purchase options"}</h4>
        {error && <p className={styles.error}>Please complete all fields</p>}
        <div onClick={handleNext} className={styles.nextBtn}>Next</div>
      </div>

    </>
  )

}

export default PurchaseStepTwo;