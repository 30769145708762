import React, { useEffect, useRef, useCallback, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

import styles from './DetailsTextEditor.module.scss';
import InlinePdfs from '../../../UtilityComps/InlinePdfs/InlinePdfs'

import saveIcon from '../../../../assets/save.svg'
import { store } from "storeConfig";
import { stripTags } from "helpers/stripTags";
import { debounce } from "helpers/debounce";
import { sendToApiReturn } from "API/teacherCalls";
import { addActivityData, updateActivityKey, updateActivitySection, updateSectionKey } from "Redux/actions";
import useOutsideClick from "helpers/CustomHooks/useOutsideClick";
import LinkModal from "./linkModal/LinkModal";


const DetailsTextEditor = ({ activeSection, setActiveSection, activity, isStoryStart, mobile, detailsHTML, saveBtnRef, preview, callback, simplified }) => {

  const { storyStartEditable, storyStartContents } = activity
  const quillRef = useRef()
  const inputRef = useRef()
  const nameDivRef = useRef()
  const [ localHTML, setLocalHTML ] = useState((mobile&&!preview) ? activeSection.instructions : detailsHTML !== "" ? detailsHTML : "")
  const [ editingName, toggleEditingName ] = useState(false)


  useEffect(()=>{
    if(detailsHTML !== localHTML && (!mobile&&!preview)){
      setLocalHTML(detailsHTML)
      console.log("DETAILS")
    }
  },[detailsHTML])

  useEffect(()=>{
    //autofocus on editor input when user opens the functional mobile editor (not the mobile preview)
    if(mobile && !preview){
      quillRef.current.focus()
    }
  },[])

  useEffect(()=>{
    setLocalHTML(activeSection.instructions)
  },[activeSection.sectionId])

  
  useOutsideClick(nameDivRef, ()=>{if(editingName)handleNewName()})

  const handleNewName = async () => {
    if(inputRef.current?.value !== "" && inputRef.current?.value !== activeSection.sectionName){
      
      const newData = {...activeSection, sectionName:inputRef.current?.value} //create updated section object
      console.log(newData)
      store.dispatch(updateSectionKey(activeSection.sectionIndex, 'sectionName', inputRef.current?.value))
      setActiveSection(await sendToApiReturn(`activity/${activity.activityId}/update_unit/${activeSection.sectionIndex}`, newData))

    }
    toggleEditingName(false)
  }

  const handleSaveInstructions = async ( newInput, isStart, activeSection, activity) => {

    //this function is auto-called based on several user interractions. When this happens on storyStart view it reverts to 
    //regaular view (displays the text editor). For this reason we cancel this action immediately when triggered on a storyStart
    if(isStart && !activity.storyStartEditable)return

    if(saveBtnRef?.current)saveBtnRef.current.innerText="Saved!"
    
    // const sanitizedText = DOMPurify.sanitize(newInput) @todo - reinstate purify
    
    if(isStart){
      sendToApiReturn(`activity/${activity.activityId}/update`, { ...activity, storyStartContents:newInput })
      store.dispatch( addActivityData( { ...activity, storyStartContents:newInput }) )
      
    } else {
      sendToApiReturn(`activity/${activity.activityId}/update_unit/${activeSection.sectionIndex}`, {...activeSection, instructions: newInput})
      store.dispatch( updateActivitySection(activeSection.sectionIndex, { ...activeSection, instructions:newInput }) )
    }

    setTimeout(()=>{
      if(saveBtnRef?.current)saveBtnRef.current.innerText="Save"
    },2500)
  }

  const debounceSave = useCallback(debounce((newData, isStart, activeSection, activity)=>handleSaveInstructions(newData, isStart, activeSection, activity), 1500), []);

  useEffect(()=>{  //update placeholder depending on instructions/story start
    if(quillRef.current&&(mobile&&preview)) quillRef.current.editor.root.dataset.placeholder = 
      `Write ${!isStoryStart ? 'instructions' : 'your story start'} here`
  },[activeSection])
  

  const editorModules = {

    toolbar: 

      preview ?

        false
    
      : !mobile ? //non-responsive options
        [
          [{ 'font': [] }],
          ['bold', 'italic', 'underline'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['video'],
          ['image']
        ]

      : //responsive options
        [
          [{ 'font': [] }],
          ['bold', 'italic', 'underline'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ]
  };

  const handlePreviewClick = e => {
    if(callback && ( !nameDivRef.current || (nameDivRef.current && !nameDivRef.current.contains(e.target)))){
      callback(true)
    }
  }

  const handleMediaBtn = (input) => {
    // const query = ``
    console.log(`.${input}`)
    const btn = Array.from(document.querySelectorAll(`.${input}`)).filter(i=>i.type=='button')
    console.log(btn)
    if(!btn)return
    btn[0].click();
    
  }

  const handleChange = (val, source) => {
    console.log(source)
    if(source=='user'){
      setLocalHTML(val)
      handleSaveInstructions(val, isStoryStart, activeSection, activity)
      console.log("localText")
    }
  }

  const handleLink = input => {
    const newInput = localHTML + `<a style={{display:"block"}} href=${input} target="_blank">${input}</a>`
    setLocalHTML(newInput)
    debounceSave(newInput, isStoryStart, activeSection, activity)
    // store.dispatch(updateChapterKey('chapterText',newInput,chapterNumber-1,activeLesson.lessonNumber-1))
  }

  return !activeSection ? null : (
    <div onClick={handlePreviewClick} 
      className={`${styles.container} ${isStoryStart && styles.storyStart} ${simplified && styles.simplified} ${preview && styles.preview} 
      ${mobile && `${styles.mobile} ${( preview && (activeSection.instructions=="<p><br></p>"||activeSection.instructions=="")) && styles.grey}`}`}> 
      {
        !mobile ?
          <>
            <div className={`${styles.mediaBtns} ${(isStoryStart && !storyStartEditable) && styles.hidden}`}>
              <div onClick={()=>handleMediaBtn('ql-image')} className={styles.image} />
              <div onClick={()=>handleMediaBtn('ql-video')} className={styles.video} />
              <LinkModal addLink={handleLink} />
            </div>
          
            <div onClick={() => handleSaveInstructions(localHTML, isStoryStart, activeSection, activity)}
              className={` ${styles.saveBtn} ${(isStoryStart && !storyStartEditable) && styles.hidden} ${stripTags(localHTML) !== stripTags(!isStoryStart ? activeSection.instructions : storyStartContents) && styles.enabled} `}>
              <img src={saveIcon} alt="save" />
              <p ref={saveBtnRef}>Save</p>
            </div>
          </>
          : 
          (preview && activeSection.instructions!=="<p><br></p>"&&activeSection.instructions!=="") &&
          <div ref={nameDivRef} onClick={()=>{if(!editingName)toggleEditingName(true)}} className={styles.nameInput}>
            {
              !editingName ?
                <>
                  <h2>{activeSection.sectionName}</h2>
                  <div className={styles.pencil} />
                </>
                :
                <>
                  <input ref={inputRef} defaultValue={activeSection.sectionName} autoFocus onKeyDown={e=>{if(e.key=="Enter"){handleNewName()}}} type="text" name="nameInput" />
                </>
            }
          </div>
      }
      {
        !isStoryStart ?

          <ReactQuill
            readOnly={preview}
            onChange={(x,y,z)=>handleChange(x,z)}
            onKeyUp={()=>debounceSave(localHTML, isStoryStart, activeSection, activity)}
            ref={quillRef}
            className={!mobile ? "activityDetails" : preview ? "activityDetailsResponsive" : mobile && "activityDetailsResponsive functional"}
            modules={editorModules}
            theme="snow"
            value={preview ? activeSection.instructions : localHTML||""}>
          </ReactQuill>
          :
          <ReactQuill
            ref={quillRef}
            readOnly={!storyStartEditable}
            modules={storyStartEditable ? editorModules : { "toolbar": false }}
            theme="snow"
            value={!storyStartEditable ? activity.storyStartContents : localHTML}
            className={preview ? "activityDetailsResponsive" : mobile && "activityDetailsResponsive functional"}
            onChange={(x,y,z)=>handleChange(x,z)}
            onKeyUp={()=>debounceSave(localHTML, isStoryStart, activeSection, activity)} >
          </ReactQuill>
      }
      {activeSection.files.length > 0 ?
       <div>
         <div className={styles.separator} />
         <InlinePdfs key={"attached_pdfs"} files={activeSection.files} />
       </div> 
       : null
       }
    </div>
  )

}

export default DetailsTextEditor;
