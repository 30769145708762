import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addActivityData } from 'Redux/actions';
import { getFromApi } from 'API/teacherCalls';
import { showModal } from 'helpers/uiFunctions';
import styles from './ActivityDetails.module.scss';
import SectionStatusBtn from './SectionStatusBtn/SectionStatusBtn';
import OverviewTable from './OverviewTable/OverviewTable';
import ActivityDetailsSidebar from './ActivityDetailsSidebar/ActivityDetailsSidebar';
import DetailsTextEditor from './DetailsTextEditor/DetailsTextEditor';
import LoadingOverlay from 'components/StructuralComps/LoadingOverlay/LoadingOverlay';
import Breadcrumb from 'components/UtilityComps/Breadcrumb/Breadcrumb';
import ActivitySectionsBar from './ActivitySectionsBar/ActivitySectionsBar';
import ActivityDetailsResponsive from './ActivityDetailsResponsive/ActivityDetailsResponsive';
import CopyUrlBtn from 'components/UtilityComps/CopyUrlBtn/CopyUrlBtn';
import { returnPhaseText } from 'helpers/returnPhaseText';

import StatusButtonsExplainedModal from '../../../components/StructuralComps/Modal/StatusButtonsExplainedModal/StatusButtonsExplainedModal'
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import questionMark from 'assets/query_grey.svg'

const ActivityDetails = ({ modalOverlay, fjUser }) => {
  const { activityId, classId, tab, sectionIndex } = useParams();
  const dispatch = useDispatch();
  const activity = useSelector(state => state.activityData);

  // no more than 5 students on the free plan to share sections
  const isBlocked = (activity.onFreePlan && activity.allStudentsCount > 5)
  
  const [activeSection, setActiveSection] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(sectionIndex ? Number(sectionIndex) : 0);
  const [ fetchingData, setFetching ] = useState(true)
  const [ initialLoad, setInitialLoad ] = useState(true)
  const [ detailsHTML, setDetailsHTML ] = useState('')
  const [ localActivityName, setLocalActivityName ] = useState(null)
  const [ showLeaderboard, toggleLeaderboard ] = useState()
  const [ view, setView ] = useState('details')
  const saveBtnRef = useRef()
  const sectionPhase = returnPhaseText(activeSection?.sectionPhase);
  const isStoryStart = activity?.activityType==="story" && activeSection?.sectionIndex === 0

  // these are used for the modal which explains the different states of the current section
  const [ activeModal, setActiveModal ] = useState(null)
  const modalContRef = useRef()
  const contRef = useRef()

  useOutsideClick(contRef, () => setActiveModal(null) )

  useEffect(()=>{
    if(activeModal){
      // hide the previous modal and show the current one with a delay
      modalContRef.current.classList.remove(styles.display)
      setTimeout(()=>{modalContRef.current.classList.add(styles.display)},50)
    } else if (modalContRef && modalContRef.current) {
      // activeModal is null or undefined, make sure the container div is not visible
      modalContRef.current.classList.remove(styles.display)
    }
  },[activeModal])
  
  const callAPI = async () => {
    const data = await getFromApi(`activity/${activityId}`)
    console.log('data', data)
    dispatch(addActivityData(data))
    if(!window.location.search&&!sectionIndex){
      setSelectedIdx(0);
    }
  } 

  const fetchSectionData = async (index) => {
    setFetching(true)
    const newSection = await getFromApi(`activity/${activityId}/unit/${index}`);
    setActiveSection(newSection)
    setFetching(false)
    if( !sectionIndex && (activity.activityType === 'story' && selectedIdx===0)) setView('details')
    setDetailsHTML( (activity.activityType==='story' && selectedIdx === 0) ? activity.storyStartContents : newSection?.instructions )
  }

  useEffect(() => {
    dispatch(addActivityData([]))

    if(initialLoad){
      callAPI();
      setInitialLoad(false)
    }

    if(tab){
      setView(tab);
      console.log(tab)
    }
  },[])
      
  useEffect(() => {
    if(activity) fetchSectionData(selectedIdx);
  },[selectedIdx, activity.activityId])
      
  useEffect(() => {
    //replace last param in URL(sectionIndex) when selecting new section
    //this avoids the entire page reloading but means that if the user refreshes
    //the page, it will still be on the correct section
    if(sectionIndex && selectedIdx>=0) window.history.replaceState(null, '', selectedIdx);
  },[selectedIdx])

  useEffect(() => {
    if(activity?.sections&&activeSection?.entries) {
      //update activeSection object in response to Redux action updateActivitySection (likely from sectionStatusBtn.jsx).
      // data mostly taken from the activity object, but there are no entries or rooms on that object, so they're taken from current activeSecion object
      // this is necessary so that the sidebar sectionDiv and bottom bar sectionStatusBtn phases are synced on UI
      setActiveSection({...activity.sections[selectedIdx], entries: activeSection.entries, rooms: activeSection.rooms})
    }
  },[activity.sections])

  useEffect(()=>{
    if(activeSection) {
      toggleLeaderboard(activeSection.voting&&activeSection.sectionStatus==='completed'&&activeSection.leaderboard?.length);
      // setDisplayStudents(activeSection.entries)
      // sortRooms(activeSection.entries, activeSection.rooms)
    }
  },[activeSection])

  return !activeSection || !activity ? 
    <LoadingOverlay loadingText={'loading...'} /> 
    : 
    (
    <>
      <ActivityDetailsResponsive
        fjUser={fjUser}
        activity={activity}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        modalOverlay={modalOverlay}
        detailsHTML={detailsHTML}
        isBlocked={isBlocked}
        setDetailsHTML={setDetailsHTML} />
    
      <div className={`${styles.container} ${fjUser && styles.simplified}`}>
        <Breadcrumb
          link1={{text:'Home',link: '/teacher/classes'}}
          link2={{text:activity.activityClassName, link: `/teacher/classes/view/${activity.activityClassId}/?activities`}}
          current={localActivityName||activity.activityName} 
          borderBottom={false}/>

        <div className={styles.mainDiv}>

          <ActivitySectionsBar 
            fjUser={fjUser}
            selectedIdx={selectedIdx}
            setSelectedIdx={setSelectedIdx}
            setActiveSection={setActiveSection}
            setLocalActivityName={setLocalActivityName}
            activity={activity}
            classId={classId}
            view={view}
            setView={setView}
            modalOverlay={modalOverlay}
            activeSection={activeSection} />
        
          <div className={styles.content}>

            <div className={`${styles.viewRadios}`} onChange={e => setView(e.target.value)}>

              <label>
                <input checked={view === "details"} type="radio" name="view" value={'details'} />
                <div className={`${styles.viewRadio} ${styles.details}`}>
                  <div className={styles.icon} />
                  <p>Chapter Settings</p>
                </div>
              </label>

              <label className={`${isStoryStart && styles.inactive}`}>
                <input checked={view === "responses"} type="radio" name="view" value={'responses'} />
                <div className={`${styles.viewRadio} ${styles.responses} ${isStoryStart && styles.inactive}`}>
                  <div className={`${styles.icon} ${styles.responses}`} />
                  <p>Responses</p>
                </div>
              </label>

              <div className={`${styles.line} ${styles[view]}`}></div>

            </div>

          <div className={`${styles.viewSwitch} ${!showLeaderboard && styles.noScroll}`}>
            {
              fetchingData &&
                <LoadingOverlay contained blueSpinner />
            }
            {

                view === 'responses' && !isStoryStart ?

                  <OverviewTable
                    showLeaderboard={showLeaderboard}
                    modalOverlay={modalOverlay}
                    setActiveSection={setActiveSection}
                    fjUser={fjUser}
                    activeSection={activeSection} />

              :

                  <DetailsTextEditor
                    simplified={fjUser}
                    saveBtnRef={saveBtnRef}
                    activity={activity}
                    isStoryStart={isStoryStart}
                    activeSection={activeSection}
                    detailsHTML={detailsHTML}
                    setDetailsHTML={setDetailsHTML} />
            }
          </div>

          <div ref={contRef} className={`${styles.bottom}`}>

            <div className={styles.left}>
              <div onClick={()=>{showModal(modalOverlay,{
                    type:'remove', 
                    variant:'deleteSection',
                    activityId:activity.activityId,
                    index:selectedIdx,
                    func:()=>{setSelectedIdx(0)}
                    })}} 
                    className={`${styles.delete} ${!activeSection.canBeDeleted && styles.disabled}`}>
                <div className={styles.icon} />
                <p>Delete section</p>
              </div>
              <CopyUrlBtn activityId={activityId} sectionIdx={selectedIdx} />
            </div>

            <div className={styles.right}>
              <div className={styles.topRow}>
                {
                  isStoryStart ? 
                    null 
                    :   
                    activeSection?.sectionStatus == "in approval" ? 
                      <p className={styles.ended}>Writing has ended <span>To re-open the section, select a new deadline</span></p>
                      :
                      <p className={`${styles.phaseText} ${styles[sectionPhase]}`}>
                        This chapter is {sectionPhase === "Draft" && "in "}<span className={styles[sectionPhase]} >{sectionPhase}</span>
                      </p>
                }
                <SectionStatusBtn 
                  modalOverlay={modalOverlay}
                  showModal={showModal}
                  activity={activity}
                  isStoryStart={isStoryStart}
                  setActiveSection={setActiveSection}
                  activeSection={activeSection} />

                <div ref={modalContRef} className={styles.modalContainer}>
                  <div onClick={() => setActiveModal(null)} className={styles.closeBtn} />
                  {activeModal === "statusButtonsExplained" && 
                  <StatusButtonsExplainedModal />}
                </div>
                
                <img src={questionMark} 
                     className={styles.stageExplanation} 
                     alt="what does the status mean"
                     onClick={()=>setActiveModal('statusButtonsExplained')} />
              </div>
                <p className={`
                ${styles.message}
                ${((activeSection.sectionStatus === 'draft' || activeSection.sectionStatus === 'scheduled')&&!isBlocked) && styles.visible}
                `}>
                  By clicking 'Set live', this assignment will be shared with your students.<br/>
                  {activeSection.sectionStatus === 'draft' && !activeSection.sectionStart ? 
                  "Select a date if you want to schedule this section." : ""}
                </p>
            </div>
          </div>
        </div>
        <ActivityDetailsSidebar
          modalOverlay={modalOverlay}
          isStoryStart={isStoryStart}
          view={view}
          fjUser={fjUser}
          setView={setView} 
          activity={activity}
          setActiveSection={setActiveSection}
          activeSection={activeSection} />
      </div>
    </div>
  </>
  )


}

export default ActivityDetails;








