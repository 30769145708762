import React, { useRef, useState } from 'react';

import PurchaseStepOne from './PurchaseStepOne';
import PurchaseStepTwo from './PurchaseStepTwo';
import PurchaseStepThree from './PurchaseStepThree';

import styles from './BookPurchaseModal.module.scss';

const BookPurchaseModal = ({item: {activity, childId}, closeBtn}) => {

  const [ step, setStep ] = useState('init');
  const [ apiRes, setApiRes ] = useState({})
  const [ payload, setPayload ] = useState({
    copies:1, 
    type:'bundle', 
    price:19.99,
    address:{
      address_1:'',
      address_2:'',
      city:'',
      country_name:'United States',
      state_name:'',
      zip:''
    }})
  const contRef = useRef();

  return (
    <div ref={contRef} className={`${styles.container} ${styles.init}`}>
      {
        step === 'init' ? 

          <PurchaseStepOne 
            activity={activity} 
            childId={childId}
            setStep={setStep} 
            contRef={contRef} 
            payload={payload} 
            setPayload={setPayload} 
          />
          
          :  step === 'delivery' ? 
          
          <PurchaseStepTwo 
            setStep={setStep} 
            contRef={contRef} 
            payload={payload} 
            setPayload={setPayload} 
            setApiRes={setApiRes}
            apiRes={apiRes}
            childId={childId}

          />

        :  step === 'confirm' ? 

          <PurchaseStepThree
            activityId={activity.activityId} 
            activityName={activity.activityTitle}
            setStep={setStep} 
            childId={childId}
            contRef={contRef} 
            payload={payload} 
            apiRes={apiRes}
            closeBtn={closeBtn}
          />
        :

          null
      }
    </div>
  )

}

export default BookPurchaseModal;