import React, { useState } from 'react';

import styles from './InlinePdfs.module.scss';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const InlinePdfs = ({ files = [] }) => {
  // number of pages in the pdf
  const [numPages, setNumPages] = useState(null);

  // this will set the number of pages in the attached pdf files
  function onPdfLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    !files.length ?
      null
      :
      <div className={styles.files}>
        <div className={`${styles.items}`}>
          {
            files.map((file, idx) =>
              // make sure the file is a pdf
              file.fileName.includes(".pdf") ? 
              <Document key={`file_${file.id}`}
                className={styles.documentWrapper}
                file={file.fileUrl}
                onLoadSuccess={onPdfLoadSuccess}>
                {Array.from(
                  new Array(numPages),
                  (el, index) => (
                    <Page key={`file_${file.id}_page_${index + 1}`}
                      className={styles.pageWrapper}
                      pageNumber={index + 1}
                    />)
                )}
              </Document>
              : 
              null
            )
          }
        </div>
      </div>
  )
}

export default InlinePdfs;