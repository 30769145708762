import React from 'react';

import styles from './TeacherNotifications.module.scss';
import partyBoomers from 'assets/boomers_bookParty.svg'
import { Link } from 'react-router-dom';

const Notification_party_deadline = ({imgSrc, classId, activityId, type}) => {

  const isDeadline = type === '5_days' ? false : true;

  return (
    <div className={styles.partyContainer}>
      <div className={"newFeature notification"}>New</div>

      <div className={styles.titleBar}>
        <img src={imgSrc} alt="feature image"/>
        <h4>{isDeadline?"Publishing Party Deadline!":"Deadline approaching!"}</h4>
      </div>
        {
          isDeadline ?
            <p>You have reached the project deadline. You can close the order or update the Publishing Party date if you need more time for parents to purchase books. You will not be able to close orders past the deadline. To do so, you will need to first update the Publishing Party Date.</p>
          : 
            <p>Families will have 5 more days to purchase books before the order is closed</p>
          }

      <div className={styles.btns}>
       { isDeadline ? 

          <Link className={`${styles.checkoutBtn} ${styles.orange}`}  to={`/teacher/classes/${classId}/${activityId}?m`} > Manage party
          </Link>

      :
          <Link className={`${styles.checkoutBtn} ${styles.wide}`} to={`/teacher/classes/${classId}/${activityId}?m`} >
            Send reminder to parents
          </Link>
          
      }
      </div>

    </div>
  )

}

export default Notification_party_deadline;