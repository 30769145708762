import { sendToApiReturn } from 'API/teacherCalls';
import LoadingOverlay from 'components/StructuralComps/LoadingOverlay/LoadingOverlay';
import { addAnimation } from 'helpers/uiFunctions';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import styles from './NewActivityModal.module.scss';
import ItemPreviewModal from './NewActivityResources/ItemPreviewModal/ItemPreviewModal';
import NewActivityResources from './NewActivityResources/NewActivityResources';

const NewActivityModal = ({ mobile, newActivityCont, closeModal, bannerPadding, fjUser, synod}) => {

  const classData = useSelector(state => state.fetchedClass)

  const defaultParams = {
      activityType:!fjUser?'story':'faith',
      startType:'own',
      activityName:'',
      grading:false,
      activityGradingType:null,
      storyStartId:null
    }

  const [ filterVals, setFilterVals ] = useState({ textInput:'', activityType:'all', subject:'all', author:'all' })

  const [ params, setParams ] = useState(defaultParams)

  const [ resourcesVis, toggleResources ] = useState(false)
  const [ loading, toggleLoading ] = useState(false)
  const [ error, toggleError ] = useState(false)
  const [ resourceItems , setResourceItems ] = useState(null)
  const [ storyStarts , setStoryStarts ] = useState(null)
  const [ previewItem , setPreviewItem ] = useState(null)
  const [ selectedItem , setSelectedItem ] = useState(null)
  
  const mainRef = useRef()
  const nameRef = useRef()

  const radioItems = [
    {
      objectClass:styles.story,
      title:'Stories',
      type:'story',
      text:'Collaboratively create an original story with your class that can even be published as a real paperback book.'
    },
    {
      objectClass:styles.journal,
      title:'Journals',
      type:'journal',
      text:'From Reading Response to Social-Emotional Learning, Journals provide students a consistent place to write.'
    },
    {
      objectClass:styles.assignment,
      title:'Assignments',
      type:'assignment',
      text:'Let students craft arguments, describe important concepts associated with a particular unit of study.'
    }
  ]

  const gradingTypes = [
    {value:1, text:'Basic'},
    {value:2, text:'Argument/Opinion'},
    {value:4, text:'Narrative'},
    {value:3, text:'Information & Explatory '}
  ]

  const handleClose = () => {
    closeModal()
    toggleResources(false)
    toggleError(false)
    setTimeout(()=>{
      setParams(defaultParams)
      mainRef.current.scrollTop=0
    },500)
  }

  const handleSlider = () => {
    setParams({...params, grading:!params.grading, activityGradingType:params.grading?null:1})
  }

  const handleSubmit = async () => {

    if(params.activityName===""){
      toggleError(true)
      return setTimeout(()=>addAnimation(nameRef, 'shake', 400),50) 
    }

    toggleLoading(true)
    toggleResources(false)
    const { classId } = classData
    const { activityName, grading, activityGradingType } = params
    const frontUrl = localStorage.getItem('frontUrl')

    localStorage.setItem('dashRecall', true)
    
    if (params.activityType !== 'story' && selectedItem) { 
      // assignment/journal using resource item
      const data = await sendToApiReturn(`activity/${selectedItem.activityId}/duplicate`, { classId, activityName, grading, activityGradingType })
      return window.location = `${frontUrl}/teacher/classes/${data.class_id}/${data.activity_id}/true`
    }

    // all 'from scratch' variants and stories with storyStart template
    const { activityId } = await sendToApiReturn('activity/create', {...params, classId})
    return window.location = !synod ? `${frontUrl}/teacher/classes/${classId}/${activityId}/settings/0` : `${frontUrl}/write/${classId}/${activityId}/settings/0`;
  }


  const selectItem = (id, item) => {
    setSelectedItem(item)
    setParams({
      ...params, 
      startType:'template', 
      activityName:item.activityName, 
      storyStartId: params.activityType==='story' ? id : null
    });
    toggleResources(false)
    setPreviewItem(null)
  }

  useEffect(()=>{

    if(selectedItem){
      const resetName = params.activityName === selectedItem.activityName ? "" : params.activityName
      setParams({...params, startType:'own', activityName:resetName})
      setSelectedItem(null)
    }
  },[params.activityType])

  useEffect(()=>{

    if(selectedItem && params.startType==='own'){
      const resetName = params.activityName === selectedItem.activityName ? "" : params.activityName
      setParams({...params, activityName:resetName})
      setSelectedItem(null)
    }
  },[params.startType])

  return (
    <div ref={newActivityCont} className={`${styles.container} ${mobile && styles.mobile} ${bannerPadding && styles.bannerPadding}`}>


      <div onClick={handleClose} id={"handle"} className={styles.clickHandle} />

        {loading &&
          <div id={"loaderDiv"} className={styles.loaderDiv}>
            <LoadingOverlay contained />
          </div>
        }

      <section ref={mainRef} className={styles.mainBlock} id={'mainBlock'}>
        
        <div onClick={()=>handleClose()} className={styles.close} />


        
        <h1>Create new activity</h1>
        {

          !fjUser &&
            <div className={styles.typeRadios}>
              <h2>Type of activity</h2>
              <div className={styles.radios}>
                {
                  radioItems.map((i,idx) =>
                    <label key={idx}>
                      <input checked={params.activityType===i.type} type="radio" name="activityType" />
                      <div onClick={()=>{setParams({...params, activityType:i.type}); setFilterVals({...filterVals, activityType:i.type})}} className={`${styles.radioDiv} ${params.activityType===i.type && styles.checked}`}>
                        <div className={`${styles.imgDiv} ${i.objectClass}`} />
                        <div className={styles.text}>
                          <h3>{i.title}</h3>
                          <p>{i.text}</p>
                        </div>
                      </div>
                    </label>
                  )
                }
              </div>
            </div>

        }

        <div className={styles.getStarted}>
          <h2>{params.activityType === 'story' ? 'Story Start' : 'Get started'}</h2>

          {

            params.activityType === 'story' &&
              <p className={styles.start}>Students read the first chapter then finish the story. <br/> Use one of our built-in storystart or write your own.</p>

          }

          <div className={styles.startRadios}>
            <label>
              <input checked={params.startType==="own"} type="radio" name="activityType" />
              <div onClick={()=>{toggleResources(false); setParams({...params, startType:"own"})}} className={`${styles.startRadio} ${params.startType==="own" && styles.checked}`}>
                <div className={`${styles.imgDiv} ${styles.fromScratch}`} />
                <h3>Start From Scratch</h3>
              </div>
            </label>

            <label>
              <input checked={params.startType!=="own"} type="radio" name="activityType" />
              <div onClick={()=>{toggleResources(true);setParams({...params, startType:"template"})}} className={`${styles.startRadio} ${mobile && styles.blocked} ${selectedItem&&styles.selectedItem} ${params.startType!=="own" && styles.checked}`}>
                {
                  !selectedItem ?
                    <>
                      <div className={`${styles.imgDiv} ${styles.resource}`} />
                      <h3>Select {params.activityType !== 'story' ? "Resource" : "StoryStart"} From Library</h3>
                      {mobile && <h5>only available on desktop</h5>}
                    </>

                  :

                    <>

                      <div className={styles.hoverDiv}>
                        <div className={styles.changeBtn}>
                          Change {params.activityType!=='story'?'Resource':'StoryStart'}
                          </div>
                      </div>
                      <div className={styles.text}>
                        <h4>{selectedItem.activityName}</h4>
                        <p>By {selectedItem.author}</p>
                      </div>

                      <div className={`${styles.subjectPill} ${styles[selectedItem.activitySubject.slice(0,3)]}`}>
                        {selectedItem.activitySubject==="" ? "other" : selectedItem.activitySubject.slice(0,3) === 'His' ? 'History' : selectedItem.activitySubject}
                      </div>
                    </>

                } 
              </div>
            </label>
          </div>
        </div>

        <div className={styles.name}>
          <h2>Activity name</h2>
          <input 
            tabIndex={'-1'}
            ref={nameRef} 
            className={error&&styles.red} 
            value={params.activityName} 
            onChange={e=>{setParams({...params, activityName:e.target.value}); toggleError(false)}} 
            type="text"/>
          {error && <p className={styles.error}>Please give your activity a name</p>}
        </div>
        {
          !fjUser &&

            <div className={styles.grading}>
              <h2>Grading</h2>

              <div className={styles.sliderDiv}>
                <div onClick={()=>handleSlider()} className={`${styles.slider} ${params.grading && styles.active}`}>
                  <div className={`${styles.sliderInner} ${params.grading && styles.active}`} />
                </div>
                <p className={params.grading && styles.activeText}>{params.grading ? 'Active': 'Not Active'}</p>
              </div>

              <div className={`${styles.gradingRadios} ${!params.grading && styles.inactive}`}>
                {
                  gradingTypes.map((i,idx) =>
                    <div onClick={()=>setParams({...params, activityGradingType:i.value})}
                      className={`${styles.gradingRadio} ${params.activityGradingType === i.value && styles.checked}`}>
                        <p>{i.text}</p>
                    </div>
                  )
                }
              </div>
            </div>

        }

        <div onClick={handleSubmit} className={styles.createBtn}>
          <p>Create activity</p>
        </div>

      </section>
    
      { 
      
        resourcesVis && 

          <NewActivityResources 
            filterVals={filterVals}
            params={params}
            setParams={setParams}
            setFilterVals={setFilterVals}
            toggleResources={toggleResources}
            setPreviewItem={setPreviewItem}
            storyStarts={storyStarts}
            setStoryStarts={setStoryStarts}
            resourceItems={resourceItems}
            setResourceItems={setResourceItems} />

        }

        {

          previewItem &&

            <ItemPreviewModal
              selectItem={selectItem}
              setPreviewItem={setPreviewItem}
              item={previewItem} />

        }
    </div>
  )

}

export default NewActivityModal;



// [1, “Basic”],
// [2, “Argument/Opinion”],
// [3, “Information & Explanatory”],
// [4, “Narrative”]