import axios from 'axios';
import {store} from '../../storeConfig';



export const getCsv = (query, className) => {
  
  const backUrl = localStorage.getItem('backUrl')
  const url = `${backUrl}/api/${query}`
  const token = localStorage.getItem('t')
  console.log(url)

  axios.get(url, 
    { responseType: 'blob',
      headers: {
      'responseType': 'text',
      'Authorization': token || '' }
    }
  )

  .then((response) => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data] , { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${className}.csv`); 
      document.body.appendChild(link);
      link.click()
    }
  )
  .catch((error) => console.log(error));
}
