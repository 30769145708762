import { sendToApiReturn } from 'API/teacherCalls';


export const handleCsvUpload = (response, details) => {
     
  var errCount = 0

  response.data.forEach(item => {
    if(

      !item.first_name.trim().length
      ||!item.last_name.trim().length
      ||!item.grade.trim().length){
        errCount++;
      }
  })

  details({data: response.data, errCount:errCount})

}


export const handleNewClassOnboarding = async (response, details, store, setQueryItem) => {

  if(details.variant==='journalTemplate'||details.variant==='writingBee'){
    const res = await sendToApiReturn(`activity/${details.activityId}/duplicate`, {classId:response[0].classId})
    const frontUrl = localStorage.getItem('frontUrl')
    window.location=`${frontUrl}/teacher/classes/${response[0].classId}/${res.activity_id}/true`
    localStorage.setItem('dashRecall', true)

    
  } else if (details.variant==='simplifiedActivity'){
    store.dispatch(setQueryItem({type:details.variant, classObj:response[0], onboarding:true, activityType:details.activityType}))
    
  } else if (details.variant==='newActivity'){
    store.dispatch(setQueryItem({type:details.variant, classObj:response[0], onboarding:true, activityType:details.activityType}))
    
  } else {
    store.dispatch(setQueryItem({type:'newStudent', variant:details.variant, classObj:response[0], onboarding:true}))
    
  }

}
