import React from 'react';

import styles from './WritingTipsModal.module.scss';

const WritingTipsModal = () => {

  return (
    <div className={styles.container}>
      <h1>Tips for writing your StoryStart</h1>

      <h2>Writing your own StoryStart</h2>
      <p>Writing your own story start is really easy and means that you have a unique opportunity to really shape your students' responses and take them in a really interesting direction. Whilst it might sound obvious, here are some of the essential ingredients that you need to consider to make a wonderful recipe ready for your students to cook up into their story writing!</p>

      <h2>Settings:</h2>
      <p>Set your story in a place that appeals to your students. Maybe it's the local ice cream parlour that you know most of the class visit with their family at the weekend? Maybe it's the school canteen? Set your story in a place that they can vividly describe using sensory detail to act as a great way to engage your students in the task.
      </p>

      <h2>The Characters:</h2>
      <p>Here is where you have real potential for fun; make a character that really appeals to your class. Write the role-model that you know your students need to read about. Give them character traits that will help to inspire and engage them. Give them a role model that you never had in fiction. </p>
      <p>Alternatively, why not sprinkle a little reality into your fiction and make some of the teachers in your school protagonists! For students who struggle with creative writing, the opportunity to ‘lean in' on reality is a great springboard for their imagination. And by all means, give some of your students the leading role in the story start but just be mindful of your students' feelings towards others that may be bubbling secretly under the surface.</p>

      <h2>The Plot:</h2>
      <p>This can be the trickiest part to plan – especially if you don't have an idea by the time it comes to writing. Our suggestion would be to think about the characters that you have and a genre you might like to follow the conventions of. Once you've established these, think about a conflict that resonates with your students and utilise common themes that are important to them at their stage of development; coming of age, dealing with loss, racism, consent.</p>
      <p>Finally, don't be afraid to take inspiration from the best of the best in literature. Recommendabook (https://www.recommendmeabook.com/covers) gives you the opening page of novels from around the world. Magpie some ideas and make them your own! Just try to not get suckered in to reading all of them in one sitting!</p>

      <h2>Where you 'end' the story:</h2>
      <p>Finally, this might sound obvious but think about where you will end your chapter. You need to have given your students enough to hook them in with regards to character, setting and narrative so consider your options; a cliff-hanger, a twist or a surprise.</p>

    </div>
  )

}

export default WritingTipsModal;