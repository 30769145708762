import React, { useState, useEffect,  useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { store } from 'storeConfig';

import { sendToApiReturn } from 'API/teacherCalls';
import { updateDashClassName } from 'Redux/actions';
import useOutsideClick from 'helpers/CustomHooks/useOutsideClick';
import { idxClass } from 'helpers/idxClass';

import cap from '../../../../assets/cap_blue2.svg'
import book from '../../../../assets/book_blue.svg'
import styles from './DashGroupBlock.module.scss';
import DashGroupDropdown from './DashGroupDropdown';
import CopyPillTooltip from 'components/UtilityComps/CopyPillTooltip/CopyPillTooltip';

const DashGroupBlock = ({idx, count, archive, item:{ classId, classCode, className, iconKey, studentsCount, teachers, archived, activities, canBeDeleted }, modalOverlay}) => {

  const history = useHistory()
  const [ isOpen, toggleOpen ] = useState(false)
  const [ display, toggleDisplay ] = useState(false)
  const [ editing, toggleEditing ] = useState(false)
  const dropRef = useRef()
  const contRef = useRef()
  const inputRef = useRef()
  const myId = useSelector(state => state.dashData.teacherId)

  const groupActions = activities.filter(i => i.sections.some(i=>(i.unseenEntries>0&&i.sectionPhase==2)))


  useEffect(()=>{
    const offset = Math.floor(Math.random() * count) * 90
    setTimeout(()=>{ toggleDisplay(true) },offset)
  },[])


  const toggleDropdown = input => dropRef.current.classList[input](styles.open)
  

  useOutsideClick(inputRef, () => editing &&  handleNewName())


  const handleNavigate = () => {
    history.push(`/teacher/classes/view/${classId}/?activities`)
  }

  const handleNewName = () => {
    if(inputRef.current?.value !== "" && inputRef.current?.value!== className){
      store.dispatch(updateDashClassName(idx, inputRef.current.value))
      sendToApiReturn(`class/${classId}/update?className=${inputRef.current.value}`)
      toggleEditing(false)
    }
  }

  const handleNameinput = async (e) => {

    if (e.key === 'escape') {
      toggleEditing(false);
    }

    if (e.key === 'Enter') {
      handleNewName()
    }

  }

  return (
    <div onMouseLeave={()=> toggleDropdown('remove')} ref={contRef} className={`${styles.container} ${isOpen && styles.open} ${display && styles.display} ${archive && styles.noGlow}`}>
        {groupActions.length > 0 && <div className={`${styles.groupActionsDot} ${!isOpen && styles.visible}`}>{groupActions.length} </div>}

      <div className={styles.inner}>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={`${styles.topRow} ${styles[archive ? 'grey' : idxClass(iconKey)]}`}>
              <div className={styles.nameDiv}>
                {
                  !editing ?
                    <h5>{className}</h5>
                    :
                    <input ref={inputRef} onKeyUp={e=>handleNameinput(e)} type="text" defaultValue={className} autoFocus className={styles.nameInput} />
                }
              </div>
              <CopyPillTooltip copyData={classCode} displayData={classCode} tooltipText={"click to copy class code"} className={styles.copy} small/>
              <div onClick={()=>toggleDropdown('toggle')} className={styles.dots} />
            </div>
            
            <div className={styles.bottomRow}>

              {
                <div className={styles.left}>
                  <div className={styles.icons}>
                    <img src={cap} alt="cap" />
                    <p>{studentsCount} student{studentsCount!==1&&'s'}</p>
                    <img src={book} alt="book" />
                    <p>{activities.length} activities</p>
                  </div>
                  <div onClick={handleNavigate} className={styles.teachers}>
                    {
                      teachers?.map((i,idx) => 
                      i.teacherId !== myId &&
                      <div className={`${styles.circleImg}`} key={`${idx-'teacherid'}`}> 
                            { teachers[idx].teacherImage && teachers[idx].teacherImage!=="" ?  <img src={i.teacherImage} alt="" /> : <div className={`${styles.placeholder} ${styles[idxClass(idx)]}`}></div> }
                          </div> 
                        )
                      }
                    </div>
                </div>
              }

              <div onClick={()=>toggleOpen(!isOpen)} className={`${styles.chevron} ${isOpen && styles.open}`} />

            </div>
          </div>

          <div className={styles.clickHandle} onClick={handleNavigate} />
      </div>

      <div className={styles.activitiesDiv}>
        { activities.length === 0 ? 
          <div className={styles.placeholder}>
            <h5 className={styles.noActivities}>No activities to display</h5>
          </div>
          :
          activities.map((activity,idx) => 
            <Link to={`/teacher/classes/${activity.activityClassId}/${activity.activityId}/settings/0`} className={styles.dashActivityDiv} key={activity.activityId}>
              {activity.sections.filter(i=>(i.unseenEntries>0&&i.sectionPhase==2)).length>0 && <div className={styles.actionCount}></div> }
              <div className={styles.iconDiv}>
                <div className={`${styles.icon} ${styles[archive ? 'grey' : idxClass(idx)]}`} />
              </div>
              <div className={styles.text}>
                <h5>{activity.activityName}</h5>
                <p>{activity.sections.length} sections</p>
              </div>
            </Link>
          )
        }
      </div>
    </div>

        <DashGroupDropdown
          canBeDeleted={canBeDeleted}
          modalOverlay={modalOverlay}
          dropRef={dropRef}
          className={className}
          classId={classId}
          idx={idx}
          archived={archived}
          toggleEditing={toggleEditing}
          toggleDropdown={toggleDropdown} />
    </div>
  )

}

export default DashGroupBlock;