import React from 'react'
import styles from './StudentReportModal.module.scss'
import { useUserType } from 'context/AltPlatformProvider'
import ReportTable from './ReportTable'



const StudentReportModal = ({item :{studentName, image, data}}) => {
  const mobile = true
  const {fjUser} = useUserType()
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.imgDiv}>
          <img src={image} alt={studentName} />
        </div>
        <h4>{studentName}</h4>
      </div>
        <div className={styles.tableHeader}>
        <div className={styles.column}>&nbsp;</div>
        <div className={`${styles.column} ${fjUser && styles.wide}`}>Words</div>
        <div className={`${styles.column} ${fjUser && styles.wide}`}>Status</div>
        <div className={`${styles.column} ${fjUser && styles.wide}`}>Approved</div>

              <div className={`${styles.column} ${fjUser && styles.wide}`}>Voted</div>
              {/* {!fjUser && */}
               <div className={`${styles.column}  ${fjUser && styles.wide}`}>Grade</div>
              {/* // } */}

      </div>
        <ReportTable 
          data={data}
        
          
          />
    </div>
  )
}

export default StudentReportModal