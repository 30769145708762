import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import styles from './NewTeacherModal.module.scss';
import logo from 'assets/modalContent/logo.png'; // @todo replace logo image
import miniMe from 'assets/modalContent/mini-mi.png';
import menuSnippet from 'assets/modalContent/menu-snippet.png';
import info from 'assets/modalContent/info.png';
import storyBoomer from 'assets/modalContent//boomer-storybook.png';
import labBoomer from 'assets/modalContent/boomers-science_Lab.png';
import query from 'assets/modalContent/query.png';
import { setQueryItem, fetchClass } from '../../../../Redux/actions';
import { openInNewTab } from 'helpers/uiFunctions'
import { getFromApi } from 'API/teacherCalls';

const NewTeacherModal = ({item}) => {
  
  const dispatch = useDispatch();
  const handleUpdate = (e) =>  {
    
    if(e.target.checked){
      localStorage.setItem('noRepeat', 'true')
    }else if(!e.target.checked && localStorage.getItem('noRepeat')){
      localStorage.removeItem('noRepeat')
    }
  };

   
  const handleNew = () => {
    // history.push(`/teacher/classes/activities/${item.classId}`)
    const callAPI = async () => {dispatch(fetchClass(await getFromApi(`class/${item.classId}`)))}
    callAPI()
    setTimeout(()=>{dispatch(setQueryItem({ type: 'newActivity', classId:item.classId}))},200) 
    // setTimeout(()=>{{showModal(modalOverlay, { type: 'newActivity', classId:classId})}},200) 
  }

return (
<Fragment>
  <div className={styles.top}>
    <div className={styles.half}>
      <h1>Welcome to</h1>
    </div>
    <div className={styles.half}>
      <img src={logo} alt="Escribli Logo"/>
    </div>
  </div>
  <div className={styles.main}>
    <div className={styles.half}>
      <h2>Classes</h2>
      <div className={styles.firstClassDiv}>
        <div className={styles.firstClassInner}>
          <img src={miniMe} alt="Mini Me" />
          <h4>Your "mini you"</h4>
        </div>
        <div className={styles.firstClassInner}>
          <h2>My First Class</h2>
          <div className={styles.classBtn}>Take me to my class</div>
        </div>
      </div>
      <div className={styles.text}>
        <p>We have created for you your first class and your first student!</p>
        <p>This student is a “mini you” that will let you be part of the class and see what students see!</p>
      </div>
      <div className={styles.menuDetails}>
        <img src={menuSnippet} alt="menu snippet" />
        <div className={styles.switchDiv}>
          <img src={info} alt="info"/>
          <p>You can switch between teacher and student account in the profile section.</p>
        </div>
      </div>
    </div>
    <div className={styles.half}>
      <h2>Activities</h2>
      <div className={styles.detailsDiv}>
        <div className={styles.third}>
          <div  onClick={()=>{openInNewTab("https://escribli.com/tools/stories")}}  className={styles.innerTop}>
            <img  src={query} alt=""/>
            <h6>What are stories?</h6>
          </div>
          <div className={styles.innerBottom}>
            <div className={styles.heading}>
              <img src={storyBoomer} alt=""/>
              <h3>Stories</h3>
            </div>
              <p>Collaboratively create an original story with your class that can even be published as a real paperback book.</p>
          </div>
        </div>
        <div className={styles.third}>
          <div  onClick={()=>{openInNewTab("https://escribli.com/tools/assignments")}}  className={styles.innerTop}>
            <img src={query} alt=""/>
            <h6>What are assignments?</h6>
          </div>
          <div className={styles.innerBottom}>
          <div className={styles.heading}>
              <img src={labBoomer} alt=""/>
              <h3>Assignments</h3>
            </div>
              <p>Persuasive essays, informational writing, poetry, anything!</p>
          </div>
        </div>
        <div className={styles.third}>
          <div  onClick={()=>{openInNewTab("https://escribli.com/tools/journals")}}  className={styles.innerTop}>
            <img src={query} alt=""/>
            <h6>What are journals?</h6>
          </div>
          <div className={styles.innerBottom}>
            <div className={styles.heading}>
              <img src={storyBoomer} alt="" />
              <h3>Journals</h3>
            </div>
            <p>Give your students a safe and inspiring place to write... consistently!</p>
          </div>
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <div onClick={()=>{openInNewTab('https://escribli.com/tools')}} className={styles.button}>Learn more</div>
        <div onClick={handleNew} className={styles.button}>Create your first activity</div>
      </div>
    </div>
  </div>
  <div className={styles.bottom} >
    <label ror="noShow">
      <input onChange={(e)=>{handleUpdate(e)}} type="checkbox" name="noShow" id="noShow"/>
      Don't show me this message again
    </label>
  </div>

</Fragment>

)
}

export default NewTeacherModal;