import React from 'react';
import ReactPlayer from 'react-player/youtube'

import spinner from 'assets/spinner-sml--fast.svg';
import styles from './VideoPlayerModal.module.scss';
import closeImg from 'assets/icon-close--circle.svg'

const VideoPlayerModal = ({ closeBtn, item:{url, height, width} }) => {

  return (
    <div className={styles.container}>
      <div
        onClick={()=>{closeBtn.current.click()}} 
        className={styles.closePlayer}>
        <img src={closeImg} alt="close"/>
      </div>
        <ReactPlayer 
          controls={true}
          width={width}
          height={height}
          url={url} 
        />
      <p onClick={()=>{closeBtn.current.click()}}>close</p>
    </div>
  )

}

export default VideoPlayerModal;