import React from 'react';
import { useState } from 'react';
import EntryTextEditor from '../EntryTextEditor/EntryTextEditor';
import pencil from 'assets/pencil_blue.svg'
import save from 'assets/save.svg'
import approve from 'assets/thumb.svg'
import privateModeImg from 'assets/sidebarIcons/sidebar_private_blue.svg';

import styles from './EntryModalMain.module.scss';
import MainTopBar from './MainTopBar';
import { sendToApiReturn } from 'API/teacherCalls';
import EntryCommentInput from '../EntryCommentInput/EntryCommentInput';
import { store } from 'storeConfig';
import { updateActivitySection } from 'Redux/actions';

const EntryModalMain = ({ activityId, entryData, setPreviewImgs, privateMode, entryData: { approved, submitted, studentId, entryText, entryHtml, studentFiles, wordCount, privateActive }, wordBank, activityClassName, activityName, sectionIndex, sectionName, handleApproveEntry }) => {

  const [ editing, setEditing ] = useState(false)
  const [ localText, setLocalText ] = useState(entryHtml)
  const returnWordCount = text =>  (text === "" || text === "<p><br></p>") ? 0 :  text.replace('<br>', ' ').split(' ').length

  const handleSaveBtn = async () => {

    if (editing) {
      const data = await sendToApiReturn(`activity/${activityId}/section/${sectionIndex}/edit_entry/${studentId}`, {value: localText})
      store.dispatch(updateActivitySection(sectionIndex, data))
    }
    setEditing(!editing)

  }

  return (
    <div className={styles.container}>
      
      <MainTopBar entryData={entryData} />

      <div className={styles.entryDiv}>

        <div className={styles.detailsBar}>
          <div className={styles.textDiv}>
            <h5>{activityClassName} / {activityName}</h5>
            <h3><span><p>{sectionIndex+1}</p></span> {sectionName} </h3>
          </div>

          <div className={styles.wordsGrade}>
            <p>words: {privateActive ? wordCount : returnWordCount(localText)}</p>

            {
              entryData.finalGrade !== 0 &&
                <div className={styles.grade}>
                  <h5>Grade</h5>
                  <div className={styles.gradeDiv}>
                    <h5>{entryData.finalGrade}</h5>
                  </div>
                </div>
            }

          </div>
        </div>

        
        <div className={styles.editorDiv}>


          {(privateActive &&  privateMode) && 
            <div className={styles.privateModeOverlay}>
              <img src={privateModeImg} alt="private mode" />
              <h1>Private mode is turned <span>ON</span></h1>
              <p>In private mode, educators won't be able to see any of the student entries for the entire activity (all sections).
Educators will be able to see who is writing and how many words each one has written.</p>
              
            </div>
          }

          <EntryTextEditor
            privateMode={privateMode}
            privateActive={privateActive}
            setPreviewImgs={setPreviewImgs}
            studentFiles={studentFiles}
            localText={localText}
            setLocalText={setLocalText}
            entryData={entryData}
            editing={editing}
            wordBank={wordBank} />

        </div>

        <div className={styles.buttons}>
          
          <EntryCommentInput 
            entryData={entryData}
            activityId={activityId}
            sectionIndex={sectionIndex} />

          <div className={styles.rightBtns}>

            { editing && <h5 onClick={()=> {setEditing(false); setLocalText(entryText)}} className={styles.cancelBtn}>cancel</h5>}
            
            <div onClick={handleSaveBtn} className={styles.editBtn}>
              <img src={!editing ? pencil : save} alt="icon" />
              <p>{!editing ? "Edit entry" : "Save entry"}</p>
            </div>

            <div onClick={handleApproveEntry} className={`${styles.approveBtn} ${( !submitted || approved ) && styles.disabled}`}>
              <img src={approve} alt="approve" />
              <p>Approve entry</p>
            </div>

          </div>
        </div>


      </div>
    </div>
  )

}

export default EntryModalMain;