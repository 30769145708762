import React, {useState, useEffect, useRef } from 'react';
import {  getFromApi, sendToApiReturn } from 'API/teacherCalls';
import { normalize } from 'helpers/filterFunctions'
import { LoadingOverlay } from 'components';

import styles from './AddExistingModal.module.scss';
import { store } from 'storeConfig';
import { updateClassKey } from 'Redux/actions';
import plus from 'assets/icon_plus_blue.svg'
import spinner from 'assets/spinner-white-sm.svg';

const AddExistingModal = ({item:{classId, updateStudents }, closeBtn}) => {

  const [ selected, setSelected ] = useState([]);
  const [ students, setStudents ] = useState(null);
  const [ loading, toggleLoading ] = useState(false);
  const [ filterValues, setFilterValues ] = useState({textInput:'', grade:'all'})
  const searchRef = useRef()

  const getStudents = async () => {  setStudents(await getFromApi(`students/external/${classId}`) )}

  const handleCheckbox = (e) => {

    let selectedCopy = [...selected]
    if(e.target.checked){
      selectedCopy.push(students[e.target.value])
      setSelected(selectedCopy)
    } else {
      const index = selectedCopy.indexOf(students[e.target.value])
      selectedCopy.splice(index, 1)
      setSelected(selectedCopy)
    }
  }

  const handleUnselect = (e) => {
    document.getElementById(`${e.target.id}Row`).click()
  }

  useEffect(()=>{ getStudents() },[])

  const handleSubmit = async () => {

    toggleLoading(true)

    const payload = {student:selected, classId:[classId]}
    const data = await sendToApiReturn('students/create', payload)
    store.dispatch(updateClassKey(data))
    updateStudents(selected)

    closeBtn.current.click();
   
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Add Existing Students</h1>
      <div className={styles.main}>
        <div className={styles.topBar}>
          <h2>Select the students you want to add to this class</h2>

          <div ref={searchRef} className={`${styles.search}`}>
            <div className={styles.inner}>
              <div className={styles.lupa} />
              <input 
                onFocus={() => { searchRef.current.classList.add(styles.focus) }}
                onBlur={() => { searchRef.current.classList.remove(styles.focus) }}
                onChange={(e)=>{setFilterValues({...filterValues, textInput:e.target.value})}}
                autoComplete={"off"} type="text" name="search input" />
            </div>
          </div>

        </div>

        <div className={styles.left}>
          <div className={styles.tableHeader}>
            <p>All Students</p>
          </div>
          <div className={styles.tableRows} onChange={(e)=>{handleCheckbox(e)}}>
          {!students ? 

              <LoadingOverlay contained spinnerWidth={"70px"} />

          :
            
              students.map((item,index)=>
                normalize(item.studentName).includes(normalize(filterValues.textInput)) && 
                  <label key={index}>
                    <input type="checkbox" value={index}/>
                    <div className={styles.row} id={`${item.studentId}Row`}>
                      <div className={styles.studentDetails}>
                        <img src={item.boomerUrl} alt="avatar" />
                        <h5>{item.studentName}</h5>
                      </div>
                      <div className={styles.add}>
                        <p className={styles.hideRes}>Add student</p>
                        <p className={styles.showRes}>select</p>
                        <img src={plus} alt="plus" />
                      </div>
                    </div>
                  </label>
              )
            }
        </div>
        </div>
        <div className={styles.right}>
        <div className={styles.rightInner}>
          <div className={styles.tableHeader}>
            <p>Selected students</p>
          </div>
          <div className={styles.tableRows}>
          {
            selected.map((item, index) => {
              return(
                <div key={index}className={styles.row}>
                <div className={styles.studentDetails}>
                  <img src={item.boomerUrl} alt="avatar" />
                  <h5>{item.studentName}</h5>
                </div>
                <div id={item.studentId} onClick={(e)=>{handleUnselect(e)}} className={styles.remove}>
                  <div className={styles.cross} />
                </div>
              </div>
              )
            })
          }
          {
            selected.length<1 &&
              <div className={styles.placeholder}>
                <h5>No students selected</h5>
              </div>
          }
        </div>
        </div>
        </div>
      </div>
      
      <div className={styles.buttons}>
        <div onClick={()=>closeBtn.current.click()} className={styles.cancelBtn}>
          cancel
        </div>
        <div onClick={handleSubmit} className={`${styles.submitBtn} ${loading&&styles.loading} ${selected.length<1 && styles.inactive}`}>
          {!loading ? "Add students" : <img src={spinner} />}
        </div>
      </div>
    </div>

  )
}

export default AddExistingModal;