import React from 'react';

import styles from './PdfCreationModal.module.scss';

const PdfCreationModal = ({ item:{ activity} }) => {

  return (
    <div className={styles.container}>
      <h1>PDF Creation</h1>

      <div className={styles.warning}>
        <p>Your activity will be generated in PDF format and sent to your email. Please see the details below:</p>
      </div>

      <div className={styles.details}>
        <p>Activity name: <strong>{activity.activityTitle}</strong></p>
        <p>Number of sections/chapters: <strong>{activity.sections.length}</strong></p>
        <p>Email: <strong>parent@escribli.com</strong></p>
      </div>

      <div className={styles.buttons}>
        <div className={styles.cancelBtn}>Cancel</div>

        <div className={styles.sendBtn}>Send email</div>
      </div>
    </div>
  )

}

export default PdfCreationModal;