import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from "react-modern-calendar-datepicker";
import { format, add } from 'date-fns'
import isEqual from 'lodash/isEqual';
import { sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { updateActivitySection } from 'Redux/actions';

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import styles from './DeadlineModal.module.scss';
import icon from '../../../../../../assets/sidebarIcons/sidebar_calendar_blue.svg';
import DeadlineModalResponsive from './DeadlineModalResponsive';

const makeDateObject = date => {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  }
}

const DeadlineModal = ({ activity, activeSection, setModal, mobile, setActiveSection, activeSection: { sectionDue, sectionStart, sectionIndex, sectionPhase }}) => {
  
  const [ pending, setPending ] = useState(false);
  const [ openEnded, toggleOpenEnded ] = useState(sectionDue=='open');
  const [ localDate, setLocalDate ] = useState({sectionStart, sectionDue})
  const [ dateRange, setRange ] = useState({
    from: makeDateObject(sectionStart == null ? new Date() : new Date(sectionStart)),
    to: makeDateObject(new Date(sectionDue))
  });

  useEffect(()=>{
    console.log(sectionStart)
  },[])

  const saveBtnRef = useRef()
  
  const startBlocked = [1,21].indexOf(sectionPhase) === -1 //modification of start date not allowed if section is live or complete
  const isLive = [2,3,4,5].indexOf(sectionPhase) !== -1 

  useEffect(()=>{ //on localDate change, activate 'save' button if existing dates(props) do not match local dates
    setPending(
      !isEqual(
        {start:new Date(localDate.sectionStart).setHours(0,0,0,0), end: new Date(localDate.sectionDue).setHours(0,0,0,0)}, 
        {start:new Date(sectionStart).setHours(0,0,0,0), end: new Date(sectionDue).setHours(0,0,0,0)}
      )
    )
  },[localDate])

  const handleDateChange = (val, end) => {
    console.log(val)
    const { day, month, year } = val.from
    const dateStr = `${year}-${month>9?month:`0${month}`}-${day>9?day:`0${day}`}`
    const newDate = new Date(dateStr)
    setLocalDate({...localDate, [!end ? 'sectionStart' : 'sectionDue']:newDate})
    setRange({...dateRange, [!end ? 'from' : 'to']:val.from})
  }

  const handleSlider = () => {
    toggleOpenEnded(!openEnded)
    if(openEnded){
      const oneWeek = new Date(new Date().setDate(new Date().getDate() + 7));
      setRange({...dateRange, to:makeDateObject(oneWeek)})
      setLocalDate({sectionStart:new Date(), sectionDue:oneWeek})
    } else {
      setRange({...dateRange, to:makeDateObject(new Date())})
      setLocalDate({sectionStart:null, sectionDue:'open'})
      const newData = {...activeSection, sectionStart: null, sectionDue: 'open'}
      sendToApiReturn(`activity/${activity.activityId}/update_unit/${sectionIndex}`, newData)
      setPending(false)

    }
  }

  const handleSubmit = async () => {
    const newData = {...activeSection, sectionStart: new Date(localDate.sectionStart), sectionDue: new Date(localDate.sectionDue)}
    const resData = await sendToApiReturn(`activity/${activity.activityId}/update_unit/${sectionIndex}`, newData)
    store.dispatch(updateActivitySection(sectionIndex, resData))

    if(!mobile){
      setPending(false)
      saveBtnRef.current.innerText='Saved!'
      setTimeout(()=>{if(saveBtnRef.current)saveBtnRef.current.innerText='Save'},1600)
    } else {
      setModal("setup");
      setActiveSection(resData)
    }
  }

  return (
      mobile ? 
        <DeadlineModalResponsive
          localDate={localDate}
          setLocalDate={setLocalDate}
          sectionDue={sectionDue}
          makeDateObject={makeDateObject}
          sectionStart={sectionStart}
          startBlocked={startBlocked}
          handleSubmit={handleSubmit} />

      :
        <div className={styles.container}>

          <div className={styles.titleBar}>
            <img src={icon} alt="upload" />
            <h2>Set your dates</h2>
          </div>

          <div className={styles.datesDiv}>

            <div className={styles.text}>
              <h6>Start date</h6>
              <h6>End date</h6>
            </div>

            <div className={styles.box}>

              <div className={`${styles.start} ${startBlocked && styles.blocked}`}>
                <div className={styles.icon} />
                <h5>{localDate.sectionStart == null ? "Open" : format(new Date(localDate.sectionStart), 'MMMM  d, yyyy')}</h5>
                <div className={styles.padlock}> <div className={styles.tooltip}> <h5>You cannot change the start date of <strong>{isLive ? 'live' : 'completed'}</strong> sections</h5> </div> </div>
              </div>

              <div className={styles.end}>
                <div className={styles.icon} />
                <h5>{localDate.sectionDue == "open" ? "Open" : format(new Date(localDate.sectionDue), 'MMMM  d, yyyy')}</h5>
              </div>

            </div>

          </div>

          <div className={`${styles.calendarDiv} ${openEnded && styles.openEnded}`}>

              <Calendar
                value={dateRange}
                calendarClassName={`${startBlocked && 'Calendar__blocked'} ${openEnded && 'openEnded'}`}
                onChange={val => handleDateChange(val, false)}
                minimumDate={ !startBlocked && makeDateObject(new Date())}
                />
              <Calendar
                value={dateRange}
                calendarClassName={`${openEnded && 'openEnded'}`}
                onChange={val => handleDateChange(val, true)}
                minimumDate={ makeDateObject( add(new Date(localDate.sectionStart), { days: 1 }) ) }
              />
            
          </div>

          <div className={styles.bottomRow}>

            <div onClick={handleSlider} className={styles.sliderDiv}>
              <p className={`${styles.deadlineSliderTxt}`}>{!openEnded ? "Deadline" : "No Deadline"}</p>
              <div className={`${styles.slider} ${!openEnded && styles.active}`}>
                <div className={`${styles.sliderInner} ${!openEnded && styles.active}`} />
              </div>
              {/* <p className={!openEnded && styles.show}>active</p> */}
            </div>

            <div onClick={handleSubmit} className={`${styles.saveBtn} ${(pending&&localDate.sectionDue!=="open") && styles.enabled}`}>
              <p ref={saveBtnRef}>Save</p>
            </div>

          </div>

        </div>
    
  )

}

export default DeadlineModal;