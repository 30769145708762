import React, { useRef } from 'react'
import FlipPage from 'react-flip-page'

import styles from './BookPreviewModal.module.scss'
import close from 'assets/icon-close--circle.svg'

const BookPreviewModal = ({ closeBtn }) => {

  return (
    <div className={styles.container}>


      <img onClick={()=>{closeBtn.current.click()}} className={styles.close} src={close} alt=""/>

      <FlipPage
        className={styles.flipPage}
        orientation={'horizontal'}
        uncutPages={true}
        flipOnTouch={true}
        showTouchHint={true}
        showHint={true}
        flipOnTouchZone={50} 
        animationDuration={300}
        disableSwipe={true}>

        <article>
          <div className={styles.page}>
            <p>Page 1</p>
          </div>
          <div className={styles.page}>
            <p>Page 2</p>
          </div>
        </article>

        <article>
          <div className={styles.page}>
            <p>Page 3</p>
          </div>
          <div className={styles.page}>
            <p>Page 4</p>
          </div>
        </article>

        <article>
          <div className={styles.page}>
            <p>Page 5</p>
          </div>
          <div className={styles.page}>
            <p>Page 6</p>
          </div>
        </article>
        

        <article>
          <div className={styles.page}>
            <p>Page 7</p>
          </div>
          <div className={styles.page}>
            <p>Page 8</p>
          </div>
        </article>
        

        <article>
          <div className={styles.page}>
            <p>Page 9</p>
          </div>
          <div className={styles.page}>
            <p>Page 10</p>
          </div>
        </article>
        

        <article>
          <div className={styles.page}>
            <p>Page 11</p>
          </div>
          <div className={styles.page}>
            <p>Page 12</p>
          </div>
        </article>
        
      </FlipPage>

      <p className={styles.hint}>Click pages to turn</p>
    </div>
  )

}

export default BookPreviewModal;