export const calculatePlanPrice = (plan, months) => {
  console.log(plan)
  console.log(months)
  let startingDiscount = 0.975; //2.5%
  let rising = 0.015; //1.5%
  let result
  if (plan === 1) {
    if(months===1 ){
      result=12.99

    }else{
      for (let i = 1; i < months; i++) {
          startingDiscount -= rising
          result = 12.99 * months * startingDiscount  
      }
    }
    
      return result > 116.99 ? '116.99' : result.toFixed(2)
      
  }

  if (plan === 2) {
    if(months===1 ){
      result=14.99

    }else{
      for (let i = 1; i < months; i++) {
          startingDiscount -= rising
          result = 14.99 * months * startingDiscount.toFixed(2)
      }
    }

      return result > 134.99 ? '134.99' : result.toFixed(2)
      
  }
}


export const multiPrice = (copies, bundle) => {
    
  const base = bundle ? 14.99*copies : 12.99*copies;

  const shipping1 = 5;
  const shipping2 = 10;
  const shipping3 = 15;
  const shipping4 = 20;

  if(copies < 6) return (base+shipping1).toFixed(2);
  if(copies  >5 && copies < 11) return (base+shipping2).toFixed(2);
  if(copies > 10 && copies < 21) return (base+shipping3).toFixed(2);
  if(copies > 20) return (base+shipping4).toFixed(2);
  
}