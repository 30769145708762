import React from 'react'
import styles from './Institution.module.scss'

import {FaPlus} from "react-icons/fa"

const InstituteTable = () => {
  return (
    <div className={styles.institute}>
        <div className={styles.header}>
            <h6>
                Teachers in the same institution
            </h6>
        </div>
        <div className={styles.table}>
            <div className={styles.item}>
                <div>
                    <input type="text" />
                </div>
                <div>
                    <input type="text" />
                </div>
                <div className={styles.addBtn}>
                    Create Account
                </div>
            </div>
        </div>

        <p style={{ fontSize: "14px" }}>
           <em>
                *All accounts are created with the password <strong>ChristtheKingNY</strong>
                Passwords are automatically set up as the institution name; no spaces or commas (capitalized as they are in the institution name)
           </em>
        </p>

        <div className={styles.inviteBtn}>
            add invitation <FaPlus />
        </div>
    </div>
  )
}

export default InstituteTable