import { sendToApiReturn } from 'API/teacherCalls';
import { BreakoutRoomBlock } from 'components';
import { chunkedArray } from 'helpers/chunkedArray';
import { filterArrayByObject, filterObjectByObject } from 'helpers/filterFunctions';
import { flattenArray } from 'helpers/flattenArray';
import { letters } from 'helpers/letters';
import React, { useEffect, useRef, useState } from 'react';

import styles from './BreakoutRoomsSetupModal.module.scss';
import StudentBlock from './StudentBlock';
import random from 'assets/icon-randomize--active.svg'
import { shuffleArray } from 'helpers/shuffleArray';

const BreakoutRoomsSetupModal = ({ closeBtn, item:{ activity, students, setActiveSection, activeSection }}) => {

  const [ existingIds, setExistingIds ] = useState(activeSection.rooms)
  const [ confirmedRooms, setConfirmedRooms ] = useState([])
  const [ unconfirmedRooms, setUnconfirmedRooms ] = useState([])
  const [ listStudents, setListStudents ] = useState(students)
  const [ textInput, setTextInput ] = useState('')
  const [ showConfModal, toggleConfModal ] = useState(false)
  const searchRef = useRef()
  const inputRef = useRef()

  useEffect(()=>{
    if(existingIds){
      //sort existing rooms and remove students from list
      const roomsArray = []
      existingIds.forEach(room => {
        roomsArray.push(room.map(id => students.find(student=>student.studentId===id)))
      })
      setConfirmedRooms(roomsArray)
      setListStudents( students.filter(student => !flattenArray(existingIds).includes(student.studentId) ) )
    }
  },[existingIds])
  

  const handleSelect = id => {
    if(listStudents.length===1) return
    const selectedIds = flattenArray(unconfirmedRooms);
    if(!selectedIds.includes(id)){
      selectedIds.push(id)
      setUnconfirmedRooms(chunkedArray(selectedIds, 2, listStudents.length))
    }

  }

  const handleRandomize = () => {
    
    setUnconfirmedRooms(chunkedArray(shuffleArray(listStudents.map(i=>i.studentId)), 2, listStudents.length))
  }

  const handleRemove = async (id, confirmed) => {
    if(confirmed){
      const removeIdx = existingIds.findIndex(i=>i.includes(id))
      const newArray = [
        ...existingIds.slice(0, removeIdx),
        ...existingIds.slice(removeIdx+1)
      ]
      setExistingIds(newArray)
      const res = await sendToApiReturn(`activity/${activity.activityId}/rooms/${activeSection.sectionIndex}`, {rooms:newArray})
      setActiveSection({...activeSection, rooms:res.rooms})
      
    } else {
      const removeIdx = unconfirmedRooms.findIndex(i=>i.includes(id))
      const newArray = [
        ...unconfirmedRooms.slice(0, removeIdx),
        ...unconfirmedRooms.slice(removeIdx+1)
      ]
      setUnconfirmedRooms(newArray)

    }
  }

  const handleSubmit = async () => {
    if(unconfirmedRooms.length===0){return}
    setUnconfirmedRooms([])
    setExistingIds([...existingIds, ...unconfirmedRooms])
    const res = await sendToApiReturn(`activity/${activity.activityId}/rooms/${activeSection.sectionIndex}`, {rooms:[...existingIds, ...unconfirmedRooms]})
    setActiveSection({...activeSection, rooms:res.rooms})
  }

  const handleClose = () => {
    if(unconfirmedRooms.length)return toggleConfModal(true)
    closeBtn.current.click()
  }

  return (
    <div className={styles.container}>

      <div onClick={handleClose} className={styles.close} />

      <div className={styles.sidebar}>
        <h1>Pick students</h1>

        <div ref={searchRef} className={`${styles.search}`}>
          <div className={styles.inner}>
            <div className={styles.lupa} />
            <input
              placeholder={"Type to find a student"}
              onFocus={() => { searchRef.current.classList.add(styles.open) }}
              onBlur={() => { searchRef.current.classList.remove(styles.open) }}
              onChange={e => setTextInput(e.target.value)}
              ref={inputRef} autoComplete={"off"} type="text" name="search input" />
          </div>
        </div>
        <div onClick={handleRandomize} className={`${styles.randomize} ${ !listStudents.length && styles.inactive}`}>
          <img src={random} alt="random" />
          <h5>Randomize all</h5>
        </div>

        <div className={styles.studentList}>
          { 

            !students?.length ?
              <h5 className={styles.placeholder}>No students found</h5>

            : !listStudents?.length ?
                <h5 className={styles.placeholder}>No remaining students</h5>

            : textInput!=="" && !filterArrayByObject(listStudents, {studentName:textInput}).length ?
                  <h5 className={styles.placeholder}>No students matching search</h5>
            
            : listStudents?.length &&

                listStudents.map((student, idx) =>
                  filterObjectByObject(student, {studentName:textInput}) &&
                    <StudentBlock
                      idx={idx}
                      confirmedRooms={confirmedRooms}
                      unconfirmedRooms={unconfirmedRooms}
                      handleSelect={handleSelect}
                      key={idx}
                      student={student} />
                )
          }
        </div>
      </div>

      <div className={styles.main}>
        <div className={styles.roomsDiv}>
          {
            confirmedRooms.map((room, idx) =>
              <BreakoutRoomBlock
                groupLetter={letters[idx]}
                key={idx}
                studentOne={room[0]}
                studentTwo={room[1]}
                studentThree={room[2]}
                handleRemove={handleRemove}
                confirmed={true}
              />)
          }
          {
            unconfirmedRooms.map((room, idx) =>
              <BreakoutRoomBlock
                groupLetter={letters[idx + confirmedRooms.length]}
                key={idx}
                studentOne={students.find(s=>s.studentId===room[0])}
                studentTwo={students.find(s=>s.studentId===room[1])}
                studentThree={students.find(s=>s.studentId===room[2])}
                handleRemove={handleRemove}
                confirmed={false}
              />)
          }

        </div>
        <div className={styles.btns}>
          <div onClick={()=>setUnconfirmedRooms([])} className={`${styles.cancelBtn} ${unconfirmedRooms?.length && styles.active}`}>Cancel</div>
          <div onClick={handleSubmit} className={`${styles.confirmBtn} ${(unconfirmedRooms?.length && !unconfirmedRooms.some(i=>i.length===1)) && styles.active}`}>Confirm rooms</div>
        </div>
      </div>

      {
        showConfModal &&
        <div className={styles.confOverlay}>
          <div className={styles.confModal}>
            <h1>Are you sure?</h1>
            <p>If you close this windows the rooms will not be saved.
              Click on ”Confirm Rooms” to save the selection.</p>
            <div className={styles.buttons}>
              <div onClick={() => toggleConfModal(false)} className={`${styles.cancelBtn} ${styles.active}`}>Cancel</div>
              <div onClick={() => closeBtn.current.click()} className={`${styles.confirmBtn} ${styles.active}`}>Close window</div>
            </div>

          </div>
        </div>
      }

    </div>
  )

}

export default BreakoutRoomsSetupModal;