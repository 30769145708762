export const initialStudents = [

  {
    studentName: "Carlos Guitierrez",
    email: "cgu@otmail.com",
    grade: "6",
    teacherName: "Jack Torrence",
    parentName: "Marta Guitierrez",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "James Wells",
    email: "Wella@hotmal.com",
    grade: "6",
    teacherName: "Les Claypool",
    parentName: "Christine Wells",
    parentEmail: "gpc@yahoo.co.uk"
  },
  {
    studentName: "Michael Scott",
    email: "mscott@dundermifflin.com",
    grade: "7",
    teacherName: "Aaron Gwin",
    parentName: "",
    parentEmail: ""
  },
  {
    studentName: "Michael Scarn",
    email: "mscott@tmmidnight.com",
    grade: "8",
    teacherName: "Todd Packer",
    parentName: "",
    parentEmail: ["mscott@tmmidnight.com", "marta@yahoo.es", "barbs@google.com"]
  },
  {
    studentName: "Michael Scarn",
    email: "mscott@tmmidnight.com",
    grade: "8",
    teacherName: "Baloo",
    parentName: "",
    parentEmail: ""
  },
  {
    studentName: "Michael Scarn",
    email: "mscott@tmmidnight.com",
    grade: "8",
    teacherName: "Maya",
    parentName: "",
    parentEmail: ""
  },
  {
    studentName: "Rosely Sánchez",
    email: "rosy@google.com",
    grade: "6",
    teacherName: "Frank Zappa",
    parentName: "María Sánchez",
    parentEmail: ["mds@google.com", "barbs@google.com"]
  },
  {
    studentName: "Carlos Torres",
    email: "carlos@sansa.com",
    grade: "7",
    teacherName: "Jack Torrence",
    parentName: "Marta Torres",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "Carlos Torres",
    email: "carlos@sansa.com",
    grade: "7",
    teacherName: "Jack Torrence",
    parentName: "Marta Torres",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "Andrew Bernard",
    email: "narddawg@cornell.edu.com",
    grade: "8",
    teacherName: "Frank Zappa",
    parentName: "Barbera Bernard",
    parentEmail: "barbs@google.com"
  },
  {
    studentName: "Kevin Malone",
    email: "kevin@dundermifflinfinity.com",
    grade: "7",
    teacherName: "Aaron Gwin",
    parentName: "Sue Malone",
    parentEmail: ["suzi@yahoo.es", "gpc@yahoo.co.uk"]
  },
  {
    studentName: "Kevin Malone",
    email: "kevin@dundermifflinfinity.com",
    grade: "7",
    teacherName: "David Jones",
    parentName: "Sue Malone",
    parentEmail: "suzi@yahoo.es"
  },
  {
    studentName: "Leslie Knope",
    email: "lezl@sabre.com",
    grade: "5",
    teacherName: "Jack Torrence",
    parentName: "Patricia Knope",
    parentEmail: "gpc@yahoo.co.uk"
  },  {
    studentName: "Craig Clydesdale",
    email: "craig@sabre.com",
    grade: "7",
    teacherName: "Les Claypool",
    parentName: "",
    parentEmail: ""
  },
  {
    studentName: "Lina López",
    email: "Lina@google.com",
    grade: "5",
    teacherName: "Frank Zappa",
    parentName: "Sandra López",
    parentEmail: "Sandra@google.com"
  },
  {
    studentName: "Carlos Blanco",
    email: "carlos@sansa.com",
    grade: "7",
    teacherName: "Jack Torrence",
    parentName: "Marta Blanco",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "Dave Clydesdale",
    email: "dave@gmail.com",
    grade: "6",
    teacherName: "Les Claypool",
    parentName: "Geraldine Clydesdale",
    parentEmail: "gpc@yahoo.co.uk"
  },
  {
    studentName: "Lina López",
    email: "Lina@google.com",
    grade: "5",
    teacherName: "Aaron Gwin",
    parentName: "Sandra López",
    parentEmail: "Sandra@google.com"
  },
  {
    studentName: "Kilgore Trout",
    email: "kilg@google.com",
    grade: "6",
    teacherName: "Frank Zappa",
    parentName: "Sandra Trout",
    parentEmail: "Sandra@google.com"
  },
  {
    studentName: "Danny Torrence",
    email: "Dani@sansa.com",
    grade: "5",
    teacherName: "Les Claypool",
    parentName: "Kathy Torrence",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "Margeret Simpson",
    email: "maggie@sabre.com",
    grade: "5",
    teacherName: "Jack Torrence",
    parentName: "Margerie Simpson",
    parentEmail: "margeandincharge@yahoo.co.uk"
  },
  {
    studentName: "Craig Clydesdale",
    email: "craig@sabre.com",
    grade: "7",
    teacherName: "Les Claypool",
    parentName: "Geraldine Clydesdale",
    parentEmail: "gpc@yahoo.co.uk"
  },
  {
    studentName: "Lina López",
    email: "Lina@google.com",
    grade: "5",
    teacherName: "Frank Zappa",
    parentName: "",
    parentEmail: ""
  },
  {
    studentName: "Carlos Blanco",
    email: "carlos@sansa.com",
    grade: "7",
    teacherName: "Jack Torrence",
    parentName: "Marta Blanco",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "Dave Clydesdale",
    email: "dave@gmail.com",
    grade: "6",
    teacherName: "Les Claypool",
    parentName: "Geraldine Clydesdale",
    parentEmail: "gpc@yahoo.co.uk"
  },
  {
    studentName: "Lina López",
    email: "Lina@google.com",
    grade: "5",
    teacherName: "Aaron Gwin",
    parentName: "Sandra López",
    parentEmail: "Sandra@google.com"
  },
  {
    studentName: "Carlos Guitierrez",
    email: "cgu@otmail.com",
    grade: "6",
    teacherName: "Jack Torrence",
    parentName: "Marta Guitierrez",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "James Wells",
    email: "Wella@hotmal.com",
    grade: "6",
    teacherName: "Les Claypool",
    parentName: "Christine Wells",
    parentEmail: "gpc@yahoo.co.uk"
  },
  {
    studentName: "Rosely Sánchez",
    email: "rosy@google.com",
    grade: "6",
    teacherName: "Frank Zappa",
    parentName: "",
    parentEmail: ""
  },
  {
    studentName: "Carlos Torres",
    email: "carlos@sansa.com",
    grade: "7",
    teacherName: "Jack Torrence",
    parentName: "Marta Torres",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "Michael Scott",
    email: "mscott@dundermifflin.com",
    grade: "7",
    teacherName: "Aaron Gwin",
    parentName: "Susanne Scott",
    parentEmail: "gpc@altavista.co.uk"
  },
  {
    studentName: "Andrew Bernard",
    email: "narddawg@cornell.edu.com",
    grade: "7",
    teacherName: "Frank Zappa",
    parentName: "Barbera Bernard",
    parentEmail: "barbs@google.com"
  },
  {
    studentName: "Kevin Malone",
    email: "kevin@dundermifflinfinity.com",
    grade: "7",
    teacherName: "Aaron Gwin",
    parentName: "Sue Malone",
    parentEmail: "suzi@yahoo.es"
  },
  {
    studentName: "Leslie Knope",
    email: "lezl@sabre.com",
    grade: "5",
    teacherName: "Jack Torrence",
    parentName: "",
    parentEmail: ""
  },

  {
    studentName: "Kilgore Trout",
    email: "kilg@google.com",
    grade: "6",
    teacherName: "Frank Zappa",
    parentName: "",
    parentEmail: ""
  },
  {
    studentName: "Danny Torrence",
    email: "Dani@sansa.com",
    grade: "5",
    teacherName: "Les Claypool",
    parentName: "Kathy Torrence",
    parentEmail: "marta@yahoo.es"
  },
  {
    studentName: "Margeret Simpson",
    email: "maggie@sabre.com",
    grade: "5",
    teacherName: "Jack Torrence",
    parentName: "Margerie Simpson",
    parentEmail: "margeandincharge@yahoo.co.uk"
  },
]