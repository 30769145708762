import React, { useState } from 'react';

import styles from './EntryModalSidebar.module.scss';
import SidebarWordbank from './SidebarWordbank/SidebarWordbank';
import SidebarGrading from './SidebarGrading/SidebarGrading';
import SidebarGrammarReport from './SidebarGrammarReport/SidebarGrammarReport';
import SidebarBreakoutRooms from './SidebarBreakoutRooms/SidebarBreakoutRooms';

const EntryModalSidebar = ({ activeSection, updateLocalGrade, entryData, activityId, sectionIndex, updateEntryKey, fjUser }) => {

  const [ selected, setSelected ] = useState('wordbank')
  

  return (
    <div className={styles.container}>
      <div className={styles.radiosBar}>

        <label className={fjUser && styles.wide}>
          <input checked={selected==='wordbank'} type="radio" name="selected"/>
          <div onClick={()=>setSelected('wordbank')} className={`${styles.radioDiv} ${styles.wordbank}`}>
            <div className={styles.icon} />
            <p>Instructions</p>
          </div>
        </label>
        
        <label className={fjUser && styles.wide}>
          <input checked={selected==='rooms'} type="radio" name="selected"/>
          <div onClick={()=>setSelected('rooms')} className={`${styles.radioDiv} ${styles.rooms}`}>
            <div className={styles.icon} />
            <p>Rooms</p>
          </div>
        </label>

        <label className={fjUser && styles.wide}>
          <input checked={selected==='grading'} type="radio" name="selected"/>
          <div onClick={()=>setSelected('grading')} className={`${styles.radioDiv} ${styles.grading}`}>
            <div className={styles.icon} />
            <p>Grading</p>
          </div>
        </label>

        {
          !fjUser &&
          <label className={fjUser && styles.wide}>
            <input checked={selected === 'grammar'} type="radio" name="selected" />
            <div onClick={() => setSelected('grammar')} className={`${styles.radioDiv} ${styles.grammar}`}>
              <div className={styles.icon} />
              <p>Grammar</p>
            </div>
          </label>
        }

      </div>

      <div className={styles.mainSwitch}>
        {

          selected === 'wordbank' ?

            <SidebarWordbank
              entryText={entryData.entryText}
              wordBank={activeSection.wordBank}
              instructions={activeSection.instructions}
              />

          :

            selected === 'grading' ?

              <SidebarGrading 
                updateEntryKey={updateEntryKey}
                sectionIndex={sectionIndex}
                studentId={entryData.studentId}
                activityId={activityId}
                gradingData={entryData.grading}
                updateLocalGrade={updateLocalGrade}/>
          :

            selected === 'grammar' ?

              <SidebarGrammarReport
                autoFeedbackCallsCount={entryData.autoFeedbackCallsCount}
                sectionIndex={sectionIndex}
                studentId={entryData.studentId}
                activityId={activityId}
                wordCount={entryData.wordCount}
                autoFeedback={entryData.autoFeedback}/>
          :

            selected === 'rooms' ?

              <SidebarBreakoutRooms
                writingRoom={entryData.writingRoom}
                entryIds={entryData.writingRoom.roomIds}
                sectionIndex={sectionIndex}
                activityId={activityId}
                studentId={entryData.studentId} />

          : null

        }
      </div>
    </div>
  )

}

export default EntryModalSidebar;