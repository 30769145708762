import React, { useState } from 'react';

import styles from './PeerReviewModal.module.scss';
import icon from '../../../../../../assets/sidebarIcons/sidebar_voting_blue.svg';
import { sendToApiReturn } from 'API/teacherCalls';
import { useSelector } from 'react-redux';
import { store } from 'storeConfig';
import { updateActivitySection } from 'Redux/actions';

const PeerReviewModal = ({activeSection, setActiveSection, setModal, mobile, activeSection: { voting, sectionIndex, votesCount, sectionPhase }}) => {

  const [ localBool, setLocalBool ] = useState(voting)
  const activityId = useSelector(state => state.activityData.activityId)
  const votingStarted = votesCount > 0;
  const isClosed = [7,8,10].indexOf(sectionPhase) != -1;

  const handleSave = async (input) => {
    setLocalBool(input)
    const res = await sendToApiReturn(`activity/${activityId}/update_unit/${sectionIndex}`, {...activeSection, voting:input})
    setActiveSection(res)
    store.dispatch(updateActivitySection(sectionIndex, res))
    // setModal(!mobile ? null : 'setup')
  }

  return (
    <div className={`${styles.container} ${mobile && styles.mobile}`}>

      <div className={styles.titleBar}>
        <img src={icon} alt="upload" />
        <h2>Peer Review</h2>
      </div>

      <div onClick={()=>handleSave(!localBool)} className={`${styles.sliderDiv} ${(votingStarted||isClosed) && styles.blocked}`}>
        <p className={!localBool && styles.show}>not active</p>
        <div className={`${styles.slider} ${localBool && styles.active}`}>
          <div className={`${styles.sliderInner} ${localBool && styles.active}`} />
        </div>
        <p className={localBool && styles.show}>active</p>
      </div>

      <div className={styles.textDiv}>
        {
          votingStarted ?
          <>
            <h3>Voting started</h3>
            <p>You are no longer able to deactivate voting on this activity as students have already cast votes.</p>
          </> 

          : isClosed ?

          <>
            <h3>Section Closed</h3>
            <p>Peer review can no longer be activated for this section as it is now closed.</p>
          </> 
        
          :
            <p>When writing ends, students review the anonymous submissions of their peers in small batches and vote for their favorites.</p>

        }
      </div>

      {/* <div className={`${styles.submitBtn} ${ localBool !== voting && styles.enabled}`}>
        <p onClick={handleSave}>Save</p>
      </div> */}

    </div>
  )
  

}

export default PeerReviewModal;