import React from 'react';
import { useHistory } from 'react-router';

import styles from './TeacherNotifications.module.scss';

const Notification_Submission = ({ item: { projectTitle, studentsAvatars, studentsNames, classId, projectId, sectionNumber }}) => {

  const history = useHistory();
  
  const handleNavigate = (classId, activityId, sectionIdx) => {
    history.push(`/teacher/classes/${classId}/${activityId}/responses/${sectionIdx-1}`)
  }

  return (
    <div className={styles.submissionContainer}>
      <div className={styles.titleBar}>
        <img src={studentsAvatars[0]} alt="Student image"/>
        <h4>New Submission</h4>
      </div>
      <p className={styles.submissionP}><strong>{studentsNames[0]}<span>{studentsNames.length > 1 && ` and ${studentsNames.length-1} more` }</span></strong> {studentsNames.length > 1 ? "have" : "has"} <span>submitted</span> a chapter to <span>{projectTitle}</span></p>

      <div className={styles.btns}>
        <div onClick={()=>handleNavigate(classId, projectId, sectionNumber)} className={styles.goToBtn}>Go to chapter</div>
      </div>
    </div>
  )

}

export default Notification_Submission;
