import { showModal } from 'helpers/uiFunctions';
import React from 'react';

import styles from './ClassActivityBlock.module.scss';

const ClassActivityDropdown = ({ idx, dropRef, archived, toggleDropdown, handleDeleteActivity, handleArchive, modalOverlay, activity, activity:{canBeDeleted, activityId} }) => {


  const handleDelete = () => {

    if(!canBeDeleted) return
    showModal(modalOverlay, { type:"remove", variant:'deleteActivity', func:()=>handleDeleteActivity(activityId, idx) })
  }


  return (
    <div onMouseLeave={() => toggleDropdown('remove')} ref={dropRef} className={`${styles.dropdown} ${archived && styles.archived}`}>

      <div onClick={()=>handleArchive(activity, toggleDropdown, idx, archived)} className={`${styles.dropRow} ${styles.archive}`}>
        <div className={styles.icon} />
        <p>{!archived ? 'Archive' : 'Unarchive'} activity</p>
      </div>

      <div onClick={handleDelete} className={`${styles.dropRow} ${styles.delete} ${!canBeDeleted && styles.disabled}`}>
        <div className={styles.icon} />
        <p>Delete activity</p>
      </div>
    </div>
  )

}

export default ClassActivityDropdown;