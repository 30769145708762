import { getFromApi } from 'API/teacherCalls';
import ClassActivityBlock from 'components/Teacher/ClassDetails/ClassActivityBlock/ClassActivityBlock';
import { showModal } from 'helpers/uiFunctions';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styles from './ChapterDetailsBottomSection.module.scss';

const ChapterDetailsBottomSection = ({ blockRef, bottomBlockSrolled, modalOverlay }) => {

  const[ classData, setClassData ] = useState(null)
  const { classId } = useParams()

  const fetchClassData = async () => setClassData( await getFromApi(`class/${classId}`))

  useEffect(()=>{

    fetchClassData()

  },[bottomBlockSrolled])

  return (
    <div ref={blockRef} className={styles.container}>
    <div className={styles.titleBar}>
      <h4>More activities</h4>
    </div>
      <div className={styles.main}>
        {
          !classData ?
            <div className={styles.loaderDiv}>
              <div className={styles.spinner} />
              <p>Fetching class data</p>
            </div>
          :
            
            classData.activities.map((item, idx) => 
              idx<3 &&
                <ClassActivityBlock
                  bordered
                  synod={false}
                  setListItem={()=>console.log("null")}
                  archived={false}
                  modalOverlay={modalOverlay}
                  key={idx}
                  idx={idx}
                  classData={classData}
                  setClassData={setClassData}
                  count={0}
                  activity={item}
                  handleArchive={()=>console.log("null")}
                  handleDeleteActivity={()=>console.log("null")} />
            )
            
        }
        <div onClick={()=> showModal(modalOverlay, {type:"activityList", classData}) } className={styles.viewAllBtn}>
          View all
        </div>
      </div>
    </div>
  )

}

export default ChapterDetailsBottomSection;