import React from 'react';

import styles from './Leaderboard.module.scss';

const Leaderboard = ({ winners, students }) => {

  const winner = students.find(i=>i.studentId === winners[0])
  const second = winners.length>1 ? students.find(i=>i.studentId === winners[1]) : null
  const third = winners.length>2 ? students.find(i=>i.studentId === winners[2]) : null

  return (
    <div className={styles.container}>

      <div className={styles.text}>
        <h1>There's a winner</h1>
        <h1>for this chapter</h1>
      </div>
      

      <div className={styles.winnerBlocks}>
        {second && second.studentName &&
          <div className={`${styles.box} ${styles.second}`}>
            <img className={styles.boomer} src={second.studentBoomerUrl} alt="2nd place boomer"/>
            {/* <div className={styles.boomer}></div> */}
            <h3>{second.studentName}</h3>
          </div>
        }

        {winner  &&
          <div className={`${styles.box} ${styles.winner} ${styles.pulse}`}>
            <div className={`${styles.after} ${styles.rotating}`}>
            </div>
            <div className={styles.confetti}></div>
            <img className={styles.boomer} src={winner.studentBoomerUrl} alt="winner boomer"/>
            <h3>{winner.studentName}</h3>
            <h3>Winner</h3>
          </div>
        }

        {third && third.studentName &&
          <div className={`${styles.box} ${styles.third}`}>
          <img className={styles.boomer} src={third.studentBoomerUrl} alt="3rd place boomer"/>
            <h3>{third.studentName}</h3>
          </div>
        }
      </div>
    </div>
  )

}

export default Leaderboard;