import { flattenArray } from 'helpers/flattenArray';
import { letters } from 'helpers/letters';
import React, { useEffect, useState } from 'react';
import tick from 'assets/tick_white.svg'

import styles from './BreakoutRoomsSetupModal.module.scss';

const StudentBlock = ({ handleSelect, unconfirmedRooms,confirmedRooms, student: { studentId, studentName, studentBoomerUrl }}) => {

  const [ roomLetter, setRoomLetter ] = useState(null)

  useEffect(()=>{
    if(flattenArray(unconfirmedRooms).includes(studentId)){
      const roomIndex = unconfirmedRooms.findIndex(i => i.includes(studentId))
      setRoomLetter(letters[roomIndex+confirmedRooms.length])

    } else {
      setRoomLetter(null)

    }
  },[unconfirmedRooms])


  return (
    <div 
      onClick={()=>handleSelect(studentId)} 
      className={`
        ${styles.studentBlock}
        ${ roomLetter && styles.selected}
      `}>

      <img className={styles.avatar} src={studentBoomerUrl} alt="avatar" />
      <h5>{studentName}</h5>

      <div className={`${styles.roomTab} ${roomLetter && styles.display} ${roomLetter && `group_${roomLetter} noHover`}`}>
        <h5>{roomLetter}</h5>
        <img src={tick} alt="tick" />
      </div>
    </div>
  )

}

export default StudentBlock;