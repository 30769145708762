import React, { useRef, useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import sent from 'assets/icon-sent--blue.svg';

import styles from './ShareTheNewsModal.module.scss';
import { getFromApi, sendToApi } from 'API/teacherCalls';
import { store } from 'storeConfig';
import StnEmailPreview from './StnEmailPreview';

const ShareTheNewsModal = ({closeBtn, item:{activityId, initClass, variant, isBee, bookCode}}) => {

  const emailInputRef = useRef();
  const [ invites, setInvites ] = useState([]);
  const [ emailInput, setEmailInput ] = useState({idx:0, text:""})
  const [ contState, setContState ] = useState(initClass)
  const [ ordered, setOrdered ] = useState(0)
  const [ vis, setVis ] = useState({copy:false, error:false, checkbox:false });
  const [ selectedMails, setSelectedMails ] = useState([]);
  const [students, setStudents ] = useState(useSelector(state => state.fetchedClass.students));
  const loginCode = store.getState().activityData.printCode;
  const [ emailText, setEmailText ] = useState(

    isBee ? 
    "Our class has been participating in The 2021 Writing Bee and working to complete an original story started by the Diary of a Wimpy Kid author, Jeff Kinney. When finished, this story will be published, and each family will be able to purchase a personalized copy of the book with their child’s name prominently displayed on the front cover and final chapter included as the alternate ending. The price per copy is $12.99 (US) plus shipping."
  :
    variant === 'story' ?
      'As you may know, the students have been using Escribli to write an original class story. Once completed, Escribli will publish our story so your child can have a personalized copy of the book with their name prominently displayed on the front cover and final chapter included as the alternate ending delivered directly to your home. The price is $12.99 (US) plus shipping.'
  :
      'As you may know, the students have been using Escribli to write journal entries. Once completed, Escribli will publish our journals so your child can have a personalized copy with their name prominently displayed on the front cover delivered directly to your home. The price is $12.99 (US) plus shipping.'
    )
    
  const regex = /.+@.+\..+/i;


  useEffect(()=>{
    const callAPI = async()=>{ 
      setStudents(await getFromApi(`activity/${activityId}/print_orders`))
      
    };
    callAPI()
  },[])



  useEffect(()=>{
    let count = 0;

    if(students&&students.length){
      students.forEach(s =>{
        if(s.bookOrders&&s.bookOrders>0){count++}
      })
    }
    setOrdered( count )
    // eslint-disable-next-line
  },[students])


  const handleSend = () => {
    const payload = selectedMails.filter(i => i !== "noMail");
    setContState('sent')
    sendToApi(`activity/${activityId}/send_ad_email`, {emails:payload, invites:invites, message:emailText})
  }

  const handleSave = () => {

    if(emailInputRef.current.value === "" || !regex.test(emailInputRef.current.value)){
      setVis({...vis, error:true})
      emailInputRef.current.classList.add('shake');
      setTimeout(()=>{emailInputRef.current.classList.remove('shake')},1000)

    } else {
      let student = students[emailInput.idx];
      student.parentEmail.unshift(emailInput.text);
      let stateCopy = [...students];
      let selectedCopy = [...selectedMails];
      selectedCopy[emailInput.idx]=emailInput.text;
      stateCopy[students.findIndex(s=>s.studentId===student.studentId)] = student;
      setSelectedMails(selectedCopy)
      setContState('main');
      setStudents(stateCopy)

      if(vis.checkbox){
        const newItem = {email:emailInput.text, studentId:student.studentId};
        setInvites([...invites, newItem]);
        setVis({...vis, checkbox:false})
      }
    }
  }


  useEffect(()=>{
    let arr = [];
    let visIdx={};

    if(students&&students!=='initial'&&students.length){
      students.forEach((s, idx)=>{ 
        s.parentEmail.length ? arr.push(s.parentEmail[0]) : arr.push('noMail');
        visIdx[idx]=false;
       })
      setSelectedMails(arr);
      setVis({...vis, ...visIdx})
    }
    
    // eslint-disable-next-line
  },[students])



  return (
    <div className={`${styles.container} ${styles[contState]}`}>
      {contState === 'main' ? 
        <>
          <h1>Share the news</h1>

          <div className={styles.yellowBox}>
            <p>
            We have provided the template in the right sidebar for you to share with families. Before sending out the good news, you can update the parents email list. By clicking on ’Send email to parents’, Escribli will send the email to the selected parents. You can also invite them to register in our app for free.
            </p>
          </div>

          <div className={styles.main}>

            <div className={styles.left}>
            <div className={styles.tableContainer}>
                <div className={styles.tableTitle}>
                  <h3>Parent/guardian email list</h3>
                </div>
                <div className={styles.tableHeader}>
                  <div className={styles.column}></div>
                  <div className={`${styles.column} ${styles.left}`}><h6> STUDENT NAME </h6></div>
                  <div className={styles.column}><h6> PARENT EMAIL </h6></div>
                  <div className={styles.column}><h6> BOOK ORDERS </h6></div>
                </div>
                <div className={styles.tableBody}>
                  { students && students.length && students!=='initial'?

                      students.map((s, idx) => {
                        return (
                          <div key={idx} className={styles.tableRow}>
                            <div className={styles.column}><img src={s.boomerUrl} alt="boomer" /></div>
                            <div className={styles.column}><p>{s.studentName}</p></div>
                            <div className={styles.column}>
      
                              {!s.parentEmail.length ?
      
                                <div onClick={() => {
                                  setContState('sml');
                                  setEmailInput({ ...emailInput, idx: idx });
                                }} className={styles.noMail} >
                                  <h5>Add parent email</h5>
                                </div>
                                :
      
                                <div className={s.parentEmail.length === 1 ? styles.singlePill : s.parentEmail.length > 1 ? styles.multipleMail : styles.noMail }
                                  onClick={() => { setVis({ ...vis, [idx]: true }) }}
                                  onMouseLeave={() => { setVis({ ...vis, [idx]: false }) }} >
      
                                  <h5>{selectedMails[idx]?selectedMails[idx]:'select email'}</h5>
      
      
                                  {vis[idx] ?
                                    <div className={styles.emailsDropdown}>
                                      {s.parentEmail.map((item, index) => {
                                        return (
                                          <div className={styles.singleMail} onClick={(e) => {
                                            let stateCopy = { ...selectedMails };
                                            stateCopy[idx] = item;
                                            setSelectedMails(stateCopy);
                                            setTimeout(() => { setVis({ ...vis, [idx]: false }) }, 50);
                                          }}>
                                            <h5>{item}</h5>
                                          </div>
                                        )
                                      })
                                      }
                                      <div onClick={() => {
                                          setContState('sml');
                                        setEmailInput({ ...emailInput, idx: idx });
                                      }} className={`${styles.noMail} ${styles.darkText}`} > <h5 >Add parent email</h5> </div>
                                    </div>
                                    : null}
      
                                </div>
                              }
                            </div>
                            <div className={styles.column}><p>{s.bookOrders}</p></div>
                          </div>
                        )
                      })

                  : 

                      <p style={{width:"100%", textAlign:"center"}}>no students found</p>
                  }
                  
                </div>

              </div>
              {/* <h3>Hello Families,</h3>
              <p>As you may know, the students have been using Escribli to write an original class story. Once completed, Escribli will publish our story so your child can have a personalized copy of the book with their name prominently displayed on the front cover and final chapter included as the alternate ending delivered directly to your home. The price is $12.99 (US) plus shipping. </p>
              <p>The Escribli Bookstore is now accepting orders for our class story using the store’s link and our class story’s unique code:</p>
              <p className={styles.link}>store.Escribli.com</p>
              <p>and enter this code:</p>
              <div onClick={(e)=>{handleCopy(e)}} className={styles.codeDiv}>
                {!vis.copy ? loginCode : <p>copied</p>}
                <div className={styles.tooltip}>Copy</div>
              </div>
              <div className={styles.bookImg}></div> */}

            </div>

            <div className={styles.right}>
              <div className={styles.summary}>
                <div className={styles.summaryRow}>
                  <h5>{variant === 'story' ? "BOOK" : "JOURNAL"} ORDER SUMMARY</h5>
                </div>
                <div className={styles.summaryRow}>
                  <h5>Ordered</h5>
                  <h5>{ordered}</h5>
                </div>
                <div className={styles.summaryRow}>
                  <h5>Did not order</h5>
                  <h5>{students ? students.length-ordered : 0}</h5>
                </div>
              </div>

              <div className={styles.preview}>
                <div className={styles.prevBtn} onClick={()=>setContState('prev')}>Preview Parent Email</div>
                <div className={styles.overlay}></div>
                <div className={styles.prevText}>
                  As you may know, the students have been using Escribli to write an original class story. Once completed, Escribli will publish our story so your child can have a personalized copy of the book with their name prominently displayed on the front cover and final chapter included as the alternate ending delivered directly to your home. The price is $12.99.
                </div>
              </div>

              <div onClick={handleSend} className={ selectedMails.some(i => i!=='noMail') ? styles.btn : `${styles.btn} ${styles.disabled}`}>Send email to parents</div>
            </div>

          </div>

        </>

      : contState === 'sml' ? 

          <div className={styles.addMailModal}>

            <h3>Enter parent/guardian emails below</h3>

            <input onChange={(e)=>{
              setEmailInput({...emailInput, text:e.target.value}); 
              setVis({...vis, error:false})
            }} 
            type="email" placeholder="email@email.com" name="emailInput" ref={emailInputRef}/>


            <div className={styles.checkDiv}>
              {emailInputRef.current && regex.test(emailInputRef.current.value) ? <div className={styles.circleTick}></div> : null}
              {vis.error ? <p className={styles.errorMsg}>Please enter a valid email address</p> : null}
              <input onChange={(e)=>{setVis({...vis, checkbox:e.target.checked})}} value="invite" type="checkbox" name='invite' id='inviteCheck' data-check="invite"/> 
              <p>
              Invite parents/guardians to register for free and be 'in the loop' with their child's Escribli activities’ titles and deadlines.
              </p>
            </div>

            <div onClick={()=>{handleSave()}} className={styles.btn}>Save</div>

            <h4 onClick={()=>{setContState('main'); setEmailInput({...emailInput, text:""})}}>{"< back to list"}</h4>

          </div>

        : contState === 'prev' ? 

          <div className={styles.previewDiv}>
            <StnEmailPreview
              bookCode={bookCode} 
              isBee={isBee}
              setContState={setContState} 
              loginCode={loginCode} 
              emailText={emailText}
              setEmailText={setEmailText}
              variant={variant}
            />
          </div>            
        

        :

        <div className={styles.sentModal}>
          <img src={sent} alt="sent!" />
          <h1>Emails sent!</h1>
          <div onClick={()=>{closeBtn.current.click()}} className={styles.closeBtn}>Close</div>
        </div>
      }
    </div>

  )

}

export default ShareTheNewsModal;