import React, { useState, useRef, useEffect } from 'react';
import styles from './PreviewModal.module.scss'
import calendar from "../../../../assets/modalContent/calendar.png";
import task from "../../../../assets/modalContent/task.png";
import grade from "../../../../assets/modalContent/grade.png";
import wordBankIcon from "../../../../assets/modalContent/wordBank.png";
import imageUploadIcon from "../../../../assets/modalContent/imageUpload.png";

import Thumbnail from './Thumbnail'

import Carousel from 'react-elastic-carousel';
import { dateNumericVar } from '../../../../helpers/dateFunctions.js'

const PreviewModal = ({ item: { images, item: { modalOverlay, activeSection, activeSection: { instructions, maxWords, wordBank, sectionDue, activityGradingType, grading } } } }) => {
    const [slide, setSlide] = useState(1)
    const carousel = useRef()
    const textAreaRef = useRef()
    const totalSlides = Math.ceil(images.length / 2)
    const handleClick = (next) => {
        if (next) {
            carousel.current.slideNext()
            totalSlides > slide && setSlide(slide + 1)
        } else {
            carousel.current.slidePrev()
            totalSlides === slide && setSlide(slide - 1)

        }
    }

    useEffect(()=>{ //resize text area upon load based on the instructions length
        if(textAreaRef.current){
            textAreaRef.current.style.height = "";textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px"
        }
    },[textAreaRef.current])
    return (
        <div className={styles.previewModal}>


            <div className={styles.cardHeader}>What your students see!</div>

            <div className={styles.infoContainer}>

                <div className={styles.imgWrapper}>
                    <img src={calendar} alt='' />
                </div>
                <div>
                    <h3>Writing Deadline</h3>
                    <p>{dateNumericVar(sectionDue)}</p>
                </div>
            </div>

            {instructions && <div className={styles.infoContainer}>
                <div className={styles.imgWrapper}>
                    <img src={task} alt='' />
                </div>
                <div style={{width:'100%', height:'fit-content'}}>
                    <h3>Instructions</h3>
                    <textarea readOnly ref={textAreaRef}>{instructions}</textarea>
                </div>
            </div>
            }

            {grading ? <div className={styles.infoContainer}>
                <div className={styles.imgWrapper}>
                    <img src={grade} alt='' />
                </div>
                <div>
                    <h3>Grading</h3>
                    <p>Your work will be graded against the following rubric:</p>
                    <div className={styles.rubric}>
                        <p className={styles.rubricName}>{activityGradingType}</p>
                        <p>View</p>
                    </div>
                </div>
            </div>
            :null}

            {wordBank.length > 0 ?
                <div className={styles.infoContainer}>
                    <div className={styles.imgWrapper}>
                        <img src={wordBankIcon} alt='' />
                    </div>
                    <div>
                        <h3>Word Bank:</h3>
                        <div className={styles.wordContainer}>{wordBank.map((elm) => <div className={styles.word}>{elm}</div>)}</div>
                        <h3>Vocab used:<span>0/{wordBank.length}</span></h3>

                    </div>
                </div>
            :null}

            {images.length > 0 ?
                <div className={styles.infoContainer}>
                    <div className={`${styles.imgWrapper} ${styles.up}`}>
                        <img src={imageUploadIcon} alt='' />
                    </div>
                    <div className={styles.imageUpload}>
                        <h3>Image Upload:</h3>
                        <div className={styles.carouselWrapper}>

                            <Carousel itemsToShow={2} showArrows={false} pagination={false} itemsToScroll={2} ref={carousel}>
                                {images.map((elm, idx) => <Thumbnail img={elm} slide={idx} key={idx} activeSection={activeSection} modalOverlay={modalOverlay} images={images}></Thumbnail>)}
                            </Carousel>


                            <div className={styles.buttons}>

                                {images.length > 2 && <div className={`${styles.btn} ${styles.left}`} onClick={() => handleClick()} />}
                                <div className={styles.totalSlides}> page {slide} / {totalSlides}</div>
                                {images.length > 2 && <div className={styles.btn} onClick={() => handleClick(true)} />}
                            </div>


                        </div>

                    </div>
                </div>
            :null}





        </div>
    );
}

export default PreviewModal;