import React, { useRef, useEffect, useState } from 'react';
import Notification_Student from './Notification_Student';
import Notification_Teacher from './Notification_Teacher';
import bellImg from 'assets/icon-notifications.svg'
import styles from './TeacherNotifications.module.scss';
import { getFromApi } from 'API/teacherCalls';
import loader from 'assets/spinner-white-sm.svg';
import partyBoomers from 'assets/boomers_bookParty.svg'
import Notification_party_deadline from './Notification_party_deadline';
import Notification_Submission from './Notification_Submission';

const TeacherNotifications = () => {

  const contRef = useRef();
  const [ apiRes, setApiRes ] = useState('init')
  const callApi = async () => { setApiRes( await getFromApi(`notifications`)) }

  useEffect(()=>{
    setTimeout(()=>{ callApi() },400)
  },[])

  useEffect(()=>{
    if(apiRes!=='init'){contRef.current.classList.add(styles.open)}
  },[apiRes])

  return (
    <div ref={contRef} className={styles.container}>
      <div className={styles.title}>
        <img src={bellImg} alt="bell"/>
        <h2>What's new</h2>
      </div>
      <div className={styles.inner}>
        {
          //loader whilst calling backend
          apiRes === 'init' ?  

            <div className={styles.loaderDiv}>
              <img src={loader} alt="spinner"/>
            </div>

          // response received, notifications present
        : apiRes && apiRes.length ? 
          <>
            { apiRes.map((item, idx) => {
              return(
                item.type==='teacherRequest' ? 
                  <Notification_Teacher teacherName={item.name} classTitle={item.classTitle} inviteId={item.inviteId} classId={item.classId} setApiRes={setApiRes} key={idx}/> 
                :
                item.type==='studentRequest' ? 
                  <Notification_Student studentName={item.name} inviteId={item.inviteId} setApiRes={setApiRes} key={idx}/> 
                :
                item.type==='newSubmissions' ? 
                  <Notification_Submission item={item} /> 
                :
                item.type==='5_days'||item.type==='1_day' ? 
                  <Notification_party_deadline classId={item.classId} activityId={item.projectId} imgSrc={partyBoomers} type={item.type}/> 
                : 
                null
                )
              })
            }
            {/* <Notification_Feature featureName={"Grammar report"} imgSrc={grammarImg} linkRoute={"classes"} /> */}
          </>

          // no backend notifications, some local ad notificationa
        // : apiRes && !apiRes.length && adCount && !fjUser ?

        //     <Notification_Feature 
        //       featureName={"New Feature Alert!"} 
        //       imgSrc={coinImg} 
        //       linkRoute={"/credit"}
        //       bodyText={"Check out 'Refer and Earn' to learn how to earn and spend credits on subscriptions and books!"} />

        : // no local or backend notifications

            <div className={styles.loaderDiv}>
              <h4>No new notifications</h4>
            </div>  

        }
  
      </div>

    </div>
  )

}

export default TeacherNotifications;
