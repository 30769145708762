import React, { useState, useEffect } from 'react';
import { getFromApi } from 'API/adminCalls';
import styles from './ManageTeachers.module.scss';
import { testTeacher } from '../../../../helpers/filterFunctions';
import { showModal } from 'helpers/uiFunctions';
import spinner from 'assets/Spinner.svg'
import TeacherRow from './TeacherRow';

const ManageTeachers = ( { modalOverlay } ) => {

  const [info, setInfo] = useState('');
  const [ gradeFilt, setGradeFilt ] = useState('all');
  const [ selectedTeachers, setSelectedTeachers ] = useState([])
  const [ teachers, setTeachers ] = useState('init');
  const [ gradeArray, setGradeArray ] = useState([])

  const updateSelected = () => {
    const checked = Array.from(document.querySelectorAll('input[type="checkbox"]:checked'));
    setSelectedTeachers([...new Set(checked.map(i => i.id))])
  }


  useEffect(()=>{
    //generate grades array based on grades within teacher objects
    if(teachers.length&&teachers!=='init'){
      const grades = [];
      teachers.forEach(teacher =>{
        teacher.grades.forEach(grade => grades.push(grade))
      })
      setGradeArray([...new Set(grades)]);
      
    }
    console.log(gradeArray)
  },[teachers])

  useEffect(()=>{
    const callApi = async () => { setTeachers( await getFromApi('school_teachers'))};
    callApi();
    
  },[])



  return (
    <div className={styles.container}>

      <div className={styles.inner}>
        <div className={styles.top}>
          <input onChange={(e) => setInfo(e.target.value)}
            type="text"
            name="filterTeachers"
            id="filterTeachers"
            placeholder="Search Teachers"
          />
          <div className={styles.addTeacher} onClick={()=>{showModal(modalOverlay, {type:'addTeacher'})}} >
            <h1>+</h1>
            <h3>Add Teacher</h3>
          </div>
          <div className={styles.addTeacherExisting} onClick={()=>{showModal(modalOverlay, {type:'addExistingTeacher'})}} >
            <h1>+</h1>
            <h3>Add Existing Teacher</h3>
          </div>
          <div onClick={()=>{
            if(selectedTeachers.length){showModal(modalOverlay, {type:'remove', variant:'removeTeacher', teachers:selectedTeachers})}
            }} 
            className={styles.delete}>
            <div className={styles.delTooltip}>Remove selection</div>
          </div>
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.photo}>
          </div>
          <div className={`${styles.name} hideMobile`}>
            <h5>Full Name</h5>
          </div>
          <div className={`${styles.email} hideMobile`}>
            <h5>Email</h5>
          </div>
          <div className={`${styles.grade} hideMobile`}>
            <h5>Grades</h5>
          </div>
          <div className={`${styles.lastLogin} hideMobile`}>
            <h5>Last Login</h5>
          </div>
          <div className={styles.deleteCol}>
          </div>
        </div>
        <div className={styles.tableRows} onChange={updateSelected}>
          
          {
              teachers === 'init' ? 

                  <div className={styles.loaderDiv}>
                    <img className={styles.loader} src={spinner} alt="spinner"/>
                  </div> 

          :
            
            !teachers.length ?

              <h5>No teachers found</h5>
          
            
          :   teachers.length &&

                teachers.map((teacher, index) => {
                  if( testTeacher(teacher, info, gradeFilt) )
                      {return <div  key={index}> <TeacherRow teacher={teacher} modalOverlay={modalOverlay} /> </div>}
                    else { return '-' }
                }) 
          }

        </div>
      </div>
    
      <div className={styles.filterBtns} onChange={(e) =>setGradeFilt(e.target.value)}>
          <label>
            <input type="radio" name="teacherFil" id="all" value="all" defaultChecked/>
            <div className={styles.filterBtn}>All Grades</div>
          </label>
        {
          gradeArray.sort((a,b)=>a-b).map((item, idx)=>{
            return(
              <label key={idx} >
                <input type="radio" name="teacherFil" id="all" value={item}/>
                <div className={styles.filterBtn}>Grade {item}</div>
              </label>
            )
          })
        }
      </div>
    </div>
  )
}

export default ManageTeachers;


// gradeArray.sort((a,b)=>parseInt(a.slice(6))-parseInt(b.slice(6))).map((item, idx)=>{