import React from 'react';

import styles from './FinalSectionBookModal.module.scss';
import story_cover from 'assets/cover_story.png';
import journal_cover from 'assets/cover_journal.png';


const FinalSectionBookModal = ({ closeBtn, item, item: {activity}, hideModal }) => {

  const { allowExtraChapters, activityType } = activity

  const handleNew = () => {
    
    closeBtn.current.click()
    setTimeout(()=>{
      document.getElementById('createNewSection').click();
    },300)

  }

  const infoText = activityType==='story'? 
    'Congratulations on completing the final chapter of your story!' 
  :
    'Congratulations on completing the final section of your journal!' 

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={activityType==='story'?story_cover:journal_cover} alt="book"/>
      < h1>Final {activityType==='story'? 'chapter' : 'section'}</h1>
      </div>
      <div className={styles.warning}>
        <p>{infoText}</p>
      </div>
      
      <div className={styles.buttons}>

       {
          allowExtraChapters&&  
            <div onClick={handleNew} className={styles.startVoting}>Create new {activityType==='story'? 'chapter' : 'section'}</div>
        }
        
      </div>
    </div>
  )

}

export default FinalSectionBookModal;
