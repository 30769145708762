
import { StyleSheet } from "@react-pdf/renderer";

export const coverStyles = (cover_height, cover_width) => {
  
  return StyleSheet.create({
  
    absoluteFullpage: {
      position:"absolute", 
      left:0, 
      top:0, 
      width:cover_width, 
      height:cover_height
    },
    pageBack: {
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      position:"absolute", 
      height: cover_height,
      width: cover_width/2,
      top:0,
      left:0
    },
    pageFront: {
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"space-evenly",
      position:"absolute", 
      height: cover_height,
      width: cover_width/2,
      left: cover_width/2,
      top:0,
    },
    pageFrontPadding: {
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"space-evenly",
      position:"absolute", 
      height: cover_height,
      width: cover_width/2,
      left: cover_width/2,
      top:0,
      paddingLeft: 100
    },
    noActivity: {
      width:"100%", 
      height:"100%", 
      display:"flex", 
      justifyContent:"center", 
      alignItems:"center"
    },
  
    //<------- STORY COVER STYLES
    story_title_text: {
      fontFamily: "riffic",
      color:"#ffffff",
      fontSize:42,
      width:"85%",
      marginTop:110,
      marginHorizontal:"auto",
      textAlign:"center",
      height: 220
    },
    story_title_text_short: {
      fontFamily: "riffic",
      color:"#ffffff",
      fontSize:80,
      width:"85%",
      marginTop:110,
      marginHorizontal:"auto",
      textAlign:"center",
      height: 220
    },
    story_title_text_long: {
      fontFamily: "riffic",
      color:"#ffffff",
      fontSize:26,
      // width:"230",
      width:"240",
      marginTop:130,
      marginHorizontal:"auto",
      textAlign:"center",
      height: 200,
      display:"flex",
      alignItems:"center"
    },
    story_boomer: {
      height: 350,
      position: "relative",
      bottom: 135,
      objectFit: "contain"
    },
    story_author: {
      width:"85%",
      height: 150,
      margin:"35 auto 0 auto",
      display:"flex",
      flexDirection:"column",
      alignItems:"center"
    },
    story_author_text: {
      color: "#ffffff",
      fontSize: 20,
      bottom:120,
      position: "relative"
    },
    story_codes: {
      height: 40,
      width: 150,
      display:"flex",
      flexDirection:"column",
      alignItems: "center",
      position: "absolute",
      bottom: 15
    },
    story_code_text: {
      color: "#ffffff",
      fontSize: 14,
      marginBottom:3
    },
  
    //<------- JOURNAL COVER STYLES
    journal_boomer: {
      height: 200,
      height: 200,
      position: "relative",
      bottom: 20,
      left:10,
      marginTop:200,
      transform:"rotate(10deg)",
      objectFit: "contain"
    },
    journal_details: {
      width:"75%",
      margin: "40 auto 0 auto",
      height: 150,
      display:"flex",
      flexDirection:"column",
      alignItems: "center",
    },
    journal_heading_text: {
      fontFamily: "Kurph",
      color:"#333333",
      fontSize: 25
    },
    journal_title_text: {
      fontFamily: "Kurph",
      color:"#333333",
      fontSize:40,
      textAlign:"center",
      marginBottom:15,
      padding:0
    },
    by_text: {
      fontFamily: "Kurph",
      color:"#333333",
      fontSize:20
    },
    journal_codes: {
      height: 40,
      width: 150,
      display:"flex",
      flexDirection:"column",
      alignItems: "center",
      position: "absolute",
      bottom: 65,
      left: 95
    },
    journal_code_text: {
      fontFamily: "Kurph",
      color: "#333333",
      fontSize: 17,
      marginBottom:3
    },

    //<------- BEE COVER STYLES
    bee_author_text: {
      fontSize:30,
      position:"absolute",
      bottom: 50,
      width:"80%",
      left:"10%",
      color:"#000000",
      textAlign: "center",
      textTransform:"uppercase",
      // fontWeight:700,
      fontFamily:"Oswald",
      // borderWidth:1,
      // borderColor: "red"
    },
    bee_author_text2: {
      fontSize:26,
      position:"absolute",
      bottom: 70,
      width:"80%",
      left:"10%",
      color:"#000000",
      textAlign: "center",
      textTransform:"uppercase",
      fontFamily:"Nexa"
    },
    bee_codes: {
      height: 30,
      width: "50%",
      display:"flex",
      flexDirection:"column",
      alignItems: "center",
      position: "absolute",
      bottom: 25,
      left: "25%"
    },
    bee_code_text: {
      color: "#ffffff",
      fontSize: 16,
      marginBottom:3
    },
    bee_code_text2: {
      color: "#ffffff",
      fontSize: 10,
      marginBottom:1,
      position:"relative",
      top:13
    },


    //<------- FAITH JOURNALS COVER STYLES
    faith_by_text: {
      fontFamily: "Josefin",
      color:"#333333",
      fontSize:20,
      color:"#D2B07E",
      marginTop:5
    },
    faith_by_text_black: {
      fontFamily: "Josefin",
      fontSize:20,
      color:"#333333",
      marginTop:5
    },
    pageFrontPaddingTwo: {
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"space-evenly",
      position:"absolute", 
      height: cover_height,
      width: cover_width/2,
      left: cover_width/2,
      top:0,
      paddingLeft: 110
    },
    faith_title_text: {
      fontFamily: "Aldi",
      color:"#ffffff",
      fontSize:25,
      width:"85%",
      marginTop:70,
      borderRadius:1,
      borderColor:"red",
      marginHorizontal:"auto",
      textAlign:"center",
      height: 180,
      color:"#D2B07E"
    },
    faith_title_text_black: {
      fontFamily: "Aldi",
      fontSize:24,
      width:"85%",
      marginTop:70,
      borderRadius:1,
      borderColor:"red",
      marginHorizontal:"auto",
      textAlign:"center",
      color:"#333333",
      height: 180
    },
    faith_author_text: {
      color: "#ffffff",
      fontSize: 25,
      marginBottom:3,
      marginTop:10,
      color:"#D2B07E",
      fontFamily: "Josefin"
    },
    faith_author_text_black: {
      fontSize: 25,
      marginBottom:3,
      marginTop:10,
      color: "#333333",
      fontFamily: "Josefin"
    },
    sponsor_text: {
      fontFamily: "Josefin",
      color:"#333333",
      fontSize: 12,
      position:"relative",
      top:20,
      right:55
    },
    faith_heading_text: {
      fontFamily: "Josefin",
      color:"#333333",
      fontSize: 22,
      marginTop:220,
      color:"#D2B07E"
    },
    faith_heading_text_black: {
      fontFamily: "Josefin",
      fontSize: 22,
      marginTop:220,
      color:"#333333"
    },
    faith_boomer: {
      height: 200,
      height: 200,
      position: "relative",
      bottom: 150,
      left:-50,
      transform:"rotate(10deg)",
      objectFit: "contain"
    },
  })

}