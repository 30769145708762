import React, { useRef } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import styles from './Teacher.module.scss';
import {
  TeacherDashboard,
  Resources,
  ActivateAccountPopper, 
  ChatModal,
  ProfileSettings} from '../../components';
import { useSelector } from 'react-redux';
import PrintList from 'components/StructuralComps/PrintList/PrintList';
import ActivityDetails from './ActivityDetails/ActivityDetails';
import Pricing from './Pricing/Pricing';
import AppBanner from 'components/UtilityComps/AppBanner/AppBanner';
import ClassDetails from 'components/Teacher/ClassDetails/ClassDetails';
import NewActivityModal from './NewActivityModal/NewActivityModal';
import StudentProfile from './StudentProfile/StudentProfile';
import NavMobile from 'components/Nav/NavMobile/NavMobile';
import Subscription from 'components/Subscription/Subscription';
import Dashboard from 'components/Admin/Dashboard/Dashboard';
import Graphs from 'components/Graphs/Graphs';
import { useUserType } from 'context/AltPlatformProvider';
import ManageSchool from 'components/Admin/ManageSchool/Manage';
import Manage from 'components/Admin/Manage/Manage';
import Tutorials from './Tutorials/Tutorials';
import SubscriptionDetails from 'components/Subscription/SubscriptionDetails';
import RandomAccess from './RandomAccess/RandomAccess';

const Teacher = ({ modalOverlay }) => {

  const teacherMail = useSelector(state => state.dashData.email)
  const addCardNeeded = useSelector(state => state.dashData.addCardNeeded)
  const accountConfirmed = useSelector(state => state.dashData.accountConfirmed)
  const history = useHistory()
  const newActivityCont = useRef()
  let someRef = useRef(false);
  const { fjUser } = useUserType() 


  return (

      <div className={`${styles.container} ${(!fjUser && addCardNeeded) && styles.bannerPadding}`}>

        <NavMobile modalOverlay={modalOverlay} fjUser={fjUser} />

        {
          !fjUser && addCardNeeded &&

          <AppBanner
            bannerText={"Your trial is ending, please fill out your credit card details to continue to use Escribli"}
            buttonText={"Add credit card"}
            bannerStyle={"blue"}
            buttonStyle={"orange"}
            buttonFunction={() => history.push('/teacher/account/manage?addCard=true')} />

        }


        <Switch>

          <Route path={["/teacher", "/teacher/classes"]} exact component={() => (
            <TeacherDashboard modalOverlay={modalOverlay} fjUser={fjUser} />)} />

          <Route path={"/teacher/admin/dashboard"} exact component={() => (
            <Dashboard modalOverlay={modalOverlay} fjUser={fjUser} />)} />

          <Route path={"/teacher/admin/manage"} exact component={() => (
            <ManageSchool modalOverlay={modalOverlay} fjUser={fjUser} />)} />

          <Route exact path={"/teacher/admin/reports"} component={()=> (
            <Graphs />)} /> 

          <Route path='/teacher/classes/view/:classId' exact component={() => (
            <ClassDetails
              toggleNewActivityVis={() => newActivityCont.current?.classList.toggle(styles.show)}
              modalOverlay={modalOverlay}
              fjUser={fjUser} />
          )} />

          <Route path='/teacher/tutorials' exact component={() => (
            <Tutorials modalOverlay={modalOverlay} />)} />

          <Route path='/teacher/classes/:classId/:activityId/print' exact component={() => (
            <PrintList modalOverlay={modalOverlay} />)} />

          <Route path='/teacher/classes/:classId/:activityId/:tab?/:sectionIndex?' component={() => (
            <ActivityDetails modalOverlay={modalOverlay}
              fjUser={fjUser} />
          )} />

          <Route path='/teacher/viewstudent/:classId/:studentId/:tab' exact component={() => (
            <StudentProfile modalOverlay={modalOverlay} />)} />


          <Route path='/teacher/resources/:activityIdParam?' component={() => (
            <Resources fjUser={fjUser} modalOverlay={modalOverlay} />)} />

            <Route
                  path={"/teacher/preview/:activityIdParam"}
                  component={() => (
                    <RandomAccess modalOverlay={modalOverlay} />
                  )}
                />
          <Route path='/teacher/account/subscription' component={() => (
            <Pricing modalOverlay={modalOverlay} />)} />

          <Route path='/teacher/account/manage/:isAdmin?' component={() => (
            <Subscription isTeacher modalOverlay={modalOverlay} />)} />

            <Route path='/teacher/account/subscriptiondetails' component={() => (
              <SubscriptionDetails isTeacher modalOverlay={modalOverlay} />)} />
          
          <Route path='/teacher/account/:tab' component={() => (
            <ProfileSettings
              isTeacher
              modalOverlay={modalOverlay} />)} />

          <Route path='/teacher/chat' component={() => (
            <ChatModal standalone />)} />


        </Switch>

        <NewActivityModal
          fjUser={fjUser}
          bannerPadding={!fjUser && addCardNeeded}
          closeModal={() => { newActivityCont.current.classList.remove(styles.show); someRef.current = true }}
          newActivityCont={newActivityCont} />

        {!fjUser && teacherMail && !accountConfirmed && < ActivateAccountPopper modalOverlay={modalOverlay} email={teacherMail} />}
      </div>

  )
}

export default Teacher;