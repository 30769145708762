import React from 'react'
import styles from './ClassReports.module.scss'
import { useRef, useState, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';


import { filterArrayByObject, normalize } from 'helpers/filterFunctions.js';

import ClassStudentRow from '../ClassStudents/ClassStudentRow.jsx';
import { showModal } from 'helpers/uiFunctions';
import { LoadingOverlay } from 'components';
import ClassStudentReport from '../ClassStudents/ClassStudentReport';

const ClassReports =  ({ autoTrigger, modalOverlay, classData, setClassData, students, setStudents, synod }) => {
    const [ showAddModal, toggleAddModal ] = useState(false)
    const [filterVals, setFilterVals] = useState({ text: '', radio: 'all' });
    const { classId } = useParams();
    const modalRef = useRef()
    const searchRef  = useRef()
    const inputRef = useRef()
  
    const toggleInnerModal = action =>{
      toggleAddModal(!showAddModal)
      if (classData.onFreePlan && classData.allStudentsCount >= 5) {
        showModal(modalOverlay, {type:'upgradePlan'})
      } else {
        if(!action){ modalRef.current.classList.toggle(styles.display)}
        else {modalRef.current.classList[action](styles.display)}
      }
    }
  
    useLayoutEffect(()=>{
      if(autoTrigger) toggleInnerModal()
    },[])

    // console.log("students",students)
  return (
    <div className={styles.container}>
    {
        !students ?

          <LoadingOverlay
            contained
            loadingText={'loading student data...'} />
            :
          <>
            <div className={styles.topBar}>
            <div className={styles.filters}>

              <div ref={searchRef} className={`${styles.search}`}>
                <div className={styles.inner}>
                  <div className={styles.lupa} />
                  <input
                    // onFocus={e => console.log(e)}  
                    onFocus={() => { searchRef.current.classList.add(styles.open)}}  
                    onBlur={() => { searchRef.current.classList.remove(styles.open)}}  
                    onChange={e => setFilterVals({ ...filterVals, text: e.target.value })} 
                    ref={inputRef} autoComplete={"off"} type="text" name="search input" />
                </div>
              </div>

            </div>
            <div></div>
          </div>
        <div className={`${styles.tableCont} ${synod && styles.synod}`}>
              <div className={`${styles.tableHeader} ${synod && styles.synod}`}>
                <div className={styles.column}><p>Student Name</p></div>
                <div className={styles.column}><p>&nbsp;</p></div>
                <div className={styles.column}><p>Coins</p></div>
                <div className={styles.column}><p>number of activities</p></div>
                <div className={styles.column}><p>total words</p></div>
              </div>
              <div className={styles.rowsContainer}>
                {
                  students.map((student,idx)=>
                    (student?.studentName && normalize(student.studentName).includes(normalize(filterVals.text))) && 
                      <ClassStudentReport 
                        synod={synod}
                        setClassData={setClassData}
                        classData={classData}
                        key={idx} 
                        idx={idx}
                        classId={classId}
                        modalOverlay={modalOverlay}
                        zIndex={students.length - idx}
                        updateStudents={input=>setStudents(input)}
                        student={student} />)
                }
                {
                 ( students?.length  && filterArrayByObject(students, {studentName:filterVals.text}).length < 1) &&
                    <div className={styles.placeholder}>
                      <h5>No students matching search criteria</h5>
                    </div>
                }
              </div>
        </div>
          </>
        
        }
    </div>
  )
}

export default ClassReports