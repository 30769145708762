import React, { useState} from 'react'


import styles from './SubscriptionModal.module.scss';
import SwitchButton from 'components/Teacher/Pricing/Switch';
import { useUserType } from 'context/AltPlatformProvider';
import PricingEscrib from 'components/Teacher/Pricing/PricingEscrib';
import PricingCard from './PricingCard';


const SubscriptionModal = () => {
    const [selectedValue, setSelectedValue] = useState('monthly');
    const { fjUser } = useUserType() 

  
  
    const handleChange = () => {
      const newSelectedValue = selectedValue === 'monthly' ? 'annual' : 'monthly';
      setSelectedValue(newSelectedValue);
    };

    const cardData = [
        {
          type:"Small Class",
          allowance: "Up to 5 students Free",
          monthly:"0",
          yearly: null,
          benefits:["Access to all functionality", "Unlimited activities"],
          ctaText:"Start for free",
          subtitle: '',
          ctaAction:"signup_teacher"
        },
        {
          type:"Standard",
          allowance: "More than 5 students",
          monthly: selectedValue === 'annual' ? '10' : '1',
          yearly: "100",
          benefits:[
            "Access to all functionality","Unlimited activities", "Personalized onboarding support", 
            "Professional development, training and ongoing support"
          ],
          subtitle: "",
          ctaText:"Get started",
          ctaAction:"contact"
        },
        {
          type:"Spirit of Truth Workbooks",
          allowance: "",
          monthly:"6.99",
          yearly: "",
          benefits:[
           'Journals that include Sophia "Spirit of Truth" workbooks'
          ],
          subtitle: '*A Standard subscription is required to use Sophia Workbooks.',
          ctaText:"Get started",
          ctaAction:"spirit"
        }
      ]


  return (
    <div>
      {
        fjUser ?
      <div className={styles.containerx}>
      <SwitchButton selectedValue={selectedValue} handleChange={handleChange} />

          <div className={`${styles.cards}`}>
              { cardData.map((i,idx) => 
                  <PricingCard
                  cardData={i} 
                  key={idx} 
                  blue={idx===2} 
                  pink={idx===1}
                  light={idx===0}
                  selectedValue={selectedValue}
                  />
              )}
              
          </div>
          <a href="https://es-courses-static.s3.amazonaws.com/subscription-terms.pdf"  target="_blank" rel="noopener" className={styles.faithlink}>
                <em>
                See Faith Journal Subscription Terms
                </em>
          </a>

            <div>
              <div >
              <p style={{ color: "#858585"}}>
                <em>
                ** Contact us at info@faith-journals.com if your institution would prefer to be <strong>invoiced</strong>.
                </em>
              </p>
              </div>
              <p style={{ color: "#858585"}}>
                <em>
                *** Contact us if your institution needs multiple teachers so we can include them in the same institution account.
                </em>
              </p>
          </div>
        
      
      </div>
      :
     <PricingEscrib show={false}/>
      }
    </div>

  )
}

export default SubscriptionModal