import React from 'react';

import styles from './TeacherNotifications.module.scss';
import studentImg from 'assets/notification-student.svg'

const Notification_Student = ({ studentName }) => {

  return (
    <div className={styles.studentContainer}>

      <div className={styles.titleBar}>
        <img src={studentImg} alt="Student image"/>
        <h4>New Student Request</h4>
      </div>

      <p><strong>{studentName}</strong> has requested to join your school</p>

      <div className={styles.btns}>
        <div className={styles.approveBtn}>Approve</div>
        <div className={styles.rejectBtn}>Reject</div>
      </div>

    </div>
  )

}

export default Notification_Student;