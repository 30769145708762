import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup'
import { Link, Redirect } from 'react-router-dom';
import { showModal } from '../../../helpers/uiFunctions';
import loader from 'assets/loader-dots.svg';
import spinner from 'assets/spinner-sml--fast.svg';
import {returnPlatformStyle } from 'helpers/altPlatformFunctions'

import styles from './Dashboard.module.scss';
import { getFromApi } from 'API/adminCalls';

const Dashboard = ({ modalOverlay, fjUser }) => {

  const [dashData, setDashData ] = useState(null)
  const [ loading, setLoading ] = useState({profilePic:false})
  const [ localLogo, setLocalLogo ] = useState('')


  const callApi = async () => { 
    setDashData(await getFromApi('school_admin'))
  };

  useEffect(()=>{

    if(!dashData){
      callApi()
    }
    if(dashData&&dashData.shoolLogoUrl){
      setLocalLogo(dashData.schoolLogoUrl)
    }

  },[dashData])


  const handleUploadImg = (e) => {

    
    if(e.target.files){
      
      setLocalLogo('')
      const newPic = e.target.files[0]
      setLoading({...loading, profilePic:true})
      setTimeout(()=>{ 
        setLocalLogo(newPic)
        setLoading({...loading, profilePic:false}) 
      },1500)
    }
  }


  return (
      <div className={styles.container}>
        {
          dashData?.message ?

            <Redirect to='/teacher/classes' /> 

        : !dashData ||  !dashData.schoolName ?

            <div className={styles.loaderDiv}>
              <img className={styles.loader} src={loader} alt="spinner"/>
            </div>    

        :
            <>
              <h1 onClick={() => showModal(modalOverlay, {type:'mini-you', fjUser:fjUser} )} className={styles.pageTitle}>Admin Dashboard</h1>
                <div className={styles.inner} id="inner">
                  
                  <div className={styles.folders}>
                  <Link to="/teacher/admin/manage">
                    <div className={styles.folder}>
                      <div className={`${styles.folderImg} ${styles.manage}`}></div>
                      <div className={styles.folderText}>
                        <h1>Teachers & Students</h1>
                        {/* <h3>Add, remove, and edit teachers</h3> */}
                      </div>
                    </div>
                  </Link>
{/* 
                  <Link to="/admin/reports">
                    <div className={`${styles.folder}`}>
                      <div className={`${styles.folderImg} ${styles.reports}`}></div>
                      <div className={styles.folderText}>
                        <div className={`${styles.soon}`}>
                          <h1>Reports</h1>
                        </div>
                        <h3>Get the latest reports</h3>
                      </div>
                    </div>
                  </Link> */}

                  <Link to='/teacher/account/manage/true'> 
                    <div className={`${styles.folder}`}>
                      <div className={`${styles.folderImg} ${styles.subscription}`}></div>
                      <div className={styles.folderText}>
                      <div className={`${styles.soon}`}>
                          <h1>Manage Subscription</h1>
                        </div>
                        {/* <h3>Add, remove and edit teachers</h3> */}
                      </div>
                    </div> 
                  </Link>
                  </div>

                  <div className={styles.details}>
                    <div className={`${styles.schoolInfo} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
                      {
                        !localLogo && !loading.profilePic ?

                          <label className={`${styles.schoolLogo} ${styles.default}`}>
                            <input onChange={(e)=>{handleUploadImg(e)}} type="file" name="logoUpload" accept=".png, .jpg, .jpeg"/>
                            <h5>Add your school logo here</h5>
                          </label>

                      :
                        !localLogo && loading.profilePic ? 

                            <div className={`${styles.schoolLogo} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
                              <img className={styles.logoSpinner} src={spinner} alt="School logo"/> 
                            </div>
                            
                      :

                          <div className={`${styles.schoolLogo} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
                            <img src={localLogo} alt="school logo"/>

                            <label className={styles.changeLogo}>
                            <input onChange={(e)=>{handleUploadImg(e)}} type="file" name="logoUpload" accept=".png, .jpg, .jpeg"/>
                              <h5>Upload new logo</h5>
                            </label>
                            {/* <img src={wildcats} alt="school"/> */}
                          </div>

                      }
                      <div className={styles.schoolInfoText}>
                        {/* <h2>Super extra mega Long School Name</h2> */}
                        <h2>{dashData.schoolName}</h2>
                        {/* <h4>The learning School</h4> */}
                      </div>
                    </div>
                    <div className={styles.statsDiv}>
                      <div className={`${styles.statBubble} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
                        <h1 className={styles.number}>
                          <CountUp start={0} end={dashData.teacherCount?dashData.teacherCount:0} duration={1.5} separator="," />
                        </h1>
                        <h3>Teachers</h3>
                      </div>
                      <div className={`${styles.statBubble} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
                        <h1 className={styles.number}>
                          <CountUp start={0} end={dashData.studentCount?dashData.studentCount:0} duration={1} separator="," />
                        </h1>
                        <h3>Students</h3>
                      </div>
                      <div className={`${styles.statBubble} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
                        <h1 className={styles.number}>
                          <CountUp start={500} end={dashData.projectCount?dashData.projectCount:0} duration={1} separator="," />

                        </h1>
                        <h3>Projects</h3>
                      </div>
                      <div className={`${styles.statBubble} ${fjUser && styles[returnPlatformStyle('fj')]}`}>
                        <h1 className={styles.number}>
                          <CountUp start={2000} end={dashData.wordCount?dashData.wordCount:0} duration={1} separator="," />
                        </h1>
                        <h3>Words</h3>
                      </div>
                    </div>
                  </div>
                </div>
            </>

        }
        
      </div>
  )
}

export default Dashboard;