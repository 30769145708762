import React, { useRef, useState } from 'react';
import { setQueryItem } from 'Redux/actions';
import { store } from 'storeConfig';
import sent from 'assets/icon-sent--blue.svg';

import styles from './ActivateAccountModal.module.scss';

const ActivateAccountModal = ({closeBtn, item: {email}}) => {

  const [localMail, setEmail] = useState(email);
  const [ contState, setContState ] = useState('init');
  const contRef = useRef()
  const emailRef = useRef()


  const handleSend = () => {
    if(localMail){
      setContState('sent');
      contRef.current.classList.add(styles.sent)

    } else {
      emailRef.current.classList.add('shake');
      setTimeout(()=>{emailRef.current.classList.remove('shake')},500)
    }
  }

  return (
    <div ref={contRef} className={`${styles.container}`}>
      { contState === 'init' ? 
        <>
          <h1>Activate your account</h1>
          <div className={styles.warning}>
            <p>
              In order to start this activity, you need to activate your account.
            </p>
          </div>


          <div ref={emailRef} className={styles.email}>
            <p className={styles.email}> Current email: <strong>{localMail}</strong> </p>
            <span onClick={()=>{ store.dispatch(setQueryItem({type:'changeEmail', setEmail:setEmail, email:email, variant:'modal'})) }} className={styles.changeBtn} className={styles.changeEmail}>change email</span> 
          </div>

          <i>
            * If your email is incorrect, you can change it before activating the account
          </i>

          <div className={styles.buttons}>
            {/* <div onClick={()=>{ store.dispatch(setQueryItem({type:'changeEmail', setEmail:setEmail, variant:'modal'})) }} className={styles.changeBtn}>
              Change email
            </div> */}
            <div onClick={handleSend} className={styles.resendBtn}>Resend email</div>
          </div>
        </>
        
      :

        <div className={styles.sentModal}>
          <img src={sent} alt="sent!" />
          <h1>Email sent!</h1>
        </div>
              
      }
    </div>
  )

}

export default ActivateAccountModal;