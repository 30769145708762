import { stripTags } from 'helpers/stripTags';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import parse from 'html-react-parser';


import styles from './SidebarWordbank.module.scss';
import { parse as Parser } from 'date-fns';

const SidebarWordbank = ({ wordBank, entryText, instructions }) => {
  const quillRef = useRef();

  const [ usedWords, setUsed ] = useState(['enthusiastic', 'one', 'two'])
  const [ unusedWords, setUnused ] = useState(['longer', 'extravegant'])

  const sortWords = text => {

    //strip HTML tags from entry text, split into array of words, strip punctuation, 
    //convert words to lower case
    let entryTextArray = stripTags(text.replace(/<br>/g, " ").replace(/[^\w\s]|_/g, "")).split(' ')
    entryTextArray=entryTextArray.map(i => i.toLowerCase())

    //identify used and unused words by filtering the entryTextArray prepared above
    setUsed([...new Set(wordBank.filter(n => entryTextArray.includes(n.toLowerCase())))])
    setUnused([...new Set(wordBank.filter(n => !entryTextArray.includes(n.toLowerCase())))])
    
  }

  useEffect(()=>{sortWords(entryText)},[entryText])

  return (
    <div className={styles.container}>

      <div className={styles.sectionTitle}>
        <div className={`${styles.dot} ${styles.used}`}>
          <p>{usedWords.length}</p>
        </div>
        <p>Used words</p>
      </div>

      <div className={styles.itemsDiv}>
        {
          usedWords.map((word, idx) =>
            <div key={idx} className={styles.wordBlock}>
              <p>{word}</p>
            </div>
          )
        }
      </div>

      <div className={styles.sectionTitle}>
        <div className={`${styles.dot} ${styles.unused}`}>
          <p>{unusedWords.length}</p>
        </div>
        <p>Unused words</p>
      </div>

      <div className={styles.itemsDiv}>
        {
          unusedWords.map((word, idx) =>
            <div key={idx} className={`${styles.wordBlock} ${styles.unused}`}>
              <p>{word}</p>
            </div>
          )
        }
      </div>
      <div className={styles.instructions}>
        <div className={styles.instructions_div}>
          {parse(instructions)}
        </div>
      </div>
    </div>
  )

}

export default SidebarWordbank;




// const instancesFromArray = text => {
//   const array = stripTags(text).split(' ')
//   setUsed([...new Set(wordbank.filter(n => array.includes(n)))])
//   setUnused([...new Set(wordbank.filter(n => !array.includes(n)))])
// }