import { getFromApi, sendToApiReturn } from 'API/teacherCalls';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import spinner from 'assets/spinner-white-sm.svg';

import styles from './StudentProfile.module.scss';
import user from 'assets/user_grey.svg'
import copy from 'assets/icon-copy--grey.svg'
import plus from 'assets/newNavAssets/icon_plus_blue.svg'
import { showModal } from 'helpers/uiFunctions';
import { debounce_deffered } from 'helpers/debounceDeffered';
import Tooltip from 'components/UtilityComps/Tooltip/Tooltip';
import { addAnimation } from 'helpers/uiFunctions'
import { idxClass } from 'helpers/idxClass';

const StudentProfile = ({ modalOverlay }) => {

  const [ student, setStudent ] = useState(null)
  const [ displayEmails, setDisplayEmails ] = useState(null)
  const [ conf, setConf ] = useState(null)
  const { studentId, classId } = useParams()
  const [ params, setParams ] = useState(null) 
  const [ confirm, toggleConfirm ] = useState(false) 
  const [ error, toggleError ] = useState(false) 
  const [ loading, toggleLoading ] = useState(false) 
  const copyLinkRef = useRef()
  const userNameRef = useRef()

  const callStudentAPI = async () =>{setStudent(await getFromApi(`students/${studentId}`))}

  useEffect(()=>{
    callStudentAPI()
  },[])

  const establishObject = (studentObj, setter) => {
    toggleError(false)
    const { studentName, studentEmail, grade } = studentObj
    setter({
      firstName: studentName?.split(' ')[0],
      lastName: studentName?.split(' ')[1]?studentName?.split(' ')[1]:"",
      userName: studentEmail,
      grade
    })
  }

  useEffect(()=>{
    if(student) {
      setDisplayEmails(student.parentEmail)
      establishObject(student, setParams, true)
    }
  },[student])


  const debounceUpdate = useCallback(debounce_deffered(async (params, student)=>{
    toggleLoading(true)
    const existingName = student.studentEmail
    const data = await sendToApiReturn(`students/${studentId}/update`, params)


    if((existingName !== params.userName) && (existingName === data.studentEmail)){
      addAnimation(userNameRef, 'shakeSm', 400)
      toggleError(true)

    } else {
      setStudent({...student, studentName:data.studentName, studentEmail:data.studentEmail })
      toggleConfirm(true)
      setTimeout(()=>{toggleConfirm(false)},2000)
      
    }

    toggleLoading(false)
    
  }, 1500), []);

  const handleCopy = (email, index) => {
    setConf(index);   
    setTimeout(()=>{ setConf(null);},1500)
    navigator.clipboard.writeText(email) 
  }

  const handleLink = () => {
    copyLinkRef.current.innerText="COPIED"
    setTimeout(()=>{
      copyLinkRef.current.innerText="Copy Link"
    },1500)
    navigator.clipboard.writeText(student.uniqLoginUrl) 
  }

  const handleAddEmail = () => {
    showModal(modalOverlay,{
      type:'sendParentInvite',
      studentId,
      guardians:displayEmails,
      setGuardians:setDisplayEmails
    })
  }


  return !params || !student ? null :  (
    <div className={styles.container}>

      <Link to={`/teacher/classes/view/${classId}/?students`} className={styles.back}>
        <div className={styles.backBtn} />
        <p>Back to students</p>

      </Link>

      <div className={styles.left}>
        <h1>Student Profile</h1>

        <div className={styles.avatarDiv}>
          <img src={student.boomerUrl} alt="" />
          <div className={styles.content}>
            <div className={styles.text}>
              <h3>{student.studentName}</h3>
              <p className={styles.bucks}>{student.boomerBucks}</p>
            </div>
            <div onClick={()=>showModal(modalOverlay, {
              type:'loginCode', 
              studentName:student.studentName, 
              studentEmail:student.studentEmail, 
              studentId:studentId
              })} className={`${styles.generateBtn} ${styles.res}`}>
              Generate password
            </div>
          </div>
        </div>

        <div onClick={()=>showModal(modalOverlay, {
          type:'loginCode', 
          studentName:student.studentName, 
          studentEmail:student.studentEmail, 
          studentId:studentId
          })} className={`${styles.generateBtn} ${styles.main}`}>
          Generate password
        </div>

        <h3 className={styles.title}>Classes</h3>
        <div className={styles.classesDiv}>
          {
            student.classDetails.map((item,idx)=>
              <div key={idx} className={styles.classItem}>
                <div className={`${styles.oval} ${styles[idxClass(item.iconKey)]}`}/>
                <p>{item.name}</p>
                <Link className={styles.clickHandle} to={`/teacher/classes/view/${item.id}/?activities`} />
              </div>
            )
          }

        </div>

      </div>

      <div className={styles.right}>
        <div className={styles.content}>
          <label>
            <h3>First name</h3>
            <input 
              onChange={e=>setParams({...params, firstName:e.target.value})} 
              value={params.firstName} 
              type="text"/>
          </label>
          <label>
            <h3>Last name</h3>
            <input 
              onChange={e=>setParams({...params, lastName:e.target.value})} 
              value={params.lastName} 
              type="text"/>
          </label>
          <label ref={userNameRef}>
            <h3>Username</h3>
            <input 
              onChange={e=>{setParams({...params, userName: e.target.value}); toggleError(false)}} 
              value={params.userName} 
              className={error && styles.red}
              type="text"/>
              { error && <p className={styles.error}>Username already exists</p>}
          </label>

          <div className={`
            ${styles.buttons}
            ${!isEqual(params,{
              firstName:student.studentName.split(" ")[0],
              lastName:student.studentName.split(" ")[1],
              userName:student.studentEmail,
              grade:student.grade
            }) && styles.active}
          `}>
            <div
              onClick={()=>establishObject(student,setParams)}
              className={ styles.cancelBtn }>
              Cancel
            </div>
            <div
              onClick={()=>debounceUpdate(params, student)}
              className={`${styles.submitBtn} ${loading && styles.loading}`}>
                {
                  !loading ? 'Update' : <img src={spinner} alt='Spinner' />
                }
            </div>
            {confirm && <p className={styles.confirmation}>Student data updated</p>}
          </div>


          <div className={styles.emailsDiv}>
            <h3>Parents/Guardians</h3>

            <div className={styles.emails}>
              {
                (displayEmails&&displayEmails.length) ?
                  displayEmails.map((item, idx) =>
                    <div onClick={e=>{handleCopy(item, idx)}} className={`${styles.emailDiv} ${conf===idx && styles.inactive}`}>
                      <img src={user} alt="user" />
                      <p className={conf === idx && styles.conf}>{conf!==idx?item:"COPIED"}</p>
                      <img className={styles.copy} src={copy} alt="copy" />
                      <Tooltip
                        tooltipText={"Click to copy"}
                        hoverClass={styles.tooltipHover}
                        maxWidth={"110px"}
                        padding={"10px 20px"}
                        whiteSpace={"noWrap"} />
                    </div>
                  )
                : null
              }
              <div onClick={handleAddEmail} className={`${styles.emailDiv} ${styles.addEmail}`}>
                Set parent email
                <img className={styles.plus} src={plus} alt="" />
              </div>
            </div>
          </div>

          <div className={styles.quickDiv}>
            <h3>Quick login</h3>

            <div className={styles.inner}>
              <div className={styles.top}>
                <p>Pass your student the following link and they can log straight in to their account simply by clicking on it:</p>
              </div>
              <div className={styles.bottom}>
                <p className={styles.link}>{student.uniqLoginUrl}</p>
                <div onClick={handleLink} ref={copyLinkRef} className={styles.copyLink}>
                  Copy Link
                </div>
              </div>
              
            </div>
          </div>

        </div>
      </div>
    </div>
  )

}

export default StudentProfile;