
export const logout = (url) => {
  localStorage.clear()

  setTimeout(() => {
    let logoutUrl = url
    if(window.location.host === "localhost:3000") {
      logoutUrl = "http://localhost:3011/auth/logout"
    }
    window.location = logoutUrl 
  }, 300)
}


