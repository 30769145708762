import React,{useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CARD_ELEMENT_OPTIONS } from 'helpers/uiFunctions';
import tick from 'assets/teacher/icon-circleTick.svg'
import styles from './BookPurchaseModal.module.scss';
import { getFromApi, sendToApi } from 'API/parentCalls';
import spinner from 'assets/spinner-sml--fast.svg';
import loader from 'assets/Spinner.svg'
import { store } from 'storeConfig';
import { setQueryItem } from 'Redux/actions';

const PurchaseStepThree = ({payload, contRef, setStep, childId, activityId, closeBtn, activityName }) => {

  const [ apiRes, setApiRes ] = useState({})
  const { address_1, address_2, zip, city, country_name, state_name } = payload.address;
  const { type, copies, price } = payload;
  const {email, name} = useSelector(state=>state.dashData)
  const stripe = useStripe();
  const elements = useElements();
  const loadOverRef = useRef()
  const confRef = useRef()
  const errorRef = useRef()


  const callApi = async () => { 
    if (!localStorage.getItem("book_pi_id")) {
      setApiRes(await(getFromApi(`book_purchase/${childId}?projectId=${activityId}&address_1=${address_1}&address_2=${address_2}&city=${city}&country_name=${country_name}&state_name=${state_name}&zip=${zip}&purchaseType=${type}&quantity=${copies}`)))
    } else {
      setApiRes(await(getFromApi(`book_purchase/${childId}?pi_id=${localStorage.getItem("book_pi_id")}&projectId=${activityId}&address_1=${address_1}&address_2=${address_2}&city=${city}&country_name=${country_name}&state_name=${state_name}&zip=${zip}&purchaseType=${type}&quantity=${copies}`)))

    }
  }

  useEffect(()=>{
    callApi()
  },[])


  useEffect(()=>{
    if(apiRes&&apiRes.pi_id){
      localStorage.setItem('book_pi_id', apiRes.pi_id);
    }
  },[apiRes])

  const itemText = (purchaseType) =>{
    if(purchaseType==='bundle')return <><h4>Bundle: {copies}x Paperback book + Digital + Shipping</h4> <h4>${apiRes.price.toFixed(2)} (US)</h4></>
    if(purchaseType==='digital')return <><h4>Digital: Digital copy</h4> <h4>$3.99 (US)</h4></>
    if(purchaseType==='paperback')return <><h4>Paperback: {copies}x Paperback book + Shipping</h4> <h4>${apiRes.price.toFixed(2)} (US)</h4></>
  }



  const handleBack = () => {
    setStep('delivery')
    contRef.current.classList.remove(styles.confirm)
    contRef.current.classList.add(styles.delivery)
  }


  

  const handleSubmit = async () => {

    console.log(elements.getElement(CardElement))
    loadOverRef.current.classList.add(styles.show)

    if (!stripe || !elements) { return; }

    const result = await stripe.confirmCardPayment(apiRes.cs, {

      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email:email
        },
      }
    }); 
    

    if (result.error) {
      console.log(result.error.message);
      loadOverRef.current.classList.remove(styles.show)
      errorRef.current.innerText=result.error.message;

    } else {
      
      if (result.paymentIntent.status === 'succeeded') {
        contRef.current.classList.add(styles.paymentConfirmation)
        loadOverRef.current.classList.remove(styles.show)
        confRef.current.classList.add(styles.show)
        sendToApi(`book_purchase_success/${localStorage.getItem("book_pi_id")}?projectId=${activityId}&studentId=${childId}&purchaseType=${type}&quantity=${copies}&price=${apiRes.price}`)
      }
    }
  };

  const handleDownload = () => {
    store.dispatch(setQueryItem({type:"pdfRender", payload:{student_id:childId}, activityId:activityId,  activityName:activityName, parent:true}))
  }

  return (
    <div className={styles.confirm}>

     { 
      apiRes&&apiRes.price ? 
          <>
            <h1>Confirm purchase</h1>

            <div className={styles.summary}>
              <div className={styles.confirmTopBar}>
                <div className={styles.colorTab}></div>
                {itemText(type)}
              </div>

              <h3>Books will be shipped to:</h3>
              <div className={styles.address}>
                <p>{apiRes.address}</p>
                <p onClick={handleBack} className={styles.edit}>edit</p>
              </div>
            </div>


            <div className='bookPurchaseCardDetails'>
              <h3>Card details:</h3>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
              <div ref={errorRef} className={styles.errorMsg}></div>
            </div>
            <div onClick={handleSubmit} className={styles.confirmBtn}>Confirm purchase</div>


            <div ref={loadOverRef} className={styles.loadingOverlay}>
              <img src={loader} alt="loader"/>
              <p>processing...</p>
            </div>
            <div ref={confRef} className={styles.confMsg}>
              <img src={tick} alt="tick"/>
              <h2>Payment successful!</h2>
                {type!=='paperback'&&<p>You can download your PDF <span onClick={handleDownload}>here</span></p>}
              <p>Thank you for your order</p>

              <div onClick={()=>closeBtn.current.click()} className={styles.closeBtn}>Close</div>
            </div>
          </>

      :

          <div className={styles.loaderDiv}>
            <img src={spinner} alt="loader"/>
          </div>
     }
    </div>
  )

}

export default PurchaseStepThree;