import prev1 from 'assets/tutorialPrev1.png'
import prev2 from 'assets/tutorialPrev2.png'
import prev3 from 'assets/tutorialPrev3.png'
import prev4 from 'assets/tutorialPrev4.png'


const tutorials = [
  {
    title:'Setting Up Your First Activity',
    tag:'basic',
    url:'https://youtu.be/lKsvX6DvEG4',
    prevImg:prev4
  },
  {
    title:'Using Automated Grammar Feedback',
    tag:'tips and tricks',
    url:'https://youtu.be/5BmEX4mRcSM',
    prevImg:prev2
  },
  {
    title:'How to add a class and students',
    tag:'basic',
    url:'https://www.youtube.com/watch?v=Xe6QAdmMDdA&feature=youtu.be',
    prevImg:prev3
  },
  // {
  //   title:'Create Assignments',
  //   tag:'basic',
  //   url:'https://youtu.be/aYDfwUJzYQg'
  // },
  // {
  //   title:'Onboarding - First Assignment',
  //   tag:'onboarding',
  //   url:'https://youtu.be/8R6StQfLNbw'
  // },
  // {
  //   title:'How Stories Work',
  //   tag:'writing bee',
  //   url:'https://youtu.be/y-E7_VHLvkE'
  // },
  // {
  //   title:'Create a Journal',
  //   tag:'basic',
  //   url:'https://youtu.be/8R6StQfLNbw'
  // },
  // {
  //   title:'Onboarding - First Class',
  //   tag:'onboarding',
  //   url:'https://youtu.be/TOdo7dhvSwg'
  // },
  // {
  //   title:'Student View',
  //   tag:'basic',
  //   url:'https://youtu.be/y-E7_VHLvkE'
  // },
  // {
  //   title:'Duplicate Assignments',
  //   tag:'tips and tricks',
  //   url:'https://youtu.be/8R6StQfLNbw'
  // },
  // {
  //   title:'Using Google Drive Supporting Documents',
  //   tag:'tips and tricks',
  //   url:'https://youtu.be/6xb898sQtu8'
  // },
  // {
  //   title:'Create Assignments',
  //   tag:'basic',
  //   url:'https://youtu.be/6xb898sQtu8'
  // },
  // {
  //   title:'Onboarding - First Assignment',
  //   tag:'onboarding',
  //   url:'https://youtu.be/TOdo7dhvSwg'
  // },
  // {
  //   title:'How Stories Work',
  //   tag:'writing bee',
  //   url:'https://youtu.be/8R6StQfLNbw'
  // },
  // {
  //   title:'Create a Journal',
  //   tag:'basic',
  //   url:'https://youtu.be/6xb898sQtu8'
  // },
  // {
  //   title:'Onboarding - First Class',
  //   tag:'onboarding',
  //   url:'https://youtu.be/8R6StQfLNbw'
  // },
]


export default tutorials