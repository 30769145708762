import React from 'react';

import styles from './TeacherNotifications.module.scss';
import teacherImg from 'assets/notification-teacher.svg'
import { sendToApi, sendToApiReturn } from 'API/teacherCalls';
import { store } from 'storeConfig';
import { updateDashData } from 'Redux/actions';
import { redirectClassActivities } from 'API/TeacherCallFunctions/dashBoardFunctions';

const Notification_Student = ({ teacherName, classTitle, inviteId, setApiRes, classId }) => {

  const handleBtn = async (redir) => {

    const res = await sendToApiReturn(`class/accept_invite/${inviteId}`)
    setApiRes(res)

    if(!res.length){ 
      store.dispatch(updateDashData('hasNotifications', false));
    }
    setTimeout(()=>{
      if(redir){ redirectClassActivities(classId)}
    },300)
  }

  return (
    <div className={styles.teacherContainer}>

      <div className={`${styles.titleBar} ${styles.hideOnMobile}`}>
        <img src={teacherImg} alt="Teacher image"/>
        <h4>New teacher Request</h4>
      </div>

      <p className={styles.hideOnMobile}> <strong>{teacherName}</strong> has invited you to join '{classTitle}' as a teacher</p>

      <div className={`${styles.btns} ${styles.hideOnMobile}`}>
        <div onClick={()=>{ handleBtn(true)} } className={styles.approveBtn}>Approve</div>
        <div onClick={()=>{ handleBtn(false) }} className={styles.rejectBtn}>Reject</div>
      </div>


      <div className={styles.responsiveContent}>
        <img src={teacherImg} alt="Teacher image"/>
        <div className={styles.mainContent}>
          <p><strong>{teacherName}</strong> has invited you to join <strong>'{classTitle}'</strong> as a teacher</p>
        <div className={`${styles.btns}`}>
          <div onClick={()=>{ handleBtn(true)} } className={styles.approveBtn}>Approve</div>
          <div onClick={()=>{ handleBtn(false) }} className={styles.rejectBtn}>Reject</div>
        </div>
        </div>
      </div>

    </div>
  )

}

export default Notification_Student;