import React, { useRef, useState } from 'react';

import SpendCreditsSub from './SpendCreditsSub';

import styles from './SpendCreditsModal.module.scss';
import subImg from 'assets/creditOption-sub.svg';
import booksImg from 'assets/creditOption-books.svg';
import loader from 'assets/Spinner.svg'
import tick from 'assets/teacher/icon-circleTick.svg'
import SpendCreditsBooks from './SpendCreditsBooks';
import { sendToApi, sendToApiReturn } from 'API/teacherCalls';

const SpendCreditsModal = ({ closeBtn, item: {  plusExpireDate, credits, setCreditData, creditData, scheduleCreditPayment }}) => {

  const loadOverRef = useRef();
  const confRef = useRef();
  const booksContRef = useRef()
  const contRef = useRef();
  const transRef = useRef();
  const [ selectedOption, setSelectedOption ] = useState('');
  const [ showConf, setShowConf ] = useState(false);
  const [ scheduleRenewal, setScheduleRenewal ] = useState(false);

  const openModal = (selection) => {
    setSelectedOption(selection)
    contRef.current.classList.add(styles.open)
    setTimeout(()=>{transRef.current.classList.add(styles.showDiv)},200)
  }


  const handleSubmit = async (sumRef, showError) => {

    if(credits<15){
      sumRef.current.classList.add('shakeSlow')
      sumRef.current.classList.add(styles.warning)
      showError(true)
      setTimeout(()=>{sumRef.current.classList.remove('shakeSlow')},1000)

    } else {
      const res = await sendToApiReturn(`plus_purchase_points${scheduleRenewal?'?scheduleCreditPayment=true':''}`)
      setCreditData(res)
      setShowConf(true)
      setTimeout(()=>{
        setShowConf(true)
        loadOverRef.current.classList.remove(styles.show)
        confRef.current.classList.add(styles.show)
      },600)
      
    }
  }

  return (
    <div ref={contRef} className={!showConf ? styles.container : `${styles.container} ${styles.confirmation}`}>

      {
        !showConf ? //main state containing subscription and books options
          <>
            <div className={styles.topBar}>
              <div className={styles.totalCredits}>
                <h5>CREDITS</h5>
                <h1>{credits}</h1>
              </div>
              <h3>Choose how you want to spend your credits</h3>
            </div>

              <div className={styles.optionRadios} onChange={(e)=>openModal(e.target.value)} >
                <label>
                  <input type="radio" name="creditOptions" value="subscription"/>
                  <div className={styles.creditOption}>
                    <img src={subImg} alt="subscription"/>
                    <h4>Subscription</h4>
                  </div>
                </label>

                <label>
                  <input type="radio" name="creditOptions" value="books"/>
                  <div className={styles.creditOption}>
                    <img src={booksImg} alt="subscription"/>
                    <h4>Books</h4>
                  </div>
                </label>
              </div>
              <div ref={transRef} className={styles.transactionDiv}>
                {
                  selectedOption === 'subscription' ?

                    <SpendCreditsSub 
                      handleSubmit={handleSubmit} 
                      setScheduleRenewal={setScheduleRenewal}
                      plusExpireDate={plusExpireDate}
                      scheduleCreditPayment={scheduleCreditPayment}
                      creditData={creditData}
                      setCreditData={setCreditData} />

                  :

                    <SpendCreditsBooks booksContRef={booksContRef} />

                }
              </div>
              </>


            :  // loading and confirmation state after redeeming

              <>   
                  <div ref={loadOverRef} className={`${styles.loadingOverlay} ${styles.show}`}>
                    <img src={loader} alt="loader"/>
                    <p>processing...</p>
                  </div>
                  <div ref={confRef} className={styles.confMsg}>
                    <img src={tick} alt="tick"/>
                    <h2>You have successfully redeemed 15 credits!</h2>
                    <p>Your new balance is: {(parseInt(credits)-15)} credits</p>

                    <div onClick={()=>closeBtn.current.click()} className={styles.closeBtn}>Close</div>
                  </div> 

              </>
      }

      </div>

  )

}

export default SpendCreditsModal;