import React, { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { privateModeDummyText } from "../EntryModalMain/privateModeDummyText";
import styles from './EntryTextEditor.module.scss';
import StudentFileDiv from "./StudentFileDiv/StudentFileDiv";


const EntryTextEditor = ({ editing, privateMode, privateActive, localText, setLocalText, mobile, studentFiles, setPreviewImgs }) => {

  useEffect(()=>{
    if(editing){
      quillRef.current.focus();
    }
  },[editing])
  const quillRef = useRef()

  const handleChange = val => setLocalText(val) 

  const editorModules = {

    toolbar: 
    
      !mobile ? //non-responsive options
        [
          [{ 'font': [] }],
          ['bold', 'italic', 'underline'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ]

      : //responsive options
      [
        [{ 'font': [] }],
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
        
  };

  return (
    <div className={`${styles.container} ${studentFiles?.length > 0 && styles.short} entryTextEditor`}> 
        
      <ReactQuill
        ref={quillRef}
        className={editing && 'withToolbar'}
        readOnly={!editing}
        modules={editing ? editorModules : { "toolbar": false }}
        theme="snow"
        value={(privateActive &&  privateMode) ? privateModeDummyText : (localText||"")}
        onChange={val=>handleChange(val)}>
      </ReactQuill>

            
      {
        (studentFiles?.length > 0 ) &&

          <div className={styles.filesDiv}>
            {
              studentFiles?.map((i,idx) => 
                <StudentFileDiv
                  styles={styles}
                  showPreview={()=>setPreviewImgs({imgs:studentFiles, idx})} 
                  key={idx} 
                  item={i} />
                )
            }
          </div>

      }

    </div>
  )

}

export default EntryTextEditor;


