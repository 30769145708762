import React from 'react';
import { Link } from 'react-router-dom';

import styles from './UpgradePlanModal.module.scss';

import plusIcon from 'assets/icon-plus--yellow.svg';

import { useUserType } from 'context/AltPlatformProvider';

const UpgradePlanModal = ({item:{ variant }, closeBtn}) => {

    //variables for Faith Journal or Synod users
  //these flags apply restyling the entire app
  const { fjUser, synodUser } = useUserType()

  const platformName = fjUser ? "Faith Journals" : "Escribli"

  return (
    <div className={styles.container}>
      <img src={plusIcon} alt="plus"/>
      <h1>{platformName} is free up to 5 students</h1>
      <p>In order to have more than 5 students you need a subscription plan. Contact us and we will help you find a plan that fits your institution needs.</p>
      
      <div className={styles.buttons}>
          <div onClick={()=>{ closeBtn.current.click()}}  className={styles.cancelBtn}>Cancel</div>
          <div className={styles.upgradeBtn}> <Link to="/teacher/account/manage"> Upgrade Plan </Link></div>
      </div>
    </div>
  )
}

export default UpgradePlanModal;