import { dateNumericVar } from 'helpers/dateFunctions';
import { defaultUserImgAdmin, showModal } from 'helpers/uiFunctions';
import React, { Fragment, useState } from 'react';

import styles from './TeacherRow.module.scss';

const TeacherRow = ({ teacher: { teacherName, teacherEmail, grades, lastLogin, teacherId, teacherImgUrl }, modalOverlay }) => {

  const [ dropVis, setDropVis ] = useState(false)
  return (
    <Fragment>
      <div className="hideMobile"> 
        <div className={styles.container}>

        <input type="checkbox" name="teachers" id={teacherId}/>
        
          <div className={styles.photo}>
            <img src={teacherImgUrl ? teacherImgUrl : defaultUserImgAdmin({imageUrl:Math.floor(Math.random()*3)})} alt="User Img" />
            {/* <img src={teacherImgUrl ? teacherImgUrl : defaultUserImg({imageUrl:Math.floor(Math.random()*3)+1})} alt="User Img" /> */}
          </div>
          <div className={styles.name}>
            <h5>{teacherName}</h5>
          </div>
          <div className={styles.email}>
            <h5>{teacherEmail}</h5>
          </div>
          <div className={styles.grade}>
            <h5>{grades && grades.join('-')}</h5>
          </div>
          <div className={`${styles.lastLogin}`}>
            <h5>{lastLogin && dateNumericVar(lastLogin) }</h5>
          </div> 
          <div onClick={(e)=>{setDropVis(true)}} onMouseLeave={()=>{setDropVis(false)}} className={styles.dots}>
            {dropVis &&
              <div className={styles.dotsDropdown} onMouseLeave={()=>{setDropVis(false)}}>
              <div onClick={()=>{showModal(modalOverlay, {type:'remove', variant:'removeTeacher', teachers:[teacherId]})}} className={styles.dropdownRemove}>Remove teacher</div>
              </div>
            }
          </div>
        </div>
      </div>
  
      <div className="showMobile">
      <div className={styles.container}>
          <div className={styles.photo}>
          </div>

          <div className={styles.mobileHalf}>
          <h4>{teacherName}</h4>     
          <h5>{teacherEmail}</h5> 
          <h5>Grades {grades}</h5>      
          </div>
        </div>
      </div>
    </Fragment>

  )
}

export default TeacherRow;