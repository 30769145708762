import React,{ useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { format } from 'date-fns';

import { getFromApi } from 'API/teacherCalls';
import { sendToApiReturn } from 'API/teacherCalls';

import spirit from "../Teacher/Resources/truth1.svg"

import { LoadingOverlay } from 'components';
import PaymentDetailsBlock from './PaymentDetailsBlock';
import { showModal } from 'helpers/uiFunctions';

import styles from './Subscription.module.scss'
import AdminAccountSettingsBlock from './AccountSettingsBlock';
import { useUserType } from 'context/AltPlatformProvider';



const SubscriptionDetails = ({ modalOverlay }) => {
    const [ apiRes, setApiRes ] = useState(null);
    const { isAdmin } = useParams()
    const { fjUser } = useUserType()
    
    const history = useHistory()

    const handleDirect = () => {
      history.push('/teacher/account/manage')
    }

    const callApi = async () => setApiRes(await getFromApi('plus_details'));

    
    useEffect(()=>{
        callApi();
        console.log(isAdmin ? 'ADMIN USER' : 'NON-ADMIN USER')
      },[])
      
      const switchToMonthly = async () => {
        setApiRes(await sendToApiReturn(`plus_monthly`, {}))
      }
      
      const switchToYearly = async () => {
        setApiRes(await sendToApiReturn(`plus_yearly`, {}))
      }
    
      const reactivateSubscription = async () => {
        setApiRes(await sendToApiReturn(`plus_reactivate`, {}))
      }

      return !apiRes ? <LoadingOverlay blueSpinner blueText loadingText={"Fetching subscription data..."} /> : (
        <div className={`${styles.container} ${fjUser && styles.fjBg} ${!fjUser && styles.escribg}`}>
        <div className={styles.header}>
            <h4 className={styles.totalheader} >
                Subscription
            </h4>
            <div className={styles.price_options} onClick={()=>showModal(modalOverlay, {type:'pricingOptions', setSubData:setApiRes})}>
             See pricing options
            </div>
        </div>

        <div className={styles.between}>
          <div className={styles.note}>
            <br />
            <p>
              <strong>{apiRes.currentSub.yearly? 'Annual' : 'Monthly'} </strong> 
                subscription for {apiRes.currentSub.numberOfSeats} students valid until 
              <strong> {format(new Date(apiRes.currentSub.renewalDate), 'MMMM do, yyyy')}. </strong>
              (Your next charge will be on {format(new Date(apiRes.currentSub.renewalDate), 'MMMM do, yyyy')})
            </p>
            <br />
            {
              fjUser &&  <p>
                {apiRes.currentSub.availableSophiaCredits} Sophia Institute Workbooks  available for use from the library
                ({apiRes.currentSub.purchasedSophiaCredits - apiRes.currentSub.availableSophiaCredits} workbooks used of {apiRes.currentSub.purchasedSophiaCredits} purchased).
              </p>
            }
          </div>
          <div className={styles.edit} onClick={handleDirect}>
              edit my subscription
            </div>
          </div>

        {/* <div className={styles.table_container}>
            <div className={styles.tophead}>Your subscription  and billing details</div>
            <br />
            <div className={`${styles.table} ${styles.current}`}>
                <div className={`${styles.details}`}>
                    <div className={`${styles.column} ${styles.leftPadding}`}><h5>Date</h5></div>
                    <div className={styles.column}><h5>Subscription Type</h5></div>
                    <div className={`${styles.column} ${styles.hideRes}`}><h5>#</h5></div>
                    <div className={styles.column}><h5> Price</h5></div>
                    <div className={styles.column}><h5>Total</h5></div>
                </div>

                <div className={styles.tableRow}>
                    <div className={styles.column}>
                        <p className={styles.responsiveDate}>{format(new Date(apiRes.currentSub.date), 'MMMM do, yyyy')}</p>
                    </div>
                    <div className={`${styles.column} ${styles.hideRes}`}>
                        <p className={` ${styles.light}`}>
                        {apiRes.currentSub.yearly? 'Annual' : 'Monthly'}
                        </p>
                    </div> 
                    <div className={`${styles.column} ${styles.input_word}`}>
                        <p className={``}>
                            {apiRes.currentSub.totalStudents}
                        </p>
                    </div>
                    <div className={`${styles.column} ${styles.input_word} ${styles.hideRes}`}><p>{apiRes.currentSub.pricePerStudent}</p></div>
                    <div className={`${styles.column} ${styles.input_word} `}>
                      <p className={``}>
                        ${(apiRes.currentSub.pricePerStudent * apiRes.currentSub.totalStudents).toFixed(2)}</p></div>
                </div>

                <div className={styles.tableRow}>
                    <div className={styles.column}>
                    <p className={styles.sophiahead}>
                        Sophia Institute Workbooks
                    </p>
                    <img src={spirit} alt="sophia" className={styles.sophiaimg} />  

                    </div>
                    <div className={`${styles.column}`}></div>
                    <div className={`${styles.column} ${styles.input_word}`}>
                    <div className={styles.input_word}>
                        {apiRes.paymentHistory[0].purchasedSophiaCredits}
                    </div>
                    </div>
                    <div className={`${styles.column} ${styles.hideRes} ${styles.input_word} `}><p>$6.99</p></div>
                    <div className={`${styles.column} ${styles.input_word}`}>
                      <p className={``}>${(apiRes.paymentHistory[0].purchasedSophiaCredits * 6.99).toFixed(2)}</p></div>
                </div>
            </div>

            <div className={styles.total}>
                Total <span>${(apiRes.currentSub.purchasedSophiaCredits * 6.99 + 
                  (apiRes.currentSub.pricePerStudent * apiRes.currentSub.totalStudents)).toFixed(2)
                }</span>
            </div>

        </div> */}

        <br />
        
        <PaymentDetailsBlock lastFourDigits={apiRes.paymentMethod} updateCard={input=>setApiRes({...apiRes, paymentMethod:input})} />

        { isAdmin && <AdminAccountSettingsBlock email={apiRes.invoice.email} updateEmail={input=>setApiRes({...apiRes, invoice:input})}/> }
      
      <div className={`${styles.history} ${styles.block}`}>
        <h4>Payment History</h4>
        <div className={`${styles.table} ${styles.history}`}>
          <div className={styles.tableHeader}>
            <div className={`${styles.column} ${styles.leftPadding}`}><h5>Date</h5></div>
            {
              fjUser && <div className={`${styles.column} ${styles.hideRes}`}><h5>Workbooks</h5></div>

            }
            <div className={`${styles.column} ${styles.hideRes}`}><h5>Subscriptions</h5></div>
            <div className={styles.column}><h5>Amount</h5></div>
            <div className={styles.column}><h5>Status</h5></div>
          </div>
          { 
            !apiRes.paymentHistory.length ? 
              <h5 className={styles.placeholder}>No payment history to display</h5>
            :


            apiRes.paymentHistory.map((item, idx) =>
              <div key={idx} className={styles.tableRow}>
              {/* <div className={styles.column}><p>{format(new Date(item.date), 'MMMM  d, yyyy')}</p></div> */}
              <div className={styles.column}><p>{format(new Date(item.date), 'MMMM do, yyyy')}</p></div>
              {
                fjUser &&
                 <div className={`${styles.column} ${styles.hideRes}`}><h5>{item.purchasedSophiaCredits}</h5></div>


              }
              <div className={`${styles.column} ${styles.hideRes}`}><p>{item.activeStudents}</p></div>
              <div className={styles.column}><p>{`$${item.amount}`}</p></div>
              <div className={styles.column}>
                <p className={`${styles.statusPill} ${styles[item.status]}`}>{item.status}</p>
              </div>
            </div>
            )
          }
        </div>

      </div>

            <div className={styles.footer}>

              {apiRes.currentSub.allowCancel  &&
                <p className={styles.cancelBtn} onClick={()=>showModal(modalOverlay, {type:'cancelSubscription', setSubData:setApiRes})}>Cancel my subscription</p>
              }

              {
                fjUser ? 
                    <a href="https://es-courses-static.s3.amazonaws.com/subscription-terms.pdf"  target="_blank" rel="noopener">
                      <em> See Faith Journal Subscription Terms</em> 
                    </a>
                  : 
                  <a style={{ textAlign: 'right' }} href="https://es-courses-static.s3.amazonaws.com/escribli-subscription-terms-2023-2024"  target="_blank" rel="noopener" className={styles.faithlink}>
                    <em>
                    See <strong>Escribli Subscription Terms</strong>
                    </em>
                  </a>

              }
                </div>

                {
                  fjUser && (
                    <div style={{ textAlign: "left" }}>
                      <div >
                      <p style={{ color: "#858585"}}>
                        <em>
                        ** Contact us at info@faith-journals.com if your institution would prefer to be <strong>invoiced</strong>.
                        </em>
                      </p>
                      </div>
                      <p style={{ color: "#858585", marginTop: "10px"}}>
                        <em>
                        *** Contact us if your institution needs multiple teachers so we can include them in the same institution account.
                        </em>
                      </p>
                  </div>
                  )
                }
    </div>
  )
}

export default SubscriptionDetails